import BackendInterface from "../../../libs/infrastructure/backend-interfaces/backend-interface";
import BackendEnergyMeasurementProvidersUserDataConverter from "@interfaces/backend-interfaces/converters/backend-energy-measurement-providers-user-data-converter";

import BackendAssetEnergyDataConfigConverter from "./converters/backend-asset-energy-data-config-converter";

export default class extends BackendInterface {
	constructor() {
		super();
		this.backend_user_energy_measurement_providers_data_converter =
			new BackendEnergyMeasurementProvidersUserDataConverter();
	}

	async update_asset_energy_data_configuration(asset_id, configuration_data) {
		const url =
			this.backend_url +
			"/api/asset/" +
			asset_id +
			"/energy-data/configuration";

		const backend_configuration =
			this._convert_local_asset_energy_data_configuration_to_backend(
				configuration_data
			);

		const backend_asset_energy_data_config = await this.rest_interface.put(
			url,
			{
				params: backend_configuration
			}
		);

		return BackendAssetEnergyDataConfigConverter(
			backend_asset_energy_data_config
		);
	}

	_convert_local_asset_energy_data_configuration_to_backend(
		local_asset_energy_data_config
	) {
		let backend_energy_data_config = {
			systems: []
		};

		if (
			local_asset_energy_data_config.systems &&
			local_asset_energy_data_config.systems.length > 0
		) {
			backend_energy_data_config.systems = [];

			local_asset_energy_data_config.systems.forEach((item) => {
				backend_energy_data_config.systems.push({
					id: item.id,
					rateContributedGeneration:
						isNaN(item.rate_contributed_generation) ||
						item.rate_contributed_generation == 0 ||
						item.rate_contributed_generation == null
							? null
							: item.rate_contributed_generation / 100,
					rateContributedConsumption:
						isNaN(item.rate_contributed_consumption) ||
						item.rate_contributed_consumption == 0 ||
						item.rate_contributed_consumption == null
							? null
							: item.rate_contributed_consumption / 100,
					rateContributedGridInteraction:
						isNaN(item.rate_contributed_grid_interaction) ||
						item.rate_contributed_grid_interaction == 0 ||
						item.rate_contributed_grid_interaction == null
							? null
							: item.rate_contributed_grid_interaction / 100
				});
			});
		}

		return backend_energy_data_config;
	}
}
