import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
		this.backend_interface = new BackendInterface();
	}

	async update_email_notifications_settings(email_notifications_settings) {
		return await this.put("/api/user/email-notification-settings", {
			body_params: {
				enabled: email_notifications_settings.enabled,
				frequency: email_notifications_settings.frequency || null
			}
		});
	}
}
