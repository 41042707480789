import ForgotPasswordBackendInterfaceClass from "@interfaces/backend-interfaces/forgot-password-backend-interface";
import ForgotPasswordParamsValidator from "@validators/user-validators/forgot-password-params-validator";
import ResetPasswordParamsValidator from "@validators/user-validators/reset-password-params-validator";

import AppError from "@classes/errors/app_error";

export default class ForgotPasswordService {
	constructor() {
		this.forgot_password_interface = new ForgotPasswordBackendInterfaceClass();
		this.forgot_password_params_validator = ForgotPasswordParamsValidator;
		this.reset_password_params_validator = ResetPasswordParamsValidator;
	}

	async forgot_password(forgot_password_params) {
		this.forgot_password_params_validator.validate(forgot_password_params);

		try {
			await this.forgot_password_interface.forgot_password(
				forgot_password_params
			);
		} catch (e) {
			throw new AppError("500", "general.errors.general_error", e);
		}
	}

	async reset_password(reset_password_params) {
		this.reset_password_params_validator.validate(reset_password_params);

		try {
			await this.forgot_password_interface.reset_password(
				reset_password_params
			);
		} catch (e) {
			throw new AppError("500", "general.errors.general_error", e);
		}
	}
}
