<template>
	<div>
		<div id="add-new-energy-system" class="white-bg rounded-s">
			<PageHeader
				:text="$t('general.add_new')"
				close_name="user-energy-systems"
				class="rounded-s_top"
			/>
			<div class="p-sm">
				<div v-if="loading" class="flex-center-content p-t-xl p-b-xl">
					<div>
						<div class="primary-color text-center">
							<Icon icon="check" size="40" />
						</div>
						<div>
							{{ $t("user_settings.energy_systems_manager.creating_msg1") }}
						</div>
						<div>
							{{ $t("user_settings.energy_systems_manager.creating_msg2") }}
						</div>
					</div>
				</div>
				<div v-else-if="new_asset">
					<div class="flex-center-content p-t-xl p-b-xl">
						<div>
							<div
								v-if="new_asset_need_to_be_reviewed"
								class="warning-color text-center"
							>
								<Icon icon="fa-search" size="40" />
							</div>
							<div v-else class="primary-color text-center">
								<Icon icon="check" size="40" />
							</div>

							<div class="sync_result_message text-center m-t font-bold">
								<span>
									{{
										$t(
											new_asset_need_to_be_reviewed
												? "user_settings.energy_systems_manager.need_to_review"
												: "user_settings.energy_systems_manager.successfully_synced"
										)
									}}
								</span>
							</div>
						</div>
					</div>
					<div class="flex-horizontal-right">
						<router-link
							:to="{
								name: 'asset-info',
								params: {
									asset_id: new_asset.id
								}
							}"
						>
							<button class="btn btn-primary">
								{{
									$t("user_settings.energy_systems_manager.go_to_asset_info")
								}}
							</button>
						</router-link>
					</div>
				</div>
				<div v-else-if="error">
					<div class="flex-center-content p-t-xl p-b-xl">
						<div>
							<div class="danger-color text-center">
								<Icon icon="alert" size="40" />
							</div>

							<div class="sync_result_message2 text-center m-t font-bold">
								<span>
									{{ $t("user_settings.energy_systems_manager.sync_error") }}
								</span>
								<span
									class="m-l-xs"
									v-if="
										error.error &&
										error.error.error_type &&
										error.error.error_type != 'error_occurred'
									"
								>
									{{ $t(error.user_message) }}.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div v-if="validation_error" class="m-b">
						<Alert :message="validation_error" />
					</div>

					<div>
						<div>
							{{
								$tc("entities.new_energy_system_with_provider.provider_name", 1)
							}}
						</div>
						<div>
							<EnergyProviderAuthsMultiselect
								v-if="can_user_editenergy_provider_auth"
								v-bind:class="{
									'danger-border':
										validation_error &&
										validation_error.field == 'provider_name'
								}"
								v-model="selected_provider_id"
								:multiple="false"
								:allow_select_all="false"
							/>
							<EnergyDataProvidersMultiselect
								v-else
								v-model="new_energy_system_with_provider.provider_name"
							/>
						</div>
					</div>

					<div class="m-t">
						<div>
							{{
								$t(
									"entities.new_energy_system_with_provider.system_id_in_provider"
								)
							}}
						</div>
						<div>
							<TextInput
								v-bind:class="{
									'danger-border':
										validation_error &&
										validation_error.field == 'system_id_in_provider'
								}"
								v-model="new_energy_system_with_provider.system_id_in_provider"
							/>
						</div>
					</div>

					<div
						class="m-t"
						v-if="new_energy_system_with_provider.provider_name == 'datadis'"
					>
						<div>
							{{ $t("entities.energy_provider_auth.auth.authorized_nifs") }}
						</div>
						<Multiselect
							v-if="authorized_nifs_options"
							v-model="
								new_energy_system_with_provider.extra_data.authorized_nif
							"
							:options="authorized_nifs_options"
						/>
						<TextInput
							v-else
							v-model="
								new_energy_system_with_provider.extra_data.authorized_nif
							"
						/>
					</div>

					<div class="flex-horizontal-right m-t-xl">
						<div class="btn btn-primary" v-on:click="create_energy_system">
							{{ $t("general.add_new") }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Alert from "@alert";

import EnergyProviderAuthsMultiselect from "@form_components/selectors/EnergyProviderAuthsMultiselect";
import EnergyDataProvidersMultiselect from "@form_components/selectors/EnergyDataProvidersMultiselect.vue";
import Multiselect from "@form_components/Multiselect.vue";
import TextInput from "@form_components/TextInput.vue";

import EnergySystemWithProviderCreatorClass from "@src/energy-system/application/energy-system-with-provider-creator.js";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder.js";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util.js";

const EnergySystemWithProviderCreator =
	new EnergySystemWithProviderCreatorClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "EnergySystemWithProviderCreate",
	components: {
		PageHeader,
		Alert,
		EnergyProviderAuthsMultiselect,
		EnergyDataProvidersMultiselect,
		Multiselect,
		TextInput
	},
	data() {
		return {
			loading: false,
			validation_error: null,
			error: null,
			can_user_editenergy_provider_auth: UserPermissionsUtil.can_user(
				"edit_energy_provider_auth"
			),
			new_asset: null,
			new_asset_need_to_be_reviewed: false,
			selected_provider_id: null,
			authorized_nifs_options: null,
			new_energy_system_with_provider: {
				provider_name: null,
				system_id_in_provider: null,
				extra_data: {}
			}
		};
	},
	methods: {
		async create_energy_system() {
			this.loading = true;
			this.validation_error = null;
			this.error = null;
			try {
				const result =
					await EnergySystemWithProviderCreator.create_energy_system(
						this.new_energy_system_with_provider
					);

				this.new_asset = result.data.new_asset;
				this.new_asset_need_to_be_reviewed = result.data.need_to_review;
			} catch (error) {
				if (error.type == "validation") {
					this.validation_error = error;
				} else {
					this.error = error;
				}
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		async selected_provider_id(new_value) {
			const energy_provider_auth =
				await EnergyProviderAuthFinder.get_energy_provider_auth(new_value);

			this.new_energy_system_with_provider.provider_name =
				energy_provider_auth.provider;

			this.authorized_nifs_options =
				energy_provider_auth.provider == "datadis"
					? energy_provider_auth.extra_data.authorized_nifs
					: null;
			this.new_energy_system_with_provider.extra_data = {};
		}
	}
};
</script>

<style scoped>
#add-new-energy-system {
	width: 600px;
}
.sync_result_message {
	max-width: 250px;
}
.sync_result_message2 {
	max-width: 350px;
}
</style>
