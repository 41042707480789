<template lang="html">
	<div id="energy-device-info" class="flex-auto-full-height-content">
		<PageHeader
			:text="$t('user_settings.energy_systems_manager.data_sheet')"
			:on_close_click="close"
			class="header rounded-s_top"
		/>
		<div class="p-sm white-bg overflow-y-scroll rounded-s_bottom">
			<div v-if="energy_device.extra_data.model">
				<span class="font-bold font-xl">
					{{ energy_device.extra_data.model }}
				</span>
			</div>

			<div v-if="energy_device.extra_data.serial_number" class="m-t-xs">
				<span class="font-bold">
					{{ $t("entities.energy_device.extra_data.serial_number") }}:
				</span>
				<span>
					{{ energy_device.extra_data.serial_number }}
				</span>
			</div>

			<div class="m-t">
				<div class="p-xs font-bold row-with-background-color rounded-s_top">
					<span>
						{{ $t("user_settings.energy_systems_manager.device_general_data") }}
					</span>
				</div>
				<div class="device-info-table">
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.dimensions") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{ energy_device.extra_data.dimensions || "-" }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.weight") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{
							energy_device.extra_data.weight
								? energy_device.extra_data.weight + "kg"
								: "-"
						}}
					</div>
				</div>

				<div class="p-xs font-bold row-with-background-color">
					<span>
						{{
							$t("user_settings.energy_systems_manager.device_technical_data")
						}}
					</span>
				</div>
				<div class="device-info-table">
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.max_efficiency") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{
							energy_device.extra_data.max_efficiency
								? energy_device.extra_data.max_efficiency + "%"
								: "-"
						}}
					</div>
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.european_efficiency") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{
							energy_device.extra_data.european_efficiency
								? energy_device.extra_data.european_efficiency + "%"
								: "-"
						}}
					</div>
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.peak_power") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{
							energy_device.extra_data.peak_power
								? energy_device.extra_data.peak_power + " kWp"
								: "-"
						}}
					</div>
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.nominal_power") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{
							energy_device.extra_data.nominal_power
								? energy_device.extra_data.nominal_power + " W"
								: "-"
						}}
					</div>
					<div class="p-xs flex-vertical-center">
						{{ $t("entities.energy_device.extra_data.nominal_voltage") }}
					</div>
					<div class="p-xs flex-vertical-center">
						{{
							energy_device.extra_data.nominal_voltage
								? energy_device.extra_data.nominal_voltage + " V"
								: "-"
						}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";

export default {
	name: "EnergyDeviceInfo",
	components: {
		PageHeader
	},
	props: {
		energy_device: Object
	},
	methods: {
		close() {
			this.$emit("close");
		}
	}
};
</script>

<style lang="css" scoped>
#energy-device-info {
	width: 700px;
	max-height: calc(100% - 30px);
}
.row-with-background-color {
	background-color: #e6e6e7;
}
.device-info-table {
	display: grid;
	grid-template-columns: 3fr 5fr;
}
.device-info-table > div {
	border: 1px solid #e6e6e7;
}
</style>
