<template lang="html">
	<div v-if="status && status != 'unsupported'" class="margin-center">
		<div
			class="location-status-circle margin-center"
			:class="circle_class"
		></div>
	</div>
</template>

<script>
export default {
	name: "AssetStatusCircle",
	props: {
		status: String
	},
	computed: {
		circle_class() {
			let result;
			switch (this.status) {
				case "ok":
					result = "success-color";
					break;
				case "faulty":
					result = "warning-bg";
					break;
				case "offline":
					result = "danger-bg";
					break;
				case "unavailable":
					result = "unavailable-color";
					break;
				default:
					result = null;
			}

			return result;
		}
	}
};
</script>

<style lang="css" scoped>
.location-status-circle {
	height: 20px;
	width: 20px;
	border-radius: 50%;
}
.unavailable-color {
	background-color: #abb1b7;
}
.success-color {
	background-color: var(--green1);
}
</style>
