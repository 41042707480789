import * as xlsx from "xlsx";

export default class {
	convert_array_to_xlsx(array_content, title) {
		var workbook = xlsx.utils.book_new();
		workbook.Props = {
			Title: title,
			Author: "Nnergix",
			CreatedDate: new Date()
		};
		workbook.SheetNames.push(title);

		const sheet_content = xlsx.utils.aoa_to_sheet(array_content);

		workbook.Sheets[title] = sheet_content;

		var wbout = xlsx.write(workbook, { bookType: "xlsx", type: "binary" });

		return wbout;
	}
}
