import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.array().items(
	Joi.object({
		time: Joi.date().required(),
		energy_production_forecast: Joi.number(),
		energy_consumption_forecast: Joi.number()
	})
);
export default new Validator(schema);
