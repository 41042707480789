<template>
	<div class="full-width full-height p-sm">
		<div class="flex-auto-full-height-content">
			<div class="header p-b">
				<div>
					<span class="font-700 font-xxl">
						{{ $t("asset_energy_panel.total_savings") }}
					</span>
				</div>
				<div
					v-if="energy_production_from && energy_production_to"
					class="font-l"
				>
					<span class="font-bold">{{ $t("asset_energy_panel.period") }}:</span>
					<span class="m-l-xs">
						{{ $format_date_to_month(energy_production_from) }} -
						{{ $format_date_to_month(energy_production_to) }}</span
					>
				</div>
			</div>
			<div class="content">
				<div v-if="loading" class="full-height flex-center-content">
					<Loader size="30" />
				</div>
				<div v-else-if="error">
					<Alert :message="error" />
				</div>
				<div v-else-if="savings" id="savings-table" class="white">
					<div class="rounded-s p-xs grey1-bg">
						<div class="display-flex-space-between full-height">
							<div class="flex-vertical-bottom">
								<div>
									<div class="display-flex p-b-xxs">
										<Icon icon="home" size="40" />
									</div>
									<div>
										<span class="font-bold">
											{{ $t("asset_energy_panel.consumption_eq_to") }}
										</span>
									</div>
								</div>
							</div>

							<div class="flex-vertical-bottom">
								<span class="font-xxl font-bold">
									{{
										savings.energy_eq_1_year_families != null
											? $format_number(savings.energy_eq_1_year_families, 0) +
											  " " +
											  $t("asset_energy_panel.families")
											: "-"
									}}
								</span>
							</div>
						</div>
					</div>

					<div class="rounded-s p-xs grey1-bg">
						<div class="display-flex-space-between full-height">
							<div class="flex-vertical-bottom">
								<div>
									<div>
										<Icon class="green1-color" icon="seedling" size="40" />
										CO2
									</div>
									<div>
										<span class="font-bold">
											{{ $t("asset_energy_panel.avoided") }}
										</span>
									</div>
								</div>
							</div>

							<div class="flex-vertical-bottom">
								<span class="font-xxl font-bold">
									{{
										savings.co2 != null ? $format_number(savings.co2, 0) : "-"
									}}
								</span>
								<span v-if="savings.co2 != null" class="font-xxl"> Kg </span>
							</div>
						</div>
					</div>

					<div class="rounded-s p-xs grey1-bg">
						<div class="display-flex-space-between full-height">
							<div class="flex-vertical-bottom">
								<div>
									<div class="display-flex p-b-xxs">
										<Icon style="color: #fcb12a" icon="sun" size="40" />
									</div>

									<div>
										<span class="font-bold">
											{{ $t("asset_energy_panel.saved") }}
										</span>
									</div>
								</div>
							</div>

							<div class="flex-vertical-bottom">
								<span class="font-xxl font-bold">
									{{
										savings.energy_self_consumption != null
											? $format_number(savings.energy_self_consumption, 0) +
											  "kWh"
											: "-"
									}}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<Alert type="info" message="general.errors.no_data_available" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder.js";
import LocationEnergyMeasurementProvidersServiceClass from "@services/location-services/location-energy-measurement-providers-service.js";

const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();
const LocationEnergyMeasurementProvidersService =
	new LocationEnergyMeasurementProvidersServiceClass();

export default {
	name: "AssetSavings",
	props: {
		asset_id: String
	},
	components: {
		Loader,
		Alert
	},
	data() {
		return {
			loading: false,
			error: null,
			savings: null,
			energy_production_from: null,
			energy_production_to: null
		};
	},
	async mounted() {
		this.set_data();
	},
	methods: {
		async set_data() {
			this.loading = true;
			this.error = null;

			try {
				const asset_monthly_energy =
					await AssetMonthlyEnergyFinder.get_asset_monthly_energy(
						this.asset_id
					);

				if (!asset_monthly_energy) {
					this.savings = null;

					setTimeout(() => {
						this.set_data();
					}, 300000);
				} else {
					let total_energy_production = 0;
					let total_energy_self_consumption = 0;
					asset_monthly_energy.forEach((item) => {
						total_energy_production += item.energy_production;
						total_energy_self_consumption += item.energy_self_consumption || 0;
					});

					this.energy_production_from = asset_monthly_energy[0].time;
					this.energy_production_to =
						asset_monthly_energy[asset_monthly_energy.length - 1].time;

					this.savings =
						LocationEnergyMeasurementProvidersService.get_saved_from_aggregated(
							{
								energy_production: total_energy_production,
								energy_self_consumption: total_energy_self_consumption
							}
						);

					setTimeout(() => {
						this.set_data();
					}, 21600000);
				}
			} catch (error) {
				this.error = error;

				setTimeout(() => {
					this.set_data();
				}, 300000);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#savings-table {
	height: 100%;
	display: grid;
	grid-template-rows: repeat(3, auto);
	row-gap: 10px;
}
</style>
