import AssetsUpdaterInterfaceClass from "@src/asset/infrastructure/asset-updater-interface";
import AssetFinderValidator from "@src/asset/domain/asset-finder-validator";
import AssetCreatorValidator from "@src/asset/domain/asset-creator-validator";

import AssetsStoreUtil from "./utils/assets-store-util";
import AssetsLastUpdateUtil from "./utils/assets-last-update-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.store_util = AssetsStoreUtil;
		this.assets_last_update_util = new AssetsLastUpdateUtil();
		this.update_validator = AssetCreatorValidator;
		this.finder_validator = AssetFinderValidator;
		this.backend_interface = new AssetsUpdaterInterfaceClass();
	}

	async update_asset(asset_id, asset_to_update) {
		const validated_asset_to_update =
			this.update_validator.validate(asset_to_update);

		try {
			const interface_result = await this.backend_interface.update_asset(
				asset_id,
				validated_asset_to_update
			);

			const updated_asset = this.finder_validator.validate(interface_result);

			await this.store_util.add_or_update_item(updated_asset);

			this.assets_last_update_util.set_assets_last_update();

			return updated_asset;
		} catch (e) {
			throw new AppError("update_asset", "general.errors.general_error", e);
		}
	}
}
