<template>
	<div id="asset-systems" class="rounded-s white-bg flex-auto-height-content">
		<PageHeader class="header" :text="$t('general.view')" close_name="app" />
		<div v-if="system_id" class="rounded-s_bottom content relative">
			<div class="full-width full-height absolute">
				<AssetsGroupAssets
					assets_group_id="default"
					:filter_assets_in_system="system_id"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader.vue";
import Loader from "@loader";
import Alert from "@alert";

import AssetsGroupAssets from "./assets-group-assets/AssetsGroupAssets.vue";

export default {
	name: "AssetSystems",
	components: {
		PageHeader,
		Loader,
		Alert,
		AssetsGroupAssets
	},
	computed: {
		system_id() {
			return this.$route.params.system_id;
		}
	}
};
</script>

<style scoped>
#asset-systems {
	width: calc(100% - 30px);
	height: calc(100% - 150px);
}
</style>
