<template>
	<div class="alert" :class="'alert-' + alert_type">
		<span>{{ transformed_message }}</span>
	</div>
</template>

<script>
import AppError from "@classes/errors/app_error";

export default {
	name: "Alert",
	props: ["type", "message", "validation_error"],
	methods: {
		get_message_from_validation_error(validation_error) {
			let multilingual_args = {
				field: this.$t(validation_error.multilingual_field_path) || ""
			};
			if (this.$is_number(validation_error.field_limit)) {
				multilingual_args.limit = validation_error.field_limit;
			}
			return this.$t(validation_error.message, multilingual_args);
		}
	},
	computed: {
		transformed_message() {
			if (this.validation_error) {
				return this.get_message_from_validation_error(this.validation_error);
			} else if (this.message.type == "validation") {
				return this.get_message_from_validation_error(this.message);
			} else if (this.message instanceof AppError) {
				return this.$t(this.message.user_message);
			} else {
				const translated_string = this.$t(this.message);
				return translated_string
					? translated_string
					: this.alert_type == "danger"
					? this.$t("general.errors.general_error")
					: this.message;
			}
		},
		alert_type() {
			return this.type ? this.type : "danger";
		}
	}
};
</script>

<style scoped>
.alert {
	margin-bottom: 0;
}
.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}
.alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
}
.alert-success {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
}
.alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
}
.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}
</style>
