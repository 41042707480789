<template lang="html">
	<div
		class="number-input"
		:class="{ disabled: is_input_disabled, 'danger-border': has_error }"
	>
		<input
			type="text"
			v-model="formatted_value"
			@change="update_number_value"
			:disabled="is_input_disabled"
			:placeholder="placeholder"
		/>
		<div v-if="unit" class="number-input-unit flex-vertical-center">
			<span>{{ unit }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "NumberInput",
	props: {
		value: Number,
		has_error: Boolean,
		disabled: Boolean,
		placeholder: String | Number,
		min: Number,
		max: Number,
		unit: String
	},
	data() {
		return {
			formatted_value: null,
			number_value: null
		};
	},
	created() {
		if (this.value != undefined && this.value != null) {
			this.number_value = this.value;
			this.set_formatted_value(this.value);
		}
	},
	methods: {
		set_formatted_value(number_value) {
			this.formatted_value =
				number_value != null ? this.$format_number(number_value) : null;
		},
		update_number_value() {
			this.number_value = this.$parse_to_number(this.formatted_value);

			if (!isNaN(this.min) && this.number_value < this.min) {
				this.number_value = this.min;
			} else if (!isNaN(this.max) && this.number_value > this.max) {
				this.number_value = this.max;
			}

			this.$emit("input", this.number_value);
			this.$emit("change", this.number_value);
		}
	},
	computed: {
		is_input_disabled() {
			return this.disabled != undefined ? this.disabled : false;
		}
	},
	watch: {
		value(new_value) {
			this.set_formatted_value(new_value);
		}
	}
};
</script>

<style lang="css" scoped>
.number-input {
	position: relative;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	-webkit-box-align: stretch;
	align-items: stretch;
	width: 100%;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	padding: 0.375rem 0.75rem;
}
.number-input.disabled {
	background-color: #e9ecef;
}
.number-input > input {
	position: relative;
	-ms-flex: 1 1 auto;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
	margin-bottom: 0;
	border: none;
	font-size: 1rem;
}
.number-input > .number-input-unit {
	font-size: 0.9rem;
}
</style>
