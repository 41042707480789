import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import messages from "@lang";
import { store_entity_names } from "@config";

export default class {
	constructor() {
		this.storage_interface = new StoreInterfaceClass();
		this.user_language_entity_name = store_entity_names.language;
	}

	set_user_app_lang(lang) {
		this.storage_interface.set(
			this.user_language_entity_name,
			lang,
			null,
			true
		);
	}
	get_user_app_lang() {
		return this.storage_interface.get(this.user_language_entity_name) || "en";
	}

	translate(string, args = undefined) {
		var translated_string = this._get_string_from_path(string);
		if (!translated_string) return null;

		if (args) {
			for (let slug in args) {
				const param_value = args[slug];

				translated_string = translated_string.replace(
					"{" + slug + "}",
					param_value
				);
			}
		}

		return translated_string;
	}

	translate_plural(string, number) {
		var translated_string = this._get_string_from_path(string);
		if (!translated_string) return null;

		const plural_in_array = translated_string.split(" | ");

		return number == 1 ? plural_in_array[0] : plural_in_array[1];
	}

	_get_string_from_path(path) {
		if (typeof path != "string") return null;

		const app_current_app = this.get_user_app_lang();
		const language_strings = messages[app_current_app];

		function get_object_value_from_path(o, s) {
			s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
			s = s.replace(/^\./, ""); // strip a leading dot
			var a = s.split(".");
			for (var i = 0, n = a.length; i < n; ++i) {
				var k = a[i];
				if (k in o) {
					o = o[k];
				} else {
					return;
				}
			}
			return o;
		}

		return get_object_value_from_path(language_strings, path);
	}
}
