<template>
	<div class="full-width full-height p-sm">
		<div class="flex-auto-full-height-content">
			<div class="header">
				<div>
					<span class="font-700 font-xxl">
						{{
							$t(
								"asset_energy_panel." +
									(asset_latest_data_date_is_today
										? "current_day_power"
										: "latest_data_available")
							)
						}}
					</span>
					<span v-if="!asset_latest_data_date_is_today" class="m-l-sm">
						{{ $format_date_to_day(latest_data_date) }}
					</span>
				</div>
			</div>

			<AssetLastDayEnergyMeasurementsChart
				:asset_id="asset_id"
				v-on:data_updated="asset_last_day_measurements_updated"
			/>
		</div>
	</div>
</template>

<script>
import AssetLastDayEnergyMeasurementsChart from "@sub_components/location-components/AssetLastDayEnergyMeasurementsChart.vue";

export default {
	name: "AssetCurrentDayPower",
	components: {
		AssetLastDayEnergyMeasurementsChart
	},
	props: {
		asset_id: String
	},
	data() {
		return {
			asset_latest_data_date_is_today: null,
			latest_data_date: null
		};
	},
	methods: {
		asset_last_day_measurements_updated(data) {
			this.asset_latest_data_date_is_today = data.latest_data_date_is_today;
			this.latest_data_date = data.latest_data_date;
		}
	}
};
</script>
