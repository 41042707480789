<template>
	<div
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
		style="z-index: 3"
	>
		<PageHeader :text="$t('general.remove')" close_name="owned-users" />
		<div class="p-sm">
			<div v-if="success">
				<Alert type="success" message="general.update_successful" />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>

			<div class="m-t" v-if="!success">
				<Loader v-if="loading" size="30" />
				<div v-else>
					<div v-on:click="remove_guest" class="btn btn-danger right">
						{{ $t("general.remove") }}
					</div>
					<router-link :to="{ name: 'owned-users' }">
						<div class="btn btn-primary right m-r">
							{{ $t("general.cancel") }}
						</div>
					</router-link>
				</div>

				<div class="clear"></div>
			</div>

			<div class="clear"></div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@components/sub_components/Loader";
import Alert from "@alert";

import OwnedUserEraserClass from "@src/user/application/owned-user-eraser";
const OwnedUserEraser = new OwnedUserEraserClass();

export default {
	name: "UserRemove",
	components: {
		PageHeader,
		Loader,
		Alert
	},
	props: {
		selected_mananger_id: String | null
	},
	data() {
		return {
			success: false,
			error: null,
			loading: undefined
		};
	},
	methods: {
		async remove_guest() {
			this.error = null;
			this.success = false;
			this.loading = true;

			try {
				const role = this.$route.params.role;
				const user_id = this.$route.params.user_id;

				await OwnedUserEraser.remove_user(
					role,
					user_id,
					this.selected_mananger_id
				);
				this.success = true;
				this.$emit("user_updated");
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = null;
			}
		}
	}
};
</script>

<style></style>
