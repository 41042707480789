import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.object({
	has_assets_with_multiple_systems: Joi.boolean().required(),
	energy_systems_summary: Joi.object({
		total_peak_power: Joi.number(),
		today_energy_production: Joi.number(),
		pr_average: Joi.number(),
		current_month_savings: Joi.number(),
		current_month_energy_self_consumption: Joi.number()
	}),
	systems_status: Joi.object({
		ok: Joi.number(),
		offline: Joi.number(),
		faulty: Joi.number(),
		unavailable: Joi.number(),
		unsupported: Joi.number()
	}),
	assets_types: Joi.object({
		generation: Joi.number(),
		consumption: Joi.number(),
		generation_and_consumption: Joi.number(),
		only_grid_interaction: Joi.number()
	}),
	alerts: Joi.object({
		provider_alert: Joi.number(),
		pr_alert: Joi.number(),
		suspicious_data_alert: Joi.number()
	})
});
export default new Validator(schema);
