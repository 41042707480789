import Joi from "joi";
import Validator from "@validators/validator";

const forgot_password_params_schema = Joi.object({
	email: Joi.string()
		.email({ minDomainSegments: 2, tlds: false })
		.required()
});

export default new Validator(forgot_password_params_schema, "login.entity.");
