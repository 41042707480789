<template lang="html">
	<div class="full-width full-height p-xxs white-bg">
		<div
			class="flex-auto-full-height-content white-bg rounded-s overflow-hidden"
		>
			<div class="header">
				<PageHeader :text="$t('asset_reports.menu_label')" close_name="app" />
				<AssetReportsHeader v-on:generate_report="generate_report" />
			</div>

			<div v-if="loading" class="flex-center-content">
				<Loader />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>

			<div v-else class="content">
				<div v-if="filter" class="full-width full-height">
					<AssetPvReport
						v-if="filter.report_type == 'standard'"
						:asset="asset"
						:asset_reports_color="asset_reports_color"
						:filter="filter"
					/>
					<AssetMonthlySavingsReport
						v-else-if="filter.report_type == 'monthly_savings'"
						:asset="asset"
						:asset_reports_color="asset_reports_color"
						:filter="filter"
					/>
					<AssetMonthlySavingsReport2
						v-else-if="filter.report_type == 'monthly_savings_2'"
						:asset="asset"
						:asset_reports_color="asset_reports_color"
						:filter="filter"
					/>
					<AssetInjection0Report
						v-else-if="filter.report_type == 'injection0'"
						:asset="asset"
						:asset_reports_color="asset_reports_color"
						:filter="filter"
					/>
				</div>
				<div v-else id="no-content" class="full-width full-height text-center">
					<div class="grey1-color">
						<Icon icon="file-alt" size="50" />
					</div>
					<div class="grey1-color font-bold font-xxxxxl">
						<span>{{ $t("asset_reports.menu_label") }}</span>
					</div>
					<div class="grey2-color font-xxl font-bold m-t">
						<span>{{ $t("asset_reports.no_content_string_1") }}</span>
					</div>
					<div class="grey2-color font-xl">
						<span>{{ $t("asset_reports.no_content_string_2") }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Alert from "@alert";
import Loader from "@loader";

import AssetReportsHeader from "./AssetReportsHeader";
import AssetPvReport from "./asset-pv-report/AssetPvReport";
import AssetMonthlySavingsReport from "./asset-monthly-savings-report/AssetMonthlySavingsReport";
import AssetMonthlySavingsReport2 from "./asset-monthly-savings-report-2/AssetMonthlySavingsReport2";
import AssetInjection0Report from "./asset-injection0-report/AssetInjection0Report.vue";

import AssetFinderClass from "@src/asset/application/assets-finder";
import UserFinderClass from "@src/user/application/user-finder.js";

const AssetFinder = new AssetFinderClass();
const UserFinder = new UserFinderClass();

export default {
	name: "LocationPvReport",
	components: {
		PageHeader,
		Alert,
		Loader,
		AssetReportsHeader,
		AssetPvReport,
		AssetMonthlySavingsReport,
		AssetMonthlySavingsReport2,
		AssetInjection0Report
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_id: null,
			asset: null,
			asset_reports_color: null,
			filter: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const user = await UserFinder.get_user();
			this.asset_reports_color = user.custom_color || "#081f56";
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async generate_report(asset_id, filter) {
			this.loading = true;

			try {
				const asset = await AssetFinder.get_asset_by_id(asset_id);

				if (!asset) {
					throw new Error({ user_message: "overview.errors.asset_not_exist" });
				}

				this.asset = asset;
				this.filter = Object.assign({}, filter);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#no-content > div:first-child {
	margin-top: 150px;
	font-size: 60px;
}
</style>

<style>
.asset-report-main-div {
	width: calc(800px + 80px);
	position: relative;
	height: 100%;
}
.asset-report-viewer {
	position: absolute;
	width: 100%;
	left: 0;
	top: 0px;
	height: 100%;
}
.report-page {
	padding: 40px 40px 40px;
}
.report-page:not(:first-child) {
	margin-top: 15px;
}
</style>
