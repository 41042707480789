import EmailNotificationsSettingsUpdaterInterfaceClass from "../infrastructure/email-notifications-settings-updater-interface";
import EmailNotificationsSettingsValidator from "../domain/email-notifications-settings-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface =
			new EmailNotificationsSettingsUpdaterInterfaceClass();
		this.validator = EmailNotificationsSettingsValidator;
	}

	async update_email_notifications_settings(email_notifications_settings) {
		const validated_email_notifications = this.validator.validate(
			email_notifications_settings
		);
		try {
			const updated_email_notifications_settings =
				await this.backend_interface.update_email_notifications_settings(
					validated_email_notifications
				);

			return this.validator.validate(updated_email_notifications_settings);
		} catch (error) {
			throw new AppError(
				"error_update_email_notifications_settings",
				"general.errors.general_error",
				error
			);
		}
	}
}
