import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async remove_assets_group(assets_group_id) {
		const url = "/api/assets-group/" + assets_group_id;
		await this.delete(url);

		return true;
	}
}
