<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="p-sm">
			<Loader size="30" />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else-if="no_data_available" class="p-sm">
			<Alert type="info" message="general.errors.no_data_available" />
		</div>
		<div v-else-if="custom_monthly_data" class="flex-auto-full-height-content">
			<div class="content p-sm">
				<div v-if="update_success_message" class="m-b">
					<Alert type="success" :message="update_success_message" />
				</div>

				<div
					v-for="(data_type, index) in data_types"
					v-bind:class="{ 'm-t-xl': index > 0 }"
				>
					<div class="border-b">{{ data_type.label }}</div>
					<div class="monthly-data-table m-t">
						<div class="monthly-data-table-row">
							<div></div>
							<div v-for="item in months_numbers">
								<div class="text-center">
									{{ $t("general.months." + item).slice(0, 3) }}
								</div>
							</div>
						</div>
						<div
							v-for="(year_data, year) in custom_monthly_data"
							class="monthly-data-table-row"
						>
							<div class="flex-vertical-center">{{ year }}</div>
							<div v-for="month_number in months_numbers">
								<NumberInput
									v-if="year_data[month_number]"
									v-model="year_data[month_number][data_type.key]"
									:placeholder="
										energy_system_monthly_data &&
										energy_system_monthly_data[year] &&
										energy_system_monthly_data[year][month_number]
											? $format_number(
													energy_system_monthly_data[year][month_number][
														data_type.key
													],
													2
											  )
											: null
									"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer border-t p-sm">
				<div class="text-right">
					<button class="btn btn-primary" v-on:click="save">
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import NumberInput from "@form_components/NumberInput";

import moment from "moment";
import EnergySytemFinderClass from "@src/energy-system/application/energy-system-finder.js";
import EnergySystemDataFirstDateFinderClass from "@src/energy-system/application/energy-system-data-first-date-finder.js";
import EnergySystemCustomMonthlyEnergyFinderClass from "@src/energy-system/application/energy-data/energy-system-custom-monthly-energy-finder.js";
import EnergySystemCustomMonthlyEnergyUpdaterClass from "@src/energy-system/application/energy-data/energy-system-custom-monthly-energy-updater.js";
import EnergySytemMonhtlyEnergyFinderClass from "@src/energy-system/application/energy-data/energy-system-monthly-energy-finder.js";

const EnergySytemFinder = new EnergySytemFinderClass();
const EnergySystemDataFirstDateFinder =
	new EnergySystemDataFirstDateFinderClass();
const EnergySystemCustomMonthlyEnergyFinder =
	new EnergySystemCustomMonthlyEnergyFinderClass();
const EnergySystemCustomMonthlyEnergyUpdater =
	new EnergySystemCustomMonthlyEnergyUpdaterClass();
const EnergySytemMonhtlyEnergyFinder =
	new EnergySytemMonhtlyEnergyFinderClass();

const variables_to_check = [
	"energy_production",
	"energy_consumption",
	"energy_self_consumption"
];

export default {
	name: "EnergySystemEditMonthlyData",
	components: {
		Loader,
		Alert,
		NumberInput
	},
	props: {
		energy_system: Object
	},
	data() {
		let months = [];
		for (var i = 1; i <= 12; i++) {
			months.push(i);
		}

		return {
			loading: false,
			error: null,
			no_data_available: false,
			update_success_message: null,
			months_numbers: months,
			custom_monthly_data: null,
			energy_system_monthly_data: null,
			system_monthly_date_from: null,
			system_monthly_date_to: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const system_id = this.$route.params.system_id;
			let date_from =
				await EnergySystemDataFirstDateFinder.get_energy_system_data_first_date(
					system_id
				);
			date_from = date_from.historical_first_day;
			const energy_system = await EnergySytemFinder.get_energy_system(
				system_id
			);
			if (
				energy_system.commissioning_date &&
				moment(energy_system.commissioning_date).isBefore(date_from)
			) {
				date_from = energy_system.commissioning_date;
			}
			const date_to = moment().subtract(1, "months").endOf("month").toDate();

			if (moment(date_to).isBefore(date_from)) {
				this.no_data_available = true;
			} else {
				const months_diff = moment(date_to).diff(date_from, "months");
				let custom_monthly_data = {};
				for (var i = 0; i <= months_diff; i++) {
					const month_date = moment(date_from).add(i, "months");
					const item_year = moment(month_date).format("YYYY");
					const item_month = moment(month_date).format("M");

					if (!custom_monthly_data[item_year])
						custom_monthly_data[item_year] = {};

					custom_monthly_data[item_year][item_month] = {
						energy_production: null,
						energy_consumption: null,
						energy_self_consumption: null
					};
				}

				const energy_system_custom_hourly =
					await EnergySystemCustomMonthlyEnergyFinder.get_energy_system_custom_hourly(
						system_id
					);

				energy_system_custom_hourly.data.forEach((item) => {
					const item_year = moment(item.month).format("YYYY");
					const item_month = moment(item.month).format("M");

					variables_to_check.forEach((variable) => {
						if (
							item[variable] != null &&
							custom_monthly_data[item_year] &&
							custom_monthly_data[item_year][item_month]
						)
							custom_monthly_data[item_year][item_month][variable] =
								item[variable];
					});
				});

				this.custom_monthly_data = custom_monthly_data;

				this.system_monthly_date_from = date_from;
				this.system_monthly_date_to = date_to;

				await this.load_energy_system_monthly_data(system_id);
			}
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async load_energy_system_monthly_data(system_id) {
			const energy_system_monthly_data =
				await EnergySytemMonhtlyEnergyFinder.get_energy_system_monthly_data(
					system_id,
					this.system_monthly_date_from,
					this.system_monthly_date_to,
					true
				);

			let formatted_energy_system_monthly_data = {};
			energy_system_monthly_data.forEach((item) => {
				const item_year = moment(item.time).format("YYYY");
				const item_month = moment(item.time).format("M");

				if (!formatted_energy_system_monthly_data[item_year])
					formatted_energy_system_monthly_data[item_year] = {};

				formatted_energy_system_monthly_data[item_year][item_month] = {
					energy_production: item.energy_production,
					energy_consumption: item.energy_consumption,
					energy_self_consumption: item.energy_self_consumption
				};
			});

			this.energy_system_monthly_data = formatted_energy_system_monthly_data;
		},
		async save() {
			this.loading = true;

			try {
				const system_id = this.$route.params.system_id;
				let formatted_custom_monthly_data = {
					data: []
				};

				for (let year in this.custom_monthly_data) {
					const year_content = this.custom_monthly_data[year];

					for (let month in year_content) {
						const month_content = year_content[month];

						if (
							month_content.energy_consumption == null &&
							month_content.energy_production == null &&
							month_content.energy_self_consumption == null
						)
							continue;

						const formatted_month = moment()
							.year(year)
							.month(Number(month) - 1)
							.format("YYYY-MM");
						let formatted_month_content = {
							month: formatted_month
						};
						variables_to_check.forEach((variable) => {
							if (month_content[variable] != null) {
								formatted_month_content[variable] = month_content[variable];
							}
						});
						formatted_custom_monthly_data.data.push(formatted_month_content);
					}
				}

				await EnergySystemCustomMonthlyEnergyUpdater.update_energy_system_custom_hourly(
					system_id,
					formatted_custom_monthly_data
				);

				await this.load_energy_system_monthly_data(system_id);

				this.update_success_message = "general.update_successful";
				setTimeout(() => {
					this.update_success_message = null;
				}, 7000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		data_types() {
			let items = [];

			if (
				this.energy_system.system_type == "generation_and_consumption" ||
				this.energy_system.system_type == "only_generation"
			) {
				items.push({
					label:
						this.$t("asset_info.monthly_data.energy_production") + " (kWh)",
					key: "energy_production"
				});
			}

			if (
				this.energy_system.system_type == "generation_and_consumption" ||
				this.energy_system.system_type == "only_consumption"
			) {
				items.push({
					label:
						this.$t("asset_info.monthly_data.energy_consumption") + " (kWh)",
					key: "energy_consumption"
				});
			}

			if (this.energy_system.system_type == "generation_and_consumption") {
				items.push({
					label:
						this.$t("asset_info.monthly_data.energy_self_consumption") +
						" (kWh)",
					key: "energy_self_consumption"
				});
			}

			return items;
		}
	}
};
</script>

<style lang="css" scoped>
.monthly-data-table {
	display: grid;
	row-gap: 10px;
}
.monthly-data-table-row {
	display: grid;
	grid-template-columns: 75px repeat(12, 1fr);
	column-gap: 10px;
}
</style>
