<template lang="html">
	<div>
		<div
			v-if="$is_number(filter_month_savings.self_consumption_savings)"
			class="flex-center-content"
		>
			<ReportChartHeaderValue
				icon_string="€"
				:color="asset_reports_color"
				:value="
					$format_number(filter_month_savings.self_consumption_savings, 0) +
					' €'
				"
			/>
		</div>

		<StackedColumnChart
			width="100%"
			height="300px"
			:series="[
				{
					self_consumption_savings:
						filter_month_savings.self_consumption_savings,
					from_grid_price: from_grid_price,
					time: filter_month_energy.time
				}
			]"
			:series_data="[
				{
					prop_name: 'from_grid_price',
					label: 'LABEL2',
					color: '#cfd4d9'
				},
				{
					prop_name: 'self_consumption_savings',
					label: 'LABEL1',
					color: asset_reports_color
				}
			]"
			:hide_legend="true"
			:hide_toolbar="true"
			:hide_tooltips="true"
			:y_axis_formatter="(value) => $format_number(value) + ' €'"
			:x_axis_formatter="(value) => $format_date_to_month(value)"
			:show_toolbar="false"
			column_width="30px"
		/>

		<div class="flex-center-content">
			<ChartCategory
				:asset_reports_color="asset_reports_color"
				:label="$t('asset_energy_panel.energy_self_consumption')"
			/>
			<ChartCategory
				class="m-l-xs"
				asset_reports_color="#cfd4d9"
				:label="$t('asset_energy_panel.energy_from_grid')"
			/>
		</div>
	</div>
</template>

<script>
import StackedColumnChart from "@sub_components/charts/StackedColumnChart";
import ChartCategory from "./ChartCategory";
import ReportChartHeaderValue from "../../../reports-shared-components/ReportChartHeaderValue";

export default {
	name: "MonthSavingsColumnChart",
	components: {
		StackedColumnChart,
		ChartCategory,
		ReportChartHeaderValue
	},
	props: {
		filter_month_savings: Object,
		filter_month_energy: Object,
		asset_reports_color: String
	},
	computed: {
		from_grid_price() {
			return (
				(this.filter_month_energy.energy_from_grid *
					this.filter_month_savings.total_savings) /
				this.filter_month_energy.energy_self_consumption
			);
		}
	}
};
</script>

<style lang="css" scoped></style>
