import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_system_custom_config(user_id, energy_system_id) {
		const url =
			"/api/admin/energy-data/" +
			user_id +
			"/energy-systems/" +
			energy_system_id +
			"/custom-config";
		const backend_data = await this.get(url);

		const local_data = BackendObjectConverter(backend_data);
		return local_data;
	}
}
