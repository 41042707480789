<template lang="html">
	<div class="full-width full-height p-xxs">
		<div class="full-width full-height">
			<div class="full-width full-height grey2-bg flex-center-content relative">
				<div class="asset-report-main-div relative">
					<div id="download-pdf-button">
						<div v-if="download_pdf_loading">
							<Loader size="20" />
						</div>
						<div v-else class="display-flex">
							<div class="btn btn-primary" v-on:click="toogle_report_comment">
								{{ $t("asset_reports.add_comment") }}
							</div>

							<div class="btn btn-primary m-l" v-on:click="download_pdf">
								{{ $t("asset_reports.download_pdf") }}
							</div>
						</div>
					</div>

					<div
						ref="pdf_content"
						class="asset-report-viewer overflow-y-scroll p-t p-b"
					>
						<div class="report-page">
							<Page1
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import Page1 from "./report-content/Page1.vue";

export default {
	name: "AssetMonthlySavingsReport2",
	components: {
		Alert,
		Loader,
		Page1
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object
	},
	data() {
		return {
			download_pdf_loading: false
		};
	},
	methods: {
		async download_pdf() {
			try {
				this.download_pdf_loading = true;

				const html_element = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					html_element,
					"asset-month-savings-report"
				);
			} catch (error) {
			} finally {
				this.download_pdf_loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#download-pdf-button {
	position: absolute;
	right: 15px;
	top: 25px;
	z-index: 1;
}
#report-comment {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.5);
}
#report-comment > div {
	width: 75%;
	margin-left: 12.5%;
	margin-top: 20px;
}
</style>

<style lang="css">
.asset-monthly-savings-report-title {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 17px;
}
</style>
