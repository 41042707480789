<template>
	<div v-if="auth_data" class="display-grid gap-s">
		<div v-for="prop_data in provider_auth_data" class="display-grid gap-xs">
			<span>
				{{ $t("entities.energy_provider_auth.auth." + prop_data.slug) }}
			</span>
			<TextInput
				v-model="auth_data[prop_data.slug]"
				:is_password="prop_data.type == 'password'"
			/>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";

export default {
	props: {
		provider_auth_data: Array //[{slug: string, type: "text | password"}]
	},
	components: {
		TextInput
	},
	name: "StandardProviderLoginForm",
	data() {
		return {
			auth_data: null
		};
	},
	mounted() {
		this.set_auth_data();
	},
	methods: {
		emit_value() {
			this.$emit("input", this.auth_data);
		},
		set_auth_data() {
			this.auth_data = {};

			this.provider_auth_data.forEach((prop_data) => {
				this.auth_data[prop_data.slug] = null;
			});
		}
	},
	watch: {
		auth_data: {
			handler(new_value, old_value) {
				this.emit_value();
			},
			deep: true
		},
		provider_auth_data() {
			this.set_auth_data();
		}
	}
};
</script>
