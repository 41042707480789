import Joi from "joi";
import Validator from "@validators/validator";

const asset_alert_schema = Joi.object({
	id: Joi.string().required(),
	asset_id: Joi.string().required(),
	alert_type: Joi.string()
		.valid(
			"new_asset_imported_with_fields_to_review",
			"new_asset_imported",
			"systems_alert"
		)
		.required(),
	date: Joi.date().required(),
	is_archived: Joi.boolean().required(),
	extra_data: Joi.when("alert_type", {
		is: "new_asset_imported_with_fields_to_review",
		then: Joi.object({
			fields_to_review: Joi.array()
				.items(Joi.string().valid("coordinates", "pv_data"))
				.required()
		})
	}).when("alert_type", {
		is: "systems_alert",
		then: Joi.object({
			alert_ids: Joi.array().items(Joi.string()).required()
		})
	})
});
export default new Validator(asset_alert_schema);
