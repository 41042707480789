<template lang="html">
	<div>
		<div v-if="loading">
			<Loader size="25" />
		</div>
		<div
			v-else-if="
				asset_year_monthly_savings && asset_year_monthly_savings.length > 0
			"
		>
			<div>
				<span class="asset-monthly-savings-report-title">
					{{
						$t(
							"asset_reports.asset_monthly_savings_report.page3.year_monthly_savings"
						)
					}}
				</span>
			</div>

			<ColumnChart
				width="100%"
				:height="height_in_px || 300 + 'px'"
				:series="[
					{
						name: $t('asset_energy_panel.energy_self_consumption'),
						data: asset_year_monthly_savings.map(
							(item) => item.total_savings || null
						)
					}
				]"
				:categories="
					asset_year_monthly_savings.map((item) =>
						$format_date_to_month_string(item.month)
					)
				"
				:bar_colors="[asset_reports_color, '#cfd4d9']"
				:y_axis_formatter="(value) => $format_number(value) + ' €'"
				:x_axis_formatter="(value) => value"
				:show_toolbar="false"
				column_width="30px"
				:show_legend="false"
			/>

			<div class="flex-center-content">
				<div class="display-flex">
					<ChartCategory
						:asset_reports_color="asset_reports_color"
						:label="$t('asset_energy_panel.energy_self_consumption')"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import ColumnChart from "@sub_components/charts/ColumnChart";
import ChartCategory from "./ChartCategory";

import moment from "moment";

import AssetSavingsMonthlyFinderClass from "@src/asset-savings/application/asset-savings-monthly-finder";
const AssetSavingsMonthlyFinder = new AssetSavingsMonthlyFinderClass();

export default {
	name: "YearMonthlySavingsChart",
	components: {
		Loader,
		ColumnChart,
		ChartCategory
	},
	props: {
		height_in_px: Number,
		asset_id: String,
		from: Date,
		to: Date,
		asset_reports_color: String
	},
	data() {
		return {
			loading: true,
			asset_year_monthly_savings: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_savings =
				await AssetSavingsMonthlyFinder.get_asset_savings_monthly(
					this.asset_id
				);

			let month = this.from;
			let result = [];
			let bucle_prevent = 0;
			do {
				const energy_savings = asset_savings.find((item) =>
					moment(item.month).isSame(month, "month")
				);

				result.push(
					energy_savings || {
						month: month.toDate(),
						savings: null
					}
				);

				month = moment(month).add(1, "month");
				bucle_prevent++;
			} while (
				!moment(month).isSame(moment(this.to).add(1, "month"), "month") &&
				bucle_prevent < 24
			);

			this.asset_year_monthly_savings = result;

			return true;
		} catch (error) {
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style lang="css" scoped></style>
