<template>
	<div
		class="white-bg rounded-s p-sm display-flex-vertical-space-between gap-m"
	>
		<div class="rounded-xs p-sm grey3-bg flex-vertical-center gap-m">
			<div
				class="grey1-bg green1-color p-xs full-rounded flex-center-content"
				style="width: 50px; height: 50px"
				:class="main_icon_class"
			>
				<Icon :size="main_icon_size || 20" :icon="main_icon" />
			</div>
			<div class="display-flex-vertical-space-between grey1-color">
				<div class="font-700">
					<span class="font-xxl">{{ text1 }}</span>
					<span
						v-if="text1_sufix"
						v-html="text1_sufix"
						class="font-l p-l-xs"
					></span>
				</div>
				<div class="font-l font-bold">
					<span>{{ text1_1 }}</span>
				</div>
			</div>
		</div>

		<div
			v-if="text2 || text3"
			class="flex-with-same-cols-width gap-m grey1-color"
		>
			<div class="rounded-xs p-m grey3-bg">
				<div class="display-flex gap-m">
					<div class="flex-center-content">
						<Icon :icon="icon2" size="35" />
					</div>
					<div class="display-flex-vertical-space-between">
						<div class="font-700 font-l">
							<span>{{ text2 }}</span>
						</div>
						<div class="font-bold font-xs">
							<span>
								{{ text2_1 }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="rounded-xs p-m grey3-bg">
				<div class="display-flex gap-m">
					<div class="flex-center-content">
						<Icon :icon="icon3" size="35" />
					</div>
					<div class="display-flex-vertical-space-between">
						<div class="font-700 font-xl">
							<span>{{ text3 }}</span>
						</div>
						<div class="font-bold font-xs">
							{{ text3_1 }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-else-if="text4"
			class="grey1-color full-height grey3-bg rounded-xs flex-vertical-center gap-m p-sm"
		>
			<div>
				<Icon icon="cloud" size="25" />
			</div>
			<div class="font-bold">
				<span>{{ text4 }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AssetSavingsSummaryBox",
	props: {
		main_icon: String,
		main_icon_size: Number,
		main_icon_class: String,
		text1: String,
		text1_sufix: String,
		text1_1: String,
		icon2: String,
		text2: String,
		text2_1: String,
		icon3: String,
		text3: String,
		text3_1: String,
		text4: String
	}
};
</script>
