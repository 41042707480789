import AssetOtherDataUpdatorValidator from "@src/asset/domain/asset-other-data-updater-validator";
import AssetOtherDataFinderValidator from "@src/asset/domain/asset-other-data-finder-validator";
import AssetOtherDataUpdaterInterfaceClass from "@src/asset/infrastructure/asset-other-data-updater-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetOtherDataUpdaterInterfaceClass();
		this.update_validator = AssetOtherDataUpdatorValidator;
		this.finder_validator = AssetOtherDataFinderValidator;
	}

	async update_asset_other_data(
		asset_id,
		other_data_to_update,
		asset_new_image = null
	) {
		const validated_asset_other_data_to_update =
			this.update_validator.validate(other_data_to_update);

		try {
			const interface_result =
				await this.backend_interface.update_asset_other_data(
					asset_id,
					validated_asset_other_data_to_update,
					asset_new_image
				);

			const validated_result = this.finder_validator.validate(interface_result);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"update_asset_other_data",
				"general.errors.general_error",
				e
			);
		}
	}
}
