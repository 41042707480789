import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_consumption_data(
		energy_system_id,
		energy_system_consumption_data
	) {
		let formatted_data = this._convert_local_consumption_data_to_backend(
			energy_system_consumption_data
		);

		const url =
			"/api/energy-data/systems/" + energy_system_id + "/consumption-data";
		const backend_updated_data = await this.put(url, {
			body_params: formatted_data
		});

		const local_updated_energy_system =
			BackendEnergySystemConverter(backend_updated_data);

		return local_updated_energy_system;
	}

	_convert_local_consumption_data_to_backend(local_consumption_data) {
		let backend_consumption_data = {
			contractedPower: local_consumption_data.contracted_power,
			facilityType: local_consumption_data.facility_type,
			connectionType: local_consumption_data.connection_type,
			landRegistryReference: local_consumption_data.land_registry_reference,
			distributionCompany: local_consumption_data.distribution_company,
			energyFromGridPrice: local_consumption_data.energy_from_grid_price
		};

		return backend_consumption_data;
	}
}
