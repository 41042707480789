<template>
	<apexcharts
		v-if="series"
		ref="forecast_chart"
		width="100%"
		:height="height || '300px'"
		:options="chart_options"
		:series="series"
	></apexcharts>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
	name: "ColumnChart",
	props: {
		height: String,
		series: Array, //[{name: String, type: 'column' | 'line', data:[Number|null]}]
		categories: Array,
		bar_colors: Array,
		column_width: String || Array,
		rounded_shape: Boolean,
		data_labels_position: String,
		data_label_color: String,
		vertical_data_labels: Boolean,
		data_label_formatter: Function,
		multiple_y_axis: Boolean,
		yaxis: Object || Array,
		y_axis_formatter: Function,
		x_axis_formatter: Function,
		show_tooltip: Boolean,
		tooltip_value_formatter: Function, // (value, serie_name) => {return String}
		tooltip_hide_markers: Boolean,
		stroke: Number,
		show_toolbar: Boolean,
		show_legend: Boolean,
		legend_positon: String, //top | right | bottom | left
		legend_font_size_in_px: Number,
		custom_chart_options: Object
	},
	components: {
		apexcharts: VueApexCharts
	},
	computed: {
		chart_options() {
			const chart_type = this.series.find(
				(serie) => serie.type && serie.type == "line"
			)
				? "line"
				: "bar";

			var chart_options = {
				chart: {
					id: "basic-bar",
					type: chart_type,
					toolbar: {
						show: this.show_toolbar == false ? false : true,
						tools: { download: true }
					},
					zoom: {
						enabled: false
					},
					fontFamily: "'Montserrat', sans-serif"
				},
				xaxis: {
					labels: {
						show: this.x_axis_formatter ? true : false,
						formatter: this.x_axis_formatter || undefined
					},
					categories: this.categories,
					tooltip: {
						enabled: false
					}
				},
				yaxis: this.multiple_y_axis
					? this.yaxis
					: {
							show: this.y_axis_formatter ? true : false,
							showAlways: false,
							labels: {
								formatter: this.y_axis_formatter || undefined
							}
					  },
				plotOptions: {
					bar: {
						columnWidth: this.column_width || "70%",
						endingShape: this.rounded_shape ? "rounded" : "flat",
						dataLabels: {
							position: this.data_labels_position,
							orientation: this.vertical_data_labels ? "vertical" : "horizontal"
						}
					}
				},
				colors: this.bar_colors,
				tooltip: {
					enabled: this.show_tooltip ? true : false,
					y: {
						formatter: this.tooltip_value_formatter
							? (value, { series, seriesIndex, dataPointIndex, w }) => {
									return this.tooltip_value_formatter(
										value,
										this.series[seriesIndex].name
									);
							  }
							: undefined
					},
					marker: {
						show: this.tooltip_hide_markers ? false : true
					}
				},
				dataLabels: {
					enabled: this.data_label_formatter ? true : false,
					style: {
						colors: [this.data_label_color || "black"],
						fontSize: "16px",
						fontWeight: "bold"
					},
					formatter: this.data_label_formatter,
					offsetY: this.data_labels_position == "top" ? -30 : 0
				},
				legend: {
					show: this.show_legend != undefined ? this.show_legend : true,
					position: this.legend_positon || "bottom",
					fontSize: (this.legend_font_size_in_px || 16) + "px",
					fontFamily: "Montserrat",
					fontWeight: 600,
					labels: {
						colors: "var(--grey1)",
						useSeriesColors: false
					},
					markers: {
						width: this.legend_font_size_in_px || 16,
						height: this.legend_font_size_in_px || 16,
						radius: 4
					},
					itemMargin: {
						horizontal: 10
					}
				}
			};

			if (this.stroke) {
				chart_options.stroke = {
					show: true,
					width: this.stroke,
					colors: ["transparent"]
				};
			}

			if (this.custom_chart_options) {
				for (let key in this.custom_chart_options) {
					chart_options[key] = this.custom_chart_options[key];
				}
			}

			return chart_options;
		}
	}
};
</script>

<style>
.bar-chart-tooptip {
	padding: 15px;
	background-color: var(--grey1);
	color: white;
}
</style>
