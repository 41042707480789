<template>
	<div class="home-asset-in-list box-shadow1 rounded-xs white-bg">
		<div class="p-xs">
			<div>
				<div class="line-height-xxl display-flex">
					<div
						class="font-s black max_width_text full-width"
						style="width: 210px"
					>
						<span>
							{{ asset.name }}
						</span>
					</div>
				</div>
			</div>
			<div class="m-t-xs primary-color display-flex">
				<router-link
					:to="{
						name: 'asset-main-panel-weather',
						params: { asset_id: asset.id }
					}"
				>
					<Icon class="primary-color m-r-xs" icon="graph" />
				</router-link>
			</div>
		</div>

		<div class="full-height">
			<router-link
				:to="{
					name: $route.name,
					query: { asset_id: asset.id }
				}"
			>
				<div
					role="button"
					class="font-xxl full-height flex-center-content"
					:class="is_asset_selected ? 'primary-bg' : 'secondary-bg'"
				>
					<div>
						<Icon icon="chevron-right" />
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "AssetInList",
	props: { asset: Object, is_asset_selected: Boolean }
};
</script>

<style scoped>
.home-asset-in-list {
	display: grid;
	grid-template-columns: auto 25px;
}
</style>
