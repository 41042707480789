import BackendInterface from "@src/shared/infrastructure/backend-interface";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_system_custom_hourly(system_id) {
		const url = "/api/energy-data/systems/" + system_id + "/custom-monthly";
		const backend_data = await this.get(url);

		let local_data = {
			data: []
		};
		backend_data.data.forEach((item) => {
			local_data.data.push({
				month: moment(item.month).format("YYYY-MM"),
				energy_production: item.energyProduction,
				energy_consumption: item.energyConsumption,
				energy_self_consumption: item.energySelfConsumption
			});
		});

		return local_data;
	}
}
