import AssetWeatherForecastInterfaceClass from "../../infrastructure/forecast/asset-weather-forecast-interface";
import WeatherForecastValidator from "../../domain/forecast/weather-forecast-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.store_interface = new StoreInterfaceClass();
		this.backend_interface = new AssetWeatherForecastInterfaceClass();
		this.weather_forecast_validator = WeatherForecastValidator;
	}

	async get_asset_weather_forecast(asset_id) {
		const store_entity_name = "assets_weather_forecast_" + asset_id;

		const cached_data = this.store_interface.get(store_entity_name);
		if (cached_data) {
			cached_data.data.forEach((item) => {
				item.time = new Date(item.time);
			});
			return cached_data;
		}

		try {
			const interface_data =
				await this.backend_interface.get_asset_weather_forecast(asset_id);
			const validated_data =
				this.weather_forecast_validator.validate(interface_data);

			this.store_interface.set(
				store_entity_name,
				validated_data,
				moment().add(1, "hour").toDate()
			);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_weather_forecast",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_asset_weather_forecast_variable_daily_max_min(
		asset_id,
		variable_slug
	) {
		const weather_forecast_data = await this.get_asset_weather_forecast(
			asset_id
		);

		let weather_forecasts = weather_forecast_data.data;

		let daily_data = {};
		weather_forecasts.forEach((data) => {
			let day = moment(data.time).format("YYYYMMDD");
			if (!daily_data[day]) daily_data[day] = [];
			daily_data[day].push(data[variable_slug]);
		});

		let daily_max_min = {};
		for (var day in daily_data) {
			daily_max_min[day] = {
				max: Math.max.apply(null, daily_data[day]),
				min: Math.min.apply(null, daily_data[day])
			};
		}

		return daily_max_min;
	}
}
