import BackendLocationsEnergyMeasurementProvidersInterface from "@interfaces/backend-interfaces/backend-locations-energy-measurements-interface";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import moment from "moment";
import { store_entity_names } from "@config";

export default class {
	constructor() {
		this.backend_interface =
			new BackendLocationsEnergyMeasurementProvidersInterface();
		this.store_interface = new StoreInterfaceClass();

		this.user_energy_systems_store_entity_name =
			store_entity_names.user_energy_systems;
	}

	get_saved_from_aggregated(data) {
		const ENERGY_EQ_TO_1_YEAR_FAMILIES_CONSUMPTION_VALUE = 9922;
		let co2 = data.energy_production * 0.27;

		return {
			energy_eq_1_year_families:
				data.energy_production != null
					? Math.round(
							data.energy_production /
								ENERGY_EQ_TO_1_YEAR_FAMILIES_CONSUMPTION_VALUE
					  )
					: null,
			co2: Math.round(co2),
			wallet:
				data.energy_production != null ? data.energy_production * 0.133 : null,
			//car_km: Math.round((co2 * 100) / (2.6 * 6)),
			energy_consumption: data.energy_consumption,
			energy_self_consumption: data.energy_self_consumption,
			needed_trees_to_process_co2: Math.round(co2 * (1000 / (12 * 150)) * 0.55)
		};
	}

	// --- START PV panels ---
	async get_pv_panels_models() {
		const entity = "pv_panels_models";

		var result = this.store_interface.get(entity);
		if (!result) {
			try {
				result = await this.backend_interface.get_pv_panels_models();
				this.store_interface.set(
					entity,
					result,
					moment().add(1, "days").toDate()
				);
			} catch (e) {
				throw new AppError(
					"error_get_pv_panels_models",
					"general.errors.general_error",
					e
				);
			}
		}
		return result;
	}

	async get_pv_model_manufacturer(pv_model) {
		let manufacturers = await this.get_pv_panels_models();
		let result = null;

		for (let key in manufacturers) {
			let has_pv_model = manufacturers[key].models.find(
				(item) => item.id === pv_model
			);
			if (has_pv_model) {
				result = manufacturers[key].name;
				break;
			}
		}

		return result;
	}

	async get_pv_model_data(pv_panel_id) {
		let manufacturers = await this.get_pv_panels_models();
		let result = null;

		for (let key in manufacturers) {
			let has_pv_model = manufacturers[key].models.find(
				(item) => item.id === pv_panel_id
			);
			if (has_pv_model) {
				result = {
					manufacturer: manufacturers[key].name,
					pv_panel_id: has_pv_model.id,
					pv_panel_name: has_pv_model.name
				};
				break;
			}
		}

		return result;
	}
	// --- END PV panels ---
}
