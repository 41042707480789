import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendAssetConverter from "./backend-asset-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_asset(asset_id, asset_to_update) {
		const url = "/api/asset/" + asset_id;

		const backend_asset_to_update = {
			name: asset_to_update.name,
			coordinates: {
				lat: asset_to_update.coordinates.lat,
				lon: asset_to_update.coordinates.lon
			},
			customId: asset_to_update.custom_id || null
		};

		const backend_created_asset = await this.put(url, {
			body_params: backend_asset_to_update
		});

		const local_created_asset = BackendAssetConverter(backend_created_asset);

		return local_created_asset;
	}
}
