import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendUserConverter from "./converters/backend-user-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_user() {
		const url = "/api/user";
		const backend_user = await this.get(url);
		return BackendUserConverter(backend_user);
	}
}
