import Joi from "joi";
import Validator from "@validators/validator";

const consumption_data_schema = Joi.object({
	contracted_power: Joi.number().min(0),
	facility_type: Joi.string().valid(
		"industrial",
		"commercial",
		"residential",
		null
	),
	connection_type: Joi.string().valid(
		"wifi",
		"wifi_pcl_or_repeater",
		"ethernet",
		"4g",
		"other",
		"no_communication",
		null
	),
	land_registry_reference: Joi.string(),
	distribution_company: Joi.string(),
	energy_from_grid_price: Joi.number().min(0)
});
export default new Validator(consumption_data_schema);
