import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendUserConverter from "./converters/backend-user-converter";
import LocalUserConverter from "./converters/local-user-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_user(user) {
		const user_url = user.roles[0];
		let url;

		switch (user_url) {
			case "maintainer":
				url = "/api/maintainers";
				break;
			case "guest":
				url = "/api/guests";
				break;
			case "manager":
				url = "/api/manager";
				break;
		}
		url += "/" + user.id;

		const backend_user = LocalUserConverter(user);

		if (backend_user.unitSystem) delete backend_user.unitSystem;

		let returned_backend_user = await this.put(url, {
			body_params: backend_user
		});

		return BackendUserConverter(returned_backend_user);
	}
}
