<template>
	<div>
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<multiselect
			v-else-if="energy_systems"
			track_by="id"
			label="name"
			:multiple="multiple ? multiple : false"
			v-model="selected"
			:options="energy_systems"
			:allow_select_all="allow_select_all"
			:searchable="true"
			:close_on_select="false"
		></multiselect>
	</div>
</template>

<script>
import Loader from "@loader";

import Multiselect from "@form_components/Multiselect";

import EnergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder.js";
const EnergySystemsFinder = new EnergySystemsFinderClass();

export default {
	name: "LocationSelect",
	props: {
		value: [Array, String],
		multiple: Boolean,
		allow_select_all: Boolean,
		energy_systems_filter: Function
	},
	components: {
		Loader,
		Multiselect
	},
	data() {
		return {
			loading: false,
			energy_systems: null,
			selected: this.value
		};
	},
	async mounted() {
		this.loading = true;
		try {
			let result = await EnergySystemsFinder.get_user_energy_systems();

			if (this.energy_systems_filter) {
				result = result.filter((item) => this.energy_systems_filter(item));
			}

			var multiselect_energy_systems = [];
			for (var key in result) {
				const asset = result[key];

				multiselect_energy_systems.push({
					id: asset.id,
					name: asset.name
				});
			}

			this.energy_systems = multiselect_energy_systems;
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	watch: {
		selected: function (value) {
			this.$emit("input", value);
		},
		value: function (val) {
			this.selected = val;
		}
	}
};
</script>
