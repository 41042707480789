<template lang="html">
	<div>
		<table id="pv-report-page2-table">
			<tr class="text-center font-700">
				<td></td>
				<td v-if="report_has_estimated_energy_production_data">
					<span>
						{{ $t("location_pv_estimated_production.estimated_production") }}
					</span>
					<span> (kWh)</span>
				</td>
				<td v-if="report_has_energy_production">
					<span class="font-700">
						{{ $t("asset_reports.pv_report.page1.energy_production") }}
					</span>
					<span> (kWh)</span>
				</td>
				<td v-if="report_has_energy_production_variation">
					<span class="font-700">
						{{ $t("asset_reports.pv_report.page1.variation") }}
					</span>
					<span> (%)</span>
				</td>
			</tr>

			<tr v-for="(item, index) in table_data.data_months">
				<td class="font-700">
					{{ $t("general.months[" + item + "]") }}
				</td>
				<td
					v-if="report_has_estimated_energy_production_data"
					class="text-center asset-reports-grey-color"
				>
					<span v-if="table_data.estimated_energy_production[index] != null">
						{{
							$format_number(table_data.estimated_energy_production[index], 2)
						}}
					</span>
				</td>
				<td
					v-if="report_has_energy_production"
					class="text-center asset-reports-grey-color"
				>
					<span v-if="table_data.energy_production[index] != null">
						{{ $format_number(table_data.energy_production[index], 2) }}
					</span>
				</td>
				<td
					v-if="report_has_energy_production_variation"
					class="text-center asset-reports-grey-color"
				>
					<span v-if="table_data.energy_production_variation[index] != null">
						{{
							$format_number(table_data.energy_production_variation[index], 2)
						}}%
					</span>
				</td>
			</tr>

			<tr v-if="table_total">
				<td class="font-700">
					{{ $t("asset_reports.pv_report.page1.selected_period_total") }}
				</td>
				<td
					v-if="table_total.estimated_energy_production"
					class="text-center font-700"
				>
					{{ $format_number(table_total.estimated_energy_production, 2) }}
				</td>
				<td v-if="table_total.energy_production" class="text-center font-700">
					{{ $format_number(table_total.energy_production, 2) }}
				</td>
				<td
					v-if="table_total.energy_production_variation"
					class="text-center font-700"
				>
					{{ $format_number(table_total.energy_production_variation, 2) }}%
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	name: "SelectedPeriodTable",
	props: {
		table_data: Object
	},
	data() {
		return {
			table_total: null
		};
	},
	mounted() {
		let formatted_data = [];
		let table_total = {
			energy_production: 0,
			energy_production_variation: 0,
			estimated_energy_production: 0
		};

		for (let index in this.table_data.data_dates) {
			table_total.energy_production += this.table_data.energy_production[index];

			table_total.estimated_energy_production +=
				this.table_data.estimated_energy_production[index];
		}

		table_total.energy_production_variation +=
			((table_total.energy_production -
				table_total.estimated_energy_production) *
				100) /
			table_total.estimated_energy_production;

		this.table_total = table_total;
	},
	computed: {
		report_has_estimated_energy_production_data() {
			if (
				this.table_data &&
				this.table_data.estimated_energy_production &&
				this.table_data.estimated_energy_production.length > 0
			) {
				for (let index in this.table_data.estimated_energy_production) {
					if (this.table_data.estimated_energy_production[index]) return true;
				}
			}

			return null;
		},
		report_has_energy_production() {
			if (
				this.table_data &&
				this.table_data.energy_production &&
				this.table_data.energy_production.length > 0
			) {
				for (let index in this.table_data.energy_production) {
					if (this.table_data.energy_production[index]) return true;
				}
			}

			return null;
		},
		report_has_energy_production_variation() {
			if (
				this.table_data &&
				this.table_data.energy_production_variation &&
				this.table_data.energy_production_variation.length > 0
			) {
				for (let index in this.table_data.energy_production_variation) {
					if (this.table_data.energy_production_variation[index]) return true;
				}
			}

			return null;
		}
	}
};
</script>
