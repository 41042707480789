import Joi from "joi";
import Validator from "@validators/validator";

const faulty_equipment_schema = Joi.object({
	id: Joi.string(),
	name: Joi.string().required()
});

export default new Validator(
	faulty_equipment_schema,
	"ticketing.ticketing_entities_props."
);
