<template>
	<div class="full-width full-height flex-center-content">
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>

		<div v-else class="flex-auto-full-height-content">
			<div v-if="asset_address" class="header">
				<span> {{ asset_address }}</span>
			</div>
			<div class="content">
				<div
					v-if="asset_image_style"
					v-bind:style="asset_image_style"
					class="full-width full-height relative"
				>
					<img
						v-if="assets_watermark"
						id="assets_watermark"
						:src="assets_watermark"
					/>
				</div>
				<Map
					v-else-if="asset && asset.coordinates"
					:default_center="asset.coordinates"
					:default_zoom="17"
					map_style="satellite"
					:disable_map_style_selector="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Map from "@sub_components/Map";

import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder.js";
import UserFinderClass from "@src/user/application/user-finder.js";

const AssetOtherDataFinder = new AssetOtherDataFinderClass();
const UserFinder = new UserFinderClass();

export default {
	name: "AssetImage",
	components: {
		Loader,
		Alert,
		Map
	},
	props: {
		asset: Object
	},
	data() {
		return {
			loading: true,
			assets_watermark: null,
			asset_image_url: null,
			asset_image_style: null,
			asset_address: null,
			error: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const user = await UserFinder.get_user();
			if (user.assets_watermark) this.assets_watermark = user.assets_watermark;

			const asset_id = this.asset.id;
			const asset_other_data = await AssetOtherDataFinder.get_asset_other_data(
				asset_id
			);

			if (asset_other_data.asset_image_url) {
				this.asset_image_url = asset_other_data.asset_image_url;

				this.asset_image_style = {
					background: "url('" + this.asset_image_url + "') no-repeat center",
					"background-size": "cover"
				};
			}

			if (asset_other_data.address)
				this.asset_address = asset_other_data.address;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style scoped>
#assets_watermark {
	position: absolute;
	bottom: 10px;
	right: 25px;
	max-height: 20%;
	max-width: 30%;
}
</style>
