<template lang="html">
	<div>
		<Multiselect
			:multiple="false"
			:options="timezones"
			:searchable="true"
			:placeholder="$t('user_settings.user_entity.timezone')"
			v-model="input_value"
			v-bind:class="{
				'danger-border': has_error
			}"
		/>
	</div>
</template>

<script>
import Multiselect from "@form_components/Multiselect";

import EntityServiceClass from "@services/entity-service";
const EntityService = new EntityServiceClass();

export default {
	name: "TimezoneMultiselect",
	components: {
		Multiselect
	},
	props: { value: String, has_error: Boolean },
	data() {
		return {
			input_value: this.value,
			timezones: EntityService.get_entity("timezones")
		};
	},
	methods: {
		emit_input_value() {
			this.$emit("input", this.input_value);
		}
	},
	watch: {
		input_value() {
			this.emit_input_value();
		}
	}
};
</script>
