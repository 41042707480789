import UserFinderInterfaceClass from "../infrastructure/user-finder-interface";
import UserFinderValidator from "../domain/user-finder-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import moment from "moment";
import { store_entity_names } from "@config";

export default class {
	constructor() {
		this.backend_interface = new UserFinderInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
		this.validator = UserFinderValidator;
	}

	async get_user(force_download = null) {
		const store_entity_name = store_entity_names.user_data;

		const cached_data = this.store_interface.get(store_entity_name);
		if (cached_data && !force_download) return cached_data;

		try {
			const interface_data = await this.backend_interface.get_user();

			const validated_data = this.validator.validate(interface_data);

			this.store_interface.set(
				store_entity_name,
				validated_data,
				moment().add(7, "days").toDate()
			);

			return validated_data;
		} catch (e) {
			throw new AppError("error_get_user", "general.errors.general_error", e);
		}
	}

	get_stored_user() {
		return this.store_interface.get(store_entity_names.user_data);
	}
}
