<template>
	<div>
		<div v-if="loading">
			<Loader size="25" />
		</div>
		<div v-else>
			<table
				style="
					display: table;
					width: 100%;
					table-layout: fixed;
					font-size: 12px;
					border-collapse: separate;
					border-spacing: 10px 5px;
				"
			>
				<thead class="text-center">
					<th></th>
					<th>
						<span class="font-700">
							{{ $t("asset_reports.shared_components.energy_production") }}
							(kWh)
						</span>
					</th>
					<th>
						<span class="font-700">
							{{ $t("asset_reports.shared_components.monthly_percentage") }} (%)
						</span>
					</th>
				</thead>
				<tbody>
					<tr v-for="item in filter_asset_monthly_energy">
						<td>
							<span class="font-700">
								{{ $format_date_to_month_string(item.time) }}
							</span>
						</td>
						<td class="text-center asset-reports-grey-color">
							<span class="asset-reports-grey-color">{{
								$format_number(item.energy_production, 2)
							}}</span>
						</td>
						<td class="text-center asset-reports-grey-color">
							<span class="asset-reports-grey-color">
								{{
									$format_number(
										(item.energy_production * 100) /
											filter_total_energy_production,
										1
									)
								}}
							</span>
						</td>
					</tr>
					<tr class="font-700">
						<td>
							{{ $t("asset_reports.shared_components.selected_period_total") }}
						</td>
						<td class="text-center">
							<span>
								{{ $format_number(filter_total_energy_production) }}
							</span>
						</td>
						<td class="text-center">
							<span>100</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();

export default {
	name: "ReportAssetGenerationTable",
	props: {
		asset_id: String,
		from: Date,
		to: Date
	},
	data() {
		return {
			loading: true,
			filter_asset_monthly_energy: null,
			filter_total_energy_production: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_monthly_energy =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy(this.asset_id);

			this.filter_asset_monthly_energy = asset_monthly_energy.filter((item) => {
				const time = item.time;
				return (
					moment(time).isSameOrAfter(this.from) &&
					moment(time).isSameOrBefore(this.to)
				);
			});

			this.filter_total_energy_production = 0;
			this.filter_asset_monthly_energy.forEach((item) => {
				this.filter_total_energy_production += item.energy_production;
			});
		} catch (error) {
		} finally {
			this.loading = false;
		}
	}
};
</script>
