import EnergySystemsFinderInterfaceClass from "../infrastructure/asset-energy-systems-finder-interface";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator";

import StoreUtilClass from "../../shared/application/utils/store-util";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new EnergySystemsFinderInterfaceClass();
		this.validator = EnergySystemFinderValidator;
		this.store_util = null;
	}

	async get_asset_energy_systems(asset_id, force_download = null) {
		try {
			const store_entity_name = "asset_energy_systems_" + asset_id;
			this.store_util = new StoreUtilClass(store_entity_name, 50, "id");

			var result = await this.store_util.get_items();
			if (result && !force_download) return result;

			const interface_data =
				await this.backend_interface.get_asset_energy_systems(asset_id);

			result = [];
			interface_data.forEach((item) => {
				const validated_item = this.validator.validate(item);
				result.push(validated_item);
			});

			await this.store_util.save_items(result, moment().endOf("day").toDate());

			return await this.store_util.get_items();
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_systems",
				"general.errors.general_error",
				e
			);
		}
	}
}
