import EnergyProvidersData from "../../domain/energy-providers-data.js";

export default {
	get_all_energy_measurement_providers_slugs() {
		return Object.keys(EnergyProvidersData);
	},

	get_energy_measurement_provider_full_name(provider_slug) {
		return EnergyProvidersData[provider_slug].full_name;
	},

	get_energy_measurement_provider_img_src(provider_slug) {
		return EnergyProvidersData[provider_slug].img_src;
	}
};
