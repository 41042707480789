<template>
	<div class="flex-center-content flex-row-wrap gap-m p-l-m p-r-m">
		<div
			v-for="variable_data in variables_data"
			v-on:click="toogle_variable(variable_data.variable_slug)"
			class="flex-vertical-center gap-xs"
			:class="{
				'cursor-pointer':
					Object.keys(custom_legend_variables_to_show).length > 1
			}"
			:style="{
				opacity: custom_legend_variables_to_show[variable_data.variable_slug]
					? 1
					: 0.45
			}"
		>
			<div v-if="variable_data.dashed_number" class="display-flex gap-xxs">
				<div
					v-for="index in 3"
					class="full-rounded"
					style="
						height: 4px;
						width: 4px;
						border-width: 0px;
						border-color: rgb(255, 255, 255);
					"
					:style="{
						backgroundColor: variable_data.color
					}"
				></div>
			</div>
			<div
				v-else
				style="
					height: 16px;
					width: 16px;
					border-width: 0px;
					border-color: rgb(255, 255, 255);
					border-radius: 4px;
				"
				:style="{
					backgroundColor: variable_data.color
				}"
			></div>
			<div class="no-wrap">
				{{ $t(variable_data.name) }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LineChartCustomLegend",
	props: {
		variables_data: Array,
		custom_legend_variables_to_show: Object
	},
	methods: {
		toogle_variable(variable) {
			this.$emit("toogle_variable", variable);
		}
	}
};
</script>
