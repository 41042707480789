import Joi from "joi";
import Validator from "@validators/validator";

const alert_schema = Joi.object({
	date_from: Joi.date(),
	date_to: Joi.date(),
	is_archived: Joi.boolean(),
	alert_types: Joi.array().items(
		Joi.string().valid(
			"new_asset_imported_with_fields_to_review",
			"new_asset_imported",
			"systems_alert"
		)
	),
	assets: Joi.array().items(Joi.string()),
	order_by: Joi.string().valid("date", "severity"),
	order: Joi.string().valid("asc", "desc")
});

export default new Validator(alert_schema);
