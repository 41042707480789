const Joi = require("joi");
import Validator from "@validators/validator";

const estimated_production_schema = Joi.object({
	energy_production_summary: Joi.object({
		energy_unit: Joi.string(),
		selected_period_energy_production: Joi.array().items({
			date: Joi.string(),
			estimated_energy_production: Joi.number()
				.min(0)
				.allow(null),
			energy_production: Joi.number()
				.min(0)
				.allow(null),
			energy_production_variation: Joi.number().allow(null)
		}),
		selected_period_energy_production_summary: Joi.object({
			energy_production: Joi.number(),
			energy_production_peak_power_ratio: Joi.number(),
			energy_production_variation: Joi.number(),
			estimated_energy_production: Joi.number()
		}),
		from_start_of_available_data_to_end_of_selected_period_energy_production_summary: Joi.object(
			{
				energy_production: Joi.number(),
				energy_production_eq_1_year_families_consumption: Joi.number(),
				energy_production_variation: Joi.number(),
				estimated_energy_production: Joi.number()
			}
		),
		from_year_start_to_end_of_selected_period_energy_production_summary: Joi.object(
			{
				energy_consumption: Joi.number(),
				energy_production: Joi.number(),
				energy_self_consumption: Joi.number(),
				energy_self_consumption_eq_1_year_families_consumption: Joi.number()
			}
		)
	}),
	savings_summary: Joi.object({
		mass_unit: Joi.string(),
		selected_period_savings: Joi.array().items(
			Joi.object({
				date: Joi.date(),
				c02: Joi.number().allow(null),
				petrol: Joi.number().allow(null)
			})
		),
		selected_period_savings_summary: Joi.object({
			c02: Joi.number().allow(null),
			mass_unit: Joi.string(),
			petrol: Joi.number().allow(null)
		}),
		from_start_of_available_data_to_end_of_selected_period_savings_summary: Joi.object(
			{
				c02: Joi.number().allow(null),
				mass_unit: Joi.string(),
				petrol: Joi.number().allow(null)
			}
		)
	})
});

export default new Validator(estimated_production_schema);
