<template lang="html">
	<div>
		<div class="border-b font-bold">
			{{ $t("ticketing.settings.paid_by_settings") }}
		</div>

		<div class="full-width relative">
			<div
				v-if="paid_by_to_delete"
				id="remove-element"
				class="full-width full-height"
			>
				<div class="rounded-s overflow-hidden">
					<PageHeader :text="$t('general.delete')" />

					<div v-if="loading_delete" class="p-sm white-bg">
						<Loader size="30" />
					</div>

					<div v-else class="white-bg full-width">
						<div class="p-sm">
							<div v-if="delete_error">
								<Alert :message="delete_error" />
							</div>

							<div>
								{{
									$t("ticketing.settings.remove_element_confirmation", {
										element_name: paid_by_to_delete.name
									})
								}}
							</div>

							<div v-if="available_paid_by_to_replace_with" class="m-t">
								<div class="">
									<span>
										{{ $t("ticketing.settings.replace_status_with") }}:
									</span>
								</div>
								<Multiselect
									:multiple="false"
									:options="available_paid_by_to_replace_with"
									:searchable="true"
									:placeholder="
										$t('ticketing.settings.select_status_placeholder')
									"
									v-model="replace_paid_by_with"
									label="name"
									track_by="id"
								/>
							</div>
						</div>

						<hr class="nomargin" />

						<div class="p-sm">
							<div class="right">
								<div
									class="btn btn-primary full-width"
									v-on:click="remove_paid_by(paid_by_to_delete)"
								>
									{{ $t("general.delete") }}
								</div>
							</div>
							<div class="right m-r">
								<div
									class="btn btn-danger full-width"
									v-on:click="paid_by_to_delete = null"
								>
									{{ $t("general.cancel") }}
								</div>
							</div>
							<div class="clear"></div>
						</div>
					</div>
				</div>
			</div>

			<div id="content" class="m-t">
				<div v-if="loading">
					<Loader size="30" />
				</div>
				<div v-else>
					<div v-if="error">
						<Alert :message="error" />
					</div>
					<div v-if="success_message">
						<Alert type="success" :message="success_message" />
					</div>

					<div v-if="user_ticketing_paid_by">
						<table class="full-width">
							<tr v-for="(paid_by, paid_by_id) in user_ticketing_paid_by">
								<td class="p-t p-b p-r">
									<input
										type="text"
										v-model="paid_by.name"
										class="form-control"
									/>
								</td>
								<td class="button-width p-t p-b text-right">
									<div
										class="btn btn-primary full-width"
										v-on:click="update_paid_by(paid_by)"
									>
										{{ $t("general.save") }}
									</div>
								</td>

								<td class="button-width p-l">
									<div
										class="btn btn-danger full-width"
										v-on:click="paid_by_to_delete = paid_by"
									>
										{{ $t("general.delete") }}
									</div>
								</td>
							</tr>
							<tr>
								<td class="p-t p-b p-r">
									<input
										type="text"
										v-model="new_paid_by.name"
										:placeholder="$t('ticketing.settings.add_new_paid_by')"
										class="form-control"
									/>
								</td>
								<td class="button-width p-t p-b text-right">
									<div
										class="btn btn-primary full-width"
										v-on:click="create_paid_by(new_paid_by)"
									>
										{{ $t("general.add_new") }}
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import Multiselect from "@form_components/Multiselect";

import TicketServiceClass from "@services/user-services/ticket-service";
const TicketService = new TicketServiceClass();

export default {
	name: "TicketingPaidBySettings",
	components: {
		Loader,
		Alert,
		PageHeader,
		Multiselect
	},
	data() {
		return {
			loading: null,
			error: null,
			success_message: false,
			user_ticketing_paid_by: null,
			new_paid_by: {},
			paid_by_to_delete: null,
			loading_delete: false,
			delete_error: null,
			replace_paid_by_with: null
		};
	},
	mounted() {
		this.load_user_ticketing_paid_by();
	},
	methods: {
		async load_user_ticketing_paid_by() {
			this.loading = true;
			this.error = null;
			try {
				var user_ticketing_paid_by = await TicketService.get_ticketing_paid_by();
				this.user_ticketing_paid_by = JSON.parse(
					JSON.stringify(user_ticketing_paid_by)
				);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async update_paid_by(paid_by) {
			this.loading = true;
			this.error = null;

			try {
				await TicketService.update_ticketing_paid_by(paid_by);

				await this.load_user_ticketing_paid_by();

				this.success_message = "general.update_successful";
				setTimeout(() => {
					this.success_message = false;
				}, 10000);
			} catch (e) {
				this.error = e;
				this.loading = false;
			}
		},
		async create_paid_by(new_paid_by) {
			this.loading = true;
			this.error = null;

			try {
				await TicketService.create_ticketing_paid_by(new_paid_by);

				await this.load_user_ticketing_paid_by();

				this.new_paid_by = {};
				this.success_message = "general.created_successful";
				setTimeout(() => {
					this.success_message = false;
				}, 10000);
			} catch (e) {
				this.error = e;
				this.loading = false;
			}
		},
		async remove_paid_by(paid_by) {
			this.loading_delete = true;
			this.delete_error = null;

			try {
				await TicketService.remove_ticketing_paid_by(
					paid_by.id,
					this.replace_paid_by_with
				);
				this.paid_by_to_delete = null;

				await this.load_user_ticketing_paid_by();
			} catch (e) {
				this.delete_error = e;
				setTimeout(() => {
					this.delete_error = null;
				}, 8000);
			} finally {
				this.loading_delete = false;
			}
		}
	},
	computed: {
		available_paid_by_to_replace_with() {
			if (!this.user_ticketing_paid_by) return null;
			else if (!this.paid_by_to_delete) return this.user_ticketing_paid_by;
			else {
				let result = [];

				for (let index in this.user_ticketing_paid_by) {
					const paid_by = this.user_ticketing_paid_by[index];

					if (paid_by != this.paid_by_to_delete) result.push(paid_by);
				}

				return result;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#content {
	width: 600px;
}
.button-width {
	width: 150px;
}
#remove-element {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
}
#remove-element > div {
	position: absolute;
	width: 400px;
	left: calc(50% - 200px);
	top: 40px;
}
</style>
