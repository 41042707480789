import Joi from "joi";
import Validator from "@validators/validator";

import EnergyDataProviderSlugValidator from "@src/energy-provider-auth/domain/energy-data-provider-slug-validator";
import PowerMeasurementValidator from "@src/asset-energy-data/domain/power-measurement-validator.js";

const schema = Joi.object({
	system_id: Joi.string().required(),
	status_provided: Joi.string().valid(
		"ok",
		"offline",
		"faulty",
		"unavailable",
		"unsupported"
	),
	provider_name: EnergyDataProviderSlugValidator.schema,
	alert_count: Joi.number(),
	latest_measurements: Joi.object({
		today_energy_production: Joi.number().allow(null),
		latest_power_measurements: PowerMeasurementValidator.schema.allow(null)
	}),
	pv_data: Joi.object({
		pr: Joi.number()
	}),
	system_info: Joi.object({
		has_battery: Joi.boolean(),
		has_pv_generation: Joi.boolean(),
		has_consumption: Joi.boolean(),
		has_grid_interaction: Joi.boolean()
	}),
	devices_status_summary: Joi.object({
		total: Joi.number(),
		online: Joi.number()
	})
});
export default new Validator(schema);
