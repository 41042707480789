module.exports = (number1, number2, operator) => {
	const operators = {
		"=": (a, b) => {
			return a == b;
		},
		">": (a, b) => {
			if (!a && b) return false;
			else if (a && !b) return true;
			else if (!a && !b) return 0;
			else return a > b;
		},
		"<": (a, b) => {
			if (!a && b) return true;
			else if (a && !b) return false;
			else if (!a && !b) return 0;
			else return a < b;
		},
		"<=": (a, b) => {
			if (!a && b) return true;
			else if (a && !b) return false;
			else if (!a && !b) return 0;
			else return a <= b;
		},
		">=": (a, b) => {
			if (!a && b) return false;
			else if (a && !b) return true;
			else if (!a && !b) return 0;
			else return a >= b;
		}
	};

	return operators[operator](number1, number2);
};
