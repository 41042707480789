<template>
	<div>
		<div class="p-b-xxs border-b font-bold display-flex-space-between">
			<div>
				{{ $t("entities.energy_system.pv_data.pv_panel_models_label") }}
			</div>
			<div>
				<div v-if="!loading && !error" v-on:click="add_pv_panel">
					<RoundedButton class="left m-l" icon="plus" :size="25" />
				</div>
			</div>
		</div>

		<div>
			<div v-if="loading" class="p-t-xl">
				<Loader />
			</div>
			<div v-else-if="error">
				<Alert type="danger" :message="error" />
			</div>
			<div v-else-if="pv_panels && pv_panels.length > 0">
				<div
					class="panels-row m-t-sm"
					v-for="(panel, index) in pv_panels"
					v-bind:key="'panels_' + index"
				>
					<div class="flex-center-content">
						<div v-on:click="remove_pv_panel(index)">
							<RoundedButton class="margin-center" icon="minus" :size="25" />
						</div>
					</div>
					<div>
						<select
							class="form-control"
							@change="delete panel.id"
							v-model="pv_panels_manufacturers[index]"
						>
							<option></option>
							<option
								v-for="(item, index) in Object.keys(
									pv_panels_manufacturers_entity
								)"
								v-bind:key="'pv_panel_manufactureres_' + index"
							>
								{{ item }}
							</option>
						</select>
					</div>
					<div>
						<select
							v-if="pv_panels_manufacturers[index]"
							class="form-control"
							v-model="panel.id"
						>
							<option></option>
							<option
								v-for="(item, index) in pv_panels_manufacturers_entity[
									pv_panels_manufacturers[index]
								]"
								v-bind:key="'pv_panel_models_' + index"
								:value="item.id"
							>
								{{ item.name }}
							</option>
						</select>
					</div>
					<div>
						<input
							type="number"
							class="form-control text-center"
							:placeholder="
								$t('entities.energy_system.pv_data.pv_panel_models.quantity')
							"
							v-model.number="panel.quantity"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RoundedButton from "@form_components/buttons/RoundedButton";
import Loader from "@components/sub_components/Loader";
import Alert from "@alert";

import LocationEnergyMeasurementProvidersServiceClass from "@services/location-services/location-energy-measurement-providers-service";
const LocationProviderService =
	new LocationEnergyMeasurementProvidersServiceClass();

export default {
	name: "EditPvPanels",
	components: {
		RoundedButton,
		Loader,
		Alert
	},
	props: {
		value: Array
	},
	data() {
		return {
			loading: false,
			error: null,
			pv_panels: this.value || [],
			pv_panels_manufacturers: [],
			pv_panels_manufacturers_entity: null
		};
	},
	async created() {
		try {
			this.loading = true;
			await this.load_pv_panels_manufacturers_entity();
			await this.load_pv_panels_manufacturers();
		} catch (e) {
			this.error = "general.errors.general_error";
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async load_pv_panels_manufacturers_entity() {
			const pv_panels_manufacturers_entity =
				await LocationProviderService.get_pv_panels_models();

			this.pv_panels_manufacturers_entity = {};
			pv_panels_manufacturers_entity.forEach((item) => {
				this.pv_panels_manufacturers_entity[item.name] = item.models;
			});
		},
		async load_pv_panels_manufacturers() {
			for (let key in this.pv_panels) {
				const panel_id = this.pv_panels[key].id;
				const panel_manufacturer =
					await LocationProviderService.get_pv_model_manufacturer(panel_id);

				this.pv_panels_manufacturers[key] = panel_manufacturer;
			}
		},
		update_input() {
			this.$emit("input", this.pv_panels);
		},
		add_pv_panel() {
			if (Array.isArray(this.pv_panels)) {
				this.pv_panels.push({});
				this.pv_panels_manufacturers.push({});
			} else {
				this.pv_panels = [{}];
				this.pv_panels_manufacturers = [{}];
			}
			this.update_input();
		},
		remove_pv_panel(index) {
			this.pv_panels.splice(index, 1);
			this.pv_panels_manufacturers.splice(index, 1);
			this.update_input();
		}
	}
};
</script>

<style scoped>
.panels-row {
	display: grid;
	grid-template-columns: 40px 2fr 2fr 1fr;
	column-gap: 15px;
}
</style>
