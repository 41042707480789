import RestInterface from "./rest-interface";
import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import { api_url as backend_api_url, store_entity_names } from "@config";

import RestError from "@classes/errors/rest_error";
import RestAuthenticationError from "@classes/errors/rest-authentication-error";
import AppError from "@classes/errors/app_error";

export default class BackendInterface {
	constructor() {
		this.rest_interface = new RestInterface();
		this.backend_url = backend_api_url;

		this.store_interface = new StoreInterfaceClass();
		this.user_token_entity_name = store_entity_names.user_token;
		this.api_token_entity_name = store_entity_names.api_token;
	}

	async get(url, args = null) {
		return await this._rest_method("get", url, args);
	}

	async post(url, args = null) {
		return await this._rest_method("post", url, args);
	}

	async put(url, args = null) {
		return await this._rest_method("put", url, args);
	}

	async delete(url, args = null) {
		return await this._rest_method("delete", url, args);
	}

	async _rest_method(method_name, url, args = null) {
		let params = {};

		if (args && args.body_params) params.body_params = args.body_params;
		if (args && args.query_params) params.query_params = args.query_params;
		if (args && args.files) params.files = args.files;

		const user_token = args && args.token ? args.token : this._get_user_token();
		if (user_token) {
			params.headers = {
				TOKEN: user_token
			};
		} else {
			const api_token = this._get_api_token();
			if (api_token) {
				params.headers = {
					"X-AUTH-TOKEN": api_token
				};
			}
		}

		try {
			const result =
				Object.keys(params).length != 0
					? await this.rest_interface[method_name](
							this.backend_url + url,
							params
					  )
					: await this.rest_interface[method_name](this.backend_url + url);

			return result;
		} catch (error) {
			const reported_error = this._check_rest_error(error);
			throw reported_error;
		}
	}

	_get_user_token() {
		return this.store_interface.get(this.user_token_entity_name);
	}

	_get_api_token() {
		return this.store_interface.get(this.api_token_entity_name);
	}

	_check_rest_error(rest_error) {
		if (rest_error.response && rest_error.response.status) {
			if (rest_error.response.status == 401)
				return new RestAuthenticationError(
					rest_error.response.status,
					rest_error.response.message || undefined
				);
			else
				return new RestError(
					rest_error.response.status,
					rest_error.response.message || undefined
				);
		} else {
			return new AppError(500, "generar.errors.general_error", rest_error);
		}
	}

	async get_items_with_offset(
		url,
		multiple_async_calls = false,
		rest_params = null
	) {
		const _get_results_with_offset = async (offset) => {
			let cloned_rest_params = rest_params
				? JSON.parse(JSON.stringify(rest_params))
				: null;

			if (cloned_rest_params) {
				if (cloned_rest_params.query_params) {
					cloned_rest_params.query_params.offset = offset;
				} else cloned_rest_params.query_params = { offset: offset };
			} else {
				cloned_rest_params = { query_params: { offset: offset } };
			}

			return await this.get(url, cloned_rest_params);
		};

		let backend_items = [];
		if (multiple_async_calls) {
			const first_page = await _get_results_with_offset(0);

			backend_items = backend_items.concat(first_page.data);

			if (!first_page.offsetNext) return backend_items;

			let backend_pagination_data = {};
			var items_per_page = first_page.offsetNext;
			var total_items = first_page.totalElements;

			var total_pages = Math.floor(total_items / items_per_page);
			var rest = total_items % items_per_page;
			if (rest) total_pages++;

			var next_page = 2;
			const _get_next_page_data = async () => {
				if (!next_page) return;

				const request_page = next_page;
				const offset = (request_page - 1) * items_per_page;
				next_page = next_page + 1 > total_pages ? null : next_page + 1;

				const page_backend_data = await _get_results_with_offset(offset);
				backend_pagination_data[request_page] = page_backend_data.data;

				return _get_next_page_data();
			};

			let promises = [];
			for (let index = 0; index < multiple_async_calls; index++) {
				promises.push(_get_next_page_data());
			}
			await Promise.all(promises);

			for (let key in backend_pagination_data) {
				backend_items = backend_items.concat(backend_pagination_data[key]);
			}
		} else {
			var next_offset = 0;
			while (next_offset != null) {
				let result = await _get_results_with_offset(next_offset);
				backend_items = backend_items.concat(result.data);

				next_offset = result.offsetNext;
			}
		}
		return backend_items;
	}
}
