import BackendInterface from "./backend-interface";
import BackendEnergyMeasurementProvidersUserDataConverter from "@interfaces/backend-interfaces/converters/backend-energy-measurement-providers-user-data-converter";

import BackendEnergyMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-energy-measurement-converter";

import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

import RestError from "@classes/errors/rest_error";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
		this.backend_user_energy_measurement_providers_data_converter =
			new BackendEnergyMeasurementProvidersUserDataConverter();
	}

	async get_pv_location_current_pv_power(location_id) {
		const url =
			this.backend_url + "/api/location/" + location_id + "/power-data/instant";

		try {
			const backend_instant_power = await this.rest_interface.get(url);

			const converted_instant_power = this._convert_backend_power(
				backend_instant_power
			);

			return converted_instant_power;
		} catch (e) {
			if (e instanceof RestError && e.code == 409) return null;
			else throw e;
		}
	}

	async get_location_energy_data_first_date(location_id) {
		const url =
			this.backend_url +
			"/api/location/" +
			location_id +
			"/energy-data/processed-data-first-day";
		const backend_result = await this.rest_interface.get(url);

		const backend_location_energy_data_first_date =
			backend_result.historicalFirstDay;
		const result = new Date(backend_location_energy_data_first_date);
		return result;
	}

	async get_location_daily_agregated_energy(location, from, to) {
		const url =
			this.backend_url +
			"/api/location/" +
			location.id +
			"/energy-data/daily/aggregated";
		let params = {
			from: from ? moment(from).format("YYYY-MM-DD") : null,
			to: to ? moment(to).format("YYYY-MM-DD") : null
		};
		let result = await this.rest_interface.get(url, params);

		const converted_data =
			BackendEnergyMeasurementConverter.convert_backend_energy(result);

		return converted_data;
	}

	_convert_backend_power(backend_obj) {
		function round_number_2_decimals(number) {
			return Math.round((Math.abs(number) + Number.EPSILON) * 100) / 100;
		}

		let result = {
			time: backend_obj.time ? new Date(backend_obj.time) : null,
			power_production:
				backend_obj.powerProduction != null
					? round_number_2_decimals(backend_obj.powerProduction)
					: null,
			power_consumption:
				backend_obj.powerConsumption != null
					? round_number_2_decimals(backend_obj.powerConsumption)
					: null,

			rate_self_consumption: backend_obj.rateSelfConsumption || null,
			rate_self_sufficiency: backend_obj.rateSelfSufficiency || null,
			power_from_grid:
				backend_obj.powerFromGrid != null
					? round_number_2_decimals(backend_obj.powerFromGrid)
					: null,
			power_to_grid:
				backend_obj.powerToGrid != null
					? round_number_2_decimals(backend_obj.powerToGrid)
					: null,
			power_self_consumption:
				backend_obj.powerSelfConsumption != null
					? round_number_2_decimals(backend_obj.powerSelfConsumption)
					: null
		};

		return result;
	}

	/* Location monthly energy */
	async get_location_monthly_energy(location, from, to) {
		const url =
			this.backend_url +
			"/api/location/" +
			location.id +
			"/energy-data/monthly";

		let results = [];

		let continue_progress = true;
		let next_from_request = moment(from);
		while (continue_progress) {
			let next_to_request = moment(next_from_request).add(11, "months");
			if (moment(next_to_request).isAfter(to, "month")) {
				continue_progress = false;
				next_to_request = to;
			}

			let request_result = await this.rest_interface.get(url, {
				from: moment(next_from_request).format("YYYY-MM"),
				to: moment(next_to_request).format("YYYY-MM")
			});

			results = results.concat(request_result);
			next_from_request = moment(next_to_request).add(1, "months");

			if (moment(next_to_request).isSame(to, "month")) {
				continue_progress = false;
			}
		}

		let formated = [];
		results.forEach((item) => {
			const month_energy_data =
				BackendEnergyMeasurementConverter.convert_backend_energy(item);
			formated.push(month_energy_data);
		});

		return formated;
	}
	/* End - Location monthly energy */

	// - START pv panel models -
	async get_pv_panels_models() {
		return await this.rest_interface.get(
			this.backend_url + "/api/pv-panel-manufacturers"
		);
	}
	// - END pv panel models -

	//Get last month with PR data
	async get_last_pr_data_month() {
		const result = await this.rest_interface.get(
			this.backend_url + "/api/providers/processed-data-last-pr-monthly"
		);
		return moment(result.lastMonthly).toDate();
	}

	_convert_backend_provider_name(backend_provider_name) {
		return BackendEntityConverter.convert_backend_value_to_local(
			"energy_measurement_provider_names",
			backend_provider_name
		);
	}
}
