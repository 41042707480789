<template>
	<div>
		<div id="user-edit-header">
			<div class="flex-center-content">
				<UserAvatar
					:user="user"
					:user_role="user_role"
					size="120"
					:edit="is_new_user ? false : true"
					:user_manager_id="user_manager_id"
				/>
			</div>
			<div>
				<div>
					<div class="font-bold">{{ $t("entities.user.first_name") }}</div>
					<TextInput class="m-t-xs" v-model="user.first_name" />
				</div>

				<div class="m-t">
					<div class="font-bold">
						{{ $t("entities.user.email") }}
					</div>
					<TextInput class="m-t-xs" v-model="user.email" />
				</div>
			</div>
		</div>

		<div class="m-t">
			<div class="font-bold">
				{{ $t("entities.user.company") }}
			</div>
			<TextInput class="m-t-xs" v-model="user.company" />
		</div>

		<div class="m-t">
			<div class="font-bold">
				{{ $t("entities.user.language") }}
			</div>
			<Multiselect
				v-model="user.language"
				:options="[
					{
						key: 'en',
						label: $t('entities.user.language_options.en')
					},
					{
						key: 'es',
						label: $t('entities.user.language_options.es')
					},
					{
						key: 'ca',
						label: $t('entities.user.language_options.ca')
					}
				]"
				:multiple="false"
				track_by="key"
				label="label"
			/>
		</div>

		<div v-if="is_role_guest">
			<div class="m-t">
				<div class="font-bold">
					{{ $t("entities.user.guest_role_extra_data.guest_id") }}
				</div>
				<TextInput
					class="m-t-xs"
					v-model="user.guest_role_extra_data.guest_id"
				/>
			</div>

			<div class="m-t">
				<div class="font-bold">
					{{ $t("entities.user.guest_role_extra_data.phone") }}
				</div>
				<TextInput class="m-t-xs" v-model="user.guest_role_extra_data.phone" />
			</div>
		</div>

		<div v-if="is_role_maintainer" class="m-t">
			<div class="font-bold">
				{{ $t("entities.user.maintainer_role_extra_data.phone") }}
			</div>
			<TextInput
				class="m-t-xs"
				v-model="user.maintainer_role_extra_data.phone"
			/>
		</div>

		<div v-if="is_role_guest">
			<div class="m-t">
				<div class="font-bold">
					{{ $t("entities.user.guest_role_extra_data.address") }}
				</div>
				<TextInput
					class="m-t-xs"
					v-model="user.guest_role_extra_data.address"
				/>
			</div>

			<div class="m-t">
				<div class="font-bold">
					{{ $t("entities.user.guest_role_extra_data.customer_since") }}
				</div>
				<Datepicker
					class="m-t-xs"
					v-model="user.guest_role_extra_data.customer_since"
				/>
			</div>

			<div class="m-t">
				<div class="font-bold">
					{{ $t("entities.user.guest_role_extra_data.vat_number") }}
				</div>
				<TextInput
					class="m-t-xs"
					v-model="user.guest_role_extra_data.vat_number"
				/>
			</div>
		</div>

		<div v-if="is_role_maintainer" class="m-t">
			<div class="font-bold">
				{{ $t("entities.user.maintainer_role_extra_data.address") }}
			</div>
			<TextInput
				class="m-t-xs"
				v-model="user.maintainer_role_extra_data.address"
			/>
		</div>

		<div v-if="is_role_maintainer" class="m-t">
			<div class="font-bold">
				{{ $t("entities.user.maintainer_role_extra_data.zones") }}
			</div>
			<Multiselect
				class="m-t-xs"
				:options="user.maintainer_role_extra_data.zones"
				v-model="user.maintainer_role_extra_data.zones"
				:allow_new_options="true"
				:multiple="true"
			/>
		</div>

		<div
			v-if="is_role_maintainer"
			class="m-t grid-with-same-cols-width-and-padding"
		>
			<div>
				<div class="font-bold">
					{{ $t("entities.user.maintainer_role_extra_data.hour_cost") }} (€/h)
				</div>
				<NumberInput
					class="m-t-xs"
					v-model="user.maintainer_role_extra_data.hour_cost"
				/>
			</div>
			<div>
				<div class="font-bold">
					{{ $t("entities.user.maintainer_role_extra_data.distance_cost") }}
					(€/h)
				</div>
				<NumberInput
					class="m-t-xs"
					v-model="user.maintainer_role_extra_data.distance_cost"
				/>
			</div>
		</div>

		<EditUserAllowedAssets class="m-t" v-model="user" />

		<EditMaintainerContacts
			v-if="is_role_maintainer"
			class="m-t"
			v-model="user"
		/>
	</div>
</template>

<script>
import UserAvatar from "@sub_components/UserAvatar";
import TextInput from "@form_components/TextInput";
import NumberInput from "@form_components/NumberInput";
import Datepicker from "@form_components/Datepicker";
import Multiselect from "@form_components/Multiselect";

import EditUserAllowedAssets from "./EditUserAllowedAssets";
import EditMaintainerContacts from "./EditMaintainerContacts";

export default {
	name: "MaintainerForm",
	props: {
		value: Object,
		user_role: String,
		is_new_user: Boolean,
		user_manager_id: String || null
	},
	components: {
		UserAvatar,
		TextInput,
		NumberInput,
		Datepicker,
		Multiselect,
		EditUserAllowedAssets,
		EditMaintainerContacts
	},
	data() {
		const is_role_maintainer = this.user_role == "maintainer";
		const is_role_guest = this.user_role == "guest";
		let user = this.value;

		if (this.is_new_user) {
			if (is_role_maintainer) {
				user.maintainer_role_extra_data = {
					other_contacts: []
				};
			} else if (is_role_guest) {
				user.guest_role_extra_data = {};
			}
		} else {
			if (!user.allowed_assets) user.allowed_assets = [];
		}

		return {
			user: user,
			is_role_maintainer: is_role_maintainer,
			is_role_guest: is_role_guest
		};
	}
};
</script>

<style lang="css" scoped>
#user-edit-header {
	display: grid;
	grid-template-columns: 155px auto;
}
#edit-avatar-button {
	position: absolute;
	top: 10px;
	left: 15px;
}
</style>
