<template>
	<div
		id="asset-measurements-aggregation"
		:style="{
			'--measurements-aggregation-number-of-columns':
				$is_number(measurements_aggregation.energy_production) &&
				$is_number(measurements_aggregation.energy_consumption)
					? 2
					: 1
		}"
	>
		<div v-if="$is_number(measurements_aggregation.energy_production)">
			<div class="font-l font-700 grey1-color">
				<span>{{ $t("pv_main_panels.generation") }}: </span>
				<span>{{
					$format_number(measurements_aggregation.energy_production, 2)
				}}</span>
				<span class="font-xs"> kWh</span>
			</div>

			<CustomPercentageBarChart
				:data="energy_generation_bar_data"
				:total="energy_generation_bar_total"
				unit="kWh"
				total_is_different_than_100_multilingual_msg="pv_main_panels.measurements.invalid_aggregated_data"
			/>
		</div>
		<div v-if="$is_number(measurements_aggregation.energy_consumption)">
			<div class="font-l font-700 grey1-color">
				<span>{{ $t("pv_main_panels.consumption") }}: </span>
				<span>{{
					$format_number(measurements_aggregation.energy_consumption, 2)
				}}</span>
				<span class="font-xs"> kWh</span>
			</div>

			<CustomPercentageBarChart
				:data="energy_consumption_bar_data"
				:total="energy_consumption_bar_total"
				unit="kWh"
				total_is_different_than_100_multilingual_msg="pv_main_panels.measurements.invalid_aggregated_data"
			/>
		</div>
	</div>
</template>

<script>
import CustomPercentageBarChart from "@sub_components/charts/CustomPercentageBarChart.vue";

import PvMeasuredVariables from "@entities/variables/pv-measured-data.js";

export default {
	name: "EnergyMeasurementsAggregation",
	props: {
		measurements_aggregation: Object
	},
	components: {
		CustomPercentageBarChart
	},
	data() {
		return {
			variables_data: PvMeasuredVariables,
			energy_generation_bar_total:
				this.measurements_aggregation.energy_production,
			energy_generation_bar_data: this.$is_number(
				this.measurements_aggregation.energy_production
			)
				? [
						{
							multilingual_label: "pv_main_panels.self_consumption",
							color: PvMeasuredVariables.power_self_consumption.color,
							value: this.measurements_aggregation.energy_self_consumption
						},
						{
							multilingual_label: "pv_main_panels.to_battery",
							color: PvMeasuredVariables.power_to_battery.color,
							value: this.measurements_aggregation.energy_to_battery
						},
						{
							multilingual_label: "pv_main_panels.to_grid",
							color: PvMeasuredVariables.power_to_grid.color,
							value: this.measurements_aggregation.energy_to_grid
						}
				  ]
				: null,
			energy_consumption_bar_total:
				this.measurements_aggregation.energy_consumption,
			energy_consumption_bar_data: this.$is_number(
				this.measurements_aggregation.energy_consumption
			)
				? [
						{
							multilingual_label: "pv_main_panels.self_consumption",
							color: PvMeasuredVariables.power_self_consumption.color,
							value: this.measurements_aggregation.energy_self_consumption
						},
						{
							multilingual_label: "pv_main_panels.from_battery",
							color: PvMeasuredVariables.power_from_battery.color,
							value: this.measurements_aggregation.energy_from_battery
						},
						{
							multilingual_label: "pv_main_panels.from_grid",
							color: PvMeasuredVariables.power_from_grid.color,
							value: this.measurements_aggregation.energy_from_grid
						}
				  ]
				: null
		};
	}
};
</script>

<style scoped>
#asset-measurements-aggregation {
	display: grid;
	grid-template-columns: repeat(
		var(--measurements-aggregation-number-of-columns),
		minmax(min-content, 400px)
	);
	column-gap: 15px;
}
</style>
