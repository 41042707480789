<template lang="html">
	<div class="full-width full-height">
		<div
			v-if="show_saving_settings_popup"
			id="savings-popup"
			class="flex-center-content"
		>
			<EnergySystemSavingsSettings
				:energy_system="energy_system"
				v-on:closed="show_saving_settings_popup = false"
			/>
		</div>

		<div v-if="loading" class="flex-center-content full-width full-height">
			<Loader size="30" />
		</div>
		<div v-else class="flex-auto-full-height-content p-sm full-height relative">
			<div class="content">
				<div v-if="error" class="max-width-fields m-b">
					<Alert :message="error" />
				</div>
				<div v-else-if="success" class="max-width-fields m-b">
					<Alert message="general.update_successful" type="success" />
				</div>

				<div class="two-col-fields max-width-fields">
					<div>
						<span>
							{{
								$t("entities.energy_system.consumption_data.contracted_power")
							}}
						</span>
						<div>
							<NumberInput
								v-model="consumption_data_to_update.contracted_power"
							/>
						</div>
					</div>

					<div>
						<span>
							{{ $t("entities.energy_system.consumption_data.facility_type") }}
						</span>
						<div>
							<MultiselectMultilingualEntity
								v-model="consumption_data_to_update.facility_type"
								multilingual_entity_name="energy_system_facility_options"
							/>
						</div>
					</div>

					<div>
						<span>
							{{
								$t("entities.energy_system.consumption_data.connection_type")
							}}
						</span>
						<div>
							<MultiselectMultilingualEntity
								v-model="consumption_data_to_update.connection_type"
								multilingual_entity_name="connection_type_options"
							/>
						</div>
					</div>

					<div>
						<span>{{
							$t(
								"entities.energy_system.consumption_data.land_registry_reference"
							)
						}}</span>
						<div>
							<TextInput
								v-model="consumption_data_to_update.land_registry_reference"
							/>
						</div>
					</div>

					<div>
						<span>{{
							$t("entities.energy_system.consumption_data.distribution_company")
						}}</span>
						<div>
							<TextInput
								v-model="consumption_data_to_update.distribution_company"
							/>
						</div>
					</div>

					<div>
						<span>
							{{
								$t(
									"user_settings.energy_systems_manager.savings.energy_from_grid_price"
								)
							}}
						</span>
						<div>
							<button
								class="btn btn-primary"
								v-on:click="show_saving_settings_popup = true"
							>
								{{ $t("general.configure") }}
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="footer p-t m-t border-t text-right">
				<button class="btn btn-primary" v-on:click="update_consumption_data">
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import NumberInput from "@form_components/NumberInput";
import TextInput from "@form_components/TextInput";
import MultiselectMultilingualEntity from "@form_components/MultiselectMultilingualEntity";

import EnergySystemSavingsSettings from "./EnergySystemSavingsSettings";

import EnergySystemConsumptionDataUpdaterClass from "@src/energy-system/application/energy-system-consumption-data-updater.js";
const EnergySystemConsumptionDataUpdater =
	new EnergySystemConsumptionDataUpdaterClass();

export default {
	name: "EnergySystemEditConsumptionData",
	components: {
		Alert,
		Loader,
		NumberInput,
		TextInput,
		MultiselectMultilingualEntity,
		EnergySystemSavingsSettings
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			show_saving_settings_popup: false,
			consumption_data_to_update: this.energy_system.consumption_data
				? Object.assign({}, this.energy_system.consumption_data)
				: {}
		};
	},
	methods: {
		async update_consumption_data() {
			this.loading = true;
			this.error = null;

			try {
				const data_to_update = Object.assign(
					{},
					this.consumption_data_to_update
				);
				const updated_energy_system =
					await EnergySystemConsumptionDataUpdater.update_energy_system_consumption_data(
						this.energy_system.id,
						data_to_update
					);

				this.consumption_data_to_update =
					updated_energy_system.consumption_data;

				this.$emit("energy_system_updated", updated_energy_system);

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#savings-popup {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
.max-width-fields {
	width: 800px;
}
.two-col-fields {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 15px;
	row-gap: 30px;
}
</style>
