import Joi from "joi";
import Validator from "@validators/validator";

const energy_measurement_schema = Joi.object({
	time: Joi.date(),
	energy_production: Joi.number(),
	energy_consumption: Joi.number(),
	energy_self_consumption: Joi.number(),
	energy_from_grid: Joi.number(),
	energy_to_grid: Joi.number(),
	rate_self_consumption: Joi.number(),
	rate_self_sufficiency: Joi.number(),
	energy_from_battery: Joi.number(),
	energy_to_battery: Joi.number()
});
export default new Validator(energy_measurement_schema);
