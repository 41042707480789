<template lang="html">
	<div>
		<div>
			<div>
				<div class="font-bold">
					<span
						>{{
							$t("user_settings.system_manager.estimated_production")
						}}
						(kWh)</span
					>
				</div>
			</div>

			<div>
				<div id="months-inputs" class="m-t-xs">
					<div v-for="item in months">
						<div>
							{{ $t("general.months." + item).slice(0, 3) }}
						</div>
						<div>
							<NumberInput
								v-model="estimated_production_to_update.data[item - 1].value"
								v-bind:class="{
									'danger-border':
										validation_error &&
										validation_error.property_path ==
											'estimated_production.data[' + (item - 1) + '].value'
								}"
							/>
						</div>
					</div>
				</div>

				<div class="m-t" id="panel-degradation">
					<div>
						{{ $t("location_pv_estimated_production.panel_degradation") }} (%)
					</div>
					<div>
						<NumberInput
							v-model="estimated_production_to_update.panel_degradation"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NumberInput from "@sub_components/form_components/NumberInput";

export default {
	name: "EnergySystemEditEstimatedProduction",
	components: {
		NumberInput
	},
	props: {
		value: Object,
		validation_error: Object
	},
	data() {
		let months = [];
		for (var i = 1; i < 13; i++) {
			months.push(i);
		}

		let estimated_production_to_update = Object.assign({}, this.value);

		if (!estimated_production_to_update.data)
			estimated_production_to_update.data = [];

		let months_value = [];
		months.forEach((month) => {
			const default_month_value = estimated_production_to_update.data.find(
				(item) => item.month == month
			);

			months_value.push(
				default_month_value || {
					month: month,
					value: null
				}
			);
		});
		estimated_production_to_update.data = months_value;

		if (!estimated_production_to_update.panel_degradation)
			estimated_production_to_update.panel_degradation = 0.5;

		return {
			months: months,
			estimated_production_to_update: estimated_production_to_update
		};
	},
	methods: {
		emit_value() {
			this.$emit("input", this.estimated_production_to_update);
		}
	},
	watch: {
		estimated_production_to_update() {
			this.emit_value();
		},
		"estimated_production_to_update.data"() {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped>
#months-inputs {
	display: grid;
	grid-template-columns: repeat(auto-fit, 75px);
	column-gap: 15px;
}
#panel-degradation {
	width: 250px;
}
</style>
