<template>
	<div class="full-width full-height p-sm">
		<div v-if="loading" class="flex-center-content full-height">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else class="flex-auto-full-height-content">
			<div class="header p-b-xxs">
				<div class="font-700 font-xxl line-height-xxl">{{ asset_name }}</div>
				<div v-if="asset_address">
					<span class="font-bold font-xl"
						>{{ $t("entities.asset.address") }}:</span
					>
					<span class="m-l-xs"> {{ asset_address }}</span>
				</div>
			</div>
			<div class="content">
				<AssetImage v-if="asset" :asset="asset" />
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import AssetImage from "@components/app_components/asset/energy-data-main-panel/general/AssetImage.vue";

import AssetFinderClass from "@src/asset/application/asset-finder.js";
import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder.js";
const AssetFinder = new AssetFinderClass();
const AssetOtherDataFinder = new AssetOtherDataFinderClass();

export default {
	name: "AssetData",
	components: {
		Loader,
		Alert,
		AssetImage
	},
	props: {
		asset_id: String
	},
	data() {
		return {
			loading: false,
			error: null,
			refesh_data_interval: null,
			asset: null,
			asset_name: null,
			asset_address: null
		};
	},
	async mounted() {
		await this.set_data();

		this.refesh_data_interval = setInterval(() => {
			this.set_data();
		}, 21600000);
	},
	methods: {
		async set_data() {
			this.loading = true;
			this.error = null;

			try {
				this.asset = await AssetFinder.get_asset(this.asset_id);
				this.asset_name = this.asset.name;

				const asset_other_data =
					await AssetOtherDataFinder.get_asset_other_data(this.asset_id);
				this.asset_address = asset_other_data.address || null;
			} catch (error) {
				this.error = error;

				setTimeout(() => {
					this.set_data();
				}, 60000);
			} finally {
				this.loading = false;
			}
		}
	},
	unmounted() {
		clearInterval(this.refesh_data_interval);
	}
};
</script>

<style lang="css" scoped></style>
