import DataFormatterUtilClass from "@src/shared/application/utils/data-formatter-util";
const DataFormatterUtil = new DataFormatterUtilClass();

//VueJS plugins doc: https://vuejs.org/v2/guide/plugins.html
export default {
	install: function (app, options) {
		app.config.globalProperties.$is_number = function (var_to_check) {
			return DataFormatterUtil.is_number(var_to_check);
		};

		app.config.globalProperties.$is_string = function (var_to_check) {
			return DataFormatterUtil.is_string(var_to_check);
		};

		app.config.globalProperties.$is_function = function (var_to_check) {
			return DataFormatterUtil.is_function(var_to_check);
		};

		app.config.globalProperties.$is_date = function (var_to_check) {
			return DataFormatterUtil.is_date(var_to_check);
		};

		app.config.globalProperties.$format_number = function (
			number_to_format,
			decimals = null
		) {
			return DataFormatterUtil.format_number(number_to_format, decimals);
		};

		app.config.globalProperties.$parse_to_number = function (string_number) {
			return DataFormatterUtil.parse_to_number(string_number);
		};

		app.config.globalProperties.$format_date_to_day = function (date) {
			return DataFormatterUtil.format_date_to_day(date);
		};

		app.config.globalProperties.$format_date_to_month = function (date) {
			return DataFormatterUtil.format_date_to_month(date);
		};

		app.config.globalProperties.$format_date_to_month_string = function (date) {
			return DataFormatterUtil.format_date_to_month_string(date);
		};

		app.config.globalProperties.$format_date_to_month_abbreviated_string =
			function (date) {
				return DataFormatterUtil.format_date_to_month_abbreviated_string(date);
			};

		app.config.globalProperties.$format_date_to_month_number = function (date) {
			return DataFormatterUtil.format_date_to_month_number(date);
		};

		app.config.globalProperties.$format_date_to_month_day_number = function (
			date
		) {
			return DataFormatterUtil.format_date_to_month_day_number(date);
		};

		app.config.globalProperties.$format_date_to_year_number = function (date) {
			return DataFormatterUtil.format_date_to_year_number(date);
		};

		app.config.globalProperties.$format_date_to_day_hour_minute = function (
			date
		) {
			return DataFormatterUtil.format_date_to_day_hour_minute(date);
		};

		app.config.globalProperties.$format_date_to_hour_minute = function (date) {
			return DataFormatterUtil.format_date_to_hour_minute(date);
		};

		app.config.globalProperties.$get_object_value_from_path = function (
			object,
			path
		) {
			return DataFormatterUtil.get_object_value_from_path(object, path);
		};
	}
};
