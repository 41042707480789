import { createPinia, defineStore } from "pinia";

// Crear instancia de Pinia
const pinia = createPinia();
const store_name = "sentinel_solar";

function set_data_in_local_storage(data) {
	if (typeof localStorage !== "undefined") {
		localStorage.setItem(store_name, JSON.stringify(data));
	}
}

const store = defineStore(store_name, {
	state: () => ({
		state_data:
			typeof localStorage !== "undefined" && localStorage.getItem(store_name)
				? JSON.parse(localStorage.getItem(store_name))
				: {}
	}),
	actions: {
		set_entity(entity_name, data) {
			this.state_data[entity_name] = data;
			set_data_in_local_storage(this.state_data);
		},
		update_entity_by_key(data) {
			if (!this.state_data.hasOwnProperty(data.entity))
				this.state_data[data.entity] = { data: {} };
			this.state_data[data.entity].data[data.key] = data.data;
			set_data_in_local_storage(this.state_data);
		},
		push_to_array(entity_name, element_to_push) {
			this.state_data[entity_name].data.push(element_to_push);
			set_data_in_local_storage(this.state_data);
		},
		remove_entity_index(entity_name, index_to_remove) {
			if (!this.state_data.hasOwnProperty(entity_name)) return null;
			const entity_data = this.state_data[entity_name].data;
			const entity_data_is_array = Array.isArray(entity_data);
			if (entity_data_is_array) {
				entity_data.splice(index_to_remove, 1);
			} else {
				delete entity_data[index_to_remove];
			}
			set_data_in_local_storage(this.state_data);
		},
		remove_entity_values(entity_name) {
			delete this.state_data[entity_name];
			set_data_in_local_storage(this.state_data);
		},
		remove_all_entities() {
			for (let key in this.state_data) {
				delete this.state_data[key];
			}
			set_data_in_local_storage(this.state_data);
		},
		get_all_entities_data() {
			return this.state_data;
		},
		get_full_entity(entity_name) {
			return this.state_data[entity_name] || undefined;
		},
		remove_entity(entity_name) {
			if (this.state_data.hasOwnProperty(entity_name)) {
				delete this.state_data[entity_name];
				if (typeof localStorage !== "undefined") {
					localStorage.setItem(store_name, JSON.stringify(this.state_data));
				}
			}
		}
	},
	getters: {
		all_entities_data() {
			return this.state_data;
		}
	}
});

export default store(pinia);
