import Joi from "joi";
import Validator from "@validators/validator";

const power_measurement_schema = Joi.object({
	time: Joi.date(),
	power_production: Joi.number(),
	power_consumption: Joi.number(),
	power_self_consumption: Joi.number(),
	power_from_grid: Joi.number(),
	power_to_grid: Joi.number(),
	rate_self_consumption: Joi.number(),
	rate_self_sufficiency: Joi.number(),
	power_from_battery: Joi.number(),
	power_to_battery: Joi.number()
});
export default new Validator(power_measurement_schema);
