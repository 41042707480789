<template lang="html">
	<div class="full-width relative white-bg">
		<div class="flex-center-content">
			<ReportChartHeaderValue
				icon="bolt"
				:color="asset_reports_color"
				:value="$format_number(energy_self_consumption_percentage, 1) + '%'"
			/>

			<ReportChartHeaderValue
				class="m-l"
				icon="bolt"
				color="#cfd4d9"
				:value="$format_number(energy_from_grid_percentage, 1) + '%'"
			/>
		</div>

		<PieChart
			:data="[
				{
					label: $t('asset_energy_panel.energy_from_grid'),
					color: '#cfd4d9',
					value: energy_from_grid_percentage
				},
				{
					label: $t('asset_energy_panel.energy_self_consumption'),
					color: asset_reports_color,
					value: energy_self_consumption_percentage
				}
			]"
			:hide_legend="true"
			:hide_tooltips="true"
			:hide_datalabels="true"
			:height_in_px="300"
			class="flex-center-content"
		/>

		<div class="flex-center-content" style="margin-top: 10px">
			<div class="display-flex">
				<ChartCategory
					:asset_reports_color="asset_reports_color"
					:label="$t('asset_energy_panel.energy_self_consumption')"
				/>
				<ChartCategory
					class="m-l"
					asset_reports_color="#cfd4d9"
					:label="$t('asset_energy_panel.energy_from_grid')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import PieChart from "@sub_components/charts/PieChart.vue";
import ChartCategory from "./ChartCategory";
import ReportChartHeaderValue from "../../../reports-shared-components/ReportChartHeaderValue";

export default {
	name: "MonthEnergyConsumptionChart",
	components: {
		PieChart,
		ChartCategory,
		ReportChartHeaderValue
	},
	props: {
		filter_month_energy: Object,
		asset_reports_color: String
	},
	data() {
		return {
			energy_from_grid_percentage: !this.filter_month_energy.energy_from_grid
				? 0
				: (this.filter_month_energy.energy_from_grid * 100) /
				  this.filter_month_energy.energy_consumption,
			energy_self_consumption_percentage:
				(this.filter_month_energy.energy_self_consumption * 100) /
				this.filter_month_energy.energy_consumption
		};
	}
};
</script>

<style lang="css" scoped></style>
