import AssetsGroupAssetsFinderInterfaceClass from "../infrastructure/assets-group-assets-finder-interface.js";
import AssetFinderValidator from "../../asset/domain/asset-finder-validator.js";
import AssetsGroupAssetsStoreUtilClass from "./utils/assets-group-assets-store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.asset_finder_validator = AssetFinderValidator;
		this.backend_interface = new AssetsGroupAssetsFinderInterfaceClass();
		this.store_util = null;
	}

	async get_assets_group_assets(assets_group_id, force_download = false) {
		try {
			this.store_util = new AssetsGroupAssetsStoreUtilClass(assets_group_id);

			var cached_asset = await this.store_util.get_items();
			if (cached_asset && !force_download) return cached_asset;

			const interface_result =
				await this.backend_interface.get_assets_group_assets(assets_group_id);

			const validated_data = interface_result.map((item) =>
				this.asset_finder_validator.validate(item)
			);

			await this.store_util.save_items(
				validated_data,
				moment().add(1, "hours").toDate()
			);

			return await this.store_util.get_items();
		} catch (e) {
			throw new AppError(
				"error_get_assets_group_assets",
				"general.errors.general_error",
				e
			);
		}
	}
}
