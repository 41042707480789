import CurrentPowerFinderValidator from "../domain/current-power-finder-validator";
import AssetCurrentPowerFinderInterfaceClass from "../infrastructure/asset-current-power-finder-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetCurrentPowerFinderInterfaceClass();
		this.validator = CurrentPowerFinderValidator;
	}

	async get_asset_current_power(asset_id) {
		try {
			const interface_data =
				await this.backend_interface.get_asset_current_power(asset_id);

			const validated_data = this.validator.validate(interface_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_current_power",
				"general.errors.general_error",
				e
			);
		}
	}
}
