import AssetEnergyAvailableDateFinderInterfaceClass from "../infrastructure/asset-energy-available-date-finder-interface.js";
import AssetEnergyAvailableDateValidator from "../domain/asset-energy-available-date-validator.js";

import StoreUtilClass from "../../shared/application/utils/store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergyAvailableDateFinderInterfaceClass();
		this.validator = AssetEnergyAvailableDateValidator;
		this.store_util = null;
	}

	async get_asset_energy_data_first_date(asset_id) {
		const entity_name = "asset_energy_data_first_date_" + asset_id;
		this.store_util = new StoreUtilClass(entity_name);

		var asset_energy_data_first_date = this.store_util.get_data();
		if (asset_energy_data_first_date) return asset_energy_data_first_date;

		try {
			const result =
				await this.backend_interface.get_asset_energy_data_first_date(asset_id);

			const validated_result = this.validator.validate(result);

			this.store_util.set_data(
				validated_result,
				moment().add(1, "days").toDate()
			);

			asset_energy_data_first_date = this.store_util.get_data();

			return asset_energy_data_first_date;
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_data_first_date",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_asset_energy_data_last_date(asset_id) {
		const entity_name = "asset_processed_energy_data_last_day_" + asset_id;
		this.store_util = new StoreUtilClass(entity_name);

		var cached_result = this.store_util.get_data();
		if (cached_result) {
			return cached_result;
		}

		try {
			const asset_processed_energy_data_last_day =
				await this.backend_interface.get_asset_energy_data_last_date(asset_id);

			const validated_result = this.validator.validate(
				asset_processed_energy_data_last_day
			);

			if (validated_result)
				this.store_util.set_data(
					validated_result,
					moment().add("5", "hours").toDate()
				);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_data_last_date",
				"general.errors.general_error",
				e
			);
		}
	}
}
