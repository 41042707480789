import moment from "moment";

export default class {
	convert_user_to_backend(user) {
		let backend_user = {
			email: user.email || undefined,
			firstName: user.first_name || undefined,
			lastName: user.last_name || undefined,
			unitSystem: user.unit_system || undefined,
			timezone: user.timezone || undefined
		};

		if (user.use_custom_interface)
			backend_user.useCustomInterface = user.use_custom_interface;

		if (user.password) backend_user.password = user.password;

		return backend_user;
	}

	convert_user_to_backend_guest(user) {
		let backend_obj = {
			email: user.email,
			firstName: user.first_name,
			timezone: user.timezone,
			guestInternalId: user.guest_data.guest_id,
			phone: user.guest_data.phone,
			address: user.guest_data.address,
			customerSince: user.guest_data.customer_since
				? moment(user.guest_data.customer_since).format("YYYY-MM-DD")
				: null,
			vatNumber: user.guest_data.vat_number || null
		};

		if (user.guest_data.assets && user.guest_data.assets.length > 0) {
			backend_obj.assetIds = user.guest_data.assets;
		}

		return backend_obj;
	}

	convert_user_to_backend_maintainer(user) {
		let backend_maintainer = {
			email: user.email,
			firstName: user.first_name,
			timezone: user.timezone
		};

		if (user.maintainer_data) {
			if (user.maintainer_data.has_access_all_assets) {
				backend_maintainer.hasAccessAllAssets =
					user.maintainer_data.has_access_all_assets;
			} else {
				backend_maintainer.assetIds = user.maintainer_data.assets;
			}

			const optional_params = [
				"phone",
				"address",
				"zones",
				"other_contacts",
				"distance_cost",
				"hour_cost"
			];
			optional_params.forEach((param) => {
				if (user.maintainer_data[param])
					backend_maintainer[param] = user.maintainer_data[param];
			});
		}

		return backend_maintainer;
	}
}
