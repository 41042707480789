import UserFinderClass from "@src/user/application/user-finder.js";
import MultilingualServiceClass from "@services/multilingual-service";

const UserFinder = new UserFinderClass();
const MultilingualService = new MultilingualServiceClass();

import moment from "moment";

export default class {
	constructor() {
		const current_user = UserFinder.get_stored_user();
		this.unit_system = current_user ? current_user.unit_system : "si";
	}

	is_number(number_to_check) {
		return typeof number_to_check == "number";
	}

	is_string(string_to_check) {
		return typeof string_to_check == "string";
	}

	is_function(function_to_check) {
		return typeof function_to_check == "function";
	}

	is_date(date_to_check) {
		let dateWrapper = new Date(date_to_check);

		return (
			dateWrapper instanceof Date &&
			!isNaN(dateWrapper) &&
			date_to_check != null
		);
	}

	format_number(number_to_format, decimals = null) {
		const is_valid_number = !isNaN(number_to_format);
		if (!is_valid_number || number_to_format == null) return null;

		if (typeof number_to_format === "string") {
			number_to_format = Number(number_to_format);
		}

		if (decimals != null) {
			number_to_format = this._set_decimals_to_number(
				number_to_format,
				decimals
			);
		}

		number_to_format = this._format_number_to_unit_system(number_to_format);

		return number_to_format;
	}

	parse_to_number(string_number) {
		//Parse number doc: https://stackoverflow.com/a/45309230
		function parse_number(value, locales = navigator.languages) {
			const example = Intl.NumberFormat(locales).format("1.1");
			const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, "g");
			const cleaned = value.replace(cleanPattern, "");
			const normalized = cleaned.replace(example.charAt(1), ".");

			return parseFloat(normalized);
		}

		let locale;
		switch (this.unit_system) {
			case "si":
				locale = "es-ES";
				break;
			default:
				locale = "en-US";
		}

		let result = parse_number(string_number, locale);

		return isNaN(result) ? null : result;
	}

	format_date_to_day(date) {
		switch (this.unit_system) {
			case "us":
				return moment(date).format("MM-DD-YYYY");
				break;
			case "uk":
				return moment(date).format("YYYY-MM-DD");
				break;
			default:
				return moment(date).format("DD-MM-YYYY");
		}
		return date;
	}

	format_date_to_day_hour_minute(date) {
		const formatted_day = this.format_date_to_day(date);
		const formatted_hour_minute = this.format_date_to_hour_minute(date);

		return formatted_day + " " + formatted_hour_minute;
	}

	format_date_to_hour_minute(date) {
		return moment(date).format("HH:mm");
	}

	format_date_to_month(date) {
		switch (this.unit_system) {
			case "si":
				return moment(date).format("MM-YYYY");
				break;
			default:
				return moment(date).format("YYYY-MM");
		}
		return date;
	}

	format_date_to_month_number(date) {
		return Number(moment(date).format("M"));
	}

	format_date_to_month_string(date) {
		const date_month_number = moment(date).format("M");

		return MultilingualService.translate("general.months." + date_month_number);
	}

	format_date_to_month_abbreviated_string(date) {
		return this.format_date_to_month_string(date).substring(0, 3);
	}

	format_date_to_month_day_number(date) {
		return Number(moment(date).format("D"));
	}

	format_date_to_year_number(date) {
		return Number(moment(date).format("YYYY"));
	}

	_set_decimals_to_number(number_to_format, decimals) {
		if (decimals == 0) {
			return Math.round(number_to_format);
		} else if (decimals) {
			return parseFloat(number_to_format.toFixed(decimals));
		}
	}

	_format_number_to_unit_system(number_to_format) {
		//FYI: locale es-ES does not allow thousand separator with 4 digit; de-DE yes
		const locale = this.unit_system == "si" ? "de-DE" : "en-US";

		const number_as_string = number_to_format.toString();
		const decimal_position = number_as_string.indexOf(".");
		const number_of_decimals =
			decimal_position == -1
				? 0
				: number_as_string.length - (decimal_position + 1);

		return Intl.NumberFormat(locale, {
			style: "decimal",
			minimumFractionDigits: number_of_decimals
		}).format(number_to_format);
	}

	get_object_value_from_path(object, path) {
		var paths = path.split("."),
			current = object,
			i;

		for (i = 0; i < paths.length; ++i) {
			if (current[paths[i]] == undefined) {
				return undefined;
			} else {
				current = current[paths[i]];
			}
		}
		return current;
	}
}
