<template>
	<div id="header-help-menu" class="relative primary-color">
		<div
			id="header-help-menu-button"
			class="flex-center-content full-rounded font-bold cursor-pointer"
			v-on:click="show_menu = !show_menu"
		>
			<div>?</div>
		</div>

		<div id="header-help-menu-elements">
			<div class="white-bg rounded-s box-shadow">
				<div v-for="item in menu_elements">
					<a :href="item.link" target="_blanc">
						<div class="p-sm display-flex-space-between">
							<div class="flex-center-content font-bold">
								{{ item.label }}
							</div>

							<div class="m-l flex-center-content">
								<Icon icon="external-link" size="15" />
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util.js";
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "HeaderHelpMenu",
	computed: {
		menu_elements() {
			let result = [];

			if (UserPermissionsUtil.can_user("help_full_menu")) {
				result = [
					{
						label: this.$t("header.help_menu.api_doc"),
						link: "https://api-doc-v3.sentinel-solar.com"
					},
					{
						label: this.$t("header.help_menu.user_manual"),
						link: "https://www.nnergix.com/manual-de-usuario-admin"
					},
					{
						label: this.$t("header.help_menu.integrations"),
						link: "https://www.nnergix.com/integraciones"
					}
				];
			}

			result.push({
				label: this.$t("header.help_menu.tutorials"),
				link: "https://www.youtube.com/@sentinel_solar"
			});

			return result;
		}
	}
};
</script>

<style>
#header-help-menu-button {
	width: 25px;
	height: 25px;
	border: 2px solid var(--custom-primary);
	font-size: 17px;
	line-height: 25px;
	z-index: 2;
}
#header-help-menu-elements {
	position: absolute;
	top: 0;
	padding-top: 45px;
	right: 0;
	white-space: nowrap;
	display: none;
}
#header-help-menu-elements > div:not(:first-child) {
	border-top: 1px solid var(--grey2);
}
#header-help-menu:hover #header-help-menu-elements {
	display: block;
}
</style>
