import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";
import LocalObjectConverter from "../../shared/infrastructure/backend-converters/local-object-converter";
import PaginatedEntityClass from "../../shared/domain/paginated-entity-class";

import StoreUtilClass from "../../shared/application/utils/store-util";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();

		this.assets_alerts_items_per_page_store_util = new StoreUtilClass(
			"assets_alerts_items_per_page"
		);
	}

	async get_assets_alerts(filter, page) {
		const pagination_stored_data =
			this.assets_alerts_items_per_page_store_util.get_data();

		const offest = pagination_stored_data
			? pagination_stored_data.items_per_page * (page - 1)
			: 0;
		const url = "/api/assets/alerts";
		const backend_result = await this.post(url, {
			query_params: {
				offset: offest
			},
			body_params: LocalObjectConverter(filter, {
				date_from: {
					formatter: (value) => moment(value).format("YYYY-MM-DD")
				},
				date_to: {
					formatter: (value) => moment(value).format("YYYY-MM-DD")
				}
			})
		});

		const converted_alerts = BackendObjectConverter(backend_result.data);

		const total_elements = backend_result.totalElements;
		let total_pages, items_per_page;

		if (page == 1 && backend_result.offsetNext) {
			items_per_page = backend_result.offsetNext;
			total_pages = Math.ceil(total_elements / items_per_page);
			this.assets_alerts_items_per_page_store_util.set_data({
				items_per_page: items_per_page,
				total_pages: total_pages
			});
		} else if (page == 1 && !backend_result.offsetNext) {
			total_pages = 1;
		} else {
			total_pages = pagination_stored_data
				? pagination_stored_data.total_pages
				: 0;
		}

		return new PaginatedEntityClass(
			filter,
			converted_alerts,
			total_elements,
			page,
			total_pages
		);
	}
}
