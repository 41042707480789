import AssetsGroupsFinderInterfaceClass from "../infrastructure/assets-groups-finder-interface.js";
import AssetsGroupFinderValidator from "../domain/assets-group-finder-validator.js";

import AssetsGroupsStoreUtil from "./utils/assets-groups-store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = AssetsGroupFinderValidator;
		this.backend_interface = new AssetsGroupsFinderInterfaceClass();
		this.store_util = AssetsGroupsStoreUtil;
	}

	async get_assets_groups(force_download = false) {
		try {
			var cached_asset = this.store_util.get_data();
			if (cached_asset && !force_download) return cached_asset;

			const interface_result = await this.backend_interface.get_assets_groups();

			const validated_data = interface_result.map((item) =>
				this.validator.validate(item)
			);

			this.store_util.set_data(
				validated_data,
				moment().add(1, "hours").toDate()
			);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_assets_groups",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_assets_group(assets_group_id) {
		let cached_data = await this.store_util.get_cached_item_by_id(
			assets_group_id
		);

		if (!cached_data) {
			await this.get_assets_groups();
			cached_data = await this.store_util.get_cached_item_by_id(
				assets_group_id
			);
		}

		return cached_data;
	}
}
