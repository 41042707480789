import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async create_energy_system(energy_system_to_create) {
		const url = "/api/energy-data/system";
		const backend_new_energy_system = await this.post(url, {
			body_params: {
				name: energy_system_to_create.name,
				systemType: energy_system_to_create.system_type,
				assetId: energy_system_to_create.asset_id
			}
		});

		return BackendEnergySystemConverter(backend_new_energy_system);
	}
}
