<template>
	<div class="full-width full-height absolute">
		<div
			v-if="this.$route.name != 'ticketing'"
			id="sub-page"
			class="flex-center-content"
		>
			<router-view v-on:refresh_tickets="refresh"></router-view>
		</div>

		<div class="gray-bg full-width full-height">
			<div class="flex-auto-full-height-content gap-s full-height full-width">
				<div class="header p-sm white-bg rounded-xs">
					<div class="white-bg rounded-xs">
						<div class="display-flex-space-between m-t">
							<div class="display-flex gap-s">
								<div class="ticketing-filter">
									<div class="font-s">
										{{ $tc("location.location", 2) }}
									</div>
									<div>
										<LocationSelect
											class="m-t-sm"
											:multiple="true"
											:track_by_id="true"
											v-model="filter.locations"
										/>
									</div>
								</div>

								<div
									v-show="show_more_filters"
									class="display-flex gap-s animation"
								>
									<div class="ticketing-filter">
										<div class="font-s">
											{{ $t("ticketing.ticket_entity.created_by") }}
										</div>
										<div class="m-t-sm">
											<Multiselect
												v-if="allowed_assigned_to"
												track_by="id"
												label="first_name"
												:multiple="true"
												v-model="filter.created_by"
												:options="allowed_assigned_to"
												:close_on_select="false"
											/>
										</div>
									</div>

									<div class="ticketing-filter">
										<div class="font-s">
											{{ $t("ticketing.ticket_entity.assigned_to") }}
										</div>
										<div class="m-t-sm">
											<Multiselect
												v-if="allowed_assigned_to"
												track_by="id"
												label="first_name"
												:multiple="true"
												v-model="filter.assigned_to"
												:options="allowed_assigned_to"
												:close_on_select="false"
											/>
										</div>
									</div>

									<div class="ticketing-filter">
										<div class="font-s">
											{{ $t("general.status") }}
										</div>
										<div class="m-t-sm">
											<Multiselect
												v-if="status"
												track_by="id"
												label="name"
												:multiple="true"
												v-model="filter.status"
												:options="status"
												:close_on_select="false"
											/>
										</div>
									</div>

									<div v-if="equipments" class="ticketing-filter">
										<div class="font-s">
											{{ $t("ticketing.ticket_entity.faulty_equipments") }}
										</div>
										<div class="m-t-sm">
											<Multiselect
												v-if="status"
												track_by="id"
												label="name"
												:multiple="true"
												v-model="filter.equipments"
												:options="equipments"
												:close_on_select="false"
											/>
										</div>
									</div>
								</div>

								<div class="flex-vertical-bottom">
									<button
										class="btn btn-primary"
										v-on:click="show_more_filters = !show_more_filters"
									>
										<Icon icon="filter" />
									</button>
								</div>

								<div>
									<button class="btn btn-primary m-t-lg" v-on:click="refresh">
										<span>{{ $t("general.search") }}</span>
									</button>
								</div>
							</div>

							<div class="flex-vertical-bottom">
								<div class="display-flex gap-s">
									<TicketsExport :tickets="tickets" />

									<div>
										<router-link
											:to="{
												name: 'new-ticket'
											}"
										>
											<button class="btn btn-primary">
												<span>{{ $t("general.add_new") }} +</span>
											</button>
										</router-link>
									</div>

									<div class="border-l p-l-xs">
										<button class="btn btn-primary" v-on:click="refresh">
											<Icon icon="sync-alt" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="content white-bg rounded-xs overflow-hidden">
					<div class="full-width full-height relative">
						<TicketList
							:refresh="refresh_tickets"
							:filter_params="filter"
							v-on:tickets_refreshed="tickets_refreshed"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts";
import TicketList from "./TicketList";
import LocationSelect from "@form_components/LocationSelect2";
import Multiselect from "@form_components/Multiselect";
import TicketsExport from "./TicketsExport.vue";

import TicketServiceClass from "@services/user-services/ticket-service";

const TicketService = new TicketServiceClass();

export default {
	name: "Ticketing",
	components: {
		AssetShortcuts,
		TicketList,
		LocationSelect,
		Multiselect,
		TicketsExport
	},
	data() {
		return {
			status: null,
			allowed_assigned_to: null,
			refresh_tickets: 0,
			equipments: null,
			filter: {
				locations: this.$route.query.asset_id
					? [this.$route.query.asset_id]
					: []
			},
			tickets: null,
			show_more_filters: false
		};
	},
	async created() {
		this.load_allowed_assigned_to();
		this.status = await TicketService.get_ticketing_status();
	},
	methods: {
		go_to_tickets() {
			this.$router.push({ name: "ticketing" });
		},
		refresh() {
			this.refresh_tickets++;
		},
		async set_filter_equipments() {
			this.equipments = await TicketService.get_ticketing_faulty_equipments();
		},
		async load_allowed_assigned_to() {
			const result = await TicketService.get_allowed_assigned_to();
			this.allowed_assigned_to = result;
		},
		tickets_refreshed(tickets) {
			this.tickets = tickets;
		}
	},
	mounted() {
		this.set_filter_equipments();
	}
};
</script>

<style lang="css" scoped>
#sub-page {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 3;
}
.ticketing-filter {
	max-width: 20%;
	min-width: 230px;
}
</style>
