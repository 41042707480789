export default {
	pv_energy_production: {
		unit: "kWh",
		is_percentage: false,
		name: "weather_forecast.variables.pv",
		color: "#ff0000",
		show_in_chart: true,
		decimals: 2,
		forecast_variables_group: "energy"
	},
	power_consumption: {
		unit: "kW",
		is_percentage: false,
		name: "weather_forecast.variables.power_consumption",
		color: "#956c21",
		show_in_chart: true,
		decimals: 2,
		forecast_variables_group: "power"
	},
	dhi: {
		unit: "W/m2",
		is_percentage: false,
		name: "weather_forecast.variables.dhi",
		color: "#FFB45D",
		show_in_chart: false,
		decimals: 0,
		forecast_variables_group: "radiation"
	},
	dni: {
		unit: "W/m2",
		is_percentage: false,
		name: "weather_forecast.variables.dni",
		color: "#FFB45D",
		show_in_chart: false,
		decimals: 0,
		forecast_variables_group: "radiation"
	},
	ghi: {
		unit: "W/m2",
		is_percentage: false,
		name: "weather_forecast.variables.ghi",
		color: "#FFB45D",
		show_in_chart: true,
		decimals: 0,
		forecast_variables_group: "radiation"
	},
	eo_energy_production: {
		unit: "kWh",
		is_percentage: false,
		name: "weather_forecast.variables.windPower",
		color: "red",
		show_in_chart: true,
		decimals: 0,
		forecast_variables_group: "energy"
	},
	wind_gust: {
		unit: {
			si: "m/s",
			us: "mph",
			uk: "mph"
		},
		is_percentage: false,
		name: "weather_forecast.variables.windGust",
		color: "#1664FF",
		show_in_chart: true,
		decimals: 1,
		forecast_variables_group: "wind_speed"
	},
	wind_speed_10m: {
		unit: {
			si: "m/s",
			us: "mph",
			uk: "mph"
		},
		is_percentage: false,
		name: "weather_forecast.variables.wind_speed",
		color: "#1664FF",
		show_in_chart: true,
		decimals: 1,
		forecast_variables_group: "wind_speed"
	},
	temperature: {
		unit: {
			si: "\u00baC",
			us: "\u00baF",
			uk: "\u00baC"
		},
		is_percentage: false,
		name: "weather_forecast.variables.temperature",
		color: "#FF6B0A",
		show_in_chart: true,
		decimals: 1,
		forecast_variables_group: "temperature"
	},
	precip_intensity: {
		unit: {
			si: "mm",
			us: "in",
			uk: "in"
		},
		is_percentage: false,
		name: "weather_forecast.variables.precipitation_intensity",
		color: "#6DF5FF",
		show_in_chart: true,
		decimals: 0,
		forecast_variables_group: "mm"
	},
	precip_probability: {
		unit: null,
		is_percentage: true,
		name: "weather_forecast.variables.precipitation_probability",
		color: "#6DF5FF",
		show_in_chart: true,
		decimals: 0
	},
	cloud_cover: {
		unit: null,
		is_percentage: true,
		name: "weather_forecast.variables.cloud_cover",
		color: "#A4A4A4",
		show_in_chart: true,
		decimals: 0
	},
	uv_index: {
		unit: "UV Index",
		is_percentage: false,
		name: "weather_forecast.variables.uv_index",
		color: "#F63F1A",
		show_in_chart: true,
		decimals: 0,
		forecast_variables_group: "uv_index"
	},
	humidity: {
		unit: null,
		is_percentage: true,
		name: "weather_forecast.variables.humidity",
		color: "#202FFF",
		show_in_chart: true,
		decimals: 0
	},
	ozone: {
		unit: "DU",
		is_percentage: false,
		name: "weather_forecast.variables.ozone",
		color: "#36BB36",
		show_in_chart: true,
		decimals: 0
	},
	visibility: {
		unit: {
			si: "km",
			us: "mi",
			uk: "mi"
		},
		is_percentage: false,
		name: "weather_forecast.variables.visibility",
		color: "#CCD9E1",
		show_in_chart: true,
		decimals: 1
	},
	pressure: {
		unit: "hPa",
		is_percentage: false,
		name: "weather_forecast.variables.pressure",
		color: "#009430",
		show_in_chart: true,
		decimals: 0
	},
	wind_bearing_10m: {
		unit: null,
		is_percentage: false,
		name: "weather_forecast.variables.wind_bearing",
		color: "#0D00CA",
		show_in_chart: false,
		decimals: 0
	}
};
