<template>
	<div class="font-bold full-height display-flex-vertical-space-between">
		<div
			v-for="(item, index) in data"
			class="assets-group-summary-chart-labels"
		>
			<div class="grid-center-content">
				<div
					class="circle full-rounded"
					v-bind:style="{ 'background-color': item.color }"
				></div>
			</div>
			<div class="font-xl">
				{{ $is_number(item.value) ? $format_number(item.value, 0) : "-" }}
			</div>
			<div></div>
			<div class="font-xs">
				{{ item.label }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AssetsGroupSummaryDonutChartLabels",
	props: {
		data: Array
	}
};
</script>

<style scoped>
.assets-group-summary-chart-labels {
	display: grid;
	grid-template-columns: 15px auto;
	column-gap: 10px;
}
.assets-group-summary-chart-labels .circle {
	width: 15px;
	height: 15px;
}
</style>
