import EnergyProviderFinderInterfaceClass from "../infrastructure/energy-provider-auth-finder-interface";
import EnergyProvidersStoreUtil from "./utils/energy-provider-auths-store-util";
import EnergyProviderValidator from "../domain/energy-provider-auth-finder-validator";
import AppError from "@classes/errors/app_error";

import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new EnergyProviderFinderInterfaceClass();
		this.store_util = EnergyProvidersStoreUtil;
		this.validator = EnergyProviderValidator;
	}

	async get_energy_provider_auths(force_download = null) {
		try {
			var user_providers = await this.store_util.get_items();
			if (user_providers && !force_download) return user_providers;

			const backend_user_providers =
				await this.backend_interface.get_energy_provider_auths();

			await this.store_util.save_items(
				backend_user_providers,
				moment().add(2, "hours").toDate()
			);

			return backend_user_providers.map((item) =>
				this.validator.validate(item)
			);
		} catch (e) {
			throw new AppError(
				"error_get_energy_provider_auths",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_energy_provider_auth(energy_provider_auth_id) {
		const energy_provider_auths = await this.get_energy_provider_auths();

		return energy_provider_auths.find(
			(item) => item.id == energy_provider_auth_id
		);
	}
}
