import Joi from "joi";
import Validator from "@validators/validator";

const asset_schema = Joi.object({
	name: Joi.string().required(),
	system_type: Joi.string()
		.valid(
			"only_generation",
			"only_consumption",
			"generation_and_consumption",
			"only_grid_interaction"
		)
		.required(),
	asset_id: Joi.string().required()
});
export default new Validator(asset_schema, "entities.energy_system.");
