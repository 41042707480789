<template lang="html">
	<div class="">
		<div v-if="watermark_loading">
			<Loader size="30" />
		</div>
		<div v-else>
			<div class="btn btn-primary" @click="$refs.file_2.click()">
				{{ $t("user_settings.change_watermark") }}
			</div>
			<input
				type="file"
				ref="file_2"
				style="display: none"
				@change="upload_watermark($event.target.files[0])"
			/>
		</div>
		<div v-if="watermark_error" class="alert alert-danger m-t">
			{{ $t(watermark_error) }}
		</div>
		<div v-if="watermark_success" class="alert alert-success m-t">
			{{ $t("user_settings.watermark_success") }}
		</div>
		<div v-if="user && user.assets_watermark" class="m-t">
			<img :src="user.assets_watermark" id="user-assets-watermark" />
		</div>
	</div>
</template>

<script>
import Loader from "@components/sub_components/Loader";

import UserServiceClass from "@services/user-services/user-service";
import UserFinderClass from "@src/user/application/user-finder.js";

const UserService = new UserServiceClass();
const UserFinder = new UserFinderClass();

export default {
	name: "UserChangeWatermark",
	components: {
		Loader
	},
	data() {
		return {
			user: null,
			watermark_error: null,
			watermark_loading: null,
			watermark_success: null
		};
	},
	async created() {
		this.user = await UserFinder.get_user();
	},
	methods: {
		async upload_watermark(image) {
			this.watermark_loading = true;
			this.watermark_error = null;
			this.watermark_success = null;

			if (image.size > 500000) {
				this.watermark_error = this.$t("user_settings.watermark_size_error", {
					image_size: "0.5Mb"
				});
				return;
			}

			try {
				await UserService.upload_locations_images_watermark(image);
				this.watermark_success = true;
			} catch (e) {
				this.watermark_error = "general.errors.general_error";
			} finally {
				this.watermark_loading = null;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#user-assets-watermark {
	max-width: 150px;
	max-height: 150px;
}
</style>
