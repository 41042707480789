<template>
	<div class="full-width full-height">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader size="30" />
		</div>
		<div v-else class="full-width full-height relative">
			<div
				v-if="error || is_current_power_empty"
				class="absolute full-width flex-center-content white-bg font-bold grey1-color"
			>
				<div class="p-t-xs text-center">
					{{ $t("asset_energy_panel.not_available_current_power") }}
				</div>
			</div>

			<AssetCurrentPowerBalls
				v-if="current_power"
				position="top-left"
				diagonal_start="top"
				:bubbles_start="current_power.power_production ? 'left' : null"
			/>
			<AssetCurrentPowerBalls
				v-if="current_power"
				position="top-right"
				diagonal_start="bottom"
				:bubbles_start="current_power.power_consumption ? 'left' : null"
			/>
			<AssetCurrentPowerBalls
				v-if="current_power"
				position="bottom-left"
				diagonal_start="bottom"
				:bubbles_start="
					current_power.power_from_grid
						? 'left'
						: current_power.power_to_grid
						? 'right'
						: null
				"
			/>
			<AssetCurrentPowerBalls
				v-if="current_power"
				position="bottom-right"
				diagonal_start="top"
				:bubbles_start="
					current_power.power_to_battery
						? 'left'
						: current_power.power_from_battery
						? 'right'
						: null
				"
			/>

			<div id="asset-current-power" class="full-height">
				<div class="flex-with-same-cols-width">
					<div class="flex-center-content">
						<CurrentPowerelement
							icon="solar-panel"
							color="#FCB12A"
							:percentage="pv_generating_percentage"
							:value="
								current_power
									? format_power_value(current_power.power_production)
									: null
							"
							value_position="top"
						/>
					</div>
					<div class="flex-center-content">
						<CurrentPowerelement
							icon="home"
							:value="
								current_power
									? format_power_value(current_power.power_consumption)
									: null
							"
							value_position="top"
						/>
					</div>
				</div>
				<div class="flex-center-content">
					<CurrentPowerelement icon="inverter" />
				</div>
				<div class="flex-with-same-cols-width">
					<div class="flex-center-content">
						<CurrentPowerelement
							icon="grid-tower"
							:value="
								current_power
									? current_power.power_from_grid
										? format_power_value(current_power.power_from_grid)
										: current_power.power_to_grid
										? format_power_value(current_power.power_to_grid)
										: null
									: null
							"
							value_position="bottom"
						/>
					</div>
					<div class="flex-center-content">
						<CurrentPowerelement
							icon="battery"
							color="var(--green1)"
							:percentage="
								current_power ? current_power.battery_charge_percent : null
							"
							:value="battery_formatted_value"
							value_position="bottom"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import CurrentPowerelement from "./CurrentPowerelement.vue";
import AssetCurrentPowerBalls from "./AssetCurrentPowerBalls.vue";

import AssetCurrentPowerFinderClass from "@src/asset-energy-data/application/asset-current-power-finder";
import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder";

const AssetCurrentPowerFinder = new AssetCurrentPowerFinderClass();
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();

export default {
	name: "AssetCurrentPower",
	components: {
		Loader,
		CurrentPowerelement,
		AssetCurrentPowerBalls
	},
	props: {
		asset: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_total_peak_power: null,
			consecutive_errors: 0,
			refesh_data_timeout: null,
			current_power: {}
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(this.asset.id);

			let asset_total_peak_power = 0;
			asset_energy_systems.forEach((energy_system) => {
				if (energy_system.pv_data)
					asset_total_peak_power += energy_system.pv_data.peak_power;
			});
			this.asset_total_peak_power = asset_total_peak_power;

			await this.refresh_current_power();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async refresh_current_power() {
			try {
				this.current_power =
					await AssetCurrentPowerFinder.get_asset_current_power(this.asset.id);

				this.$emit("current_power_updated", this.current_power || null);

				this.refesh_data_timeout = setTimeout(() => {
					this.refresh_current_power();
				}, 60000);

				this.consecutive_errors = 0;
				this.error = null;
			} catch (e) {
				this.consecutive_errors++;
				this.error = e;

				if (this.consecutive_errors == 120) {
					this.$emit("current_power_updated", null);

					this.refesh_data_timeout = setTimeout(() => {
						this.refresh_current_power();
					}, 300000);

					this.current_power = {
						time: null,
						power_production: null,
						power_consumption: null,
						rate_self_consumption: null,
						rate_self_sufficiency: null,
						power_from_grid: null
					};
				} else {
					this.refesh_data_timeout = setTimeout(() => {
						this.refresh_current_power();
					}, 60000);
				}
			}
		},
		format_power_value(power_value) {
			if (power_value == null) return null;

			const unit = power_value < 1 ? "W" : "kW";
			const formatted_value = this.$format_number(
				unit == "W" ? power_value * 1000 : power_value,
				2
			);

			return formatted_value != null ? formatted_value + " " + unit : null;
		}
	},
	computed: {
		is_current_power_empty() {
			if (!this.current_power) return true;

			for (let key in this.current_power) {
				if (key != "time" && this.current_power[key] != null) return false;
			}

			return true;
		},
		pv_generating_percentage() {
			if (!this.current_power || !this.asset_total_peak_power) return null;

			return (
				(this.current_power.power_production * 100) /
				this.asset_total_peak_power
			);
		},
		battery_formatted_value() {
			if (!this.current_power) return null;

			let result = "";

			if (
				this.current_power.power_from_battery != null &&
				!this.current_power.power_to_battery
			)
				result += this.format_power_value(
					this.current_power.power_from_battery
				);
			else if (this.current_power.power_to_battery != null)
				result += this.format_power_value(this.current_power.power_to_battery);

			if (this.current_power.battery_charge_percent != null) {
				result += result != "" ? " - " : "";
				result +=
					this.$format_number(this.current_power.battery_charge_percent, 0) +
					"%";
			}

			return result != "" ? result : null;
		}
	},
	unmounted() {
		clearTimeout(this.refesh_data_timeout);
	}
};
</script>

<style scoped>
#asset-current-power {
	display: grid;
	grid-auto-rows: 1fr;
}
</style>
