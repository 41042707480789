import { app_version as AppVersion } from "@config";
import AppError from "@classes/errors/app_error";
import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import IndexedDbInterfaceClass from "@src/shared/infrastructure/indexed-db-interface";
import UserServiceClass from "@services/user-services/user-service";

import { store_entity_names } from "@config";
const CacheAppVersionName = store_entity_names.client_app_version;

export default class {
	constructor() {
		this.app_version = AppVersion;
		this.store_interface = new StoreInterfaceClass();
		this.indexed_db_interface = new IndexedDbInterfaceClass();
		this.user_service = new UserServiceClass();
	}

	async intitial_setup() {
		const cached_version = this.store_interface.get(CacheAppVersionName);
		if (cached_version != this.app_version) {
			await this._set_new_app_version();
			throw new AppError(
				"incompatible_version",
				"general.errors.general_error"
			);
		}

		const current_user_token = this.user_service.get_current_user_token();
		if (!current_user_token) {
			await this._remove_all_cache_data();
			throw new AppError("expired_user_token", "general.errors.general_error");
		}

		return true;
	}

	async _set_new_app_version() {
		await this._remove_all_cache_data();

		this.store_interface.set(CacheAppVersionName, this.app_version, null, true);
	}

	async _remove_all_cache_data() {
		this.store_interface.delete_all_entities();
		await this.indexed_db_interface.remove_db();
	}
}
