import Joi from "joi";
import Validator from "@validators/validator";

const asset_provider_data_schema = Joi.object({
	systems: Joi.array().items(
		Joi.object({
			id: Joi.string().required(),
			rate_contributed_generation: Joi.number().min(0).max(100).allow(null),
			rate_contributed_consumption: Joi.number().min(0).max(100).allow(null),
			rate_contributed_grid_interaction: Joi.number()
				.min(0)
				.max(100)
				.allow(null)
		})
	)
});

export default new Validator(
	asset_provider_data_schema,
	"overview.energy_data_config.entity."
);
