<template>
	<div id="main-div" class="white-bg rounded-s box-shadow1">
		<div class="flex-auto-full-height-content">
			<PageHeader
				class="header"
				:text="$t('general.edit')"
				close_name="owned-users"
			/>
			<div class="content p-sm relative">
				<div v-if="loading">
					<Loader size="30" />
				</div>
				<div v-else-if="user">
					<div class="message-info" v-if="error">
						<Alert :message="error" />
					</div>
					<div class="message-info" v-else-if="success">
						<Alert type="success" message="general.update_successful" />
					</div>

					<EditUser
						v-if="user && role"
						v-bind:class="{ 'p-t-xxxl': error || success }"
						v-model="user"
						:is_new_user="false"
						:user_role="this.role"
						:user_manager_id="this.selected_mananger_id"
					/>
				</div>
			</div>
			<div v-if="!loading && user" class="footer p-sm border-t">
				<div class="flex-horizontal-right">
					<button class="btn btn-primary" v-on:click="save_user()">
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import EditUser from "./user-form-components/EditUser";

import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import OwnedManagerUsersFinderClass from "@src/user/application/owned-manager-users-finder";
import OwnedUserUpdaterClass from "@src/user/application/owned-user-updater";

const OwnedUsersFinder = new OwnedUsersFinderClass();
const OwnedManagerUsersFinder = new OwnedManagerUsersFinderClass();
const OwnedUserUpdater = new OwnedUserUpdaterClass();

export default {
	name: "UserEdit",
	components: {
		Loader,
		Alert,
		PageHeader,
		EditUser
	},
	props: {
		selected_mananger_id: String || null
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			role: null,
			user_id: null,
			user: null
		};
	},
	async mounted() {
		this.loading = true;
		this.error = null;

		try {
			this.role = this.$route.params.role;
			this.user_id = this.$route.params.user_id;

			const user = this.selected_mananger_id
				? await OwnedManagerUsersFinder.get_owned_manager_user(
						this.selected_mananger_id,
						this.role,
						this.user_id
				  )
				: await OwnedUsersFinder.get_owned_user(this.role, this.user_id);
			this.user = user ? Object.assign({}, user) : null;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async save_user() {
			try {
				this.loading = true;
				this.error = null;

				let user_to_update = Object.assign({}, this.user);
				const updated_user = await OwnedUserUpdater.update_user(
					user_to_update,
					this.selected_mananger_id
				);

				this.user = Object.assign({}, updated_user);
				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (error) {
				this.error = error;

				setTimeout(() => {
					this.error = false;
				}, 8000);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#main-div {
	width: 600px;
	height: calc(100% - 50px);
}
.message-info {
	position: fixed;
	width: calc(600px - 30px);
	z-index: 1;
}
</style>
