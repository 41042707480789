import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
		this.backend_interface = new BackendInterface();
	}

	async get_email_notifications_settings() {
		return await this.get("/api/user/email-notification-settings");
	}
}
