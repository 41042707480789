<template>
	<div
		class="custom-checkbox flex-center-content cursor-pointer"
		:style="custom_checkbox_syles"
		v-on:click="toogle"
	>
		<span v-if="no_value">-</span>
		<Icon v-else-if="value" icon="check" size="12" />
		<!-- <span v-else-if="value">&check;</span> -->
	</div>
</template>
<script>
export default {
	name: "CustomCheckbox",
	props: { value: Boolean, size_in_px: Number, no_value: Boolean },
	methods: {
		toogle() {
			this.$emit("input", !this.value);
		}
	},
	computed: {
		custom_checkbox_syles() {
			const size_in_px = this.size_in_px || 20;
			return {
				width: size_in_px + "px",
				height: size_in_px + "px",
				"font-size": size_in_px - 5 + "px"
			};
		}
	}
};
</script>

<style>
.custom-checkbox {
	border: 2px solid black;
	border-radius: 5px;
}
</style>
