<template lang="html">
	<div class="full-width full-height">
		<div id="reset-password" class="p-sm">
			<div>
				<img height="50px" src="/img/sentinel-solar-logo-v2.png" />
			</div>

			<div v-if="loading" class="m-t-lg">
				<Loader size="30" />
			</div>
			<div v-else>
				<div v-if="validation_error" class="m-t-lg">
					<Alert
						v-if="validation_error.field == 'new_password'"
						:validation_error="validation_error"
					/>
					<Alert v-else :message="general.errors.general_error" />
				</div>
				<div v-if="error" class="m-t-lg">
					<Alert :message="error" />
				</div>

				<div class="m-t-lg">
					<div class="font-bold">
						New password
					</div>
					<input
						type="password"
						class="form-control m-t-sm"
						placeholder="Enter your new password"
						v-model="reset_password_params.new_password"
					/>
				</div>

				<div class="m-t-lg">
					<div class="font-bold">
						Repeat new password
					</div>
					<input
						type="password"
						class="form-control m-t-sm"
						placeholder="Enter your new password"
						v-model="repeat_password"
					/>
				</div>

				<div class="m-t-lg">
					<div
						v-if="!success"
						v-on:click="reset_password"
						class="btn btn-primary full-width"
					>
						Change password
					</div>
					<div v-else>
						<Alert
							type="success"
							:message="$t('login.reset_password.success')"
						/>
						<router-link :to="{ name: 'login' }">
							<div class="btn btn-primary right">
								{{ $t("login.forgot_password.go_to_login") }}
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div id="reset-password-footer" class="text-center grey2-color">
			<span>© {{ new Date().getFullYear() }}</span>
			<a href="http://www.nnergix.com" class="m-l-xs" target="_blanc">
				www.nnergix.com
			</a>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import ForgotPasswordServiceClass from "@services/user-services/forgot-password-service";
const ForgotPasswordService = new ForgotPasswordServiceClass();

export default {
	name: "ResetPassword",
	components: {
		Loader,
		Alert
	},
	data() {
		return {
			loading: null,
			error: null,
			validation_error: null,
			reset_password_params: {
				email: null,
				token: null,
				new_password: null
			},
			repeat_password: null,
			success: null
		};
	},
	mounted() {
		const url_params = this.$route.query;
		this.reset_password_params.email = url_params.email;
		this.reset_password_params.token = url_params.token;

		if (
			this.reset_password_params.email == null ||
			this.reset_password_params.token == null
		) {
			this.$router.push("login");
		}
	},
	methods: {
		async reset_password() {
			this.loading = true;
			this.error = null;
			this.validation_error = null;

			if (this.reset_password_params.new_password != this.repeat_password) {
				this.error = "login.reset_password.repeat_password_not_match";
				this.loading = null;
				return;
			}

			try {
				await ForgotPasswordService.reset_password(this.reset_password_params);

				this.success = true;
			} catch (e) {
				if (e.type == "validation") {
					this.validation_error = e;
				} else {
					this.error = "general.errors.general_error";
				}
			} finally {
				this.loading = null;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#reset-password {
	position: absolute;
	width: 500px;
	left: calc(50% - 250px);
	top: 150px;
}
#reset-password-footer {
	position: absolute;
	bottom: 15px;
	width: 500px;
	left: calc(50% - 250px);
}
</style>
