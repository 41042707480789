<template>
	<apexcharts
		v-if="series && chart_options"
		ref="forecast_chart"
		width="100%"
		:height="height"
		type="bar"
		:options="chart_options"
		:series="formatted_series"
		@mounted="chart_mounted"
	></apexcharts>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
	name: "StackedColumnChart",
	props: {
		series: Array,
		series_data: Array,
		hide_legend: Boolean,
		legend_positon: String, //top | right | bottom | left
		legend_font_size_in_px: Number,
		hide_toolbar: Boolean,
		hide_tooltips: Boolean,
		height: String,
		y_axis_formatter: Function,
		x_axis_formatter: Function,
		x_axis_tooltip_formatter: Function,
		data_label_formatter: Function,
		column_width: String,
		data_labels_posisiton: String, //top | center | bottom
		data_labels_orientation: String, //horizontal | vertical
		default_visible_variables: Array
	},
	components: {
		apexcharts: VueApexCharts
	},
	data() {
		return {
			chart_options: null,
			processed_series_data: null
		};
	},
	mounted() {
		this.processed_series_data = this.series_data.filter((variable_data) => {
			const variable_name = variable_data.prop_name;

			return this.series.find((item) => this.$is_number(item[variable_name]));
		});

		this.chart_options = {
			chart: {
				type: "bar",
				stacked: true,
				toolbar: {
					show: this.hide_toolbar ? false : true
				},
				fontFamily: "'Montserrat', sans-serif"
			},
			stroke: {
				width: 1,
				colors: ["#fff"]
			},
			xaxis: {
				categories: this.series.map((item) => item.time),
				labels: {
					show: this.x_axis_formatter ? true : false,
					formatter: this.x_axis_formatter || undefined
				}
			},
			yaxis: {
				show: this.y_axis_formatter ? true : false,
				labels: {
					formatter: this.y_axis_formatter || undefined
				}
			},
			dataLabels: {
				enabled: this.data_label_formatter ? true : false,
				formatter: this.data_label_formatter || undefined,
				offsetY: 5,
				style: {
					fontSize:
						this.data_labels_orientation == "vertical" ? "12px" : "14px",
					fontWeight: "bold",
					colors: ["black"]
				},
				dropShadow: {
					enabled: false
				}
			},
			colors: this.processed_series_data.map((item) => item.color),
			legend: {
				show: this.hide_legend ? false : true,
				showForSingleSeries: this.hide_legend ? false : true,
				position: this.legend_positon || "bottom",
				fontSize: (this.legend_font_size_in_px || 16) + "px",
				fontFamily: "Montserrat",
				fontWeight: 600,
				labels: {
					colors: "var(--grey1)",
					useSeriesColors: false
				},
				markers: {
					width: this.legend_font_size_in_px || 16,
					height: this.legend_font_size_in_px || 16,
					radius: 4
				},
				itemMargin: {
					horizontal: 10
				}
			},
			tooltip: {
				enabled: this.hide_tooltips ? false : true,
				x: {
					formatter: this.x_axis_tooltip_formatter || undefined
				}
			},
			plotOptions: {
				bar: {
					columnWidth: this.column_width || "70%",
					dataLabels: {
						position: this.data_labels_posisiton || "center", // top | center | bottom
						orientation: this.data_labels_orientation || "horizontal", //horizontal | vertical
						hideOverflowingLabels: false
					}
				}
			}
		};
	},
	methods: {
		chart_mounted(chartContext, config) {
			if (!this.default_visible_variables) return null;

			const any_default_visible_variables_has_data =
				this.processed_series_data.find(
					(item) => this.default_visible_variables.indexOf(item.prop_name) != -1
				);
			if (!any_default_visible_variables_has_data) return null;

			const variables = this.processed_series_data.map(
				(item) => item.prop_name
			);
			variables.forEach((variable) => {
				if (!this.default_visible_variables.find((item) => item == variable)) {
					const series_name = this.processed_series_data.find(
						(serie_data) => serie_data.prop_name == variable
					).label;
					//Doc -> https://apexcharts.com/docs/methods/#hideSeries
					chartContext.hideSeries(series_name);
				}
			});
		}
	},
	computed: {
		formatted_series() {
			let result = this.processed_series_data.map((item) => {
				return {
					name: item.multilingual_label
						? this.$t(item.multilingual_label)
						: item.label,
					data: this.series.map((series_item) => series_item[item.prop_name]),
					group: item.group || null
				};
			});

			return result;
		}
	}
};
</script>

<style></style>
