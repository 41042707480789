<template>
	<div class="relative" v-bind:style="{ width: height_in_px + 'px' }">
		<ApexChart
			v-if="any_data_item_has_value_and_not_cero"
			ref="line_chart"
			width="100%"
			:height="height_in_px + 'px'"
			:type="is_donut ? 'donut' : 'pie'"
			:options="chart_options"
			:series="processed_series"
		/>

		<div
			v-else
			class="p-sm"
			v-bind:style="{
				width: height_in_px + 'px',
				height: height_in_px + 'px',
				color: donut_middle_text_color | null
			}"
		>
			<div
				id="empty-pie-chart"
				class="full-width full-height full-rounded flex-center-content"
			>
				<div class="text-center">
					<div v-if="donut_middle_label" class="font-l font-bold">
						{{ donut_middle_label }}
					</div>
					<div class="font-xxxl">
						<span v-if="is_all_values_cero">0</span>
						<span v-else>-</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApexChart from "vue3-apexcharts";

export default {
	components: {
		ApexChart
	},
	name: "ApexChartsPieChart",
	props: {
		data: Array, //[{label: String, color: String, value:Number}]
		hide_legend: Boolean,
		hide_tooltips: Boolean,
		hide_datalabels: Boolean,
		height_in_px: Number,
		is_donut: Boolean,
		donut_middle_label: String,
		donut_middle_text_color: String
	},
	data() {
		return {
			any_data_item_has_value_and_not_cero: this.data.find(
				(item) => this.$is_number(item.value) && item.value != 0
			),
			is_all_values_cero:
				this.data.length == this.data.filter((item) => item.value == 0).length,
			processed_series: this.data.map((item) => item.value)
		};
	},
	computed: {
		chart_options() {
			let chart_options = {
				chart: {
					fontFamily: "'Montserrat', sans-serif"
				},
				labels: this.data.map((item) => item.label),
				colors: this.data.map((item) => item.color),
				legend: {
					show: this.hide_legend ? false : true
				},
				tooltip: {
					enabled: this.hide_tooltips ? false : true
				},
				dataLabels: {
					enabled: this.hide_datalabels ? false : true,
					formatter: (val) => this.$format_number(val, 1) + "%",
					style: {
						fontSize: "14px",
						fontWeight: "bold",
						colors: ["#FFFFFF"]
					},
					background: {
						enabled: true,
						foreColor: "#000000",
						opacity: 1,
						padding: 10,
						borderRadius: 5,
						borderColor: "#000000"
					},
					dropShadow: {
						enabled: false
					}
				}
			};

			if (this.is_donut) {
				//Doc: https://apexcharts.com/docs/options/plotoptions/pie/
				chart_options.stroke = {
					show: true,
					width: 0
				};
				chart_options.states = {
					hover: {
						filter: {
							type: "none"
						}
					}
				};
				chart_options.plotOptions = {
					pie: {
						donut: {
							size: "80%",
							labels: {
								show: this.donut_middle_label ? true : false,
								value: {
									show: true,
									fontSize: "42px",
									//fontFamily: "Helvetica, Arial, sans-serif",
									fontWeight: 600,
									color: this.donut_middle_text_color || "black",
									offsetY: 16
								},
								total: {
									show: true,
									showAlways: false,
									label: this.donut_middle_label,
									fontSize: "17px",
									//fontFamily: "Helvetica, Arial, sans-serif",
									fontWeight: 600,
									color: this.donut_middle_text_color || "black"
								}
							}
						}
					}
				};
			}

			return chart_options;
		}
	}
};
</script>

<style scoped>
#empty-pie-chart {
	border: 17px solid var(--grey3);
}
</style>
