import EnergySystemEraserInterfaceClass from "../infrastructure/energy-system-eraser-interface";

import EnergySystemsStoreUtil from "./utils/energy-systems-store-util";
import AssetsStoreUtil from "@src/asset/application/utils/assets-store-util.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new EnergySystemEraserInterfaceClass();
		this.store_util = EnergySystemsStoreUtil;
		this.assets_store_util = AssetsStoreUtil;
	}

	async remove_energy_system(energy_system_id) {
		try {
			const energy_system = await this.store_util.get_cached_item_by_id(
				energy_system_id
			);

			if (energy_system) {
				let interface_result =
					await this.backend_interface.remove_energy_system(energy_system);
				if (interface_result.deleted_assets_ids) {
					interface_result.deleted_assets_ids.forEach((deleted_asset_id) =>
						this.assets_store_util.remove_item(deleted_asset_id)
					);
				}

				await this.store_util.remove_item(energy_system_id);
			}

			return true;
		} catch (e) {
			throw new AppError(
				"error_remove_energy_system",
				"general.errors.general_error",
				e
			);
		}
	}
}
