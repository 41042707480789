<template>
	<div class="m-t p-t border-t">
		<div id="maintainer-contacts">
			<div
				v-for="contact in maintainer.maintainer_role_extra_data.other_contacts"
			>
				<div class="font-bold">{{ contact.name }}</div>
				<div class="p-l">
					<div v-for="item in contact_props_to_show">
						<span class="grey2-color">
							{{
								$t(
									"entities.user.maintainer_role_extra_data.other_contacts." +
										item
								)
							}}:
						</span>
						<span>{{ contact[item] }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MaintainerContacts",
	props: {
		maintainer: Object
	},
	data() {
		return {
			contact_props_to_show: ["name", "phone", "email", "position"]
		};
	}
};
</script>

<style lang="css" scoped>
#maintainer-contacts {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 15px;
	row-gap: 15px;
}
</style>
