<template>
	<div>
		<div>
			<h2>{{ $t("header.settings") }}</h2>
		</div>

		<div
			id="user-settings-content"
			class="white-bg full-width p-sm overflow-y-scroll rounded-s"
		>
			<div class="display-flex">
				<div style="width: 400px">
					<UserSettingsForm />
				</div>
				<div class="m-l-xl" style="width: 300px">
					<div v-if="can_user('edit_locations_watermark')">
						<div>
							<UserChangeWatermark />
						</div>
					</div>

					<UserEnergyMeasurementProvidersSettings
						v-if="can_user('edit_energy_provider_auth')"
					/>

					<div style="width: 300px">
						<UserChangePassword />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@components/sub_components/Loader";

import UserSettingsForm from "./UserSettingsForm";
import UserChangeWatermark from "./UserChangeWatermark";
import UserEnergyMeasurementProvidersSettings from "./UserEnergyMeasurementProvidersSettings";
import UserChangePassword from "./UserChangePassword";

import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "UserSettings",
	components: {
		Loader,
		UserSettingsForm,
		UserChangeWatermark,
		UserEnergyMeasurementProvidersSettings,
		UserChangePassword
	},
	methods: {
		can_user(permission) {
			return UserPermissionsUtil.can_user(permission);
		}
	}
};
</script>

<style media="screen">
#user-settings-content {
	position: absolute;
	top: 50px;
	height: calc(100% - 50px);
}
</style>
