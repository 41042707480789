import AssetEraserInterfaceClass from "../infrastructure/asset-eraser-interface";
import AssetsStoreUtil from "./utils/assets-store-util";
import AssetsLastUpdateUtil from "./utils/assets-last-update-util";
import AssetsGroupAssetsStoreUtilClass from "../../assets-group/application/utils/assets-group-assets-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetEraserInterfaceClass();

		this.assets_last_update_util = new AssetsLastUpdateUtil();
		this.store_util = AssetsStoreUtil;
		this.assets_defaul_group_assets_summary_store_util =
			new AssetsGroupAssetsStoreUtilClass("default");
	}

	async remove_asset(asset_id) {
		try {
			await this.backend_interface.remove_asset(asset_id);

			await this.store_util.remove_item(asset_id);

			this.assets_last_update_util.set_assets_last_update();
			await this.assets_defaul_group_assets_summary_store_util.remove_item(
				asset_id
			);

			return true;
		} catch (e) {
			throw new AppError(
				"error_remove_asset",
				"general.errors.general_error",
				e
			);
		}
	}
}
