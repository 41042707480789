import Joi from "joi";
import Validator from "@validators/validator";
import CoordinatesValidator from "@src/shared/domain/coordinates-validator";

const energy_system_general_data_schema = Joi.object({
	name: Joi.string().required(),
	commissioning_date: Joi.date(),
	cups: Joi.string(),
	coordinates: CoordinatesValidator.schema
});
export default new Validator(energy_system_general_data_schema);
