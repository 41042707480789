import MultilingualServiceClass from "@services/multilingual-service.js";
const MultilingualService = new MultilingualServiceClass();

export default {
	install: function (app, options) {
		app.config.globalProperties.$t = function (string, args = undefined) {
			return MultilingualService.translate(string, args);
		};

		app.config.globalProperties.$tc = function (string, number) {
			return MultilingualService.translate_plural(string, number);
		};

		app.config.globalProperties.$te = function (string) {
			return true;
		};
	}
};
