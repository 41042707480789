<template lang="html">
	<div class="full-width white-bg rounded-s overflow-hidden">
		<PageHeader :text="$t('asset_reports.menu_label')" />

		<div class="p-sm">
			<div>
				{{ $t("asset_reports.add_comment_label") }}
			</div>
			<div class="m-t">
				<textarea
					class="form-control"
					v-model="form_comment"
					rows="8"
					cols="80"
					style="height: 200px"
				></textarea>
			</div>
		</div>

		<hr />

		<div class="p-sm">
			<div>
				<div class="btn btn-primary right" v-on:click="emmit_comment">
					{{ $t("general.save") }}
				</div>
				<div class="btn btn-danger m-r right" v-on:click="hide_edit_comment">
					{{ $t("general.cancel") }}
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";

export default {
	name: "AssetReportAddComment",
	components: {
		PageHeader
	},
	props: {
		report_comment: String
	},
	data() {
		return {
			form_comment: this.report_comment || null
		};
	},
	methods: {
		emmit_comment() {
			this.$emit("comment_edited", this.form_comment);
		},
		hide_edit_comment() {
			this.$emit("hide_edit_comment");
		}
	}
};
</script>

<style lang="css" scoped></style>
