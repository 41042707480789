<template>
	<div class="relative">
		<div
			v-if="loading"
			v-bind:style="loading_style"
			class="flex-center-content"
		>
			<Loader size="30" />
		</div>
		<div v-else-if="user">
			<div v-if="edit">
				<FileButton v-on:file_uploaded="update_user_avatar">
					<div class="edit-avatar-button">
						<div class="btn btn-primary flex-center-content">
							<Icon icon="edit" size="20" />
						</div>
					</div>
				</FileButton>
			</div>
			<div class="avatar overflow-hidden" v-bind:style="style">
				<div v-if="user && !user.avatar" class="text-center gray-bg">
					{{
						user && user.first_name
							? user.first_name.substring(0, 1).toUpperCase()
							: null
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import FileButton from "@form_components/FileButton";

import UserAvatarUpdaterClass from "@src/user/application/user-avatar-updater";
const UserAvatarUpdater = new UserAvatarUpdaterClass();

export default {
	name: "UserAvatar",
	components: {
		Loader,
		FileButton
	},
	props: {
		user: Object,
		user_role: String,
		url: String,
		size: [Number, String],
		edit: Boolean,
		user_manager_id: [String, null]
	},
	data() {
		return {
			loading: false,
			loading_style: {
				"min-height": (this.size || 50) + "px"
			}
		};
	},
	computed: {
		avatar_url: function () {
			if (this.user) return this.user.avatar || null;
			else if (this.url) return this.url;
		},
		style: function () {
			const size = this.size || 50;
			let style = {
				"font-size": size * 0.75 + "px"
			};

			if (this.avatar_url)
				style["background-image"] = "url(" + this.avatar_url + ")";
			else {
				style.border = "2px solid rgb(74, 144, 226)";
				style["line-height"] = size + "px";
			}

			style.width = size + "px";
			style.height = size + "px";

			return style;
		}
	},
	methods: {
		async update_user_avatar(image_file) {
			this.loading = true;

			try {
				const allowed_tyes = [
					"image/jpeg",
					"image/jpg",
					"image/png",
					"image/gif"
				];
				const max_allowed_size_in_mb = 1.5;
				const file_size_in_mb = image_file.size / 1024 / 1024;

				if (
					allowed_tyes.indexOf(image_file.type) == -1 ||
					file_size_in_mb > max_allowed_size_in_mb
				) {
					this.$emit("error_updating_avatar");
				} else {
					const updated_user = await UserAvatarUpdater.upload_user_avatar(
						this.user.id,
						image_file,
						this.user_role || null,
						this.user_manager_id || null
					);

					this.user.avatar = updated_user.avatar;
					this.$emit("success_updating_avatar");
				}
			} catch (error) {
				this.$emit("error_updating_avatar");
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
.avatar {
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-radius: 50%;
	color: #9b9b9b;
}
</style>
<style>
.edit-avatar-button {
	position: absolute;
	top: 0px;
	left: -5px;
}
.edit-avatar-button > .btn {
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding: 0;
}
</style>
