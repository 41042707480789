<template>
	<div class="full-width full-height">
		<div v-if="loading" class="m-t">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else-if="processed_series">
			<div class="relative">
				<div v-if="!hide_options" class="absolute" style="right: 0">
					<Tooltip position="left" theme="light">
						<template #content>
							<Icon icon="info" />
						</template>

						<template #tooltip-content>
							<div class="display-flex gap-m p-sm">
								<div>
									<div class="display-flex">
										<div class="m-r-xs flex-center-content">Local</div>
										<div class="flex-center-content">
											<CustomCheckbox v-model="utc_timezone" size="small" />
										</div>
										<div class="m-l-xs flex-center-content">UTC</div>
									</div>
								</div>

								<div>
									<div class="cursor-pointer" v-on:click="download_xlsx_file">
										<Icon icon="file-excel" />
									</div>
								</div>
							</div>
						</template>
					</Tooltip>
				</div>

				<LineChartCustomLegend
					v-if="
						!hide_legend && series_used_variables && legend_positon == 'top'
					"
					:custom_legend_variables_to_show="custom_legend_variables_to_show"
					:variables_data="
						series_used_variables.map((variable_slug) => {
							let variable_data = variables_data[variable_slug];
							variable_data.variable_slug = variable_slug;
							return variable_data;
						})
					"
					v-on:toogle_variable="toogle_variable"
				/>
			</div>

			<apexchart
				ref="line_chart"
				width="100%"
				:height="height"
				type="line"
				:options="chart_options"
				:series="processed_series"
				@mounted="chart_mounted"
			></apexchart>

			<LineChartCustomLegend
				v-if="
					!hide_legend && series_used_variables && legend_positon == 'bottom'
				"
				:custom_legend_variables_to_show="custom_legend_variables_to_show"
				:variables_data="
					series_used_variables.map((variable_slug) => {
						let variable_data = variables_data[variable_slug];
						variable_data.variable_slug = variable_slug;
						return variable_data;
					})
				"
				v-on:toogle_variable="toogle_variable"
			/>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import CustomCheckbox from "@form_components/CustomCheckbox";
import VueApexCharts from "vue3-apexcharts";
import LineChartCustomLegend from "./charts-shared-components/LineChartCustomLegend.vue";

import moment from "moment";

export default {
	components: {
		apexchart: VueApexCharts,
		Loader,
		Alert,
		CustomCheckbox,
		LineChartCustomLegend
	},
	name: "LineChart",
	props: {
		series: Array,
		variables_data: Object, //{variable_slug: {unit: String, is_percentage: Boolean, name: String(multilingual), color: "String", dashed_number: Number}}
		default_visible_variables: Array,
		height: String,
		single_y_axis: Boolean,
		y_axis_formatter: Function,
		hide_options: Boolean,
		hide_legend: Boolean,
		legend_positon: String, //top | bottom
		x_axis_annotations: Array // [{date: Date(), color: String, multilingual_text: String}]
	},
	data() {
		return {
			loading: false,
			error: null,
			utc_timezone: false,
			visible_variables: null,
			processed_series: null,
			series_used_variables: null,
			chart: null,
			custom_legend_variables_to_show: {}
		};
	},
	computed: {
		chart_options() {
			return {
				chart: {
					id: "line_chart",
					toolbar: {
						//show: this.hide_options ? false : true,
						show: false,
						tools: { download: true, zoom: true, pan: false, reset: true }
					},
					zoom: {
						//enabled: this.hide_options ? false : true,
						enabled: false
					},
					animations: {
						enabled: false
					}
				},
				legend: {
					show: false
					//show: this.hide_legend ? false : true,
					/*position: this.legend_positon || "bottom",
					fontSize: "16px",
					fontFamily: "Montserrat",
					fontWeight: 600,
					formatter: (series_name, opts) => {
						return this.get_variable_name(series_name);
						//return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
					},
					labels: {
						colors: "var(--grey1)",
						useSeriesColors: false
					},
					markers: {
						width: 16,
						height: 16,
						radius: 4
					},
					itemMargin: {
						horizontal: 10
					}*/
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: this.utc_timezone,
						style: {
							fontSize: "15px"
						}
					},
					axisTicks: {
						show: true,
						borderType: "dotted"
					}
				},
				yaxis: this.get_variables_yaxis(),
				tooltip: {
					enabled: true,
					shared: true,
					followCursor: true,
					x: {
						format: "HH:mm d MMM"
					},
					y: {
						title: {
							formatter: (serie_name) => {
								const variable_unit = this.get_variable_unit(serie_name);
								const variable_name = this.get_variable_name(serie_name);

								return (
									"<span>" + variable_name + " (" + variable_unit + "): </span>"
								);
							}
						},
						formatter: (value, series) => {
							return this.$format_number(value);
						}
					},
					theme: "light",
					style: {
						fontSize: "18px"
					}
				},
				stroke: {
					width: 3,
					curve: "smooth",
					dashArray: this.series_used_variables
						? this.series_used_variables.map(
								(item) => this.variables_data[item].dashed_number || 0
						  )
						: undefined
				},
				dataLabels: {
					enabled: false
				},
				series_formatters: [],
				//Annotations doc: https://apexcharts.com/docs/options/annotations/
				annotations: {
					xaxis: this.x_axis_annotations
						? this.x_axis_annotations.map((item) => {
								return {
									x: item.date.getTime(),
									borderColor: item.color,
									strokeDashArray: 3,
									label: {
										borderColor: "transparent",
										position: "top",
										style: {
											color: item.color,
											fontSize: "15px",
											borderWidth: 0
										},
										text: this.$t(item.multilingual_text)
									}
								};
						  })
						: undefined
				}
			};
		}
	},
	methods: {
		get_variables_yaxis() {
			if (this.single_y_axis) {
				return {
					show: true,
					axisBorder: {
						show: true,
						color: "#78909C",
						offsetX: 0,
						offsetY: 0
					},
					labels: {
						formatter: this.y_axis_formatter || undefined
					}
				};
			} else {
				this.series_used_variables = this.processed_series.map(
					(item) => item.name
				);

				var result = [];
				var units_serie_name = {};
				this.series_used_variables.forEach((variable_name) => {
					const variable_data = this.variables_data[variable_name];
					const variable_group_name = variable_data.is_percentage
						? "percenage"
						: variable_data.unit;

					if (!units_serie_name[variable_group_name])
						units_serie_name[variable_group_name] = variable_name;

					result.push({
						seriesName: units_serie_name[variable_group_name],
						show: false
					});
				});

				function format_label_unit(unit) {
					return unit.replace("&sup2;", "2");
				}

				const first_y_axis_unit =
					this.variables_data[result[0].seriesName].unit;
				result[0].labels = {
					formatter: (value) =>
						this.$format_number(value, 0) +
						" " +
						format_label_unit(first_y_axis_unit)
				};

				const different_y_axis_number = Object.keys(units_serie_name).length;
				if (different_y_axis_number == 1) {
					result[0].show = true;
				} else if (different_y_axis_number == 2) {
					//Always show first y-axis
					result[0].show = true;
					result[0].showAlways = true;

					//Show always seconds y-axis if more than one variable needs it
					let second_y_axis = result.filter(
						(item) =>
							item.seriesName ==
							units_serie_name[Object.keys(units_serie_name)[1]]
					);
					second_y_axis[0].show = true;
					second_y_axis[0].opposite = true;
					const second_y_axis_unit =
						this.variables_data[second_y_axis[0].seriesName].unit;
					second_y_axis[0].labels = {
						formatter: (value) =>
							this.$format_number(value, 0) +
							" " +
							format_label_unit(second_y_axis_unit)
					};
					if (second_y_axis.length > 1) second_y_axis[0].showAlways = true;
				}

				return result;
			}
		},
		is_variable_visible(variable_slug) {
			if (!this.visible_variables) return true;
			else
				return this.visible_variables.indexOf(variable_slug) == -1
					? false
					: true;
		},
		get_varible_color(variable_slug) {
			return this.variables_data[variable_slug]
				? String(this.variables_data[variable_slug].color)
				: null;
		},
		get_variable_name(variable_slug) {
			let multilingual_slug = this.variables_data[variable_slug].name;

			return this.$t(multilingual_slug) || multilingual_slug;
		},
		get_variable_unit(variable_slug) {
			return this.variables_data[variable_slug].is_percentage
				? "%"
				: this.variables_data[variable_slug].unit;
		},
		load_chart_data() {
			this.loading = true;
			this.processed_series = null;
			this.error = null;

			try {
				var cache_series = {};

				this.series.forEach((ts_row) => {
					const time =
						ts_row.time instanceof Date
							? ts_row.time
							: new Date(parseInt(ts_row.time) * 1000);

					for (let variable_slug in this.variables_data) {
						if (!cache_series[variable_slug]) {
							cache_series[variable_slug] = [];
						}

						cache_series[variable_slug].push([
							time,
							this.$is_number(ts_row[variable_slug])
								? ts_row[variable_slug]
								: null
						]);
					}
				});

				var return_series = [];
				for (var key in this.variables_data) {
					const variable_has_any_number_value = cache_series[key]
						? cache_series[key].find((variable_data) =>
								this.$is_number(variable_data[1])
						  )
						: false;

					if (variable_has_any_number_value)
						return_series.push({
							name: key,
							data: cache_series[key],
							type: this.variables_data[key].chart_type || "line",
							color: this.get_varible_color(key)
						});
				}

				this.processed_series = return_series;
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		download_xlsx_file() {
			let array_content = [];

			//1st row categories
			let first_row = [""];
			for (let variable_slug in this.variables_data) {
				const variable_data = this.variables_data[variable_slug];
				let multilingual_variable_name = this.$t(variable_data.name);

				if (variable_data.is_percentage) multilingual_variable_name += " (%)";
				else if (variable_data.unit) {
					multilingual_variable_name += " (" + variable_data.unit + ")";
				}

				first_row.push(multilingual_variable_name);
			}
			array_content.push(first_row);

			//Time serie data
			this.series.forEach((series_item) => {
				let row_time_formatted = Number.isInteger(series_item.time)
					? moment.unix(series_item.time)
					: moment(series_item.time);
				if (this.utc_timezone) {
					row_time_formatted = moment(row_time_formatted)
						.utc()
						.format("YYYY-MM-DD HH:mm");
				}
				row_time_formatted = this.$format_date_to_day_hour_minute(
					moment(row_time_formatted).toDate()
				);

				let next_row = [row_time_formatted];

				for (let variable_slug in this.variables_data) {
					next_row.push(
						series_item[variable_slug] != undefined
							? series_item[variable_slug]
							: null
					);
				}

				array_content.push(next_row);
			});

			const file_name = "Export.xlsx";
			this.$download_array_as_xlsx_file(array_content, file_name);
		},
		chart_mounted(chartContext, config) {
			this.chart = chartContext;
			if (!this.default_visible_variables) return null;

			const any_default_visible_variables_has_data = this.processed_series.find(
				(item) => this.default_visible_variables.indexOf(item.name) != -1
			);
			if (!any_default_visible_variables_has_data) return null;

			this.series_used_variables.forEach((variable) => {
				if (!this.default_visible_variables.find((item) => item == variable)) {
					//Doc -> https://apexcharts.com/docs/methods/#hideSeries
					this.chart.hideSeries(variable);
					this.custom_legend_variables_to_show[variable] = false;
				} else this.custom_legend_variables_to_show[variable] = true;
			});
		},
		toogle_variable(variable_slug) {
			if (
				!this.chart ||
				Object.keys(this.custom_legend_variables_to_show).length <= 1
			)
				return;

			this.custom_legend_variables_to_show[variable_slug] =
				!this.custom_legend_variables_to_show[variable_slug];

			if (this.custom_legend_variables_to_show[variable_slug])
				this.chart.showSeries(variable_slug);
			else this.chart.hideSeries(variable_slug);
		}
	},
	mounted() {
		this.visible_variables = this.default_visible_variables;
		this.load_chart_data();
	}
};
</script>

<style scoped></style>
<style>
/*Hide zoom buttons*/
.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
	display: none;
}
/*
	Tooltip css mod doc: https://stackoverflow.com/a/61374184
*/
.apexcharts-tooltip {
	border-radius: 12px;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
	&.apexcharts-theme-light .apexcharts-tooltip-title {
		background-color: var(--grey1) !important;
		border-bottom: 0;
		font-weight: bold;
		color: white;
		font-size: 18px !important;
	}
	.apexcharts-tooltip-text {
		font-size: 18px !important;
		color: var(--grey1);
	}
}
</style>
