import AssetsGroupUpdaterInterfaceClass from "../infrastructure/assets-group-assets-updater-interface.js";
import AssetFinderClass from "../../asset/application/asset-finder.js";

import AssetsGroupAssetsStoreUtilClass from "./utils/assets-group-assets-store-util.js";
import AssetsGroupsStoreUtil from "./utils/assets-groups-store-util.js";

import AssetsGroupAssetsSummaryStoreUtilClass from "./utils/assets-group-assets-summary-store-util.js";
import AssetsGroupSummaryStoreUtilClass from "./utils/assets-group-summary-store-util.js";
import AssetsGroupSystemsSummaryStoreUtilClass from "./utils/assets-group-systems-summary-store-util.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetsGroupUpdaterInterfaceClass();
		this.asset_finder = new AssetFinderClass();
		this.store_util = null;
		this.assets_group_store_util = AssetsGroupsStoreUtil;
	}

	_get_store_util(assets_group_id) {
		return new AssetsGroupAssetsStoreUtilClass(assets_group_id);
	}

	async _remove_assets_group_stored_data(assets_group_id) {
		const assets_group_summary_store_util =
			new AssetsGroupSummaryStoreUtilClass(assets_group_id);
		await assets_group_summary_store_util.remove_all_items();

		const assets_group_assets_summary_store_util =
			new AssetsGroupAssetsSummaryStoreUtilClass(assets_group_id);
		await assets_group_assets_summary_store_util.remove_all_items();

		const assets_group_systems_summary_store_util =
			new AssetsGroupSystemsSummaryStoreUtilClass(assets_group_id);
		await assets_group_systems_summary_store_util.remove_all_items();
	}

	async add_assets_to_assets_group(assets_group_id, assets_ids) {
		try {
			await this.backend_interface.add_assets_to_assets_group(
				assets_group_id,
				assets_ids
			);

			this.store_util = this._get_store_util(assets_group_id);

			for (let index in assets_ids) {
				const asset_id = assets_ids[index];
				const asset = await this.asset_finder.get_asset(asset_id);
				await this.store_util.add_or_update_item(asset);
			}

			let affected_assets_group =
				await this.assets_group_store_util.get_cached_item_by_id(
					assets_group_id
				);
			affected_assets_group.total_assets += assets_ids.length;
			this.assets_group_store_util.add_or_update_item(affected_assets_group);

			await this._remove_assets_group_stored_data(assets_group_id);

			return true;
		} catch (e) {
			throw new AppError(
				"error_add_assets_to_assets_group",
				"general.errors.general_error",
				e
			);
		}
	}

	async remove_assets_to_assets_group(assets_group_id, assets_ids) {
		try {
			await this.backend_interface.remove_assets_to_assets_group(
				assets_group_id,
				assets_ids
			);

			this.store_util = this._get_store_util(assets_group_id);

			for (let index in assets_ids) {
				const asset_id = assets_ids[index];

				await this.store_util.remove_item(asset_id);
			}

			let affected_assets_group =
				await this.assets_group_store_util.get_cached_item_by_id(
					assets_group_id
				);
			affected_assets_group.total_assets -= assets_ids.length;
			this.assets_group_store_util.add_or_update_item(affected_assets_group);

			await this._remove_assets_group_stored_data(assets_group_id);

			return true;
		} catch (e) {
			throw new AppError(
				"error_remove_assets_to_assets_group",
				"general.errors.general_error",
				e
			);
		}
	}
}
