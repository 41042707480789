<template>
	<div v-if="status && id" class="display-flex gap-m">
		<div
			class="full-rounded"
			style="width: 18px; height: 18px"
			v-bind:style="{
				backgroundColor: status.color
			}"
		></div>
		<div class="flex-vertical-center">
			<div>
				<span>{{ status.name }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import TicketServiceClass from "@services/user-services/ticket-service";

const TicketService = new TicketServiceClass();

export default {
	name: "TicketStatus",
	props: {
		id: String
	},
	data() {
		return {
			status: null
		};
	},
	async created() {
		const all_status = await TicketService.get_ticketing_status();
		if (all_status[this.id]) this.status = all_status[this.id];
	}
};
</script>
