<template lang="html">
	<div>
		<div v-if="energy_data_props">
			<div class="font-bold">
				{{ $t("pv_main_panels.monthly_accumulated") }}
				{{ $format_date_to_month(energy_aggregated_data.time) }}
			</div>

			<div class="m-t"></div>

			<div>
				<div class="aggregated-data" v-for="item in energy_data_props">
					<div class="left text-center col1">
						<Icon :icon="item.icon" size="lg" />
					</div>
					<div class="left text-center col2">{{ $t(item.label) }}</div>
					<div class="right text-right font-bold col3">
						{{ item.value }} kWh
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LocationMonthlyEnergy",
	props: {
		energy_aggregated_data: Object
	},
	data() {
		return {
			energy_data_props: [
				{
					label: "pv_main_panels.generation",
					value: this.energy_aggregated_data.energy_production,
					icon: "solar-panel"
				},
				{
					label: "pv_main_panels.consumption",
					value: this.energy_aggregated_data.energy_consumption,
					icon: "lightbulb"
				},
				{
					label: "pv_main_panels.to_grid",
					value: this.energy_aggregated_data.energy_to_grid,
					icon: "grid-tower-arrow-up"
				},
				{
					label: "pv_main_panels.from_grid",
					value: this.energy_aggregated_data.energy_from_grid,
					icon: "grid-tower-arrow-down"
				},
				{
					label: "pv_main_panels.solar_consumption",
					value: this.energy_aggregated_data.energy_self_consumption,
					icon: "sun"
				}
			]
		};
	}
};
</script>

<style lang="css" scoped>
.aggregated-data {
	padding: 5px 0;
	line-height: 30px;
	border-bottom: 1px solid grey;
}
.col1 {
	width: 30px;
}
.col2 {
	width: calc(70% - 30px);
}
.col3 {
	width: 30%;
}
</style>
