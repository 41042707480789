<template>
	<div>
		<div id="add-new-energy-system" class="white-bg rounded-s">
			<PageHeader
				:text="
					!success
						? $t(
								'user_settings.energy_systems_manager.add_new_energy_system_title'
						  )
						: null
				"
				close_name="user-energy-systems"
				class="rounded-s_top"
			/>
			<div class="p-sm">
				<div v-if="loading" class="flex-center-content p-t-xl p-b-xl">
					<Loader size="30" />
				</div>
				<div v-else-if="error" class="p-sm">
					<Alert :message="error" />
				</div>
				<div v-else-if="success" class="p-t-xl p-b-xl">
					<div>
						<div class="flex-center-content">
							<SuccessCircledCheckIcon />
						</div>
						<div class="flex-center-content m-t font-bold grey-color">
							<div class="text-center" style="width: 250px">
								<span>{{
									$t(
										"user_settings.energy_systems_manager.successfully_created"
									)
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div v-if="validation_error" class="m-b">
						<Alert :message="validation_error" />
					</div>

					<div>
						<div>{{ $tc("entities.energy_system.name", 1) }}<sup>*</sup></div>
						<div>
							<TextInput
								v-bind:class="{
									'danger-border':
										validation_error && validation_error.field == 'name'
								}"
								v-model="new_energy_system.name"
							/>
						</div>
					</div>

					<div class="m-t">
						<div>
							{{ $t("entities.energy_system.system_type") }}<sup>*</sup>
						</div>
						<div>
							<Multiselect
								v-bind:class="{
									'danger-border':
										validation_error && validation_error.field == 'system_type'
								}"
								v-model="new_energy_system.system_type"
								:options="system_type_multiselect_options"
								track_by="key"
								label="label"
								:placeholder="$t('general.select')"
							/>
						</div>
					</div>

					<div class="m-t">
						<div>{{ $t("entities.energy_system.asset_id") }}<sup>*</sup></div>
						<div>
							<AssetMultiSelect
								v-bind:class="{
									'danger-border':
										validation_error && validation_error.field == 'asset_id'
								}"
								v-model="new_energy_system.asset_id"
								:multiple="false"
								:allow_select_all="false"
							/>
							<!-- <Multiselect
								v-bind:class="{
									'danger-border':
										validation_error && validation_error.field == 'asset_id'
								}"
								v-model="new_energy_system.system_type"
								:options="system_type_multiselect_options"
								track_by="key"
								label="label"
								:placeholder="$t('general.select')"
							/> -->
						</div>
					</div>

					<div class="flex-horizontal-right m-t-xl">
						<div class="btn btn-primary" v-on:click="create_energy_system">
							{{ $t("general.add") }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";
import TextInput from "@form_components/TextInput.vue";
import Multiselect from "@form_components/Multiselect.vue";
import SuccessCircledCheckIcon from "@sub_components/SuccessCircledCheckIcon.vue";
import AssetMultiSelect from "@form_components/LocationSelect2.vue";

import EnergySystemCreatorClass from "@src/energy-system/application/energy-system-creator.js";
const EnergySystemCreator = new EnergySystemCreatorClass();

export default {
	name: "EnergySystemWithProviderCreate",
	components: {
		Loader,
		PageHeader,
		Alert,
		Multiselect,
		TextInput,
		SuccessCircledCheckIcon,
		AssetMultiSelect
	},
	data() {
		return {
			loading: false,
			validation_error: null,
			error: null,
			success: false,
			new_energy_system: {
				name: null,
				system_type: null,
				asset_id: null
			}
		};
	},
	methods: {
		async create_energy_system() {
			this.loading = true;
			this.validation_error = null;
			this.error = null;

			try {
				await EnergySystemCreator.create_energy_system(this.new_energy_system);

				this.success = true;
				this.$emit("system_created");
			} catch (error) {
				if (error.type == "validation") {
					this.validation_error = error;
				} else {
					this.error = error;
				}
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		system_type_multiselect_options() {
			const system_type_options = [
				"only_generation",
				"only_consumption",
				"generation_and_consumption",
				"only_grid_interaction"
			];

			return system_type_options.map((item) => {
				return {
					key: item,
					label: this.$t("entities.energy_system.system_type_options." + item)
				};
			});
		}
	}
};
</script>

<style scoped>
#add-new-energy-system {
	width: 600px;
}
.sync_result_message {
	max-width: 250px;
}
.sync_result_message2 {
	max-width: 350px;
}
</style>
