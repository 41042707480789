<template>
	<div>
		<div v-on:click="choose_files()">
			<slot></slot>
		</div>

		<input
			type="file"
			hidden
			ref="file_button_ref"
			v-on:change="handle_file_upload()"
		/>
	</div>
</template>

<script>
export default {
	name: "LocationOverview",
	props: {
		text: String
	},
	data() {
		return {
			has_input_button: !!this.$slots.custom_file_button
		};
	},
	methods: {
		choose_files() {
			this.$refs.file_button_ref.click();
		},
		handle_file_upload() {
			this.$emit("file_uploaded", this.$refs.file_button_ref.files[0]);
		}
	}
};
</script>
