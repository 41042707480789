<template>
	<div class="full-width full-height">
		<div v-if="loading" class="m-t">
			<Loader size="30" />
		</div>
		<div v-else-if="error" class="header p-b">
			<Alert :message="error" />
		</div>
		<div v-else-if="asset" class="flex-auto-full-height-content">
			<div class="content">
				<div>
					<div class="font-bold border-b">
						{{ $t("asset_info.common_data.basic_info") }}
					</div>
					<div class="grid-with-same-cols-width-and-padding m-t">
						<div>
							<div class="font-bold">{{ $t("entities.asset.name") }} *</div>
							<TextInput v-model="asset_data_to_update.name" />
						</div>
						<div>
							<div class="font-bold">{{ $t("entities.asset.custom_id") }}</div>
							<TextInput v-model="asset_data_to_update.custom_id" />
						</div>
					</div>

					<div class="m-t">
						<div class="font-bold">{{ $t("entities.asset.address") }}</div>
						<TextInput v-model="asset_other_data_to_update.address" />
					</div>

					<div class="grid-with-same-cols-width-and-padding m-t">
						<div>
							<div class="font-bold">
								{{ $t("entities.asset.coordinates.lat") }} *
							</div>
							<NumberInput
								v-model="asset_data_to_update.coordinates.lat"
								:min="-90"
								:max="90"
							/>
						</div>
						<div>
							<div class="font-bold">
								{{ $t("entities.asset.coordinates.lon") }} *
							</div>
							<NumberInput
								v-model="asset_data_to_update.coordinates.lon"
								:min="-180"
								:max="180"
							/>
						</div>
					</div>
				</div>

				<div class="m-t-xl">
					<div class="font-bold border-b">
						{{ $t("asset_info.common_data.other_data") }}
					</div>

					<div class="display-flex m-t">
						<div v-if="asset_other_data.asset_image_url">
							<img id="asset-image" :src="asset_other_data.asset_image_url" />
						</div>
						<div class="p-l">
							<div class="display-flex">
								<FileButton v-on:file_uploaded="image_uploaded">
									<div class="btn btn-primary">
										{{ $t("asset_info.common_data.upload_image") }}
									</div>
								</FileButton>

								<div
									v-if="asset_new_image_error"
									class="m-l danger-color flex-vertical-center"
								>
									<span>{{
										$t("asset_info.common_data.not_valid_image")
									}}</span>
								</div>
							</div>
							<div v-if="asset_new_image" class="m-t">
								<span>{{ asset_new_image.name }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer border-t p-t text-right">
				<button class="btn btn-primary" v-on:click="update_asset">
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import TextInput from "@form_components/TextInput";
import NumberInput from "@form_components/NumberInput";
import FileButton from "@form_components/FileButton";

import AssetsFinderClass from "@src/asset/application/assets-finder";
import AssetUpdaterClass from "@src/asset/application/asset-updater.js";
import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder.js";
import AssetOtherDataUpdaterClass from "@src/asset/application/asset-other-data-updater.js";

const AssetsFinder = new AssetsFinderClass();
const AssetUpdater = new AssetUpdaterClass();
const AssetOtherDataFinder = new AssetOtherDataFinderClass();
const AssetOtherDataUpdater = new AssetOtherDataUpdaterClass();

export default {
	name: "AssetEditCommonData",
	components: {
		Loader,
		Alert,
		TextInput,
		NumberInput,
		FileButton
	},
	props: {
		map_coordinates: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			asset: null,
			asset_data_to_update: null,
			asset_other_data: null,
			asset_other_data_to_update: null,
			asset_new_image: null,
			asset_new_image_error: false
		};
	},
	async mounted() {
		await this.load_asset_other_data();
	},
	methods: {
		async load_asset_other_data() {
			this.loading = true;
			this.error = null;

			try {
				const asset_id = this.$route.params.asset_id;
				const asset = await AssetsFinder.get_asset_by_id(asset_id);
				this.asset = Object.assign({}, asset);

				this.asset_data_to_update = {
					name: this.asset.name,
					coordinates: this.asset.coordinates,
					custom_id: this.asset.custom_id
				};

				const asset_other_data =
					await AssetOtherDataFinder.get_asset_other_data(asset_id);
				this.asset_other_data = Object.assign({}, asset_other_data);

				this.asset_other_data_to_update = {
					address: this.asset_other_data.address
				};
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		image_uploaded(uploaded_file) {
			this.asset_new_image_error = null;
			this.asset_new_image = null;

			const allowed_tyes = [
				"image/jpeg",
				"image/jpg",
				"image/png",
				"image/gif"
			];
			const max_allowed_size_in_mb = 1.5;
			const file_size_in_mb = uploaded_file.size / 1024 / 1024;

			if (
				allowed_tyes.indexOf(uploaded_file.type) == -1 ||
				file_size_in_mb > max_allowed_size_in_mb
			) {
				this.asset_new_image_error = true;
			} else {
				this.asset_new_image = uploaded_file;
			}
		},
		async update_asset() {
			this.loading = true;
			this.error = null;

			try {
				await AssetUpdater.update_asset(
					this.asset.id,
					this.asset_data_to_update
				);

				await AssetOtherDataUpdater.update_asset_other_data(
					this.asset.id,
					this.asset_other_data_to_update,
					this.asset_new_image
				);
				await this.load_asset_other_data();
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		map_coordinates(new_value, old_value) {
			this.asset_data_to_update.coordinates = new_value;
		},
		"asset_data_to_update.coordinates"(new_value) {
			this.$emit("coordinates_updated", new_value);
		}
	}
};
</script>

<style scoped>
#asset-image {
	max-width: 300px;
	max-height: 300px;
}
</style>
