import EnergySystemFinderInterfaceClass from "../infrastructure/energy-system-finder-interface";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator";

import EnergySystemStoreUtil from "./utils/energy-system-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new EnergySystemFinderInterfaceClass();
		this.store_util = EnergySystemStoreUtil;
		this.validator = EnergySystemFinderValidator;
	}

	async get_energy_system(energy_system_id, force_download = false) {
		let energy_system = await this.store_util.get_cached_item_by_id(
			energy_system_id
		);
		if (energy_system && !force_download) return energy_system;

		try {
			const interface_data = await this.backend_interface.get_energy_system(
				energy_system_id
			);

			const validated_data = this.validator.validate(interface_data);

			await this.store_util.add_or_update_item(validated_data);

			return await this.store_util.get_cached_item_by_id(energy_system_id);
		} catch (e) {
			throw new AppError(
				"error_get_user_energy_system",
				"general.errors.general_error",
				e
			);
		}
	}
}
