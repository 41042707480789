<template lang="html">
	<div>
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<Multiselect
			v-else-if="energy_providers_auths_select_options"
			track_by="id"
			v-model="selected"
			:options="energy_providers_auths_select_options"
			label="label"
			:multiple="multiple ? true : false"
			:allow_select_all="allow_select_all"
		/>
	</div>
</template>

<script>
import Loader from "@loader";
import Multiselect from "@form_components/Multiselect";

import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "EnergyProviderAuthsMultiselect",
	props: {
		value: [Array, String],
		multiple: Boolean,
		allow_select_all: Boolean,
		allow_only: [Array, null]
	},
	components: {
		Loader,
		Multiselect
	},
	data() {
		return {
			loading: false,
			selected: this.value,
			energy_providers_auths_select_options: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			if (UserPermissionsUtil.can_user("edit_energy_provider_auth")) {
				const energy_providers_auths =
					await EnergyProviderAuthFinder.get_energy_provider_auths();

				this.energy_providers_auths_select_options = energy_providers_auths.map(
					(item) => {
						return {
							id: item.id,
							label:
								this.$get_energy_provider_full_name(item.provider) +
								(item.name ? " - " + item.name : "")
						};
					}
				);
			}
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {},
	watch: {
		selected: function (value) {
			this.$emit("input", value);
		},
		value: function (val) {
			this.selected = val;
		},
		allow_only() {
			this.set_options();
		}
	}
};
</script>

<style lang="css" scoped></style>
