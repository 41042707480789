import HtmlToPdfInterfaceClass from "../../infrastructure/html-to-pdf-interface";
import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.interface = new HtmlToPdfInterfaceClass();
	}

	async get_pdf_url_from_html(html_content, file_name) {
		try {
			return await this.interface.get_pdf_url_from_html(
				html_content,
				file_name
			);
		} catch (e) {
			throw new AppError(
				"error_get_pdf_url_from_html",
				"general.errors.general_error",
				e
			);
		}
	}
}
