import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

function _convert_backend_provider_name(backend_provider_name) {
	return BackendEntityConverter.convert_backend_value_to_local(
		"energy_measurement_provider_names",
		backend_provider_name
	);
}

export default (backend_energy_system) => {
	let result = {
		id: backend_energy_system.id,
		name: backend_energy_system.name,
		system_type: backend_energy_system.systemType,
		has_provider: backend_energy_system.hasProvider || false
	};

	const posible_props_names_convertion = {
		creationDate: "creation_date",
		coordinates: "coordinates",
		commissioningDate: "commissioning_date",
		cups: "cups"
	};

	for (let backend_prop_name in posible_props_names_convertion) {
		const local_prop_name = posible_props_names_convertion[backend_prop_name];
		if (backend_energy_system[backend_prop_name]) {
			switch (local_prop_name) {
				case "commissioning_date":
				case "creation_date":
					result[local_prop_name] = new Date(
						backend_energy_system[backend_prop_name]
					);
					break;

				default:
					result[local_prop_name] = backend_energy_system[backend_prop_name];
					break;
			}
		}
	}

	if (result.has_provider) {
		result.provider_data = BackendObjectConverter(
			backend_energy_system.providerData,
			{
				providerName: {
					formatter: (value) => {
						return _convert_backend_provider_name(value);
					}
				},
				authId: {
					local_name: "energy_provider_auth_id"
				}
			}
		);
	}

	if (backend_energy_system.assets && backend_energy_system.assets.length > 0) {
		result.assets = BackendObjectConverter(
			backend_energy_system.assets,

			{
				rateContributedGeneration: {
					formatter: (value) => {
						return isNaN(value) ? 0 : value * 100;
					}
				},
				rateContributedConsumption: {
					formatter: (value) => {
						return isNaN(value) ? 0 : value * 100;
					}
				}
			}
		);
	}

	if (backend_energy_system.pvData) {
		result.pv_data = BackendObjectConverter(backend_energy_system.pvData);
	}

	if (backend_energy_system.consumptionData) {
		result.consumption_data = BackendObjectConverter(
			backend_energy_system.consumptionData
		);
	}

	if (backend_energy_system.savingsSettings) {
		result.savings_settings = {
			energy_from_grid_price_rate_id:
				backend_energy_system.savingsSettings.energyFromGridPriceRateId || null
		};

		if (backend_energy_system.savingsSettings.customPrices) {
			result.savings_settings.custom_price_types =
				backend_energy_system.savingsSettings.customPrices;
		}
	}

	return result;
};
