<template>
	<div
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
	>
		<PageHeader :text="$t('general.add_new')" close_name="user-providers" />

		<div class="p-sm">
			<div>
				<span>{{ $tc("user_settings.provider", 2) }}</span>

				<div id="providers-logos-selector" class="m-t-sm">
					<div
						v-for="(
							energy_measurement_provider, index
						) in energy_measurement_providers"
						v-on:click="
							set_energy_measurement_provider(energy_measurement_provider)
						"
						class="new-providers-logo cursor-pointer text-center"
						v-bind:class="{
							selected: selected_provider == energy_measurement_provider
						}"
					>
						<EnergyProviderImg
							:provider_name="energy_measurement_provider"
							:height_in_px="30"
						/>
					</div>
					<div class="clear"></div>
				</div>
			</div>

			<div class="m-t">
				<div v-if="success" class="m-b">
					<Alert message="user_settings.provider_added" type="success" />
				</div>
				<div v-else-if="error" class="m-b">
					<Alert :message="error" />
				</div>

				<div>
					<span>{{ $t("entities.energy_provider_auth.name") }}</span>
					<TextInput
						class="m-t-xs"
						v-model="new_energy_provider_auth.name"
						:placeholder="$t('general.optional')"
					/>
				</div>

				<div class="m-t">
					<StandardProviderLoginForm
						v-if="providers_auth_data[selected_provider]"
						:provider_auth_data="providers_auth_data[selected_provider]"
						v-model="new_energy_provider_auth.auth"
					/>
					<HuaweiFusionsolarLoginForm
						v-else-if="selected_provider == 'huawei-fusionsolar'"
						v-model="new_energy_provider_auth.auth"
					/>
					<EnphaseV4LoginForm
						v-else-if="selected_provider == 'enphase-v4'"
						v-model="new_energy_provider_auth.auth"
					/>
					<DatadisLoginForm
						v-else-if="selected_provider == 'datadis'"
						v-model="new_energy_provider_auth.auth"
					/>
					<LacecalLoginForm v-else-if="selected_provider == 'lacecal'" />
				</div>
			</div>

			<div v-if="selected_provider != 'sma'" class="m-t">
				<Loader v-if="loading" size="30" />
				<div v-else class="btn btn-primary right" @click="save_provider">
					{{ $t("general.save") }}
				</div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";

import TextInput from "@form_components/TextInput";
import EnergyProviderImg from "../../../sub_components/location-components/EnergyProviderImg.vue";

import HuaweiFusionsolarLoginForm from "./energy-providers-login-forms/HuaweiFusionsolarLoginForm";
import EnphaseV4LoginForm from "./energy-providers-login-forms/EnphaseV4LoginForm";
import DatadisLoginForm from "./energy-providers-login-forms/DatadisLoginForm";
import LacecalLoginForm from "./energy-providers-login-forms/LacecalLoginForm.vue";

import StandardProviderLoginForm from "./energy-providers-login-forms/StandardProviderLoginForm.vue";

import EnergyProvidersDataUtil from "../../../../../../src/energy-provider-auth/application/utils/energy-providers-data-util.js";

import EnergyProviderAuthCreatorClass from "@src/energy-provider-auth/application/energy-provider-auth-creator";
const EnergyProviderAuthCreator = new EnergyProviderAuthCreatorClass();

export default {
	name: "EnergyProvidersAuthNew",
	components: {
		PageHeader,
		Loader,
		Alert,
		TextInput,
		EnergyProviderImg,
		HuaweiFusionsolarLoginForm,
		EnphaseV4LoginForm,
		DatadisLoginForm,
		LacecalLoginForm,
		StandardProviderLoginForm
	},
	props: ["refresh"],
	data() {
		let all_providers_slugs =
			EnergyProvidersDataUtil.get_all_energy_measurement_providers_slugs();

		const disabled_providers_login = ["vmc", "custom"];
		const allowed_providers_login_slugs = all_providers_slugs.filter(
			(slug) => disabled_providers_login.indexOf(slug) == -1
		);

		return {
			success: false,
			energy_measurement_providers: allowed_providers_login_slugs,
			error: null,
			loading: undefined,
			selected_provider: "fronius",
			new_energy_provider_auth: {
				name: null,
				auth: null
			},

			providers_auth_data: {
				fronius: [
					{
						slug: "user_id",
						type: "text"
					},
					{
						slug: "user_key",
						type: "text"
					}
				],
				enphase: [
					{
						slug: "user_id",
						type: "text"
					},
					{
						slug: "user_key",
						type: "text"
					}
				],
				ingeteam: [
					{
						slug: "email",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					}
				],
				wibeee: [
					{
						slug: "email",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					}
				],
				"huawei-neteco": [
					{
						slug: "username",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					}
				],
				twinmeter: [
					{
						slug: "username",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					}
				],
				victron: [
					{
						slug: "username",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					}
				],
				sungrow: [
					{
						slug: "username",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					},
					{
						slug: "app_key",
						type: "text"
					},
					{
						slug: "x_access_key",
						type: "text"
					}
				],
				solaredge: [
					{
						slug: "api_key",
						type: "text"
					}
				],
				solax: [
					{
						slug: "token",
						type: "text"
					}
				],
				sunvecx: [
					{
						slug: "token",
						type: "text"
					}
				],
				growatt: [
					{
						slug: "token",
						type: "text"
					}
				],
				solis: [
					{
						slug: "key_id",
						type: "text"
					},
					{
						slug: "key_secret",
						type: "text"
					}
				],
				"canadian-solar": [
					{
						slug: "app_id",
						type: "text"
					},
					{
						slug: "app_secret",
						type: "text"
					}
				],
				kostal: [
					{
						slug: "client_id",
						type: "text"
					},
					{
						slug: "client_secret",
						type: "text"
					},
					{
						slug: "username",
						type: "text"
					},
					{
						slug: "password",
						type: "password"
					}
				]
			}
		};
	},
	methods: {
		set_energy_measurement_provider(provider_slug) {
			this.selected_provider = provider_slug;

			this.error = null;
			this.new_energy_provider_auth.auth = {};
		},
		async save_provider() {
			this.loading = true;
			this.error = null;

			try {
				await EnergyProviderAuthCreator.energy_provider_auth_create(
					this.selected_provider,
					this.new_energy_provider_auth
				);
				this.success = true;
				this.new_energy_provider_auth = {
					name: this.selected_provider,
					auth: null
				};

				setTimeout(() => {
					this.success = null;
				}, 5000);
			} catch (e) {
				if (e.code == 409) {
					this.error = "general.errors.invalid_credentials";
				} else {
					this.error = e;
				}
			} finally {
				this.loading = undefined;
			}
		}
	}
};
</script>

<style scoped>
#providers-logos-selector {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 15px;
	row-gap: 30px;
}
.new-providers-logo img {
	max-height: 30px;
	max-width: 100%;
	opacity: 0.5;
}
.new-providers-logo.selected img {
	opacity: 1;
}
</style>
