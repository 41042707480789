<template lang="html">
	<div>
		<div
			v-if="$is_number(filter_month_energy.energy_self_consumption)"
			class="flex-center-content"
		>
			<ReportChartHeaderValue
				icon="bolt"
				:color="asset_reports_color"
				:value="
					$format_number(filter_month_energy.energy_self_consumption, 0) +
					' kWh'
				"
			/>
		</div>

		<StackedColumnChart
			width="100%"
			height="300px"
			:series="[filter_month_energy]"
			:series_data="[
				{
					prop_name: 'energy_from_grid',
					label: $t('asset_energy_panel.energy_from_grid'),
					color: '#cfd4d9'
				},
				{
					prop_name: 'energy_self_consumption',
					label: $t('asset_energy_panel.energy_self_consumption'),
					color: asset_reports_color
				}
			]"
			:hide_legend="true"
			:hide_toolbar="true"
			:hide_tooltips="true"
			:y_axis_formatter="(value) => $format_number(value) + ' kWh'"
			:x_axis_formatter="(value) => $format_date_to_month(value)"
			:show_toolbar="false"
			column_width="30px"
		/>
		<div class="flex-center-content">
			<div class="display-flex">
				<ChartCategory
					:asset_reports_color="asset_reports_color"
					:label="$t('asset_energy_panel.energy_self_consumption')"
				/>
				<ChartCategory
					class="m-l-xs"
					asset_reports_color="#cfd4d9"
					:label="$t('asset_energy_panel.energy_from_grid')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import StackedColumnChart from "@sub_components/charts/StackedColumnChart";
import ChartCategory from "./ChartCategory";
import ReportChartHeaderValue from "../../../reports-shared-components/ReportChartHeaderValue";

export default {
	name: "MonthEnergyColumnChart",
	components: {
		StackedColumnChart,
		ChartCategory,
		ReportChartHeaderValue
	},
	props: {
		filter_month_energy: Object,
		asset_reports_color: String
	},
	mounted() {
		if (!this.filter_month_energy.energy_from_grid)
			this.filter_month_energy.energy_from_grid = 0;
	}
};
</script>

<style lang="css" scoped></style>
