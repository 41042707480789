import AssetReanalysisWeatherFinderInterfaceClass from "../infrastructure/asset-reanalysis-weather-finder-interface";
import ReanalysisWeatherValidator from "../domain/reanalysis-weather-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = ReanalysisWeatherValidator;
		this.backend_interface = new AssetReanalysisWeatherFinderInterfaceClass();
	}

	async get_asset_monthly_reanalysis_weaher(asset_id, from, to) {
		try {
			const interface_data =
				await this.backend_interface.get_asset_monthly_reanalysis_weaher(
					asset_id,
					from,
					to
				);

			const validated_data = this.validator.validate(interface_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_monthly_reanalysis_weaher",
				"general.errors.general_error",
				e
			);
		}
	}
}
