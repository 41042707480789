import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergyMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-energy-measurement-converter";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_daily_aggregated_energy(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/energy-data/daily/aggregated";
		let params = {
			query_params: {
				from: from ? moment(from).format("YYYY-MM-DD") : null,
				to: to ? moment(to).format("YYYY-MM-DD") : null
			}
		};
		let result = await this.get(url, params);

		return BackendEnergyMeasurementConverter(result);
	}
}
