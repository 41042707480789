<template lang="html">
	<div class="full-width" style="position: absolute; bottom: 0; left: 0">
		<hr style="border-top: 1px solid #94979b" />
		<div class="display-flex-space-between">
			<div class="display-flex">
				<div
					class="flex-vertical-center"
					style="font-size: 14px; color: #94979b"
				>
					{{ $t("asset_reports.data_from") }}:
				</div>
				<div class="m-l-xs">
					<Base64Img
						src="/img/sentinel-solar-logo-v2-grey.png"
						image_height="25px"
					/>
				</div>
			</div>
			<div class="font-bold flex-vertical-center">{{ page_number }}</div>
		</div>
	</div>
</template>

<script>
import Base64Img from "@sub_components/Base64Img.vue";

export default {
	name: "ReportFooter",
	components: {
		Base64Img
	},
	props: {
		page_number: String
	}
};
</script>

<style lang="css" scoped></style>
