<template lang="html">
	<div class="full-width relative white-bg">
		<div v-if="cover_top_image_url" class="flex-horizontal-right">
			<img :src="cover_top_image_url" height="30" />
		</div>

		<div id="page1-content">
			<div id="pv-report-page1-cover-image">
				<Base64Img image_width="100%" :src="require('./img/cover-page.png')" />
			</div>

			<div class="flex-horizontal-right">
				<div class="text-right m-t-lg">
					<span
						class="text-uppercase font-700"
						v-bind:style="{ color: asset_reports_color }"
						style="font-size: 30px"
					>
						{{ report_title }}
					</span>
				</div>
			</div>
			<div class="flex-horizontal-right">
				<div class="text-right m-t-sm" style="width: 500px">
					{{ $t("asset_reports.cover.report_description") }}
				</div>
			</div>

			<div class="flex-horizontal-right m-t">
				<table style="border-collapse: separate; border-spacing: 10px">
					<tr v-if="asset_guests">
						<td class="font-700 text-right">
							<span>
								{{ $t("asset_reports.cover.guests") }}
							</span>
						</td>
						<td class="asset-reports-grey-color text-right p-l">
							<div v-for="guest in asset_guests">
								<span>{{ guest.first_name }}</span>
								<span v-if="guest.last_name"> {{ guest.last_name }}</span>
							</div>
						</td>
					</tr>

					<tr>
						<td class="font-700 text-right">
							{{ $t("asset_reports.cover.location") }}
						</td>
						<td class="asset-reports-grey-color text-right p-l">
							{{ asset.name }}
						</td>
					</tr>

					<tr>
						<td class="font-700 text-right">
							{{ $t("asset_reports.cover.creation_date") }}
						</td>
						<td class="asset-reports-grey-color text-right p-l">
							{{ formatted_current_day }}
						</td>
					</tr>

					<tr>
						<td class="font-700 text-right">
							{{ $t("asset_reports.cover.report_period") }}
						</td>
						<td class="asset-reports-grey-color text-right p-l">
							<div v-if="asset_reports_filter.report_type == 'monthly_savings'">
								<span>
									{{ $format_date_to_month(asset_reports_filter.month) }}
								</span>
							</div>
							<div v-else>
								{{ report_formatted_from_month }}
								<span>{{ $t("asset_reports.cover.to") }}</span>
								{{ report_formatted_to_month }}
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div style="clear: both"></div>
		</div>
	</div>
</template>

<script>
import Base64Img from "@sub_components/Base64Img";

import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import UserFinderClass from "@src/user/application/user-finder.js";
const OwnedUsersFinder = new OwnedUsersFinderClass();
const UserFinder = new UserFinderClass();

export default {
	name: "AssetReportCover",
	components: {
		Base64Img
	},
	props: {
		report_title: String,
		asset: Object,
		asset_reports_filter: Object,
		asset_reports_color: String
	},
	data() {
		return {
			cover_top_image_url: null,
			formatted_current_day: this.$format_date_to_day(new Date()),
			report_formatted_from_month: this.$format_date_to_month(
				this.asset_reports_filter.from
			),
			report_formatted_to_month: this.$format_date_to_month(
				this.asset_reports_filter.to
			),
			asset_guests: null
		};
	},
	async mounted() {
		const user = await UserFinder.get_user();
		this.cover_top_image_url =
			user.assets_watermark || "/img/sentinel-solar-logo-v2-grey.png";

		this.set_asset_guests_names();
	},
	methods: {
		async set_asset_guests_names() {
			const asset_id = this.asset.id;
			const asset_guests =
				await OwnedUsersFinder.get_owned_users_by_allowed_asset_id(
					"guest",
					asset_id
				);

			this.asset_guests =
				asset_guests && asset_guests.length > 0 ? asset_guests : null;
		}
	}
};
</script>

<style lang="css" scoped></style>
