import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergyMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-energy-measurement-converter";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_daily_energy(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/energy-data/daily";

		const months_diff = moment(to).diff(from, "months");
		let daily_energy_data = [];
		for (let index = 0; index <= months_diff; index++) {
			let for_from = moment(from)
				.add(index, "months")
				.startOf("month")
				.format("YYYY-MM-DD");
			let for_to = moment(from)
				.add(index, "months")
				.endOf("month")
				.format("YYYY-MM-DD");

			const result = await this.get(url, {
				query_params: {
					from: for_from,
					to: for_to
				}
			});

			daily_energy_data = daily_energy_data.concat(result);
		}

		return daily_energy_data.map((item) =>
			BackendEnergyMeasurementConverter(item)
		);
	}
}
