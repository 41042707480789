<template>
	<div class="locale-changer">
		<select
			class="form-control"
			@change="select_changed($event)"
			v-model="user_lang"
		>
			<option
				v-for="lang in langs"
				:key="`Lang${lang.code}`"
				:value="lang.code"
			>
				{{ lang.name }}
			</option>
		</select>
	</div>
</template>

<script>
import MultilingualServiceClass from "@services/multilingual-service";
const MultilingualService = new MultilingualServiceClass();

export default {
	name: "LanguajeSelector",
	data() {
		return {
			user_lang: null,
			langs: [
				{
					name: "EN",
					code: "en"
				},
				{
					name: "ES",
					code: "es"
				},
				{
					name: "CAT",
					code: "cat"
				}
			]
		};
	},
	mounted() {
		this.user_lang = MultilingualService.get_user_app_lang();
	},
	methods: {
		select_changed(data) {
			MultilingualService.set_user_app_lang(data.target.value);
		}
	}
};
</script>

<style></style>
