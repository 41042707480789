<template lang="html">
	<b-pagination
		v-model="current_page"
		:total-rows="total_items"
		:per-page="items_per_page"
		aria-controls="my-table"
		align="center"
		id="table-paginator"
	/>
</template>

<script>
export default {
	name: "TablePaginator",
	props: {
		value: Number,
		total_items: Number,
		items_per_page: Number
	},
	data() {
		return {
			current_page: this.value
		};
	},
	watch: {
		current_page() {
			this.$emit("input", this.current_page);
		}
	}
};
</script>

<style scoped>
#table-paginator {
	padding: 0.2rem;
}
</style>

<style media="screen">
.b-pagination .page-item > button {
	color: black;
}
.b-pagination .page-item.active > button {
	background-color: var(--custom-primary) !important;
	color: white !important;
	border: 1px solid var(--custom-primary) !important;
}
</style>
