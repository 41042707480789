<template lang="html">
	<div class="flex-auto-full-height-content">
		<div v-if="display_sub_page" id="sub-page" class="rounded-s">
			<router-view
				v-on:data_updated="load_energy_systems"
				v-on:system_removed="system_removed"
				v-on:system_created="load_energy_systems"
				:energy_systems_to_remove="energy_systems_to_remove"
			/>
		</div>

		<div class="header p-b">
			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div v-else-if="user_systems" class="display-flex-space-between">
				<div class="display-flex">
					<div class="flex-center-content font-bold">
						<span>
							{{ $tc("user_settings.energy_systems_manager.energy_system", 2) }}
							|
							{{ user_systems.length }}
						</span>
					</div>
				</div>

				<div v-bind:class="{ 'visibility-hidden': display_sub_page }">
					<div class="display-flex">
						<div id="search-inputs" class="p-r">
							<div>
								{{
									$t("user_settings.energy_systems_manager.system_id_or_name")
								}}
							</div>
							<div>
								{{ $tc("user_settings.provider", 1) }}
							</div>
							<div>
								{{ $t("entities.energy_system.cups") }}
							</div>
							<div>
								{{ $t("entities.energy_system.commissioning_date") }}
							</div>
							<div>
								<TextInput v-model="search_by.system_name_or_id" />
							</div>
							<div>
								<EnergyProviderAuthsMultiselect
									v-if="energy_providers_auths"
									v-model="search_by.energy_provider_auth"
									:multiple="false"
									:allow_select_all="false"
								/>
								<EnergyDataProvidersMultiselect
									v-else="energy_providers_auths"
									v-model="search_by.provider_name"
								/>
							</div>
							<div>
								<TextInput v-model="search_by.cups" />
							</div>
							<div id="commissioning_date_filter">
								<div class="white-bg">
									<Datepicker v-model="search_by.commissioning_date.date" />
								</div>
								<div>
									<OperatorSelector
										v-model="search_by.commissioning_date.operator"
									/>
								</div>
							</div>
						</div>
						<div
							id="search-buttons"
							class="display-flex-vertical-space-between gap-xs border-l p-l m-r"
						>
							<button
								class="btn btn-primary full-width"
								v-on:click="load_energy_systems()"
							>
								{{ $t("general.search") }}
							</button>
							<button
								class="btn btn-danger-inverse full-width"
								v-on:click="clear_search_by()"
							>
								{{ $t("general.clean") }}
							</button>
						</div>

						<div class="display-flex-vertical-space-between border-l p-l">
							<router-link
								:to="{
									name: 'add-energy-system'
								}"
							>
								<button class="btn btn-primary full-width">
									{{
										$t(
											"user_settings.energy_systems_manager.add_new_energy_system"
										)
									}}
								</button>
							</router-link>
							<router-link
								:to="{
									name: 'add-energy-system-with-provider'
								}"
							>
								<button class="btn btn-primary full-width">
									{{
										$t(
											"user_settings.energy_systems_manager.add_new_energy_system_with_provider"
										)
									}}
								</button>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!loading" class="content relative">
			<div class="absolute full-width full-height overflow-y-scroll">
				<DataTable
					:items="user_systems"
					:items_per_page="30"
					:fields="table_columns"
				>
					<template #head(disable_energy_system)="data">
						<div class="">
							<div class="flex-vertical-center">
								<Checkbox
									v-model="remove_all_energy_systems"
									:no_value="
										!remove_all_energy_systems &&
										any_energy_system_selected_to_remove
									"
									@input="remove_all_energy_systems_modified"
								/>
								<router-link
									:to="{
										name: 'remove-energy-systems'
									}"
									:style="{
										visibility: any_energy_system_selected_to_remove
											? 'visible'
											: 'hidden'
									}"
								>
									<div class="flex-vertical-center m-l cursor-pointer">
										<Icon icon="trash" class="danger-color" size="17" />
									</div>
								</router-link>
							</div>
						</div>
					</template>
					<template #cell(disable_energy_system)="data">
						<Checkbox
							v-model="energy_systems_to_remove[data.item.id]"
							@input="single_energy_system_to_remove_modified"
						/>
					</template>

					<template #cell(provider_data.provider_name)="data">
						<div class="flex-center-content">
							<EnergyProviderImg
								:provider_name="data.value"
								:heigh_in_px="20"
							/>
						</div>
					</template>

					<template #cell(options)="data">
						<div class="flex-horizontal-right gap-xs">
							<router-link
								:to="{
									name: 'user-energy-system',
									params: { system_id: data.item.id }
								}"
							>
								<div class="cursor-pointer primary-color">
									<Icon icon="eye" />
								</div>
							</router-link>

							<router-link
								:to="{
									name: 'user-energy-system-update',
									params: { system_id: data.item.id }
								}"
							>
								<div class="cursor-pointer primary-color">
									<Icon icon="edit" />
								</div>
							</router-link>
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import TextInput from "@form_components/TextInput";
import EnergyProviderAuthsMultiselect from "@form_components/selectors/EnergyProviderAuthsMultiselect";
import Checkbox from "@form_components/Checkbox";
import EnergyDataProvidersMultiselect from "@form_components/selectors/EnergyDataProvidersMultiselect";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";
import Datepicker from "@form_components/Datepicker";
import OperatorSelector from "@form_components/OperatorSelector";
import DataTable from "@sub_components/DataTable.vue";

import EnergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const EnergySystemsFinder = new EnergySystemsFinderClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "UserEnergySystems",
	components: {
		Loader,
		Alert,
		TextInput,
		EnergyProviderAuthsMultiselect,
		EnergyDataProvidersMultiselect,
		Checkbox,
		EnergyProviderImg,
		Datepicker,
		OperatorSelector,
		DataTable
	},
	data() {
		return {
			loading: false,
			error: null,
			user_systems: null,
			energy_providers_auths: null,
			search_by: {
				system_name_or_id: null,
				provider_name: null,
				energy_provider_auth: null,
				cups: null,
				commissioning_date: {
					date: null,
					operator: null
				}
			},
			test: false,
			energy_systems_to_remove: {},
			remove_all_energy_systems: false,

			//table params
			sort_by: null,
			sort_desc: true,
			items_per_page: 50,
			current_page: 1
		};
	},
	mounted() {
		this.load_energy_systems();
	},
	computed: {
		table_columns() {
			let result = [
				{
					key: "disable_energy_system",
					label: ""
				},
				{
					key: "provider_data.provider_name",
					label: this.$tc("user_settings.provider", 1),
					class: "text-center",
					sortable: true
				}
			];

			if (UserPermissionsUtil.can_user("edit_energy_provider_auth")) {
				result.push({
					key: "provider_data.energy_provider_auth_id",
					label: this.$t("entities.energy_provider_auth.name"),
					class: "text-center",
					formatter: (value, key, item) => {
						return this.get_energy_provider_auth_name(value);
					}
				});
			}

			result = result.concat([
				{
					key: "provider_data.system_id_in_provider",
					class: "text-center",
					label: this.$t("user_settings.energy_systems_manager.system_id")
				},
				{
					key: "name",
					class: "text-center",
					label: this.$t("user_settings.energy_systems_manager.system_name")
				},

				{
					key: "assets",
					class: "text-center",
					label: this.$t(
						"user_settings.energy_systems_manager.locations_with_system"
					),
					sortable: true,
					formatter: (value, key, item) => {
						return value && Array.isArray(value) ? value.length : 0;
					}
				},
				{
					key: "options",
					class: "text-center",
					label: ""
				}
			]);

			return result;
		},
		display_sub_page() {
			const current_route_name = this.$route.name;
			return current_route_name != "user-energy-systems";
		},
		any_energy_system_selected_to_remove() {
			for (let index in this.energy_systems_to_remove) {
				if (this.energy_systems_to_remove[index]) {
					return true;
				}
			}
			return false;
		}
	},
	methods: {
		async load_energy_systems(force_download = null) {
			this.loading = true;
			this.current_page = 1;
			this.remove_all_energy_systems = false;

			try {
				this.user_systems = await EnergySystemsFinder.get_user_energy_systems(
					force_download,
					this.search_by.provider_name,
					this.search_by.system_name_or_id,
					this.search_by.cups,
					null,
					this.search_by.energy_provider_auth
				);

				if (UserPermissionsUtil.can_user("edit_energy_provider_auth")) {
					this.energy_providers_auths =
						await EnergyProviderAuthFinder.get_energy_provider_auths();
				}

				if (Object.keys(this.energy_systems_to_remove).length == 0) {
					this.user_systems.forEach((item) => {
						this.energy_systems_to_remove[item.id] = false;
					});
				}
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		clear_search_by() {
			this.search_by = {
				system_name_or_id: null,
				provider_name: null,
				cups: null,
				commissioning_date: {
					date: null,
					operator: null
				}
			};
		},
		get_energy_provider_auth_name(energy_provider_auth_id) {
			const energy_provider_auth = this.energy_providers_auths.find(
				(item) => item.id == energy_provider_auth_id
			);
			return energy_provider_auth ? energy_provider_auth.name : null;
		},
		single_energy_system_to_remove_modified(value) {
			if (!value) this.remove_all_energy_systems = false;
		},
		remove_all_energy_systems_modified(value) {
			const energy_systems_to_remove_values = value;

			this.user_systems.forEach((energy_system) => {
				this.energy_systems_to_remove[energy_system.id] =
					energy_systems_to_remove_values;
			});
		},
		system_removed() {
			this.energy_systems_to_remove = {};
			this.clear_search_by();
			this.load_energy_systems();
		}
	},
	watch: {
		"search_by.commissioning_date.date"(new_value) {
			if (
				this.search_by.commissioning_date &&
				!this.search_by.commissioning_date.operator
			) {
				this.search_by.commissioning_date.operator = "=";
			}
		}
	}
};
</script>

<style lang="css" scoped>
#sub-page {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 4;
	background-color: rgba(0, 0, 0, 0.2);

	display: flex;
	justify-content: center;
	align-items: center;
}
#search-buttons {
	width: 150px;
}
#search-inputs {
	display: grid;
	grid-template-columns: repeat(3, 200px) 250px;
	column-gap: 20px;
	row-gap: 15px;
}
#commissioning_date_filter {
	display: grid;
	grid-template-columns: 140px auto;
	column-gap: 5px;
}
</style>
