<template lang="html">
	<div class="full-width full-height relative">
		<div
			v-if="show_savings_config"
			class="absolute full-width full-height full-size-popup-bg rounded-s"
		>
			<div class="flex-center-content full-width full-height">
				<AssetSavingsSettings v-on:close="show_savings_config = false" />
			</div>
		</div>
		<div v-if="loading">
			<Loader :size="30" />
		</div>
		<div v-else class="absolute full-width full-height">
			<div class="flex-auto-full-height-content">
				<div class="content">
					<div v-if="error" class="m-b">
						<Alert :message="error" />
					</div>
					<div v-else-if="success" class="m-b">
						<Alert :message="success" type="success" />
					</div>

					<div
						v-if="
							can_add_new_sistem_with_provider ||
							can_add_new_sistem_with_no_provider
						"
					>
						<div class="p-b-xs border-b m-b display-flex-space-between">
							<div class="display-flex">
								<div class="flex-center-content">
									<span class="font-bold">
										{{ $t("entities.asset_energy_data_config.add_new_system") }}
									</span>
								</div>
								<div class="m-l-xs flex-center-content">
									<Tooltip
										:tooltip_text="
											$t(
												'asset_info.energy_systems.multple_systems_info_tooltip'
											)
										"
										:width_in_px="400"
										position="bottom-right"
									/>
								</div>
							</div>

							<div
								v-if="can_add_new_sistem_with_no_provider"
								class="display-flex"
							>
								<div>
									<span>
										{{ $t("asset_info.pv_data.systems_with_provider") }}
									</span>
								</div>
								<div class="flex-center-content p-l-xs p-r-xs">
									<Checkbox
										v-model="systems_with_no_provider_form"
										size="small"
									/>
								</div>
								<div>
									<span>{{
										$t("asset_info.pv_data.systems_with_no_provider")
									}}</span>
								</div>
							</div>
						</div>
						<EnergyDataWithNoProviderForm
							v-if="systems_with_no_provider_form"
							v-on:new_system_added="add_new_energy_system"
						/>
						<EnergyDataConfigForm
							v-else
							v-on:new_system_added="add_new_energy_system"
							v-on:error_occurred="error = true"
							v-on:clear_error="error = null"
							class="m-b-xl"
							:can_add_new_system_with_generation="
								can_add_new_system_with_generation
							"
							:can_add_new_system_with_consumption="
								can_add_new_system_with_consumption
							"
							:can_add_new_sistem_with_grid_interaction="
								can_add_new_sistem_with_grid_interaction
							"
							:asset_has_grid_interaction="asset_has_grid_interaction"
						/>
					</div>

					<div class="m-t" v-if="asset_systems && asset_systems.length > 0">
						<div class="display-flex-space-between p-b-xs border-b m-b">
							<div class="font-bold flex-vertical-bottom">
								<span>{{
									$t("entities.asset_energy_data_config.energy_sytems_list")
								}}</span>
							</div>
							<button
								v-if="!enable_edit && asset_systems.length > 1"
								class="btn btn-primary"
								v-on:click="enable_edit = true"
							>
								{{ $t("general.edit") }}
							</button>
						</div>

						<table id="systems-table" class="full-width">
							<thead>
								<th class="text-center">
									{{ $tc("user_settings.provider", 1) }}
								</th>
								<th
									v-if="
										energy_providers_auths &&
										show_energy_provider_auth_name_column
									"
								>
									{{ $t("entities.energy_provider_auth.name") }}
								</th>
								<th>
									{{ $t("entities.energy_system.name") }}
								</th>
								<th class="text-center" v-if="show_generation_column">
									{{
										$t(
											"entities.asset_energy_data_config.rate_contributed_generation"
										)
									}}
								</th>
								<th class="text-center" v-if="show_consumption_column">
									{{
										$t(
											"entities.asset_energy_data_config.rate_contributed_consumption"
										)
									}}
								</th>
								<th v-if="asset_has_grid_interaction" class="text-center">
									{{
										$t(
											"entities.asset_energy_data_config.rate_contributed_grid_interaction"
										)
									}}
								</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-for="(system_config, index) in asset_systems">
									<td class="text-center">
										<EnergyProviderImg
											v-if="system_config.provider_name"
											:provider_name="system_config.provider_name"
											:height_in_px="20"
										/>
									</td>
									<td
										v-if="
											energy_providers_auths &&
											show_energy_provider_auth_name_column
										"
									>
										{{ get_energy_system_provider_auth_name(system_config.id) }}
									</td>
									<td>
										{{ system_config.name }}
									</td>
									<td v-if="show_generation_column">
										<div class="flex-center-content">
											<div
												v-if="
													enable_edit &&
													$is_number(system_config.rate_contributed_generation)
												"
											>
												<NumberInput
													style="width: 75px"
													v-model="system_config.rate_contributed_generation"
													unit="%"
													:min="0"
													:max="100"
													:has_error="
														error &&
														error.type == 'validation' &&
														error.property_path ==
															'systems[' +
																index +
																'].rate_contributed_generation'
													"
												/>
											</div>
											<div
												v-else
												:class="{
													'danger-border':
														error &&
														error.type == 'validation' &&
														error.property_path ==
															'systems[' +
																index +
																'].rate_contributed_generation'
												}"
											>
												<span>
													{{
														system_config.rate_contributed_generation
															? system_config.rate_contributed_generation + "%"
															: "-"
													}}
												</span>
											</div>
										</div>
									</td>
									<td
										class="flex-center-content"
										v-if="show_consumption_column"
									>
										<div
											v-if="
												enable_edit &&
												$is_number(system_config.rate_contributed_consumption)
											"
										>
											<NumberInput
												style="width: 75px"
												v-model="system_config.rate_contributed_consumption"
												unit="%"
												:min="0"
												:max="100"
												:has_error="
													error &&
													error.type == 'validation' &&
													error.property_path ==
														'systems[' +
															index +
															'].rate_contributed_consumption'
												"
											/>
										</div>
										<div
											v-else
											:class="{
												'danger-border':
													error &&
													error.type == 'validation' &&
													error.property_path ==
														'systems[' +
															index +
															'].rate_contributed_consumption'
											}"
										>
											<span>
												{{
													system_config.rate_contributed_consumption
														? system_config.rate_contributed_consumption + "%"
														: "-"
												}}
											</span>
										</div>
									</td>

									<td
										v-if="asset_has_grid_interaction"
										class="flex-center-content"
									>
										<div
											v-if="
												enable_edit &&
												$is_number(
													system_config.rate_contributed_grid_interaction
												)
											"
										>
											<NumberInput
												style="width: 75px"
												v-model="
													system_config.rate_contributed_grid_interaction
												"
												unit="%"
												:min="0"
												:max="100"
												:has_error="
													error &&
													error.type == 'validation' &&
													error.property_path ==
														'systems[' +
															index +
															'].rate_contributed_grid_interaction'
												"
											/>
										</div>
										<div
											v-else
											:class="{
												'danger-border':
													error &&
													error.type == 'validation' &&
													error.property_path ==
														'systems[' +
															index +
															'].rate_contributed_grid_interaction'
											}"
										>
											<span>
												{{
													system_config.rate_contributed_grid_interaction
														? system_config.rate_contributed_grid_interaction +
														  "%"
														: "-"
												}}
											</span>
										</div>
									</td>

									<td>
										<div class="display-flex">
											<div
												class="danger-color cursor-pointer"
												v-on:click="remove_energy_system(system_config.id)"
											>
												<Icon icon="trash" size="22" />
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div v-if="asset_systems && asset_systems.length > 1" class="m-t">
						<div class="p-b-xs border-b m-b">
							<div class="font-bold flex-vertical-bottom">
								<span>
									{{ $t("pv_main_panels.savings.label") }}
								</span>
							</div>
						</div>

						<div class="display-flex-space-between">
							<div>
								<span>
									{{
										$t("entities.asset_savings_settings.energy_to_grid_price")
									}}
								</span>
								<span class="m-l-xs">
									{{
										$t(
											"entities.asset_savings_settings.energy_to_grid_price_unit"
										)
									}}
								</span>
							</div>
							<div>
								<button
									class="btn btn-primary"
									v-on:click="show_savings_config = true"
								>
									{{ $t("general.configure") }}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="footer border-t p-t text-right">
					<button
						class="btn btn-primary"
						v-on:click="update_location_energy_data_config"
					>
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import EnergyDataConfigForm from "./EnergyDataConfigForm";
import EnergyDataWithNoProviderForm from "./EnergyDataWithNoProviderForm.vue";
import NumberInput from "@form_components/NumberInput.vue";
import AssetSavingsSettings from "./AssetSavingsSettings.vue";

import Checkbox from "@form_components/CustomCheckbox";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";

import AssetEnergyDataConfigFinderClass from "@src/asset-energy-data/application/asset-energy-data-config-finder";
import AssetEnergyDataConfigUpdaterClass from "@src/asset-energy-data/application/asset-energy-data-config-updater";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";
import EnsergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder";

const AssetEnergyDataConfigFinder = new AssetEnergyDataConfigFinderClass();
const AssetEnergyDataConfigUpdater = new AssetEnergyDataConfigUpdaterClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();
const EnsergySystemsFinder = new EnsergySystemsFinderClass();

export default {
	name: "LocationEnergyDataConfiguration",
	components: {
		Loader,
		Alert,
		EnergyDataConfigForm,
		EnergyDataWithNoProviderForm,
		Checkbox,
		EnergyProviderImg,
		NumberInput,
		AssetSavingsSettings
	},
	data() {
		return {
			loading: false,
			error: null,
			validation_error: null,
			success: null,
			systems_with_no_provider_form: false,
			energy_systems: null,
			energy_providers_auths: null,
			enable_edit: false,
			show_savings_config: false,

			new_system: {},
			asset_systems: []
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.$route.params.asset_id;

			const asset_energy_config =
				await AssetEnergyDataConfigFinder.get_asset_energy_data_configuration(
					asset_id
				);

			if (asset_energy_config) {
				if (asset_energy_config.systems)
					this.asset_systems = JSON.parse(
						JSON.stringify(asset_energy_config.systems)
					);
			}

			if (UserPermissionsUtil.can_user("edit_energy_provider_auth")) {
				this.energy_providers_auths =
					await EnergyProviderAuthFinder.get_energy_provider_auths();
				this.energy_systems =
					await EnsergySystemsFinder.get_user_energy_systems();
			}
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		add_new_energy_system(energy_system) {
			if (!this.asset_systems) this.asset_systems = [];
			this.asset_systems.push(energy_system);

			this.$forceUpdate();
		},
		remove_energy_system(system_id) {
			const system_index = this.asset_systems.findIndex(
				(item) => item.id == system_id
			);
			if (system_index != -1) {
				this.asset_systems.splice(system_index, 1);
				this.$forceUpdate();
			}
		},
		async update_location_energy_data_config() {
			this.loading = true;
			this.error = null;
			const asset_id = this.$route.params.asset_id;

			let energy_data_to_update = {};

			if (this.asset_systems) {
				energy_data_to_update.systems = [];

				this.asset_systems.forEach((system_config) => {
					energy_data_to_update.systems.push({
						id: system_config.id,
						rate_contributed_generation:
							system_config.rate_contributed_generation,
						rate_contributed_consumption:
							system_config.rate_contributed_consumption,
						rate_contributed_grid_interaction:
							system_config.rate_contributed_grid_interaction
					});
				});

				//If only 1 system it must have 100% generation and consumption
				if (this.asset_systems.length == 1) {
					var unique_system_config_data = energy_data_to_update.systems[0];
					unique_system_config_data = {
						id: unique_system_config_data.id,
						rate_contributed_generation:
							unique_system_config_data.rate_contributed_generation ? 100 : 0,
						rate_contributed_consumption:
							unique_system_config_data.rate_contributed_consumption ? 100 : 0,
						rate_contributed_grid_interaction:
							unique_system_config_data.rate_contributed_grid_interaction
								? 100
								: 0
					};

					energy_data_to_update.systems[0] = unique_system_config_data;
				}
			}

			try {
				const asset_energy_config =
					await AssetEnergyDataConfigUpdater.update_asset_energy_data_configuration(
						asset_id,
						energy_data_to_update
					);

				if (asset_energy_config) {
					this.asset_systems = asset_energy_config.systems;
				}

				this.success = "general.update_successful";
				setTimeout(() => {
					this.success = null;
				}, 5000);

				this.enable_edit = false;
			} catch (e) {
				this.error = e;

				setTimeout(() => {
					this.error = null;
				}, 5000);
			} finally {
				this.loading = false;
			}
		},
		get_energy_system_provider_auth_name(energy_system_id) {
			const energy_system = this.energy_systems.find(
				(item) => item.id == energy_system_id
			);
			if (!energy_system || !energy_system.provider_data) return null;

			const energy_provider_auth = this.energy_providers_auths.find(
				(item) => item.id == energy_system.provider_data.energy_provider_auth_id
			);

			return energy_provider_auth ? energy_provider_auth.name : null;
		}
	},
	computed: {
		asset_has_generation() {
			return this.asset_systems &&
				this.asset_systems.find((item) => item.rate_contributed_generation)
				? true
				: false;
		},
		asset_has_consumption() {
			return this.asset_systems &&
				this.asset_systems.find((item) => item.rate_contributed_consumption)
				? true
				: false;
		},
		asset_has_grid_interaction() {
			return this.asset_systems &&
				this.asset_systems.find(
					(item) => item.rate_contributed_grid_interaction
				)
				? true
				: false;
		},
		can_add_new_system_with_generation() {
			return (
				this.asset_has_generation ||
				!this.asset_has_grid_interaction ||
				(this.asset_has_grid_interaction &&
					!this.asset_has_generation &&
					!this.asset_has_consumption)
			);
		},
		can_add_new_system_with_consumption() {
			return (
				this.asset_has_consumption ||
				!this.asset_has_grid_interaction ||
				(this.asset_has_grid_interaction &&
					!this.asset_has_generation &&
					!this.asset_has_consumption)
			);
		},
		can_add_new_sistem_with_grid_interaction() {
			return (
				this.asset_has_grid_interaction ||
				!this.asset_has_generation ||
				!this.asset_has_consumption
			);
		},
		can_add_new_sistem_with_provider() {
			return (
				!this.asset_systems ||
				this.asset_systems.length == 0 ||
				this.asset_systems.find((item) => item.provider_name)
			);
		},
		can_add_new_sistem_with_no_provider() {
			return !this.asset_systems || this.asset_systems.length == 0;
		},
		show_energy_provider_auth_name_column() {
			return this.asset_systems.find((item) =>
				this.get_energy_system_provider_auth_name(item.ids)
			);
		},
		show_generation_column() {
			return this.asset_systems.find(
				(item) => item.rate_contributed_generation != null
			);
		},
		show_consumption_column() {
			return this.asset_systems.find(
				(item) => item.rate_contributed_consumption != null
			);
		}
	}
};
</script>

<style lang="css" scoped>
#systems-table th,
#systems-table td {
	padding: 15px 5px;
}
#systems-table thead {
	border-bottom: 1px solid var(--grey2);
}
</style>
