import AssetEnergyProductionForecastFinderInterfaceClass from "../infrastructure/asset-energy-forecast-finder-interface.js";
import AssetEnergyProductionForecastValidator from "../domain/energy-forecast-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetEnergyProductionForecastValidator;
		this.backend_interface =
			new AssetEnergyProductionForecastFinderInterfaceClass();
	}

	async get_asset_energy_forecast(asset_id, from = null, to = null) {
		try {
			let result = await this.backend_interface.get_asset_energy_forecast(
				asset_id,
				from,
				to
			);

			return this.validator.validate(result);
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_production_forecast",
				"general.errors.general_error",
				e
			);
		}
	}
}
