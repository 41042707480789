import BackendInterface from "@src/shared/infrastructure/backend-interface";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_savings_monthly(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/savings/monthly";
		const backend_data = await this.get(url, {
			query_params: {
				from: moment(from).format("YYYY-MM"),
				to: moment(to).format("YYYY-MM")
			}
		});

		return backend_data.map((item) => {
			return {
				month: new Date(item.month),
				total_savings: item.savingsTotal,
				self_consumption_savings: item.savingsSelfConsumption,
				to_grid_savings: item.savingsToGrid
			};
		});
	}
}
