import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_day_optimal_weaher_measurements(asset_id, date) {
		const url =
			"/api/asset/" +
			asset_id +
			"/measurements/weather/optimal-resolution/" +
			moment(date).format("YYYY-MM-DD");

		const backend_data = await this.get(url, {
			query_params: {
				useLocalTimeZone: true
			}
		});

		return backend_data.results
			? backend_data.results.map((item) => BackendObjectConverter(item))
			: null;
	}
}
