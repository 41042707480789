<template>
	<div>
		<div class="display-flex-space-between">
			<div class="font-bold">
				{{ $t("entities.user.allowed_assets") }}
			</div>

			<div class="display-flex">
				<div class="flex-center-content">
					<CustomCheckbox
						class="left m-t-xs"
						size="small"
						v-model="user.has_access_all_assets"
					/>
				</div>
				<div class="m-l-xs">
					<span>{{ $t("general.select_all") }}</span>
				</div>
				<div class="m-l-xs">
					<Tooltip
						:tooltip_text="
							$t('user_settings.users_manager.select_all_assets_tooltip')
						"
						position="left"
					/>
				</div>
			</div>
		</div>

		<div v-if="!user.has_access_all_assets" class="m-t-xs">
			<div v-if="loading">
				<Loader />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<Multiselect
				v-else-if="allowed_assets"
				track_by="id"
				label="name"
				:multiple="true"
				v-model="user.allowed_assets"
				:options="allowed_assets"
				:allow_select_all="false"
				:searchable="true"
				:placeholder="$tc('location.select_location', multiple ? 2 : 1)"
			></Multiselect>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Multiselect from "@form_components/Multiselect";

import CustomCheckbox from "@form_components/CustomCheckbox";

import UserFinderClass from "@src/user/application/user-finder.js";
import AssetsFinderClass from "@src/asset/application/assets-finder";
import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";

const UserFinder = new UserFinderClass();
const AssetsFinder = new AssetsFinderClass();
const OwnedUsersFinder = new OwnedUsersFinderClass();

export default {
	name: "EditUserAllowedAssets",
	props: {
		value: Object
	},
	components: {
		Loader,
		Alert,
		Multiselect,
		CustomCheckbox
	},
	data() {
		let user = this.value;
		if (!user.allowed_assets) user.allowed_assets = [];

		return {
			loading: true,
			error: null,
			user: user,
			allowed_assets: null
		};
	},
	mounted() {
		this.load_asset_selector();
	},
	methods: {
		async load_asset_selector() {
			this.loading = true;
			this.error = null;
			try {
				const current_user = await UserFinder.get_user();
				const curent_user_assets = await AssetsFinder.get_user_assets();
				const selected_user_to_edit = this.user;

				if (
					!selected_user_to_edit.manager_id ||
					selected_user_to_edit.manager_id == current_user.id ||
					(selected_user_to_edit.supervisor_id &&
						selected_user_to_edit.supervisor_id == current_user.id)
				) {
					this.allowed_assets = curent_user_assets;
				} else {
					var allowed_assets_ids = null;
					var bucle_securize = 0;

					var manager = await OwnedUsersFinder.get_owned_user(
						"manager",
						selected_user_to_edit.manager_id
					);

					do {
						if (!manager.has_access_all_assets && manager.allowed_assets) {
							allowed_assets_ids = manager.allowed_assets;
						}
						bucle_securize++;

						if (manager.manager_id) {
							manager = await OwnedUsersFinder.get_owned_user(
								"manager",
								manager.manager_id
							);
						} else {
							allowed_assets_ids = curent_user_assets.map((asset) => asset.id);
						}
					} while (allowed_assets_ids == null && bucle_securize < 100);

					this.allowed_assets = curent_user_assets.filter(
						(asset) => allowed_assets_ids.indexOf(asset.id) != -1
					);
				}
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
.contact-form {
	display: grid;
	grid-template-columns: 100px auto 100px auto;
	column-gap: 10px;
	row-gap: 10px;
}
.remove-contact-button {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
}
</style>
