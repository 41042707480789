import Joi from "joi";
import Validator from "@validators/validator";

const PRLimit_schema = Joi.object({
	PRLowestLimit: Joi.number().min(0)
});
export const PRLimitValidator = new Validator(PRLimit_schema);

const user_energy_measurement_providers_data_schema = Joi.object({
	PRLimit: PRLimit_schema
});
export default new Validator(user_energy_measurement_providers_data_schema);
