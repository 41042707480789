import UserAvatarUpdaterInterface from "../infrastructure/user-avatar-updater-interface";
import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import OwnedManagerUsersFinderClass from "@src/user/application/owned-manager-users-finder";
import UserFinderClass from "./user-finder";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import AppError from "@classes/errors/app_error";
import { store_entity_names } from "@config";

export default class {
	constructor() {
		this.backend_interface = new UserAvatarUpdaterInterface();
		this.store_interface = new StoreInterfaceClass();

		this.owned_users_finder = new OwnedUsersFinderClass();
		this.owned_manager_users_finder = new OwnedManagerUsersFinderClass();
		this.UserFinder = new UserFinderClass();
		this.user_data_entity_name = store_entity_names.user_data;
	}

	async upload_user_avatar(
		user_id,
		avatar_file,
		user_role = null,
		user_manager_id = null
	) {
		try {
			const result = await this.backend_interface.upload_user_avatar(
				user_id,
				avatar_file
			);

			let user;
			if (user_role) {
				user = user_manager_id
					? await this.owned_manager_users_finder.get_owned_manager_user(
							user_manager_id,
							user_role,
							user_id
					  )
					: await this.owned_users_finder.get_owned_user(user_role, user_id);
			} else {
				user = await this.UserFinder.get_user(true);
			}
			user.avatar = result.avatar;

			return user;
		} catch (e) {
			throw new AppError(
				"error_upload_user_avatar",
				"general.errors.general_error",
				e
			);
		}
	}
}
