<template lang="html">
	<div class="flex-auto-full-height-content gray-bg">
		<div v-if="!loading && !error && energy_system" class="header p-b">
			<div
				class="flex-vertical-center full-width white-bg p-sm rounded-s box-shadow font-bold grey2-color"
			>
				<router-link :to="{ name: 'user-energy-systems', params: {} }">
					<div>
						<Icon icon="chevron-left" />
					</div>
				</router-link>
				<div
					class="m-r m-l cursor-pointer"
					v-bind:class="{ 'primary-color': menu == 'general' }"
					v-on:click="menu = 'general'"
				>
					{{ $t("user_settings.energy_systems_manager.common_data") }}
				</div>
				<div
					v-if="system_has_pv_data"
					class="m-r m-l cursor-pointer"
					v-bind:class="{ 'primary-color': menu == 'pv_data' }"
					v-on:click="menu = 'pv_data'"
				>
					{{ $t("user_settings.energy_systems_manager.pv_data") }}
				</div>
				<div
					v-if="system_has_consumption_data"
					class="m-r m-l cursor-pointer"
					v-bind:class="{ 'primary-color': menu == 'consumption_data' }"
					v-on:click="menu = 'consumption_data'"
				>
					{{ $t("user_settings.energy_systems_manager.consumption_data") }}
				</div>
				<div
					v-if="
						energy_system.has_provider &&
						energy_system.system_type != 'only_grid_interaction'
					"
					class="m-r m-l cursor-pointer"
					v-bind:class="{ 'primary-color': menu == 'monthly_data' }"
					v-on:click="menu = 'monthly_data'"
				>
					{{ $t("user_settings.energy_systems_manager.monthly_data") }}
				</div>
				<div
					v-if="energy_system.has_provider"
					class="m-l cursor-pointer"
					v-bind:class="{ 'primary-color': menu == 'devices' }"
					v-on:click="menu = 'devices'"
				>
					{{ $t("user_settings.energy_systems_manager.devices") }}
				</div>
			</div>
		</div>
		<div class="content white-bg rounded-s box-shadow">
			<div v-if="loading" class="p-sm">
				<Loader size="30" />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="energy_system" class="full-width full-height relative">
				<EditCommonData
					v-if="menu == 'general'"
					:energy_system="energy_system"
					v-on:energy_system_updated="energy_system_updated"
				/>
				<EditPvData
					v-else-if="menu == 'pv_data'"
					:energy_system="energy_system"
					v-on:energy_system_updated="energy_system_updated"
				/>
				<EditConsumptionData
					v-else-if="menu == 'consumption_data'"
					:energy_system="energy_system"
					v-on:energy_system_updated="energy_system_updated"
				/>
				<EditMonthlyData
					v-else-if="menu == 'monthly_data'"
					:energy_system="energy_system"
				/>
				<EnergySystemDevices
					v-else-if="menu == 'devices'"
					:energy_system="energy_system"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import EditCommonData from "./EnergySystemEditCommonData";
import EditPvData from "./pv-data/EnergySystemEditPvData";
import EditConsumptionData from "./consumption-data/EnergySystemEditConsumptionData";
import EditMonthlyData from "./EnergySystemEditMonthlyData";
import EnergySystemDevices from "./devices/EnergySystemDevices";

import EnergySystemFinderClass from "@src/energy-system/application/energy-system-finder";
const EnergySystemFinder = new EnergySystemFinderClass();

export default {
	name: "UserEnergySystemEdit",
	components: {
		Loader,
		Alert,
		EditCommonData,
		EditPvData,
		EditConsumptionData,
		EditMonthlyData,
		EnergySystemDevices
	},
	data() {
		return {
			loading: false,
			error: null,
			energy_system: null,
			menu: "general",

			system_has_consumption_data: null,
			system_has_pv_data: null
		};
	},
	async mounted() {
		this.loading = true;
		this.error = null;
		const current_energy_system_id = this.$route.params.system_id;

		try {
			this.energy_system = await EnergySystemFinder.get_energy_system(
				current_energy_system_id
			);

			this.system_has_consumption_data =
				this.energy_system.system_type == "generation_and_consumption" ||
				this.energy_system.system_type == "only_consumption";
			this.system_has_pv_data =
				this.energy_system.system_type == "generation_and_consumption" ||
				this.energy_system.system_type == "only_generation";
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		energy_system_updated(energy_system) {
			this.energy_system = energy_system;
		}
	}
};
</script>

<style lang="css" scoped></style>
