import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergyMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-energy-measurement-converter";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_monthly_energy(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/energy-data/monthly";

		let results = [];

		let continue_progress = true;
		let next_from_request = moment(from);
		while (continue_progress) {
			let next_to_request = moment(next_from_request).add(11, "months");
			if (moment(next_to_request).isAfter(to, "month")) {
				continue_progress = false;
				next_to_request = to;
			}

			let request_result = await this.get(url, {
				query_params: {
					from: moment(next_from_request).format("YYYY-MM"),
					to: moment(next_to_request).format("YYYY-MM")
				}
			});

			results = results.concat(request_result);
			next_from_request = moment(next_to_request).add(1, "months");

			if (moment(next_to_request).isSame(to, "month")) {
				continue_progress = false;
			}
		}

		let formated = [];
		results.forEach((item) => {
			const month_energy_data = BackendEnergyMeasurementConverter(item);
			formated.push(month_energy_data);
		});

		return formated;
	}
}
