<template>
	<div
		class="grey4-bg rounded-s box-shadow1 p-t-xxs p-b-xxs p-l p-r flex-vertical-center"
	>
		<div class="display-flex">
			<div class="flex-vertical-center">
				<div
					class="circled-icon grey1-bg full-rounded flex-center-content"
					:style="{ color: icon_color }"
				>
					<Icon v-if="icon" :icon="icon" size="17" />
					<div v-else-if="symbol">
						<span class="font-bold">{{ symbol }}</span>
					</div>
				</div>
			</div>

			<div class="m-l-sm display-flex-vertical-space-between line-height-s">
				<div class="font-700 font-l">
					<div v-if="$is_number(value)">
						<span>{{ $format_number(value, 0) }}</span>
						<span v-if="unit" class="m-l-xs">{{ unit }}</span>
					</div>
					<div v-else>-</div>
				</div>
				<div class="font-bold font-s">{{ label }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AssetGroupSummaryCol1Element",
	props: {
		icon: String,
		icon_color: String,
		symbol: String,
		value: Number,
		unit: String,
		label: String
	}
};
</script>

<style scoped>
.circled-icon {
	width: 35px;
	height: 35px;
}
</style>
