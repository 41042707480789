<template>
	<div class="full-width full-height white-bg rounded-s box-shadow relative">
		<router-view v-if="show_other_route_popup" style="z-index: 2"></router-view>

		<div v-if="loading" class="flex-center-content full-width full-height">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else class="flex-auto-full-height-content p-sm">
			<div class="header p-b">
				<div class="flex-horizontal-right gap-m">
					<router-link
						:to="{
							name: 'new-assets-group'
						}"
					>
						<button class="btn btn-primary">
							{{ $t("general.add") }}
							<Icon icon="plus" size="15" class="m-l-xs" />
						</button>
					</router-link>
					<button class="btn btn-primary" v-on:click="load_assets_groups(true)">
						<Icon icon="sync-alt" size="15" />
					</button>
				</div>
			</div>
			<div class="content">
				<div v-if="!assets_groups || assets_groups.length == 0">
					<Alert :message="$t('general.errors.no_results')" type="info" />
				</div>
				<DataTable
					v-else
					:items="assets_groups"
					:items_per_page="50"
					:fields="data_table_fields"
				>
					<template #cell(options)="data">
						<div class="display-flex gap-s">
							<router-link
								:to="{
									name: 'edit-assets-group',
									params: {
										assets_group_id: data.item.id
									}
								}"
							>
								<div class="cursor-pointer grey1-color">
									<Icon icon="edit" />
								</div>
							</router-link>
							<router-link
								:to="{
									name: 'remove-assets-group',
									params: {
										assets_group_id: data.item.id
									}
								}"
							>
								<div class="cursor-pointer danger-color">
									<Icon icon="trash" />
								</div>
							</router-link>
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "../../../sub_components/Loader.vue";
import Alert from "../../../sub_components/Alert.vue";
import DataTable from "../../../sub_components/DataTable.vue";

import AssetsGroupsFinderClass from "../../../../../../src/assets-group/application/assets-groups-finder.js";
const AssetsGroupsFinder = new AssetsGroupsFinderClass();

export default {
	name: "AssetsGroupsConfig",
	components: {
		Loader,
		Alert,
		DataTable
	},
	data() {
		return {
			loading: false,
			error: null,
			assets_groups: null
		};
	},
	mounted() {
		this.load_assets_groups();
	},
	methods: {
		async load_assets_groups(force_download = null) {
			this.loading = true;
			this.assets_groups = null;
			this.error = null;

			try {
				this.assets_groups = await AssetsGroupsFinder.get_assets_groups(
					force_download
				);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		show_other_route_popup() {
			const current_route_name = this.$route.name;

			return current_route_name != "assets-group-config";
		},
		data_table_fields() {
			return [
				{
					key: "name",
					label: this.$t("entities.assets_group.name")
				},
				{
					key: "total_assets",
					label: this.$t("user_settings.sites_operated")
				},
				{
					key: "options",
					label: this.$t("general.options")
				}
			];
		}
	}
};
</script>
