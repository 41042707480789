<template>
	<div>
		<div
			v-if="total_is_different_than_100"
			class="grey3-bg p-l p-r p-t-xxs p-b-xxs rounded-s"
			style="font-size: 14px"
		>
			{{ $t(total_is_different_than_100_multilingual_msg) }}
		</div>
		<div v-else>
			<!-- Custom bar chart -->
			<div
				class="full-width grey3-bg rounded-s display-flex white font-700 overflow-hidden"
				style="height: 40px"
			>
				<div
					v-for="item in formatted_data"
					class="flex-vertical-center"
					v-bind:style="{
						'background-color': item.color,
						width: item.value + '%'
					}"
				>
					<span
						v-if="$is_number(item.value) && item.value > 10"
						class="p-l-xxs"
					>
						{{ $format_number(item.value, 0) }}%
					</span>
				</div>
			</div>
		</div>

		<!-- Legend -->
		<div class="m-t-xs grey1-color font-bold display-flex-space-between">
			<div v-for="item in data">
				<div
					style="
						display: grid;
						grid-template-columns: auto auto;
						column-gap: 3px;
					"
				>
					<div class="flex-center-content">
						<div
							class="square rounded-xs"
							v-bind:style="{ 'background-color': item.color }"
							style="width: 12px; height: 12px"
						></div>
					</div>

					<div
						class="flex-vertical-center"
						:style="{ 'font-size': (legend_font_size_in_px || 14) + 'px' }"
					>
						{{ $t(item.multilingual_label) }}
					</div>
					<div></div>
					<div>
						<span
							class="font-bold"
							:style="{
								'font-size':
									(legend_font_size_in_px ? legend_font_size_in_px + 2 : 16) +
									'px'
							}"
						>
							{{ $is_number(item.value) ? $format_number(item.value, 2) : "-" }}
						</span>
						<span
							class="m-l-xs"
							:style="{
								'font-size':
									(legend_font_size_in_px ? legend_font_size_in_px - 2 : 12) +
									'px'
							}"
						>
							{{ unit }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CustomPercentageBar",
	props: {
		data: Array, //[{color: String, value: Number, multilingual_label: String, unit: String}]
		total: Number,
		unit: String,
		legend_font_size_in_px: Number,
		total_is_different_than_100_multilingual_msg: String
	},
	computed: {
		formatted_data() {
			return this.data.map((item) => {
				return {
					multilingual_label: item.label,
					color: item.color,
					value: (item.value * 100) / this.total
				};
			});
		},
		total_is_different_than_100() {
			const all_values_sum = this.formatted_data.reduce(
				(partialSum, a) => partialSum + a.value,
				0
			);

			return all_values_sum < 99 || all_values_sum > 101;
		}
	}
};
</script>
