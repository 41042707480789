<template lang="html">
	<div>
		<div v-if="loading">
			<Loader size="25" />
		</div>
		<div v-else-if="show_chart">
			<div>
				<span class="asset-monthly-savings-report-title">
					{{
						$t(
							"asset_reports.asset_monthly_savings_report.page2.month_daily_consumtpion_data"
						)
					}}
				</span>
			</div>

			<StackedColumnChart
				width="100%"
				:height="height_in_px || 300 + 'px'"
				:series="asset_month_daily_energy"
				:series_data="[
					{
						prop_name: 'energy_from_grid',
						label: $t('asset_energy_panel.energy_from_grid'),
						color: '#cfd4d9'
					},
					{
						prop_name: 'energy_self_consumption',
						label: $t('asset_energy_panel.energy_self_consumption'),
						color: asset_reports_color
					}
				]"
				:hide_legend="true"
				:hide_toolbar="true"
				:hide_tooltips="true"
				:y_axis_formatter="(value) => $format_number(value) + ' kWh'"
				:x_axis_formatter="(value) => $format_date_to_month_day_number(value)"
				:show_toolbar="false"
				column_width="10px"
				:data_label_formatter="chart_label_formatter"
				data_labels_posisiton="top"
				data_labels_orientation="vertical"
			/>

			<div class="flex-center-content">
				<div class="display-flex">
					<ChartCategory
						:asset_reports_color="asset_reports_color"
						:label="$t('asset_energy_panel.energy_self_consumption')"
					/>
					<ChartCategory
						class="m-l-xs"
						asset_reports_color="#cfd4d9"
						:label="$t('asset_energy_panel.energy_from_grid')"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import StackedColumnChart from "@sub_components/charts/StackedColumnChart";
import ChartCategory from "./ChartCategory";

import moment from "moment";
import AssetDailyEnergyFinderClass from "@src/asset-energy-data/application/asset-daily-energy-finder";
const AssetDailyEnergyFinder = new AssetDailyEnergyFinderClass();

export default {
	name: "ReportPvProductionChart",
	components: {
		Loader,
		StackedColumnChart,
		ChartCategory
	},
	props: {
		height_in_px: Number,
		asset_id: String,
		month: String,
		asset_reports_color: String
	},
	data() {
		return {
			loading: true,
			asset_month_daily_energy: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const from = moment(this.month).startOf("month");
			const to = moment(this.month).endOf("month");

			const asset_month_daily_energy =
				await AssetDailyEnergyFinder.get_asset_daily_energy(
					this.asset_id,
					from,
					to
				);

			var month_day = moment(
				asset_month_daily_energy.length > 0
					? asset_month_daily_energy[0].time
					: from
			).startOf("month");

			const chart_month =
				asset_month_daily_energy.length > 0
					? asset_month_daily_energy[0].time
					: from;
			let chart_serie = [];

			for (let day = 1; day <= 31; day++) {
				const month_day_data = asset_month_daily_energy.find((item) =>
					moment(item.time).isSame(month_day, "day")
				);

				chart_serie.push(
					month_day_data
						? month_day_data
						: {
								time: month_day.toDate(),
								energy_production: null,
								energy_consumption: null,
								energy_from_grid: null,
								energy_to_grid: null,
								energy_self_consumption: null,
								rate_self_consumption: null,
								rate_self_sufficiency: null
						  }
				);

				month_day.add(1, "days");
				if (!moment(chart_month).isSame(month_day, "month")) break;
			}

			this.asset_month_daily_energy = chart_serie;
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		chart_label_formatter(val, data) {
			const is_energy_from_grid = data.seriesIndex == 0;

			if (is_energy_from_grid || isNaN(val)) return "";

			const month_data = this.asset_month_daily_energy[data.dataPointIndex];
			if (month_data.energy_self_consumption == null) return "";

			const value =
				(month_data.energy_self_consumption * 100) /
				(month_data.energy_self_consumption +
					(month_data.energy_from_grid || 0));

			return this.$format_number(value, 0) + "%";
		}
	},
	computed: {
		show_chart() {
			return (
				this.asset_month_daily_energy &&
				this.asset_month_daily_energy.find(
					(item) => item.energy_from_grid || item.energy_self_consumption
				)
			);
		}
	}
};
</script>

<style lang="css" scoped></style>
