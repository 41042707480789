<template lang="html">
	<div
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
		style="z-index: 3"
	>
		<PageHeader text="Reset password" close_name="owned-users" />
		<div v-if="user" class="p-sm">
			<div v-if="error">
				<Alert :message="error" />
			</div>
			<div v-else-if="success" class="m-b">
				<Alert
					type="success"
					message="user_settings.reset_and_send_user_password_success"
				/>
			</div>

			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else>
				<div>
					{{
						$t("user_settings.reset_and_send_user_password", {
							user_name:
								user.first_name + (user.last_name ? " " + user.last_name : "")
						})
					}}
				</div>
				<div class="m-t text-right">
					<div class="btn btn-primary" v-on:click="reset_and_send_password">
						{{ $t("user_settings.reset_password_button") }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";

import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import OwnedManagerUsersFinderClass from "@src/user/application/owned-manager-users-finder.js";
import OWnedUserResetPasswordClass from "@src/user/application/owned-user-reset-password";

const OwnedUsersFinder = new OwnedUsersFinderClass();
const OwnedManagerUsersFinder = new OwnedManagerUsersFinderClass();
const OWnedUserResetPassword = new OWnedUserResetPasswordClass();

export default {
	name: "SupervisorUserResetPassword",
	components: {
		PageHeader,
		Loader,
		Alert
	},
	data() {
		return {
			user: null,
			loading: null,
			success: null,
			error: null,
			page_hader_close_link: null,
			user: null
		};
	},
	async mounted() {
		try {
			this.loading = true;
			const role = this.$route.params.role;
			const user_id = this.$route.params.user_id;
			const user_manager_id = this.$route.query.manager_id;

			this.user = user_manager_id
				? await OwnedManagerUsersFinder.get_owned_manager_user(
						user_manager_id,
						role,
						user_id
				  )
				: await OwnedUsersFinder.get_owned_user(role, user_id);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async reset_and_send_password() {
			this.loading = true;
			this.error = null;
			this.success = null;

			try {
				await OWnedUserResetPassword.reset_user_password(this.user.id);
				this.success = true;
				setTimeout(() => {
					this.success = null;
				}, 7000);
			} catch (e) {
				this.error = e;
				setTimeout(() => {
					this.error = null;
				}, 7000);
			} finally {
				this.loading = null;
			}
		}
	}
};
</script>

<style lang="css" scoped></style>
