<template lang="html">
	<div class="full-width">
		<div class="grid-with-same-cols-width-and-padding">
			<div>
				<div class="font-bold">{{ $t("entities.asset.name") }}*</div>
				<div>
					<TextInput v-model="input_value.name" />
				</div>
			</div>
			<div>
				<div class="font-bold">{{ $t("entities.asset.custom_id") }}</div>
				<div>
					<TextInput v-model="input_value.custom_id" />
				</div>
			</div>
		</div>
		<div class="m-t">
			<div class="font-bold">{{ $t("entities.asset.address") }}</div>
			<div>
				<TextInput v-model="input_value.address" />
			</div>
		</div>
		<div class="m-t grid-with-same-cols-width-and-padding">
			<div>
				<div class="font-bold">{{ $t("entities.asset.coordinates.lat") }}*</div>
				<div>
					<NumberInput
						v-model="input_value.coordinates.lat"
						:min="-90"
						:max="90"
					/>
				</div>
			</div>
			<div>
				<div class="font-bold">{{ $t("entities.asset.coordinates.lon") }}*</div>
				<div>
					<NumberInput
						v-model="input_value.coordinates.lon"
						:min="-180"
						:max="180"
					/>
				</div>
			</div>
		</div>
		<div class=""></div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput";
import NumberInput from "@form_components/NumberInput";

export default {
	name: "NewLocationForm",
	components: {
		TextInput,
		NumberInput
	},
	props: {
		value: Object
	},
	data() {
		return {
			input_value: this.value
		};
	},
	methods: {
		emit_input_value() {
			this.$emit("input", this.input_value);
		}
	},
	watch: {
		input_value() {
			this.emit_input_value();
		},
		value(new_value) {
			if (new_value != this.input_value) this.input_value = new_value;
		}
	}
};
</script>

<style lang="css" scoped></style>
