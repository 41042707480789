<template>
	<div class="full-width full-height">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else class="full-width full-height">
			<AssetsGroupAssetsTable
				:assets_group_assets_summary="filtererd_asset_group_assets_summary"
				:filter_text="filter_text"
				:custom_filters="custom_filters"
			/>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import AssetsGroupAssetsTable from "./AssetsGroupAssetsTable.vue";

import AssetsGroupAssetsSummaryClass from "@src/assets-group/application/assets-group-assets-summary-finder.js";
import AssetsFinderClass from "@src/asset/application/assets-finder.js";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util.js";
import EnergySystemFinderClass from "@src/energy-system/application/energy-system-finder.js";

const AssetsGroupAssetsSummary = new AssetsGroupAssetsSummaryClass();
const AssetsFinder = new AssetsFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();
const EnergySystemFinder = new EnergySystemFinderClass();

export default {
	name: "AssetsGroupAssets",
	components: {
		Loader,
		Alert,
		AssetsGroupAssetsTable
	},
	props: {
		assets_group_id: String,
		filter_text: String,
		custom_filters: [Object, null],
		force_data_download: Boolean,
		filter_assets_in_system: String
	},
	data() {
		return {
			loading: true,
			error: null,
			assets_group_assets_summary: null,
			user_can_add_asset: UserPermissionsUtil.can_user("create_asset")
		};
	},
	async mounted() {
		this.load_data();
	},
	methods: {
		async load_data(force_download = false) {
			try {
				this.loading = true;

				this.assets_group_assets_summary = null;
				var assets;
				var promises = [];
				if (this.assets_group_id == "default") {
					promises.push(
						AssetsGroupAssetsSummary.get_default_assets_group_assets_summary(
							force_download
						).then((data) => {
							this.assets_group_assets_summary = data;
						})
					);
				} else {
					promises.push(
						AssetsGroupAssetsSummary.get_assets_group_assets_summary(
							this.assets_group_id,
							force_download
						).then((data) => {
							this.assets_group_assets_summary = data;
						})
					);
				}

				promises.push(
					AssetsFinder.get_user_assets(force_download).then((data) => {
						assets = data;
					})
				);
				await Promise.all(promises);

				for (let index in this.assets_group_assets_summary) {
					var item = this.assets_group_assets_summary[index];
					const asset = assets.find((asset) => asset.id == item.asset_id);
					if (asset) {
						item.asset_name = asset.name;
						item.asset_custom_id = asset.custom_id;
					}
				}

				if (this.filter_assets_in_system) {
					const system_id = this.filter_assets_in_system;
					const energy_system = await EnergySystemFinder.get_energy_system(
						system_id
					);
					const energy_system_assets_ids = energy_system.assets.map(
						(item) => item.id
					);

					this.assets_group_assets_summary =
						this.assets_group_assets_summary.filter(
							(item) => energy_system_assets_ids.indexOf(item.asset_id) != -1
						);
				}
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		assets_last_update() {
			return AssetsFinder.get_assets_last_update();
		},
		filtererd_asset_group_assets_summary() {
			if (!this.custom_filters) {
				this.$emit(
					"assets_group_assets_summary_loaded",
					this.assets_group_assets_summary
				);
				return this.assets_group_assets_summary;
			}

			let result = this.assets_group_assets_summary;

			if (this.custom_filters.status_provided) {
				result = result.filter((item) => {
					return (
						this.custom_filters.status_provided.indexOf(item.status_provided) !=
						-1
					);
				});
			}

			if (this.custom_filters.provider_names) {
				result = result.filter((item) => {
					if (!item.provider_names) return false;

					for (let index in this.custom_filters.provider_names) {
						const provider_to_filter =
							this.custom_filters.provider_names[index];
						if (item.provider_names.indexOf(provider_to_filter) == -1)
							return false;
					}

					return true;
				});
			}

			if (this.custom_filters.systems_info) {
				result = result.filter((item) => {
					if (!item.asset_systems_info) return false;

					for (let index in this.custom_filters.systems_info) {
						if (
							!item.asset_systems_info[this.custom_filters.systems_info[index]]
						)
							return false;
					}

					return true;
				});
			}

			this.$emit("assets_group_assets_summary_loaded", result);

			return result;
		}
	},
	watch: {
		assets_group_id() {
			this.load_data();
		},
		assets_last_update() {
			this.load_data(true);
		},
		force_data_download(new_value) {
			if (new_value) this.load_data(true);
		}
	}
};
</script>
