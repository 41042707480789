<template lang="html">
	<div>
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else>
			<Header :title="$t('asset_reports.pv_report.title')" />

			<ReportAssetPvData
				:asset="asset"
				:asset_reports="asset_reports"
				:filter="asset_reports_filter"
			/>

			<div v-if="table_data" id="pv-report-page2-chart">
				<div>
					<div class="pv-report-title">
						{{
							$t(
								"asset_reports.pv_report.page1.selected_period_energy_production_summary"
							)
						}}
					</div>
					<div>
						<span>
							({{ $format_date_to_month(asset_reports_filter.from) }}
							{{ $t("general.period_to") }}
							{{ $format_date_to_month(asset_reports_filter.to) }})
						</span>
					</div>
				</div>

				<ReportPvProductionChart
					:chart_data="table_data"
					:asset_reports_color="asset_reports_color"
				/>
			</div>

			<div v-if="table_data" id="pv-report-page2-table">
				<SelectedPeriodTable :table_data="table_data" />
			</div>
		</div>
		<Footer :page_number="report_comment ? '1/3' : '1/2'" />
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Base64Img from "@sub_components/Base64Img";

import Header from "../../reports-shared-components/Header.vue";
import Footer from "../../reports-shared-components/Footer.vue";
import ReportPvProductionChart from "./sub-components/ReportPvProductionChart";
import ReportAssetPvData from "../../reports-shared-components/ReportAssetPvData.vue";
import SelectedPeriodTable from "./sub-components/SelectedPeriodTable";

import moment from "moment";

export default {
	name: "AssetPvReportPage2",
	components: {
		Loader,
		Alert,
		Base64Img,
		Header,
		Footer,
		ReportPvProductionChart,
		ReportAssetPvData,
		SelectedPeriodTable
	},
	props: {
		asset: Object,
		asset_reports: Object,
		asset_reports_color: String,
		asset_reports_filter: Object,
		report_comment: String
	},
	data() {
		return {
			table_data: null,
			loading: null,
			error: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.set_table_data();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		set_table_data() {
			let data_dates = [];
			let data_months = [];
			let estimated_energy_production = [];
			let energy_production = [];
			let energy_production_variation = [];

			this.asset_reports.energy_production_summary.selected_period_energy_production.forEach(
				(report_energy_production) => {
					const date_month_in_number = moment(
						report_energy_production.date
					).format("M");
					const formatted_and_translated_date = this.$t(
						"general.months." + date_month_in_number
					);
					const short_translated_month = formatted_and_translated_date.slice(
						0,
						3
					);

					data_months.push(moment(report_energy_production.date).format("M"));
					data_dates.push(short_translated_month);

					estimated_energy_production.push(
						report_energy_production.estimated_energy_production
					);
					energy_production.push(report_energy_production.energy_production);
					energy_production_variation.push(
						report_energy_production.energy_production_variation
					);
				}
			);

			this.table_data = {
				data_dates: data_dates,
				data_months: data_months,
				estimated_energy_production: estimated_energy_production,
				energy_production: energy_production,
				energy_production_variation: energy_production_variation
			};
		}
	}
};
</script>

<style lang="css" scoped></style>
