<template lang="html">
	<div>
		<div class="pv-report-title">
			{{ $t("asset_reports.page3.savings") }}
		</div>

		<table
			style="
				display: table;
				width: 100%;
				table-layout: fixed;
				font-size: 12px;
				border-collapse: separate;
				border-spacing: 10px 5px;
			"
		>
			<tr class="font-700 pv-report-page3-ƒont-s">
				<td></td>
				<td class="text-center">
					<span>Kg CO2</span>
				</td>
				<td class="text-center">
					<span>{{ $t("asset_reports.page3.tep") }}</span>
				</td>
			</tr>

			<tr v-for="item in selected_period_savings">
				<td class="font-700">
					{{ $t("general.months." + get_date_month(item.date)) }}
				</td>
				<td class="asset-reports-grey-color text-center">
					{{ $format_number(item.c02, 0) }}
				</td>
				<td class="asset-reports-grey-color text-center">
					{{ $format_number(item.petrol_in_tones, 2) }}
				</td>
			</tr>

			<tr v-if="selected_period_total">
				<td class="font-700">
					{{ $t("asset_reports.page3.selected_period_total_savings") }}
				</td>
				<td class="font-700 text-center">
					<span v-if="selected_period_total.c02 != null">
						{{ $format_number(selected_period_total.c02, 0) }}
					</span>
				</td>
				<td class="font-700 text-center">
					<span v-if="selected_period_total.petrol_in_tones != null">
						{{ $format_number(selected_period_total.petrol_in_tones, 2) }}
					</span>
				</td>
			</tr>

			<tr v-if="previously_accumulated">
				<td class="font-700">
					{{ $t("asset_reports.page3.total_previosly_accumulated") }}
				</td>
				<td class="font-700 text-center">
					<span v-if="previously_accumulated.c02 != null">
						{{ $format_number(previously_accumulated.c02, 0) }}
					</span>
				</td>
				<td class="font-700 text-center">
					<span v-if="previously_accumulated.petrol_in_tones != null">
						{{ $format_number(previously_accumulated.petrol_in_tones, 2) }}
					</span>
				</td>
			</tr>

			<tr v-if="total_savings">
				<td class="font-700">
					{{ $t("asset_reports.page3.total_savings") }}
				</td>
				<td class="font-700 text-center">
					<span v-if="total_savings.c02 != null">
						{{ $format_number(total_savings.c02, 0) }}
					</span>
				</td>
				<td class="font-700 text-center">
					<span v-if="total_savings.petrol_in_tones != null">
						{{ $format_number(total_savings.petrol_in_tones, 2) }}
					</span>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "SavingsPollutionTable",
	props: {
		selected_period_savings: Array,
		total_savings: Object,
		previously_accumulated: Object
	},
	data() {
		return {
			selected_period_total: null
		};
	},
	methods: {
		get_date_month(date) {
			return moment(date).format("M");
		}
	},
	mounted() {
		var selected_period_total = {};

		this.selected_period_savings.forEach((item) => {
			if (item.c02) {
				if (!selected_period_total.c02) selected_period_total.c02 = 0;
				selected_period_total.c02 += item.c02;
			}

			if (item.petrol) {
				item.petrol_in_tones = item.petrol / 1000;
			}

			if (item.petrol_in_tones) {
				if (!selected_period_total.petrol_in_tones)
					selected_period_total.petrol_in_tones = 0;
				selected_period_total.petrol_in_tones += item.petrol_in_tones;
			}
		});

		this.selected_period_total = selected_period_total;

		if (!this.total_savings.petrol_in_tones && this.total_savings.petrol) {
			this.total_savings.petrol_in_tones = this.total_savings.petrol / 1000;
		}
	}
};
</script>

<style lang="css" scoped></style>
