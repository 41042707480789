<template>
	<div class="full-width full-height relative primary-color">
		<div id="map-selector" v-if="!disable_map_style_selector">
			<div
				class="left"
				v-bind:class="{ active: map_type == 'custom' }"
				v-on:click="set_map_style('custom')"
			>
				{{ $t("general.map") }}
			</div>
			<div
				class="right"
				v-bind:class="{ active: map_type == 'satellite' }"
				v-on:click="set_map_style('satellite')"
			>
				{{ $t("index.satellite") }}
			</div>
		</div>

		<div v-if="map_id" :id="map_id"></div>
	</div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
	name: "Map",
	props: {
		markers: Array,
		draggable_marker: Object,
		assets: Array,
		fly_to: Object,
		default_center: Object,
		default_zoom: Number,
		map_style: String,
		disable_map_style_selector: Boolean
	},
	data() {
		function make_random_id(length) {
			let result = "";
			const characters =
				"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			const charactersLength = characters.length;
			let counter = 0;
			while (counter < length) {
				result += characters.charAt(
					Math.floor(Math.random() * charactersLength)
				);
				counter += 1;
			}
			return result;
		}

		return {
			map_id: "map_" + make_random_id(6),
			map: null,
			map_type: "custom",
			clicked_map_asset_id: null,
			map_draggable_marker: null,
			map_styles: {
				custom: "mapbox://styles/ybilbao/ck17yag6b0cq31cqp9vpfeft0",
				satellite: "mapbox://styles/mapbox/satellite-v9"
			}
		};
	},
	mounted() {
		mapboxgl.accessToken =
			"pk.eyJ1IjoieWJpbGJhbyIsImEiOiJjazBjNGFxdGcweTk2M2JsOHIwb3huN3dnIn0.KrZ6lSHatwiTSKOudFQgig";
		this.map = new mapboxgl.Map({
			container: this.map_id,
			style: this.map_styles[this.map_style || this.map_type],
			center: [34, 40.6699],
			zoom: 3
		});

		this.map.on("load", () => {
			// When a click event occurs on a feature in
			// the unclustered-point layer, open a popup at
			// the location of the feature, with
			// description HTML from its properties.
			this.map.on("click", "unclustered-point", (e) => {
				const clicked_point_properties = e.features[0].properties;
				const clicked_asset_id = clicked_point_properties.id;

				this.asset_clicked(clicked_asset_id);
			});
			this.map.on("mouseenter", "clusters", () => {
				this.map.getCanvas().style.cursor = "pointer";
			});
			this.map.on("mouseleave", "clusters", () => {
				this.map.getCanvas().style.cursor = "";
			});

			this.map.on("mouseenter", "unclustered-point", () => {
				this.map.getCanvas().style.cursor = "pointer";
			});
			this.map.on("mouseleave", "unclustered-point", () => {
				this.map.getCanvas().style.cursor = "";
			});

			//Center map to assets
			if (this.assets && this.assets.length == 1) {
				const asset_coordinates = this.assets[0].coordinates;
				this.map.setCenter(asset_coordinates);
				this.map.setZoom(15);
			} else if (this.assets && this.assets.length > 1) {
				let lowest_lon = null;
				let highest_lon = null;
				let lowest_lat = null;
				let highest_lat = null;

				this.assets.forEach((asset, index) => {
					const asset_longitude = asset.coordinates.lon;
					const asset_latitude =
						asset.coordinates.lat > -90 && asset.coordinates.lat < 90
							? asset.coordinates.lat
							: 0;

					if (index == 0) {
						lowest_lon = asset_longitude;
						highest_lon = asset_longitude;
						lowest_lat = asset_latitude;
						highest_lat = asset_latitude;
					} else {
						if (lowest_lon > asset_longitude) lowest_lon = asset_longitude;
						else if (highest_lon < asset_longitude)
							highest_lon = asset_longitude;

						if (lowest_lon > asset_longitude) lowest_lon = asset_longitude;
						else if (highest_lat < asset_latitude) highest_lat = asset_latitude;
					}
				});

				const southwestern_coordinates = [lowest_lon, lowest_lat];
				const northeastern_coordinates = [highest_lon, highest_lat];
				const bounds = [southwestern_coordinates, northeastern_coordinates];

				this.map.fitBounds(bounds, {
					padding: { top: 100, bottom: 100, left: 100, right: 100 }
				});
			} else if (this.default_center || this.default_zoom) {
				if (this.default_center) this.map.setCenter(this.default_center);
				if (this.default_zoom) this.map.setZoom(this.default_zoom);
			} else if (this.draggable_marker) {
				this.set_draggable_marker(this.draggable_marker);

				this.map.setCenter(this.draggable_marker);
				this.map.setZoom(15);
			} else {
				this.map.flyTo({
					center: [-3.70325, 40.4167],
					zoom: 2
				});
			}
		});

		this.map.on("style.load", () => {
			this.map_reload_assets_geojson();
		});

		this.map.on("click", (e) => {
			const clicked_features = this.map.queryRenderedFeatures(e.point);

			if (clicked_features.length == 0) {
				const clicked_coordinates = {
					lon: e.lngLat.lng,
					lat: e.lngLat.lat
				};
				this.map_clicked(clicked_coordinates);
			}
		});

		this.set_map_markers();
	},
	methods: {
		set_map_markers() {
			if (!this.markers || this.markers.length == 0) return;

			this.markers.forEach((marker) => {
				new mapboxgl.Marker({
					draggable: false
				})
					.setLngLat(marker)
					.addTo(this.map);
			});
		},
		map_reload_assets_geojson() {
			if (this.map.getSource("assets_geojson")) {
				//Remove layers and source on map style chenge
				//TO IMPROVE doc: https://docs.mapbox.com/mapbox-gl-js/example/style-switch/
				this.map.removeLayer("clusters");
				this.map.removeLayer("cluster-count");
				this.map.removeLayer("unclustered-point");
				this.map.removeSource("assets_geojson");
			}

			//let source = this.map.getSource("assets_geojson");
			//DOC cluster example: https://docs.mapbox.com/mapbox-gl-js/example/cluster/
			this.map.addSource("assets_geojson", {
				type: "geojson",
				data: this.assets_to_geojson,
				cluster: true,
				clusterMaxZoom: 14, // Max zoom to cluster points on
				clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
			});

			this.map.addLayer({
				id: "clusters",
				type: "circle",
				source: "assets_geojson",
				filter: ["has", "point_count"],
				paint: {
					// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
					"circle-color": "green",
					"circle-radius": 20
				}
			});

			this.map.addLayer({
				id: "cluster-count",
				type: "symbol",
				source: "assets_geojson",
				filter: ["has", "point_count"],
				layout: {
					"text-field": ["get", "point_count_abbreviated"],
					"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
					"text-size": 12
				},
				paint: {
					"text-color": "#ffffff"
				}
			});

			this.map.addLayer({
				id: "unclustered-point",
				type: "circle",
				source: "assets_geojson",
				filter: ["!", ["has", "point_count"]],
				paint: {
					"circle-color": "rgba(80, 215, 59, 0.6)",
					"circle-radius": 8,
					"circle-stroke-width": 2,
					"circle-stroke-color": "green"
				}
			});

			// inspect a cluster on click
			this.map.on("click", "clusters", (e) => {
				const features = this.map.queryRenderedFeatures(e.point, {
					layers: ["clusters"]
				});
				const clusterId = features[0].properties.cluster_id;
				this.map
					.getSource("assets_geojson")
					.getClusterExpansionZoom(clusterId, (err, zoom) => {
						if (err) return;

						this.map.easeTo({
							center: features[0].geometry.coordinates,
							zoom: zoom
						});
					});
			});
		},
		map_clicked(clicked_coordinates) {
			this.$emit("map_clicked", { coordinates: clicked_coordinates });
		},

		asset_clicked(asset_id) {
			const clicked_asset = this.assets.find((asset) => asset.id == asset_id);
			this.clicked_map_asset_id = asset_id;

			if (clicked_asset) {
				this.$emit("map_asset_click", clicked_asset);
				new mapboxgl.Popup()
					.setLngLat(clicked_asset.coordinates)
					//For adding a popup when click on asset
					//.setHTML("---CONTENT---")
					.addTo(this.map);
			}
		},
		set_map_style(map_type) {
			const map_style_url = this.map_styles[map_type];

			if (map_style_url) {
				this.map_type = map_type;
				this.map.setStyle(map_style_url, { diff: true });

				this.map_reload_assets_geojson();
			}
		},
		draggable_maker_dragged(marker_new_coordinates) {
			this.$emit("draggable_marker_dragged", marker_new_coordinates);
		},
		set_draggable_marker(new_coordinates) {
			if (this.map_draggable_marker) {
				this.map_draggable_marker.setLngLat(new_coordinates);
			} else {
				this.map_draggable_marker = new mapboxgl.Marker({
					draggable: true
				})
					.setLngLat(new_coordinates)
					.addTo(this.map);

				this.map_draggable_marker.on("dragend", () => {
					const lngLat = this.map_draggable_marker.getLngLat();

					const coordinates = {
						lon: lngLat.lng,
						lat: lngLat.lat
					};

					this.draggable_maker_dragged(coordinates);
				});
			}
		}
	},
	computed: {
		map_markers() {
			if (!this.markers || this.markers.length == 0) return null;

			return this.markers;
		},
		assets_to_geojson() {
			if (!this.assets || Object.keys(this.assets).length == 0) return null;

			let geojson = {
				type: "FeatureCollection",
				crs: {
					type: "name",
					properties: { name: "Locations" }
				},
				features: []
			};

			for (let index in this.assets) {
				const asset = this.assets[index];

				geojson.features.push({
					type: "Feature",
					properties: {
						id: asset.id
					},
					geometry: {
						type: "Point",
						coordinates: [asset.coordinates.lon, asset.coordinates.lat, 0.0]
					}
				});
			}

			return geojson;
		}
	},
	watch: {
		fly_to(new_value, old_value) {
			const coordinates = new_value.coordinates;
			const fly_to_zoom = new_value.zoom || null;
			const current_zoom = this.map.getZoom();
			const zoom_to = fly_to_zoom > current_zoom ? fly_to_zoom : current_zoom;

			this.map.flyTo({
				center: coordinates,
				zoom: zoom_to
			});
		},
		draggable_marker(new_coordinates, old_value) {
			this.set_draggable_marker(new_coordinates);
		},
		assets: {
			handler(new_value, old_value) {
				this.map_reload_assets_geojson();
			},
			deep: true
		}
	}
};
</script>

<style lang="css" scoped>
#map-selector {
	position: absolute;
	background-color: white;
	top: 10px;
	right: 50px;
	z-index: 401;
	border-radius: 8px;
	padding: 5px 0;
}
#map-selector > div {
	padding: 5px 13px;
	font-weight: 500;
	cursor: pointer;
}
#map-selector > div.active {
	font-weight: bold;
}
#map-selector > div:first-child {
	border-right: 1px solid #d7d7d7;
}
</style>

<style media="screen">
.mapboxgl-map {
	width: 100%;
	height: 100%;
}
.map-asset-popup {
	max-width: 500px;
}
.map-asset-popup .mapboxgl-popup-close-button {
	font-size: 25px;
}
.map-asset-popup .mapboxgl-popup-content {
	padding-right: 25px;
	border-radius: 5px;
	box-shadow: rgba(67, 90, 111, 0.3) 0px 3px 6px 3px;
}
</style>
