<template lang="html">
	<div id="pv-report-page3-selected-period-summary">
		<div>
			<table>
				<tr>
					<td
						v-if="formatted_data.selected_period_estimated_energy_production"
						class="pv-report-page3-big-numbers"
						v-bind:style="{ color: asset_reports_color }"
					>
						{{
							$format_number(
								formatted_data.selected_period_estimated_energy_production,
								2
							)
						}}
					</td>
					<td
						v-if="formatted_data.selected_period_energy_production"
						class="pv-report-page3-big-numbers"
						v-bind:style="{ color: asset_reports_color }"
					>
						<span>
							{{
								$format_number(
									formatted_data.selected_period_energy_production,
									2
								)
							}}
						</span>
					</td>

					<td
						v-if="formatted_data.selected_period_energy_production_variation"
						class="pv-report-page3-big-numbers"
						v-bind:style="{ color: asset_reports_color }"
					>
						<span>
							{{
								$format_number(
									formatted_data.selected_period_energy_production_variation,
									2
								)
							}}%
						</span>
					</td>
				</tr>

				<tr class="pv-report-page3-ƒont-xs">
					<td v-if="formatted_data.selected_period_estimated_energy_production">
						<span>kWh</span>
					</td>
					<td v-if="formatted_data.selected_period_energy_production">
						<span>kWh</span>
					</td>
					<td
						v-if="formatted_data.selected_period_energy_production_variation"
					></td>
				</tr>

				<tr class="pv-report-page3-ƒont-s">
					<td v-if="formatted_data.selected_period_estimated_energy_production">
						<span>
							{{ $t("location_pv_estimated_production.estimated_production") }}
						</span>
					</td>
					<td v-if="formatted_data.selected_period_energy_production">
						<span>{{
							$t("asset_reports.pv_report.page1.energy_production")
						}}</span>
					</td>
					<td v-if="formatted_data.selected_period_energy_production_variation">
						<span>{{ $t("asset_reports.pv_report.page1.variation") }}</span>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: "SelectedPeriodSummary",
	props: {
		asset_reports: Object,
		asset_reports_color: String
	},
	data() {
		let formatted_data = {};

		if (
			this.asset_reports &&
			this.asset_reports.energy_production_summary
				.selected_period_energy_production_summary
		) {
			const selected_period_summary =
				this.asset_reports.energy_production_summary
					.selected_period_energy_production_summary;

			if (selected_period_summary.estimated_energy_production)
				formatted_data.selected_period_estimated_energy_production =
					selected_period_summary.estimated_energy_production;

			if (selected_period_summary.energy_production)
				formatted_data.selected_period_energy_production =
					selected_period_summary.energy_production;

			if (selected_period_summary.energy_production_variation)
				formatted_data.selected_period_energy_production_variation =
					selected_period_summary.energy_production_variation;
		}

		return {
			formatted_data: formatted_data
		};
	}
};
</script>

<style lang="css" scoped></style>
