import UserRoleConverter from "./user-role-converter";

export default (backend_user) => {
	const props_convertion = {
		id: "id",
		email: "email",
		firstName: "first_name",
		lastName: "last_name",
		company: "company",
		unitSystem: "unit_system",
		timezone: "timezone",
		creation: "creation",
		avatar: "avatar",
		roles: "roles",
		language: "language"
	};

	let user = {};

	for (let index in props_convertion) {
		const local_prop_name = props_convertion[index];
		const backend_prop_name = index;

		if (backend_user[backend_prop_name] != undefined) {
			switch (local_prop_name) {
				case "creation":
					user[local_prop_name] = new Date(backend_user[backend_prop_name]);
					break;

				case "roles":
					user[local_prop_name] = backend_user[backend_prop_name]
						.filter(
							(item) =>
								UserRoleConverter.convert_backend_to_local_user_role(item) !=
								null
						)
						.map((backend_role) =>
							UserRoleConverter.convert_backend_to_local_user_role(backend_role)
						);
					break;

				default:
					user[local_prop_name] = backend_user[backend_prop_name];
					break;
			}
		}
	}

	if (_user_is_supervisor(user)) {
		if (backend_user.ticketing_settings) {
			user.ticketing_settings = {
				default_distance_cost:
					backend_user.ticketing_settings.defaultDistanceCost || undefined,
				default_hour_cost:
					backend_user.ticketing_settings.defaultHourCost || undefined
			};
		}
	} else {
		user.has_access_all_assets = backend_user.hasAccessAllAssets || false;
		if (!user.has_access_all_assets) {
			user.allowed_assets = backend_user.allowedAssets || [];
		}
		user.supervisor_id = backend_user.supervisorId;

		if (backend_user.managerId) {
			user.manager_id = backend_user.managerId;
		}

		if (_user_is_maintainer(user)) {
			const backend_maintainer_data =
				backend_user.extra_data || backend_user.maintainerRoleExtraData || null;
			user.maintainer_role_extra_data = {};

			user.maintainer_role_extra_data.phone =
				backend_maintainer_data.phone || null;
			user.maintainer_role_extra_data.address =
				backend_maintainer_data.address || null;
			user.maintainer_role_extra_data.other_contacts =
				backend_maintainer_data.otherContacts || null;
			user.maintainer_role_extra_data.zones =
				backend_maintainer_data.zones || null;
			user.maintainer_role_extra_data.distance_cost =
				backend_maintainer_data.distanceCost || null;
			user.maintainer_role_extra_data.hour_cost =
				backend_maintainer_data.hourCost || null;
			user.maintainer_role_extra_data.phone =
				backend_maintainer_data.phone || null;
		} else if (_user_is_guest(user)) {
			const backend_guest_data =
				backend_user.extra_data || backend_user.guestRoleExtraData;

			user.guest_role_extra_data = {
				guest_id: backend_guest_data.guestInternalId || null,
				phone: backend_guest_data.phone || null,
				address: backend_guest_data.address || null,
				customer_since: backend_guest_data.customerSince
					? new Date(backend_guest_data.customerSince)
					: null,
				vat_number: backend_guest_data.vatNumber || null
			};
		}
	}

	if (backend_user.settings) {
		if (
			backend_user.settings.assetsWatermark &&
			backend_user.settings.assetsWatermark.image
		) {
			user.assets_watermark = backend_user.settings.assetsWatermark.image;
		}

		if (backend_user.settings.customColor)
			user.custom_color = backend_user.settings.customColor;

		user.use_custom_interface = backend_user.settings.hasOwnProperty(
			"useCustomInterface"
		)
			? backend_user.settings.useCustomInterface
			: false;
	}

	return user;
};

function _user_is_supervisor(user) {
	return user.roles && user.roles.indexOf("supervisor") != -1;
}

function _user_is_maintainer(user) {
	return user.roles && user.roles.indexOf("maintainer") != -1;
}

function _user_is_guest(user) {
	return user.roles && user.roles.indexOf("guest") != -1;
}
