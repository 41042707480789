<template>
	<div class="flex-center-content">
		<div
			class="full-rounded flex-center-content"
			style="width: 20px; height: 20px; color: white"
			:style="{ backgroundColor: color }"
		>
			<span v-if="icon_string" style="font-size: 15px">
				{{ icon_string }}
			</span>
			<Icon v-else-if="icon" :icon="icon" size="13" />
		</div>
		<div class="m-l-xs font-700 flex-center-content">
			<span>{{ value }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "ReportChartHeaderValue",
	props: {
		icon_string: String,
		icon: String,
		color: String,
		value: String
	}
};
</script>
