<template lang="html">
	<div>
		<Multiselect
			:multiple="false"
			:options="options"
			:searchable="true"
			v-model="input_value"
		/>
	</div>
</template>

<script>
import Multiselect from "@form_components/Multiselect";

export default {
	name: "OperatorSelector",
	components: {
		Multiselect
	},
	props: { value: String },
	data() {
		return {
			input_value: this.value,
			options: ["=", "<", "<=", ">", ">="]
		};
	},
	methods: {
		emit_input_value() {
			this.$emit("input", this.input_value);
		}
	},
	watch: {
		input_value() {
			this.emit_input_value();
		},
		value(new_value) {
			this.input_value = new_value;
		}
	}
};
</script>
