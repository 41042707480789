export default class AppError extends Error {
	constructor(code, user_message, error, extra_data = null) {
		super(code);

		this.code = code;
		this.user_message = user_message || null;
		this.error = error || null;
		this.extra_data = extra_data || undefined;
	}
}
