<template lang="html">
	<div>
		<Header :title="header_title" />

		<div class="pv-report-m-t">
			<div class="pv-report-title">
				{{ $t("asset_reports.page3.user_comment") }}
			</div>

			<div
				id="pv-report-page4-user-comment"
				class="pv-report-m-t pv-report-page3-ƒont-s"
			>
				<span style="white-space: pre-wrap">{{ report_comment }}</span>
			</div>
		</div>

		<Footer :page_number="page_number" />
	</div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
	name: "LocationPvReportPage3",
	props: {
		header_title: String,
		report_comment: String,
		page_number: String
	},
	components: {
		Header,
		Footer
	}
};
</script>

<style lang="css" scoped></style>
