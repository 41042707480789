<template>
	<div>
		<hr />
		<div v-if="loading" class="">
			<Loader size="30" />
		</div>
		<div v-else>
			<div v-if="error" class="p-b">
				<Alert :message="error" />
			</div>
			<div v-else-if="success" class="p-b">
				<Alert type="success" :message="$t('general.update_successful')" />
			</div>

			<div>
				<div class="display-flex">
					<div class="no-wrap flex-center-content p-r">
						<span
							>{{
								$t("entities.user_pr_limit.pr_limit.pr_lowest_limit")
							}}
							(%)</span
						>
					</div>
					<div>
						<NumberInput v-model="pr_lowest_limit" />
					</div>
				</div>

				<div class="flex-horizontal-right m-t">
					<button class="btn btn-primary" v-on:click="update_user_pr_limit">
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
		<hr />
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@components/sub_components/Loader";
import NumberInput from "@form_components/NumberInput.vue";

import UserPrLimitFinderClass from "@src/user/application/pr-limit/user-pr-limit-finder.js";
import UserPrLimitUpdaterClass from "@src/user/application/pr-limit/user-pr-limit-updater.js";

const UserPrLimitFinder = new UserPrLimitFinderClass();
const UserPrLimitUpdater = new UserPrLimitUpdaterClass();

export default {
	name: "UserEnergyMeasurementProvidersSettings",
	components: {
		Loader,
		Alert,
		NumberInput
	},
	data() {
		return {
			loading: false,
			error: null,
			pr_lowest_limit: null,
			success: false
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const pr_limit_data = await UserPrLimitFinder.get_user_pr_limit();
			this.pr_lowest_limit = pr_limit_data.pr_limit.pr_lowest_limit;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async update_user_pr_limit() {
			this.loading = true;
			this.error = null;

			try {
				await UserPrLimitUpdater.update_user_pr_limit({
					pr_limit: {
						pr_lowest_limit: this.pr_lowest_limit
					}
				});

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 8000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>
