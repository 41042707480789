import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_alert(alert_id) {
		const url = "/api/alert/" + alert_id;
		const backend_alert = await this.get(url);

		return BackendObjectConverter(backend_alert, {
			PR: {
				local_name: "pr",
				formatter: (value) => {
					return value * 100;
				}
			},
			PRLowestLimit: {
				ignore: true
			}
		});
	}
}
