import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.object({
	enabled: Joi.boolean().required(),
	frequency: Joi.string()
		.valid("hourly", "daily", "weekly")
		.when("enabled", { is: true, then: Joi.required() })
});

export default new Validator(schema, "entities.email_notifications_settings.");
