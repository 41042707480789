import BackendInterface from "@src/shared/infrastructure/backend-interface";
import LocalObjectConverter from "@src/shared/infrastructure/backend-converters/local-object-converter.js";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async create_energy_system_measurements_download_request(
		user_id,
		energy_system_id,
		request
	) {
		const url =
			"/api/admin/energy-data/" +
			user_id +
			"/energy-systems/" +
			energy_system_id +
			"/measurements-download-request";

		const backend_request_object = LocalObjectConverter(request, {
			day_from: {
				formatter: (value) => {
					return moment(value).format("YYYY-MM-DD");
				}
			},
			day_to: {
				formatter: (value) => {
					return moment(value).format("YYYY-MM-DD");
				}
			}
		});

		await this.post(url, {
			body_params: backend_request_object
		});

		return true;
	}
}
