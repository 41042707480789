<template lang="html">
	<div class="full-width full-height">
		<div v-if="users && users.length == 0">
			<Alert type="info" message="general.errors.no_results" />
		</div>
		<div v-else class="flex-auto-full-height-content full-height full-widht">
			<div class="content rounded-s box-shadow1 relative">
				<div class="absolute full-height full-width">
					<DataTable
						:items="users"
						:fields="table_columns"
						:items_per_page="100"
						:filter="table_filter_value"
						:filter_fields="['first_name', 'email']"
					>
						<template #cell(avatar)="data">
							<UserAvatar
								class="margin-center"
								v-if="data.value"
								:user="data.item"
								size="50"
							/>
						</template>
						<template #cell(options)="data">
							<div class="relative">
								<div
									class="cursor-pointer"
									v-on:click="tootle_options(data.item.id)"
								>
									<Icon icon="ellipsis-v" />
								</div>
								<div
									v-if="options_id == data.item.id"
									class="managers-options form-control-line overflow-hidden box-shadow1 white-bg"
								>
									<router-link
										v-if="role == 'manager'"
										:to="{
											name: 'owned-users',
											query: { managerId: data.item.id }
										}"
										v-on:click="manager_selected(data.item.id)"
									>
										<div class="p-l p-r" v-on:click="hide_options()">
											<span>{{
												$t("user_settings.users_manager.go_to_manager")
											}}</span>
										</div>
									</router-link>
									<router-link
										:to="{
											name: 'owned-user-view',
											params: { role: role, user_id: data.item.id }
										}"
									>
										<div class="p-l p-r" v-on:click="hide_options()">
											<span>{{ $t("general.view") }}</span>
										</div>
									</router-link>
									<router-link
										:to="{
											name: 'owned-user-edit',
											params: { role: role, user_id: data.item.id }
										}"
									>
										<div class="p-l p-r" v-on:click="hide_options()">
											<span>{{ $t("general.update") }}</span>
										</div>
									</router-link>
									<router-link
										:to="{
											name: 'owned-user-reset-password',
											params: { role: role, user_id: data.item.id },
											query: { manager_id: data.item.manager_id || null }
										}"
									>
										<div class="p-l p-r primary-bg" v-on:click="hide_options()">
											{{ $t("user_settings.reset_password_button") }}
										</div>
									</router-link>
									<router-link
										:to="{
											name: 'owned-user-remove',
											params: { role: role, user_id: data.item.id }
										}"
									>
										<div
											class="p-l p-r danger-background"
											v-on:click="hide_options()"
										>
											<span>{{ $t("general.delete") }}</span>
										</div>
									</router-link>
								</div>
							</div>
						</template>
					</DataTable>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import DataTable from "@sub_components/DataTable.vue";
import UserAvatar from "@sub_components/UserAvatar";
import TablePaginator from "@sub_components/TablePaginator";

export default {
	name: "UserManagersList",
	components: {
		Alert,
		DataTable,
		UserAvatar,
		TablePaginator
	},
	props: {
		users: Array,
		role: String,
		table_filter_value: String | null
	},
	data() {
		return {
			options_id: null,
			sort_by: null,
			sort_desc: true,
			items_per_page: 100,
			current_page: 1
		};
	},
	methods: {
		tootle_options(selected_id) {
			if (this.options_id && this.options_id == selected_id)
				this.hide_options();
			else this.options_id = selected_id;
		},
		hide_options() {
			this.options_id = null;
		},
		manager_selected(manager_id) {
			this.$emit("manager_selected", manager_id);
		}
	},
	computed: {
		table_columns() {
			let result = [
				{
					key: "avatar",
					label: this.$t("entities.user.avatar")
				},
				{
					key: "first_name",
					label: this.$t("entities.user.first_name"),
					sortable: true
				}
			];

			if (this.role == "guest") {
				result.push({
					key: "guest_role_extra_data.guest_id",
					label: this.$t("entities.user.guest_role_extra_data.guest_id")
				});
			}

			result.push({
				key: "assets_number",
				label: this.$t("user_settings.sites_operated"),
				sortable: true,
				formatter: (value, user) => {
					return user.has_access_all_assets
						? this.$t("user_settings.users_manager.all_assets")
						: user.allowed_assets
						? user.allowed_assets.length
						: 0;
				}
			});
			result.push({
				key: "options",
				label: this.$t("general.options")
			});

			return result;
		}
	}
};
</script>

<style lang="css" scoped>
.managers-options {
	position: absolute;
	top: 0px;
	right: 60px;
	border-radius: 10px;
	z-index: 1;
}
</style>
