<template lang="html">
	<div>
		<div class="border-b font-bold">
			{{ $t("ticketing.settings.cost_settings") }}
		</div>

		<div class="m-t">
			<div v-if="success">
				<Alert type="success" message="general.update_successful" />
			</div>

			<div v-if="error">
				<Alert type="danger" :message="error" />
			</div>

			<div id="form-div" v-if="user">
				<div>
					<div>
						<span class="form-control-line">
							{{ $t("ticketing.default_hour_cost") }}
						</span>
					</div>
					<div>
						<NumberInput
							v-model="user.ticketing_settings.default_hour_cost"
							placeholder="€/h"
							class="text-right"
						/>
					</div>
				</div>

				<div class="m-t">
					<div>
						<span class="form-control-line">
							{{ $t("ticketing.default_distance_cost") }}
						</span>
					</div>
					<div>
						<NumberInput
							v-model="user.ticketing_settings.default_distance_cost"
							placeholder="€/km"
							class="text-right"
						/>
					</div>
				</div>

				<div class="m-t">
					<div v-if="loading">
						<Loader size="30" />
					</div>
					<div v-else>
						<button class="btn btn-primary" v-on:click="save">
							{{ $t("general.save") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import NumberInput from "@form_components/NumberInput";

import TicketServiceClass from "@services/user-services/ticket-service";
import UserFinderClass from "@src/user/application/user-finder.js";

const TicketService = new TicketServiceClass();
const UserFinder = new UserFinderClass();

export default {
	name: "TicketingPriceSettings",
	components: { Loader, Alert, NumberInput },
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			user: null
		};
	},
	async created() {
		this.user = await UserFinder.get_user();
		if (!this.user.ticketing_settings) this.user.ticketing_settings = {};
	},
	methods: {
		async save() {
			this.loading = true;
			this.error = null;
			this.success = false;

			try {
				await TicketService.save_ticketing_settings(
					this.user.ticketing_settings
				);
				this.user = await UserFinder.get_user(true);

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#form-div {
	width: 300px;
}
</style>
