<template>
	<div
		style="min-width: 400px; max-height: calc(100% - 30px)"
		class="white-bg rounded-s flex-auto-height-content"
	>
		<PageHeader
			class="header"
			:text="$t('general.view')"
			:on_close_click="emit_close"
		/>
		<div class="content">
			<div v-if="loading" class="flex-center-content full-height full-width">
				<Loader size="32" />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else>
				<SystemsAlertsTable
					v-if="asset_systems_alerts"
					:alerts="asset_systems_alerts"
				/>
				<AffectedAssetsTable
					v-else-if="affected_assets_ids"
					:assets_ids="affected_assets_ids"
				/>
				<AssetToReviewView
					v-else-if="
						alert.alert_type == 'new_asset_imported_with_fields_to_review'
					"
					:alert="alert"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";
import SystemsAlertsTable from "./alerts-sub-components/SystemsAlertsTable.vue";
import AffectedAssetsTable from "./alerts-sub-components/AffectedAssetsTable.vue";
import AssetToReviewView from "./alerts-sub-components/AssetToReviewView.vue";

import AlertFinderClass from "@src/alert/application/alert-finder.js";
const AlertFinder = new AlertFinderClass();

export default {
	name: "AlertInfo",
	components: {
		PageHeader,
		Loader,
		Alert,
		SystemsAlertsTable,
		AffectedAssetsTable,
		AssetToReviewView
	},
	props: {
		alert: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_systems_alerts: null,
			affected_assets_ids: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			switch (this.alert.alert_type) {
				case "systems_alert":
					await this.set_asset_systems_alerts();
					break;
				case "provider_alert":
					if (this.alert.energy_system.assets)
						this.affected_assets_ids = this.alert.energy_system.assets.map(
							(item) => item.id
						);
					break;
			}
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async set_asset_systems_alerts() {
			let alerts = [];

			for (let index in this.alert.extra_data.alert_ids) {
				const alert_id = this.alert.extra_data.alert_ids[index];
				const alert = await AlertFinder.get_alert(alert_id);
				alerts.push(alert);
			}

			this.asset_systems_alerts = alerts;
		},
		emit_close() {
			this.$emit("close");
		}
	}
};
</script>
