<template>
	<div>
		<div class="flex-with-same-cols-width gap-m">
			<AssetSavingsSummaryBox
				main_icon="euro"
				:main_icon_size="25"
				main_icon_class="green1-color"
				:text1="
					$is_number(savings_summary.total_savings)
						? $format_number(savings_summary.total_savings, 0) + ' €'
						: '-'
				"
				:text1_1="$t('pv_main_panels.savings.total_saved')"
				icon2="sun"
				:text2="
					$is_number(savings_summary.self_consumption_savings)
						? $format_number(savings_summary.self_consumption_savings, 0) + ' €'
						: '-'
				"
				:text2_1="
					$t('pv_main_panels.savings.total_self_consumption_savings_saved')
				"
				icon3="grid-tower-arrow-up"
				:text3="
					$is_number(savings_summary.to_grid_savings)
						? $format_number(savings_summary.to_grid_savings, 0) + ' €'
						: '-'
				"
				:text3_1="$t('pv_main_panels.savings.total_to_grid_savings_saved')"
			/>
			<AssetSavingsSummaryBox
				main_icon="sun"
				:main_icon_size="30"
				main_icon_class="warning-color"
				:text1="
					$is_number(energy_summary.energy_production)
						? $format_number(energy_summary.energy_production, 0) + ' kWh'
						: '-'
				"
				:text1_1="$t('pv_main_panels.savings.total_saved')"
				icon2="sun"
				:text2="
					$is_number(energy_summary.energy_self_consumption)
						? $format_number(energy_summary.energy_self_consumption, 0) + ' kWh'
						: '-'
				"
				:text2_1="
					$t('pv_main_panels.savings.total_self_consumption_energy_saved')
				"
				icon3="grid-tower-arrow-up"
				:text3="
					$is_number(energy_summary.energy_to_grid)
						? $format_number(energy_summary.energy_to_grid, 0) + ' kWh'
						: '-'
				"
				:text3_1="$t('pv_main_panels.savings.total_to_grid_energy_saved')"
			/>
			<AssetSavingsSummaryBox
				main_icon="co2"
				main_icon_class="white"
				:text1="saved_co2 ? $format_number(saved_co2, 0) : '-'"
				text1_sufix="CO<sub>2</sub> Kg"
				:text1_1="$t('pv_main_panels.savings.total_avoided')"
				icon2="cloud"
				:text4="$t('pv_main_panels.savings.total_co2_saved')"
			/>
		</div>
	</div>
</template>

<script>
import AssetSavingsSummaryBox from "./AssetSavingsSummaryBox";
import SavingsUtil from "@src/shared/application/utils/savings-util.js";

export default {
	name: "AssetSavingsSummary",
	components: {
		AssetSavingsSummaryBox
	},
	props: {
		energy_summary: Object,
		savings_summary: Object
	},
	data() {
		return {
			saved_co2: null
		};
	},
	mounted() {
		this.saved_co2 = SavingsUtil.get_co2_from_energy(
			this.energy_summary.energy_production
		);
	}
};
</script>
