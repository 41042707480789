export default {
	time_serie_aggregate(time_serie, props_to_exclude = null) {
		return time_serie.reduce((result, object) => {
			for (let key in object) {
				if (props_to_exclude && props_to_exclude.indexOf(key) != -1) continue;
				if (!result.hasOwnProperty(key)) result[key] = 0;

				result[key] += object[key];
			}
			return result;
		}, {});
	}
};
