import EnergyFromGridPriceRatesFinderInterfaceClass from "../infrastructure/energy-from-grid-price-rates-finder-interface";
import EnergyFromGridPriceRateFinderValidator from "../domain/energy-from-grid-price-rate-finder-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new EnergyFromGridPriceRatesFinderInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
		this.validator = EnergyFromGridPriceRateFinderValidator;
	}

	async get_energy_from_grid_price_rates() {
		try {
			const entity_name = "energy_from_grid_price_rates";
			const cached_data = this.store_interface.get(entity_name);
			if (cached_data) return cached_data;

			const interface_data =
				await this.backend_interface.get_energy_from_grid_price_rates();
			const validated_data = interface_data.map((item) =>
				this.validator.validate(item)
			);

			this.store_interface.set(
				entity_name,
				validated_data,
				moment().add(1, "days").toDate()
			);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_energy_from_grid_price_rates",
				"general.errors.general_error",
				e
			);
		}
	}
}
