import BackendUserEnergyMeasurementProvidersDataValidator from "@interfaces_validators/backend-user-energy-measurement-providers-data-validator";

export default class {
	constructor() {
		this.backend_energy_measurement_providers_user_data_validator = BackendUserEnergyMeasurementProvidersDataValidator;
	}

	convert_backend_energy_measurement_providers_user_data(backend_object) {
		backend_object = this.backend_energy_measurement_providers_user_data_validator.validate(
			backend_object
		);

		let converted_object = {};

		if (backend_object.PRLimit && backend_object.PRLimit.PRLowestLimit) {
			converted_object.pr_limit = backend_object.PRLimit.PRLowestLimit;
		}

		return converted_object;
	}

	convert_energy_measurement_providers_user_data_to_backend(energy_measurement_providers_user_data) {
		let backend_object = {};

		if (energy_measurement_providers_user_data.pr_limit) {
			backend_object.PRLimit = {
				PRLowestLimit: energy_measurement_providers_user_data.pr_limit
			};
		}

		return backend_object;
	}
}
