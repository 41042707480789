<template>
	<div>
		<PageHeader
			:text="$t('pv_main_panels.savings.label')"
			:on_close_click="close"
			class="rounded-s_top"
		/>
		<div class="white-bg rounded-s_bottom">
			<div v-if="loading" class="p-sm flex-center-content">
				<Loader size="20" />
			</div>
			<div v-else-if="asset_savings_settings">
				<div class="p-sm">
					<div v-if="error" class="m-b">
						<Alert :message="error" />
					</div>
					<div v-else-if="success" class="m-b">
						<Alert type="success" message="general.update_successful" />
					</div>

					<div>
						<div class="font-bold p-b-xxs">
							<span>
								{{ $t("entities.asset_savings_settings.energy_to_grid_price") }}
							</span>
							<span class="m-l-xs">
								{{
									$t(
										"entities.asset_savings_settings.energy_to_grid_price_unit"
									)
								}}
							</span>
						</div>
						<div>
							<NumberInput
								v-model="asset_savings_settings.energy_to_grid_price"
								:min="0"
							/>
						</div>
					</div>

					<div class="m-t">
						<div class="font-bold p-b-xxs">
							<span>
								{{
									$t(
										"entities.asset_savings_settings.energy_from_grid_price.energy_from_grid_price_rate_id"
									)
								}}
							</span>
						</div>
						<div>
							<Multiselect
								v-model="
									asset_savings_settings.energy_from_grid_price
										.energy_from_grid_price_rate_id
								"
								:multiple="false"
								:placeholder="
									$t(
										'user_settings.energy_systems_manager.savings.select_tariff'
									)
								"
								label="name"
								track_by="id"
								:options="energy_from_grid_price_rates"
							/>
						</div>
					</div>

					<div v-if="selected_price_rate_prices" class="m-t">
						<div class="font-bold p-b-xxs">
							{{ $t("user_settings.energy_systems_manager.periods") }}
						</div>
						<div id="price_rate_prices">
							<div v-for="(price_rate, index) in selected_price_rate_prices">
								<div class="font-bold">{{ price_rate.name }}</div>
								<div>
									<NumberInput
										v-model="
											asset_savings_settings.energy_from_grid_price
												.custom_prices[index].price
										"
										:placeholder="price_rate.price"
										unit="€/kWh"
										:min="0"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="border-t p-sm flex-horizontal-right">
					<button
						class="btn btn-primary"
						v-on:click="update_asset_savings_settings"
					>
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import NumberInput from "@form_components/NumberInput";
import Multiselect from "@form_components/Multiselect";

import EnergyFromGridPriceRatesFinderClass from "@src/price-rate/application/energy-from-grid-price-rates-finder";
import AssetSavingsSettingsFinderClass from "@src/asset/application/asset-savings-settings-finder.js";
import AssetSavingsSettingsUpdaterClass from "@src/asset/application/asset-savings-settings-updater.js";

const EnergyFromGridPriceRatesFinder =
	new EnergyFromGridPriceRatesFinderClass();
const AssetSavingsSettingsFinder = new AssetSavingsSettingsFinderClass();
const AssetSavingsSettingsUpdater = new AssetSavingsSettingsUpdaterClass();

export default {
	name: "AssetSavingsSettings",
	components: {
		Loader,
		Alert,
		PageHeader,
		NumberInput,
		Multiselect
	},
	data() {
		return {
			loading: false,
			error: null,
			success: null,
			energy_from_grid_price_rates: null,
			asset_savings_settings: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.energy_from_grid_price_rates =
				await EnergyFromGridPriceRatesFinder.get_energy_from_grid_price_rates();

			const asset_id = this.$route.params.asset_id;
			this.asset_savings_settings =
				await AssetSavingsSettingsFinder.get_asset_savings_settings(asset_id);

			if (!this.asset_savings_settings.energy_from_grid_price)
				this.asset_savings_settings.energy_from_grid_price = {};
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		selected_price_rate_prices() {
			if (
				!this.asset_savings_settings ||
				!this.asset_savings_settings.energy_from_grid_price
			)
				return null;

			const price_rate = this.energy_from_grid_price_rates.find(
				(item) =>
					item.id ==
					this.asset_savings_settings.energy_from_grid_price
						.energy_from_grid_price_rate_id
			);

			return price_rate ? price_rate.price_types : null;
		}
	},
	methods: {
		close() {
			this.$emit("close");
		},
		async update_asset_savings_settings() {
			this.loading = true;
			this.error = null;

			try {
				const asset_id = this.$route.params.asset_id;
				const asset_savings_settings = Object.assign(
					{},
					this.asset_savings_settings
				);
				let updated_asset_savings_settings =
					await AssetSavingsSettingsUpdater.update_asset_savings_settings(
						asset_id,
						asset_savings_settings
					);

				if (
					updated_asset_savings_settings.energy_from_grid_price &&
					updated_asset_savings_settings.energy_from_grid_price.custom_prices
						.length == 0
				) {
					const price_rate = this.energy_from_grid_price_rates.find(
						(item) =>
							item.id ==
							this.asset_savings_settings.energy_from_grid_price
								.energy_from_grid_price_rate_id
					);

					price_rate.price_types.forEach((item) => {
						updated_asset_savings_settings.energy_from_grid_price.custom_prices.push(
							{
								id: item.id,
								price: null
							}
						);
					});
				}

				this.asset_savings_settings = updated_asset_savings_settings;

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 8000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		selected_price_rate_prices(new_price_types, old_price_types) {
			if (
				!old_price_types &&
				this.asset_savings_settings.energy_from_grid_price.custom_prices &&
				this.asset_savings_settings.energy_from_grid_price.custom_prices
					.length > 0
			)
				return null;

			this.asset_savings_settings.energy_from_grid_price.custom_prices = [];

			if (new_price_types) {
				new_price_types.forEach((item) => {
					this.asset_savings_settings.energy_from_grid_price.custom_prices.push(
						{
							id: item.id,
							price: null
						}
					);
				});
			}
		}
	}
};
</script>

<style lang="css" scoped>
#energy-system-savings-settings {
	width: 600px;
}
#price_rate_prices {
	display: grid;
	grid-template-columns: 150px 150px 150px;
	column-gap: 10px;
	row-gap: 15px;
}
</style>
