export default {
	user_roles: {
		ROLE_GUEST: "guest",
		ROLE_MAINTAINER: "maintainer",
		ROLE_ADMIN: "admin",
		ROLE_SUPERVISOR: "supervisor",
		ROLE_MANAGER: "manager"
	},

	convert_backend_to_local_user_role(backend_user_role) {
		return this.user_roles[backend_user_role] || null;
	},

	convert_local_to_backend_user_role(local_user_role) {
		for (let backend_role in this.user_roles) {
			if (this.user_roles[backend_role] == local_user_role) return backend_role;
		}

		return null;
	}
};
