<template>
	<div>
		<StackedColumnChart
			height="200px"
			:series="formatted_series"
			:series_data="variables_data"
			legend_positon="bottom"
			:legend_font_size_in_px="12"
			:hide_toolbar="true"
			:hide_tooltips="true"
			column_width="8px"
			:y_axis_formatter="(value) => $format_number(value, 0) + ' kWh'"
			:x_axis_formatter="
				(time) => {
					return $format_date_to_month_day_number(time);
				}
			"
			:x_axis_tooltip_formatter="
				(time) => {
					return $format_date_to_day(time);
				}
			"
		/>
	</div>
</template>

<script>
import StackedColumnChart from "@sub_components/charts/StackedColumnChart.vue";
import PvMeasuredVariables from "@entities/variables/pv-measured-data";

export default {
	name: "AssetMonhtlyReport2Page1EnergyChart",
	props: {
		variables_to_show: Array,
		asset_daily_energy_measurements: Array
	},
	components: {
		StackedColumnChart
	},
	data() {
		const allowed_variables = [
			{
				name: "energy_self_consumption",
				multilingual_label:
					"asset_reports.asset_monthly_savings_report2.energy_self_consumption",
				color: "#FCB12A"
			},
			{
				name: "energy_from_grid",
				multilingual_label:
					"asset_reports.asset_monthly_savings_report2.energy_from_grid",
				color: "var(--grey2)"
			},
			{
				name: "energy_to_grid",
				multilingual_label:
					"asset_reports.asset_monthly_savings_report2.energy_to_grid",
				color: "var(--custom-asset_monht_report2_green)"
			}
		];

		const filtered_variables_to_show = allowed_variables.filter(
			(item) => this.variables_to_show.indexOf(item.name) != -1
		);

		return {
			formatted_series: this.asset_daily_energy_measurements.map((item) => {
				let result = {
					time: item.time
				};

				filtered_variables_to_show.forEach((variable) => {
					result[variable.name] = item[variable.name];
				});

				return result;
			}),
			variables_data: filtered_variables_to_show.map((variable_data) => {
				return {
					prop_name: variable_data.name,
					multilingual_label:
						variable_data.multilingual_label ||
						PvMeasuredVariables[variable_data.name].name,
					color: variable_data.color
				};
			})
		};
	}
};
</script>
