<template>
	<DataTable
		v-if="assets_group_systems_summary"
		:items="
			assets_group_systems_summary.filter(
				(item) => item != null && item != undefined
			)
		"
		:items_per_page="40"
		:fields="table_columns"
		default_sort_by="pr"
		default_sort_order="desc"
		:filter="filter_text"
		:filter_fields="['system_name', 'system_provider_id']"
		v-on:sort_changed="reset_power_measurments_popup"
		v-on:filtered="reset_power_measurments_popup"
		style="font-size: 14px"
		class="grey1-color font-700"
	>
		<template #head(status_provided)="data">
			<div class="flex-center-content">
				<div>
					{{ data.label }}
				</div>

				<Tooltip class="m-l-xs" position="bottom-right">
					<template #tooltip-content>
						<table id="tooltip-table">
							<tr>
								<td>
									<AssetStatusCircle status="ok" />
								</td>
								<td class="text-left">
									<span>{{
										$t("dashboard.asset_status_descriptions.ok")
									}}</span>
								</td>
							</tr>

							<tr>
								<td>
									<AssetStatusCircle status="faulty" />
								</td>
								<td class="text-left">
									{{ $t("dashboard.asset_status_descriptions.faulty") }}
								</td>
							</tr>

							<tr>
								<td>
									<AssetStatusCircle status="offline" />
								</td>
								<td class="text-left">
									{{ $t("dashboard.asset_status_descriptions.offline") }}
								</td>
							</tr>

							<tr>
								<td>
									<AssetStatusCircle status="unavailable" />
								</td>
								<td class="text-left">
									{{ $t("dashboard.asset_status_descriptions.unavailable") }}
								</td>
							</tr>

							<tr>
								<td>
									<AssetStatusCircle status="unsupported" />
								</td>
								<td class="text-left">
									{{ $t("dashboard.asset_status_descriptions.unsupported") }}
								</td>
							</tr>
						</table>
					</template>
				</Tooltip>

				<div class="clear"></div>
			</div>
		</template>
		<template #cell(status_provided)="data">
			<div class="flex-center-content">
				<AssetStatusCircle :status="data.value" />
			</div>
		</template>

		<template
			#cell(latest_measurements.latest_power_measurements.power_production)="data"
		>
			<div class="flex-center-content">
				<div v-if="data.value != null">
					{{ data.value }}
				</div>

				<LatestPowerMasurementsPopup
					class="m-l-xs"
					v-if="
						show_tooltips &&
						data.item.latest_measurements &&
						data.item.latest_measurements.latest_power_measurements
					"
					:power_measurement="
						data.item.latest_measurements.latest_power_measurements
					"
					:location_id="data.item.asset_id"
				/>
			</div>
		</template>

		<template #cell(provider_name)="data">
			<EnergyProviderImg
				v-if="data.value"
				:provider_name="data.value"
				:height_in_px="20"
			/>
			<span v-else> - </span>
		</template>

		<template #cell(system_info)="data">
			<div class="display-flex gap-s" v-if="data.value">
				<div v-for="item in data.value">
					<Icon size="18" :icon="item.icon" />
				</div>
			</div>
		</template>

		<template #cell(system_in_assets_number)="data">
			<router-link
				v-if="data.value && data.value > 0"
				:to="{
					name: 'system-assets',
					params: {
						system_id: data.item.system_id
					}
				}"
			>
				<span class="blue1-color">
					{{ data.value }}
				</span>
			</router-link>
			<span v-else>
				{{ data.value }}
			</span>
		</template>

		<template #cell(shortcuts)="data">
			<div class="display-flex gap-xs">
				<router-link
					:to="{
						name: 'user-energy-system-update',
						params: {
							system_id: data.item.system_id
						}
					}"
				>
					<button class="btn btn-primary">
						<Icon icon="gear" size="19" />
					</button>
				</router-link>

				<router-link
					:to="{
						name: 'alerts',
						query: {
							alerts_from_systems_or_assets: 'systems',
							systems: data.item.system_id
						}
					}"
				>
					<button class="btn btn-primary">
						<Icon icon="alert" size="19" />
					</button>
				</router-link>
			</div>
		</template>
	</DataTable>
</template>

<script>
import DataTable from "@sub_components/DataTable.vue";
import AssetStatusCircle from "../assets-group-assets/AssetStatusCircle.vue";
import LatestPowerMasurementsPopup from "../assets-group-assets/LatestPowerMasurementsPopup.vue";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg.vue";

export default {
	name: "AssetsGroupSystemsTable",
	props: {
		assets_group_systems_summary: Array,
		filter_text: String
	},
	components: {
		DataTable,
		AssetStatusCircle,
		LatestPowerMasurementsPopup,
		EnergyProviderImg
	},
	data() {
		return {
			show_tooltips: true
		};
	},
	computed: {
		table_columns() {
			return [
				{
					key: "status_provided",
					label: this.$t("entities.assets_group_asset_summary.status_provided"),
					sortable: true,
					has_tooltip: true,
					formatter: (value) => {
						return value || null;
					}
				},
				{
					key: "system_name",
					label: this.$t("entities.energy_system.name"),
					sortable: true
				},
				{
					key: "system_provider_id",
					label: this.$t(
						"entities.energy_system.provider_data.system_id_in_provider"
					),
					sortable: true
				},
				{
					key: "latest_measurements.today_energy_production",
					label:
						this.$t(
							"entities.assets_group_asset_summary.latest_measurements.today_energy_production"
						) + " (kWh)",
					sortable: true,
					formatter: (value) => {
						return this.$format_number(value, 1);
					}
				},
				{
					key: "latest_measurements.latest_power_measurements.power_production",
					label: this.$t("dashboard.summary.latest_generation") + " (kW)",
					sortable: true,
					formatter: (value) => {
						return this.$format_number(value, 1);
					}
				},
				{
					key: "pv_data.pr",
					label: this.$t("entities.assets_group_asset_summary.pv_data.pr"),
					sortable: true,
					formatter: (value) => {
						if (!this.$is_number(value)) return "-";
						else if (value >= 120) return this.$t("dashboard.not_normal_pr");
						else if (value > 98) return ">98%";
						else return this.$format_number(value, 0) + "%";
					}
				},
				{
					key: "provider_name",
					label: this.$t("entities.assets_group_asset_summary.provider_names"),
					sortable: false
				},
				{
					key: "system_info",
					label: this.$t("dashboard.device"),
					formatter: (value) => {
						if (!value) return null;

						let result = [];

						if (value.has_battery) {
							result.push({
								icon: "battery"
							});
						}

						if (value.has_pv_generation) {
							result.push({
								icon: "sun"
							});
						}

						if (value.has_consumption) {
							result.push({
								icon: "home"
							});
						}

						if (value.has_grid_interaction) {
							result.push({
								icon: "grid-tower"
							});
						}

						return result;
					}
				},
				{
					key: "devices_status_summary",
					label: "Online",
					formatter: (value) => {
						let online = this.$is_number(value.online) ? value.online : "-";
						let total = this.$is_number(value.total) ? value.total : "-";

						return online + "/" + total;
					}
				},
				{
					key: "system_in_assets_number",
					label: this.$t("user_settings.sites_operated")
				},
				{
					key: "alert_count",
					label: this.$t("entities.assets_group_asset_summary.alert_count"),
					sortable: true,
					formatter: (value) => {
						return this.$is_number(value) && value != 0 ? value : "-";
					}
				},
				{
					key: "shortcuts",
					label: this.$t("general.shortcuts"),
					sortable: false
				}
			];
		}
	},
	methods: {
		reset_power_measurments_popup() {
			//Fix for updating popups data
			this.show_tooltips = false;

			setTimeout(() => {
				this.show_tooltips = true;
			}, 50); //4hours
		}
	}
};
</script>

<style lang="css" scoped>
#tooltip-table {
	border-spacing: 10px;
	border-collapse: separate;
	white-space: nowrap;
}
</style>
