import Joi from "joi";
import Validator from "@validators/validator";

const reset_password_params_schema = Joi.object({
	email: Joi.string()
		.email({ minDomainSegments: 2, tlds: false })
		.required(),
	token: Joi.string().required(),
	new_password: Joi.string().required()
});

export default new Validator(
	reset_password_params_schema,
	"login.forgot_password.reset_password_entity."
);
