<template>
	<div>
		<div v-if="loading">
			<Loader size="32" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="formated_alerts_for_table">
			<div v-if="formated_alerts_for_table.length == 0" class="p-sm">
				<Alert message="general.errors.no_data_available" type="info" />
			</div>
			<DataTable
				v-else
				:items="formated_alerts_for_table"
				:fields="table_fields"
				default_order_by="date"
				default_order="desc"
			>
				<template #cell(energy_system.provider_data.provider_name)="data">
					<EnergyProviderImg :provider_name="data.value" :height_in_px="20" />
				</template>

				<template #cell(extra_data.severity)="data">
					<span
						:class="{
							'danger-bg': data.value == 1,
							'warning-bg': data.value == 2,
							'blue1-bg': data.value == 3
						}"
						class="p-xxs font-700 font-s white rounded-s text-uppercase"
					>
						{{ $t("entities.alert.extra_data.severity_options." + data.value) }}
					</span>
				</template>

				<template #cell(alert_actions)="data">
					<ArchiveAlertButton
						v-if="!data.item.is_archived"
						:alert_id="data.item.id"
						v-on:alert_archived="alert_archived"
						:new_is_archive_value="true"
					/>
				</template>
			</DataTable>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import DataTable from "@sub_components/DataTable.vue";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg.vue";
import ArchiveAlertButton from "./ArchiveAlertButton.vue";

import EnergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder.js";
const EnergySystemsFinder = new EnergySystemsFinderClass();

export default {
	name: "SystemsAlertsTable",
	components: {
		Loader,
		Alert,
		DataTable,
		EnergyProviderImg,
		ArchiveAlertButton
	},
	props: {
		alerts: Array
	},
	data() {
		return {
			loading: false,
			error: null,
			formated_alerts_for_table: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			let formated_alerts_for_table = [];

			for (let index in this.alerts) {
				let system_alert = this.alerts[index];
				const energy_system = await EnergySystemsFinder.get_energy_system(
					system_alert.system_id
				);

				system_alert.energy_system = energy_system;

				formated_alerts_for_table.push(system_alert);
			}

			this.formated_alerts_for_table = formated_alerts_for_table;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		table_fields() {
			return [
				{
					key: "date",
					label: this.$t("entities.alert.date"),
					sortable: true,
					formatter: (value) => {
						return this.$format_date_to_day_hour_minute(value);
					}
				},
				{
					key: "energy_system.name",
					label: this.$t("entities.energy_system.name"),
					sortable: false
				},
				{
					key: "energy_system.provider_data.system_id_in_provider",
					label: this.$t(
						"entities.energy_system.provider_data.system_id_in_provider"
					),
					sortable: false
				},
				{
					key: "alert_type",
					label: this.$t("entities.alert.alert_type"),
					sortable: false,
					formatter: (value) => {
						return this.$t("entities.alert.alert_type_options." + value);
					}
				},
				{
					key: "energy_system.provider_data.provider_name",
					label: this.$t("entities.energy_system.provider_data.provider_name"),
					sortable: false
				},
				{
					key: "extra_data.severity",
					label: this.$t("entities.alert.extra_data.severity"),
					sortable: true
				},
				{
					key: "extra_data.provider_alert_message",
					label: this.$t("entities.alert.extra_data.provider_alert_message"),
					sortable: false
				},
				{
					key: "extra_data.provider_alert_code",
					label: this.$t("entities.alert.extra_data.provider_alert_code"),
					sortable: false
				},
				{
					key: "alert_actions"
				}
			];
		}
	},
	methods: {
		alert_archived(alert_id) {
			var archived_alert = this.formated_alerts_for_table.find((alert) => {
				return alert.id == alert_id;
			});

			archived_alert.is_archived = true;
		}
	}
};
</script>
