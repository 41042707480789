import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.object({
	energy_to_grid_price: Joi.number().min(0).when("energy_from_grid_price", {
		is: Joi.exist(),
		then: Joi.required()
	}),
	energy_from_grid_price: Joi.object({
		energy_from_grid_price_rate_id: Joi.string(),
		custom_prices: Joi.array().items(
			Joi.object({
				id: Joi.string().required(),
				price: Joi.number().min(0).required()
			})
		)
	})
});
export default new Validator(schema, "entities.asset_savings_settings.");
