import EnergyMeasurementValidator from "../domain/energy-measurement-validator";
import AssetDAilyEnergyFinderInterfaceClass from "../infrastructure/asset-daily-energy-finder-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetDAilyEnergyFinderInterfaceClass();
		this.energy_measurement_validator = EnergyMeasurementValidator;
	}

	async get_asset_daily_energy(asset_id, from, to) {
		try {
			const interface_data =
				await this.backend_interface.get_asset_daily_energy(asset_id, from, to);

			return interface_data
				? interface_data.map((item) =>
						this.energy_measurement_validator.validate(item)
				  )
				: [];
		} catch (e) {
			throw new AppError(
				"error_get_asset_daily_energy",
				"general.errors.general_error",
				e
			);
		}
	}
}
