import EnergySystemMeasurementsDownloadRequestCreatorInterfaceClass from "../infrastructure/energy-system-measurements-download-request-creator-interface.js";
import EnergySystemMeasurementsDownloadRequestValidator from "../domain/energy-system-measurements-download-request-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = EnergySystemMeasurementsDownloadRequestValidator;
		this.backend_interface =
			new EnergySystemMeasurementsDownloadRequestCreatorInterfaceClass();
	}

	async create_energy_system_measurements_download_request(
		user_id,
		energy_system_id,
		request
	) {
		const valid_request = this.validator.validate(request);

		try {
			await this.backend_interface.create_energy_system_measurements_download_request(
				user_id,
				energy_system_id,
				valid_request
			);

			return true;
		} catch (e) {
			throw new AppError(
				"error_create_energy_system_measurements_download_request",
				"general.errors.general_error",
				e
			);
		}
	}
}
