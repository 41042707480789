<template>
	<div
		class="box-shadow1 display-flex-space-between p-l p-r p-t-xxs p-b-xxs"
		v-bind:class="{
			'danger-background': is_admin_switching,
			'white-bg': !is_admin_switching
		}"
		id="header"
	>
		<div class="flex-center-content">
			<div id="mobile-logo" class="display-only-small-devices">
				<router-link :to="{ name: 'app' }">
					<img
						class="full-height"
						:src="custom_logo || '/img/sentinel-solar-logo-mobile-v2.png'"
					/>
				</router-link>
			</div>
			<div class="left hide-in-small-devices">
				<router-link :to="{ name: 'app' }">
					<img
						alt="Sentinel logo"
						id="top-logo"
						:src="custom_logo || '/img/sentinel-solar-logo-v2.png'"
					/>
				</router-link>
			</div>
		</div>

		<div
			v-if="middle_menu"
			class="flex-center-content grey2-color text-uppercase font-700 font-m"
		>
			<div
				v-for="(item, index) in middle_menu"
				class="p-l-l p-r-l"
				v-bind:class="{
					'primary-color': current_active_route_name == item.link_name
				}"
			>
				<router-link :to="{ name: item.link_name }">
					<span>{{ item.multilingual_label }}</span>
				</router-link>
			</div>
		</div>

		<div class="flex-center-content">
			<router-link v-if="user_can_assets_dashboard" :to="{ name: 'full-map' }">
				<div class="m-r primary-color flex-center-content">
					<Icon size="26" icon="globe-search" />
				</div>
			</router-link>

			<HeaderHelpMenu />

			<div class="m-l">
				<LanguajeSelector />
			</div>
			<div
				id="mainSubmenu"
				class="cursor-pointer m-l"
				v-on:click="toogle_menu = !toogle_menu"
			>
				<UserAvatar v-if="user" :user="user" size="50" />
			</div>
		</div>

		<div
			id="header-toogle-menu"
			class="box-shadow1 animation grey1-color"
			v-bind:class="{ active: toogle_menu }"
		>
			<div class="p-sm">
				<router-link v-if="user_can_settings" :to="{ name: 'user-settings' }">
					<div>
						{{ $t("header.settings") }}
					</div>
				</router-link>
			</div>

			<div v-if="user_can_admin_menu" class="p-sm border-t">
				<router-link :to="{ name: 'admin-users' }">
					<div>
						{{ $t("header.admin") }}
					</div>
				</router-link>
			</div>

			<div class="p-sm border-t">
				<router-link :to="{ name: 'logout' }">
					<div>
						{{ $t("header.logout") }}
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import LanguajeSelector from "@sub_components/LanguajeSelector";
import UserAvatar from "@sub_components/UserAvatar";
import HeaderHelpMenu from "./HeaderHelpMenu.vue";

import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
import UserServiceClass from "@services/user-services/user-service";
import UserFinderClass from "@src/user/application/user-finder.js";

const UserService = new UserServiceClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();
const UserFinder = new UserFinderClass();

export default {
	name: "Header",
	components: { LanguajeSelector, UserAvatar, HeaderHelpMenu },
	data() {
		return {
			user: null,
			toogle_menu: false,
			user_can_settings: UserPermissionsUtil.can_user("user_settings"),
			user_can_admin_menu: UserPermissionsUtil.can_user("admin_menu"),
			user_can_assets_dashboard:
				UserPermissionsUtil.can_user("assets_dashboard"),
			user_can_assets_reports: UserPermissionsUtil.can_user("asset_reports"),
			custom_logo: null
		};
	},
	async created() {
		this.user = await UserFinder.get_user();

		const custom_interface_data =
			await UserService.get_custom_user_interface_data();
		this.custom_logo = custom_interface_data
			? custom_interface_data.logo
			: null;
	},
	computed: {
		avatar_url: function () {
			return UserService.get_avatar_url(this.user);
		},
		is_admin_switching() {
			return UserService.get_switched_admin() ? true : false;
		},
		current_active_route_name() {
			return this.$route.name;
		},
		middle_menu() {
			if (!this.user_can_assets_dashboard) return null;

			let result = [
				{
					link_name: "app",
					multilingual_label: this.$t("header.home")
				}
			];

			if (UserPermissionsUtil.can_user("asset_reports")) {
				result.push({
					link_name: "assets-reports",
					multilingual_label: this.$t("header.reports")
				});
			}

			if (UserPermissionsUtil.can_user("have_alerts")) {
				result.push({
					link_name: "alerts",
					multilingual_label: this.$t("header.alerts")
				});
			}

			if (UserPermissionsUtil.can_user("pr_tool")) {
				result.push({
					link_name: "pr-tool",
					multilingual_label: this.$t("header.pr_tool")
				});
			}

			result.push({
				link_name: "ticketing",
				multilingual_label: this.$t("header.ticketing")
			});
			return result;
		}
	},
	watch: {
		$route() {
			this.toogle_menu = false;
		}
	}
};
</script>

<style lang="css" scoped>
#header {
	margin-bottom: 0;
	z-index: 4;
}
#header-toogle-menu {
	position: absolute;
	width: 150px;
	top: 65px;
	right: -150px;
	background-color: white;
	z-index: 4;
}
#header-toogle-menu.active {
	right: 10px;
}
#mobile-logo {
	height: 50px;
}
#top-logo {
	max-height: 50px;
}
</style>
