import BackendInterface from "@src/shared/infrastructure/backend-interface";
import backendAssetConverter from "../../asset/infrastructure/backend-asset-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_assets_group_assets(assets_group_id) {
		const url = "/api/assets-group/" + assets_group_id + "/assets";
		const backend_assets_group_assets = await this.get_items_with_offset(
			url,
			4
		);

		const local_assets_group_assets = backend_assets_group_assets.map((item) =>
			backendAssetConverter(item)
		);

		return local_assets_group_assets;
	}
}
