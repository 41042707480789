import BackendInterfaceAsOtherUser from "@src/shared/infrastructure/backend-interface-as-other-user.js";

import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterfaceAsOtherUser {
	constructor(user_id) {
		super(user_id);
	}

	async get_energy_systems_from_user() {
		const url = "/api/energy-data/systems";
		const backend_data = await this.get_items_with_offset(url, 3);
		const local_data = backend_data.map((item) =>
			BackendEnergySystemConverter(item)
		);

		return local_data;
	}
}
