import moment from "moment";

export default (user) => {
	const props_convertion = {
		email: "email",
		firstName: "first_name",
		lastName: "last_name",
		company: "company",
		unitSystem: "unit_system",
		timezone: "timezone",
		//avatar: "avatar",
		hasAccessAllAssets: "has_access_all_assets",
		allowedAssets: "allowed_assets",
		language: "language"
	};

	let backend_user = {};
	for (let index in props_convertion) {
		const local_prop_name = props_convertion[index];
		const backend_prop_name = index;

		if (user[local_prop_name] != undefined) {
			const local_prop_value = user[local_prop_name];

			switch (local_prop_name) {
				default:
					backend_user[backend_prop_name] = local_prop_value;
					break;
			}
		}
	}

	const user_role = user.roles[0];
	if (user_role == "maintainer") {
		convert_maintainer_data(user, backend_user);
	} else if (user_role == "guest" && user.guest_role_extra_data) {
		convert_guest_data(user, backend_user);
	}

	return backend_user;
};

function convert_maintainer_data(user, backend_user) {
	const maintainer_props_convertion = {
		address: "address",
		distanceCost: "distance_cost",
		hourCost: "hour_cost",
		otherContacts: "other_contacts",
		phone: "phone",
		zones: "zones"
	};

	let backend_maintainer_role_extra_data = {};
	for (let index in maintainer_props_convertion) {
		const local_prop_name = maintainer_props_convertion[index];
		const backend_prop_name = index;

		if (user.maintainer_role_extra_data[local_prop_name] != undefined) {
			const local_prop_value = user.maintainer_role_extra_data[local_prop_name];

			switch (local_prop_name) {
				default:
					backend_maintainer_role_extra_data[backend_prop_name] =
						local_prop_value;
					break;
			}
		}
	}

	backend_user.maintainerRoleExtraData = backend_maintainer_role_extra_data;
}

function convert_guest_data(user, backend_user) {
	const guest_props_convertion = {
		phone: "phone",
		address: "address",
		customerSince: "customer_since",
		guestInternalId: "guest_id",
		vatNumber: "vat_number"
	};

	let backend_guest_role_extra_data = {};
	for (let index in guest_props_convertion) {
		const local_prop_name = guest_props_convertion[index];
		const backend_prop_name = index;

		if (user.guest_role_extra_data[local_prop_name] != undefined) {
			const local_prop_value = user.guest_role_extra_data[local_prop_name];

			switch (local_prop_name) {
				case "customer_since":
					backend_guest_role_extra_data[backend_prop_name] =
						moment(local_prop_value).toISOString();
					break;

				default:
					backend_guest_role_extra_data[backend_prop_name] = local_prop_value;
					break;
			}
		}
	}

	backend_user.guestRoleExtraData = backend_guest_role_extra_data;
}
