<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="flex-center-content full-width full-height">
			<Loader size="30" />
		</div>
		<div v-else class="flex-auto-full-height-content p-sm full-height">
			<div class="content">
				<div v-if="error" class="max-width-fields fixed">
					<Alert :message="error" />
				</div>
				<div v-else-if="success" class="max-width-fields fixed">
					<Alert message="general.update_successful" type="success" />
				</div>

				<div
					class="two-col-fields max-width-fields"
					v-bind:class="{ 'm-t-xxxl': error || success }"
				>
					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							<span>{{ $t("entities.energy_system.pv_data.peak_power") }}</span>
							<span> (kWp)<sup>*</sup></span>
						</div>
						<div>
							<NumberInput v-model="pv_data_to_update.peak_power" />
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							<span>
								{{ $t("entities.energy_system.pv_data.nominal_power") }}
							</span>
							<span> (kW)<sup>*</sup></span>
						</div>

						<div>
							<NumberInput v-model="pv_data_to_update.nominal_power" />
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							<span>{{ $t("entities.energy_system.pv_data.tracking") }}</span>
							<span><sup>*</sup></span>
						</div>

						<div>
							<MultiselectMultilingualEntity
								v-model="pv_data_to_update.tracking"
								multilingual_entity_name="energy_system_tracking_options"
							/>
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							<span>{{ $t("entities.energy_system.pv_data.tilt") }}</span>
							<span> (º)<sup>*</sup></span>
						</div>
						<div>
							<NumberInput v-model="pv_data_to_update.tilt" />
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							<span>{{ $t("entities.energy_system.pv_data.azimuth") }}</span>
							<span> (º)<sup>*</sup></span>
						</div>
						<div>
							<NumberInput v-model="pv_data_to_update.azimuth" />
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							<span>{{ $t("entities.energy_system.pv_data.losses") }}</span>
							<span> (%)</span>
						</div>
						<div>
							<NumberInput v-model="pv_data_to_update.losses" />
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<span>{{ $t("entities.energy_system.pv_data.solar_type") }}</span>
						<div>
							<MultiselectMultilingualEntity
								v-model="pv_data_to_update.solar_type"
								multilingual_entity_name="energy_system_solar_options"
							/>
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<span>
							{{ $t("entities.energy_system.pv_data.facility_type") }}
						</span>
						<div>
							<MultiselectMultilingualEntity
								v-model="pv_data_to_update.facility_type"
								multilingual_entity_name="energy_system_facility_options"
							/>
						</div>
					</div>

					<div class="display-flex-vertical-space-between gap-xs">
						<div class="display-flex gap-xs">
							<span>
								{{ $t("entities.energy_system.pv_data.pr_limit") }} (%)
							</span>
							<Tooltip
								:tooltip_text="
									$t('user_settings.energy_systems_manager.pr_limit_tooltip')
								"
								:single_line_tooltip_text="true"
								position="right"
							/>
						</div>

						<div>
							<NumberInput
								v-model="pv_data_to_update.pr_limit"
								:min="0"
								:max="100"
							/>
						</div>
					</div>
				</div>

				<EditPvPanels
					class="m-t-xl max-width-fields"
					v-model="pv_data_to_update.pv_panel_models"
				/>

				<div class="two-col-fields max-width-fields m-t-xl">
					<div>
						<span>{{
							$t("entities.energy_system.pv_data.connection_type")
						}}</span>
						<div>
							<MultiselectMultilingualEntity
								v-model="pv_data_to_update.connection_type"
								multilingual_entity_name="connection_type_options"
							/>
						</div>
					</div>

					<div>
						<span>{{ $t("entities.energy_system.pv_data.voltage") }}</span>
						<div>
							<NumberInput v-model="pv_data_to_update.voltage" />
						</div>
					</div>

					<div>
						<span>{{
							$t("entities.energy_system.pv_data.land_registry_reference")
						}}</span>
						<div>
							<TextInput v-model="pv_data_to_update.land_registry_reference" />
						</div>
					</div>

					<div>
						<span>
							{{ $t("entities.energy_system.pv_data.energy_to_grid_price") }}
						</span>
						<div>
							<NumberInput v-model="pv_data_to_update.energy_to_grid_price" />
						</div>
					</div>

					<div>
						<span>
							{{ $t("entities.energy_system.pv_data.distribution_company") }}
						</span>
						<div>
							<TextInput v-model="pv_data_to_update.distribution_company" />
						</div>
					</div>

					<div>
						<span>
							{{ $t("entities.energy_system.pv_data.system_id_in_grid") }}
						</span>
						<div>
							<TextInput v-model="pv_data_to_update.system_id_in_grid" />
						</div>
					</div>
				</div>

				<EditEstimatedProduction
					v-if="energy_system.commissioning_date"
					class="m-t-xl"
					v-model="pv_data_to_update.estimated_production"
					:validation_error="error && error.type == 'validation' ? error : null"
				/>
				<div v-else class="m-t display-flex">
					<div class="font-bold grey2-color">
						<span>
							{{ $t("user_settings.system_manager.estimated_production") }}
							(kWh)</span
						>
					</div>
					<Tooltip
						class="m-l-xs"
						position="right"
						:tooltip_text="
							$t(
								'user_settings.energy_systems_manager.estimated_generation_tooltip'
							)
						"
						:single_line_tooltip_text="true"
					></Tooltip>
				</div>
			</div>

			<div class="footer p-t m-t border-t text-right">
				<button class="btn btn-primary" v-on:click="update_pv_data">
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import NumberInput from "@form_components/NumberInput";
import TextInput from "@form_components/TextInput";
import Multiselect from "@form_components/Multiselect";
import MultiselectMultilingualEntity from "@form_components/MultiselectMultilingualEntity";
import Tooltip from "../../../../../sub_components/Tooltip.vue";

import EditPvPanels from "./EditPvPanels";
import EditEstimatedProduction from "./EditEstimatedProduction";

import EnergySystemPvDataUpdaterClass from "@src/energy-system/application/energy-system-pv-data-updater";
const EnergySystemPvDataUpdater = new EnergySystemPvDataUpdaterClass();

export default {
	name: "EnergySystemEditGeneral",
	components: {
		Alert,
		Loader,
		NumberInput,
		TextInput,
		Multiselect,
		MultiselectMultilingualEntity,
		Tooltip,
		EditPvPanels,
		EditEstimatedProduction
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			pv_data_to_update: this.energy_system.pv_data
				? Object.assign({}, this.energy_system.pv_data)
				: {}
		};
	},
	methods: {
		async update_pv_data() {
			this.loading = true;
			this.error = null;

			try {
				const data_to_update = Object.assign({}, this.pv_data_to_update);

				const updated_energy_system =
					await EnergySystemPvDataUpdater.update_energy_system_pv_data(
						this.energy_system.id,
						data_to_update
					);

				this.pv_data_to_update = updated_energy_system.pv_data;

				this.$emit("energy_system_updated", updated_energy_system);

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
.max-width-fields {
	width: 800px;
}

.two-col-fields {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 15px;
	row-gap: 30px;
}
</style>
