import EnergyProviderUpdatorInterfaceClass from "../infrastructure/energy-provider-auth-updater-interface";
import EnergyProviderValidator from "../domain/energy-provider-auth-finder-validator";
import EnergyProvidersStoreUtil from "./utils/energy-provider-auths-store-util";

import EnergyProviderAuthUpdatorValidators from "../domain/energy-provider-auth-updator-validators";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new EnergyProviderUpdatorInterfaceClass();
		this.store_util = EnergyProvidersStoreUtil;
		this.validator = EnergyProviderValidator;
	}

	async energy_provider_auth_data_update(
		provider_name,
		energy_provider_auth_id,
		energy_provider_auth_data
	) {
		try {
			const updated_energy_provider_auth =
				await this.backend_interface.energy_provider_auth_data_update(
					provider_name,
					energy_provider_auth_id,
					energy_provider_auth_data
				);

			const validated_energy_provider = this.validator.validate(
				updated_energy_provider_auth
			);

			this.store_util.add_or_update_item(validated_energy_provider);

			return validated_energy_provider;
		} catch (error) {
			throw new AppError(
				"error_energy_provider_auth_data_update",
				"general.errors.general_error",
				error
			);
		}
	}

	async energy_provider_auth_extra_data_update(
		provider_name,
		energy_provider_auth_id,
		energy_provider_auth_extra_data
	) {
		try {
			const updated_energy_provider_auth =
				await this.backend_interface.energy_provider_auth_extra_data_update(
					provider_name,
					energy_provider_auth_id,
					energy_provider_auth_extra_data
				);

			const validated_energy_provider = this.validator.validate(
				updated_energy_provider_auth
			);

			this.store_util.add_or_update_item(validated_energy_provider);

			return validated_energy_provider;
		} catch (error) {
			throw new AppError(
				"error_energy_provider_auth_extra_data_update",
				"general.errors.general_error",
				error
			);
		}
	}

	async energy_provider_auth_update(
		provider_name,
		energy_provider_auth_id,
		auth_data
	) {
		try {
			const provider_auth_validator =
				EnergyProviderAuthUpdatorValidators(provider_name);
			if (provider_auth_validator)
				auth_data = provider_auth_validator.validate(auth_data);

			await this.backend_interface.energy_provider_auth_update(
				provider_name,
				energy_provider_auth_id,
				auth_data
			);

			return true;
		} catch (error) {
			throw new AppError(
				"error_energy_provider_auth_update",
				"general.errors.general_error",
				error
			);
		}
	}
}
