import EnergyMeasurementValidator from "@src/asset-energy-data/domain/energy-measurement-validator";
import AssetDailyEnergyAggregatedFinderInterfaceClass from "@src/asset-energy-data/infrastructure/asset-daily-aggregated-energy-finder-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface =
			new AssetDailyEnergyAggregatedFinderInterfaceClass();
		this.energy_measurement_validator = EnergyMeasurementValidator;
	}

	async get_asset_daily_aggregated_energy(asset_id, from, to) {
		try {
			const interface_data =
				await this.backend_interface.get_asset_daily_aggregated_energy(
					asset_id,
					from,
					to
				);

			let validated_data =
				this.energy_measurement_validator.validate(interface_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_daily_aggregated_energy",
				"general.errors.general_error",
				e
			);
		}
	}
}
