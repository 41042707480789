export default (local_object, custom_props_names_convertions = {}) => {
	const convert_camel_case_to_snake_case = (str) =>
		str
			.toLowerCase()
			.replace(/([-_][a-z])/g, (group) =>
				group.toUpperCase().replace("-", "").replace("_", "")
			);

	const convert_array = (array) => {
		let result = [];
		array.forEach((array_item) => {
			if (Array.isArray(array_item)) {
				result.push(convert_array(array_item));
			} else if (typeof array_item == "object") {
				result.push(convert_object(array_item));
			} else result.push(array_item);
		});

		return result;
	};

	const convert_object = (object) => {
		let result = {};
		for (let local_key in object) {
			const local_prop_name =
				custom_props_names_convertions[local_key] &&
				custom_props_names_convertions[local_key].local_name
					? custom_props_names_convertions[local_key].local_name
					: convert_camel_case_to_snake_case(local_key);

			let value = object[local_key];

			if (value != null) {
				if (
					custom_props_names_convertions[local_key] &&
					custom_props_names_convertions[local_key].formatter
				)
					value = custom_props_names_convertions[local_key].formatter(value);
				else if (Array.isArray(value)) value = convert_array(value);
				else if (typeof value == "object") value = convert_object(value);
				else if (local_prop_name == "time") value = new Date(value);
			}

			result[local_prop_name] = value;
		}

		return result;
	};

	return convert_object(local_object);
};
