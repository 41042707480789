import AssetsFinderInterfaceClass from "@src/asset/infrastructure/assets-finder-interface";
import AssetFinderValidator from "@src/asset/domain/asset-finder-validator";

import AssetsLastUpdateUtil from "./utils/assets-last-update-util";
import AssetsStoreUtil from "./utils/assets-store-util";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = AssetFinderValidator;
		this.backend_interface = new AssetsFinderInterfaceClass();

		this.assets_last_update_util = new AssetsLastUpdateUtil();
		this.store_util = AssetsStoreUtil;
	}

	async get_user_assets(force_download = false) {
		var cached_assets = await this.store_util.get_items();
		if (cached_assets && !force_download) return cached_assets;

		try {
			const interface_result = await this.backend_interface.get_user_assets();

			let validated_result = [];
			if (interface_result.length > 0) {
				interface_result.forEach((asset) => {
					const validated_asset = this.validator.validate(asset);
					validated_result.push(validated_asset);
				});
			}

			await this.store_util.save_items(
				validated_result,
				moment().add(2, "hours").toDate()
			);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_user_assets",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_asset_by_id(asset_id) {
		return await this.store_util.get_cached_item_by_id(asset_id);
	}

	async get_user_assets_by_text(text_to_search) {
		const user_assets = await this.get_user_assets();

		return user_assets.filter((asset) => {
			return (
				asset.name.toLowerCase().search(text_to_search) != -1 ||
				asset.custom_id.toLowerCase().search(text_to_search) != -1
			);
		});
	}

	get_assets_last_update() {
		return this.assets_last_update_util.get_assets_last_update();
	}
}
