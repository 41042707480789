import Joi from "joi";

export default Joi.object({
	plan_type: Joi.string().valid("developer", "partner").required(),
	client_id: Joi.string().required(),
	client_secret: Joi.string().required(),
	api_key: Joi.string().required(),

	//plant_type == developer
	user_code: Joi.string().when("plan_type", {
		is: "developer",
		then: Joi.required()
	}),
	redirect_uri: Joi.string().when("plan_type", {
		is: "developer",
		then: Joi.required()
	}),

	//plant_type == partner
	username: Joi.string().when("plan_type", {
		is: "partner",
		then: Joi.required()
	}),
	password: Joi.string().when("plan_type", {
		is: "partner",
		then: Joi.required()
	})
});
