<template>
	<div id="admin-user-api-key" class="white-bg rounded-s">
		<PageHeader :text="$t('general.edit')" close_name="admin-users" />
		<div class="p-sm">
			<div v-if="loading" class="flex-center-content">
				<Loader size="25" />
			</div>
			<div v-else>
				<div v-if="error" class="m-b">
					<Alert :message="error" />
				</div>
				<div v-else-if="user_api_key_removed" class="m-b">
					<Alert message="general.update_successful" type="success" />
				</div>

				<div v-if="new_api_key">
					<span> Api key: </span>
					<span class="font-bold">
						{{ new_api_key }}
					</span>
				</div>

				<div class="flex-horizontal-right">
					<button class="btn btn-primary" v-on:click="user_api_key_update">
						{{ $t("general.update") }}
					</button>
					<button class="btn btn-danger m-l" v-on:click="user_api_key_remove">
						{{ $t("general.delete") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";

import UserApiKeyUpdaterClass from "@src/admin/application/user-api-key-updater";
import UserApiKeyEraserClass from "@src/admin/application/user-api-key-eraser";

const UserApiKeyUpdater = new UserApiKeyUpdaterClass();
const UserApiKeyEraser = new UserApiKeyEraserClass();

export default {
	name: "UserApiKey",
	components: {
		Loader,
		Alert,
		PageHeader
	},
	data() {
		return {
			loading: false,
			error: null,
			new_api_key: null,
			user_api_key_removed: false
		};
	},
	methods: {
		async user_api_key_update() {
			this.loading = true;
			this.error = null;
			this.new_api_key = null;
			this.user_api_key_removed = false;

			try {
				const user_id = this.$route.params.user_id;
				const result = await UserApiKeyUpdater.user_api_key_update(user_id);
				this.new_api_key = result.api_key;
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		async user_api_key_remove() {
			this.loading = true;
			this.error = null;
			this.new_api_key = null;

			try {
				const user_id = this.$route.params.user_id;
				await UserApiKeyEraser.user_api_key_remove(user_id);

				this.user_api_key_removed = true;
				setTimeout(() => {
					this.user_api_key_removed = false;
				}, 5000);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#admin-user-api-key {
	width: 600px;
}
</style>
