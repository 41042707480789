import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import IndexedDbInterfaceClass from "@src/shared/infrastructure/indexed-db-interface";
import UserBackendInterface from "@interfaces/backend-interfaces/user-backend-interface";
import UserFinderClass from "@src/user/application/user-finder.js";

import UserLoginValidator from "@validators/user-login-validator";
import UserValidator from "@src/user/domain/user-finder-validator.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";
import { store_entity_names } from "@config";

export default class {
	constructor() {
		this.set_storage_interface(new StoreInterfaceClass());
		this.indexed_db_interface = new IndexedDbInterfaceClass();
		this.set_user_backend_interface(new UserBackendInterface());

		this.user_token_entity_name = store_entity_names.user_token;
		this.user_data = store_entity_names.user_data;
		this.users_list_entity_name = store_entity_names.users;
		this.current_admin_token_entity = store_entity_names.current_admin_token;
		this.api_token_entity_name = store_entity_names.api_token;

		this.user_login_validator = UserLoginValidator;
		this.user_validator = UserValidator;

		this.user_finder = new UserFinderClass();
	}

	set_storage_interface(storage_interface) {
		this.storage_interface = storage_interface;
	}

	set_user_backend_interface(user_backend_interface) {
		this.user_backend_interface = user_backend_interface;
	}

	async login(user_login) {
		this.user_login_validator.validate(user_login);

		this.storage_interface.delete_all_entities();
		const user_token = await this.user_backend_interface.user_login(
			user_login.email,
			user_login.password
		);

		this.storage_interface.set(
			this.user_token_entity_name,
			user_token.token,
			moment(user_token.end_date).toDate()
		);

		await this.user_finder.get_user(true);

		return true;
	}

	set_api_token(api_token) {
		this.storage_interface.delete_all_entities();
		this.storage_interface.set(this.api_token_entity_name, api_token);
	}

	async refresh_user() {
		return await this.user_finder.get_user(true);
	}

	get_current_user_token() {
		return this.storage_interface.get(this.user_token_entity_name);
	}

	get_avatar_url(user) {
		return user.avatar;
	}

	user_has_role(roles) {
		const allowed_roles = Array.isArray(roles) ? roles : [roles];

		const user = this.user_finder.get_stored_user();
		if (!user) return false;

		for (var key in allowed_roles) {
			if (user.roles.indexOf(allowed_roles[key]) != -1) return true;
		}
		return false;
	}

	async switch_to_user(user) {
		const user_to_switch_token =
			await this.user_backend_interface.get_user_token(user);
		const current_admin_token = this.storage_interface.get(
			this.user_token_entity_name
		);

		this.storage_interface.delete_all_entities();
		//Save user tu switch token
		this.storage_interface.set(
			this.user_token_entity_name,
			user_to_switch_token.token,
			moment(user_to_switch_token.end_date).toDate()
		);
		//Save admin token
		this.storage_interface.set(
			this.current_admin_token_entity,
			current_admin_token
		);

		return await this.refresh_user();
	}

	get_switched_admin() {
		return this.storage_interface.get(this.current_admin_token_entity);
	}

	async logout() {
		const switched_admin_token = await this.storage_interface.get(
			this.current_admin_token_entity
		);

		this.storage_interface.delete_all_entities();
		await this.indexed_db_interface.remove_db();

		//If admin was switching return to its app
		if (switched_admin_token) {
			this.storage_interface.set(
				this.user_token_entity_name,
				switched_admin_token
			);
			const current_user = await this.refresh_user();
			this.storage_interface.set(this.user_data, current_user);
		}
	}

	//Locations images watermark
	async upload_locations_images_watermark(watermark_image) {
		const result =
			await this.user_backend_interface.upload_plants_images_watermark(
				watermark_image
			);
		await this.refresh_user();
		return result;
	}

	async change_user_password(user_new_password) {
		try {
			await this.user_backend_interface.change_user_password(user_new_password);
		} catch (e) {
			throw new AppError("500", "general.errors.general_error", e);
		}
	}

	async change_user_settings(user) {
		this.user_validator.validate(user);

		try {
			await this.user_backend_interface.change_user_settings(user);
			await this.user_finder.get_user(true);
			await this.get_custom_user_interface_data();
		} catch (e) {
			throw new AppError("500", "general.errors.general_error", e);
		}
	}

	async create_user(new_user) {
		const validated_new_user = this.user_validator.validate(new_user);

		try {
			const created_user = await this.user_backend_interface.create_user(
				validated_new_user
			);
			const validated_created_user = this.user_validator.validate(created_user);
			return validated_created_user;
		} catch (e) {
			throw new AppError(
				"create_user_error",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_admin_data() {
		try {
			return this.user_has_role("supervisor")
				? await this.user_finder.get_user()
				: await this.user_backend_interface.get_admin_data();
		} catch (e) {
			throw new AppError(
				"get_admin_data_error",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_custom_user_interface_data() {
		const current_user = await this.user_finder.get_user();
		let result;

		const is_user_supervisor = current_user.roles.indexOf("supervisor") != -1;
		if (is_user_supervisor) {
			result = current_user.use_custom_interface
				? {
						logo: current_user.assets_watermark
							? current_user.assets_watermark
							: null,
						primary_color: current_user.custom_color
							? current_user.custom_color
							: null
				  }
				: null;
		} else {
			const is_user_guest_or_maintainer =
				current_user.roles.indexOf("guest") != -1 ||
				current_user.roles.indexOf("maintainer") != -1;
			if (is_user_guest_or_maintainer) {
				const admin_data = await this.get_admin_data();
				result = {
					logo: admin_data.custom_interface
						? admin_data.custom_interface.assets_watermark
						: null,
					primary_color: admin_data.custom_interface
						? admin_data.custom_interface.custom_color
						: null
				};
			}
		}

		this.storage_interface.set(
			"custom_user_interface_data",
			result,
			moment().add(2, "hours").toDate()
		);

		return this.storage_interface.get("custom_user_interface_data");
	}
}
