import EnergySystemDataFirstDateValidator from "../domain/energy-system-data-first-date-validator";
import EnergySystemDataFirstDateFinderInterfaceClass from "../infrastructure/energy-system-data-first-date-finder-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = EnergySystemDataFirstDateValidator;
		this.backend_interface =
			new EnergySystemDataFirstDateFinderInterfaceClass();
	}

	async get_energy_system_data_first_date(system_id) {
		try {
			const interface_data =
				await this.backend_interface.get_energy_system_data_first_date(
					system_id
				);

			const validated_data = this.validator.validate(interface_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_energy_system_data_first_date",
				"general.errors.general_error",
				e
			);
		}
	}
}
