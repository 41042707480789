import UsersByRoleCreatorInterface from "../infrastructure/owned-user-creator-interface";
import UserValidator from "../domain/user-finder-validator";
import OwnedUsersStoreUtilClass from "./utils/owned-users-store-util";
import OwnedManagerUsersStoreUtilClass from "./utils/owned-manager-users-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new UsersByRoleCreatorInterface();
		this.validator = UserValidator;
	}

	async create_user(user, manager_id = null) {
		const validated_user = this.validator.validate(user);

		try {
			const interface_user = await this.backend_interface.create_user(
				validated_user,
				manager_id
			);
			const validated_interface_user = this.validator.validate(interface_user);

			const user_role = validated_interface_user.roles[0];
			const store_util = manager_id
				? new OwnedManagerUsersStoreUtilClass(manager_id, user_role)
				: new OwnedUsersStoreUtilClass(user_role);
			await store_util.add_or_update_item(validated_interface_user);

			return validated_interface_user;
		} catch (e) {
			throw new AppError(
				"error_create_user",
				"general.errors.general_error",
				e
			);
		}
	}
}
