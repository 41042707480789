import ManagerUsersByRoleFinderInterface from "../infrastructure/owned-manager-users-finder-interface";
import UserValidator from "../domain/user-finder-validator";
import OwnedManagerUsersStoreUtilClass from "./utils/owned-manager-users-store-util";

import moment from "moment";
import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new ManagerUsersByRoleFinderInterface();
		this.validator = UserValidator;
	}

	async get_owned_manager_users_by_role(
		manager_id,
		role,
		force_download = false
	) {
		try {
			const owned_manager_users_store_util =
				new OwnedManagerUsersStoreUtilClass(manager_id, role);

			let users;
			if (!force_download) {
				users = await owned_manager_users_store_util.get_items();
				if (users) return users;
			}

			users = await this.backend_interface.get_owned_manager_users_by_role(
				manager_id,
				role
			);
			const validated_users = users.map((user) =>
				this.validator.validate(user)
			);

			await owned_manager_users_store_util.save_items(
				validated_users,
				moment().add(1, "days").toDate()
			);

			return validated_users;
		} catch (e) {
			throw new AppError(
				"error_get_owned_manager_users_by_role",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_owned_manager_user(manager_id, role, user_id) {
		const role_users = await this.get_owned_manager_users_by_role(
			manager_id,
			role
		);

		return role_users.find((user) => user.id == user_id);
	}
}
