<template>
	<div>
		<div class="border-b">
			<div class="font-bold">
				{{ $t("user_settings.users_manager." + role + "s") }}
			</div>
		</div>
		<div class="m-t">
			<Loader v-if="loading" size="30" class="margin-center" />
			<Alert v-else-if="error" type="danger" :message="error" />

			<div v-else>
				<Multiselect
					label="first_name"
					track_by="id"
					:options="not_allowed_users_in_asset"
					:multiple="false"
					@input="add_user_to_asset"
					v-model="multiselect_value"
				/>
				<div v-if="allowed_users_in_asset">
					<div v-for="user in allowed_users_in_asset" class="m-t display-flex">
						<div
							v-if="!user.has_access_all_assets"
							@click="remove_user_from_asset(user)"
							class="danger-color font-xl cursor-pointer flex-vertical-center"
						>
							<Icon icon="trash" size="20" />
						</div>
						<div v-else class="grey2-color font-xl flex-vertical-center">
							<Icon icon="trash" size="20" />
						</div>
						<div class="m-l flex-vertical-center">
							<span>{{ user.first_name }}</span>
							<span v-if="user.last_name"> {{ user.last_name }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@components/sub_components/Loader";
import Alert from "@alert";
import Multiselect from "@form_components/Multiselect";

import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import OwnedUsersUpdaterClass from "@src/user/application/owned-user-updater";
const OwnedUsersFinder = new OwnedUsersFinderClass();
const OwnedUsersUpdater = new OwnedUsersUpdaterClass();

export default {
	name: "AssetUsers",
	components: {
		Loader,
		Alert,
		Multiselect
	},
	props: { asset: Object, role: String },
	data() {
		return {
			loading: true,
			error: null,
			multiselect_value: null,
			users_not_in_location: null,
			location_users: null,

			allowed_users_in_asset: [],
			not_allowed_users_in_asset: []
		};
	},
	async mounted() {
		this.load_allowed_users();
	},
	methods: {
		async load_allowed_users() {
			this.loading = true;

			try {
				this.allowed_users_in_asset = [];
				this.not_allowed_users_in_asset = [];

				const asset_id = this.asset.id;
				const owned_users = await OwnedUsersFinder.get_owned_users_by_role(
					this.role
				);

				owned_users.forEach((user) => {
					if (
						user.has_access_all_assets ||
						(user.allowed_assets &&
							user.allowed_assets.find(
								(allowd_asset_id) => allowd_asset_id == asset_id
							))
					)
						this.allowed_users_in_asset.push(user);
					else this.not_allowed_users_in_asset.push(user);
				});
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async add_user_to_asset(owned_user_id) {
			this.loading = true;

			try {
				const asset_id = this.asset.id;
				var owned_user = this.not_allowed_users_in_asset.find(
					(user) => user.id == owned_user_id
				);
				const user_to_update = JSON.parse(JSON.stringify(owned_user));

				if (user_to_update.allowed_assets)
					user_to_update.allowed_assets.push(asset_id);
				else user_to_update.allowed_assets = [asset_id];

				await OwnedUsersUpdater.update_user(user_to_update);
			} catch (error) {
				this.error = error;
				return;
			} finally {
				this.loading = false;
			}

			this.load_allowed_users();
		},
		async remove_user_from_asset(owned_user) {
			this.loading = true;

			try {
				const asset_id = this.asset.id;
				const user_to_update = JSON.parse(JSON.stringify(owned_user));

				user_to_update.allowed_assets.splice(
					user_to_update.allowed_assets.indexOf(asset_id),
					1
				);

				await OwnedUsersUpdater.update_user(user_to_update);
			} catch (error) {
				this.error = error;
				return;
			} finally {
				this.loading = false;
			}

			this.load_allowed_users();
		}
	}
};
</script>
