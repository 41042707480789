<template>
	<div class="full-width full-height">
		<div v-if="loading" class="flex-center-content full-height">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else class="flex-auto-full-height-content">
			<div
				class="header p-b display-flex"
				v-if="selected_managers_tree && selected_managers_tree.length > 0"
			>
				<div
					class="flex-vertical-center cursor-pointer"
					v-on:click="supervisor_selected"
				>
					{{ user.first_name }}
				</div>

				<div
					v-for="(manager, index) in selected_managers_tree"
					class="display-flex"
				>
					<div class="breadcrump-separator flex-vertical-center m-l-xs m-r-xs">
						<Icon icon="chevron-right" size="20" />
					</div>

					<div
						v-if="index == selected_managers_tree.length - 1"
						class="flex-vertical-center font-bold"
					>
						{{ manager.first_name }}
					</div>
					<div
						v-else
						class="flex-vertical-center cursor-pointer"
						v-on:click="manager_selected(manager.id)"
					>
						{{ manager.first_name }}
					</div>
				</div>

				<!-- <div class="breadcrump-separator flex-vertical-center m-l-xs m-r-xs">
					<Icon icon="chevron-right" size="20" />
				</div>

				<div class="flex-vertical-center font-bold">
					{{ selected_manager.first_name }}
				</div> -->
			</div>
			<div class="content-no-scroll">
				<div class="flex-auto-full-height-content">
					<div class="header">
						<div id="roles-tab" class="display-flex">
							<button
								v-if="can_manage_users"
								v-on:click="selected_role = 'manager'"
								class="border-l border-r border-t rounded-s_top"
								v-bind:class="{ active: selected_role == 'manager' }"
							>
								{{ $t("user_settings.users_manager.managers") }}
							</button>
							<button
								v-on:click="selected_role = 'maintainer'"
								class="border-l border-r border-t rounded-s_top"
								v-bind:class="{ active: selected_role == 'maintainer' }"
							>
								{{ $t("user_settings.users_manager.maintainers") }}
							</button>
							<button
								v-on:click="selected_role = 'guest'"
								class="border-l border-r border-t rounded-s_top"
								v-bind:class="{ active: selected_role == 'guest' }"
							>
								{{ $t("user_settings.users_manager.guests") }}
							</button>
						</div>
					</div>
					<div class="content-no-scroll">
						<UsersContent
							class="white-bg p-sm border-all rounded-s_bottom rounded-s_right"
							:role="selected_role"
							:selected_mananger_id="selected_manager"
							v-on:manager_selected="manager_selected"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import UsersContent from "./UsersContent";

import UserFinderClass from "@src/user/application/user-finder.js";
import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const UserFinder = new UserFinderClass();
const OwnedUsersFinder = new OwnedUsersFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "Users",
	components: {
		Loader,
		Alert,
		UsersContent
	},
	data() {
		const can_manage_users = UserPermissionsUtil.can_user("manage_users");

		return {
			selected_role: can_manage_users ? "manager" : "maintainer",
			loading: false,
			error: null,
			user: null,
			selected_managers_tree: null,
			selected_manager: null,
			can_manage_users: can_manage_users
		};
	},
	async mounted() {
		try {
			this.loading = true;
			this.user = await UserFinder.get_user();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async manager_selected(manager_id) {
			this.loading = true;

			try {
				let previously_selected_manager_index = null;
				if (!this.selected_managers_tree) this.selected_managers_tree = [];
				else {
					for (let index in this.selected_managers_tree) {
						const manager = this.selected_managers_tree[index];
						if (manager.id == manager_id) {
							previously_selected_manager_index = index;
							break;
						}
					}
				}

				if (previously_selected_manager_index) {
					this.selected_managers_tree.splice(
						previously_selected_manager_index + 1
					);
				} else {
					const manager = await OwnedUsersFinder.get_owned_user(
						"manager",
						manager_id
					);
					this.selected_managers_tree.push(manager);

					this.selected_role = "manager";
				}

				this.selected_manager = manager_id;
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		supervisor_selected() {
			this.selected_manager = null;
			this.selected_managers_tree = null;
			this.selected_role = this.can_manage_users ? "manager" : "maintainer";
		}
	}
};
</script>

<style lang="css" scoped>
#roles-tab button {
	padding: 5px 15px;
	background-color: white;
	border-bottom: none;
}
#roles-tab button.active {
	background-color: var(--custom-primary);
	color: white;
	border: none;
}
.breadcrump-separator {
	padding-top: 2px;
}
</style>
