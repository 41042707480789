import axios from "axios";
import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import RestError from "@classes/errors/rest_error";
import RestAuthenticationError from "@classes/errors/rest-authentication-error";
import AppError from "@classes/errors/app_error";

export default class RestInterface {
	constructor(use_user_token_if_available = true) {
		this.use_user_token_if_available = use_user_token_if_available;
		this.set_rest_library(axios);
	}

	set_rest_library(rest_library) {
		this.rest_library = rest_library;
	}

	_get_user_token() {
		if (!this.use_user_token_if_available) return null;
		const StorageInterface = new StoreInterfaceClass();
		return StorageInterface.get("user_backend_token");
	}

	_check_rest_error(rest_error) {
		if (rest_error.response && rest_error.response.status) {
			if (rest_error.response.status == 401)
				return new RestAuthenticationError(
					rest_error.response.status,
					rest_error.response.message || undefined
				);
			else
				return new RestError(
					rest_error.response.status,
					rest_error.response.message || undefined
				);
		} else {
			return new AppError(500, "generar.errors.general_error", rest_error);
		}
	}

	async get(url, args = null) {
		let params = {};
		try {
			if (this._get_user_token()) {
				params.headers = {
					TOKEN: this._get_user_token()
				};
			}
			if (args) params.params = args;

			const response = await this.rest_library.get(url, params);
			return response.data;
		} catch (error) {
			const reported_error = this._check_rest_error(error);
			throw reported_error;
		}
	}

	async post(url, args = {}) {
		try {
			let body_params;
			let config = {};
			if (args.files) {
				body_params = new FormData();
				config.headers = {
					"Content-Type": "multipart/form-data"
				};
				for (var index in args.files) {
					body_params.append(index, args.files[index], args.files[index].name);
				}
				//If a post request with files and params
				if (args.params) {
					for (let key in args.params) {
						body_params.append(key, args.params[key]);
					}
				}
			} else if (args.params) {
				body_params = args.params;
			}

			if (args.query_params) {
				config = {
					params: args.query_params
				};
			}

			if (this._get_user_token()) {
				if (!config.headers) config.headers = {};
				config.headers.TOKEN = this._get_user_token();
			}
			//const response = await this.rest_library.post(url, params, config);
			const response = await this.rest_library.post(url, body_params, config);

			return response.data;
		} catch (error) {
			const reported_error = this._check_rest_error(error);
			throw reported_error;
		}
	}

	async put(url, args = {}) {
		try {
			let params = {};
			let config = {};
			if (args && args.params) {
				params = args.params;
			}
			if (this._get_user_token()) {
				config.headers = { TOKEN: this._get_user_token() };
			}
			const response = await this.rest_library.put(url, params, config);
			return response.data;
		} catch (error) {
			const reported_error = this._check_rest_error(error);
			throw reported_error;
		}
	}

	async delete(url, args = {}) {
		try {
			let config = {};
			if (args && args.params) {
				config.params = args.params;
			}
			if (this._get_user_token()) {
				config.headers = { TOKEN: this._get_user_token() };
			}
			const response = await this.rest_library.delete(url, config);
			return response.data;
		} catch (error) {
			const reported_error = this._check_rest_error(error);
			throw reported_error;
		}
	}
}
