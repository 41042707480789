import Joi from "joi";
import Validator from "@validators/validator";

import EnergyProviderSlugValidator from "@src/shared/domain/energy-provider-slug-validator";

const user_energy_provider_info_schema = Joi.object({
	id: Joi.string().required(),
	provider: EnergyProviderSlugValidator.schema.required(),
	name: Joi.string(),
	systems_number: Joi.number().min(0).required(),
	is_valid: Joi.boolean(),
	last_update: Joi.date(),
	extra_data: Joi.object({
		authorized_nifs: Joi.array().items(Joi.string())
	})
});

export default new Validator(user_energy_provider_info_schema);
