<template>
	<div class="flex-auto-full-height-content">
		<div class="content">
			<div id="custom-data-table">
				<table v-if="formatted_items">
					<thead role="rowgroup">
						<tr role="row">
							<th
								v-for="(field, index) in fields"
								:class="{ 'cursor-pointer': field.sortable }"
								v-on:click="field.sortable ? set_order(field.key) : null"
								role="columnheader"
								scope="col"
								:aria-colindex="index + 1"
								:style="{ 'z-index': field.has_tooltip ? 2 : 1 }"
							>
								<div>
									<div v-if="head_has_custom_content(field.key)">
										<slot
											:name="'head(' + field.key + ')'"
											v-bind="{
												label: field.label
											}"
										/>
									</div>
									<div v-else>
										<span v-if="field.label">
											{{ field.label }}
										</span>
									</div>
									<div
										v-if="field.sortable"
										class="data-table-sort full-height"
									>
										<div class="flex-center-content full-height">
											<div>
												<div
													class="flex-center-content"
													:class="
														order_by && order_by == field.key && order == 'asc'
															? 'grey1-color'
															: 'grey2-color'
													"
												>
													<Icon icon="caret-up" size="16" />
												</div>
												<div
													class="flex-center-content"
													:class="
														order_by && order_by == field.key && order == 'desc'
															? 'grey1-color'
															: 'grey2-color'
													"
												>
													<Icon icon="caret-down" size="16" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</th>
						</tr>
					</thead>

					<tbody role="rowgroup">
						<tr
							v-for="(item, item_index) in items_to_show"
							role="row"
							:aria-rowindex="item_index + 1"
						>
							<td
								v-for="(field, index) in fields"
								role="cell"
								:aria-colindex="index + 1"
							>
								<div v-if="cell_has_custom_content(field.key)">
									<slot
										:name="'cell(' + field.key + ')'"
										v-bind="{
											value: get_field_formatted_value(item, field.key),
											item: get_item_original_item(item),
											index: item_index
										}"
									/>
								</div>

								<div v-else :class="field.class || null">
									<span>
										{{ get_field_formatted_value(item, field.key) }}
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div
			class="footer"
			v-if="
				(custom_current_page && custom_total_pages) ||
				(items_per_page && items_to_show && items_per_page < items.length)
			"
		>
			<div id="data-table-paginator" class="flex-center-content p-t-xs p-b-xs">
				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != 1 }"
					v-on:click="
						() => {
							if (current_page != 1) current_page = 1;
						}
					"
				>
					<Icon icon="chevron-left" size="10" />
					<Icon icon="chevron-left" size="10" />
				</div>
				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != 1 }"
					v-on:click="
						() => {
							if (current_page != 1) current_page = current_page - 1;
						}
					"
				>
					<Icon icon="chevron-left" size="10" />
				</div>

				<div
					class="flex-center-content"
					v-if="current_page > paginator_max_links + 1"
				>
					<span>...</span>
				</div>

				<div
					class="flex-center-content"
					:class="page == current_page ? 'grey1-bg white' : 'cursor-pointer'"
					v-for="page in paginator_pages_links"
					v-on:click="
						() => {
							if (page != current_page) current_page = page;
						}
					"
				>
					<span>{{ page }}</span>
				</div>

				<div
					class="flex-center-content"
					v-if="total_pages - current_page > paginator_max_links"
				>
					<span>...</span>
				</div>

				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != total_pages }"
					v-on:click="
						() => {
							if (current_page != total_pages) current_page = current_page + 1;
						}
					"
				>
					<Icon icon="chevron-right" size="10" />
				</div>
				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != total_pages }"
					v-on:click="
						() => {
							if (current_page != total_pages) current_page = total_pages;
						}
					"
				>
					<Icon icon="chevron-right" size="10" />
					<Icon icon="chevron-right" size="10" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DataTable",
	props: {
		items: Array,
		items_per_page: Number,
		fields: Array, //[{ key: String, label: String, class: String, sortable: Boolean, formatter: (value, key, item) => { return value; } }]
		default_order_by: String,
		default_order: String, //asc | desc
		filter: String,
		filter_fields: Array,
		//Async props
		async_actions: Boolean,
		custom_current_page: Number,
		custom_total_pages: Number
	},
	data() {
		return {
			formatted_items: null,
			order_by: this.default_order_by,
			order: this.default_order,
			current_page: this.custom_current_page || 1,
			paginator_max_links: 2
		};
	},
	mounted() {
		this.format_items();
	},
	methods: {
		format_items() {
			let result = [];

			for (let index in this.items) {
				const item = this.items[index];

				let row = {};

				this.fields.forEach((field_data) => {
					const field_path = field_data.key;
					const field_value = this.get_value_from_path(item, field_path, false);

					row[field_path] = field_value;
				});

				row["items_index"] = index;
				result.push(row);
			}

			this.formatted_items = result;
		},
		head_has_custom_content(field_path) {
			return this.$slots.hasOwnProperty("head(" + field_path + ")");
		},
		cell_has_custom_content(field_path) {
			return this.$slots.hasOwnProperty("cell(" + field_path + ")");
		},
		get_value_from_path(item, field_path) {
			return this.$get_object_value_from_path(item, field_path);
		},
		get_field_formatted_value(item, field_path) {
			const field_path_data = this.formatted_fields[field_path];
			const value = item[field_path];
			const original_item = this.get_item_original_item(item);

			return field_path_data.formatter
				? field_path_data.formatter(value, original_item)
				: value;
		},
		get_item_original_item(item) {
			return this.items[item.items_index];
		},
		set_order(order_by) {
			if (this.order_by != order_by) {
				this.order_by = order_by;
				this.order = "asc";
			} else {
				this.order = this.order == "asc" ? "desc" : "asc";
			}

			this.$emit("sort_changed", {
				order_by: this.order_by,
				order: this.order
			});
		}
	},
	computed: {
		formatted_fields() {
			let result = {};

			this.fields.forEach((item) => {
				result[item.key] = item;
			});

			return result;
		},
		filtered_items() {
			if (!this.formatted_items) return null;
			if (!this.filter || !this.filter_fields) return this.formatted_items;

			const filter_lower_case = this.filter.toLowerCase();

			return this.formatted_items.filter((item) => {
				for (let index in this.filter_fields) {
					const field = this.filter_fields[index];

					const value = item[field];
					if (!value || value == "") return false;
					const value_lower_case = value.toLowerCase();

					if (value_lower_case.search(filter_lower_case) != -1) return true;
				}

				return false;
			});
		},
		sorted_items() {
			if (!this.filtered_items) return null;

			if (!this.order_by || !this.order) return this.filtered_items;

			const order_by = this.order_by;
			const order = this.order;

			const first_item_with_order_by_element = this.filtered_items.find(
				(item) => {
					const value = item[order_by];
					return value != null && value != undefined;
				}
			);
			if (first_item_with_order_by_element == null) return this.filtered_items;

			const first_order_by_value_found =
				first_item_with_order_by_element[order_by];
			if (
				!this.$is_number(first_order_by_value_found) &&
				!this.$is_date(first_order_by_value_found) &&
				!this.$is_string(first_order_by_value_found)
			)
				return this.filtered_items;

			function sort_items(order_by, ascending, sort_numbers = false) {
				return function (a, b) {
					const a_value = a[order_by];
					const b_value = b[order_by];

					// equal items sort equally
					if (a_value === b_value) {
						return 0;
					}

					// nulls sort after anything else
					if (a_value == null || a_value == undefined) {
						return 1;
					} else if (b_value == null || b_value == undefined) {
						return -1;
					}

					if (sort_numbers)
						return ascending ? a_value - b_value : b_value - a_value;
					else {
						if (ascending)
							return a_value > b_value ? 1 : b_value > a_value ? -1 : 0;
						else return b_value > a_value ? 1 : a_value > b_value ? -1 : 0;
					}
				};
			}

			return this.filtered_items.sort(
				sort_items(
					order_by,
					order == "asc",
					this.$is_number(first_order_by_value_found)
				)
			);
		},
		items_to_show() {
			const order = this.order; //This line fixes bug when filtering and sorting at the same time watching if order changes

			let sorted_items = this.sorted_items;

			if (this.items_per_page && this.sorted_items) {
				return sorted_items.slice(
					(this.current_page - 1) * this.items_per_page,
					this.current_page * this.items_per_page
				);
			}

			return sorted_items;
		},
		total_pages() {
			if (this.custom_total_pages) return this.custom_total_pages;

			if (!this.sorted_items || !this.items_per_page) return 0;

			return Math.ceil(this.sorted_items.length / this.items_per_page);
		},
		paginator_pages_links() {
			if (!this.total_pages) return null;

			let result = [];

			if (this.current_page != 1) {
				for (
					let index = this.current_page - this.paginator_max_links;
					index < this.current_page;
					index++
				) {
					if (index > 0) result.push(index);
				}
			}

			result.push(this.current_page);

			if (this.current_page != this.total_pages) {
				for (
					let index = this.current_page + 1;
					index <= this.current_page + this.paginator_max_links;
					index++
				) {
					if (index <= this.total_pages) {
						result.push(index);
					}
				}
			}

			return result;
		}
	},
	watch: {
		filter() {
			this.current_page = 1;
			this.$emit("filtered");
		},
		items: {
			handler() {
				this.format_items();
			},
			deep: true
		},
		current_page(new_page, old_page) {
			this.$emit("page_changed", { new_page: new_page, old_page: old_page });
		}
	}
};
</script>

<style scoped>
.data-table-sort {
	position: absolute;
	right: 0;
	top: 0;
}
.data-table-sort > div > div > div {
	height: 6px;
}
#custom-data-table {
	max-height: 100%;
	margin: 0;
}
#custom-data-table table {
	border-collapse: collapse;
	margin-bottom: 0;
	width: 100%;
}
#custom-data-table table thead th {
	z-index: 1;
	padding: 0.75rem;
	position: sticky !important;
	top: 0;
	background-color: #ffffff;
	border-bottom: 2px solid #dee2e6;
}
#custom-data-table table tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}
#custom-data-table table tbody tr:hover {
	color: #212529;
	background-color: rgba(0, 0, 0, 0.075);
}
#custom-data-table table td {
	padding: 0.75rem;
}

#data-table-paginator > div {
	padding: 0.5rem 0.75rem;
	border: 1px solid #dee2e6;
	width: 2rem;
	height: 2rem;
}
#data-table-paginator > div:first-child {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
#data-table-paginator > div:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}
</style>
