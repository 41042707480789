import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.object({
	energy_from_grid_price_rate_id: Joi.string(),
	custom_price_types: Joi.array().items(
		Joi.object({
			id: Joi.string().required(),
			price: Joi.number().min(0).required()
		})
	)
});
export default new Validator(schema);
