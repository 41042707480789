import UsetsByRoleFinderInterfaceClass from "../infrastructure/users-by-role-finder-interface.js";
import UserFinderValidator from "@src/user/domain/user-finder-validator.js";
import StoreUtilClass from "@src/shared/application/utils/store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = UserFinderValidator;
		this.backend_interface = new UsetsByRoleFinderInterfaceClass();
		this.store_util = null;
	}

	async get_users_by_role(users_role, force_download = null) {
		try {
			this.store_util = new StoreUtilClass("users_" + users_role, 500);
			const cached_data = await this.store_util.get_data();
			if (cached_data && !force_download) {
				return cached_data;
			}

			const interface_users = await this.backend_interface.get_users_by_role(
				users_role
			);
			const validated_users = interface_users.map((interface_user) =>
				this.validator.validate(interface_user)
			);

			await this.store_util.save_items(validated_users);
			return validated_users;
		} catch (e) {
			throw new AppError(
				"error_get_users_by_role",
				"general.errors.general_error",
				e
			);
		}
	}
}
