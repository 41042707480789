import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_from_grid_price_rates() {
		const url = "/api/energy-from-grid-price-rates";
		const backend_items = await this.get(url);

		return backend_items.map((item) => this._convert_bakend_data(item));
	}

	_convert_bakend_data(backend_data) {
		return {
			id: backend_data.id,
			name: backend_data.name,
			price_types: backend_data.priceTypes
		};
	}
}
