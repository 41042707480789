import EnergySystemsFinderInterfaceClass from "../infrastructure/energy-systems-finder-interface";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator.js";

import EnergySystemsStoreUtil from "./utils/energy-systems-store-util";

import OperatorsUtil from "@services/utils/operators-util";
import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new EnergySystemsFinderInterfaceClass();
		this.store_util = EnergySystemsStoreUtil;
		this.validator = EnergySystemFinderValidator;
	}

	async get_user_energy_systems(
		force_download = null,
		filter_by_provider_name = null,
		filter_by_system_name_or_id_in_provider = null,
		filter_by_cups = null,
		filter_by_commissioning_date = null,
		filter_by_energy_provider_auth_id = null,
		only_systems_with_no_provider = null
	) {
		try {
			var result = await this.store_util.get_items();

			if (!result || force_download) {
				const interface_data =
					await this.backend_interface.get_user_energy_systems();

				result = [];
				interface_data.forEach((item) => {
					const validated_item = this.validator.validate(item);
					result.push(validated_item);
				});

				await this.store_util.save_items(
					result,
					moment().endOf("day").toDate()
				);
			}

			if (filter_by_provider_name)
				result = this._filter_energy_systems_by_provider_name(
					result,
					filter_by_provider_name
				);

			if (filter_by_system_name_or_id_in_provider)
				result = this._filter_energy_systems_by_system_name_or_id_in_provider(
					result,
					filter_by_system_name_or_id_in_provider
				);

			if (filter_by_cups)
				result = this._filter_energy_systems_by_cups(result, filter_by_cups);

			if (filter_by_commissioning_date) {
				result = this._filter_energy_systems_by_commissioning_date(
					result,
					filter_by_commissioning_date
				);
			}

			if (filter_by_energy_provider_auth_id) {
				result = this._filter_energy_systems_by_energy_provider_auth(
					result,
					filter_by_energy_provider_auth_id
				);
			}

			if (only_systems_with_no_provider) {
				result = result.filter((item) => !item.has_provider);
			}

			return result;
		} catch (e) {
			throw new AppError(
				"error_get_user_energy_systems",
				"general.errors.general_error",
				e
			);
		}
	}

	_filter_energy_systems_by_provider_name(energy_systems, provider_name) {
		return energy_systems.filter(
			(item) =>
				item.provider_data && item.provider_data.provider_name == provider_name
		);
	}

	_filter_energy_systems_by_system_name_or_id_in_provider(
		energy_systems,
		system_name
	) {
		const filter_by_system_name_to_lower_case = system_name.toLowerCase();

		return energy_systems.filter((item) => {
			const lowercase_name = item.name.toLowerCase();
			const system_id_in_provider = item.provider_data
				? item.provider_data.system_id_in_provider
				: null;

			return (
				lowercase_name.search(filter_by_system_name_to_lower_case) != -1 ||
				(system_id_in_provider != undefined &&
					system_id_in_provider.search(filter_by_system_name_to_lower_case) !=
						-1)
			);
		});
	}

	_filter_energy_systems_by_cups(energy_systems, cups) {
		const cups_to_filter = cups.toLowerCase();

		return energy_systems.filter((item) => {
			if (!item.cups) return false;

			const lowercase_cups = item.cups.toLowerCase();
			return lowercase_cups.search(cups_to_filter) != -1;
		});
	}

	_filter_energy_systems_by_commissioning_date(
		energy_systems,
		commissioning_date_filter
	) {
		const filter_date = commissioning_date_filter.date;
		const filter_operator = commissioning_date_filter.operator;

		return energy_systems.filter(
			(item) =>
				item.commissioning_date &&
				OperatorsUtil(item.commissioning_date, filter_date, filter_operator)
		);
	}

	_filter_energy_systems_by_energy_provider_auth(
		energy_systems,
		filter_by_energy_provider_auth_id
	) {
		return energy_systems.filter(
			(item) =>
				item.provider_data &&
				item.provider_data.energy_provider_auth_id ==
					filter_by_energy_provider_auth_id
		);
	}

	async get_energy_system(energy_system_id) {
		const energy_systems = await this.get_user_energy_systems();
		return energy_systems.find((item) => item.id == energy_system_id);
	}
}
