import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_user_pr_limit() {
		const url = "/api/providers/user";
		const backend_data = await this.get(url);

		const local_data = {
			pr_limit: {
				pr_lowest_limit:
					backend_data && backend_data.PRLimit
						? backend_data.PRLimit.PRLowestLimit
						: null
			}
		};
		return local_data;
	}
}
