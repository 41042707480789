import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async user_api_key_remove(user_id) {
		const url = "/api/user/" + user_id + "/delete-api-key";

		await this.delete(url);

		return true;
	}
}
