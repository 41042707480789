<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="flex-center-content full-width full-height">
			<Loader size="30" />
		</div>
		<div v-else class="flex-auto-full-height-content p-sm">
			<div class="content">
				<div v-if="error" class="m-b">
					<Alert :message="error" />
				</div>
				<div v-else-if="success" class="m-b">
					<Alert message="general.update_successful" type="success" />
				</div>

				<div class="grid-with-same-cols-width-and-padding">
					<div>
						<div v-if="energy_system.has_provider">
							<div class="font-bold m-b">
								{{ $tc("user_settings.provider", 1) }}
							</div>

							<div class="display-flex">
								<div>
									<EnergyProviderImg
										:provider_name="energy_system.provider_data.provider_name"
										:height_in_px="25"
									/>
								</div>

								<div class="p-l-xl flex-center-content">
									{{ energy_system.provider_data.system_id_in_provider }}
								</div>
							</div>
						</div>

						<div
							v-if="
								energy_system_provider_auth && energy_system_provider_auth.name
							"
							class="m-t"
						>
							<div class="font-bold">
								{{ $t("entities.energy_provider_auth.name") }}
							</div>

							<div class="m-t-xs">
								{{ energy_system_provider_auth.name }}
							</div>
						</div>

						<div class="m-t">
							<div>
								<div class="font-bold">
									{{ $t("user_settings.energy_systems_manager.system_name") }}
								</div>
								<div class="m-t-xs">
									<TextInput v-model="energy_system_update_data.name" />
								</div>
							</div>

							<div class="m-t">
								<div class="font-bold">
									{{ $t("entities.energy_system.commissioning_date") }}
								</div>
								<div class="m-t-xs">
									<Datepicker
										v-model="energy_system_update_data.commissioning_date"
									/>
								</div>
							</div>

							<div class="m-t">
								<div class="font-bold">
									{{ $t("entities.energy_system.cups") }}
								</div>
								<div class="m-t-xs">
									<TextInput v-model="energy_system_update_data.cups" />
								</div>
							</div>
						</div>
					</div>

					<div v-if="energy_system_update_data.coordinates" class="m-t">
						<div class="grid-with-same-cols-width-and-padding">
							<div>
								<div>
									{{ $t("entities.energy_system.coordinates.lat") }}
								</div>
								<div>
									<NumberInput
										v-model="energy_system_update_data.coordinates.lat"
									/>
								</div>
							</div>
							<div>
								<div>
									{{ $t("entities.energy_system.coordinates.lon") }}
								</div>
								<div>
									<NumberInput
										v-model="energy_system_update_data.coordinates.lon"
									/>
								</div>
							</div>
						</div>

						<div class="m-t">
							<Map
								style="height: 400px"
								:draggable_marker="{
									lat: energy_system_update_data.coordinates.lat,
									lon: energy_system_update_data.coordinates.lon
								}"
								v-on:draggable_marker_dragged="change_coordinates"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="footer m-t text-right p-t">
				<button class="btn btn-primary" v-on:click="update_energy_system">
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import TextInput from "@form_components/TextInput";
import NumberInput from "@form_components/NumberInput.vue";
import Datepicker from "@form_components/Datepicker";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";
import Map from "@sub_components/Map.vue";

import EnergySystemCommonDataUpdatorClass from "@src/energy-system/application/energy-system-common-data-updater";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const EnergySystemCommonDataUpdator = new EnergySystemCommonDataUpdatorClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "EnergySystemEditCommonData",
	components: {
		Alert,
		Loader,
		TextInput,
		NumberInput,
		Datepicker,
		EnergyProviderImg,
		Map
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			energy_system_update_data: {},
			energy_system_provider_auth: null
		};
	},
	async mounted() {
		try {
			this.loading = true;

			this.set_data_to_update(this.energy_system);

			if (
				UserPermissionsUtil.can_user("edit_energy_provider_auth") &&
				this.energy_system.provider_data &&
				this.energy_system.provider_data.energy_provider_auth_id
			) {
				this.energy_system_provider_auth =
					await EnergyProviderAuthFinder.get_energy_provider_auth(
						this.energy_system.provider_data.energy_provider_auth_id
					);
			}
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		set_data_to_update(energy_system) {
			const cloned_energy_system = Object.assign({}, energy_system);
			if (!cloned_energy_system.coordinates)
				cloned_energy_system.coordinates = {
					lat: null,
					lon: null
				};

			this.energy_system_update_data = {
				name: cloned_energy_system.name,
				commissioning_date: cloned_energy_system.commissioning_date,
				cups: cloned_energy_system.cups,
				coordinates: cloned_energy_system.coordinates
			};
		},
		change_coordinates(new_coordinates) {
			this.energy_system_update_data.coordinates = new_coordinates;
		},
		async update_energy_system() {
			this.loading = true;
			this.error = null;

			try {
				const data_to_update = Object.assign(
					{},
					this.energy_system_update_data
				);
				const updated_energy_system =
					await EnergySystemCommonDataUpdator.update_energy_system_general_data(
						this.energy_system.id,
						data_to_update
					);
				this.set_data_to_update(updated_energy_system);

				this.$emit("energy_system_updated", updated_energy_system);

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 5000);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
.max-width-fields {
	width: 800px;
}
</style>
