<template>
	<div class="success-circled-checkbox full-rounded flex-center-content">
		<Icon icon="check" size="20" />
	</div>
</template>

<script>
export default {
	name: "SuccessCircledCheckIcon"
};
</script>

<style scoped>
.success-circled-checkbox {
	color: var(--green1);
	border: 3px solid var(--green1);
	width: 35px;
	height: 35px;
	font-size: 25px;
}
</style>
