<template lang="html">
	<div class="relative">
		<div v-if="show_hex_value" id="user-color-input" class="left">
			<input
				type="text"
				class="form-control text-center"
				disabled
				v-model="color_picker_value"
			/>
		</div>
		<div
			id="user-color-preview"
			class="full-rounded left m-l cursor-pointer"
			v-bind:style="{ backgroundColor: color_picker_value || '#e9ecef' }"
			v-on:click="toogle_color_picker"
		></div>

		<div v-if="show_color_picker" id="color-picker">
			<VueColorPicker
				:value="color_picker_value"
				v-model="color_picker_returned_value"
			/>
		</div>

		<div class="clear"></div>
	</div>
</template>

<script>
import { Chrome as VueColorPicker } from "vue-color";

export default {
	name: "ColorPicker",
	components: {
		VueColorPicker
	},
	props: {
		value: String,
		show_hex_value: Boolean
	},
	data() {
		const color_picker_value = this.value || "#FFFFFF";

		return {
			show_color_picker: false,
			color_picker_value: color_picker_value,
			color_picker_returned_value: color_picker_value
		};
	},
	methods: {
		toogle_color_picker() {
			this.show_color_picker = !this.show_color_picker;
		}
	},
	watch: {
		color_picker_returned_value(new_value) {
			const hex_color = new_value.hex;
			this.color_picker_value = hex_color;

			this.$emit("input", hex_color);
		}
	}
};
</script>

<style lang="css" scoped>
#user-color-input {
	width: 200px;
}
#user-color-preview {
	background-color: red;
	width: 34px;
	height: 34px;
	border: 1px solid grey;
}
#color-picker {
	position: absolute;
	top: 45px;
	z-index: 2;
}
</style>
