import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async user_api_key_update(user_id) {
		const url = "/api/user/" + user_id + "/generate-api-key";

		const backend_data = await this.put(url);

		return {
			api_key: backend_data.apiKey
		};
	}
}
