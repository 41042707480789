<template>
	<div class="full-width full-height relative">
		<AssetUsers v-if="can_user('manage_users')" :asset="asset" role="manager" />

		<AssetUsers
			v-if="can_user('manage_users')"
			:asset="asset"
			role="maintainer"
			class="m-t"
		/>

		<AssetUsers
			v-if="can_user('manage_users')"
			:asset="asset"
			role="guest"
			class="m-t"
		/>
	</div>
</template>

<script>
import AssetUsers from "./AssetUsers";

import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "AssetPermisions",
	components: {
		AssetUsers
	},
	props: {
		asset: Object
	},
	methods: {
		can_user(permission) {
			return UserPermissionsUtil.can_user(permission);
		}
	}
};
</script>
