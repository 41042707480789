<template lang="html">
	<div v-if="is_custom_icon_v2" class="flex-center-content">
		<svg
			aria-hidden="true"
			focusable="false"
			:data-icon="icon"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			:viewBox="
				'0 0 ' + is_custom_icon_v2.width + ' ' + is_custom_icon_v2.height
			"
			:style="custom_icon_style"
			style="overflow: visible; box-sizing: content-box; height: 1em"
		>
			<path
				v-if="is_custom_icon_v2.path"
				:fill-rule="is_custom_icon_v2['fill-rule'] || null"
				:clip-rule="is_custom_icon_v2['clip-rule'] || null"
				fill="currentColor"
				:d="is_custom_icon_v2.path"
			></path>
			<path
				v-else-if="is_custom_icon_v2.paths"
				v-for="custom_icon in is_custom_icon_v2.paths"
				:fill-rule="custom_icon['fill-rule'] || null"
				:clip-rule="custom_icon['clip-rule'] || null"
				fill="currentColor"
				:d="custom_icon.d"
			></path>
		</svg>
	</div>
</template>

<script>
import CustomIconsV2 from "./custom-icons-svg.json";

export default {
	name: "Icon",
	props: {
		icon: String,
		size: [String, Number]
	},
	data() {
		const custom_icon_size_in_px = this.size || 22;

		return {
			custom_icon_style: {
				"font-size": custom_icon_size_in_px + "px"
			}
		};
	},
	computed: {
		is_custom_icon_v2() {
			return CustomIconsV2.hasOwnProperty(this.icon)
				? CustomIconsV2[this.icon]
				: null;
		}
	}
};
</script>
