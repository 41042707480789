import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendPowerMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-power-measurement-converter";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_day_optimal_power(asset_id, date) {
		const url =
			"/api/asset/" +
			asset_id +
			"/power-data/optimal-resolution/" +
			moment(date).format("YYYY-MM-DD");

		const backend_data = await this.get(url, {
			query_params: {
				useLocalTimeZone: true
			}
		});

		const backend_asset_historical_power_data = backend_data.results;

		if (
			!Array.isArray(backend_asset_historical_power_data) ||
			backend_asset_historical_power_data.length == 0
		)
			return null;

		var return_result = [];
		backend_asset_historical_power_data.forEach((data) => {
			return_result.push(BackendPowerMeasurementConverter(data));
		});

		return return_result;
	}
}
