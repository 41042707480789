import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async reset_user_password(user_id_to_reset_password) {
		const url =
			"/api/supervisor/user/" +
			user_id_to_reset_password +
			"/reset-send-password";

		await this.get(url);
	}
}
