import BackendInterface from "@src/shared/infrastructure/backend-interface";
import LocalObjectConverter from "../../shared/infrastructure/backend-converters/local-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_alert(alert_id, alert_data_to_update) {
		const url = "/api/alert/" + alert_id;
		await this.put(url, {
			body_params: LocalObjectConverter(alert_data_to_update)
		});

		return true;
	}
}
