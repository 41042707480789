<template>
	<div>
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else class="p-sm full-width full-height">
			<div class="full-width full-height relative">
				<div v-if="display_sub_page" id="sub-page" class="rounded-s">
					<router-view v-on:user_created="user_created" />
				</div>

				<div class="flex-auto-full-height-content">
					<div class="header p-b">
						<div class="display-flex-space-between">
							<div class="display-flex">
								<div class="flex-center-content" v-if="users">
									<span>Users | {{ users.length }}</span>
								</div>

								<div class="m-l">
									<select class="form-control" v-model="filter_by_role">
										<option value="admin">Admins</option>
										<option value="supervisor">Clients</option>
										<option value="manager">
											{{ $t("user_settings.users_manager.managers") }}
										</option>
										<option value="guest">
											{{ $t("user_settings.users_manager.guests") }}
										</option>
										<option value="maintainer">
											{{ $t("user_settings.users_manager.maintainers") }}
										</option>
									</select>
								</div>
								<div class="m-l">
									<Textinput
										v-model="table_filter"
										:placeholder="$t('general.search')"
									/>
								</div>
							</div>
							<div class="display-flex">
								<div
									class="btn btn-primary"
									v-on:click="get_users_by_role(true)"
								>
									<Icon icon="sync-alt" />
								</div>

								<router-link
									v-if="filter_by_role == 'supervisor'"
									:to="{ name: 'admin-new-user' }"
								>
									<div class="btn btn-primary m-l">
										{{ $t("general.add_new") }}
									</div>
								</router-link>
							</div>
						</div>
					</div>

					<div class="content p-xxxs">
						<div v-if="error">
							<Alert :message="error" />
						</div>
						<div v-else class="full-width full-height relative">
							<div class="absolute full-width full-height">
								<DataTable
									:items="users"
									:items_per_page="50"
									:fields="table_columns"
									:filter="table_filter"
									:filter_fields="['id', 'first_name', 'email']"
								>
									<template #cell(avatar)="avatar">
										<UserAvatar
											class="margin-center"
											v-if="avatar.value"
											:user="avatar.item"
											size="50"
										/>
									</template>

									<template #cell(options)="options" class="relative">
										<div class="p-l p-r">
											<div
												v-if="
													loading_switch && loading_switch == options.item.id
												"
												class="margin-center p-t-xs p-b-xs"
											>
												<Loader size="15" />
											</div>
											<div v-else class="cursor-pointer">
												<div class="display-flex">
													<button
														class="btn btn-primary"
														v-on:click="switch_to_user(options.item)"
													>
														<Icon size="18" icon="user-secret" />
														<span class="m-l-sm">Switch</span>
													</button>

													<div
														v-if="
															options.item.roles.indexOf('supervisor') != -1
														"
														class="m-l"
													>
														<router-link
															:to="{
																name: 'admin-user-energy-systems',
																params: {
																	user_id: options.item.id
																}
															}"
														>
															<div class="btn btn-primary">
																{{ $t("general.edit") }}
															</div>
														</router-link>
													</div>

													<div
														v-if="
															options.item.roles.indexOf('supervisor') == -1 &&
															options.item.roles.indexOf('admin') == -1
														"
														class="m-l"
													>
														<router-link
															:to="{
																name: 'admin-user-api-key',
																params: {
																	user_id: options.item.id
																}
															}"
														>
															<button class="btn btn-primary">Token</button>
														</router-link>
													</div>
												</div>
											</div>
										</div>
									</template>
								</DataTable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import DataTable from "@sub_components/DataTable.vue";
import UserAvatar from "@sub_components/UserAvatar";
import AdminNewUser from "./AdminNewUser";
import Textinput from "@form_components/TextInput";

import UsersByRoleFinderClass from "@src/admin/application/users-by-role-finder.js";
const UsersByRoleFinder = new UsersByRoleFinderClass();

import UserService from "@services/user-services/user-service";

export default {
	name: "Users",
	components: {
		Loader,
		Alert,
		DataTable,
		AdminNewUser,
		UserAvatar,
		Textinput
	},
	data() {
		return {
			options_id: null,
			loading: true,
			loading_switch: false,
			error: null,
			filter_by_role: "supervisor",
			users: [],

			sort_by: null,
			sort_desc: true,
			items_per_page: 50,
			current_page: 1,
			table_filter: null
		};
	},
	methods: {
		hide_options() {
			this.options_id = null;
		},
		async switch_to_user(user) {
			const user_service = new UserService();
			this.loading_switch = user.id;
			try {
				await user_service.switch_to_user(user);
				window.location = "/";
			} catch (e) {
				this.error = "general.errors.general_error";
				this.loading_switch = false;
			}
		},
		async get_users_by_role(force_download = null) {
			this.loading = true;
			this.error = null;
			this.users = null;

			try {
				this.users = await UsersByRoleFinder.get_users_by_role(
					this.filter_by_role,
					force_download
				);
			} catch (e) {
				this.error = "general.errors.general_error";
			} finally {
				this.loading = false;
			}
		},
		user_created() {
			this.get_users_by_role(true);
		}
	},
	created() {
		this.get_users_by_role();
	},
	computed: {
		table_columns() {
			return [
				{
					key: "avatar",
					label: null
				},
				{
					key: "id",
					label: "ID"
				},
				{
					key: "first_name",
					label: this.$t("user_settings.user_entity.first_name")
				},
				{
					key: "email",
					label: this.$t("user_settings.user_entity.email")
				},
				{
					key: "creation",
					sortable: true,
					label: this.$t("user_settings.user_entity.creation"),
					formatter: (value, item) => {
						return this.$format_date_to_day(value);
					}
				},
				{
					key: "options"
				}
			];
		},
		display_sub_page() {
			const current_route_name = this.$route.name;
			return current_route_name != "admin-users";
		}
	},
	watch: {
		filter_by_role() {
			this.get_users_by_role();
		}
	}
};
</script>

<style lang="css" scoped>
.users-options {
	width: 150px;
	position: absolute;
	top: 35px;
	right: 45px;
	border-radius: 10px;
}
.users-options .white-bg:hover {
	background-color: var(--grey1);
	color: white;
}

#sub-page {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 4;
	background-color: rgba(0, 0, 0, 0.2);

	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
