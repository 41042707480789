<template>
	<div>
		<div v-if="loading">
			<Loader size="32" />
		</div>
		<div v-else-if="error">
			<Alert :message="erro" />
		</div>
		<DataTable v-else-if="assets" :items="assets" :fields="table_fields">
			<template #cell(shortcuts)="data">
				<AssetShortcuts :asset_id="data.item.id" :hide_tooltips="true" />
			</template>
		</DataTable>
		<div v-else class="p-sm">
			<Alert message="general.errors.no_data_available" type="info" />
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import DataTable from "@sub_components/DataTable.vue";
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts.vue";

import AssetsFinderClass from "@src/asset/application/assets-finder.js";
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "AffectedAssetsTable",
	components: {
		Loader,
		Alert,
		DataTable,
		AssetShortcuts
	},
	props: {
		assets_ids: Array
	},
	data() {
		return {
			loading: true,
			error: null,
			assets: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			let assets = [];
			for (let index in this.assets_ids) {
				const asset_id = this.assets_ids[index];
				const asset = await AssetsFinder.get_asset_by_id(asset_id);
				assets.push(asset);
			}

			this.assets = assets;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		table_fields() {
			return [
				{
					key: "name",
					label: this.$t("entities.asset.name")
				},
				{
					key: "custom_id",
					label: this.$t("entities.asset.custom_id")
				},
				{
					key: "shortcuts"
				}
			];
		}
	}
};
</script>
