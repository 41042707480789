import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_custom_hourly(system_id, data_to_update) {
		let backend_data = {
			data: []
		};
		data_to_update.data.forEach((item) => {
			backend_data.data.push({
				month: item.month,
				energyProduction: item.energy_production,
				energyConsumption: item.energy_consumption,
				energySelfConsumption: item.energy_self_consumption
			});
		});

		const url = "/api/energy-data/systems/" + system_id + "/custom-monthly";
		await this.put(url, { body_params: backend_data });

		return true;
	}
}
