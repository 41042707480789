<template lang="html">
	<img
		v-if="image_src"
		:style="{ height: (height_in_px || 20) + 'px' }"
		:src="image_src"
	/>
</template>

<script>
export default {
	name: "EnergyProviderImg",
	props: {
		provider_name: String,
		height_in_px: Number
	},
	computed: {
		image_src() {
			return this.provider_name
				? this.$get_energy_provider_img_src(this.provider_name)
				: null;
		}
	}
};
</script>

<style lang="css" scoped></style>
