import BackendInterface from "@src/shared/infrastructure/backend-interface";
import UserTokenFinderInterfaceClass from "./user-token-finder-interface";

export default class extends BackendInterface {
	constructor(user_id) {
		super();

		this.user_token_finder_interface = new UserTokenFinderInterfaceClass();
		this.user_id = user_id;
		this.user_token = null;
	}

	async get(url, args = null) {
		const formatted_args = await this._format_args(args);
		return await this._rest_method("get", url, formatted_args);
	}

	async post(url, args = null) {
		const formatted_args = await this._format_args(args);
		return await this._rest_method("post", url, formatted_args);
	}

	async put(url, args = null) {
		const formatted_args = await this._format_args(args);
		return await this._rest_method("put", url, formatted_args);
	}

	async delete(url, args = null) {
		const formatted_args = await this._format_args(args);
		return await this._rest_method("delete", url, formatted_args);
	}

	async _format_args(args) {
		const user_token = await this._get_user_token();

		if (args) args.token = user_token;
		else args = { token: user_token };

		return args;
	}

	async _get_user_token() {
		if (this.user_token) return this.user_token;

		this.user_token = await this.user_token_finder_interface.get_user_token(
			this.user_id
		);

		return this.user_token;
	}
}
