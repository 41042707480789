<template>
	<div class="p-b-xl">
		<div class="flex-horizontal-right">
			<router-link
				:to="{
					name: 'user-energy-system-update',
					params: {
						system_id: energy_system.id
					}
				}"
			>
				<button class="btn btn-primary">
					{{ $t("general.edit") }}
				</button>
			</router-link>
		</div>

		<div v-if="energy_provider_auth" class="display-flex m-b">
			<div class="font-bold">
				{{ $t("entities.energy_provider_auth.name") }}
			</div>
			<div class="m-l-lg">
				{{ energy_provider_auth.name }}
			</div>
		</div>

		<div id="asset-energy-system-pv-data-table">
			<div v-for="prop in pv_data_properties" class="display-flex">
				<div class="font-bold">
					<span>
						{{ prop.multilingual_prop_name }}
					</span>
					<span v-if="prop.unit" class="font-xs m-l-xs">({{ prop.unit }})</span>
				</div>
				<div class="m-l-lg">
					{{ prop.value }}
				</div>
			</div>
		</div>

		<div
			v-if="
				energy_system.pv_data.pv_panel_models &&
				energy_system.pv_data.pv_panel_models.length > 0
			"
			class="m-t-xl"
		>
			<div class="font-bold border-b">
				{{ $t("entities.energy_system.pv_data.pv_panel_models_label") }}
			</div>

			<Pvpanels
				:pv_panels="energy_system.pv_data.pv_panel_models"
				class="m-t"
			/>
		</div>
	</div>
</template>

<script>
import Pvpanels from "./PvPanels";

import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";

const UserPermissionsUtil = new UserPermissionsUtilClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();

export default {
	name: "PvEnergySystemInfo",
	components: {
		Pvpanels
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			energy_provider_auth: null
		};
	},
	async mounted() {
		if (
			UserPermissionsUtil.can_user("edit_energy_provider_auth") &&
			this.energy_system.provider_data
		) {
			this.energy_provider_auth =
				await EnergyProviderAuthFinder.get_energy_provider_auth(
					this.energy_system.provider_data.energy_provider_auth_id
				);
		}
	},
	computed: {
		pv_data_properties() {
			return [
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.peak_power"
					),
					unit: "kW",
					value: this.energy_system.pv_data.peak_power
						? this.$format_number(this.energy_system.pv_data.peak_power, 1)
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.nominal_power"
					),
					unit: "kW",
					value: this.energy_system.pv_data.nominal_power
						? this.$format_number(this.energy_system.pv_data.nominal_power, 1)
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.tilt"
					),
					unit: "º",
					value: this.energy_system.pv_data.tilt
						? this.$format_number(this.energy_system.pv_data.tilt)
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.tracking"
					),
					value: this.energy_system.pv_data.tracking
						? this.$t(
								"entities.energy_system.pv_data.tracking_options." +
									this.energy_system.pv_data.tracking
						  )
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.azimuth"
					),
					unit: "º",
					value: this.energy_system.pv_data.azimuth
						? this.$format_number(this.energy_system.pv_data.azimuth)
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.losses"
					),
					unit: "%",
					value: this.energy_system.pv_data.losses
						? this.$format_number(this.energy_system.pv_data.losses)
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.solar_type"
					),
					value: this.energy_system.pv_data.solar_type
						? this.$t(
								"entities.energy_system.pv_data.solar_type_options." +
									this.energy_system.pv_data.solar_type
						  )
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.facility_type"
					),
					value: this.energy_system.pv_data.facility_type
						? this.$t(
								"entities.energy_system.pv_data.facility_type_options." +
									this.energy_system.pv_data.facility_type
						  )
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.connection_type"
					),
					value: this.energy_system.pv_data.connection_type
						? this.$t(
								"entities.energy_system.pv_data.connection_type_options." +
									this.energy_system.pv_data.connection_type
						  )
						: " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.voltage"
					),
					unit: "V",
					value: this.energy_system.pv_data.voltage || " - "
				},
				{
					multilingual_prop_name: this.$t(
						"entities.energy_system.pv_data.land_registry_reference"
					),
					value: this.energy_system.pv_data.land_registry_reference || " - "
				}
			];
		}
	}
};
</script>

<style scoped>
#asset-energy-system-pv-data-table {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 15px;
}
</style>
