<template lang="html">
	<div>
		<div v-if="loading" class="p-sm">
			<Loader size="30" />
		</div>
		<div
			v-else
			style="display: grid; grid-template-columns: 3fr 2fr; margin-top: 15px"
		>
			<div>
				<div class="pv-report-title">
					{{ $t("asset_reports.shared_components.asset_data") }}
				</div>
				<div id="pv-report-page2-top-info">
					<div v-if="asset.address">
						<span class="font-700">
							{{ $t("asset_reports.shared_components.asset") }}:
						</span>
						<span class="asset-reports-grey-color">{{ asset.address }}</span>
					</div>
					<div v-if="formatted_asset_data.commissioning_date">
						<span class="font-700">
							{{ $t("entities.energy_system.commissioning_date") }}:
						</span>
						<span class="asset-reports-grey-color">
							{{ formatted_asset_data.commissioning_date }}
						</span>
					</div>

					<div v-if="formatted_asset_data.pv_panels_count != undefined">
						<span class="font-700">
							{{ $t("asset_reports.shared_components.number_of_pv_panels") }}:
						</span>
						<span class="asset-reports-grey-color">{{
							formatted_asset_data.pv_panels_count
						}}</span>
					</div>

					<div v-if="formatted_asset_data.pv_panels_manufacturers_names">
						<span class="font-700">
							{{ $t("asset_reports.shared_components.panel_brand") }}:
						</span>
						<span class="asset-reports-grey-color">
							{{ formatted_asset_data.pv_panels_manufacturers_names }}
						</span>
					</div>
					<div v-if="formatted_asset_data.pv_panels_models_names">
						<span class="font-700">
							{{ $t("asset_reports.shared_components.model_of_panels") }}:
						</span>
						<span class="asset-reports-grey-color">{{
							formatted_asset_data.pv_panels_models_names
						}}</span>
					</div>

					<div v-if="formatted_asset_data.peak_power">
						<span class="font-700">
							{{ $t("location.pv_data.peak_power") }}:
						</span>
						<span class="asset-reports-grey-color">
							{{ $format_number(formatted_asset_data.peak_power, 2) + " kWp" }}
						</span>
					</div>

					<div
						v-if="
							formatted_asset_data.peak_power &&
							asset_reports &&
							from_year_total_estimated_production
						"
					>
						<span class="font-700">
							{{
								$t("asset_reports.shared_components.year_estimated_production")
							}}:
						</span>
						<span class="asset-reports-grey-color">
							{{
								$format_number(
									from_year_total_estimated_production /
										formatted_asset_data.peak_power,
									0
								) + " kWh/kWp"
							}}
						</span>
					</div>
				</div>
			</div>

			<div>
				<div>
					<Base64Img
						v-if="formatted_asset_data.asset_image_url"
						alt="Plant image"
						image_width="100%"
						style="max-height: 200px"
						:src="formatted_asset_data.asset_image_url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Base64Img from "@sub_components/Base64Img";

import moment from "moment";
import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder";
import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder";
import LocationProviderServiceClass from "@services/location-services/location-energy-measurement-providers-service";
import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder";

const AssetOtherDataFinder = new AssetOtherDataFinderClass();
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();
const LocationProviderService = new LocationProviderServiceClass();
const AssetPvReportFinder = new AssetPvReportFinderClass();

export default {
	name: "ReportAssetPvData",
	components: {
		Loader,
		Alert,
		Base64Img
	},
	props: {
		asset: Object,
		asset_reports: Object,
		filter: Object
	},
	data() {
		return {
			loading: null,
			error: null,
			formatted_asset_data: {}
		};
	},
	async mounted() {
		this.loading = true;

		try {
			await this.set_formatted_commissioning_date();
			await this.set_formatted_pv_panels_data();
			await this.set_peak_power_formatter();
			await this.set_asset_image();
			await this.set_from_year_total_estimated_production();
			this.set_selected_period_summary();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async set_formatted_commissioning_date() {
			const asset = this.asset;

			const asset_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(asset.id);

			let earliest_commissioning_date = null;

			for (let index in asset_systems) {
				const energy_system = asset_systems[index];

				if (!energy_system.commissioning_date) continue;

				earliest_commissioning_date = !earliest_commissioning_date
					? energy_system.commissioning_date
					: energy_system.commissioning_date < earliest_commissioning_date
					? energy_system.commissioning_date
					: earliest_commissioning_date;
			}

			this.formatted_asset_data.commissioning_date = earliest_commissioning_date
				? this.$format_date_to_day(earliest_commissioning_date)
				: null;
		},
		async set_formatted_pv_panels_data() {
			const asset = this.asset;
			const asset_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(asset.id);

			let pv_panels_manufacturers_names = [];
			let pv_panels_models_names = [];
			for (let index in asset_systems) {
				const energy_system = asset_systems[index];

				if (!energy_system.pv_data || !energy_system.pv_data.pv_panel_models)
					continue;

				for (let pv_panel_index in energy_system.pv_data.pv_panel_models) {
					const pv_panel_id =
						energy_system.pv_data.pv_panel_models[pv_panel_index].id;

					const pv_panel_data = await LocationProviderService.get_pv_model_data(
						pv_panel_id
					);

					if (
						pv_panels_manufacturers_names.indexOf(pv_panel_data.manufacturer) ==
						-1
					)
						pv_panels_manufacturers_names.push(pv_panel_data.manufacturer);
					if (pv_panels_models_names.indexOf(pv_panel_data.pv_panel_name) == -1)
						pv_panels_models_names.push(pv_panel_data.pv_panel_name);
				}
			}

			this.formatted_asset_data.pv_panels_manufacturers_names =
				pv_panels_manufacturers_names.length > 0
					? pv_panels_manufacturers_names.join(", ")
					: null;
			this.formatted_asset_data.pv_panels_models_names =
				pv_panels_models_names.length > 0
					? pv_panels_models_names.join(", ")
					: null;
		},
		async set_peak_power_formatter() {
			const asset = this.asset;

			const asset_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(asset.id);

			let total_peak_power = 0;
			asset_systems.forEach((energy_system) => {
				if (energy_system.pv_data)
					total_peak_power += energy_system.pv_data.peak_power;
			});

			this.formatted_asset_data.peak_power = total_peak_power || null;
		},
		async set_asset_image() {
			const asset = this.asset;

			const asset_other_data = await AssetOtherDataFinder.get_asset_other_data(
				asset.id
			);

			this.formatted_asset_data.asset_image_url =
				asset_other_data.asset_image_url || null;
		},
		async set_from_year_total_estimated_production() {
			const from_month = moment(this.filter.from).format("M");
			const to_month = moment(this.filter.to).format("M");

			const request_from_date_is_current_year =
				moment(this.filter.from).format("YYYY") == moment().format("YYYY");
			const is_not_anual_report = from_month != 1 && to_month != 12;
			var anual_pv_report;

			if (request_from_date_is_current_year) {
				//Get previous full year data data
				const previous_year = moment(this.filter.from).subtract(1, "years");
				const year_start = moment(previous_year).startOf("year").toDate();
				const year_end = moment(previous_year).endOf("year").toDate();

				anual_pv_report = await AssetPvReportFinder.get_asset_pv_report(
					this.asset.id,
					year_start,
					year_end
				);
			} else if (is_not_anual_report) {
				const from_year = moment(this.filter.from).format("YYYY");
				const year_start = moment(from_year).startOf("year").toDate();
				const year_end = moment(from_year).endOf("year").toDate();

				anual_pv_report = await AssetPvReportFinder.get_asset_pv_report(
					this.asset.id,
					year_start,
					year_end
				);
			} else {
				anual_pv_report = this.asset_reports;
			}

			const anual_energy_production =
				anual_pv_report.energy_production_summary
					.selected_period_energy_production;
			let year_total_estimated_production = 0;
			anual_energy_production.forEach((item) => {
				if (item.estimated_energy_production)
					year_total_estimated_production += item.estimated_energy_production;
			});

			//For current year we assume degradation as previous year multiply by 0.995
			if (request_from_date_is_current_year) {
				year_total_estimated_production =
					year_total_estimated_production * 0.995;
			}

			this.from_year_total_estimated_production =
				year_total_estimated_production;
		},
		set_selected_period_summary() {
			if (
				this.asset_reports &&
				this.asset_reports.energy_production_summary
					.selected_period_energy_production_summary
			) {
				const selected_period_summary =
					this.asset_reports.energy_production_summary
						.selected_period_energy_production_summary;

				if (selected_period_summary.estimated_energy_production)
					this.formatted_asset_data.selected_period_estimated_energy_production =
						selected_period_summary.estimated_energy_production;

				if (selected_period_summary.energy_production)
					this.formatted_asset_data.selected_period_energy_production =
						selected_period_summary.energy_production;

				if (selected_period_summary.energy_production_variation)
					this.formatted_asset_data.selected_period_energy_production_variation =
						selected_period_summary.energy_production_variation;
			}
		}
	}
};
</script>

<style lang="css" scoped></style>
