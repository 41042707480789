import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendUserConverter from "./converters/backend-user-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_owned_manager_users_by_role(manager_id, users_role) {
		let url = "/api/manager/" + manager_id + "/";

		switch (users_role) {
			case "maintainer":
				url += "maintainers";
				break;
			case "guest":
				url += "guests";
				break;
			case "manager":
				url += "managers";
				break;
		}

		const backend_users = await this.get_items_with_offset(url);
		var local_users = [];
		backend_users.forEach((backend_user) => {
			local_users.push(BackendUserConverter(backend_user));
		});
		return local_users;
	}
}
