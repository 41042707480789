<template>
	<div>
		<div class="font-bold">
			<span>{{ $t("user_settings.add_contact") }}</span>
			<span
				v-on:click="add_new_contact()"
				class="m-l primary-color cursor-pointer"
			>
				<Icon icon="plus" />
			</span>
		</div>

		<div
			class="contact-form p-t p-b relative"
			v-for="(contact, index) in user.maintainer_role_extra_data.other_contacts"
			:class="{ 'border-t': index > 0 }"
		>
			<div class="flex-vertical-center">
				{{ $t("entities.user.maintainer_role_extra_data.other_contacts.name") }}
			</div>
			<div>
				<div
					class="remove-contact-button danger-color cursor-pointer"
					v-on:click="remove_contact(index)"
				>
					<Icon icon="close-circle" size="20" />
				</div>
				<TextInput v-model="contact.name" />
			</div>

			<div class="flex-vertical-center">
				{{
					$t("entities.user.maintainer_role_extra_data.other_contacts.phone")
				}}
			</div>
			<div>
				<TextInput v-model="contact.phone" />
			</div>

			<div class="flex-vertical-center">
				{{
					$t("entities.user.maintainer_role_extra_data.other_contacts.email")
				}}
			</div>
			<div>
				<TextInput v-model="contact.email" />
			</div>

			<div class="flex-vertical-center">
				{{
					$t("entities.user.maintainer_role_extra_data.other_contacts.position")
				}}
			</div>
			<div>
				<TextInput v-model="contact.position" />
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput";
import NumberInput from "@form_components/NumberInput";

export default {
	name: "EditMaintainerContacts",
	props: {
		value: Object
	},
	components: {
		TextInput,
		NumberInput
	},
	data() {
		return {
			user: this.value
		};
	},
	methods: {
		add_new_contact() {
			if (!this.user.maintainer_role_extra_data.other_contacts)
				this.user.maintainer_role_extra_data.other_contacts = [];

			this.user.maintainer_role_extra_data.other_contacts.push({});
		},
		remove_contact(index) {
			if (!this.user.maintainer_role_extra_data.other_contacts)
				this.user.maintainer_role_extra_data.other_contacts = [];
			this.user.maintainer_role_extra_data.other_contacts.splice(index, 1);
		}
	}
};
</script>

<style lang="css" scoped>
.contact-form {
	display: grid;
	grid-template-columns: 100px auto 100px auto;
	column-gap: 10px;
	row-gap: 10px;
}
.remove-contact-button {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
}
</style>
