<template>
	<Multiselect
		v-model="selected"
		:multiple="false"
		:options="options"
		track_by="value"
		label="label"
	/>
</template>

<script>
import Multiselect from "@form_components/Multiselect";

import EntityServiceClass from "@services/entity-service";
const EntityService = new EntityServiceClass();

export default {
	name: "MultiselectMultilingualEntity",
	components: {
		Multiselect
	},
	props: {
		value: String,
		multilingual_entity: Object,
		multilingual_entity_name: String
	},
	data() {
		return {
			selected: null
		};
	},
	mounted() {
		this.selected = this.value;
	},
	computed: {
		options() {
			let result = [];

			const multilingual_entity_values = this.multilingual_entity_name
				? EntityService.get_multilingual_entity(this.multilingual_entity_name)
				: this.multilingual_entity;

			for (let key in multilingual_entity_values) {
				result.push({
					value: key,
					label: this.$t(multilingual_entity_values[key])
				});
			}

			return result;
		}
	},
	watch: {
		value(new_value) {
			this.selected = new_value;
		},
		selected(new_value) {
			this.$emit("input", new_value);
		}
	}
};
</script>
