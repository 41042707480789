import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_user_energy_systems() {
		const url = "/api/energy-data/systems";
		const backend_data = await this.get_items_with_offset(url, 3);
		const local_data = backend_data.map((item) =>
			BackendEnergySystemConverter(item)
		);

		return local_data;
	}
}
