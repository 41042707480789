import Joi from "joi";
import Validator from "@validators/validator";

const energy_system_schema = Joi.array().items(
	Joi.object({
		month: Joi.date().required(),
		total_savings: Joi.number().allow(null),
		self_consumption_savings: Joi.number().allow(null),
		to_grid_savings: Joi.number().allow(null)
	})
);
export default new Validator(energy_system_schema);
