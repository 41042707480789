<template>
	<div class="full-width full-height" :style="main_css_styles">
		<div v-if="!loading" class="flex-auto-full-height-content">
			<Header class="header" />
			<div class="content relative">
				<div
					v-if="is_mobile_device"
					class="full-width full-height display-only-small-devices"
				>
					<SmallDeviceHome />
				</div>
				<div v-else class="full-width full-height hide-in-small-devices">
					<div
						v-if="is_active_full_app_path"
						id="full-page-popup"
						class="grey2-bg"
					>
						<router-view></router-view>
					</div>

					<AssetsDashboard v-if="user_can_assets_dashboard" />
					<AssetsFullMap v-else />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Header from "@components/app_components/header/Header";
import AssetsFullMap from "@components/app_components/assets-full-map/AssetsFullMap";
import SmallDeviceHome from "./small-device-components/SmallDeviceHome";
import AssetsDashboard from "@components/app_components/assets-dashboard/AssetsDashboard.vue";

import AppServiceClass from "@services/app-service";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util.js";
import UserServiceClass from "@services/user-services/user-service";

const AppService = new AppServiceClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();
const UserService = new UserServiceClass();

export default {
	name: "App",
	components: {
		Header,
		AssetsFullMap,
		SmallDeviceHome,
		AssetsDashboard
	},
	data() {
		return {
			loading: true,
			is_mobile_device:
				this.$is_extra_small_device() || this.$is_small_device(),
			//user_can_assets_dashboard: false
			user_can_assets_dashboard:
				UserPermissionsUtil.can_user("assets_dashboard"),
			custom_primary_color: null
		};
	},
	async mounted() {
		try {
			this.loading = true;
			window.addEventListener("resize", this.window_resized);

			await AppService.intitial_setup();

			const custom_interface_data =
				await UserService.get_custom_user_interface_data();

			if (custom_interface_data) {
				this.custom_primary_color = custom_interface_data.primary_color;
			}
		} catch (e) {
			this.logout();
			return;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		logout() {
			this.$router.push({ name: "logout" });
		},
		window_resized() {
			this.is_mobile_device =
				this.$is_extra_small_device() || this.$is_small_device();

			const current_route_name = this.$route.name;
			const is_current_path_mobile_path =
				this.$route.path.indexOf("/mobile") != -1;

			if (
				current_route_name != "app" &&
				((this.is_mobile_device && !is_current_path_mobile_path) ||
					(!this.is_mobile_device && is_current_path_mobile_path))
			) {
				//Go to home
				this.$router.push({ name: "app" });
			}
		}
	},
	computed: {
		is_active_full_app_path: function () {
			const allowed_pages_names = [
				"user",
				"admin",
				"new-asset",
				"asset-info",
				"asset-main-panel",
				"ticketing",
				"assets",
				"full-map",
				"alerts",
				"assets-reports",
				"pr-tool"
			];

			const active_page_match_allowed = this.$route.matched.find(
				(item) => allowed_pages_names.indexOf(item.name) != -1
			);

			return active_page_match_allowed ? true : false;
		},
		main_css_styles() {
			return this.custom_primary_color
				? {
						"--custom-primary": this.custom_primary_color
				  }
				: null;
		}
	}
};
</script>

<style lang="css" scoped>
#full-page-popup {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
}
</style>
