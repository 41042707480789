import AssetsFinderInterfaceClass from "@src/asset/infrastructure/asset-finder-interface";
import AssetFinderValidator from "@src/asset/domain/asset-finder-validator";

import AssetsStoreUtil from "./utils/assets-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetFinderValidator;
		this.backend_interface = new AssetsFinderInterfaceClass();
		this.store_util = AssetsStoreUtil;
	}

	async get_asset(asset_id) {
		try {
			var cached_asset = await this.store_util.get_cached_item_by_id(asset_id);

			if (cached_asset) return cached_asset;

			const interface_result = await this.backend_interface.get_asset(asset_id);
			const validated_asset = this.validator.validate(interface_result);

			await this.store_util.add_or_update_item(validated_asset);

			return validated_asset;
		} catch (e) {
			throw new AppError("error_get_asset", "general.errors.general_error", e);
		}
	}
}
