<template>
	<div
		id="asset-edit"
		class="grey3-bg rounded-s box-shadow1 absolute full-width_xs overflow-hidden"
	>
		<div class="flex-auto-full-height-content">
			<PageHeader
				:text="$t('index.asset_menu.overview')"
				close_name="app"
				class="header"
			/>

			<div v-if="loading" class="m-t-xl">
				<Loader />
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="asset" class="content p-sm">
				<div class="flex-auto-full-height-content">
					<div class="header p-b">
						<div
							class="display-flex-space-between p-sm white-bg box-shadow1 display-flex rounded-s"
						>
							<div class="display-flex font-bold grey2-color">
								<div
									v-for="item in menu_items"
									class="menu-item cursor-pointer flex-center-content"
									v-on:click="menu_item = item.value"
									v-bind:class="{ 'primary-color': menu_item == item.value }"
								>
									{{ $t(item.multilingual_label) }}
								</div>
							</div>

							<div>
								<AssetShortcuts :asset_id="asset.id" />
							</div>
						</div>
					</div>
					<div class="content white-bg box-shadow1 rounded-s">
						<div class="grid-with-same-cols-width full-width full-height">
							<Map
								class="full-width full-height overflow-hidden rounded-m"
								:show_selector="true"
								:draggable_marker="asset.coordinates"
								v-on:map_clicked="map_clicked"
								v-on:draggable_marker_dragged="marker_dragged"
							/>
							<div class="p-sm">
								<EditCommonData
									v-if="menu_item == 'common_data'"
									:map_coordinates="asset.coordinates"
									v-on:coordinates_updated="coordinates_updated"
								/>
								<PvData v-else-if="menu_item == 'pv_data'" />
								<ConsumptionData v-else-if="menu_item == 'consumption_data'" />
								<EnergySystems v-else-if="menu_item == 'energy_systems'" />
								<AssetPermissions
									v-else-if="menu_item == 'permissions'"
									:asset="asset"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";
import Map from "@sub_components/Map";
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts";

import EditCommonData from "./AssetEditCommonData";
import PvData from "./pv-data/AssetPvData";
import ConsumptionData from "./consumption-data/AssetConsumptionData";
import EnergySystems from "./energy-systems/AssetEnergySystems";
import AssetPermissions from "./permissions/AssetPermissions";

import AssetsFinderClass from "@src/asset/application/assets-finder";
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "AssetInfo",
	components: {
		PageHeader,
		Loader,
		Alert,
		Map,
		AssetShortcuts,
		EditCommonData,
		PvData,
		ConsumptionData,
		EnergySystems,
		AssetPermissions
	},
	data() {
		return {
			loading: false,
			error: null,
			asset: null,
			menu_item: "common_data"
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.$route.params.asset_id;
			const asset = await AssetsFinder.get_asset_by_id(asset_id);
			this.asset = Object.assign({}, asset);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		marker_dragged(new_coordinates) {
			if (this.menu_item == "common_data") {
				this.asset.coordinates = new_coordinates;
			}
		},
		coordinates_updated(new_coordinates) {
			this.asset.coordinates = new_coordinates;
		}
	},
	computed: {
		menu_items() {
			if (!this.asset) return null;

			let menu_items = [
				{
					multilingual_label: "asset_info.menu.common_data",
					value: "common_data"
				}
			];

			if (this.asset.features) {
				if (this.asset.features.has_pv_production) {
					menu_items.push({
						multilingual_label: "asset_info.menu.pv_data",
						value: "pv_data"
					});
				}

				if (this.asset.features.has_energy_consumption) {
					menu_items.push({
						multilingual_label: "asset_info.menu.consumption_data",
						value: "consumption_data"
					});
				}

				if (this.asset.features.has_eo_production) {
					menu_items.push({
						multilingual_label: "asset_info.menu.eo_data",
						value: "eo_data"
					});
				}
			}

			menu_items.push({
				multilingual_label: "asset_info.menu.asset_energy_systems",
				value: "energy_systems"
			});

			menu_items.push({
				multilingual_label: "asset_info.menu.permissions",
				value: "permissions"
			});

			return menu_items;
		}
	}
};
</script>

<style scoped>
#asset-edit {
	width: calc(100% - 10px);
	left: 5px;
	top: 5px;
	height: calc(100% - 10px);
}
.menu-item:not(:first-child) {
	margin-left: 25px;
}
</style>
