<template>
	<img
		v-if="image_as_base64"
		v-bind:src="image_as_base64"
		:style="image_style"
	/>
</template>

<script>
export default {
	props: {
		src: String,
		image_width: String,
		image_height: String
	},
	data() {
		return {
			image_as_base64: "",
			image_style: {
				width: this.image_width || null,
				height: this.image_height || null
			}
		};
	},
	mounted() {
		this.load_src_image();
	},
	methods: {
		async load_src_image() {
			let getImageBlob = function (url) {
				return new Promise(async (resolve) => {
					let resposne = await fetch(url);
					let blob = resposne.blob();
					resolve(blob);
				});
			};

			let blobToBase64 = function (blob) {
				return new Promise((resolve) => {
					let reader = new FileReader();
					reader.onload = function () {
						let dataUrl = reader.result;
						resolve(dataUrl);
					};
					reader.readAsDataURL(blob);
				});
			};

			let blob = await getImageBlob(this.src);
			let base64_image = await blobToBase64(blob);

			this.image_as_base64 = base64_image;
		}
	},
	watch: {
		src() {
			this.load_src_image();
		}
	}
};
</script>
