import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_user_pr_limit(data) {
		const url = "/api/user-providers/pr-limit";
		const backend_data = await this.put(url, {
			body_params: {
				PRLimit: {
					PRLowestLimit: data.pr_limit.pr_lowest_limit
				}
			}
		});

		return true;
	}
}
