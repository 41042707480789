import Joi from "joi";
import Validator from "@validators/validator";

const user_login_schema = Joi.object({
	email: Joi.string()
		.email({ minDomainSegments: 2, tlds: false })
		.required(),
	password: Joi.string().required()
});

export default new Validator(user_login_schema, "login.entity.");
