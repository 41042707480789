import AssetEnergyDataUpdaterInterfaceClass from "@src/asset-energy-data/infrastructure/asset-energy-data-config-updater-interface";
import AssetEnergyDataUpdaterUpdaterValidator from "@src/asset-energy-data/domain/asset-energy-data-config-updater-validator";
import AssetEnergyDataFinderValidator from "@src/asset-energy-data/domain/asset-energy-data-config-finder-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import { store_entity_names } from "@config";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergyDataUpdaterInterfaceClass();
		this.updater_validator = AssetEnergyDataUpdaterUpdaterValidator;
		this.finder_validator = AssetEnergyDataFinderValidator;

		this.store_interface = new StoreInterfaceClass();
		this.user_energy_systems_store_entity_name =
			store_entity_names.user_energy_systems;
	}

	async update_asset_energy_data_configuration(asset_id, configuration_data) {
		const validated_data_to_update =
			this.updater_validator.validate(configuration_data);

		try {
			const updated_data =
				await this.backend_interface.update_asset_energy_data_configuration(
					asset_id,
					validated_data_to_update
				);
			const validated_returned_data =
				this.finder_validator.validate(updated_data);

			this.store_interface.delete_entity_values(
				this.user_energy_systems_store_entity_name
			);

			return validated_returned_data;
		} catch (e) {
			throw new AppError(
				"error_update_asset_energy_data_configuration",
				"general.errors.general_error",
				e
			);
		}
	}
}
