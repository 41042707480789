<template lang="html">
	<div>
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else-if="asset_pv_report">
			<Header :title="$t('asset_reports.injection0_report.title')" />

			<EnergyProductionSummary
				:asset_reports="asset_pv_report"
				:asset_reports_color="asset_reports_color"
				:hide_energy_production_variation="true"
			/>

			<SavingsPollutionTable
				class="m-t-xl"
				:selected_period_savings="
					asset_pv_report.savings_summary.selected_period_savings
				"
				:total_savings="
					asset_pv_report.savings_summary
						.from_start_of_available_data_to_end_of_selected_period_savings_summary
				"
			/>

			<Footer :page_number="'2/' + (report_comment ? '3' : '2')" />
		</div>
		<div v-else></div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import Header from "../../reports-shared-components/Header.vue";

import EnergyProductionSummary from "../../reports-shared-components/EnergyProductionSummary.vue";
import SavingsPollutionTable from "../../asset-pv-report/report-pages/sub-components/SavingsPollutionTable.vue";

import Footer from "../../reports-shared-components/Footer.vue";

import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder";
const AssetPvReportFinder = new AssetPvReportFinderClass();

export default {
	name: "AssetInjection0ReportPage2",
	components: {
		Loader,
		Alert,
		Header,
		Footer,
		EnergyProductionSummary,
		SavingsPollutionTable
	},
	props: {
		asset: Object,
		asset_reports_color: String,
		asset_reports_filter: Object,
		asset_pv_report: Object,
		report_comment: String
	},
	data() {
		return {
			table_data: null,
			loading: null,
			error: null,
			asset_pv_report: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_pv_report = await AssetPvReportFinder.get_asset_pv_report(
				this.asset.id,
				this.asset_reports_filter.from,
				this.asset_reports_filter.to
			);

			this.asset_pv_report = Object.assign({}, asset_pv_report);
		} catch (error) {
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style lang="css" scoped></style>
