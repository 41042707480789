<template>
	<div v-if="loading" class="p-sm">
		<Loader size="30" />
	</div>
	<div v-else-if="error" class="p-sm">
		<Alert :message="error" />
	</div>
	<div
		v-else
		class="flex-with-same-cols-width"
		style="display: grid; grid-template-columns: repeat(2, 600px)"
	>
		<div v-if="asset" class="border-r p-sm">
			<div class="flex-auto-full-height-content">
				<div class="content">
					<div class="display-grid gap-m">
						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.asset.name") }}
							</div>
							<div>
								{{ asset.name }}
							</div>
						</div>

						<div v-if="asset_other_data" class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.asset.address") }}
							</div>
							<div>
								{{ asset_other_data.address || "-" }}
							</div>
						</div>

						<div class="flex-with-same-cols-width">
							<div class="display-grid gap-xs">
								<div class="font-700">
									{{ $t("entities.asset.coordinates.lat") }}
								</div>
								<div>
									{{ $format_number(asset.coordinates.lat) }}
								</div>
							</div>

							<div class="display-grid gap-xs">
								<div class="font-700">
									{{ $t("entities.asset.coordinates.lon") }}
								</div>
								<div>
									{{ $format_number(asset.coordinates.lon) }}
								</div>
							</div>
						</div>

						<div style="width: 100%; height: 300px">
							<Map :assets="[asset]" />
						</div>
					</div>
				</div>
				<div class="footer p-t">
					<div class="flex-horizontal-right">
						<router-link
							:to="{
								name: 'asset-info',
								params: { asset_id: asset.id }
							}"
						>
							<button class="btn btn-primary">
								{{ $t("general.edit") }}
							</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="p-sm">
			<div v-for="energy_system in energy_systems">
				<div class="display-grid gap-m">
					<div class="display-grid gap-xs">
						<div class="font-700">
							{{ $t("entities.energy_system.name") }}
						</div>
						<div>
							{{ energy_system.name || "-" }}
						</div>
					</div>

					<div class="flex-with-same-cols-width">
						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.peak_power") }} (kWp)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.peak_power)
										? $format_number(energy_system.pv_data.peak_power)
										: "-"
								}}
							</div>
						</div>

						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.nominal_power") }} (kWp)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.nominal_power)
										? $format_number(energy_system.pv_data.nominal_power)
										: "-"
								}}
							</div>
						</div>
					</div>

					<div class="flex-with-same-cols-width">
						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.peak_power") }} (kWp)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.peak_power)
										? $format_number(energy_system.pv_data.peak_power)
										: "-"
								}}
							</div>
						</div>

						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.nominal_power") }} (kW)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.nominal_power)
										? $format_number(energy_system.pv_data.nominal_power)
										: "-"
								}}
							</div>
						</div>
					</div>

					<div class="flex-with-same-cols-width">
						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.tracking") }}
							</div>
							<div>
								{{
									energy_system.pv_data.tracking
										? $t(
												"entities.energy_system.pv_data.tracking_options." +
													energy_system.pv_data.tracking
										  )
										: "-"
								}}
							</div>
						</div>

						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.tilt") }} (º)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.tilt)
										? $format_number(energy_system.pv_data.tilt)
										: "-"
								}}
							</div>
						</div>
					</div>

					<div class="flex-with-same-cols-width">
						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.azimuth") }} (º)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.azimuth)
										? $format_number(energy_system.pv_data.azimuth)
										: "-"
								}}
							</div>
						</div>

						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.pv_data.losses") }} (%)
							</div>
							<div>
								{{
									$is_number(energy_system.pv_data.losses)
										? $format_number(energy_system.pv_data.losses)
										: "-"
								}}
							</div>
						</div>
					</div>

					<div class="flex-with-same-cols-width">
						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.coordinates.lat") }}
							</div>
							<div>
								{{
									$is_number(energy_system.coordinates.lat)
										? $format_number(energy_system.coordinates.lat)
										: "-"
								}}
							</div>
						</div>

						<div class="display-grid gap-xs">
							<div class="font-700">
								{{ $t("entities.energy_system.coordinates.lon") }}
							</div>
							<div>
								{{
									$is_number(energy_system.coordinates.lon)
										? $format_number(energy_system.coordinates.lon)
										: "-"
								}}
							</div>
						</div>
					</div>

					<div class="full-width" style="height: 300px">
						<Map :assets="[energy_system]" />
					</div>
				</div>

				<div class="flex-horizontal-right m-t">
					<router-link
						:to="{
							name: 'user-energy-system-update',
							params: { system_id: energy_system.id }
						}"
					>
						<button class="btn btn-primary">
							{{ $t("general.edit") }}
						</button>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Map from "@sub_components/Map.vue";

import AssetFinderClass from "@src/asset/application/asset-finder.js";
import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder.js";
import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder.js";

const AssetFinder = new AssetFinderClass();
const AssetOtherDataFinder = new AssetOtherDataFinderClass();
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();

export default {
	name: "AssetToReviewView",
	components: {
		Loader,
		Map
	},
	props: {
		alert: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			asset: null,
			asset_other_data: null,
			energy_systems: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.alert.asset_id;
			this.asset = await AssetFinder.get_asset(asset_id);
			this.asset_other_data = await AssetOtherDataFinder.get_asset_other_data(
				asset_id
			);
			this.energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(asset_id);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	}
};
</script>
