//import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";

import Login from "@components/login/Login";
import ForgotPasswordForm from "@components/login/ForgotPasswordForm";

import ResetPassword from "@components/login/ResetPassword";

import Logout from "@components/Logout";
import Index from "@components/Index.vue";

import AssetsFullMap from "@components/app_components/assets-full-map/AssetsFullMap.vue";

import AssetInfo from "@components/app_components/asset/info/AssetInfo";
import NewAsset from "@components/app_components/asset/new-asset/NewAsset";
import RemoveAsset from "@components/app_components/asset/RemoveAsset";
import SystemAssets from "@components/app_components/assets-dashboard/SystemAssets.vue";
import AssetSystems from "@components/app_components/assets-dashboard/AssetSystems.vue";

import Location from "@components/app_components/locations/location/Location";

import AssetEnergyDataMainPanel from "@components/app_components/asset/energy-data-main-panel/AssetEnergyDataMainPanel";
import AssetEnergyDataMainPanelMeasurements from "@components/app_components/asset/energy-data-main-panel/measurements/AssetEnergyDataMainPanelMeasurements";
import AssetEnergyDataMainPanelWeather from "@components/app_components/asset/energy-data-main-panel/AssetEnergyDataMainPanelWeather";
import AssetEnergyDataMainPanelSavings from "@components/app_components/asset/energy-data-main-panel/savings/AssetEnergyDataMainPanelSavings";

import User from "@components/app_components/user/User";
import UserSettings from "@components/app_components/user/settings/UserSettings";
import TicketingSettings from "@components/app_components/user/ticketing/TicketingSettings";
import EnergyProvidersAuth from "@components/app_components/user/energy-providers-auths/EnergyProvidersAuth";
import EnergyProvidersAuthNew from "@components/app_components/user/energy-providers-auths/EnergyProvidersAuthNew";
import EnergyProvidersAuthEdit from "@components/app_components/user/energy-providers-auths/EnergyProvidersAuthEdit";
import EmailNotificationsSettings from "@components/app_components/user/EmailNotificationsSettings";

import AssetsGroupsConfig from "@components/app_components/user/assets-groups-config/AssetsGroupsConfig.vue";
import NewAssetsGroup from "@components/app_components/user/assets-groups-config/NewAssetsGroup.vue";
import EditAssetsGroup from "@components/app_components/user/assets-groups-config/EditAssetsGroup.vue";
import RemoveAssetsGroup from "@components/app_components/user/assets-groups-config/RemoveAssetsGroup.vue";

import OwnedUsers from "@components/app_components/user/users/Users";
import OwnedUserView from "@components/app_components/user/users/view/UserView";
import OwnedUserAdd from "@components/app_components/user/users/UserAdd";
import OwnedUserEdit from "@components/app_components/user/users/UserEdit";
import OwnedUserRemove from "@components/app_components/user/users/UserRemove";
import OwnedUserResetPassword from "@components/app_components/user/users/UserResetPassword";

import UserEnergySystems from "@components/app_components/user/energy-systems/UserEnergySystems";
import UserEnergySystem from "@components/app_components/user/energy-systems/UserEnergySystem";
import UserEnergySystemUpdate from "@components/app_components/user/energy-systems/edit/EnergySystemEdit";
import EnergySystemWithProviderCreate from "@components/app_components/user/energy-systems/create/EnergySystemWithProviderCreate";
import EnergySystemCreate from "@components/app_components/user/energy-systems/create/EnergySystemCreate";
import EnergySystemsRemove from "@components/app_components/user/energy-systems/EnergySystemsRemove";

import Admin from "@components/app_components/admin/Admin";
import AdminUsers from "@components/app_components/admin/Users";
import AdminNewUser from "@components/app_components/admin/AdminNewUser";
import UserApiKey from "@components/app_components/admin/UserApiKey";
import UserEdit from "@components/app_components/admin/user-edit/UserEdit";
import AdminUserEnergySystems from "@components/app_components/admin/user-edit/user-energy-systems/AdminUserEnergySystems";
import AdminUi from "@components/app_components/admin/ui/AdminUi.vue";

import Ticketing from "@components/app_components/ticketing/Ticketing";
import Ticket from "@components/app_components/ticketing/Ticket";
import TicketRemove from "@components/app_components/ticketing/TicketRemove";
import TicketView from "@components/app_components/ticketing/TicketView";

import Alerts from "@components/app_components/alerts/Alerts";

import AssetReports from "@components/app_components/reports/asset-reports/AssetReports";

import PrTool from "@components/app_components/pr-tool/PrTool";

//Small device components
import LocationMainPanel from "@components/small-device-components/location-main-panel/LocationMainPanel";

//Asset energy panel
import AssetEnergyPanel from "@components/asset-energy-panel/AssetEnergyPanel";

const routes = [
	{
		path: "/login",
		name: "login",
		component: Login,
		children: [
			{
				path: "/forgot-password",
				name: "forgot-password",
				component: ForgotPasswordForm
			}
		]
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: ResetPassword
	},
	{
		path: "/logout",
		name: "logout",
		component: Logout
	},
	{
		path: "/",
		name: "app",
		default: true, // indicate if it's the default route
		component: Index,
		children: [
			{
				name: "full-map",
				path: "full-map",
				component: AssetsFullMap
			},
			{
				name: "new-asset",
				path: "new-asset",
				component: NewAsset
			},
			{
				name: "remove-asset",
				path: "remove-asset/:asset_id",
				component: RemoveAsset
			},
			{
				name: "system-assets",
				path: "system-assets/:system_id",
				component: SystemAssets
			},
			{
				name: "asset-systems",
				path: "asset-systems/:asset_id",
				component: AssetSystems
			},
			{
				name: "asset",
				path: "asset/:asset_id",
				component: Location,
				children: [
					{
						name: "asset-main-panel",
						path: "main-panel",
						component: AssetEnergyDataMainPanel,
						children: [
							{
								name: "asset-main-panel-measurements",
								path: "measurements",
								component: AssetEnergyDataMainPanelMeasurements
							},
							{
								name: "asset-main-panel-weather",
								path: "weather",
								component: AssetEnergyDataMainPanelWeather
							},
							{
								name: "asset-main-panel-savings",
								path: "savings",
								component: AssetEnergyDataMainPanelSavings
							}
						]
					},
					{
						name: "asset-info",
						path: "asset-info",
						component: AssetInfo
					}
				]
			},
			{
				name: "user",
				path: "/user",
				component: User,
				children: [
					{
						name: "user-settings",
						path: "settings",
						component: UserSettings
					},
					{
						name: "ticketing-settings",
						path: "ticketing-settings",
						component: TicketingSettings
					},
					{
						name: "email-notifications-settings",
						path: "email-notifications-settings",
						component: EmailNotificationsSettings
					},
					{
						name: "user-providers",
						path: "providers",
						component: EnergyProvidersAuth,
						children: [
							{
								name: "user-energy-data-provider-new",
								path: "new",
								component: EnergyProvidersAuthNew
							},
							{
								name: "user-energy-data-provider-edit",
								path: ":auth_id/update",
								component: EnergyProvidersAuthEdit
							}
						]
					},
					{
						name: "owned-users",
						path: "users",
						component: OwnedUsers,
						children: [
							{
								name: "owned-user-view",
								path: ":role/:user_id/view",
								component: OwnedUserView
							},
							{
								name: "owned-user-add",
								path: ":role/add",
								component: OwnedUserAdd
							},
							{
								name: "owned-user-edit",
								path: ":role/edit/:user_id",
								component: OwnedUserEdit
							},
							{
								name: "owned-user-remove",
								path: ":role/remove/:user_id",
								component: OwnedUserRemove
							},
							{
								name: "owned-user-reset-password",
								path: ":role/reset-password/:user_id",
								component: OwnedUserResetPassword
							}
						]
					},
					{
						name: "user-energy-systems",
						path: "user-energy-systems",
						component: UserEnergySystems,
						children: [
							{
								name: "user-energy-system",
								path: ":system_id",
								component: UserEnergySystem
							},
							{
								name: "user-energy-system-update",
								path: ":system_id/update",
								component: UserEnergySystemUpdate
							},
							{
								name: "add-energy-system-with-provider",
								path: "add-energy-system-with-provider",
								component: EnergySystemWithProviderCreate
							},
							{
								name: "add-energy-system",
								path: "add",
								component: EnergySystemCreate
							},
							{
								name: "remove-energy-systems",
								path: "remove",
								component: EnergySystemsRemove
							}
						]
					},
					{
						name: "assets-group-config",
						path: "assets-group-config",
						component: AssetsGroupsConfig,
						children: [
							{
								name: "new-assets-group",
								path: "new",
								component: NewAssetsGroup
							},
							{
								name: "edit-assets-group",
								path: ":assets_group_id/edit",
								component: EditAssetsGroup
							},
							{
								name: "remove-assets-group",
								path: ":assets_group_id/remove",
								component: RemoveAssetsGroup
							}
						]
					}
				]
			},
			{
				name: "admin",
				path: "/admin",
				component: Admin,
				children: [
					{
						name: "admin-users",
						path: "users",
						component: AdminUsers,
						children: [
							{
								name: "admin-new-user",
								path: "new-user",
								component: AdminNewUser
							},
							{
								name: "admin-user-api-key",
								path: ":user_id/admin-user-api-key",
								component: UserApiKey
							},
							{
								name: "admin-user-edit",
								path: ":user_id/edit",
								component: UserEdit,
								children: [
									{
										name: "admin-user-energy-systems",
										path: "energy-systems",
										component: AdminUserEnergySystems
									}
								]
							}
						]
					},
					{
						name: "admin-ui",
						path: "ui",
						component: AdminUi
					}
				]
			},
			{
				name: "ticketing",
				path: "/ticketing",
				component: Ticketing,
				children: [
					{
						name: "new-ticket",
						path: "new",
						component: Ticket
					},
					{
						name: "edit-ticket",
						path: "ticket/:ticket_id/edit",
						component: Ticket
					},
					{
						name: "remove-ticket",
						path: "ticket/:ticket_id/remove",
						component: TicketRemove
					},
					{
						name: "view-ticket",
						path: "ticket/:ticket_id/view",
						component: TicketView
					}
				]
			},
			{
				name: "alerts",
				path: "/alerts",
				component: Alerts
			},
			{
				name: "assets-reports",
				path: "assets-reports",
				component: AssetReports
			},
			{
				name: "pr-tool",
				path: "pr-tool",
				component: PrTool
			},
			//mobile routes
			{
				name: "mobile-location-main-panel",
				path: "/mobile/location/:location_id/main-panel",
				component: LocationMainPanel
			}
		]
	},
	{
		path: "/asset-energy-panel",
		name: "asset-energy-panel",
		component: AssetEnergyPanel
	}
];

const router = createRouter({
	// mode: 'history',
	history: createWebHistory(),
	routes
});

export default router;
