<template>
	<div id="main-div" class="white-bg rounded-s box-shadow1">
		<div class="flex-auto-full-height-content">
			<PageHeader
				class="header"
				:text="$t('general.view')"
				close_name="owned-users"
			/>

			<div class="content p-sm">
				<div v-if="loading">
					<Loader size="30" />
				</div>
				<div v-else-if="error">
					<Alert :message="error" />
				</div>
				<div v-else-if="user">
					<div id="user-info">
						<div>
							<div class="flex-center-content">
								<UserAvatar :user="user" size="120" />
							</div>
							<div class="m-t-xs font-bold text-center">
								<span>{{ user.first_name }}</span>
								<span v-if="user.last_name">{{ user.last_name }}</span>
							</div>
							<div class="m-t-xs text-center">
								<router-link
									:to="{
										name: 'owned-user-edit',
										params: { role: role, user_id: user_id }
									}"
								>
									<button class="btn btn-primary">
										{{ $t("general.edit") }}
									</button>
								</router-link>
							</div>
						</div>

						<div>
							<div id="user-data" class="grey1-color">
								<div v-for="item in user_data" class="user-data-table">
									<div class="flex-center-content grey2-color">
										<Icon :icon="item.icon" />
									</div>
									<div>
										<div class="font-bold">
											{{ $t(item.label) }}
										</div>
										<div v-if="Array.isArray(item.value)" class="display-flex">
											<div
												v-for="value in item.value"
												class="border-all rounded rounded-s p-l p-r p-t-xxs p-b-xxs m-l-xs"
											>
												{{ value }}
											</div>
										</div>
										<div v-else>
											{{ item.value }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						v-if="
							role == 'maintainer' &&
							user.maintainer_role_extra_data &&
							user.maintainer_role_extra_data.other_contacts &&
							user.maintainer_role_extra_data.other_contacts.length > 0
						"
					>
						<MaintainerContacts :maintainer="user" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import UserAvatar from "@sub_components/UserAvatar";
import MaintainerContacts from "./MaintainerContacts";

import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import OwnedManagerUsersFinderClass from "@src/user/application/owned-manager-users-finder";
const OwnedUsersFinder = new OwnedUsersFinderClass();
const OwnedManagerUsersFinder = new OwnedManagerUsersFinderClass();

export default {
	name: "UserView",
	components: { Loader, Alert, PageHeader, UserAvatar, MaintainerContacts },
	props: {
		selected_mananger_id: String | null
	},
	data() {
		return {
			loading: false,
			error: null,
			user: null
		};
	},
	async created() {
		this.load_user_view();
	},
	methods: {
		async load_user_view() {
			if (!this.role || !this.user_id) return null;
			this.loading = true;

			try {
				this.user = this.selected_mananger_id
					? await OwnedManagerUsersFinder.get_owned_manager_user(
							this.selected_mananger_id,
							this.role,
							this.user_id
					  )
					: await OwnedUsersFinder.get_owned_user(this.role, this.user_id);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		role() {
			return this.$route.params.role || null;
		},
		user_id() {
			return this.$route.params.user_id || null;
		},
		user_data() {
			if (!this.user) return null;

			var data = [];
			switch (this.role) {
				case "manager":
					data = [
						{
							value: this.user.email,
							label: "entities.user.email",
							icon: "envelope"
						}
					];
					break;
				case "maintainer":
					if (this.user.maintainer_role_extra_data) {
						const maintainer_data = this.user.maintainer_role_extra_data;

						if (maintainer_data.company_name) {
							data.push({
								value: maintainer_data.company_name,
								label: "entities.user.maintainer_role_extra_data.company_name",
								icon: "building"
							});
						}

						if (maintainer_data.phone) {
							data.push({
								value: maintainer_data.phone,
								label: "entities.user.maintainer_role_extra_data.phone",
								icon: "phone"
							});
						}

						data.push({
							value: this.user.email,
							label: "entities.user.email",
							icon: "envelope"
						});

						if (maintainer_data.address) {
							data.push({
								value: maintainer_data.address,
								label: "entities.user.maintainer_role_extra_data.address",
								icon: "home"
							});
						}

						if (maintainer_data.zones && maintainer_data.zones.length > 0) {
							data.push({
								value: maintainer_data.zones,
								label: "user_settings.working_zones",
								icon: "location-dot"
							});
						}
					}
					break;

				case "guest":
					data = [
						{
							value: this.user.email,
							label: "entities.user.email",
							icon: "envelope"
						}
					];

					if (this.user.guest_role_extra_data) {
						const guest_data = this.user.guest_role_extra_data;
						if (guest_data.customer_since)
							data.push({
								value: this.$format_date_to_day(guest_data.customer_since),
								label: "entities.user.guest_role_extra_data.customer_since",
								icon: "calendar-alt"
							});
						if (guest_data.address)
							data.push({
								value: guest_data.address,
								label: "entities.user.guest_role_extra_data.address",
								icon: "home"
							});
					}
					break;
			}

			data.push({
				value: this.user.has_access_all_assets
					? this.$t("general.all")
					: this.user.allowed_assets
					? this.user.allowed_assets.length
					: 0,
				label: "user_settings.sites_operated",
				icon: "solar-panel"
			});

			return data;
		}
	}
};
</script>

<style lang="css" scoped>
#main-div {
	width: 900px;
	max-height: calc(100% - 30px);
}
#user-info {
	display: grid;
	grid-template-columns: 150px auto;
}
#user-data {
	display: grid;
	grid-template-columns: auto auto;
	row-gap: 15px;
}
.user-data-table {
	display: grid;
	grid-template-columns: 35px auto;
	column-gap: 10px;
}
</style>
