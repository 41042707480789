<template>
	<div class="full-width relative white-bg">
		<Header :title="$t('asset_reports.asset_monthly_savings_report2.title')" />
		<div class="m-t" :style="main_css_styles">
			<div v-if="loading">
				<Loader />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div v-else style="font-size: 13px">
				<div class="display-flex">
					<div class="no-wrap">
						<span class="font-bold">
							{{
								$t("asset_reports.asset_monthly_savings_report.page1.month")
							}}:
						</span>
						<span class="no-wrap">
							{{ $format_date_to_month(filter.month) }}
						</span>
					</div>
					<div class="p-l-xs p-r-xs">|</div>
					<div class="no-wrap">
						<span class="font-bold">
							{{
								$t(
									"asset_reports.asset_monthly_savings_report.page1.asset_name"
								)
							}}:
						</span>
						<span>
							{{ asset.name }}
						</span>
					</div>
				</div>

				<div v-if="asset_other_data && asset_other_data.address" class="m-t-xs">
					<div>
						<span class="font-bold">
							{{
								$t(
									"asset_reports.asset_monthly_savings_report.page1.asset_address"
								)
							}}:
						</span>
						<span>
							{{ asset_other_data.address }}
						</span>
					</div>
				</div>

				<div v-if="asset_energy_system" class="display-flex m-t-xs">
					<div v-if="asset_energy_system.commissioning_date">
						<span class="font-bold">
							{{
								$t(
									"asset_reports.asset_monthly_savings_report.page1.commissioning_date"
								)
							}}:
						</span>
						<span>
							{{ $format_date_to_day(asset_energy_system.commissioning_date) }}
						</span>
					</div>

					<div
						class="p-l-xs p-r-xs"
						v-if="
							asset_energy_system.commissioning_date &&
							asset_energy_system.pv_data &&
							$is_number(asset_energy_system.pv_data.nominal_power)
						"
					>
						|
					</div>

					<div v-if="$is_number(asset_energy_system.pv_data.nominal_power)">
						<span class="font-bold">
							{{ $t("entities.energy_system.pv_data.nominal_power") }}:
						</span>
						<span> {{ asset_energy_system.pv_data.nominal_power }} kW </span>
					</div>
				</div>
			</div>

			<div v-if="asset_daily_energy_measurements" class="m-t">
				<div class="font-bold text-uppercase">
					{{ $t("asset_energy_panel.energy_summary") }}
				</div>
				<AssetEnergyChart
					:variables_to_show="['energy_self_consumption', 'energy_to_grid']"
					:asset_daily_energy_measurements="asset_daily_energy_measurements"
				/>
				<AssetEnergyChart
					style="margin-top: -10px"
					:variables_to_show="['energy_self_consumption', 'energy_from_grid']"
					:asset_daily_energy_measurements="asset_daily_energy_measurements"
				/>
			</div>

			<AssetFilterMonthSummaryCharts
				v-if="asset_filter_month_energy_measurements"
				class="m-t"
				:asset_filter_month_energy_measurements="
					asset_filter_month_energy_measurements
				"
			/>

			<AssetFilterMonthSavingsSummary
				v-if="
					asset_filter_month_energy_measurements && asset_filter_month_savings
				"
				class="m-t-md"
				:asset_filter_month_energy_measurements="
					asset_filter_month_energy_measurements
				"
				:asset_filter_month_savings="asset_filter_month_savings"
			/>

			<AssetTotalSummary
				v-if="asset_monthly_energy_measurements"
				class="m-t-md"
				:asset_last_year_monthly_energy_measurements="
					asset_last_year_monthly_energy_measurements
				"
				:asset_monthly_energy_measurements="asset_monthly_energy_measurements"
				:asset_total_savings="asset_total_savings"
			/>
		</div>

		<Footer />
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Header from "../../reports-shared-components/Header.vue";
import Footer from "../../reports-shared-components/Footer.vue";

import AssetEnergyChart from "./sub-components/AssetEnergyChart.vue";
import AssetFilterMonthSummaryCharts from "./sub-components/AssetFilterMonthSummaryCharts.vue";
import AssetTotalSummary from "./sub-components/AssetTotalSummary.vue";
import AssetFilterMonthSavingsSummary from "./sub-components/AssetFilterMonthSavingsSummary.vue";

import moment from "moment";
import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder.js";
import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder.js";
import AssetDailyEnergyFinderClass from "@src/asset-energy-data/application/asset-daily-energy-finder.js";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder.js";
import AssetSavingsMonhtlyFinderClass from "@src/asset-savings/application/asset-savings-monthly-finder.js";

const AssetOtherDataFinder = new AssetOtherDataFinderClass();
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();
const AssetDailyEnergyFinder = new AssetDailyEnergyFinderClass();
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();
const AssetSavingsMonhtlyFinder = new AssetSavingsMonhtlyFinderClass();

export default {
	name: "AssetMonhtlyReport2Page1",
	components: {
		Loader,
		Alert,
		Header,
		Footer,
		AssetEnergyChart,
		AssetFilterMonthSummaryCharts,
		AssetTotalSummary,
		AssetFilterMonthSavingsSummary
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object
	},
	data() {
		return {
			main_css_styles: {
				"--custom-asset_monht_report2_green": "#46C27E",
				"--custom-asset_monht_report2_yellow": "#FFBD00"
			},
			loading: null,
			error: null,
			asset_other_data: null,
			asset_energy_system: null,
			asset_daily_energy_measurements: null,
			asset_filter_month_energy_measurements: null,
			asset_last_year_monthly_energy_measurements: null,
			asset_monthly_energy_measurements: null,
			asset_filter_month_savings: null,
			asset_total_savings: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			await Promise.all([
				this.set_asset_other_data(),
				this.set_asset_energy_system(),
				this.set_asset_daily_energy_measurements(),
				this.set_asset_monthly_energy_measurements(),
				this.set_asset_savings()
			]);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async set_asset_other_data() {
			this.asset_other_data = await AssetOtherDataFinder.get_asset_other_data(
				this.asset.id
			);
		},
		async set_asset_energy_system() {
			const asset_energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(this.asset.id);
			if (asset_energy_systems.length == 1)
				this.asset_energy_system = asset_energy_systems[0];
		},
		async set_asset_daily_energy_measurements() {
			this.asset_daily_energy_measurements =
				await AssetDailyEnergyFinder.get_asset_daily_energy(
					this.asset.id,
					moment(this.filter.month).startOf("month").toDate(),
					moment(this.filter.month).endOf("month").toDate()
				);
		},
		async set_asset_monthly_energy_measurements() {
			this.asset_monthly_energy_measurements =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
					this.asset.id,
					null,
					moment(this.filter.month).endOf("month").toDate()
				);

			this.asset_last_year_monthly_energy_measurements =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
					this.asset.id,
					moment(this.filter.month)
						.subtract(11, "months")
						.startOf("month")
						.toDate(),
					moment(this.filter.month).endOf("month").toDate()
				);

			const asset_filter_month_energy_measurements =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
					this.asset.id,
					moment(this.filter.month).startOf("month").toDate(),
					moment(this.filter.month).endOf("month").toDate()
				);

			if (asset_filter_month_energy_measurements)
				this.asset_filter_month_energy_measurements =
					asset_filter_month_energy_measurements[0];
		},
		async set_asset_savings() {
			const asset_filter_month_savings =
				await AssetSavingsMonhtlyFinder.get_asset_savings_monhtly_by_date(
					this.asset.id,
					this.filter.month,
					this.filter.month
				);
			if (asset_filter_month_savings)
				this.asset_filter_month_savings =
					asset_filter_month_savings[0].total_savings;

			const asset_total_savings =
				await AssetSavingsMonhtlyFinder.get_asset_savings_monhtly_by_date(
					this.asset.id,
					null,
					moment(this.filter.month).endOf("month").toDate()
				);
			if (asset_total_savings) {
				this.asset_total_savings = 0;
				asset_total_savings.forEach((item) => {
					this.asset_total_savings += item.total_savings;
				});
			}
		}
	}
};
</script>
