<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="full-height flex-center-content">
			<Loader />
		</div>
		<div v-else class="full-width full-height">
			<div v-if="full_app_path" id="full-page-popup" class="grey2-bg">
				<router-view></router-view>
			</div>
			<div class="full-width full-height">
				<div
					v-if="poup_app_paths && !full_app_path"
					id="app-paths"
					class="relative"
				>
					<router-view></router-view>
				</div>
				<div id="main_page" class="relative animation">
					<AssetsList v-if="assets" :assets="assets" />
					<div id="map-area" class="relative full-width full-height">
						<Map
							:assets="assets"
							v-on:map_asset_click="map_asset_click"
							:fly_to="map_fly_to"
						/>
						<div
							v-if="can_user_create_asset"
							id="add-asset-button"
							class="primary-bg"
						>
							<router-link :to="{ name: 'new-asset' }">
								<div>
									<Icon icon="plus" :size="30" />
								</div>
							</router-link>
						</div>

						<div v-if="custom_logo" id="powered-by">
							<div class="flex-center-content">
								<div>powered by</div>
								<img
									class="m-l-xs"
									height="25px"
									src="/img/sentinel-solar-logo-v2.png"
								/>
							</div>
						</div>
					</div>
					<MainMenu />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@components/sub_components/Loader";
import Alert from "@alert";
import AssetsList from "./assets-list/AssetsList";
import Map from "@sub_components/Map";
import MainMenu from "./MainMenu";

import UserServiceClass from "@services/user-services/user-service";
import AssetsFinderClass from "@src/asset/application/assets-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const UserService = new UserServiceClass();
const AssetsFinder = new AssetsFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "Home",
	components: {
		Loader,
		Alert,
		AssetsList,
		MainMenu,
		Map
	},
	data() {
		return {
			loading: false,
			custom_logo: null,
			can_user_create_asset: UserPermissionsUtil.can_user("create_asset"),
			assets: null,
			new_asset: null,
			map_data: null,

			//New map vars
			map_fly_to: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			await this.load_assets();

			const custom_interface_data =
				await UserService.get_custom_user_interface_data();
			if (custom_interface_data) {
				this.custom_logo = custom_interface_data.logo;
			}
		} catch (e) {
			this.logout();
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async load_assets() {
			this.assets = await AssetsFinder.get_user_assets();
		},
		logout() {
			this.$router.push({ name: "logout" });
		},
		fly_to_asset(asset_id) {
			const asset = this.assets.find((asset) => asset.id == asset_id);

			if (asset) this.fly_to_coordinates(asset.coordinates);
		},
		fly_to_coordinates(coordinates) {
			this.map_fly_to = {
				coordinates: coordinates,
				zoom: 17
			};
		},
		map_asset_click(asset) {
			if (this.$route.query.asset_id != asset.id) {
				this.$router.push({
					name: this.$route.name,
					query: { asset_id: asset.id }
				});
			} else {
				this.fly_to_asset(asset.id);
			}
		}
	},
	computed: {
		poup_app_paths: function () {
			const allowed = [
				"new-asset",
				"user",
				"admin",
				"remove-asset",
				"asset",
				"asset-info",
				"alerts"
			];

			for (var key in this.$route.matched) {
				if (allowed.indexOf(this.$route.matched[key].name) != -1) {
					return true;
				}
			}
			return false;
		},
		full_app_path: function () {
			const allowed = [
				"user",
				"admin",
				"asset-main-panel",
				"ticketing",
				"assets"
			];

			for (var key in this.$route.matched) {
				if (allowed.indexOf(this.$route.matched[key].name) != -1) {
					return true;
				}
			}
			return false;
		},
		assets_last_update() {
			return AssetsFinder.get_assets_last_update();
		}
	},
	watch: {
		"$route.query"(params) {
			if (params.asset_id) {
				const asset_id = params.asset_id;
				const asset = this.assets.find((asset) => asset.id == asset_id);

				if (asset_id && this.assets && asset) {
					this.fly_to_asset(asset_id);
				}
			}
		},
		assets_last_update() {
			this.load_assets();
		}
	}
};
</script>

<style lang="css" scoped>
:root {
	--left-panel-width: 300px;
}
#main-loading {
	position: absolute;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
}
#app-paths {
	position: absolute;
	z-index: 3;
	background-color: rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100%;
}
#main_page {
	width: 100%;
	height: 100%;
}
a #import-assets-button {
	position: absolute;
	right: 50px;
	bottom: 50px;
	z-index: 2;
	color: var(--custom-primary) !important;
}
#map-area {
	z-index: 1;
}
</style>

<style lang="css" scoped>
#add-asset-button {
	position: absolute;
	right: 45px;
	bottom: 45px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
#powered-by {
	position: absolute;
	right: 45px;
	bottom: 20px;
	font-size: 14px;
}
#full-page-popup {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 3;
}
</style>
