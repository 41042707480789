<template>
	<div class="full-width full-height">
		<div
			id="login"
			class="full-width full-height"
			:style="'background-image: url(' + bg_url + ')'"
		>
			<div class="p-sm white-bg-08 rounded-s">
				<div class="text-center">
					<img src="/img/sentinel-solar-logo-v2.png" />
				</div>

				<div class="m-t-md p-b">
					<LoginForm v-if="is_login_route" />
					<router-view v-else />
				</div>

				<div class="display-flex-space-between">
					<div>
						<small>© {{ new Date().getFullYear() }} </small>
						<small>
							<a href="http://www.nnergix.com" class="black" target="_blanc">
								www.nnergix.com
							</a>
						</small>
					</div>
					<div>
						<router-link
							v-if="is_login_route"
							:to="{ name: 'forgot-password' }"
							class="black"
						>
							{{ $t("login.forgot_password_link") }}
						</router-link>
						<router-link v-else :to="{ name: 'login' }" class="black">
							{{ $t("login.forgot_password.go_to_login") }}
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoginForm from "./LoginForm";

import AppServiceClass from "@services/app-service";

const AppService = new AppServiceClass();

export default {
	name: "Login",
	components: {
		LoginForm
	},
	data() {
		return {
			bg_url:
				"/img/login/login-bg-" + Math.floor(Math.random() * 8 + 1) + ".jpg"
		};
	},
	async mounted() {
		try {
			await AppService.intitial_setup();
		} catch (e) {}
	},
	computed: {
		is_login_route() {
			return this.$route.name == "login";
		}
	}
};
</script>

<style lang="css" scoped>
#login {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
#login > div {
	width: 100%;
	width: 420px;
	max-width: calc(100% - 30px);
	position: absolute;
	top: 15%;
	left: 0;
	right: 0;
	margin: auto;
}
#login a {
	font-size: 14px;
	font-weight: 500;
}
#login img {
	width: 80%;
}
</style>
