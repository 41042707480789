import AlertsFinderInterfaceClass from "../infrastructure/systems-alerts-finder-interface.js";
import SystemsAlertsFinderFilterValidator from "../domain/systems-alerts-finder-filter-validator.js";
import SystemAlertFinderValidator from "../domain/system-alert-finder-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = SystemAlertFinderValidator;
		this.finder_validator = SystemsAlertsFinderFilterValidator;
		this.backend_interface = new AlertsFinderInterfaceClass();
	}

	async get_systems_alerts(filter, page) {
		try {
			filter = this.finder_validator.validate(filter);
			var paginated_alerts = await this.backend_interface.get_systems_alerts(
				filter,
				page
			);

			paginated_alerts.data = paginated_alerts.data.map((alert) => {
				return this.validator.validate(alert);
			});

			return paginated_alerts;
		} catch (e) {
			throw new AppError(
				"error_get_systems_alerts",
				"general.errors.general_error",
				e
			);
		}
	}
}
