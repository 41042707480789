<template lang="html">
	<div class="full-width full-height">
		<div>
			<TextInput :placeholder="$t('index.filter_locations')" v-model="filter" v-on:input="filter_locations" />
		</div>
		<div id="locations-list" class="overflow-y-scroll m-t">
			<div v-if="loading" class="m-t">
				<Loader size="30" />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div v-else-if="locations">
				<div v-for="location in locations" :key="'locations_list_' + location.id"
					class="location m-b full-width rounded-s box-shadow1 white-bg relative overflow-hidden">
					<router-link :to="{
						name: 'mobile-location-main-panel',
						params: { location_id: location.id }
					}">
						<div class="left p-sm"> {{ location.name }} </div>
						<div class="right white grey1-bg">
							<Icon icon="chevron-right" />
						</div>
						<div class="clear"></div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@components/sub_components/Loader";
import Alert from "@alert";
import TextInput from "@form_components/TextInput";

import AssetsFinderClass from "@src/asset/application/assets-finder";
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "LocationsList",
	components: {
		Loader,
		Alert,
		TextInput
	},
	data() {
		return {
			loading: null,
			error: null,
			filter: null,
			locations: null,
			debounce: null
		};
	},
	async mounted() {
		try {
			this.loading = true;
			await this.set_list_locations();
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async set_list_locations(filter = null) {
			const get_first_x_locations = locations => {
				const number_to_get = 20;
				let result = [];

				let x = 0;
				for (let index in locations) {
					const location = locations[index];

					result.push(location);
					x++;

					if (number_to_get == x) break;
				}

				return result;
			};

			const locations = filter
				? await AssetsFinder.get_user_assets_by_text(filter)
				: await AssetsFinder.get_user_assets();

			this.locations = get_first_x_locations(locations);
		},
		filter_locations(filter_text) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				if (filter_text && filter_text != "")
					this.set_list_locations(filter_text);
				else this.set_list_locations();
			}, 600);
		}
	}
};
</script>

<style lang="css" scoped>
#locations-list {
	height: calc(100% - 55px);
}
.location .left {
	line-height: 30px;
}
.location .right {
	height: 60px;
	line-height: 60px;
}
</style>
