<template lang="html">
	<div>
		<div v-if="loading">
			<Loader size="25" />
		</div>
		<ColumnChart
			v-if="chart_series && chart_categories"
			width="100%"
			height="300px"
			:categories="chart_categories"
			:series="chart_series"
			:y_axis_formatter="(value) => $format_number(value, 0) + ' kWh'"
			:x_axis_formatter="(value) => value"
			:bar_colors="[primary_color]"
			:show_toolbar="false"
		/>
	</div>
</template>

<script>
import Loader from "@loader";
import ColumnChart from "@sub_components/charts/ColumnChart";

import moment from "moment";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();

export default {
	name: "ReportPvProductionChart",
	components: {
		Loader,
		ColumnChart
	},
	props: {
		asset_id: String,
		from: Date,
		to: Date,
		primary_color: String
	},
	data() {
		return {
			loading: true,
			chart_categories: null,
			chart_series: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_monthly_energy =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy(this.asset_id);

			const time_filter_data = asset_monthly_energy.filter((item) => {
				const time = item.time;
				return (
					moment(time).isSameOrAfter(this.from) &&
					moment(time).isSameOrBefore(this.to)
				);
			});

			this.chart_series = [
				{
					name: this.$t("asset_reports.pv_report.page1.energy_production"),
					data: time_filter_data.map((item) => item.energy_production)
				}
			];
			this.chart_categories = time_filter_data.map((item) =>
				this.$format_date_to_month_string(item.time)
			);
		} catch (error) {
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style lang="css" scoped></style>
