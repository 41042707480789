import AssetEnergyForecastFinderInterfaceClass from "../infrastructure/asset-energy-forecast-available-date-range-finder-interface.js";
import EnergyForecastAvailableDateRangeValidator from "../domain/energy-forecast-available-date-range-validator.js";
import StoreUtil from "../../shared/application/utils/store-util.js";

import moment from "moment";
import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = EnergyForecastAvailableDateRangeValidator;
		this.backend_interface = new AssetEnergyForecastFinderInterfaceClass();
		this.store_util = null;
	}

	async get_asset_energy_production_forecast_available_date_range(asset_id) {
		this.store_util = new StoreUtil(
			"asset_energy_production_forecast_available_date_range_" + asset_id
		);

		const cached_data = this.store_util.get_data();
		if (cached_data) return cached_data;

		try {
			let result =
				await this.backend_interface.get_asset_energy_production_forecast_available_date_range(
					asset_id
				);

			if (result) result = this.validator.validate(result);

			this.store_util.set_data(result, moment().add(2, "hours").toDate());

			return result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_production_forecast_available_date_range",
				"general.errors.general_error",
				e
			);
		}
	}
}
