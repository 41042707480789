import Joi from "joi";
import Validator from "@validators/validator";
import { ValidStringsValidation } from "../../../libs/domain/classes/validators/validator-utils";

import EnergyProvidersData from "@src/energy-provider-auth/domain/energy-providers-data.js";
const valid_energy_providers_slugs = Object.keys(EnergyProvidersData);

const energy_data_provider_slug_schema = Joi.string().custom(
	ValidStringsValidation(valid_energy_providers_slugs),
	"Energy data provider slug validation"
);

export default new Validator(energy_data_provider_slug_schema);
