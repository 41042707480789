export default (backend_obj) => {
	function round_number_2_decimals(number) {
		return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
	}

	let result = {
		energy_production:
			backend_obj.energyProduction != null
				? round_number_2_decimals(backend_obj.energyProduction)
				: null,
		energy_consumption:
			backend_obj.energyConsumption != null
				? round_number_2_decimals(backend_obj.energyConsumption)
				: null,
		energy_from_grid:
			backend_obj.energyFromGrid != null
				? round_number_2_decimals(backend_obj.energyFromGrid)
				: null,
		energy_to_grid:
			backend_obj.energyToGrid != null
				? round_number_2_decimals(backend_obj.energyToGrid)
				: null,
		energy_self_consumption:
			backend_obj.energySelfConsumption != null
				? round_number_2_decimals(backend_obj.energySelfConsumption)
				: null,
		time: backend_obj.time ? new Date(backend_obj.time) : null,
		rate_self_consumption: backend_obj.rateSelfConsumption || null,
		rate_self_sufficiency: backend_obj.rateSelfSufficiency || null
	};

	const battery_optional_props = {
		energyToBattery: "energy_to_battery",
		energyFromBattery: "energy_from_battery"
	};
	for (let backend_prop in battery_optional_props) {
		const local_prop_name = battery_optional_props[backend_prop];
		if (backend_obj.hasOwnProperty(backend_prop))
			result[local_prop_name] = backend_obj[backend_prop];
	}

	return result;
};
