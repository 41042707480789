<template>
	<div class="p-b-xl">
		<div class="flex-horizontal-right">
			<router-link
				:to="{
					name: 'user-energy-system-update',
					params: {
						system_id: energy_system.id
					}
				}"
			>
				<button class="btn btn-primary">
					{{ $t("general.edit") }}
				</button>
			</router-link>
		</div>

		<div v-if="energy_provider_auth" class="display-flex m-b-md">
			<div class="font-bold">
				{{ $t("entities.energy_provider_auth.name") }}
			</div>
			<div class="m-l-lg">
				{{ energy_provider_auth.name }}
			</div>
		</div>

		<div id="consumption-data-table">
			<div v-for="item in energy_system_consumption_data" class="display-flex">
				<div class="font-bold">
					{{ item.label }}
				</div>
				<div class="m-l">
					{{ item.value }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EntityServiceClass from "@services/entity-service.js";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";

const EntityService = new EntityServiceClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();

export default {
	name: "ConsumptionEnergySystemInfo",
	props: {
		energy_system: Object
	},
	data() {
		return {
			energy_provider_auth: null
		};
	},
	async mounted() {
		if (
			UserPermissionsUtil.can_user("edit_energy_provider_auth") &&
			this.energy_system.provider_data
		) {
			this.energy_provider_auth =
				await EnergyProviderAuthFinder.get_energy_provider_auth(
					this.energy_system.provider_data.energy_provider_auth_id
				);
		}
	},
	computed: {
		energy_system_consumption_data() {
			const consumption_data = this.energy_system.consumption_data;

			const facility_types = EntityService.get_multilingual_entity(
				"energy_system_facility_options"
			);
			const connection_types = EntityService.get_multilingual_entity(
				"connection_type_options"
			);

			return [
				{
					label: this.$t("entities.energy_system.cups"),
					value: this.energy_system.cups || " - "
				},
				{
					label: this.$t(
						"entities.energy_system.consumption_data.contracted_power"
					),
					value:
						consumption_data && consumption_data.contracted_power
							? consumption_data.contracted_power
							: " - "
				},
				{
					label: this.$t(
						"entities.energy_system.consumption_data.facility_type"
					),
					value:
						consumption_data && consumption_data.facility_type
							? this.$t(facility_types[consumption_data.facility_type])
							: " - "
				},
				{
					label: this.$t(
						"entities.energy_system.consumption_data.connection_type"
					),
					value:
						consumption_data && consumption_data.connection_type
							? this.$t(connection_types[consumption_data.connection_type])
							: " - "
				},
				{
					label: this.$t(
						"entities.energy_system.consumption_data.land_registry_reference"
					),
					value: consumption_data
						? consumption_data.land_registry_reference
						: " - "
				},
				{
					label: this.$t(
						"entities.energy_system.consumption_data.distribution_company"
					),
					value:
						consumption_data && consumption_data.distribution_company
							? consumption_data.distribution_company
							: " - "
				},
				{
					label: this.$t(
						"entities.energy_system.consumption_data.energy_from_grid_price"
					),
					value:
						consumption_data && consumption_data.energy_from_grid_price
							? consumption_data.energy_from_grid_price
							: " - "
				}
			];
		}
	}
};
</script>

<style scoped>
#consumption-data-table {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 15px;
}
</style>
