<template>
	<div class="full-height full-width flex-center-content">
		<Loader />
	</div>
</template>

<script>
import Loader from "@loader";

import UserService from "@services/user-services/user-service";
export default {
	name: "Logout",
	components: {
		Loader
	},
	async created() {
		const user_service = new UserService();
		await user_service.logout();
		this.$router.push({ name: "login" });
	}
};
</script>
