import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_savings_settings(asset_id) {
		const url = "/api/asset/" + asset_id + "/savings/settings";

		const backend_asset_other_data = await this.get(url);

		return BackendObjectConverter(backend_asset_other_data);
	}
}
