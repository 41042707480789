<template>
	<div>
		<div class="font-700 text-uppercase">
			{{
				$t("asset_reports.asset_monthly_savings_report2.page1.annual_summary")
			}}
		</div>
		<div style="display: grid; grid-template-columns: auto max-content">
			<div>
				<ColumnChart
					v-if="chart_formatted_series"
					width="100%"
					height="200px"
					:series="chart_formatted_series"
					:categories="
						asset_last_year_monthly_energy_measurements.map((item) =>
							$format_date_to_month_abbreviated_string(item.time)
						)
					"
					:bar_colors="[
						'var(--custom-asset_monht_report2_yellow)',
						'var(--grey2)'
					]"
					:multiple_y_axis="false"
					:y_axis_formatter="(value) => $format_number(value) + 'kWh'"
					:x_axis_formatter="(value) => value"
					:show_legend="true"
					legend_positon="bottom"
					:legend_font_size_in_px="12"
					column_width="10px"
				/>
			</div>

			<div style="height: calc(100% - 40px)">
				<div class="grid-with-same-rows-height gap-s font-700">
					<div class="flex-vertical-center">
						<div>
							<div class="grey2-color" style="font-size: 16px">
								<span>
									{{ $format_number(total_energy_consumption, 0) }} kWh
								</span>
							</div>
							<div style="font-size: 13px">
								{{
									$t(
										"asset_reports.asset_monthly_savings_report2.page1.total_consumption"
									)
								}}
							</div>
						</div>
					</div>

					<div class="flex-vertical-center">
						<div>
							<div class="grey2-color" style="font-size: 16px">
								<span
									>{{ $format_number(total_energy_production, 0) }} kWh</span
								>
							</div>
							<div style="font-size: 13px">
								{{
									$t(
										"asset_reports.asset_monthly_savings_report2.page1.total_production"
									)
								}}
							</div>
						</div>
					</div>

					<div class="flex-vertical-center" v-if="asset_total_savings">
						<div>
							<div class="grey2-color" style="font-size: 16px">
								<span>{{ $format_number(asset_total_savings, 0) }} €</span>
							</div>
							<div style="font-size: 13px">
								<span>
									{{
										$t(
											"asset_reports.asset_monthly_savings_report2.page1.total_savings"
										)
									}}
									(€)
								</span>
							</div>
						</div>
					</div>

					<div
						class="flex-vertical-center"
						v-if="total_energy_autocosumption_percentage"
					>
						<div>
							<div class="grey2-color" style="font-size: 16px">
								<span
									>{{
										$format_number(total_energy_autocosumption_percentage, 0)
									}}
									%</span
								>
							</div>
							<div style="font-size: 13px">
								<span>
									{{
										$t(
											"asset_reports.asset_monthly_savings_report2.page1.self_consumtption_energy_percentage"
										)
									}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PvMeasuredVariables from "@entities/variables/pv-measured-data";
import ColumnChart from "@sub_components/charts/ColumnChart.vue";

export default {
	name: "AssetTotalSummary",
	components: {
		ColumnChart
	},
	props: {
		asset_last_year_monthly_energy_measurements: Array,
		asset_monthly_energy_measurements: Array,
		asset_total_savings: Number
	},
	data() {
		let total_energy_production = 0,
			total_energy_consumption = 0;
		this.asset_monthly_energy_measurements.forEach((item) => {
			total_energy_production += item.energy_production;
			total_energy_consumption += item.energy_consumption;
		});

		return {
			total_energy_production: total_energy_production,
			total_energy_consumption: total_energy_consumption,
			total_energy_autocosumption_percentage: null
		};
	},
	mounted() {
		const total_energy_self_consumption =
			this.asset_monthly_energy_measurements.reduce((total, data) => {
				return total + data.energy_self_consumption;
			}, 0);

		const total_energy_consumption =
			this.asset_monthly_energy_measurements.reduce((total, data) => {
				return total + data.energy_consumption;
			}, 0);

		this.total_energy_autocosumption_percentage =
			(total_energy_self_consumption / total_energy_consumption) * 100;
	},
	computed: {
		chart_formatted_series() {
			if (!this.asset_last_year_monthly_energy_measurements) return null;

			const pv_variables_to_use = [
				{
					slug: "energy_production",
					multilingual_label:
						"asset_reports.asset_monthly_savings_report2.energy_production"
				},
				{
					slug: "energy_consumption"
				}
			];

			return pv_variables_to_use.map((variable_data) => {
				return {
					name: this.$t(
						variable_data.multilingual_label ||
							PvMeasuredVariables[variable_data.slug].name
					),
					type: "column",
					data: this.asset_last_year_monthly_energy_measurements.map(
						(item) => item[variable_data.slug] || null
					)
				};
			});
		}
	}
};
</script>
