<template lang="html">
	<div v-if="energy_data_providers">
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<Multiselect
			v-else-if="energy_data_providers"
			track_by="id"
			label="name"
			:multiple="multiple ? multiple : false"
			v-model="selected"
			:options="energy_data_providers"
			:allow_select_all="allow_select_all"
		></Multiselect>
	</div>
</template>

<script>
import Loader from "@loader";
import Multiselect from "@form_components/Multiselect";

import EntityServiceClass from "@services/entity-service";
import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";

const EntityService = new EntityServiceClass();
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();

export default {
	name: "EnergyDataProvidersMultiselect",
	props: {
		value: [Array, String],
		multiple: Boolean,
		allow_select_all: Boolean,
		allow_only: [Array, null],
		allow_only_user_providers: Boolean
	},
	components: {
		Loader,
		Multiselect
	},
	data() {
		return {
			loading: false,
			selected: this.value,
			energy_data_providers: null
		};
	},
	mounted() {
		this.set_options();
	},
	methods: {
		async set_options() {
			this.loading = true;

			try {
				async function check_user_has_provider(provider_name) {
					const user_providers =
						await EnergyProviderAuthFinder.get_energy_provider_auths();

					return user_providers.find(
						(provider_data) => provider_data.provider == provider_name
					);
				}

				const energy_data_providers = EntityService.get_entity(
					"energy_data_providers"
				);

				let options = [];
				for (let provider_slug in energy_data_providers) {
					if (this.allow_only && this.allow_only.indexOf(provider_slug) == -1)
						continue;

					if (this.allow_only_user_providers) {
						const user_has_provider = await check_user_has_provider(
							provider_slug
						);
						if (!user_has_provider) continue;
					}

					options.push({
						id: provider_slug,
						name: energy_data_providers[provider_slug].full_name
					});
				}

				this.energy_data_providers = options;
			} catch (error) {
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		selected: function (value) {
			this.$emit("input", value);
		},
		value: function (val) {
			this.selected = val;
		},
		allow_only() {
			this.set_options();
		}
	}
};
</script>

<style lang="css" scoped></style>
