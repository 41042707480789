import SpreadsheetsInterfaceClass from "@interfaces/spreadsheets-interface";

export default class {
	constructor() {
		this.spreedsheets_interface = new SpreadsheetsInterfaceClass();
	}

	convert_array_to_xlsx_content(array_content, file_name) {
		return this.spreedsheets_interface.convert_array_to_xlsx(
			array_content,
			file_name
		);
	}
}
