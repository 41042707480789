<template>
	<div class="white-bg">
		<div class="absolute full-width full-height overflow-y-scroll p-sm">
			<PriceSettings />

			<StatusSettings class="m-t-lg" />

			<FaultyEquipmentSettings class="m-t-lg" />

			<PaidBySettings class="m-t-lg" />
		</div>
	</div>
</template>

<script>
import PriceSettings from "./PriceSettings";
import StatusSettings from "./StatusSettings";
import FaultyEquipmentSettings from "./FaultyEquipmentSettings";
import PaidBySettings from "./PaidBySettings";

export default {
	name: "TicketingSettings",
	components: {
		PriceSettings,
		StatusSettings,
		FaultyEquipmentSettings,
		PaidBySettings
	}
};
</script>
