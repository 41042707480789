<template lang="html">
	<div
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
	>
		<PageHeader :text="$t('general.update')" close_name="user-providers" />
		<div class="p-sm">
			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else-if="error">
				<Alert :message="error" />
			</div>
			<div v-else>
				<div v-if="update_error" class="m-b">
					<Alert :message="update_error" />
				</div>
				<div v-else-if="update_success" class="m-b">
					<Alert type="success" message="general.update_successful" />
				</div>

				<div>
					<div v-if="energy_provider_auth">
						<div class="p-b">
							<div>
								<div>
									<span class="font-bold">
										{{ $tc("user_settings.provider", 1) }}
									</span>
								</div>
								<div class="m-t-xs">
									<EnergyProviderImg
										:provider_name="energy_provider_auth.provider"
									/>
								</div>
							</div>

							<div class="m-t">
								<span class="font-bold">
									{{ $t("entities.energy_provider_auth.name") }}
								</span>
								<TextInput
									class="m-t-xs"
									v-model="energy_provider_auth_data.name"
								/>
							</div>
						</div>

						<DatadisLoginForm
							v-if="energy_provider_auth.provider == 'datadis'"
							v-model="energy_provider_auth_login"
							:editing="true"
						/>

						<div class="m-t flex-horizontal-right">
							<button class="btn btn-primary" v-on:click="update">
								{{ $t("general.save") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Alert from "@alert";
import Loader from "@loader";

import TextInput from "@form_components/TextInput";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";
import DatadisLoginForm from "./energy-providers-login-forms/DatadisLoginForm.vue";

import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";
import EnergyProviderAuthUpdaterClass from "@src/energy-provider-auth/application/energy-provider-auth-updater";

const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();
const EnergyProviderAuthUpdater = new EnergyProviderAuthUpdaterClass();

export default {
	name: "UserEnergyDataProvidersEdit",
	components: {
		PageHeader,
		Loader,
		Alert,
		TextInput,
		EnergyProviderImg,
		DatadisLoginForm
	},
	data() {
		return {
			loading: false,
			error: null,
			update_error: null,
			update_success: null,
			energy_provider_auth: null,
			energy_provider_auth_data: {
				name: null
			},
			energy_provider_auth_login: {}
		};
	},
	mounted() {
		this.load_energy_provider_auth_data();
	},
	methods: {
		async load_energy_provider_auth_data() {
			this.loading = true;

			try {
				const energy_provider_auth_id = this.$route.params.auth_id;
				this.energy_provider_auth =
					await EnergyProviderAuthFinder.get_energy_provider_auth(
						energy_provider_auth_id
					);

				if (this.energy_provider_auth.name)
					this.energy_provider_auth_data.name = JSON.parse(
						JSON.stringify(this.energy_provider_auth.name)
					);
				if (this.energy_provider_auth.extra_data)
					this.energy_provider_auth_login = JSON.parse(
						JSON.stringify(this.energy_provider_auth.extra_data)
					);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		async update() {
			this.loading = true;

			try {
				await EnergyProviderAuthUpdater.energy_provider_auth_data_update(
					this.energy_provider_auth.provider,
					this.energy_provider_auth.id,
					this.energy_provider_auth_data
				);

				if (this.energy_provider_auth_login.extra_data) {
					await EnergyProviderAuthUpdater.energy_provider_auth_extra_data_update(
						this.energy_provider_auth.provider,
						this.energy_provider_auth.id,
						this.energy_provider_auth_login.extra_data
					);
				}

				await this.load_energy_provider_auth_data();

				this.update_success = true;
				setTimeout(() => {
					this.update_success = false;
				}, 5000);
			} catch (error) {
				this.update_error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped></style>
