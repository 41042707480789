<template>
	<div class="full-width full-height p-sm">
		<div class="flex-auto-full-height-content">
			<div class="header p-b-xxs">
				<div class="font-700 font-xxl">
					{{ $t("asset_energy_panel.current_power") }}
				</div>
				<div v-if="current_power_time">
					<span class="font-bold font-xl">
						{{ $t("asset_energy_panel.last_update") }}:
					</span>
					<span>
						{{ $format_date_to_day_hour_minute(current_power_time) }}</span
					>
				</div>
			</div>
			<div class="content relative">
				<AssetCurrentPower
					:asset="{ id: asset_id }"
					v-on:current_power_updated="current_power_updated"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import AssetCurrentPower from "@sub_components/AssetCurrentPower/AssetCurrentPower.vue";

export default {
	name: "AssetCurrentPower2",
	components: {
		AssetCurrentPower
	},
	props: {
		asset_id: String
	},
	data() {
		return {
			current_power_time: null
		};
	},
	methods: {
		current_power_updated(current_power) {
			this.current_power_time = current_power ? current_power.time : null;
		}
	}
};
</script>

<style lang="css" scoped></style>
