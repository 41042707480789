<template>
	<div class="full-width full-height">
		<div v-if="loading" class="full-height flex-center-contet">
			<Loader size="30" />
		</div>
		<div v-else id="asset-pv-data" class="full-height">
			<div
				id="top-box"
				class="box-shadow white-bg rounded-s p-t p-b text-center"
			>
				<div
					class="flex-center-content border-r font-700 font-xl pv-data-label"
				>
					{{ $t("asset_energy_panel.nominal_power") }}
				</div>
				<div class="flex-center-content font-700 font-xl pv-data-label">
					{{ $t("asset_energy_panel.peak_power") }}
				</div>
				<div class="flex-center-content border-r font-l">
					{{
						asset_pv_data
							? $format_number(asset_pv_data.nominal_power, 2) + " kW"
							: "-"
					}}
				</div>
				<div class="flex-center-content font-l">
					{{
						asset_pv_data
							? $format_number(asset_pv_data.peak_power, 2) + " kWp"
							: "-"
					}}
				</div>
			</div>

			<div id="botton-box" class="box-shadow white-bg rounded-s p-sm">
				<div class="font-700 font-xl">
					{{ $t("asset_energy_panel.pv_energy_production") }}
				</div>
				<div class="flex-center-content">
					<Icon size="60" icon="solar-panel" />
				</div>
				<div class="text-center font-700 font-l">
					{{ $t("asset_energy_panel.total_aggregated_energy") }}
				</div>
				<div class="text-center font-l">
					{{
						asset_total_producction != null
							? $format_number(asset_total_producction, 0) + " kWh"
							: "-"
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";

import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder.js";
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();

import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder.js";
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();

export default {
	name: "AssetPvData",
	props: {
		asset_id: String
	},
	components: {
		Loader
	},
	data() {
		return {
			loading: true,
			error: null,
			asset_pv_data: null,
			asset_total_producction: null
		};
	},
	mounted() {
		this.set_data();
	},
	methods: {
		async set_data() {
			this.loading = true;
			this.error = null;

			try {
				await this.set_asset_pv_data();
				await this.set_asset_total_production();
			} catch (error) {
				setTimeout(() => {
					this.set_data();
				}, 60000);
			} finally {
				this.loading = false;
			}
		},
		async set_asset_pv_data() {
			try {
				const asset_energy_systems =
					await AssetEnergySystemsFinder.get_asset_energy_systems(
						this.asset_id,
						true
					);

				if (asset_energy_systems.length > 0) {
					let total_peak_power = 0;
					let total_nominal_power = 0;

					asset_energy_systems.forEach((energy_system) => {
						if (energy_system.pv_data) {
							total_peak_power += energy_system.pv_data.peak_power;
							total_nominal_power += energy_system.pv_data.nominal_power;
						}
					});

					this.asset_pv_data = {
						peak_power: total_peak_power,
						nominal_power: total_nominal_power
					};
				}

				setTimeout(() => {
					this.set_asset_pv_data();
				}, 43200000);
			} catch (error) {
				setTimeout(() => {
					this.set_asset_pv_data();
				}, 21600000);
			}
		},
		async set_asset_total_production() {
			try {
				const asset_monthly_energy =
					await AssetMonthlyEnergyFinder.get_asset_monthly_energy(
						this.asset_id
					);

				var total_energy_production = 0;
				if (asset_monthly_energy.length > 0) {
					asset_monthly_energy.forEach((item) => {
						total_energy_production += item.energy_production;
					});
				}

				this.asset_total_producction =
					total_energy_production != 0 ? total_energy_production : null;

				setTimeout(() => {
					this.set_asset_total_production();
				}, 21600000);
			} catch (error) {
				setTimeout(() => {
					this.set_asset_total_production();
				}, 60000);
			}
		}
	}
};
</script>

<style lang="css" scoped>
#asset-pv-data {
	display: grid;
	grid-template-rows: 1fr 2fr;
	row-gap: 15px;
}
#top-box {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
}
#botton-box {
	display: grid;
	grid-template-rows: min-content auto min-content min-content;
}
.pv-data-label {
	word-spacing: 9999rem;
	line-height: 22px;
}
</style>
