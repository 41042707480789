import BackendInterface from "@src/shared/infrastructure/backend-interface";
import EnergyProviderFinderInterfaceClass from "./energy-provider-auth-finder-interface";
import LocalEnergyProviderAuthConverter from "./converters/local-energy-provider-auth-creator-converter";

export default class extends BackendInterface {
	constructor() {
		super();

		this.energy_provider_finder_interface =
			new EnergyProviderFinderInterfaceClass();
	}

	async energy_provider_auth_create(provider, params) {
		const url = "/api/providers/" + provider;

		const converted_params = LocalEnergyProviderAuthConverter(provider, params);

		await this.post(url, { body_params: converted_params });

		const energy_provider_auths =
			await this.energy_provider_finder_interface.get_energy_provider_auths();

		return energy_provider_auths && energy_provider_auths.length > 0
			? energy_provider_auths[energy_provider_auths.length - 1]
			: null;
	}
}
