import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendUserConverter from "@interfaces/backend-interfaces/converters/backend-user-converter";
import UserConverter from "@interfaces/backend-interfaces/converters/user-converter";

export default class UserBackendInterface extends BackendInterface {
	constructor(
		backend_user_converter = new BackendUserConverter(),
		user_converter = new UserConverter()
	) {
		super();
		this.backend_user_converter = backend_user_converter;
		this.user_converter = user_converter;
	}

	async user_login(email, password) {
		const url = "/auth/login";
		const params = {
			email: email,
			password: password
		};

		const result = await this.post(url, { body_params: params });

		return result;
	}

	async get_user() {
		const url = "/api/user";
		const backend_user = await this.get(url);
		return this.backend_user_converter.convert_backend_user(backend_user);
	}

	async get_user_token(user) {
		const url = "/api/loginas/" + user.id;
		const result = await this.get(url);

		return result;
	}

	async upload_plants_images_watermark(watermark) {
		const url = "/api/supervisor/settings/assets-watermark";
		return await this.post(url, {
			files: { watermark: watermark },
			params: { scaleInPicture: "20" }
		});
	}

	async change_user_password(user_new_password) {
		const url = "/api/user/change-password";

		const result = await this.post(url, {
			body_params: { password: user_new_password }
		});

		return result;
	}

	async change_user_settings(user) {
		const url = "/api/user/profile";

		const result = await this.put(url, {
			body_params: {
				firstName: user.first_name,
				lastName: user.last_name,
				company: user.company,
				customColor: user.custom_color || null,
				unitSystem: user.unit_system || null,
				language: user.language || null
			}
		});

		return result;
	}

	async create_user(new_user) {
		const backend_new_user =
			this.user_converter.convert_user_to_backend(new_user);

		const url = "/api/admin/user";
		const returned_user = await this.post(url, {
			body_params: backend_new_user
		});

		const created_user =
			this.backend_user_converter.convert_backend_user(returned_user);
		return created_user;
	}

	async get_admin_data() {
		const url = "/api/user/admin-profile";
		const backend_admin_data = await this.get(url);

		return {
			id: backend_admin_data.id,
			first_name: backend_admin_data.firstName,
			last_name: backend_admin_data.lastName || null,
			email: backend_admin_data.email || null,
			company: backend_admin_data.company || null,
			avatar: backend_admin_data.avatar || null,
			custom_interface: {
				assets_watermark: backend_admin_data.customInterface
					? backend_admin_data.customInterface.assetWatermark
					: null,
				custom_color: backend_admin_data.customInterface
					? backend_admin_data.customInterface.customColor
					: null
			}
		};
	}
}
