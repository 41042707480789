import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_settings_savings(
		energy_system_id,
		energy_system_savings_settings
	) {
		let backend_savings_settings = {
			energyFromGridPriceRateId:
				energy_system_savings_settings.energy_from_grid_price_rate_id || null
		};

		backend_savings_settings.customPrices =
			energy_system_savings_settings.custom_price_types &&
			energy_system_savings_settings.custom_price_types.length > 0
				? energy_system_savings_settings.custom_price_types
				: [];

		const url =
			"/api/energy-data/systems/" + energy_system_id + "/savings-settings";
		const backend_updated_data = await this.put(url, {
			body_params: backend_savings_settings
		});

		return BackendEnergySystemConverter(backend_updated_data);
	}
}
