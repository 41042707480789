import AlertUpdaterInterfaceClass from "../infrastructure/alert-updater-interface.js";
import AlertUpdaterValidator from "../domain/alert-updater-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AlertUpdaterValidator;
		this.backend_interface = new AlertUpdaterInterfaceClass();
	}

	async update_alert(alert_id, alert_data_to_update) {
		try {
			alert_data_to_update = this.validator.validate(alert_data_to_update);

			await this.backend_interface.update_alert(alert_id, alert_data_to_update);

			return true;
		} catch (e) {
			throw new AppError(
				"error_update_alert",
				"general.errors.general_error",
				e
			);
		}
	}
}
