import UserFinderClass from "../user-finder";

export default class {
	constructor() {
		this.user_finder = new UserFinderClass();

		this.permissions = {
			help_full_menu: {
				allowed_roles: ["supervisor", "maintainer", "manager"]
			},
			admin_menu: {
				allowed_roles: ["admin"]
			},
			create_asset: {
				allowed_roles: ["supervisor", "manager"]
			},
			remove_asset: {
				allowed_roles: ["supervisor", "manager"]
			},
			assets_dashboard: {
				allowed_roles: ["supervisor", "manager", "maintainer"]
			},
			edit_asset_info: {
				allowed_roles: ["admin", "supervisor", "maintainer", "manager"]
			},
			edit_asset_guests: {
				allowed_roles: ["supervisor", "manager"]
			},
			edit_asset_maintainers: {
				allowed_roles: ["supervisor", "manager"]
			},
			pr_tool: {
				allowed_roles: ["maintainer", "supervisor", "manager"]
			},
			ticketing: {
				allowed_roles: ["maintainer", "supervisor", "manager"]
			},
			remove_ticket: {
				allowed_roles: ["supervisor", "manager"]
			},
			edit_ticketing_parameters: {
				allowed_roles: ["supervisor", "manager"]
			},
			have_email_notifications: {
				allowed_roles: ["supervisor", "manager"]
			},
			user_settings: {
				allowed_roles: ["admin", "supervisor", "manager", "maintainer", "guest"]
			},
			have_alerts: {
				allowed_roles: ["supervisor", "manager"]
			},
			edit_energy_provider_auth: {
				allowed_roles: ["supervisor"]
			},
			manage_user_energy_systems: {
				allowed_roles: ["supervisor", "manager"]
			},
			edit_locations_watermark: {
				allowed_roles: ["supervisor", "manager"]
			},
			asset_reports: {
				allowed_roles: ["supervisor", "manager"]
			},
			pr_tool: {
				allowed_roles: ["supervisor", "manager", "maintainer"]
			},
			edit_own_custom_color: {
				allowed_roles: ["supervisor", "manager"]
			},
			edit_location_energy_data_config: {
				allowed_roles: ["supervisor", "manager"]
			},
			manage_users: {
				allowed_roles: ["supervisor", "manager"]
			}
		};
	}

	can_user(permission) {
		if (!this.permissions[permission]) return false;

		const permission_allowed_roles = this._permission_roles(permission);

		return this._user_has_role(permission_allowed_roles);
	}

	_permission_roles(permission) {
		return this.permissions[permission].allowed_roles;
	}

	_user_has_role(roles) {
		const allowed_roles = Array.isArray(roles) ? roles : [roles];

		const user = this.user_finder.get_stored_user();
		if (!user) return false;

		for (var key in allowed_roles) {
			if (user.roles.indexOf(allowed_roles[key]) != -1) return true;
		}
		return false;
	}
}
