import BackendInterface from "@src/shared/infrastructure/backend-interface";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_monthly_reanalysis_weaher(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/reanalysis/weather/monthly";
		const backend_result = await this.get(url, {
			query_params: {
				monthFrom: moment(from).format("YYYY-MM"),
				monthTo: moment(to).format("YYYY-MM")
			}
		});

		const local_asset_monthly_reanalysis_weaher =
			this._convert_backend_reanalysis_weather(backend_result);

		return local_asset_monthly_reanalysis_weaher.filter(
			(item) =>
				moment(item.time).isSameOrAfter(from, "month") &&
				moment(item.time).isSameOrBefore(to, "month")
		);
	}

	_convert_backend_reanalysis_weather(backend_reanalysis_weather) {
		return backend_reanalysis_weather.data.map((item) => {
			return {
				time: new Date(item.time),
				ghi: item.hasOwnProperty("ghi") ? item.ghi : null
			};
		});
	}
}
