<template lang="html">
	<div>
		<div>
			<span>{{ $t("entities.energy_provider_auth.auth.username") }}</span>
			<input
				class="form-control m-t-xs"
				type="text"
				v-model="username"
				v-on:change="emit_value"
				:disabled="editing ? true : false"
				:placeholder="editing ? '********' : null"
			/>
		</div>
		<div class="m-t">
			<span>{{ $t("entities.energy_provider_auth.auth.password") }}</span>
			<input
				class="form-control m-t-xs"
				type="password"
				v-model="password"
				v-on:change="emit_value"
				:disabled="editing ? true : false"
				:placeholder="editing ? '********' : null"
			/>
		</div>

		<hr />

		<div class="m-t">
			<div>
				<div class="left">
					{{ $t("entities.energy_provider_auth.auth.authorized_nifs") }}
				</div>
				<div class="left m-l-xs">
					<div v-on:click="add_authorized_nif">
						<RoundedButton icon="plus" :size="25" />
					</div>
				</div>
				<div class="clear"></div>
			</div>
			<div v-if="authorized_nifs">
				<div v-for="(nif_data, index) in authorized_nifs" class="display-flex">
					<input
						class="form-control m-t-xs"
						type="text"
						v-model="nif_data.value"
						v-on:change="emit_value"
					/>
					<div
						class="flex-center-content p-l-xs p-r-xs cursor-pointer"
						v-on:click="remove_authorized_nif(index)"
					>
						<Icon class="danger-color" icon="trash" size="25" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RoundedButton from "@form_components/buttons/RoundedButton";

export default {
	name: "DatadisLoginForm",
	components: {
		RoundedButton
	},
	props: { value: Object },
	data() {
		return {
			editing: this.value && this.value.authorized_nifs ? true : false,
			username: null,
			password: null,
			authorized_nifs:
				this.value && this.value.authorized_nifs
					? this.value.authorized_nifs.map((item) => {
							return {
								value: item
							};
					  })
					: null
		};
	},
	methods: {
		emit_value() {
			let value_to_emit = {};

			if (!this.editing)
				value_to_emit = {
					username: this.username,
					password: this.password
				};

			if (this.authorized_nifs) {
				let authorized_nifs = [];

				this.authorized_nifs.forEach((nif_data) => {
					if (nif_data.value && nif_data.value != "")
						authorized_nifs.push(nif_data.value);
				});

				if (authorized_nifs.length > 0)
					value_to_emit.extra_data = {
						authorized_nifs: authorized_nifs
					};
			}

			this.$emit("input", value_to_emit);
		},
		add_authorized_nif() {
			if (this.authorized_nifs == null) this.authorized_nifs = [];

			this.authorized_nifs.push({
				value: null
			});
		},
		remove_authorized_nif(index) {
			this.authorized_nifs.splice(index, 1);
			this.emit_value();
		}
	},
	watch: {
		username() {
			this.emit_value();
		},
		password() {
			this.emit_value();
		},
		authorized_nifs() {
			this.emit_value();
		}
	}
};
</script>

<style lang="css" scoped></style>
