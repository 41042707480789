<template>
	<div class="full-width full-height white-bg p-lg rounded-m box-shadow">
		<div class="border-b">
			<span class="font-700 font-xl">
				{{ $t("user_settings.email_notifications_settings.title") }}
			</span>
		</div>

		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else-if="mounted_error" class="m-t">
			<Alert :message="mounted_error" />
		</div>
		<div v-else>
			<div v-if="update_error" class="m-t">
				<Alert :message="update_error" />
			</div>
			<div v-else-if="success" class="m-t">
				<Alert message="general.update_successful" type="success" />
			</div>

			<div class="m-t-xl display-flex-space-between">
				<div>
					<div
						class="font-700 font-xl"
						v-bind:class="{
							'grey2-color': !email_notifications_settings.enabled
						}"
					>
						{{ $t("entities.email_notifications_settings.enabled") }}
					</div>
					<div class="grey2-color font-l m-t-xs">
						{{
							$t("entities.email_notifications_settings.enabled_description")
						}}
					</div>
				</div>
				<div class="flex-vertical-center">
					<CustomCheckbox v-model="email_notifications_settings.enabled" />
				</div>
			</div>

			<div
				class="m-t-xl p-l p-r"
				v-bind:class="{ 'grey2-color': !email_notifications_settings.enabled }"
			>
				<div class="font-700 font-xl">
					{{ $t("entities.email_notifications_settings.frequency") }}
				</div>

				<div class="display-grid gap-m">
					<div v-for="frequency in frequency_options" class="display-flex m-t">
						<div>
							<RadioButton
								v-model="email_notifications_settings.frequency"
								:radio_value="frequency"
								:disabled="!email_notifications_settings.enabled"
							/>
						</div>
						<div class="m-l display-grid gap-s">
							<div class="font-700">
								{{
									$t(
										"entities.email_notifications_settings.frequency_options." +
											frequency
									)
								}}
							</div>
							<div class="grey2-color line-height-xl">
								{{
									$t(
										"entities.email_notifications_settings.frequency_options." +
											frequency +
											"_description"
									)
								}}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="m-t-xl p-t border-t flex-horizontal-right">
				<button
					class="btn btn-primary"
					v-on:click="update_email_notifications_settings"
				>
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import CustomCheckbox from "@form_components/CustomCheckbox.vue";
import RadioButton from "@form_components/RadioButton.vue";

import EmailNotificationsSettingsFinderClass from "@src/user/application/email-notifications-settings-finder.js";
import EmailNotificationsSettingsUpdaterClass from "@src/user/application/email-notifications-settings-updater.js";

const EmailNotificationsSettingsFinder =
	new EmailNotificationsSettingsFinderClass();
const EmailNotificationsSettingsUpdater =
	new EmailNotificationsSettingsUpdaterClass();

export default {
	name: "EmailNotificationsSettings",
	components: {
		Loader,
		Alert,
		CustomCheckbox,
		RadioButton
	},
	data() {
		return {
			loading: true,
			mounted_error: null,
			success: false,
			update_error: null,
			frequency_options: ["hourly", "daily", "weekly"],
			email_notifications_settings: {
				enabled: false,
				frequency: null
			}
		};
	},
	async mounted() {
		try {
			this.loading = true;

			this.email_notifications_settings =
				await EmailNotificationsSettingsFinder.get_email_notifications_settings();
		} catch (error) {
			this.mounted_error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async update_email_notifications_settings() {
			try {
				this.loading = true;

				const duplicated_email_notifications_settings = Object.assign(
					{},
					this.email_notifications_settings
				);
				this.email_notifications_settings =
					await EmailNotificationsSettingsUpdater.update_email_notifications_settings(
						duplicated_email_notifications_settings
					);

				this.success = true;
				setTimeout(() => {
					this.success = null;
				}, 5000);
			} catch (error) {
				this.update_error = error;
				setTimeout(() => {
					this.update_error = null;
				}, 5000);
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		"email_notifications_settings.enabled"(new_value) {
			if (!new_value) this.email_notifications_settings.frequency = null;
		}
	}
};
</script>

<style>
input[type="radio"] {
	-ms-transform: scale(1.5); /* IE 9 */
	-webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
	transform: scale(1.5);
	accent-color: green;
}
</style>
