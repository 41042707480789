import AssetsGroupUpdaterInterfaceClass from "../infrastructure/assets-group-updater-interface.js";
import AssetsGroupCreatorValidator from "../domain/assets-group-creator-validator.js";
import AssetsGroupFinderValidator from "../domain/assets-group-finder-validator.js";

import AssetsGroupsStoreUtil from "./utils/assets-groups-store-util.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetsGroupCreatorValidator;
		this.finder_validator = AssetsGroupFinderValidator;
		this.backend_interface = new AssetsGroupUpdaterInterfaceClass();
		this.store_util = AssetsGroupsStoreUtil;
	}

	async update_assets_group(id, assets_group) {
		const validated_assets_group_to_create =
			this.validator.validate(assets_group);

		try {
			const interface_result = await this.backend_interface.update_assets_group(
				id,
				validated_assets_group_to_create
			);

			const validated_data = this.finder_validator.validate(interface_result);

			this.store_util.add_or_update_item(validated_data);
			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_update_assets_group",
				"general.errors.general_error",
				e
			);
		}
	}
}
