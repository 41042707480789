import EnergyMeasurementValidator from "../domain/energy-measurement-validator";
import AssetMonthlyEnergyFinderInterfaceClass from "../infrastructure/asset-monthly-energy-finder-interface";
import AssetEnergyAvailableDateFinderClass from "./asset-energy-available-date-finder.js";

import StoreUtilClass from "@src/shared/application/utils/store-util.js";
import TimeSeriesUtil from "@src/shared/application/utils/time-series-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new AssetMonthlyEnergyFinderInterfaceClass();
		this.energy_measurement_validator = EnergyMeasurementValidator;

		this.store_util = new StoreUtilClass(
			"assets_monthy_energy_data",
			null,
			"asset_id"
		);

		this.asset_energy_available_date_finder =
			new AssetEnergyAvailableDateFinderClass();
	}

	async get_asset_monthly_energy(asset_id, force_download = null) {
		const stored_monthly_energy = await this.store_util.get_cached_item_by_id(
			asset_id
		);

		if (!force_download && stored_monthly_energy)
			return stored_monthly_energy.data;

		try {
			const asset_energy_data_since =
				await this.asset_energy_available_date_finder.get_asset_energy_data_first_date(
					asset_id
				);

			const asset_energy_data_to =
				await this.asset_energy_available_date_finder.get_asset_energy_data_last_date(
					asset_id
				);

			if (!asset_energy_data_since || !asset_energy_data_to) return null;

			const result = await this.get_asset_monthly_energy_by_date(
				asset_id,
				asset_energy_data_since,
				asset_energy_data_to
			);

			const item_to_store = {
				asset_id: asset_id,
				data: result
			};

			const previous_stored_data = await this.store_util.get_data();
			if (previous_stored_data) {
				await this.store_util.set_data(
					[item_to_store],
					moment().add(4, "hours").toDate()
				);
			} else {
				await this.store_util.add_or_update_item(item_to_store);
			}

			return result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_monthly_energy",
				"general.errors.general_error"
			);
		}
	}

	async get_asset_monthly_energy_by_date(asset_id, from = null, to = null) {
		try {
			if (!from)
				from =
					await this.asset_energy_available_date_finder.get_asset_energy_data_first_date(
						asset_id
					);

			if (!to)
				to =
					await this.asset_energy_available_date_finder.get_asset_energy_data_last_date(
						asset_id
					);

			const interface_data =
				await this.backend_interface.get_asset_monthly_energy(
					asset_id,
					from,
					to
				);

			let validated_data = [];
			interface_data.forEach((item) => {
				const validated_item = this.energy_measurement_validator.validate(item);
				validated_data.push(validated_item);
			});

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_monthly_energy_by_date",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_asset_aggregated_energy_by_date(asset_id, from, to) {
		const asset_monthly_energy_by_date =
			await this.get_asset_monthly_energy_by_date(asset_id, from, to);

		return TimeSeriesUtil.time_serie_aggregate(asset_monthly_energy_by_date, [
			"time"
		]);
	}
}
