import BackendInterface from "@src/shared/infrastructure/backend-interface";
import LocalObjectConverter from "@src/shared/infrastructure/backend-converters/local-object-converter.js";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_asset_savings_settings(asset_id, asset_savings_settings) {
		const url = "/api/asset/" + asset_id + "/savings/settings";

		let backend_data = LocalObjectConverter(asset_savings_settings);

		if (!backend_data.energyFromGridPrice.energyFromGridPriceRateId) {
			backend_data.energyFromGridPrice = null;
		} else if (
			backend_data.energyFromGridPrice &&
			backend_data.energyFromGridPrice.customPrices
		) {
			backend_data.energyFromGridPrice.customPrices =
				backend_data.energyFromGridPrice.customPrices.filter(
					(item) => item.price != null
				);
		}

		const backend_asset_other_data = await this.put(url, {
			body_params: backend_data
		});

		return BackendObjectConverter(backend_asset_other_data);
	}
}
