import BackendInterface from "@src/shared/infrastructure/backend-interface";

import UserRoleConverter from "@src/user/infrastructure/converters/user-role-converter.js";
import BackendUserConverter from "@src/user/infrastructure/converters/backend-user-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_users_by_role(users_role) {
		const url = "/api/users";
		const backend_role =
			UserRoleConverter.convert_local_to_backend_user_role(users_role);

		const backend_users = await this.get_items_with_offset(url, 2, {
			query_params: {
				hasRole: backend_role
			}
		});
		const local_users = backend_users.map((item) => BackendUserConverter(item));

		return local_users;
	}
}
