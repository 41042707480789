import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendPowerMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-power-measurement-converter";

import RestError from "@classes/errors/rest_error";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_current_power(asset_id) {
		const url = "/api/asset/" + asset_id + "/power-data/instant";

		try {
			const backend_instant_power = await this.get(url);

			const converted_instant_power = BackendPowerMeasurementConverter(
				backend_instant_power
			);

			return converted_instant_power;
		} catch (e) {
			if (e instanceof RestError && e.code == 409) return null;
			else throw e;
		}
	}
}
