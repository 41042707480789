<template>
	<div class="full-width full-height grey3-bg">
		<div class="flex-auto-full-height-content">
			<div class="header p-b">
				<div
					class="flex-vertical-center full-width white-bg p-sm rounded-s box-shadow"
				>
					<router-link :to="{ name: 'admin-users' }">
						<div>
							<Icon icon="chevron-left" />
						</div>
					</router-link>

					<div
						class="m-l"
						v-bind:class="{
							'primary-color': current_route_name == 'admin-user-energy-systems'
						}"
					>
						<router-link :to="{ name: 'admin-user-energy-systems' }">
							<span>
								{{
									$tc("user_settings.energy_systems_manager.energy_system", 2)
								}}
							</span>
						</router-link>
					</div>
				</div>
			</div>

			<div
				class="content white-bg rounded-s box-shadow"
				v-if="current_route_name != 'admin-user-edit'"
			>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Users",
	computed: {
		current_route_name() {
			return this.$route.name;
		}
	}
};
</script>
