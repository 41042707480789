import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

export default (backend_energy_provider_auth) => {
	function _convert_backend_provider_name(backend_provider_name) {
		return BackendEntityConverter.convert_backend_value_to_local(
			"energy_measurement_provider_names",
			backend_provider_name
		);
	}

	let local_energy_provider_auth = {
		id: backend_energy_provider_auth.id,
		provider: _convert_backend_provider_name(
			backend_energy_provider_auth.providerName
		),
		name: backend_energy_provider_auth.name || null,
		systems_number: backend_energy_provider_auth.count,
		is_valid: backend_energy_provider_auth.isValid,
		last_update: new Date(backend_energy_provider_auth.updatedAt)
	};

	if (
		backend_energy_provider_auth.extraData &&
		backend_energy_provider_auth.extraData != {}
	) {
		local_energy_provider_auth.extra_data = {};

		if (backend_energy_provider_auth.extraData.authorizedNifs)
			local_energy_provider_auth.extra_data.authorized_nifs =
				backend_energy_provider_auth.extraData.authorizedNifs;
	}

	return local_energy_provider_auth;
};
