import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_system_data_first_date(system_id) {
		const url =
			"/api/energy-data/systems/" + system_id + "/processed-data-first-day";
		const result = await this.get(url);

		return {
			historical_first_day: new Date(result.historicalFirstDay)
		};
	}
}
