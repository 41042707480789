import EnergySystemCustomHourlyValidator from "../../domain/energy-system-custom-monthly-editor-validator.js";
import EnergySystemCustomMonthlyEnergyUpdaterInterfaceClass from "../../infrastructure/energy-data/energy-system-custom-monthly-energy-updater-interface.js";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

import AppError from "@classes/errors/app_error";
import { store_entity_names } from "@config";

export default class {
	constructor() {
		this.validator = EnergySystemCustomHourlyValidator;
		this.backend_interface =
			new EnergySystemCustomMonthlyEnergyUpdaterInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
	}

	async update_energy_system_custom_hourly(system_id, data_to_update) {
		const validated_data = this.validator.validate(data_to_update);

		try {
			await this.backend_interface.update_energy_system_custom_hourly(
				system_id,
				validated_data
			);

			const store_entity_name =
				store_entity_names.locations_monthy_energy_energy;
			this.store_interface.delete_entity_values(store_entity_name);

			return true;
		} catch (e) {
			throw new AppError(
				"error_update_energy_system_custom_hourly",
				"general.errors.general_error",
				e
			);
		}
	}
}
