//VueJS plugins doc: https://vuejs.org/v2/guide/plugins.html
export default {
	install: function (app, options) {
		app.config.globalProperties.$is_extra_small_device = function () {
			const current_screen_width = window.innerWidth;

			return current_screen_width < 576;
		};

		app.config.globalProperties.$is_small_device = function () {
			const current_screen_width = window.innerWidth;

			return current_screen_width >= 576 && current_screen_width < 768;
		};

		app.config.globalProperties.$is_medium_device = function () {
			const current_screen_width = window.innerWidth;

			return current_screen_width >= 768 && current_screen_width < 992;
		};

		app.config.globalProperties.$is_large_device = function () {
			const current_screen_width = window.innerWidth;

			return current_screen_width >= 992 && current_screen_width < 1200;
		};

		app.config.globalProperties.$is_extra_large_device = function () {
			const current_screen_width = window.innerWidth;

			return current_screen_width >= 1200;
		};
	}
};
