<template>
	<div
		class="grey1 full-rounded text-center cursor-pointer rounded-button flex-center-content"
		v-bind:style="styles"
	>
		<Icon :icon="icon" />
	</div>
</template>

<script>
export default {
	name: "RoundedButton",
	props: {
		icon: String,
		size: Number
	},
	computed: {
		styles() {
			const size = this.size || 30;

			return {
				width: size + "px",
				height: size + "px",
				"line-height": size + "px"
			};
		}
	}
};
</script>

<style scoped>
.rounded-button {
	border: 1px solid var(--grey1);
}
</style>
