import axios from "axios";
import RestInterfaceClass from "@interfaces/rest_interface";
import { map_data } from "@config";

const RestInterface = new RestInterfaceClass(false);

class Geosearch {
	async query_coordinates(lat, lng) {
		const url =
			"https://api.mapbox.com/geocoding/v5/mapbox.places/" +
			lng +
			"," +
			lat +
			".json?types=place&access_token=" +
			map_data.mapbox_token;

		try {
			const response = await axios.get(url);
			if (response.status === 200) {
				if (
					!response.data.hasOwnProperty("features") ||
					response.data.features.length == 0
				) {
					return {
						name: "Unknown",
						full_name: "Unknown"
					};
				} else {
					return this.format_query_feature(response.data.features[0]);
				}
			} else throw new Error("Error in geosearch");
		} catch (error) {
			throw new Error("Error in geosearch");
		}
	}

	async address_search(query) {
		if (query == "") return "";

		const url = "https://maps.googleapis.com/maps/api/geocode/json";
		try {
			const response = await RestInterface.get(url, {
				address: query,
				key: map_data.google_geocoding_api_token
			});

			const response_results = response.results;

			let formatted_response = [];
			response_results.forEach((item) => {
				formatted_response.push({
					full_name: item.formatted_address,
					coordinates: {
						lat: item.geometry.location.lat,
						lon: item.geometry.location.lng
					}
				});
			});
			return formatted_response;
		} catch (e) {
			throw new Error("Error in geosearch");
		}
	}

	async search_timezone(lat, lon) {
		const url =
			"https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/" +
			lon +
			"," +
			lat +
			".json?access_token=" +
			map_data.mapbox_token;

		try {
			const response = await axios.get(url);
			if (response.status === 200) {
				if (
					response.data.hasOwnProperty("features") &&
					response.data.features.length > 0
				) {
					return response.data.features[0].properties.TZID;
				} else {
					return null;
				}
			} else throw new Error("Error in geosearch");
		} catch (error) {
			throw new Error("Error in geosearch");
		}
	}
}

export default new Geosearch();
