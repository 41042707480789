import UsersByRoleFinderInterface from "../infrastructure/owned-users-finder-interface";
import UserValidator from "../domain/user-finder-validator";
import OwnedUsersStoreUtilClass from "./utils/owned-users-store-util";

import moment from "moment";
import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new UsersByRoleFinderInterface();
		this.validator = UserValidator;
	}

	async get_owned_users_by_role(role, force_download = false) {
		const store_util = new OwnedUsersStoreUtilClass(role);

		try {
			let users;
			if (!force_download) {
				users = await store_util.get_items();
				if (users) return users;
			}

			users = await this.backend_interface.get_owned_users_by_role(role);
			const validated_users = users.map((user) =>
				this.validator.validate(user)
			);

			await store_util.save_items(
				validated_users,
				moment().add(1, "days").toDate()
			);

			return validated_users;
		} catch (e) {
			throw new AppError(
				"error_get_owned_users_by_role",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_owned_user(role, user_id) {
		const owned_users_by_role = await this.get_owned_users_by_role(role);

		return owned_users_by_role.find((user) => user.id == user_id);
	}

	async get_owned_users_by_allowed_asset_id(role, asset_id) {
		const owned_users_by_role = await this.get_owned_users_by_role(role);

		return owned_users_by_role.filter(
			(user) =>
				user.has_access_all_assets ||
				user.allowed_assets.indexOf(asset_id) != -1
		);
	}
}
