<template lang="html">
	<div class="full-width relative white-bg">
		<Header :title="$t('asset_reports.asset_monthly_savings_report.title')" />

		<div v-if="loading" class="m-t-xl">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else>
			<div class="m-t-xs">
				<span class="asset-monthly-savings-report-title">
					{{
						$t(
							"asset_reports.asset_monthly_savings_report.page2.year_monthly_energy_data"
						)
					}}
				</span>
				<span style="font-weight: bold; font-size: 17px"> kWh </span>
			</div>

			<EnergyConstumptionMonthlyChart
				v-if="year_monthly_energy"
				:monthly_energy="year_monthly_energy"
				:asset_reports_color="asset_reports_color"
				:to="filter.month"
				:height_in_px="350"
				:asset="asset"
			/>

			<MonthDailyConsumptionChart
				class="m-t-xl"
				:asset_id="asset.id"
				:month="filter.month"
				:asset_reports_color="asset_reports_color"
				:height_in_px="350"
			/>

			<Footer :page_number="'2/' + (comment ? '4' : '3')" />
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Header from "../../reports-shared-components/Header.vue";
import Footer from "../../reports-shared-components/Footer.vue";

import EnergyConstumptionMonthlyChart from "./charts/EnergyConstumptionMonthlyChart.vue";
import MonthDailyConsumptionChart from "./charts/MonthDailyConsumptionChart.vue";

import moment from "moment";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();

export default {
	name: "AssetMonhtlyReportPage2",
	components: {
		Loader,
		Alert,
		Header,
		Footer,
		EnergyConstumptionMonthlyChart,
		MonthDailyConsumptionChart
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object,
		comment: String || null
	},
	data() {
		return {
			testing: "dale dale<sub>2</sub>",
			loading: true,
			error: null,
			year_monthly_energy: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.year_monthly_energy = await this.get_year_monthly_energy();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async get_year_monthly_energy() {
			const monthly_energy_data =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy(this.asset.id);

			return monthly_energy_data.filter((item) =>
				moment(item.time).isSame(this.filter.month, "year")
			);
		}
	}
};
</script>

<style lang="css" scoped>
.equivalent-box {
	background-color: #e6e6e7;
	border-radius: 5px;
	padding: 10px 50px 10px 15px;
}
</style>
