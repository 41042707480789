<template lang="html">
	<div id="user-energy-system" class="white-bg rounded-s">
		<div class="full-width full-height flex-auto-full-height-content">
			<div class="header">
				<PageHeader
					:text="$t('general.view')"
					close_name="user-energy-systems"
				/>
			</div>
			<div class="content">
				<div v-if="loading" class="p-sm">
					<Loader size="30" />
				</div>
				<div v-else-if="error" class="p-sm">
					<Alert :message="error" />
				</div>
				<div
					v-else-if="system_assets"
					class="full-width full-height box-shadow relative"
				>
					<div
						v-if="system_assets.length > 0"
						class="full-width full-height absolute"
					>
						<DataTable
							:items="system_assets"
							:items_per_page="50"
							:fields="table_columns"
						>
							<template #cell(id)="data">
								<div class="display-flex">
									<AssetShortcuts :asset="data.item" />
								</div>
							</template>
						</DataTable>
					</div>

					<div v-else>
						<Alert type="info" message="general.errors.no_results" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import DataTable from "@sub_components/DataTable.vue";
import AssetShortcuts from "@sub_components/location-components/AssetShortcuts";

import EnergySystemFinderClass from "@src/energy-system/application/energy-system-finder";
import AssetsFinderClass from "@src/asset/application/assets-finder";

const EnergySystemFinder = new EnergySystemFinderClass();
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "UserEnergySystem",
	components: {
		Loader,
		Alert,
		PageHeader,
		DataTable,
		AssetShortcuts
	},
	data() {
		return {
			loading: false,
			error: null,
			system_assets: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const system_id = this.$route.params.system_id;
			const system_data = await EnergySystemFinder.get_energy_system(system_id);

			let assets_ids_with_selected_sistem = [];
			if (system_data.assets) {
				system_data.assets.map((item) => {
					assets_ids_with_selected_sistem.push(item.id);
				});
			}

			let assets_with_selected_system = [];
			await Promise.all(
				assets_ids_with_selected_sistem.map(async (id) => {
					const asset = await AssetsFinder.get_asset_by_id(id);
					if (asset) assets_with_selected_system.push(asset);
				})
			);

			this.system_assets = assets_with_selected_system;
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		table_columns() {
			return [
				{
					key: "name",
					label: this.$t("location.name")
				},
				{
					key: "custom_id",
					class: "text-center vertical-align-center",
					label: this.$t("location.custom_id")
				},
				{
					key: "id",
					label: this.$t("general.shortcuts")
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
#user-energy-system {
	width: 900px;
	height: 600px;
}
</style>
