<template lang="html">
	<div v-if="user_data">
		<span>{{ user_data.first_name }}</span>
		<span v-if="user_data.last_name"> {{ user_data.last_name }}</span>
	</div>
</template>

<script>
import UserServiceClass from "@services/user-services/user-service";
import UserFinderClass from "@src/user/application/user-finder.js";
import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";

const UserService = new UserServiceClass();
const UserFinder = new UserFinderClass();
const OwnedUsersFinder = new OwnedUsersFinderClass();

export default {
	name: "TicketUserName",
	props: {
		requested_user_id: String
	},
	data() {
		return {
			user_data: null
		};
	},
	async mounted() {
		const current_user = await UserFinder.get_user();

		if (this.requested_user_id == current_user.id)
			this.user_data = {
				first_name: current_user.first_name,
				last_name: current_user.last_name
			};
		else if (
			UserService.user_has_role("supervisor") ||
			UserService.user_has_role("admin")
		) {
			const maintainer = await OwnedUsersFinder.get_owned_user(
				"maintainer",
				this.requested_user_id
			);

			this.user_data = {
				first_name: maintainer ? maintainer.first_name : null,
				last_name: maintainer ? maintainer.last_name : null
			};
		} else if (UserService.user_has_role("maintainer")) {
			this.user_data = {
				first_name: this.$t("header.admin")
			};
		}
	}
};
</script>

<style lang="css" scoped></style>
