import UserPrLimitUpdaterInterfaceClass from "../../infrastructure/pr-limit/user-pr-limit-updater-interface.js";
import UserPrLimitValidator from "../../domain/user-pr-limit-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new UserPrLimitUpdaterInterfaceClass();
		this.validator = UserPrLimitValidator;
	}

	async update_user_pr_limit(data) {
		const validated_data = this.validator.validate(data);

		try {
			await this.backend_interface.update_user_pr_limit(validated_data);
			return true;
		} catch (e) {
			throw new AppError(
				"error_update_user_pr_limit",
				"general.errors.general_error"
			);
		}
	}
}
