<template>
	<div v-if="loading" class="full-width p-sm white-bg rounded-s">
		<Loader size="30" />
	</div>
	<div v-else-if="error">
		<Alert :message="error" />
	</div>
	<div
		v-else
		class="white-bg full-width p-sm flex-with-same-cols-width gap-s rounded-s"
	>
		<div class="display-grid gap-s">
			<div>
				<span class="font-700 font-l">
					{{ $t("asset_energy_panel.sunrise") }}
				</span>
			</div>
			<div class="flex-center-content grey3-bg p-sm rounded-s">
				<div v-if="sunrise" class="flex-vertical-center gap-s">
					<div>
						<Icon icon="sunrise" size="30" />
					</div>
					<div>
						<span class="font-700 font-xl">
							{{ $format_date_to_hour_minute(sunrise) }}
						</span>
					</div>
				</div>
				<div v-else>-</div>
			</div>
		</div>
		<div class="display-grid gap-s">
			<div>
				<span class="font-700 font-l">
					{{ $t("asset_energy_panel.sunset") }}
				</span>
			</div>
			<div class="flex-center-content grey3-bg p-sm rounded-s">
				<div v-if="sunset" class="flex-vertical-center gap-s">
					<div>
						<Icon icon="sunset" size="30" />
					</div>
					<div>
						<span class="font-700 font-xl">
							{{ $format_date_to_hour_minute(sunset) }}
						</span>
					</div>
				</div>
				<div v-else>-</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import AssetSunriseSunsetFinderClass from "@src/asset/application/asset-sunrise-sunset-finder.js";
const AssetSunriseSunsetFinder = new AssetSunriseSunsetFinderClass();

export default {
	name: "AssetDaylight",
	components: {
		Loader,
		Alert
	},
	props: {
		asset_id: String
	},
	data() {
		return {
			loading: false,
			error: null,
			sunrise: null,
			sunset: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const sunrise_sunset_data =
				await AssetSunriseSunsetFinder.get_asset_sunrise_sunset(this.asset_id);

			this.sunrise = sunrise_sunset_data.sunrise;
			this.sunset = sunrise_sunset_data.sunset;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style scoped></style>
