export default (backend_obj) => {
	function round_number_2_decimals(number) {
		return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
	}

	let result = {
		time: backend_obj.time ? new Date(backend_obj.time) : null,
		power_production:
			backend_obj.powerProduction != null
				? round_number_2_decimals(backend_obj.powerProduction)
				: null,
		power_consumption:
			backend_obj.powerConsumption != null
				? round_number_2_decimals(backend_obj.powerConsumption)
				: null,

		rate_self_consumption: backend_obj.rateSelfConsumption || null,
		rate_self_sufficiency: backend_obj.rateSelfSufficiency || null,
		power_from_grid:
			backend_obj.powerFromGrid != null
				? round_number_2_decimals(backend_obj.powerFromGrid)
				: null,
		power_to_grid:
			backend_obj.powerToGrid != null
				? round_number_2_decimals(backend_obj.powerToGrid)
				: null,
		power_self_consumption:
			backend_obj.powerSelfConsumption != null
				? round_number_2_decimals(backend_obj.powerSelfConsumption)
				: null
	};

	const battery_optional_props = {
		batteryCharge: "battery_charge",
		batteryChargePercent: "battery_charge_percent",
		powerToBattery: "power_to_battery",
		powerFromBattery: "power_from_battery"
	};
	for (let backend_prop in battery_optional_props) {
		const local_prop_name = battery_optional_props[backend_prop];

		if (backend_obj.hasOwnProperty(backend_prop))
			result[local_prop_name] = backend_obj[backend_prop];
	}

	return result;
};
