<template>
	<div>
		<div id="energy-systems-remove" class="white-bg rounded-s">
			<PageHeader
				:text="$t('general.remove')"
				close_name="user-energy-systems"
				class="rounded-s_top"
			/>
			<div v-if="loading" class="p-t-xl p-b-xl flex-center-content">
				<Loader size="35" />
			</div>
			<div v-else-if="success" class="p-md">
				<div class="flex-center-content">
					<SuccessCircledCheckIcon />
				</div>
				<div class="flex-center-content">
					<div class="m-t text-center" style="width: 380px">
						<span>
							{{
								$t(
									"user_settings.system_manager.remove_energy_system_success_message"
								)
							}}
						</span>
					</div>
				</div>
			</div>
			<div v-else class="p-sm">
				<div class="p-t-xl flex-center-content">
					<div class="text-center" style="width: 380px">
						<div>
							{{
								$t(
									"user_settings.system_manager.remove_energy_system_confirmation_message"
								)
							}}
						</div>
						<div class="m-t font-bold">
							{{
								$t(
									"user_settings.system_manager.remove_energy_system_confirmation_message2"
								)
							}}
						</div>
					</div>
				</div>

				<div class="flex-horizontal-right m-t-md">
					<router-link
						:to="{
							name: 'user-energy-systems'
						}"
					>
						<button class="btn btn-primary">
							{{ $t("general.cancel") }}
						</button>
					</router-link>

					<button class="btn btn-danger m-l" v-on:click="remove_energy_systems">
						{{ $t("general.remove") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import SuccessCircledCheckIcon from "@sub_components/SuccessCircledCheckIcon.vue";

import EnergySystemEraserClass from "@src/energy-system/application/energy-system-eraser";
const EnergySystemEraser = new EnergySystemEraserClass();

export default {
	name: "EnergySystemsRemove",
	components: {
		PageHeader,
		Loader,
		SuccessCircledCheckIcon
	},
	props: {
		energy_systems_to_remove: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false
		};
	},
	methods: {
		async remove_energy_systems() {
			this.success = false;
			this.loading = true;

			for (let index in this.energy_systems_to_remove) {
				if (!this.energy_systems_to_remove[index]) continue;

				const energy_system_id = index;

				try {
					await EnergySystemEraser.remove_energy_system(energy_system_id);
				} catch (error) {}
			}

			this.$emit("system_removed");

			this.success = true;
			this.loading = false;
		}
	}
};
</script>

<style scoped>
#energy-systems-remove {
	width: 600px;
}
</style>
