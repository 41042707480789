<template lang="html">
	<div>
		<div v-if="loading">
			<Loader size="40" />
		</div>
		<div v-else-if="success">
			<Alert
				type="success"
				:message="$t('login.forgot_password.success_message')"
			/>
		</div>
		<div v-else>
			<div v-if="error" class="m-t">
				<Alert :message="error" />
			</div>

			<div class="font-bold">
				{{ $t("login.forgot_password.title") }}
			</div>
			<div class="m-t-sm">
				{{ $t("login.forgot_password.description") }}
			</div>
			<div class="m-t">
				<div v-if="validation_error" class="m-t m-b">
					<Alert :validation_error="validation_error" />
				</div>

				<input
					v-model="email"
					type="text"
					class="form-control"
					:placeholder="$t('login.entity.email')"
				/>
			</div>
			<div v-on:click="forgot_passowrd" class="m-t btn btn-primary full-width">
				{{ $t("login.forgot_password.submit_button") }}
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import ForgotPasswordServiceClass from "@services/user-services/forgot-password-service";
const ForgotPasswordService = new ForgotPasswordServiceClass();

export default {
	name: "ForgotPasswordForm",
	components: {
		Loader,
		Alert
	},
	data() {
		return {
			loading: null,
			error: null,
			validation_error: null,
			email: null,
			success: null
		};
	},
	methods: {
		async forgot_passowrd() {
			this.loading = true;
			this.error = null;
			this.validation_error = null;

			try {
				await ForgotPasswordService.forgot_password({ email: this.email });
				this.success = true;
			} catch (e) {
				if (e.type == "validation") {
					this.validation_error = e;
				} else {
					this.error = "general.errors.general_error";
				}
			} finally {
				this.loading = null;
			}
		}
	}
};
</script>

<style lang="css" scoped></style>
