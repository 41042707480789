import RestInterface from "@interfaces/rest_interface";
import { api_url as backend_api_url } from "@config";

export default class BackendInterface {
	constructor() {
		this.rest_interface = new RestInterface();
		this.backend_url = backend_api_url;
	}

	set_rest_interface(rest_interface) {
		this.rest_interface = rest_interface;
	}
}
