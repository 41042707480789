<template lang="html">
	<div>
		<select
			class="form-control"
			v-bind:class="{
				'danger-border': has_error
			}"
			v-model="input_value"
		>
			<option v-for="(multilingual_string, key) in unit_systems" :value="key">{{
				$t(multilingual_string)
			}}</option>
		</select>
	</div>
</template>

<script>
import Multiselect from "@form_components/Multiselect";

import EntityServiceClass from "@services/entity-service";
const EntityService = new EntityServiceClass();

export default {
	name: "TimezoneMultiselect",
	components: {
		Multiselect
	},
	props: { value: String, has_error: Boolean },
	data() {
		return {
			input_value: this.value,
			unit_systems: EntityService.get_multilingual_entity("user_unit_systems")
		};
	},
	methods: {
		emit_input_value() {
			this.$emit("input", this.input_value);
		}
	},
	watch: {
		input_value() {
			this.emit_input_value();
		}
	}
};
</script>
