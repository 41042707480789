import Joi from "joi";
import Validator from "@validators/validator";

const alert_schema = Joi.object({
	id: Joi.string().required(),
	system_id: Joi.string().when("asset_id", {
		not: Joi.exist(),
		then: Joi.required()
	}),
	asset_id: Joi.string(),
	alert_type: Joi.string()
		.required()
		.when("system_id", {
			is: Joi.exist(),
			then: Joi.valid("pr_alert", "provider_alert", "suspicious_data_alert")
		}),
	date: Joi.date().required(),
	is_archived: Joi.boolean().required(),
	extra_data: Joi.when("alert_type", {
		is: "pr_alert",
		then: Joi.object({
			pr: Joi.number().required()
		})
	})
		.when("alert_type", {
			is: "provider_alert",
			then: Joi.object({
				provider_device_id: Joi.string(),
				alert_code: Joi.string().valid(
					"ac_voltage_too_high",
					"ac_voltage_too_low",
					"smart_meter_offline",
					"inverter_offline",
					"unknown"
				),
				provider_alert_code: Joi.string(),
				provider_alert_message: Joi.string(),
				severity: Joi.number().min(1).max(3)
			})
		})

		.when("alert_type", {
			is: "suspicious_data_alert",
			then: Joi.object({
				suspicious_data_type: Joi.string()
					.valid(
						"generation_is_zero",
						"generation_equals_consumption",
						"consumption_is_zero",
						"generation_consumption_is_zero"
					)
					.required()
			})
		})

		.when("alert_type", {
			is: "new_asset_imported_with_fields_to_reiew",
			then: Joi.object({
				fields_to_review: Joi.array()
					.items(Joi.string().valid("coordinates", "pv_data"))
					.required()
			})
		})
});
export default new Validator(alert_schema);
