import AlertFinderInterfaceClass from "../infrastructure/alert-finder-interface.js";
import SystemAlertFinderValidator from "../domain/system-alert-finder-validator.js";
import AssetAlertFinderValidator from "../domain/asset-alert-finder-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.system_alert_validator = SystemAlertFinderValidator;
		this.asset_alert_validator = AssetAlertFinderValidator;
		this.backend_interface = new AlertFinderInterfaceClass();
	}

	async get_alert(alert_id) {
		try {
			const interface_alert = await this.backend_interface.get_alert(alert_id);

			if (interface_alert.system_id)
				return this.system_alert_validator.validate(interface_alert);
			else if (interface_alert.asset_id)
				return this.asset_alert_validator.validate(interface_alert);
			else return null;
		} catch (e) {
			throw new AppError("error_get_alert", "general.errors.general_error", e);
		}
	}
}
