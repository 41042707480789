<template lang="html">
	<div class="full-width full-height p-xxs">
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else class="full-width full-height">
			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div
				v-else
				class="full-width full-height grey2-bg flex-center-content relative"
			>
				<div class="asset-report-main-div relative">
					<div id="download-pdf-button">
						<div v-if="download_pdf_loading">
							<Loader size="20" />
						</div>
						<div v-else class="display-flex">
							<div class="btn btn-primary" v-on:click="toogle_report_comment">
								{{ $t("asset_reports.add_comment") }}
							</div>

							<div class="btn btn-primary m-l" v-on:click="download_pdf">
								{{ $t("asset_reports.download_pdf") }}
							</div>
						</div>
					</div>

					<div v-if="show_report_comment" id="report-comment">
						<div>
							<AddComment
								:report_comment="report_comment"
								v-on:comment_edited="comment_emmited"
								v-on:hide_edit_comment="toogle_report_comment"
							/>
						</div>
					</div>

					<div
						ref="pdf_content"
						class="asset-report-viewer overflow-y-scroll p-t p-b"
					>
						<div class="report-page">
							<Cover
								:report_title="
									$t('asset_reports.asset_monthly_savings_report.title')
								"
								:asset="asset"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
							/>
						</div>

						<div class="report-page">
							<Page1
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:comment="comment"
							/>
						</div>

						<div class="report-page">
							<Page2
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:comment="comment"
							/>
						</div>
						<div class="report-page">
							<Page3
								:filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:comment="comment"
							/>
						</div>

						<div class="report-page" v-if="comment">
							<CommentPage :report_comment="comment" page_number="4/4" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import AddComment from "../reports-shared-components/AddComment";
import Cover from "../reports-shared-components/Cover";
import Page1 from "./report-content/Page1.vue";
import Page2 from "./report-content/Page2.vue";
import Page3 from "./report-content/Page3.vue";
import CommentPage from "../reports-shared-components/CommentPage";

export default {
	name: "AssetMonthlySavingsReport",
	components: {
		Alert,
		Loader,
		AddComment,
		Cover,
		Page1,
		Page2,
		Page3,
		CommentPage
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object
	},
	data() {
		return {
			loading: null,
			error: null,
			show_report_comment: false,
			comment: null,
			download_pdf_loading: false
		};
	},
	methods: {
		async download_pdf() {
			try {
				this.download_pdf_loading = true;

				const custom_css_styles =
					"<style>.asset-monthly-savings-report-title { font-weight: bold; text-transform: uppercase; font-size: 17px; } .equivalent-box { background-color: #e6e6e7; border-radius: 5px; padding: 10px 50px 10px 15px; } .report-table { border-collapse: collapse; border: 1px solid #efeff0; font-size: 13px; } .report-table th, .report-table td { padding: 5px 5px; border: 1px solid #efeff0; } .report-table thead, .report-table td:first-child { background-color: #e6e6e7; }</style>";
				const html_element = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					html_element,
					"asset-month-savings-report",
					custom_css_styles
				);
			} catch (error) {
			} finally {
				this.download_pdf_loading = false;
			}
		},
		toogle_report_comment() {
			this.show_report_comment = !this.show_report_comment;
		},
		comment_emmited(comment) {
			this.comment = comment;
			this.show_report_comment = false;
		}
	},
	watch: {
		filter() {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 50);
		}
	}
};
</script>

<style lang="css" scoped>
#download-pdf-button {
	position: absolute;
	right: 15px;
	top: 25px;
	z-index: 1;
}
#report-comment {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.5);
}
#report-comment > div {
	width: 75%;
	margin-left: 12.5%;
	margin-top: 20px;
}
</style>

<style lang="css">
.asset-monthly-savings-report-title {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 17px;
}
</style>
