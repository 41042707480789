import BackendInterface from "../../../libs/infrastructure/backend-interfaces/backend-interface";
import BackendEnergyMeasurementProvidersUserDataConverter from "@interfaces/backend-interfaces/converters/backend-energy-measurement-providers-user-data-converter";

import BackendAssetEnergyDataConfigConverter from "./converters/backend-asset-energy-data-config-converter";

export default class extends BackendInterface {
	constructor() {
		super();
		this.backend_user_energy_measurement_providers_data_converter =
			new BackendEnergyMeasurementProvidersUserDataConverter();
	}

	async get_asset_energy_data_configuration(asset_id) {
		const url =
			this.backend_url +
			"/api/asset/" +
			asset_id +
			"/energy-data/configuration";

		try {
			const backend_asset_energy_data_config = await this.rest_interface.get(
				url
			);

			return BackendAssetEnergyDataConfigConverter(
				backend_asset_energy_data_config
			);
		} catch (e) {
			if (e.code && e.code == 409) {
				return undefined;
			} else throw e;
		}
	}
}
