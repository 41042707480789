import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";
import BackendEnergySystemConverter from "./backend-energy-system-converter";
import BackendAssetConverter from "@src/asset/infrastructure/backend-asset-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async create_energy_system(energy_system_provider_data) {
		const url = "/api/energy-data/system-with-provider";

		let extra_data = {};
		if (energy_system_provider_data.extra_data) {
			if (energy_system_provider_data.extra_data.authorized_nif)
				extra_data.authorizedNif =
					energy_system_provider_data.extra_data.authorized_nif;
		}

		const backend_result = await this.post(url, {
			body_params: {
				providerData: {
					providerName: BackendEntityConverter.convert_value_to_backend_value(
						"energy_measurement_provider_names",
						energy_system_provider_data.provider_name
					),
					idInProvider: energy_system_provider_data.system_id_in_provider,
					extraData: extra_data
				}
			}
		});

		return this._convert_bakckend_result(backend_result);
	}

	_convert_bakckend_result(backend_result) {
		return backend_result.error
			? {
					error: backend_result.error
			  }
			: {
					data: {
						new_asset: BackendAssetConverter(backend_result.data.asset),
						new_energy_system: BackendEnergySystemConverter(
							backend_result.data.system
						),
						need_to_review: backend_result.data.fieldsToReview ? true : false
					}
			  };
	}
}
