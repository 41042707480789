import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEnergySystemConverter from "./backend-energy-system-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_system(energy_system_id) {
		const url = "/api/energy-data/systems/" + energy_system_id;
		const backend_energy_system = await this.get(url);

		const local_energy_system = BackendEnergySystemConverter(
			backend_energy_system
		);
		return local_energy_system;
	}
}
