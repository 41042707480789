import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergyProviderAuthConverter from "./converters/backend-energy-provider-auth-converter";

import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_provider_auths() {
		const url = "/api/providers";
		const backend_user_providers = await this.get(url);

		let converted_user_providers = [];
		backend_user_providers.forEach((backend_user_provider_data) => {
			const local_user_provider_data = BackendEnergyProviderAuthConverter(
				backend_user_provider_data
			);
			converted_user_providers.push(local_user_provider_data);
		});

		return converted_user_providers;
	}

	_convert_backend_energy_provider_auth_to_local(backend_user_provider_data) {
		let local_user_provider_data = {
			id: backend_user_provider_data.id,
			provider: this._convert_backend_provider_name(
				backend_user_provider_data.name
			),
			plant_number: backend_user_provider_data.count,
			is_valid: backend_user_provider_data.isValid,
			last_update: new Date(backend_user_provider_data.updatedAt)
		};

		if (
			backend_user_provider_data.extraData &&
			backend_user_provider_data.extraData != {}
		) {
			local_user_provider_data.extra_data = {};

			if (backend_user_provider_data.extraData.authorizedNifs)
				local_user_provider_data.extra_data.authorized_nifs =
					backend_user_provider_data.extraData.authorizedNifs;
		}

		return local_user_provider_data;
	}

	_convert_backend_provider_name(backend_provider_name) {
		return BackendEntityConverter.convert_backend_value_to_local(
			"energy_measurement_provider_names",
			backend_provider_name
		);
	}
}
