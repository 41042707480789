import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_energy_production_forecast_available_date_range(asset_id) {
		const url =
			"/api/asset/" +
			asset_id +
			"/forecast/energy-production/available-date-range";

		const result = await this.get(url);
		return BackendObjectConverter(result, {
			dayFrom: {
				formatter: (value) => new Date(value)
			},
			dayTo: {
				formatter: (value) => new Date(value)
			}
		});
	}
}
