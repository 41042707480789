import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";
import LocalObjectConverter from "@src/shared/infrastructure/backend-converters/local-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_assets_group(id, assets_group) {
		const url = "/api/assets-group/" + id;
		const backend_assets_group = await this.put(url, {
			body_params: LocalObjectConverter(assets_group)
		});

		const local_assets_group = BackendObjectConverter(backend_assets_group);

		return local_assets_group;
	}
}
