import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendAssetConverter from "./backend-asset-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset(asset_id) {
		const url = "/api/asset/" + asset_id;
		const backend_result = await this.get(url);

		const converted_asset = BackendAssetConverter(backend_result);
		return converted_asset;
	}
}
