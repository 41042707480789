import BackendInterface from "@src/shared/infrastructure/backend-interface";
import RestError from "@classes/errors/rest_error";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_energy_forecast(asset_id, from = null, to = null) {
		try {
			const url = "/api/asset/" + asset_id + "/forecast/energy-production";

			let query_params = {
				useLocalTimeZone: true
			};

			if (from) query_params.dayFrom = moment(from).format("YYYY-MM-DD");
			if (to) query_params.dayTo = moment(to).format("YYYY-MM-DD");

			const result = await this.get(url, { query_params: query_params });
			return this._convert_backend_energy_production_forecast(result.data);
		} catch (error) {
			if (error instanceof RestError && error.code == 409) return null;
			else throw error;
		}
	}

	_convert_backend_energy_production_forecast(
		backend_energy_production_forecast
	) {
		const converted_data = backend_energy_production_forecast.map((item) => {
			return {
				time: new Date(item.time),
				energy_production_forecast: item.energyProduction,
				energy_consumption_forecast: item.energyConsumption
			};
		});

		converted_data.sort(function (a, b) {
			return a.time - b.time;
		});

		return converted_data;
	}
}
