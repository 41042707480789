<template>
	<div class="full-width full-height relative grey1-color">
		<div
			class="absolute full-width full-height overflow-y-scroll white-bg p-sm rounded-s box-shadow"
		>
			<div>
				<div class="font-xxl font-700">V2 icons</div>

				<div class="display-flex flex-row-wrap gap-xl m-t">
					<div v-for="icon in v2_icons" class="text-center display-grid gap-s">
						<div>
							<Icon :icon="icon" size="50" />
						</div>
						<div class="font-s">
							<span>{{ icon }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CustomIconsV2 from "@sub_components/custom-icon/custom-icons-svg.json";

export default {
	name: "AdminUi",

	data() {
		return {
			v2_icons: Object.keys(CustomIconsV2)
		};
	}
};
</script>
