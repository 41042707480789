import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_assets_groups() {
		const url = "/api/assets-groups";
		const backend_assets_groups = await this.get_items_with_offset(url, 4);

		const local_assets_groups = backend_assets_groups.map((item) =>
			BackendObjectConverter(item)
		);

		return local_assets_groups;
	}
}
