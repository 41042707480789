import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendUserConverter from "./converters/backend-user-converter";
import LocalUserConverter from "./converters/local-user-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async create_user(user, manager_id = null) {
		const user_url = user.roles[0];
		let url;

		switch (user_url) {
			case "maintainer":
				url = "/api/maintainer";
				break;
			case "guest":
				url = "/api/guest";
				break;
			case "manager":
				url = "/api/manager";
				break;
		}

		let backend_user = LocalUserConverter(user);
		if (manager_id) backend_user.managerId = manager_id;

		let returned_backend_user = await this.post(url, {
			body_params: backend_user
		});

		return BackendUserConverter(returned_backend_user);
	}
}
