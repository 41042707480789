<template lang="html">
	<div id="admin-new-user" class="absolute full-width full-height rounded-s">
		<div id="content" class="white-bg rounded-s overflow-hidden">
			<PageHeader text="New user" close_name="admin-users" />
			<div id="form" class="p-sm overflow-y-scroll">
				<Alert v-if="error" :message="error" />

				<div>
					<div>
						<div class="font-bold">
							{{ $t("user_settings.user_entity.first_name") }}
						</div>
						<input
							type="text"
							class="form-control"
							v-bind:class="{
								'danger-border': validation_error_field == 'first_name'
							}"
							v-model="new_user.first_name"
						/>
					</div>

					<div class="m-t">
						<div class="font-bold">
							{{ $t("user_settings.user_entity.last_name") }}
						</div>
						<input
							type="text"
							class="form-control"
							v-bind:class="{
								'danger-border': validation_error_field == 'last_name'
							}"
							v-model="new_user.last_name"
						/>
					</div>

					<div class="m-t">
						<div class="font-bold">
							{{ $t("user_settings.user_entity.email") }}
						</div>
						<input
							type="email"
							class="form-control"
							v-bind:class="{
								'danger-border': validation_error_field == 'email'
							}"
							v-model="new_user.email"
						/>
					</div>

					<div class="m-t">
						<div class="font-bold">
							{{ $t("user_settings.user_entity.company") }}
						</div>
						<input
							type="text"
							class="form-control"
							v-bind:class="{
								'danger-border': validation_error_field == 'company'
							}"
							v-model="new_user.company"
						/>
					</div>

					<div class="m-t">
						<div class="font-bold">
							{{ $t("user_settings.user_entity.unit_system") }}
						</div>

						<UnitSystemSelect
							v-model="new_user.unit_system"
							:has_error="validation_error_field == 'unit_system'"
						/>
					</div>

					<div class="m-t">
						<div class="font-bold">
							{{ $t("user_settings.user_entity.timezone") }}
						</div>
						<TimezoneSelect
							v-model="new_user.timezone"
							:has_error="validation_error_field == 'timezone'"
						/>
					</div>

					<div class="m-t">
						<div class="font-bold">
							{{ $t("user_settings.user_entity.password") }}
						</div>
						<input
							type="text"
							class="form-control"
							v-bind:class="{
								'danger-border': validation_error_field == 'password'
							}"
							v-model="new_user.password"
						/>
					</div>
				</div>
			</div>

			<hr />

			<div class="p-l p-r p-b">
				<div v-if="loading" class="">
					<Loader size="30" />
				</div>
				<div v-else>
					<div class="right">
						<div class="btn btn-primary" v-on:click="create_user">
							{{ $t("general.save") }}
						</div>
					</div>

					<div class="right m-r">
						<router-link :to="{ name: 'admin-users' }">
							<div class="btn btn-danger">
								{{ $t("general.cancel") }}
							</div>
						</router-link>
					</div>

					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";

import TimezoneSelect from "@form_components/TimezoneSelect";
import UnitSystemSelect from "@form_components/UnitSystemSelect";

import UserServiceClass from "@services/user-services/user-service";
const UserService = new UserServiceClass();

export default {
	name: "AdminNewUser",
	components: {
		Loader,
		Alert,
		PageHeader,
		TimezoneSelect,
		UnitSystemSelect
	},
	data() {
		return {
			loading: false,
			error: null,
			new_user: {
				unit_system: "si",
				timezone: "Europe/Madrid",
				roles: ["supervisor"]
			}
		};
	},
	methods: {
		async create_user() {
			this.loading = true;
			this.error = null;

			try {
				const new_user = Object.assign({}, this.new_user);
				const creted_user = await UserService.create_user(new_user);

				this.$emit("user_created", creted_user);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		validation_error_field() {
			if (!this.error || this.error.type != "validation") return null;
			else return this.error.field;
		}
	}
};
</script>

<style lang="css" scoped>
#admin-new-user {
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 3;
}
#content {
	position: absolute;
	width: 400px;
	left: calc(50% - 200px);
	top: 50px;
	height: calc(100% - 100px);
}
#form {
	height: calc(100% - 120px);
}
</style>
