import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendUserConverter from "./converters/backend-user-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_owned_users_by_role(users_role) {
		let url;

		switch (users_role) {
			case "maintainer":
				url = "/api/maintainers";
				break;
			case "guest":
				url = "/api/guests";
				break;
			case "manager":
				url = "/api/managers";
				break;
		}

		let backend_users = await this.get(url);

		var local_users = [];
		backend_users.forEach((backend_user) => {
			local_users.push(BackendUserConverter(backend_user));
		});
		return local_users;
	}
}
