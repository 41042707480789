import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_other_data(asset_id) {
		const url = "/api/asset/" + asset_id + "/other-data";

		const backend_asset_other_data = await this.get(url);

		const local_asset_other_data = {
			address: backend_asset_other_data.address || null,
			asset_image_url: backend_asset_other_data.assetImageUrl || null
		};

		return local_asset_other_data;
	}
}
