import Joi from "joi";
import Validator from "@validators/validator";

const weather_forecast_schema = Joi.object({
	resolution_in_minutes: Joi.number().min(1),
	data: Joi.array().items(
		Joi.object({
			time: Joi.date().required(),
			temperature: Joi.number().allow(null),
			dew_point: Joi.number().min(0).allow(null),
			humidity: Joi.number().min(0).max(100).allow(null),
			ghi: Joi.number().min(0).allow(null),
			dni: Joi.number().min(0).allow(null),
			dhi: Joi.number().min(0).allow(null),
			cloud_cover: Joi.number().min(0).max(100).allow(null),
			wind_speed_10m: Joi.number().min(0).allow(null),
			wind_bearing_10m: Joi.number().allow(null),
			wind_gust: Joi.number().min(0).allow(null),
			precip_intensity: Joi.number().min(0).allow(null),
			precip_probability: Joi.number().min(0).max(100).allow(null),
			visibility: Joi.number().min(0).allow(null),
			pressure: Joi.number().min(0).allow(null),
		})
	),
});

export default new Validator(weather_forecast_schema);
