import BackendInterface from "@src/shared/infrastructure/backend-interface";
import EnergyProviderFinderInterfaceClass from "./energy-provider-auth-finder-interface";

export default class extends BackendInterface {
	constructor() {
		super();

		this.energy_provider_finder_interface =
			new EnergyProviderFinderInterfaceClass();
	}

	async energy_provider_auth_data_update(
		provider_name,
		energy_provider_auth_id,
		params
	) {
		const url = "/api/providers/" + energy_provider_auth_id + "/data";
		await this.put(url, {
			body_params: params
		});

		return await this._get_energy_provider_auth(energy_provider_auth_id);
	}

	async energy_provider_auth_extra_data_update(
		provider_name,
		energy_provider_auth_id,
		extra_data
	) {
		let url;
		let backend_params;

		switch (provider_name) {
			case "datadis":
				url =
					"/api/providers/" +
					provider_name +
					"/" +
					energy_provider_auth_id +
					"/add-authorized-nifs";
				backend_params = {
					authorizedNifs: extra_data.authorized_nifs
				};

				await this.put(url, {
					body_params: backend_params
				});
				break;
			default:
				break;
		}

		return await this._get_energy_provider_auth(energy_provider_auth_id);
	}

	async energy_provider_auth_update(
		provider_name,
		energy_provider_auth_id,
		auth_data
	) {
		let converted_params;
		switch (provider_name) {
			case "datadis":
				converted_params = {
					auth: {
						password: auth_data.password,
						username: auth_data.username
					}
				};
				break;
			default:
				converted_params = this._convert_povider_login_params(
					provider_name,
					params
				);
		}

		const url =
			"/api/providers/" +
			provider_name +
			"/" +
			energy_provider_auth_id +
			"/add-authorized-nifs";
		return await this.put(url, {
			params: converted_params
		});
	}

	async _get_energy_provider_auth(energy_provider_auth_id) {
		const energy_provider_auths =
			await this.energy_provider_finder_interface.get_energy_provider_auths();

		return energy_provider_auths.find(
			(item) => item.id == energy_provider_auth_id
		);
	}
}
