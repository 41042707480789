<template>
	<div class="full-height">
		<div v-if="loading" class="content flex-center-content">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else class="content relative full-height full-width">
			<div v-if="chart_series" class="absolute full-width full-height">
				<div class="flex-auto-full-height-content">
					<div class="header">
						<div class="flex-horizontal-right">
							<div
								v-for="item in chart_used_variables"
								class="display-flex m-r-md"
							>
								<div class="flex-center-content">
									<div
										class="m-r-xs full-rounded"
										:style="{ 'background-color': item.color }"
										style="width: 15px; height: 15px"
									></div>
								</div>
								<div class="font-l flex-center-content">
									{{ $t(item.name) }}
								</div>
							</div>
						</div>
					</div>
					<div class="content" id="asset_energy_panel_chart">
						<LineChart
							v-if="show_chart"
							:series="chart_series"
							:variables_data="chart_used_variables"
							:height="chart_height"
							:display_minichart="false"
							:single_y_axis="true"
							:hide_options="true"
							:hide_legend="true"
							:y_axis_formatter="y_axis_formatter"
						/>
					</div>
				</div>
			</div>
			<div v-else>
				<Alert type="info" message="general.errors.no_data_available" />
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import LineChart from "@components/sub_components/charts/LineChart2";

import PvMeasuredVariables from "@entities/variables/pv-measured-data";

import AssetDayOptimalPowerFinderClass from "@src/asset-energy-data/application/asset-day-optimal-power-finder.js";
import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder";
import moment from "moment";

const AssetDayOptimalPowerFinder = new AssetDayOptimalPowerFinderClass();
const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();

export default {
	name: "AssetLastDayEnergyMeasurementsChart",
	components: {
		Loader,
		Alert,
		LineChart
	},
	props: {
		asset_id: String
	},
	data() {
		var chart_used_variables = {
			power_production: PvMeasuredVariables.power_production,
			power_consumption: PvMeasuredVariables.power_consumption,
			power_to_grid: PvMeasuredVariables.power_to_grid
		};
		//chart_used_variables.power_production.color = "#fcb12a";
		chart_used_variables.power_production.chart_type = "area";
		//chart_used_variables.power_consumption.color = "var(--blue1)";
		chart_used_variables.power_consumption.chart_type = "line";
		//chart_used_variables.power_to_grid.color = "var(--green1)";
		chart_used_variables.power_to_grid.chart_type = "area";

		return {
			loading: false,
			error: false,
			refesh_data_interval: null,
			chart_series: null,
			chart_used_variables: chart_used_variables,
			default_visible_variables: [
				"power_production",
				"power_to_grid",
				"power_consumption"
			],
			chart_height: null,
			show_chart: false,
			y_axis_formatter: (value) => {
				return this.$format_number(value, value <= 10 ? 2 : 0) + " kWh";
			}
		};
	},
	async mounted() {
		this.loading = true;
		try {
			await this.set_data(true);

			this.refesh_data_interval = setInterval(() => {
				this.set_data();
			}, 900000); //15 min
			setTimeout(() => {
				this.set_chart_height();
			}, 100);
		} catch (error) {
			this.error = error;

			setTimeout(() => {
				this.set_data();
			}, 300000);
		} finally {
			this.loading = false;
		}
	},
	created() {
		window.addEventListener("resize", this.set_chart_height);
	},
	destroyed() {
		window.removeEventListener("resize", this.set_chart_height);
	},
	methods: {
		async set_data(first_attempt) {
			try {
				const asset_lastest_data_date =
					await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
						this.asset_id
					);

				if (asset_lastest_data_date) {
					const result =
						await AssetDayOptimalPowerFinder.get_asset_day_optimal_power(
							this.asset_id,
							asset_lastest_data_date,
							true
						);

					if (first_attempt) {
						//OLD code
						//this.chart_series = this.fill_serie_full_day(result);
						this.chart_series = result;
					} else {
						this.loading = true;

						setTimeout(() => {
							//OLD code
							//this.chart_series = this.fill_serie_full_day(result);
							this.chart_series = result;
							this.loading = false;
						}, 10);
					}

					this.$emit("data_updated", {
						latest_data_date_is_today: moment().isSame(
							asset_lastest_data_date,
							"day"
						),
						latest_data_date: asset_lastest_data_date
					});
				}

				this.error = null;
			} catch (error) {
				this.error = first_attempt ? error : this.error;

				setTimeout(() => {
					this.set_data();
				}, 300000);
			}
		},
		set_chart_height() {
			this.chart_height = null;
			this.show_chart = false;
			const element = document.getElementById("asset_energy_panel_chart");

			if (element) {
				const element_height = element.offsetHeight;
				this.chart_height = element_height - 20;

				setTimeout(() => {
					this.show_chart = true;
				}, 100);
			}
		}
	},
	unmounted() {
		clearInterval(this.refesh_data_interval);
	}
};
</script>
