<template lang="html">
	<div class="p-t-xl p-b-xl flex-center-content">
		<div style="width: 400px" class="text-center">
			<span>
				{{ $t("user_settings.energy_provider_auths.lacecal_login_message") }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "LacecalLoginForm"
};
</script>

<style lang="css" scoped></style>
