import EnergySystemsFromUserFinderInterfaceClass from "../infrastructure/energy-systems-from-user-finder-interface";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator";

import StoreUtilClass from "@src/shared/application/utils/store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor(user_id) {
		this.user_id = user_id;
		this.backend_interface = new EnergySystemsFromUserFinderInterfaceClass(
			user_id
		);

		this.store_util = new StoreUtilClass("admin_users_energy_systems");
		this.validator = EnergySystemFinderValidator;
	}

	async get_energy_systems_from_user() {
		const stored_data = this.store_util.get_data();
		if (stored_data) {
			if (stored_data.user_id != this.user_id) this.store_util.set_data(null);
			else return stored_data.energy_systems;
		}

		try {
			const interface_data =
				await this.backend_interface.get_energy_systems_from_user();

			var result = interface_data.map((item) => this.validator.validate(item));

			this.store_util.set_data(
				{
					user_id: this.user_id,
					energy_systems: result
				},
				moment().endOf("day").toDate()
			);

			return this.store_util.get_data().energy_systems;
		} catch (e) {
			throw new AppError(
				"error_get_energy_systems_from_user",
				"general.errors.general_error",
				e
			);
		}
	}
}
