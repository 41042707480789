<template>
	<div id="ticket-view" class="rounded-s box-shadow1">
		<div class="full-width full-height flex-auto-full-height-content">
			<div class="header">
				<PageHeader
					:text="$t('ticketing.ticket_view')"
					close_name="ticketing"
				/>
			</div>
			<div v-if="loading" class="p-t-xl p-b-xl">
				<Loader size="40" />
			</div>
			<div v-else-if="error">
				<div class="p-sm">
					<Alert type="danger" :message="error" />
				</div>
			</div>
			<div v-else-if="ticket" class="content">
				<div>
					<div class="p-t p-b white-bg box-shadow">
						<div class="grid-with-same-cols-width-and-padding text-center">
							<div>
								<div class="grey2-color">
									{{ $t("ticketing.ticket_entity.id") }}
								</div>
								<div class="grey1-color m-t">{{ ticket.id }}</div>
							</div>
							<div v-if="ticket_location && ticket_location.custom_id">
								<div class="grey2-color">{{ $t("location.id") }}</div>
								<div class="grey1-color m-t">
									{{ ticket_location.custom_id }}
								</div>
							</div>
							<div v-if="ticket.creation">
								<div class="grey2-color">{{ $t("general.date") }}</div>
								<div class="grey1-color m-t">
									{{ format_date(ticket.creation) }}
								</div>
							</div>
							<div>
								<div class="grey2-color">
									{{ $t("ticketing.ticket_entity.created_by") }}
								</div>
								<div class="grey1-color m-t">
									<TicketUserName
										v-if="ticket.created_by"
										:requested_user_id="ticket.created_by"
									/>
								</div>
							</div>
							<div v-if="ticket.assigned_to">
								<div class="grey2-color">
									{{ $t("ticketing.ticket_entity.assigned_to") }}
								</div>
								<div class="grey1-color m-t">
									<TicketUserName
										v-if="ticket.assigned_to"
										:requested_user_id="ticket.assigned_to"
									/>
								</div>
							</div>
							<div v-if="location_current_year">
								<div class="grey2-color">{{ $tc("general.year", 1) }}</div>
								<div class="grey1-color m-t">
									{{ location_current_year }}
								</div>
							</div>
							<div>
								<div class="grey2-color">{{ $t("general.status") }}</div>
								<div class="grey1-color m-t">
									<TicketStatus :id="ticket.status" />
								</div>
							</div>
						</div>
					</div>

					<div class="p-sm">
						<div class="white-bg p-sm rounded-m">
							<div class="display-flex-space-between">
								<div>
									<div>
										<div class="grey2-color">
											{{ $t("ticketing.ticket_entity.title") }}
										</div>
										<div class="m-t-xs">
											{{ ticket.title }}
										</div>
									</div>
									<div class="m-t" v-if="ticket.description">
										<div class="grey2-color">
											{{ $t("general.description") }}
										</div>
										<div class="m-t-xs" style="white-space: pre-line">
											{{ ticket.description }}
										</div>
									</div>
									<div class="m-t">
										<span class="grey2-color">
											{{ $t("ticketing.required_visit") }}
										</span>
										<span class="m-l-xs">
											{{ ticket.visit ? $t("general.yes") : $t("general.no") }}
										</span>
									</div>
								</div>
								<div>
									<div id="ticket-view-price" class="m-t font-bold">
										{{
											ticket.visit && ticket.visit.total_cost
												? $format_number(ticket.visit.total_cost, 2)
												: 0
										}}€
									</div>
								</div>
							</div>

							<div
								v-if="
									ticket.visit &&
									(ticket.visit.paid_by ||
										ticket.visit.date ||
										ticket.visit.hours ||
										ticket.visit.distance ||
										ticket.visit.operators)
								"
							>
								<div class="m-t-md flex-center-content">
									<div
										class="bottom-boxes-width grey1-bg p-sm white rounded-m text-center"
									>
										<div class="grid-with-same-cols-width-and-padding">
											<div v-if="ticket.visit.paid_by">
												<div>
													{{ $t("ticketing.ticket_entity.visit.paid_by") }}
												</div>
												<div class="font-bold m-t-sm">
													{{ paid_by[ticket.visit.paid_by].name }}
												</div>
											</div>
											<div v-if="ticket.visit.date">
												<div>
													<span>
														{{ $t("ticketing.ticket_entity.visit.date") }}
													</span>
												</div>
												<div class="font-bold m-t-sm">
													<Icon icon="calendar" />
													<span class="m-l-sm">{{
														format_date(ticket.visit.date)
													}}</span>
												</div>
											</div>
											<div v-if="ticket.visit.hours">
												<div>
													{{ $t("ticketing.ticket_entity.visit.hours") }}
												</div>
												<div class="font-bold m-t-sm">
													{{ ticket.visit.hours }}
												</div>
											</div>
											<div v-if="ticket.visit.distance">
												<div>
													{{ $t("ticketing.ticket_entity.visit.distance") }}
												</div>
												<div class="font-bold m-t-sm">
													{{ ticket.visit.distance }} Km
												</div>
											</div>
											<div v-if="ticket.visit.operators">
												<div>
													{{ $t("ticketing.ticket_entity.visit.operators") }}
												</div>
												<div class="font-bold m-t-sm">
													{{ ticket.visit.operators }}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div
									v-if="ticket.visit.materials || ticket.visit.description"
									class="flex-center-content m-t-md"
								>
									<div
										class="bottom-boxes-width grid-with-same-cols-width-and-padding"
									>
										<div v-if="ticket.visit.materials">
											<div class="grey2-color">
												{{ $t("ticketing.ticket_entity.visit.materials") }}
											</div>
											<div class="m-t-sm">
												{{ ticket.visit.materials }}
											</div>
										</div>
										<div v-if="ticket.visit.description">
											<div class="grey2-color">
												{{ $t("ticketing.ticket_entity.visit.description") }}
											</div>
											<div class="m-t-sm">
												{{ ticket.visit.description }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div class="p-t">
								<router-link
									:to="{
										name: 'remove-ticket',
										params: { ticket_id: ticket.id }
									}"
								>
									<div class="right btn btn-danger">
										{{ $t("general.delete") }}
									</div>
								</router-link>
								<div class="clear"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import PageHeader from "@sub_components/PageHeader";
import TicketStatus from "./sub-components/TicketStatus";
import TicketUserName from "./sub-components/TicketUserName";

import TicketServiceClass from "@services/user-services/ticket-service";
import AssetsFinderClass from "@src/asset/application/assets-finder";

import moment from "moment";

const TicketService = new TicketServiceClass();
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "TicketView",
	components: {
		PageHeader,
		Alert,
		Loader,
		TicketStatus,
		TicketUserName
	},
	data() {
		return {
			loading: false,
			error: null,
			paid_by: null,
			ticket: null,
			ticket_location: null
		};
	},
	async mounted() {
		await this.load_ticket();
	},
	methods: {
		format_date(date) {
			return this.$format_date_to_day(date);
		},
		async load_ticket() {
			this.loading = true;
			this.paid_by = await TicketService.get_ticketing_paid_by();
			if (!this.$route.params.ticket_id) {
				this.error = "ticketing.errors.ticket_not_exist";
				return;
			}
			try {
				this.ticket = await TicketService.get_ticket(
					this.$route.params.ticket_id
				);

				if (this.ticket && this.ticket.location_id) {
					this.ticket_location = await AssetsFinder.get_asset_by_id(
						this.ticket.location_id
					);
				}
			} catch (e) {
				if (e.code && e.code == 404) {
					this.disable = true;
					this.error = "ticketing.errors.ticket_not_exist";
				} else this.error = e;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		location_current_year() {
			return this.ticket_location &&
				this.ticket_location.plant_data &&
				this.ticket_location.plant_data.commissioning_date
				? moment().diff(
						this.ticket_location.plant_data.commissioning_date,
						"years"
				  )
				: null;
		}
	}
};
</script>

<style scoped>
#ticket-view {
	width: 1000px;
	max-height: calc(100% - 90px);
	background-color: #f4f4f4;
}
.ticket-view-prop {
	font-size: 16px;
}
#ticket-view-price {
	font-size: 40px;
	font-weight: 400;
}
.bottom-boxes-width {
	width: 80%;
}
</style>
