<template>
	<div
		class="custom-tooltip flex-center-content font-500"
		:style="tooltip_style"
	>
		<slot v-if="has_custom_content_slot" name="content"></slot>
		<div v-else class="info-tooltip cursor-pointer">
			<span>?</span>
		</div>

		<div
			class="tooltip-content"
			v-bind:class="'tooltip-' + position"
			:style="{
				width: width_in_px + 'px',
				color: text_color
			}"
		>
			<div
				v-if="tooltip_text"
				class="p-t-xxs p-b-xxs p-l-xxs p-r-xxs font-s text-left"
				:style="{
					'white-space': single_line_tooltip_text ? 'nowrap' : 'initial'
				}"
			>
				<span>{{ tooltip_text }}</span>
			</div>

			<slot v-else name="tooltip-content"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "Tooltip",
	props: {
		position: String, //top | bottom | bottom-right | left | right
		theme: String, //light | dark (default)
		tooltip_text: String,
		single_line_tooltip_text: Boolean,
		width_in_px: Number
	},
	data() {
		let background_color, text_color;
		switch (this.theme) {
			case "light":
				background_color = "white";
				text_color = "var(--grey1)";
				break;
			default:
				background_color = "var(--grey1)";
				text_color = "white";
				break;
		}

		return {
			has_custom_content_slot: this.$slots.hasOwnProperty("content"),
			tooltip_style: {
				"--tooltip-background-color": background_color
			},
			text_color: text_color
		};
	}
};
</script>

<style scoped>
.info-tooltip {
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 50%;
	color: var(--grey2);
	text-align: center;
}

.custom-tooltip {
	position: relative;
	display: block;
}

.custom-tooltip .tooltip-content {
	visibility: hidden;
	background-color: var(--tooltip-background-color);
	color: #fff;
	border-radius: 6px;
	padding: 2px 5px;
	position: absolute;
	z-index: 3;
	box-shadow: rgba(67, 90, 111, 0.3) 0px 3px 6px 0px;

	/* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
	/*opacity: 0;*/
	/*transition: opacity 0.5s;*/
}
.custom-tooltip .tooltip-content.tooltip-top {
	bottom: 150%;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
}
.custom-tooltip .tooltip-content.tooltip-top::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: var(--tooltip-background-color) transparent transparent
		transparent;
}

.custom-tooltip .tooltip-content.tooltip-bottom {
	top: calc(100% + 10px);
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
}
.custom-tooltip .tooltip-content.tooltip-bottom::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent var(--tooltip-background-color)
		transparent;
}

.custom-tooltip .tooltip-content.tooltip-bottom-right {
	top: 150%;
	left: 130%;
	-webkit-transform: translate(-25px, 0%);
	transform: translate(-25px, 0%);
}
.custom-tooltip .tooltip-content.tooltip-bottom-right::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 10px;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent var(--tooltip-background-color)
		transparent;
}

.custom-tooltip .tooltip-content.tooltip-right {
	left: 130%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.custom-tooltip .tooltip-content.tooltip-right::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent var(--tooltip-background-color) transparent
		transparent;
}

.custom-tooltip .tooltip-content.tooltip-left {
	right: 130%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.custom-tooltip .tooltip-content.tooltip-left::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent transparent
		var(--tooltip-background-color);
}

.custom-tooltip:hover .tooltip-content {
	visibility: visible;
	opacity: 1;
}
</style>
