import AssetSavingsTotalFinderInterfaceClass from "../infrastructure/asset-savings-total-finder-interface";
import AssetSavingsTotalFinderValidator from "../domain/asset-savings-total-finder-validator";
import StoreUtilClass from "@src/shared/application/utils/store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetSavingsTotalFinderInterfaceClass();
		this.validator = AssetSavingsTotalFinderValidator;
	}

	async get_asset_savings_total(asset_id, force_download = false) {
		const store_util = new StoreUtilClass("asset_savings_total_" + asset_id);

		let asset_savings_total = await store_util.get_items();
		if (asset_savings_total && !force_download) return asset_savings_total;

		try {
			const interface_data =
				await this.backend_interface.get_asset_savings_total(asset_id);

			const validated_data = this.validator.validate(interface_data);

			await store_util.save_items(validated_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_user_energy_system",
				"general.errors.general_error",
				e
			);
		}
	}
}
