import AssetsGroupRemoveInterfaceClass from "../infrastructure/assets-group-eraser-interface.js";

import AssetsGroupsStoreUtil from "./utils/assets-groups-store-util.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetsGroupRemoveInterfaceClass();
		this.store_util = AssetsGroupsStoreUtil;
	}

	async remove_assets_group(assets_group_id) {
		try {
			await this.backend_interface.remove_assets_group(assets_group_id);

			await this.store_util.remove_item(assets_group_id);

			return true;
		} catch (e) {
			throw new AppError(
				"error_remove_assets_group",
				"general.errors.general_error",
				e
			);
		}
	}
}
