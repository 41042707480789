import UserPrLimitFinderInterfaceClass from "../../infrastructure/pr-limit/user-pr-limit-finder-interface.js";
import UserPrLimitValidator from "../../domain/user-pr-limit-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new UserPrLimitFinderInterfaceClass();
		this.validator = UserPrLimitValidator;
	}

	async get_user_pr_limit() {
		try {
			const interface_data = await this.backend_interface.get_user_pr_limit();
			const validated_data = this.validator.validate(interface_data);
			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_user_pr_limit",
				"general.errors.general_error",
				e
			);
		}
	}
}
