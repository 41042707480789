import AssetSavingsSettingsUpdaterInterfaceClass from "../infrastructure/asset-savings-settings-updater-interface.js";
import AssetSavingsSettingsValidator from "../domain/asset-savings-settings-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetSavingsSettingsValidator;
		this.backend_interface = new AssetSavingsSettingsUpdaterInterfaceClass();
	}

	async update_asset_savings_settings(asset_id, asset_savings_settings) {
		const validated_asset_savings_settings = this.validator.validate(
			asset_savings_settings
		);

		try {
			const interface_result =
				await this.backend_interface.update_asset_savings_settings(
					asset_id,
					validated_asset_savings_settings
				);

			const validated_result = this.validator.validate(interface_result);
			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_update_asset_savings_settings",
				"general.errors.general_error",
				e
			);
		}
	}
}
