import Joi from "joi";
import Validator from "@validators/validator";
import { ValidStringsValidation } from "@validators/validator-utils.js";

import EnergyProvidersData from "@src/energy-provider-auth/domain/energy-providers-data.js";
const valid_energy_providers_slugs = Object.keys(EnergyProvidersData);

export default new Validator(
	Joi.string().custom(
		ValidStringsValidation(valid_energy_providers_slugs),
		"Energy data provider slug validation"
	)
);
