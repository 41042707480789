const head_start = "<html><head>";
const head_metas =
	'<meta name="viewport" content="width=device-width, initial-scale=1" /> <meta http-equiv="Content-Type" content="text/html" charset="UTF-8" /> <link rel="preconnect" href="https://fonts.googleapis.com" /> <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /> <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap" rel="stylesheet" />';
const general_css = "<style>" + require("./general-css") + "</style>";
const reports_general_styles =
	"<style> .report-page { background-color: white; width: 800px; } .report-page > div { height: 1131px; position: relative; } .asset-reports-grey-color { color: #626262; } .pv-report-font-bold { font-weight: 700; } .pv-report-text-right { text-align: right; } .text-center { text-align: center; } .pv-report-title { font-size: 1rem; font-weight: 700; text-transform: uppercase; } .pv-report-m-t { margin-top: 15px; } .pv-report-m-t-xl { margin-top: 40px; } </style>";
const colors_css =
	"<style>:root { --grey1: #495062; --grey2: #94979b; --grey3: #F2F2F2; --grey4: #5A6173; --blue1: #5B8DEF; --green1: #32A560; --danger-color: #fb614d; --warning-color: #FDAC42; --custom-primary: var(--grey1); --custom-primary-hover: var(--grey2); }.grey1-bg { background-color: var(--grey1); color: white; } .grey1-color { color: var(--grey1); } .grey2-color { color: var(--grey2); } .grey2-bg { background-color: var(--grey2); } .grey3-color { color: var(--grey3); } .grey3-bg { background-color: var(--grey3); color: var(--grey1); } .grey4-color { color: var(--grey4); } .grey4-bg { background-color: var(--grey4); color: white; } .blue1-color { color: var(--blue1); } .blue1-bg { background-color: var(--blue1); color: white; } .green1-color { color: var(--green1); } .green1-bg { background-color: var(--green1); color: white; }.white, .white:hover { color: white !important; }</style>";
const head_end = "</head><body>";

export default (custom_css_styles = null) => {
	let result =
		head_start + head_metas + general_css + reports_general_styles + colors_css;

	if (custom_css_styles) result += "<style>" + custom_css_styles + "</style>";

	result += head_end;

	return result;
};
