<template>
	<div class="pv-report-m-t-xl">
		<div class="pv-report-title">
			{{ $t("asset_reports.page3.total_production_summary") }}
		</div>
		<div
			class="m-t text-center"
			style="
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
			"
		>
			<div
				v-if="
					!hide_energy_production_peak_power_ratio &&
					is_anual_report &&
					selected_period_energy_production_summary &&
					selected_period_energy_production_summary.energy_production_peak_power_ratio
				"
			>
				<div
					class="pv-report-page3-big-numbers"
					v-bind:style="{ color: asset_reports_color }"
				>
					{{
						$format_number(
							selected_period_energy_production_summary.energy_production_peak_power_ratio,
							2
						)
					}}
				</div>
				<div class="pv-report-page3-ƒont-s">kWh/kWp</div>
				<div class="pv-report-m-t pv-report-page3-ƒont-s">
					{{ $t("asset_reports.page3.selected_period_performance") }}
				</div>
			</div>

			<div
				v-if="
					from_year_start_to_end_of_selected_period_energy_production_summary &&
					from_year_start_to_end_of_selected_period_energy_production_summary.energy_self_consumption
				"
			>
				<div
					class="pv-report-page3-big-numbers"
					v-bind:style="{ color: asset_reports_color }"
				>
					{{
						$format_number(
							from_year_start_to_end_of_selected_period_energy_production_summary.energy_self_consumption,
							2
						)
					}}
					<sup
						v-if="
							from_year_start_to_end_of_selected_period_energy_production_summary &&
							from_year_start_to_end_of_selected_period_energy_production_summary.energy_self_consumption_eq_1_year_families_consumption
						"
						>*</sup
					>
				</div>
				<div class="pv-report-page3-ƒont-xs">kWh</div>
				<div class="pv-report-m-t pv-report-page3-ƒont-s">
					{{ $t("asset_reports.page3.selected_period_year_self_consumption") }}
				</div>
			</div>

			<div
				v-if="
					from_start_of_available_data_to_end_of_selected_period_energy_production_summary &&
					from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production
				"
			>
				<div
					class="pv-report-page3-big-numbers"
					v-bind:style="{ color: asset_reports_color }"
				>
					{{
						$format_number(
							from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production,
							2
						)
					}}
					<sup
						v-if="
							from_start_of_available_data_to_end_of_selected_period_energy_production_summary &&
							from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production_eq_1_year_families_consumption
						"
						>**</sup
					>
				</div>
				<div class="pv-report-page3-ƒont-xs">kWh</div>
				<div class="pv-report-m-t pv-report-page3-ƒont-s">
					{{ $t("asset_reports.page3.total_energy_production") }}
				</div>
			</div>

			<div
				v-if="
					!hide_energy_production_variation &&
					from_start_of_available_data_to_end_of_selected_period_energy_production_summary &&
					from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production_variation
				"
			>
				<div
					class="pv-report-page3-big-numbers"
					v-bind:style="{ color: asset_reports_color }"
				>
					{{
						$format_number(
							from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production_variation,
							2
						)
					}}%
				</div>
				<div class="pv-report-m-t-xl pv-report-page3-ƒont-s">
					{{ $t("asset_reports.page3.total_energy_variation") }}
				</div>
			</div>
		</div>

		<div class="pv-report-m-t pv-report-page3-ƒont-s">
			<div
				v-if="
					from_year_start_to_end_of_selected_period_energy_production_summary &&
					from_year_start_to_end_of_selected_period_energy_production_summary.energy_self_consumption_eq_1_year_families_consumption
				"
			>
				*
				{{
					$t("asset_reports.page3.consumption_eq_families_per_year", {
						families_number:
							from_year_start_to_end_of_selected_period_energy_production_summary.energy_self_consumption_eq_1_year_families_consumption
					})
				}}
			</div>
			<div
				v-if="
					from_start_of_available_data_to_end_of_selected_period_energy_production_summary &&
					from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production_eq_1_year_families_consumption
				"
			>
				**
				{{
					$t("asset_reports.page3.consumption_eq_families_per_year", {
						families_number:
							from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energy_production_eq_1_year_families_consumption
					})
				}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EnergyProductionSummary",
	props: {
		asset_reports: Object,
		asset_reports_color: String,
		hide_energy_production_peak_power_ratio: Boolean,
		hide_energy_production_variation: Boolean
	},
	data() {
		return {
			selected_period_energy_production_summary:
				this.asset_reports.energy_production_summary
					.selected_period_energy_production_summary || null,
			from_start_of_available_data_to_end_of_selected_period_energy_production_summary:
				this.asset_reports.energy_production_summary
					.from_start_of_available_data_to_end_of_selected_period_energy_production_summary ||
				null,
			from_year_start_to_end_of_selected_period_energy_production_summary:
				this.asset_reports.energy_production_summary
					.from_year_start_to_end_of_selected_period_energy_production_summary ||
				null
		};
	},
	computed: {
		is_anual_report() {
			const selected_period_energy =
				this.asset_reports.energy_production_summary
					.selected_period_energy_production;

			return selected_period_energy.length == 12;
		}
	}
};
</script>
