<template>
	<div>
		<div
			style="
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 20px;
				padding: 0 30px;
			"
		>
			<div>
				<div class="font-700">
					<span
						>{{
							$t(
								"asset_reports.asset_monthly_savings_report2.page1.total_production"
							)
						}}:
						{{ asset_filter_month_energy_measurements.energy_production }}
						kWh
					</span>
				</div>

				<CustomPercentageBarChart
					class="m-t-xs"
					:data="[
						{
							multilingual_label: 'pv_main_panels.self_consumption',
							color: 'var(--custom-asset_monht_report2_yellow)',
							value:
								asset_filter_month_energy_measurements.energy_self_consumption
						},

						{
							multilingual_label:
								'asset_reports.asset_monthly_savings_report2.energy_to_grid',
							color: 'var(--custom-asset_monht_report2_green)',
							value: asset_filter_month_energy_measurements.energy_to_grid
						}
					]"
					:total="asset_filter_month_energy_measurements.energy_production"
					unit="kWh"
					total_is_different_than_100_multilingual_msg="pv_main_panels.measurements.invalid_aggregated_data"
					:legend_font_size_in_px="12"
				/>
			</div>
			<div>
				<div class="font-700">
					<span
						>{{
							$t(
								"asset_reports.asset_monthly_savings_report2.page1.total_consumption"
							)
						}}:
						{{ asset_filter_month_energy_measurements.energy_consumption }}
						kWh
					</span>
				</div>
				<CustomPercentageBarChart
					class="m-t-xs"
					:data="[
						{
							multilingual_label: 'pv_main_panels.self_consumption',
							color: 'var(--custom-asset_monht_report2_yellow)',
							value:
								asset_filter_month_energy_measurements.energy_self_consumption
						},

						{
							multilingual_label:
								'asset_reports.asset_monthly_savings_report2.energy_from_grid',
							color: 'var(--grey2)',
							value: asset_filter_month_energy_measurements.energy_from_grid
						}
					]"
					:total="asset_filter_month_energy_measurements.energy_consumption"
					unit="kWh"
					total_is_different_than_100_multilingual_msg="pv_main_panels.measurements.invalid_aggregated_data"
					:legend_font_size_in_px="12"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CustomPercentageBarChart from "@sub_components/charts/CustomPercentageBarChart.vue";

export default {
	name: "AssetMonhtlyReport2Page1EnergyChart",
	components: {
		CustomPercentageBarChart
	},
	props: {
		asset_filter_month_energy_measurements: Object
	},
	computed: {
		energy_production_bar_chart_data() {
			return [
				{
					color: "",
					value: "",
					multilingual_label: ""
				}
			];
		}
	}
};
</script>
