import { Hotjar } from "./hotjar";
import { hotjar_api_key } from "@config";

export default {
	install(app) {
		const snippet_version = 6;
		if (hotjar_api_key) new Hotjar(hotjar_api_key, snippet_version);
		return window.hj;
	}
};
