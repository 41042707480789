<template lang="html">
	<div>
		<div v-if="error" class="m-b">
			<Alert :message="error" />
		</div>
		<div>
			<input
				type="text"
				class="form-control"
				placeholder="Email"
				v-model.number="user_login.email"
				@keyup.enter="login()"
			/>
		</div>
		<div class="m-t">
			<input
				type="password"
				class="form-control"
				placeholder="Password"
				v-model="user_login.password"
				@keyup.enter="login()"
			/>
		</div>
		<div v-if="loading" class="m-t m-b">
			<Loader size="30" />
		</div>
		<button v-else class="btn btn-primary full-width m-b m-t" @click="login">
			{{ $t("login.login_button") }}
		</button>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import UserService from "@services/user-services/user-service";
import LoginError from "@classes/errors/rest-authentication-error";

export default {
	name: "Login",
	components: {
		Loader,
		Alert
	},
	data() {
		return {
			user_login: {},
			error: null,
			loading: null
		};
	},
	methods: {
		async login() {
			const user_service = new UserService();
			this.loading = true;
			this.validation_error = null;

			try {
				await user_service.login(this.user_login);
				this.$router.push({ name: "app" });
				return;
			} catch (e) {
				if (e instanceof LoginError) {
					this.error = "login.invalid_credentials";
				} else if (e.type == "validation") {
					this.error = e;
				} else {
					this.error = "general.errors.general_error";
				}

				this.loading = null;
			}
		}
	},
	created() {
		const user_service = new UserService();
		if (user_service.get_current_user_token()) {
			this.$router.push({ name: "app" });
		}
	}
};
</script>

<style lang="css" scoped></style>
