import AlertsFinderInterfaceClass from "../infrastructure/assets-alerts-finder-interface.js";
import AssetsAlertsFinderFilterValidator from "../domain/assets-alerts-finder-filter-validator.js";
import AssetAlertFinderValidator from "../domain/asset-alert-finder-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetAlertFinderValidator;
		this.filter_validator = AssetsAlertsFinderFilterValidator;
		this.backend_interface = new AlertsFinderInterfaceClass();
	}

	async get_assets_alerts(filter, page) {
		try {
			filter = this.filter_validator.validate(filter);
			var paginated_alerts = await this.backend_interface.get_assets_alerts(
				filter,
				page
			);

			paginated_alerts.data = paginated_alerts.data.map((alert) => {
				return this.validator.validate(alert);
			});

			return paginated_alerts;
		} catch (e) {
			throw new AppError(
				"error_get_assets_alerts",
				"general.errors.general_error",
				e
			);
		}
	}
}
