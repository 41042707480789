import AssetEnergySystemGeneralDataUpdaterInterfaceClass from "../infrastructure/energy-system-common-data-updater-interface";
import EnergySystemGeneralDataValidator from "../domain/energy-system-common-data-validator";
import EnergySystemFinderValidator from "@src/energy-system/domain/energy-system-finder-validator";
import EnergySystemFinderClass from "./energy-system-finder.js";

import EnergySystemStoreUtil from "@src/energy-system/application/utils/energy-system-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface =
			new AssetEnergySystemGeneralDataUpdaterInterfaceClass();
		this.store_util = EnergySystemStoreUtil;
		this.validator = EnergySystemGeneralDataValidator;
		this.finder_validator = EnergySystemFinderValidator;
	}

	async update_energy_system_general_data(
		energy_system_id,
		energy_system_general_data
	) {
		const validated_data = this.validator.validate(energy_system_general_data);

		try {
			const interface_data =
				await this.backend_interface.update_energy_system_general_data(
					energy_system_id,
					validated_data
				);

			const validated_interface_data =
				this.finder_validator.validate(interface_data);

			await this.store_util.add_or_update_item(validated_interface_data);

			return validated_interface_data;
		} catch (e) {
			throw new AppError(
				"error_update_energy_system_general_data",
				"general.errors.general_error",
				e
			);
		}
	}
}
