import OwnedUserResetPasswordInterfaceClass from "../infrastructure/owned-user-reset-password-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new OwnedUserResetPasswordInterfaceClass();
	}

	async reset_user_password(user_id) {
		try {
			await this.backend_interface.reset_user_password(user_id);
		} catch (e) {
			throw new AppError(
				"error_reset_user_password",
				"general.errors.general_error",
				e
			);
		}
	}
}
