<template>
	<div class="">
		<div
			v-if="value_position == 'top'"
			class="text-center font-700 p-b-xs grey1-color"
		>
			<span :class="{ 'visibility-hidden': value == null }">
				{{ value != null ? value : "-" }}
			</span>
		</div>

		<div class="flex-center-content">
			<div class="relative">
				<div
					class="asset-current-power-element full-rounded flex-center-content white"
				>
					<Icon :icon="icon" size="25" />
				</div>
				<div v-if="formatted_percentage && color" class="outside-circle">
					<div
						class="current-power-percentage"
						:style="'--p: ' + formatted_percentage + '; --color: ' + color"
					></div>
				</div>
			</div>
		</div>

		<div
			v-if="value_position == 'bottom'"
			class="text-center font-700 p-t-xs grey1-color"
		>
			<span :class="{ 'visibility-hidden': value == null }">
				{{ value != null ? value : "-" }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "AssetCurrentPower",
	props: {
		icon: String,
		percentage: Number,
		color: String,
		value: String,
		value_position: String
	},
	computed: {
		formatted_percentage() {
			if (this.percentage == null) return null;
			else if (this.percentage > 100) return 100;
			else if (this.percentage < 0) return 0;
			else return this.$format_number(this.percentage);
		}
	}
};
</script>

<style scoped>
.asset-current-power-element {
	width: 50px;
	height: 50px;
	background-color: #4a5060;
	position: relative;
	z-index: 1;
}
.outside-circle {
	position: absolute;
	left: -5px;
	top: -5px;
}

.current-power-percentage {
	--v: calc(((18 / 5) * var(--p) - 90) * 1deg);

	width: 60px;
	height: 60px;
	display: inline-block;
	border-radius: 50%;
	padding: 5px;
	background: linear-gradient(#ccc, #ccc) content-box,
		linear-gradient(var(--v), #ffffff 50%, transparent 0) 0 /
			min(100%, (50 - var(--p)) * 100%),
		linear-gradient(var(--v), transparent 50%, var(--color) 0) 0 /
			min(100%, (var(--p) - 50) * 100%),
		linear-gradient(to right, #ffffff 50%, var(--color) 0);
}
</style>
