import EnergyDevicesFinderInterfaceClass from "../infrastructure/energy-devices-finder-interface";
import EnergySystemFinderValidator from "../domain/energy-device-validator";

import StoreUtilClass from "../../shared/application/utils/store-util";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new EnergyDevicesFinderInterfaceClass();
		this.validator = EnergySystemFinderValidator;
		this.store_util = null;
	}

	async get_energy_devices_by_system_id(
		energy_system_id,
		force_download = false
	) {
		const store_entity_name = "energy_system_devices_" + energy_system_id;
		this.store_util = new StoreUtilClass(store_entity_name);

		let energy_system = this.store_util.get_data();
		if (energy_system && !force_download) return energy_system;

		try {
			const interface_data =
				await this.backend_interface.get_energy_devices_by_system_id(
					energy_system_id
				);

			const validated_data = interface_data.map((item) =>
				this.validator.validate(item)
			);

			this.store_util.set_data(
				validated_data,
				moment().add(2, "days").toDate()
			);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_energy_devices_by_system_id",
				"general.errors.general_error",
				e
			);
		}
	}
}
