import EnergyMeasurementValidator from "@src/asset-energy-data/domain/energy-measurement-validator.js";
import EnergySystemMonthlyFinderInterfaceClass from "../../infrastructure/energy-data/energy-system-monthly-energy-finder-interface.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.energy_measurement_validator = EnergyMeasurementValidator;
		this.backend_interface = new EnergySystemMonthlyFinderInterfaceClass();
	}

	async get_energy_system_monthly_data(
		system_id,
		from,
		to,
		only_provider_data = false
	) {
		try {
			const interface_data =
				await this.backend_interface.get_energy_system_monthly_data(
					system_id,
					from,
					to,
					only_provider_data
				);

			let validated_data = [];
			interface_data.forEach((backend_item) => {
				const validated_item =
					this.energy_measurement_validator.validate(backend_item);
				validated_data.push(validated_item);
			});

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_energy_system_monthly_data",
				"general.errors.general_error",
				e
			);
		}
	}
}
