<template>
	<div class="full-width full-height relative">
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else class="flex-auto-full-height-content">
			<div v-if="is_subpage_active" id="subpage">
				<div class="flex-center-content full-width full-height">
					<router-view
						v-on:user_updated="refresh_managers"
						:role="role"
						:selected_mananger_id="selected_mananger_id"
					></router-view>
				</div>
			</div>

			<div class="header">
				<div class="display-flex-space-between p-b">
					<div class="display-flex">
						<TextInput
							v-model="table_filter_value"
							:clean_button="true"
							:placeholder="$t('general.search')"
						/>
					</div>
					<div>
						<router-link
							:to="{ name: 'owned-user-add', params: { role: role } }"
						>
							<div class="btn btn-primary right">
								{{ $t("general.add_new") }} +
							</div>
						</router-link>
						<div
							class="btn btn-primary right m-r"
							v-on:click="set_users_by_role(true)"
						>
							<Icon icon="sync-alt" />
						</div>
					</div>
				</div>
			</div>
			<div v-if="error">
				<Alert :message="error" />
			</div>
			<div v-else class="content-no-scroll">
				<UsersList
					:users="users"
					:role="role"
					:table_filter_value="table_filter_value"
					v-on:manager_selected="manager_selected"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";
import TextInput from "@form_components/TextInput";
import UsersList from "./UsersList";

import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";
import OwnedManagerUsersFinderClass from "@src/user/application/owned-manager-users-finder";
const OwnedUsersFinder = new OwnedUsersFinderClass();
const OwnedManagerUsersFinder = new OwnedManagerUsersFinderClass();

export default {
	name: "UserManagers",
	components: {
		Alert,
		Loader,
		TextInput,
		UsersList
	},
	props: {
		role: String,
		selected_mananger_id: String | null
	},
	data() {
		return {
			loading: false,
			error: null,
			users: [],
			table_filter_value: null
		};
	},
	methods: {
		async set_users_by_role(force = null) {
			this.loading = true;
			this.error = null;
			try {
				this.users = this.selected_mananger_id
					? await OwnedManagerUsersFinder.get_owned_manager_users_by_role(
							this.selected_mananger_id,
							this.role,
							force
					  )
					: await OwnedUsersFinder.get_owned_users_by_role(this.role, force);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		manager_selected(manager_id) {
			this.$emit("manager_selected", manager_id);
		}
	},
	mounted() {
		this.set_users_by_role();
	},
	computed: {
		is_subpage_active() {
			return this.$route.name != "owned-users";
		}
	},
	watch: {
		role() {
			this.table_filter_value = null;
			this.set_users_by_role();
		},
		selected_mananger_id() {
			this.table_filter_value = null;
			this.set_users_by_role();
		}
	}
};
</script>

<style lang="css" scoped>
#subpage {
	position: absolute;
	z-index: 4;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
}
</style>
