import BackendInterface from "./backend-interface";

export default class ForgotPasswordBackendInterface extends BackendInterface {
	constructor() {
		super();
	}

	async forgot_password(params) {
		const url = this.backend_url + "/auth/forgot-password";

		const result = await this.rest_interface.post(url, {
			params: { email: params.email }
		});
		return result;
	}

	async reset_password(params) {
		const url = this.backend_url + "/auth/reset-password";

		const result = await this.rest_interface.post(url, {
			params: {
				email: params.email,
				token: params.token,
				newPassword: params.new_password
			}
		});
		return result;
	}
}
