const props = [
	"wifi",
	"wifi_pcl_repeater",
	"ethernet",
	"4g",
	"other",
	"no_communnication"
];

let result = {};
props.forEach((item) => {
	result[item] =
		"entities.energy_system.pv_data.connection_type_options." + item;
});

module.exports = result;
