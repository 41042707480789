import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendAssetConverter from "./backend-asset-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_user_assets() {
		const url = "/api/assets";
		const backend_locations = await this.get_items_with_offset(url, 4);
		let formatted_locations = [];
		for (var key in backend_locations) {
			formatted_locations.push(BackendAssetConverter(backend_locations[key]));
		}
		return formatted_locations;
	}
}
