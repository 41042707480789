<template>
	<div>
		<div class="text-uppercase font-700">
			<span>
				{{
					$t(
						"asset_reports.asset_monthly_savings_report2.page1.month_savings_subtitle"
					)
				}}
			</span>
		</div>
		<div class="grid-with-same-cols-width-and-padding m-t">
			<div
				class="rounded-s grey3-bg p-xs"
				style="
					display: grid;
					grid-template-columns: auto auto;
					column-gap: 10px;
				"
			>
				<div class="flex-center-content grey2-color">
					<Icon icon="euro" />
				</div>
				<div class="font-700 flex-vertical-center" style="font-size: 12px">
					<span>
						{{
							$t(
								"asset_reports.asset_monthly_savings_report2.page1.money_savings_msg",
								{ saved_money: $format_number(asset_filter_month_savings, 0) }
							)
						}}*
					</span>
				</div>
			</div>

			<div
				class="rounded-s grey3-bg p-xs"
				style="
					display: grid;
					grid-template-columns: auto auto;
					column-gap: 10px;
				"
			>
				<div
					class="flex-center-content"
					style="color: var(--custom-asset_monht_report2_yellow)"
				>
					<Icon icon="co2" />
				</div>
				<div class="font-700 flex-vertical-center" style="font-size: 12px">
					<span> </span>
					<span
						v-html="
							$t(
								'asset_reports.asset_monthly_savings_report2.page1.c02_savings_msg',
								{ kg_co2_saved: $format_number(filter_month_saved_c02, 0) }
							)
						"
					>
					</span>
				</div>
			</div>

			<div
				class="rounded-s grey3-bg p-xs"
				style="
					display: grid;
					grid-template-columns: auto auto;
					column-gap: 10px;
				"
			>
				<div
					class="flex-center-content"
					style="color: var(--custom-asset_monht_report2_green)"
				>
					<Icon icon="tree" />
				</div>
				<div class="font-700 flex-vertical-center" style="font-size: 12px">
					<span>
						{{
							$t(
								"asset_reports.asset_monthly_savings_report2.page1.tree_savings_msg",
								{ number_of_trees: $format_number(filter_month_saved_trees, 0) }
							)
						}}
					</span>
				</div>
			</div>
		</div>

		<div class="m-t-xs" style="font-size: 11px">
			<span>
				*
				{{
					$t(
						"asset_reports.asset_monthly_savings_report2.page1.money_savings_annotation"
					)
				}}
			</span>
		</div>
	</div>
</template>

<script>
import LocationEnergyMeasurementProvidersServiceClass from "@services/location-services/location-energy-measurement-providers-service.js";
const LocationEnergyMeasurementProvidersService =
	new LocationEnergyMeasurementProvidersServiceClass();

export default {
	name: "AssetMonhtlyReport2FilterMonthSavingsSummary",
	props: {
		asset_filter_month_energy_measurements: Object,
		asset_filter_month_savings: Object
	},
	data() {
		return {
			filter_month_saved_c02: null,
			filter_month_saved_trees: null
		};
	},
	mounted() {
		const savings =
			LocationEnergyMeasurementProvidersService.get_saved_from_aggregated(
				this.asset_filter_month_energy_measurements
			);

		this.filter_month_saved_c02 = savings.co2;
		this.filter_month_saved_trees = savings.needed_trees_to_process_co2;
	}
};
</script>
