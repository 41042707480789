import BackendInterface from "@src/shared/infrastructure/backend-interface";
import StoreUtilClass from "@src/shared/application/utils/store-util.js";

export default class extends BackendInterface {
	constructor() {
		super();
		this.store_util = new StoreUtilClass("user_tokens", null, "user_id");
	}

	async get_user_token(user_id) {
		const previously_stored_data = await this.store_util.get_cached_item_by_id(
			user_id
		);

		if (previously_stored_data) return previously_stored_data.token;

		const url = "/api/loginas/" + user_id;
		const result = await this.get(url);

		await this.store_util.get_cached_item_by_id({
			user_id: user_id,
			token: result.token,
			end_date: new Date(result.end_date)
		});

		return result.token;
	}
}
