<template lang="html">
	<div class="full-width p-sm border-b">
		<div class="display-flex-space-between">
			<div class="display-flex">
				<div class="display-flex">
					<div class="flex-center-content">
						{{ $t("general.location") }}
					</div>
					<div class="m-l flex-vertical-center">
						<div style="min-width: 250px">
							<LocationSelect v-model="asset_id" :multiple="false" />
						</div>
					</div>
				</div>

				<div class="display-flex m-l" v-if="asset_id">
					<div class="flex-center-content">
						{{ $t("asset_reports.report_type") }}
					</div>
					<div class="m-l flex-center-content">
						<Multiselect
							id="report-type-select"
							v-model="filter.report_type"
							:multiple="false"
							:options="report_type_select_options"
							label="label"
							track_by="key"
						/>
					</div>

					<div v-if="loading" class="flex-center-content m-l">
						<Loader size="20" />
					</div>
					<div v-else-if="!filter.from || !filter.to" class="m-l">
						<Alert type="info" message="general.errors.no_data_available" />
					</div>
					<div
						v-else-if="
							filter.report_type == 'standard' ||
							filter.report_type == 'injection0'
						"
						class="display-flex"
					>
						<div class="flex-center-content m-l">
							{{ $t("general.period") }}
						</div>
						<div class="m-l-sm">
							<Datepicker
								min_view="month"
								:allow_from="min_datepicker_date"
								:allow_to="max_datepicker_date"
								v-model="filter.from"
							/>
						</div>
						<div class="flex-center-content m-l-sm m-r-sm">
							<span>/</span>
						</div>
						<div class="">
							<Datepicker
								min_view="month"
								:allow_from="min_datepicker_date"
								:allow_to="max_datepicker_date"
								v-model="filter.to"
							/>
						</div>
					</div>
					<div
						v-else-if="
							filter.report_type == 'monthly_savings' ||
							filter.report_type == 'monthly_savings_2'
						"
						class="display-flex"
					>
						<div class="flex-center-content m-l">
							{{ $t("general.period") }}
						</div>
						<div class="m-l-sm">
							<Datepicker
								min_view="month"
								:allow_from="min_datepicker_date"
								:allow_to="max_datepicker_date"
								v-model="filter.month"
							/>
						</div>
					</div>
				</div>
			</div>

			<div v-if="asset_id && filter.from && filter.to">
				<div id="buttons" class="flex-vertical-center">
					<button class="btn btn-primary" v-on:click="generate_report">
						{{ $t("asset_reports.generate_report") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LocationSelect from "@form_components/LocationSelect2.vue";

import Loader from "@loader";
import Alert from "@alert";
import Datepicker from "@form_components/Datepicker";
import Multiselect from "@form_components/Multiselect";

import moment from "moment";

import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder";
const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();

export default {
	name: "AssetReportsHeader",
	components: {
		LocationSelect,
		Loader,
		Alert,
		Datepicker,
		Multiselect
	},
	data() {
		return {
			asset_id: null,
			loading: true,
			filter: {
				report_type: "standard",
				from: null,
				to: null,
				month: null
			},
			max_datepicker_date: new Date(),
			min_datepicker_date: new Date("2020-01"),
			selected_from_date: this.from,
			selected_to_date: this.to
		};
	},
	methods: {
		async asset_id_changed() {
			try {
				this.loading = true;
				this.filter.from = null;
				this.filter.to = null;
				this.filter.month = null;

				const asset_id = JSON.parse(JSON.stringify(this.asset_id));

				this.min_datepicker_date =
					await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
						asset_id
					);
				this.max_datepicker_date =
					await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
						asset_id
					);

				if (!this.min_datepicker_date || !this.max_datepicker_date) return;

				this.filter.to = moment(this.max_datepicker_date)
					.startOf("month")
					.toDate();
				this.filter.from = moment(this.filter.to)
					.subtract(3, "months")
					.startOf("month")
					.toDate();
				this.filter.month = this.max_datepicker_date;
			} catch (error) {
			} finally {
				this.loading = false;
			}
		},
		generate_report() {
			this.$emit("generate_report", this.asset_id, this.filter);
		}
	},
	computed: {
		report_type_select_options() {
			const types = [
				"standard",
				"monthly_savings",
				"monthly_savings_2",
				"injection0"
			];
			return types.map((item) => {
				return {
					key: item,
					label: this.$t("asset_reports.report_types." + item)
				};
			});
		}
	},
	watch: {
		asset_id(new_value) {
			this.asset_id_changed();
		},
		"filter.from"(new_from_value) {
			const months_differens_from_to = moment(new_from_value).diff(
				this.filter.to,
				"months"
			);

			if (months_differens_from_to < -11) {
				this.filter.to = moment(new_from_value)
					.add(11, "months")
					.endOf("month")
					.toDate();
			} else if (months_differens_from_to > 0) {
				const from_add_3_moths = moment(new_from_value)
					.add(3, "months")
					.toDate();

				if (moment(from_add_3_moths).isBefore(this.max_datepicker_date))
					this.filter.to = this.max_datepicker_date;
				else this.filter.to = this.max_datepicker_date;
			}
		},
		"filter.to"(new_to_date) {
			const months_differens_from_from = moment(new_to_date).diff(
				this.filter.from,
				"months"
			);

			if (months_differens_from_from > 11 || new_to_date < this.filter.from) {
				this.filter.from = moment(new_to_date)
					.subtract(3, "months")
					.startOf("month")
					.toDate();
			}
		}
	}
};
</script>

<style lang="css" scoped>
#report-type-select {
	width: 250px;
}
</style>
