import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async remove_energy_system(energy_system) {
		let local_result = null;
		let backend_result;
		if (energy_system.has_provider) {
			await this.put(
				"/api/energy-data/systems/" + energy_system.id + "/disable"
			);
		} else {
			backend_result = await this.delete(
				"/api/energy-data/systems/" + energy_system.id
			);

			if (
				backend_result.assetsIdsDeleted &&
				backend_result.assetsIdsDeleted.length > 0
			) {
				local_result = {
					deleted_assets_ids: backend_result.assetsIdsDeleted
				};
			}
		}

		return local_result || true;
	}
}
