import AssetOtherDataFinderInterfaceClass from "@src/asset/infrastructure/asset-other-data-finder-interface";
import AssetOtherDataFinderValidator from "@src/asset/domain/asset-other-data-finder-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetOtherDataFinderValidator;
		this.backend_interface = new AssetOtherDataFinderInterfaceClass();
	}

	async get_asset_other_data(asset_id) {
		try {
			const interface_result =
				await this.backend_interface.get_asset_other_data(asset_id);

			const validated_result = this.validator.validate(interface_result);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_other_data",
				"general.errors.general_error",
				e
			);
		}
	}
}
