<template>
	<div>
		<table class="full-width">
			<tr class="font-bold">
				<td>
					{{
						$t("entities.energy_system.pv_data.pv_panel_models.manufacturer")
					}}
				</td>
				<td>
					{{ $t("entities.energy_system.pv_data.pv_panel_models.name") }}
				</td>
				<td>
					{{ $t("entities.energy_system.pv_data.pv_panel_models.quantity") }}
				</td>
			</tr>

			<tr v-for="pv_panel in pv_panels_data">
				<td>
					{{ pv_panel.manufacturer }}
				</td>
				<td>
					{{ pv_panel.name }}
				</td>
				<td>
					{{ pv_panel.quantity }}
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import LocationProviderServiceClass from "@services/location-services/location-energy-measurement-providers-service";
const LocationProviderService = new LocationProviderServiceClass();

export default {
	name: "EnergySystemPvPanels",
	props: {
		pv_panels: Array
	},
	data() {
		return {
			loading: false,
			error: null,
			pv_panels_data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			let result = [];

			for (let index in this.pv_panels) {
				const pv_panel = this.pv_panels[index];
				const pv_panel_data = await LocationProviderService.get_pv_model_data(
					pv_panel.id
				);

				result.push({
					manufacturer: pv_panel_data.manufacturer,
					name: pv_panel_data.pv_panel_name,
					quantity: pv_panel.quantity
				});
			}

			this.pv_panels_data = result;
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	}
};
</script>
