<template>
	<div class="flex-center-content">
		<div class="sentinel-loader" v-bind:style="loader_props">
			<hr />
			<hr />
			<hr />
			<hr />
		</div>
	</div>
</template>

<script>
export default {
	name: "Loader",
	props: ["size"],
	data() {
		return {
			current_size: this.size || 60
		};
	},
	computed: {
		loader_props() {
			return {
				width: this.current_size + "px",
				height: this.current_size + "px",
				paddingTop: this.current_size / 2 + "px",
				paddingLeft: this.current_size / 2 + "px"
			};
		}
	}
};
</script>

<style>
/* Loader */
.sentinel-loader {
	transform: translate(-50%, -50%);
}
.loader-default-size {
	width: 60px;
	height: 60px;
	padding-top: 30px;
	padding-left: 30px;
}
.sentinel-loader hr {
	border: 0;
	margin: 0;
	width: 40%;
	height: 40%;
	position: absolute;
	border-radius: 50%;
	animation: spin 2s ease infinite;
}

.sentinel-loader :first-child {
	background: rgb(74, 144, 226);
	animation-delay: -1.5s;
}
.sentinel-loader :nth-child(2) {
	background: rgb(239, 161, 49);
	animation-delay: -1s;
}
.sentinel-loader :nth-child(3) {
	background: rgb(78, 170, 175);
	animation-delay: -0.5s;
}
.sentinel-loader :last-child {
	background: rgb(229, 72, 56);
}

@keyframes spin {
	0%,
	100% {
		transform: translate(0);
	}
	25% {
		transform: translate(160%);
	}
	50% {
		transform: translate(160%, 160%);
	}
	75% {
		transform: translate(0, 160%);
	}
}
/* End loader */
</style>
