<template lang="html">
	<div>
		<TextInput
			v-model="input_search_text"
			v-on:input="search"
			:placeholder="$t('general.search')"
		/>

		<div v-if="loading" class="white-bg p-sm">
			<Loader size="30" />
		</div>
		<div
			v-else-if="results && results.length > 0"
			id="search-results"
			class="white-bg overflow-y-scroll"
		>
			<div
				v-for="(item, index) in results"
				v-bind:key="'search_results_' + index"
				v-on:click="result_selected(item)"
				class="border-b p-sm cursor-pointer"
			>
				{{ item.full_name }}
			</div>
		</div>
		<div
			v-else-if="results && results.length == 0"
			class="white-bg p-t p-l p-r p-b"
		>
			<Alert type="info" :message="'general.errors.no_results'" />
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import TextInput from "@form_components/TextInput";

import CoordinatesUtilService from "@services/utils/coordinates-util";
import GeosearchService from "@services/geosearch";

export default {
	name: "GeoSearch",
	components: {
		Loader,
		Alert,
		TextInput
	},
	data() {
		return {
			loading: false,
			input_search_text: null,
			results: null,
			debounce: null
		};
	},
	methods: {
		async search(value) {
			const text_value = value;

			clearTimeout(this.debounce);
			this.debounce = setTimeout(async () => {
				if (text_value && text_value != "") {
					this.loading = true;
					this.results = null;

					try {
						const is_text_coordinates =
							CoordinatesUtilService.get_coordinates_from_string(text_value);

						if (is_text_coordinates) {
							this.results = [
								{
									full_name: text_value,
									coordinates: is_text_coordinates
								}
							];
						} else {
							this.results = await GeosearchService.address_search(text_value);
						}
					} catch (e) {
					} finally {
						this.loading = false;
					}
				} else this.results = null;
			}, 500);
		},
		result_selected(data) {
			this.$emit("result_selected", data);
			this.results = null;
			this.input_search_text = null;
		}
	}
};
</script>

<style lang="css" scoped>
#search-results {
	position: absolute;
	width: 100%;
	top: 40px;
	max-height: 250px;
}
#search-results > div:hover {
	background-color: var(--custom-primary);
	color: white;
}
</style>
