<template lang="html">
	<div>
		<ColumnChart
			width="100%"
			height="300px"
			:categories="categories"
			:series="series"
			:y_axis_formatter="y_axis_formatter"
			:x_axis_formatter="x_axis_formatter"
			:bar_colors="['#d7d9dd', asset_reports_color]"
			:show_toolbar="false"
			:show_legend="true"
		/>
	</div>
</template>

<script>
import ColumnChart from "@sub_components/charts/ColumnChart";

export default {
	name: "ReportPvProductionChart",
	components: {
		ColumnChart
	},
	props: {
		chart_data: Object,
		asset_reports_color: String
	},
	data() {
		let categories = [];
		this.chart_data.data_dates.forEach((month_date) => {
			categories.push(month_date);
		});

		return {
			categories: categories,
			series: [
				{
					name: this.$t(
						"location_pv_estimated_production.estimated_production"
					),
					data: this.chart_data.estimated_energy_production
				},
				{
					name: this.$t("asset_reports.pv_report.page1.energy_production"),
					data: this.chart_data.energy_production
				}
			],
			y_axis_formatter: (value) => {
				return value + " kWh";
			},
			x_axis_formatter: (value) => {
				return value;
			}
		};
	}
};
</script>

<style lang="css" scoped></style>
