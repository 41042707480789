import UserValidator from "@src/user/domain/user-finder-validator.js";
import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";
import moment from "moment";

export default class {
	constructor(user_validator = UserValidator) {
		this.user_validator = user_validator;
	}

	convert_backend_user(backend_user) {
		let user = {
			first_name: backend_user.firstName,
			last_name: backend_user.lastName || null,
			email: backend_user.email,
			timezone: backend_user.timezone
		};

		if (backend_user.unitSystem) user.unit_system = backend_user.unitSystem;

		const posible_keys = ["id", "creation", "avatar", "unit_system", "company"];
		posible_keys.forEach((key) => {
			if (backend_user[key]) user[key] = backend_user[key];
		});

		//Check roles
		if (backend_user.roles) {
			user.roles = [];
			for (var key in backend_user.roles) {
				const local_role =
					BackendEntityConverter.convert_backend_value_to_local(
						"user_roles",
						backend_user.roles[key]
					);
				if (local_role) user.roles.push(local_role);
			}
		}

		if (backend_user.ticketing_settings) {
			user.ticketing_settings = {
				default_distance_cost:
					backend_user.ticketing_settings.defaultDistanceCost || undefined,
				default_hour_cost:
					backend_user.ticketing_settings.defaultHourCost || undefined
			};
		}

		if (this._user_is_maintainer(user) && backend_user.extra_data) {
			const backend_maintainer_data = backend_user.extra_data;
			user.maintainer_data = {};

			if (backend_maintainer_data.phone)
				user.maintainer_data.phone = backend_maintainer_data.phone;
			if (backend_maintainer_data.address)
				user.maintainer_data.address = backend_maintainer_data.address;
			if (backend_maintainer_data.other_contacts)
				user.maintainer_data.other_contacts =
					backend_maintainer_data.other_contacts;
			if (backend_maintainer_data.zones)
				user.maintainer_data.zones = backend_maintainer_data.zones;
			if (backend_maintainer_data.distanceCost)
				user.maintainer_data.distance_cost =
					backend_maintainer_data.distanceCost;
			if (backend_maintainer_data.hourCost)
				user.maintainer_data.hour_cost = backend_maintainer_data.hourCost;
			if (backend_maintainer_data.hasAccessAllAssets)
				user.maintainer_data.has_access_all_assets =
					backend_maintainer_data.hasAccessAllAssets;
			if (backend_maintainer_data.phone)
				user.maintainer_data.phone = backend_maintainer_data.phone;

			user.maintainer_data.assets = [];
			if (
				!backend_maintainer_data.hasAccessAllAssets &&
				backend_maintainer_data.assetIds
			) {
				backend_maintainer_data.assetIds.forEach((asset_id) => {
					user.maintainer_data.assets.push(asset_id);
				});
			}
		} else if (this._user_is_guest(user) && backend_user.extra_data) {
			user.guest_data = {
				guest_id: backend_user.extra_data.guestInternalId || null,
				phone: backend_user.extra_data.phone || null,
				address: backend_user.extra_data.address || null,
				customer_since: backend_user.extra_data.customerSince
					? moment(backend_user.extra_data.customerSince).toDate()
					: null,
				vat_number: backend_user.extra_data.vatNumber || null,
				assets: []
			};
			if (backend_user.extra_data.assetIds) {
				backend_user.extra_data.assetIds.forEach((asset_id) => {
					user.guest_data.assets.push(asset_id);
				});
			}
		}

		if (backend_user.settings) {
			if (
				backend_user.settings.assetsWatermark &&
				backend_user.settings.assetsWatermark.image
			) {
				user.assets_watermark = backend_user.settings.assetsWatermark.image;
			}

			if (backend_user.settings.customColor)
				user.custom_color = backend_user.settings.customColor;

			user.use_custom_interface = backend_user.settings.hasOwnProperty(
				"useCustomInterface"
			)
				? backend_user.settings.useCustomInterface
				: false;
		}

		return this.user_validator.validate(user);
	}

	_user_is_maintainer(user) {
		return user.roles && user.roles.indexOf("maintainer") != -1;
	}

	_user_is_guest(user) {
		return user.roles && user.roles.indexOf("guest") != -1;
	}
}
