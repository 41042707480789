<template>
	<div
		v-if="data"
		class="display-flex grey3-bg rounded-s"
		:class="{
			'p-xs': !size || size == 'medium',
			'p-xxs': size == 'small'
		}"
	>
		<button
			v-for="(item, index) in data"
			v-on:click="set_value(item.value)"
			class="font-700 p-xxs rounded-s"
			v-bind:class="{
				'm-l-xs': index > 0,
				'grey1-bg': item.value == selected_option
			}"
		>
			{{ $t(item.multilingual_label) }}
		</button>
	</div>
</template>

<script>
export default {
	name: "Switcher",
	props: {
		value: String,
		size: String, //medium(defaul) | small
		data: Array //[{multilingual_label: String, value: String}]
	},
	data() {
		return {
			selected_option: this.value
		};
	},
	methods: {
		set_value(value, emit_value = true) {
			this.selected_option = value;
			if (emit_value) this.emit_value();
		},
		emit_value() {
			this.$emit("input", this.selected_option);
		}
	},
	watch: {
		value(new_value) {
			this.set_value(new_value, false);
		}
	}
};
</script>
