import moment from "moment";

export default (time_serie, resolution_in_minutes = null) => {
	const first_element_time = time_serie[0].time;
	const last_element_time = time_serie[time_serie.length - 1].time;
	if (!resolution_in_minutes)
		resolution_in_minutes = moment(time_serie[1].time).diff(
			first_element_time,
			"minutes"
		);
	const time_serie_props_without_time = Object.keys(time_serie[0]).filter(
		(item) => item != "time"
	);

	let last_time = moment(last_element_time)
		.add(resolution_in_minutes, "minutes")
		.toDate();
	var control_var = 0;
	while (
		moment(last_time).isSame(first_element_time, "day") &&
		control_var < 150
	) {
		let time_serie_new_element = {
			time: last_time
		};

		time_serie_props_without_time.forEach((prop) => {
			time_serie_new_element[prop] = null;
		});

		time_serie.push(time_serie_new_element);

		control_var++;
		last_time = moment(last_time)
			.add(resolution_in_minutes, "minutes")
			.toDate();
	}

	return time_serie;
};
