import UserApiKeyEraserInterfaceClass from "../infrastructure/user-api-key-eraser-interface";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new UserApiKeyEraserInterfaceClass();
	}

	async user_api_key_remove(user_id) {
		try {
			await this.backend_interface.user_api_key_remove(user_id);

			return true;
		} catch (e) {
			throw new AppError(
				"user_api_key_remove",
				"general.errors.general_error",
				e
			);
		}
	}
}
