import Joi from "joi";
import Validator from "@validators/validator";

import CoordinatesValidator from "@src/shared/domain/coordinates-validator";

const asset_schema = Joi.object({
	name: Joi.string().required(),
	coordinates: CoordinatesValidator.schema.required(),
	custom_id: Joi.string()
});
export default new Validator(asset_schema, "entities.asset.");
