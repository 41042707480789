import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.array().items(
	Joi.object({
		pr: Joi.number().required(),
		month: Joi.date().required()
	})
);

export default new Validator(schema);
