import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_devices_by_system_id(energy_system_id) {
		const url = "/api/energy-data/systems/" + energy_system_id + "/devices";
		const backend_energy_devices = await this.get(url);

		return this._convert_backend_energy_devices(backend_energy_devices);
	}

	_convert_backend_energy_devices(backend_energy_devices) {
		return backend_energy_devices.map((item) => {
			let result = {
				id: item.id,
				type: item.type,
				system_id: item.systemId,
				provider_id: item.providerId || null,
				extra_data: {}
			};

			if (item.extraData) {
				const extra_data_props_names_convertion = {
					model: "model",
					serialNumber: "serial_number",
					peakPower: "peak_power",
					nominalPower: "nominal_power",
					dimensions: "dimensions",
					weight: "weight",
					maxEfficiency: "max_efficiency",
					europeanEfficiency: "european_efficiency",
					nominalVoltage: "nominal_voltage"
				};

				for (let backend_prop_name in extra_data_props_names_convertion) {
					const local_prop_name =
						extra_data_props_names_convertion[backend_prop_name];

					result.extra_data[local_prop_name] =
						typeof item.extraData[backend_prop_name] != "undefined"
							? item.extraData[backend_prop_name]
							: null;
				}
			}

			return result;
		});
	}
}
