import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendAssetConverter from "./backend-asset-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async remove_asset(asset_id) {
		const url = "/api/asset/" + asset_id;

		await this.delete(url);

		return true;
	}
}
