import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_asset_other_data(
		asset_id,
		other_data_to_update,
		asset_new_image = null
	) {
		if (asset_new_image) {
			await this._update_asset_image(asset_id, asset_new_image);
		}

		const updated_asset_other_data = await this._update_asset_other_data(
			asset_id,
			other_data_to_update
		);

		return updated_asset_other_data;
	}

	async _update_asset_other_data(asset_id, other_data_to_update) {
		const url = "/api/asset/" + asset_id + "/other-data";

		const backend_asset_to_update = {
			address: other_data_to_update.address
		};

		const backend_asset_other_data = await this.put(url, {
			body_params: backend_asset_to_update
		});

		return {
			address: backend_asset_other_data.address || null,
			asset_image_url: backend_asset_other_data.assetImageUrl || null
		};
	}

	async _update_asset_image(asset_id, asset_new_image) {
		const url = "/api/asset/" + asset_id + "/other-data/image";

		await this.post(url, {
			files: { image: asset_new_image }
		});
	}
}
