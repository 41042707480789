<template>
	<div class="display-flex-space-between grey1-bg white p-l p-r rounded-s_top">
		<div class="flex-center-content">
			<div class="p-t-xxs p-b-xxs">
				{{ this.text }}
			</div>
		</div>

		<div class="flex-center-content white">
			<router-link v-if="to.name" :to="to">
				<div>
					<Icon icon="close" size="20" />
				</div>
			</router-link>
			<div v-else-if="on_close_click">
				<div class="cursor-pointer" v-on:click="on_close_click()">
					<Icon icon="close" size="20" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PageHeader",
	props: [
		"text",
		"close_name",
		"close_params",
		"close_query",
		"on_close_click"
	],
	computed: {
		to() {
			return {
				name: this.close_name || null,
				params: this.close_params || undefined,
				query: this.close_query || undefined
			};
		}
	}
};
</script>

<style lang="css" scoped>
.page-header-close-icon {
	position: absolute;
	right: 15px;
	top: 5px;
	line-height: 25px;
}
</style>
