import Joi from "joi";
import Validator from "@validators/validator";

import EnergySystemFinderValidator from "./energy-system-finder-validator";
import AssetFinderValidator from "@src/asset/domain/asset-finder-validator";

const energy_system_creator_schema = Joi.object({
	error: Joi.string().valid(
		"system_not_exist",
		"error_occurred",
		"system_already_exists"
	),
	data: Joi.object({
		new_asset: AssetFinderValidator.schema.required(),
		new_energy_system: EnergySystemFinderValidator.schema.required(),
		need_to_review: Joi.boolean().required()
	}).when("error", {
		not: Joi.exist(),
		then: Joi.required()
	})
});
export default new Validator(
	energy_system_creator_schema,
	"entities.new_energy_system_with_provider."
);
