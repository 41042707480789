<template>
	<div class="full-width full-height">
		<div v-if="loading" class="flex-center-content full-height">
			<Loader />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="no_data_available">
			<Alert type="info" message="general.errors.no_data_available" />
		</div>
		<div v-else class="flex-auto-full-height-content gap-m">
			<div class="header">
				<AssetSavingsSummary
					v-if="
						selected_year_aggregated_energy && selected_year_aggregated_savings
					"
					:energy_summary="selected_year_aggregated_energy"
					:savings_summary="selected_year_aggregated_savings"
				/>
			</div>

			<div class="content white-bg rounded-xs p-sm">
				<div class="flex-auto-full-height-content gap-m">
					<div class="header">
						<div class="display-flex">
							<Datepicker
								v-model="selected_year"
								min_view="year"
								:allow_from="asset_energy_daya_first_date"
								:allow_to="asset_energy_daya_last_date"
							/>
						</div>
					</div>
					<div class="content" v-if="selected_year_monthly_savings">
						<AssetSavingsChart :asset_savings="selected_year_monthly_savings" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import AssetSavingsSummary from "./asset-savings-summary/AssetSavingsSummary.vue";
import Datepicker from "@form_components/Datepicker.vue";
import AssetSavingsChart from "./AssetSavingsChart.vue";

import moment from "moment";
import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder.js";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
import AssetSavingsMonthlyFinderClass from "@src/asset-savings/application/asset-savings-monthly-finder";

const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();
const AssetSavingsMonthlyFinder = new AssetSavingsMonthlyFinderClass();

export default {
	name: "AssetSavings",
	components: {
		Loader,
		Alert,
		AssetSavingsSummary,
		Datepicker,
		AssetSavingsChart
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_id: null,
			no_data_available: false,
			selected_year: null,
			asset_energy_daya_first_date: null,
			asset_energy_daya_last_date: null,

			selected_year_aggregated_energy: null,
			selected_year_monthly_savings: null,
			selected_year_aggregated_savings: null
		};
	},

	async mounted() {
		this.loading = true;

		try {
			this.asset_id = this.$route.params.asset_id;

			this.asset_energy_daya_first_date =
				await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
					this.asset_id
				);
			this.asset_energy_daya_last_date =
				await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
					this.asset_id
				);

			if (!this.asset_energy_daya_last_date) {
				this.no_data_available = true;
				return;
			}

			this.selected_year = this.asset_energy_daya_last_date;

			await this.load_data();
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async load_data() {
			this.loading = true;

			try {
				const date_from = moment(this.selected_year).startOf("year").toDate();
				const date_to = moment(this.selected_year).endOf("year").toDate();

				this.selected_year_aggregated_energy =
					await AssetMonthlyEnergyFinder.get_asset_aggregated_energy_by_date(
						this.asset_id,
						date_from,
						date_to
					);

				this.selected_year_monthly_savings =
					await AssetSavingsMonthlyFinder.get_asset_savings_monhtly_by_date(
						this.asset_id,
						date_from,
						date_to
					);

				this.selected_year_aggregated_savings =
					await AssetSavingsMonthlyFinder.get_asset_aggregated_savings_by_date(
						this.asset_id,
						date_from,
						date_to
					);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		selected_year() {
			this.load_data();
		}
	}
};
</script>
