<template lang="html">
	<div class="">
		<div v-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="success">
			<Alert message="user_settings.settings.password_updated" type="success" />
		</div>
		<div class="">
			<div class="">
				{{ $t("user_settings.settings.new_password_label") }}
			</div>
			<div class="">
				<input type="password" class="form-control" v-model="new_password" />
			</div>
		</div>
		<div class="m-t">
			<div class="">
				{{ $t("user_settings.settings.repeat_new_password_label") }}
			</div>
			<div class="">
				<input
					type="password"
					class="form-control"
					v-model="repeated_new_password"
				/>
			</div>
		</div>
		<div v-if="loading" class="m-t">
			<Loader size="30" />
		</div>
		<div v-else class="m-t text-right">
			<div
				v-if="new_password && repeated_new_password"
				class="btn btn-primary"
				v-on:click="change_user_password"
			>
				{{ $t("general.save") }}
			</div>
			<div v-else class="btn btn-disabled">
				{{ $t("general.save") }}
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import UserServiceClass from "@services/user-services/user-service";
const UserService = new UserServiceClass();

export default {
	name: "UserChangePassword",
	components: {
		Alert,
		Loader
	},
	data() {
		return {
			loading: null,
			error: null,
			success: null,
			new_password: null,
			repeated_new_password: null
		};
	},
	methods: {
		async change_user_password() {
			this.error = null;
			this.success = null;

			if (this.new_password != this.repeated_new_password) {
				this.error = "user_settings.settings.new_password_not_match";
				return;
			}

			try {
				this.loading = true;

				await UserService.change_user_password(this.new_password);
				this.success = true;

				this.new_password = null;
				this.repeated_new_password = null;

				setTimeout(() => {
					this.success = null;
				}, 10000);
			} catch (e) {
				this.error = "general.errors.general_error";
			} finally {
				this.loading = null;
			}
		}
	}
};
</script>

<style lang="css" scoped></style>
