import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

export default class {
	constructor() {
		this.store_interface = new StoreInterfaceClass();
		this.store_entity_name = "assets_last_update";
	}

	set_assets_last_update() {
		this.store_interface.set(this.store_entity_name, new Date());
	}

	get_assets_last_update() {
		return this.store_interface.get(this.store_entity_name);
	}
}
