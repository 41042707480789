<template>
	<div>
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<div v-else-if="energy_systems">
			<div class="display-flex-space-between">
				<div style="width: 300px">
					<div>{{ $t("entities.energy_system.name") }}</div>
					<Multiselect
						class="m-t-xs"
						v-model="selected_energy_system_id"
						:multiple="false"
						:options="energy_systems"
						label="name"
						track_by="id"
					/>
				</div>

				<div
					v-if="selected_energy_system_id"
					class="full-height flex-vertical-bottom"
				>
					<div>
						<button
							class="btn btn-primary"
							v-on:click="add_new_system_to_energy_data_config"
						>
							{{ $t("general.add") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Multiselect from "@form_components/Multiselect.vue";

import EnsergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder";
const EnsergySystemsFinder = new EnsergySystemsFinderClass();

export default {
	name: "EnergyDataWithNoProviderForm",
	components: {
		Loader,
		Multiselect
	},
	data() {
		return {
			loading: false,
			energy_systems: null,
			selected_energy_system_id: null
		};
	},
	async mounted() {
		try {
			this.loading = true;

			this.energy_systems = await EnsergySystemsFinder.get_user_energy_systems(
				null,
				null,
				null,
				null,
				null,
				null,
				true
			);
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		add_new_system_to_energy_data_config() {
			const selected_energy_system = this.energy_systems.find(
				(item) => item.id == this.selected_energy_system_id
			);

			const energy_data_config = {
				id: selected_energy_system.id,
				name: selected_energy_system.name,
				provider_name: null,
				system_id_in_provider: null,
				rate_contributed_generation:
					selected_energy_system.system_type == "only_generation" ||
					selected_energy_system.system_type == "generation_and_consumption"
						? 100
						: null,
				rate_contributed_consumption:
					selected_energy_system.system_type == "only_consumption" ||
					selected_energy_system.system_type == "generation_and_consumption"
						? 100
						: null,
				rate_contributed_grid_interaction: null
			};

			this.$emit("new_system_added", energy_data_config);
			this.selected_energy_system_id = null;
		}
	}
};
</script>
