<template>
	<div id="main-div" class="white-bg rounded-s box-shadow1">
		<div class="flex-auto-full-height-content">
			<PageHeader
				class="header"
				:text="$t('general.add_new')"
				close_name="owned-users"
			/>
			<div class="content p-sm relative">
				<div v-if="loading">
					<Loader size="30" />
				</div>
				<div v-else>
					<div class="message-info" v-if="error">
						<Alert :message="error" />
					</div>
					<div class="message-info" v-else-if="success">
						<Alert type="success" message="general.update_successful" />
					</div>

					<EditUser
						v-if="new_user && role"
						v-bind:class="{ 'p-t-xxxl': error || success }"
						v-model="new_user"
						:is_new_user="true"
						:user_role="this.role"
					/>
				</div>
			</div>
			<div v-if="!loading && new_user" class="footer p-sm border-t">
				<div class="flex-horizontal-right">
					<button class="btn btn-primary" v-on:click="add_user()">
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import PageHeader from "@sub_components/PageHeader";
import EditUser from "./user-form-components/EditUser";

import OwnedUserCreatorClass from "@src/user/application/owned-user-creator";
const OwnedUserCreator = new OwnedUserCreatorClass();

export default {
	name: "UserAdd",
	components: {
		Loader,
		Alert,
		PageHeader,
		EditUser
	},
	props: {
		role: String,
		selected_mananger_id: String | null
	},
	data() {
		return {
			loading: false,
			error: null,
			success: false,
			new_user: {}
		};
	},
	methods: {
		async add_user() {
			this.loading = true;
			this.error = null;

			try {
				let new_user = Object.assign({}, this.new_user);
				new_user.roles = [this.role];

				const updated_user = await OwnedUserCreator.create_user(
					new_user,
					this.selected_mananger_id
				);

				this.$router.push({
					name: "owned-user-view",
					params: { role: this.role, user_id: updated_user.id }
				});
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		role() {
			return this.$route.params.role || null;
		}
	}
};
</script>

<style lang="css" scoped>
#main-div {
	width: 600px;
	height: calc(100% - 50px);
}
.message-info {
	position: fixed;
	width: calc(600px - 30px);
	z-index: 1;
}
</style>
