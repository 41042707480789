import WeatherMeasurementsValidator from "../domain/weather-measurements-validator.js";
import AssetDayOptimalWeaherMeasurementsFinderInterfaceClass from "../infrastructure/asset-day-optimal-weather-measurements-finder-interface.js";
import FillTimeSerieDataDay from "../../shared/application/utils/fill-time-serie-data-day.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface =
			new AssetDayOptimalWeaherMeasurementsFinderInterfaceClass();
		this.validator = WeatherMeasurementsValidator;
	}

	async get_asset_day_optimal_weaher_measurements(
		asset_id,
		date,
		fill_day = false
	) {
		try {
			const interface_result =
				await this.backend_interface.get_asset_day_optimal_weaher_measurements(
					asset_id,
					date
				);

			if (!interface_result || interface_result.length == 0) return null;

			let validated_result = this.validator.validate(interface_result);

			return fill_day
				? FillTimeSerieDataDay(validated_result)
				: validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_day_optimal_weaher_measurements",
				"general.errors.general_error",
				e
			);
		}
	}

	async get_asset_optimal_weaher_measurements(
		asset_id,
		from,
		to,
		fill_day = null
	) {
		const days_diff = moment(to).diff(from, "days");
		const days_to_download = days_diff + 1;

		let result = [];
		let day_to_request = from;
		for (var index = 1; index <= days_to_download; index++) {
			const day_energy_data =
				await this.get_asset_day_optimal_weaher_measurements(
					asset_id,
					day_to_request,
					fill_day
				);

			if (!day_energy_data || day_energy_data.length == 0) return null;

			result = result.concat(day_energy_data);

			day_to_request = moment(day_to_request).add(1, "days").toDate();
		}

		return result;
	}
}
