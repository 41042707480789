<template>
	<div id="home-assets-list" class="overflow-y-scroll">
		<div>
			<div class="p-b-xs">
				<div class="p-t-xs">
					<TextInput
						v-model="filter"
						:placeholder="$t('index.filter_assets')"
						:debounce="true"
					/>
				</div>
			</div>

			<div>
				<div v-if="filtered_assets && filtered_assets.length > 0">
					<div
						v-for="(asset, index) in filtered_assets"
						v-bind:key="'asset_list_' + index"
						v-bind:class="{ 'm-b': index < filtered_assets.length - 1 }"
					>
						<AssetInList
							:asset="asset"
							:is_asset_selected="
								selected_asset_id && selected_asset_id == asset.id
							"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";
import AssetInList from "./AssetInList";

export default {
	name: "AssetsList",
	components: {
		TextInput,
		AssetInList
	},
	props: {
		assets: Array
	},
	data() {
		return {
			filter: null,
			filtered_assets: null
		};
	},
	mounted() {
		this.set_list_assets();
	},
	methods: {
		set_list_assets(filter = null) {
			const get_first_x_assets = (assets) => {
				const number_to_get = 20;
				let result = [];

				let x = 0;
				for (let index in assets) {
					const asset = Object.assign({}, assets[index]);

					result.push(asset);
					x++;

					if (number_to_get == x) break;
				}

				return result;
			};

			let assets_list_to_return = [];

			if (!filter) assets_list_to_return = get_first_x_assets(this.assets);
			else {
				var result = {};

				for (var key in this.assets) {
					if (
						//Search by assets name
						this.assets[key].name.toLowerCase().search(filter.toLowerCase()) !=
							-1 ||
						//Search by loation custom id
						(this.assets[key].custom_id &&
							this.assets[key].custom_id
								.toLowerCase()
								.search(filter.toLowerCase()) != -1) ||
						//Search by loation cups
						(this.assets[key].plant_data &&
							this.assets[key].plant_data.cups &&
							this.assets[key].plant_data.cups
								.toLowerCase()
								.search(filter.toLowerCase()) != -1)
					)
						result[key] = this.assets[key];
				}
				assets_list_to_return = get_first_x_assets(result);
			}

			this.filtered_assets = assets_list_to_return;
		}
	},
	computed: {
		selected_asset_id() {
			return this.$route.query.asset_id || null;
		}
	},
	watch: {
		selected_asset_id(new_selected_asset_id, presvious_selected_asset_id) {
			if (new_selected_asset_id) {
				this.filter = null;
				const selected_asset_id = new_selected_asset_id;
				const selected_asset = this.assets.find(
					(item) => item.id == selected_asset_id
				);

				const filtered_assets_index = this.filtered_assets.findIndex(
					(item) => item.id == selected_asset_id
				);

				if (filtered_assets_index != -1) {
					this.filtered_assets.splice(filtered_assets_index, 1);
				}

				this.filtered_assets.unshift(selected_asset);

				if (
					presvious_selected_asset_id &&
					!this.filtered_assets.find(
						(item) => item.id == presvious_selected_asset_id
					)
				) {
					const previous_selected_asset = this.assets.find(
						(item) => item.id == presvious_selected_asset_id
					);

					if (previous_selected_asset)
						this.filtered_assets.push(previous_selected_asset);
				}
			} else {
				this.set_list_assets();
			}
		},
		filter(new_value) {
			if (new_value && new_value != "") this.set_list_assets(new_value);
			else this.set_list_assets();
		}
	}
};
</script>

<style scoped>
#home-assets-list {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 2;
	max-height: calc(100% - 50px);
	width: 275px;
}
</style>
