export default (backend_asset) => {
	let local_asset = {
		id: backend_asset.id,
		name: backend_asset.name,
		coordinates: backend_asset.coordinates,
		custom_id: backend_asset.customId || null,
		features: {}
	};

	if (backend_asset.features) {
		local_asset.features = {
			has_eo_production: backend_asset.features.hasEoProduction || false,
			has_pv_production: backend_asset.features.hasPvProduction || false,
			has_energy_consumption:
				backend_asset.features.hasEnergyConsumption || false,
			has_grid_interaction: backend_asset.features.hasGridInteraction || false
		};
	}

	return local_asset;
};
