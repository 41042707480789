import AssetPvReportFinderInterfaceClass from "../infrastructure/asset-pv-report-finder-interface";
import AssetPvReportFinderValidator from "../domain/asset-pv-report-finder-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetPvReportFinderInterfaceClass();
		this.validator = AssetPvReportFinderValidator;
	}

	async get_asset_pv_report(location_id, from, to) {
		try {
			const interface_data = await this.backend_interface.get_asset_pv_report(
				location_id,
				from,
				to
			);
			const validated_data = this.validator.validate(interface_data);
			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_pv_report",
				"general.errors.general_error",
				e
			);
		}
	}
}
