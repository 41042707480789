import BackendInterface from "@src/shared/infrastructure/backend-interface";

import BackendEnergySystemConverter from "./backend-energy-system-converter";
import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async update_energy_system_general_data(
		energy_system_id,
		energy_system_general_data
	) {
		let formatted_data = {
			name: energy_system_general_data.name,
			commissioningDate: energy_system_general_data.commissioning_date
				? moment(energy_system_general_data.commissioning_date).toISOString()
				: null,
			cups: energy_system_general_data.cups || null,
			coordinates: energy_system_general_data.coordinates || null
		};

		const url = "/api/energy-data/systems/" + energy_system_id + "/common-data";
		const backend_updated_data = await this.put(url, {
			body_params: formatted_data
		});

		const local_updated_energy_system =
			BackendEnergySystemConverter(backend_updated_data);

		return local_updated_energy_system;
	}
}
