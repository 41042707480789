import CoordinatesValidator from "@src/shared/domain/coordinates-validator";

export default {
	get_coordinates_distance_in_km(lat1, lon1, lat2, lon2) {
		// Converts numeric degrees to radians
		const toRad = function (Value) {
			return (Value * Math.PI) / 180;
		};

		var R = 6371; // km
		var dLat = toRad(lat2 - lat1);
		var dLon = toRad(lon2 - lon1);
		lat1 = toRad(lat1);
		lat2 = toRad(lat2);

		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d;
	},

	get_coordinates_from_string(string) {
		const string_to_array = string.split(",");

		if (
			string_to_array.length == 2 &&
			string_to_array[0] != "" &&
			string_to_array[1] != ""
		) {
			const longitude = Number(string_to_array[1]);
			const latitude = Number(string_to_array[0]);

			const coordinates_object = {
				lat: latitude,
				lon: longitude
			};

			let error = false;
			try {
				CoordinatesValidator.validate(coordinates_object);
			} catch (e) {
				error = true;
			}

			if (error) return false;
			else return coordinates_object;
		}

		return false;
	}
};
