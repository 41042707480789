<template lang="html">
	<div>
		<div>
			<div id="asset-new-system-1st-row">
				<div>
					{{ $tc("user_settings.provider", 1) }}
				</div>
				<div>
					{{ $t("entities.energy_system.name") }}
				</div>

				<div>
					<EnergyProviderAuthsMultiselect
						v-if="can_edit_energy_provider_auth"
						v-model="new_system.energy_provider_auth"
					/>
					<EnergyDataProvidersMultiselect
						v-else
						v-model="new_system.provider_name"
						:allow_only_user_providers="true"
					/>
				</div>
				<div>
					<div v-if="loading_energy_systems">
						<Loader size="20" />
					</div>
					<Multiselect
						v-else
						v-model="new_system.selected_system"
						:multiple="false"
						:options="selected_provider_systems"
						label="name"
						track_by="id"
						:disabled="selected_provider_systems ? false : true"
					/>
				</div>
			</div>

			<div class="m-t">
				<div class="display-flex-space-between">
					<div id="asset-new-system-2nd-row">
						<div class="text-center">
							{{
								$t(
									"entities.asset_energy_data_config.rate_contributed_generation"
								)
							}}
						</div>
						<div class="text-center">
							{{
								$t(
									"entities.asset_energy_data_config.rate_contributed_consumption"
								)
							}}
						</div>
						<div class="text-center">
							<span v-if="can_edit_grid_interaction">
								{{
									$t(
										"entities.asset_energy_data_config.rate_contributed_grid_interaction"
									)
								}}
							</span>
						</div>

						<div>
							<NumberInput
								v-model="new_system.rate_contributed_generation"
								:disabled="!can_edit_generation"
								unit="%"
								:min="0"
								:max="100"
							/>
						</div>

						<div>
							<NumberInput
								v-model="new_system.rate_contributed_consumption"
								:disabled="!can_edit_consumption"
								unit="%"
								:min="0"
								:max="100"
							/>
						</div>

						<div>
							<NumberInput
								v-if="can_edit_grid_interaction"
								v-model="new_system.rate_contributed_grid_interaction"
								:disabled="!can_edit_grid_interaction"
								unit="%"
								:min="0"
								:max="100"
							/>
						</div>
					</div>
					<div class="flex-vertical-bottom">
						<button
							v-if="new_system.selected_system"
							v-on:click="add_new_system_to_energy_data_config"
							class="btn btn-primary"
						>
							{{ $t("general.add") }}
						</button>
						<button v-else class="btn btn-disabled">
							{{ $t("general.add") }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="m-t display-flex"
			v-if="
				asset_has_grid_interaction &&
				((can_edit_generation &&
					!can_edit_consumption &&
					can_edit_grid_interaction) ||
					(!can_edit_generation &&
						can_edit_consumption &&
						can_edit_grid_interaction))
			"
		>
			<div
				id="info-icon"
				class="primary-color full-rounded primary-border flex-center-content"
			>
				<Icon icon="info" size="10" />
			</div>
			<div class="m-l-xs flex-vertical-center">
				<span>
					{{ $t("asset_info.energy_systems.grid_interaction_tooltip") }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Multiselect from "@form_components/Multiselect";
import Checkbox from "@form_components/CustomCheckbox";
import NumberInput from "@form_components/NumberInput";
import EnergyProviderAuthsMultiselect from "@form_components/selectors/EnergyProviderAuthsMultiselect";
import EnergyDataProvidersMultiselect from "@form_components/selectors/EnergyDataProvidersMultiselect";

import EnsergySystemsFinderClass from "@src/energy-system/application/energy-systems-finder";
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";

const EnsergySystemsFinder = new EnsergySystemsFinderClass();
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "EnergyDataConfigForm",
	components: {
		Loader,
		Multiselect,
		Checkbox,
		NumberInput,
		EnergyProviderAuthsMultiselect,
		EnergyDataProvidersMultiselect
	},
	props: {
		can_add_new_system_with_generation: Boolean,
		can_add_new_system_with_consumption: Boolean,
		can_add_new_sistem_with_grid_interaction: Boolean,
		asset_has_grid_interaction: Boolean
	},
	data() {
		return {
			loading_energy_systems: false,
			new_system: {},
			selected_provider_systems: null,
			can_edit_energy_provider_auth: UserPermissionsUtil.can_user(
				"edit_energy_provider_auth"
			)
		};
	},
	methods: {
		async load_provider_user_systems() {
			this.loading_energy_systems = true;
			this.selected_provider_systems = null;
			this.$emit("clear_error");

			try {
				this.selected_provider_systems =
					this.new_system.energy_provider_auth != null
						? await EnsergySystemsFinder.get_user_energy_systems(
								null,
								null,
								null,
								null,
								null,
								this.new_system.energy_provider_auth
						  )
						: await EnsergySystemsFinder.get_user_energy_systems(
								null,
								this.new_system.this.new_system
						  );
			} catch (e) {
				this.$emit("error_occurred", e);
			} finally {
				this.loading_energy_systems = false;
			}
		},

		add_new_system_to_energy_data_config() {
			const provider_systems = this.selected_provider_systems;
			const selected_system_id = this.new_system.selected_system;
			const selected_system = provider_systems.find(
				(item) => item.id == selected_system_id
			);

			const new_system = {
				id: selected_system.id,
				name: selected_system.name || null,
				provider_name: selected_system.provider_data.provider_name || null,
				system_id_in_provider:
					selected_system.provider_data.system_id_in_provider,
				rate_contributed_generation: isNaN(
					this.new_system.rate_contributed_generation
				)
					? 0
					: this.new_system.rate_contributed_generation,
				rate_contributed_consumption: isNaN(
					this.new_system.rate_contributed_consumption
				)
					? 0
					: this.new_system.rate_contributed_consumption,

				rate_contributed_grid_interaction: isNaN(
					this.new_system.rate_contributed_grid_interaction
				)
					? 0
					: this.new_system.rate_contributed_grid_interaction
			};

			this.$emit("new_system_added", new_system);
			this.new_system = {};
		}
	},
	computed: {
		can_edit_generation() {
			if (
				!this.can_add_new_system_with_generation ||
				!this.new_system ||
				!this.new_system.selected_system
			)
				return false;

			if (
				(this.new_system.rate_contributed_consumption != null &&
					this.new_system.rate_contributed_grid_interaction != null) ||
				(this.can_edit_grid_interaction &&
					this.new_system.rate_contributed_consumption != null)
			) {
				this.new_system.rate_contributed_generation = null;
				return false;
			}

			const selected_system_data = this.selected_provider_systems.find(
				(item) => item.id == this.new_system.selected_system
			);
			if (!selected_system_data) return null;

			const system_type = selected_system_data.system_type;
			const system_has_generation =
				system_type == "generation_and_consumption" ||
				system_type == "only_generation";

			if (!system_has_generation) {
				this.new_system.rate_contributed_generation = null;
			}

			return system_has_generation;
		},
		can_edit_consumption() {
			if (
				!this.can_add_new_system_with_consumption ||
				!this.new_system ||
				!this.new_system.selected_system
			)
				return false;

			if (
				(this.new_system.rate_contributed_generation != null &&
					this.new_system.rate_contributed_grid_interaction != null) ||
				(this.can_edit_grid_interaction &&
					this.new_system.rate_contributed_generation != null)
			) {
				this.new_system.rate_contributed_consumption = null;
				return false;
			}

			const selected_system_data = this.selected_provider_systems.find(
				(item) => item.id == this.new_system.selected_system
			);
			if (!selected_system_data) return null;

			const system_type = selected_system_data.system_type;
			const system_has_consumption =
				system_type == "generation_and_consumption" ||
				system_type == "only_consumption";

			if (!system_has_consumption) {
				this.new_system.rate_contributed_consumption = null;
			}

			return system_has_consumption;
		},
		can_edit_grid_interaction() {
			if (
				!this.can_add_new_sistem_with_grid_interaction ||
				!this.new_system ||
				!this.new_system.selected_system
			)
				return null;

			if (
				this.new_system.rate_contributed_generation != null &&
				this.new_system.rate_contributed_consumption != null
			) {
				this.new_system.rate_contributed_grid_interaction = null;
				return false;
			}

			if (this.asset_has_grid_interaction) return true;

			const selected_system_data = this.selected_provider_systems.find(
				(item) => item.id == this.new_system.selected_system
			);

			const system_has_only_grid_interaction =
				selected_system_data.system_type == "only_grid_interaction";

			if (!system_has_only_grid_interaction) {
				this.new_system.rate_contributed_grid_interaction = null;
			}

			return system_has_only_grid_interaction;
		}
	},
	watch: {
		"new_system.provider_name"(new_value) {
			this.new_system = {
				provider_name: new_value
			};

			if (new_value) this.load_provider_user_systems();
			else this.selected_provider_systems = null;
		},
		"new_system.energy_provider_auth"(new_value) {
			this.new_system = {
				energy_provider_auth: new_value
			};

			if (new_value) this.load_provider_user_systems();
			else this.selected_provider_systems = null;
		},
		"new_system.selected_system"(new_value) {
			if (!new_value) {
				this.new_system.rate_contributed_generation = null;
				this.new_system.rate_contributed_consumption = null;
				this.new_system.rate_contributed_grid_interaction = null;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#asset-new-system-1st-row {
	display: grid;
	grid-template-columns: 200px 200px;
	column-gap: 15px;
	row-gap: 5px;
}
#asset-new-system-2nd-row {
	display: grid;
	grid-template-columns: 100px 100px 100px;
	column-gap: 15px;
	row-gap: 5px;
}
#info-icon {
	width: 20px;
	height: 20px;
}
</style>
