<template>
	<div class="gray-bg full-width full-height absolute p-b p-t-l p-l p-r">
		<div id="content">
			<div id="left-menu">
				<div>
					<div id="profile-back-button" class="flex-center-content">
						<router-link :to="{ name: 'app' }">
							<div>
								<Icon icon="chevron-left" size="18" />
							</div>
						</router-link>
					</div>

					<div class="hide-xs">
						<div class="m-t-xl">
							<router-link
								:to="{
									name: 'admin-users'
								}"
								class="light-color2 font-l"
								v-bind:class="{
									'primary-color': this.$route.name == 'admin-users'
								}"
							>
								Users
							</router-link>
						</div>
						<div class="m-t">
							<router-link
								:to="{
									name: 'admin-ui'
								}"
								class="light-color2 font-l"
								v-bind:class="{
									'primary-color': this.$route.name == 'admin-ui'
								}"
							>
								UI
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<div>
				<router-view
					class="full-width full-height full-height relative overflow-hidden"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import UserService from "@services/user-services/user-service";

export default {
	name: "User",
	computed: {
		user: function () {
			return this.$store.getters.current_user;
		},
		avatar_url: function () {
			const user_service = new UserService();
			return user_service.get_avatar_url(this.user);
		}
	}
};
</script>

<style lang="css" scoped>
#content {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: max-content auto;
}
#left-menu {
	min-width: 150px;
}
#profile-back-button {
	width: 30px;
	height: 30px;
	background-color: white;
	box-shadow: 0px 3px 17px grey;
	border-radius: 50%;
	color: black !important;
}

/*#profile-back-button {
	width: 30px;
	height: 30px;
	background-color: white;
	box-shadow: 0px 3px 17px grey;
	border-radius: 50%;
	text-align: center;
	line-height: 33px;
	position: absolute;
	left: 10px;
	top: 5px;
	color: black !important;
}
#settings-avatar {
	width: 125px;
	height: 125px;
}
#settings-avatar > div {
	line-height: 115px;
	font-size: 40px;
}
.user-table thead {
	border-bottom: 1px solid var(--grey2);
}
.user-table th {
	color: var(--grey2);
	font-weight: 500;
	font-size: 16px;
	padding: 5px 10px 15px;
}
.user-table tbody tr {
	line-height: 60px;
}
.user-table tbody td {
	padding-left: 10px;
	padding-right: 10px;
}
.user-new-popup {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 3;
}
.user-new-popup > div {
	top: 25px;
	width: 450px;
	left: calc(50% - 225px);
	max-height: calc(100% - 50px);
}*/
</style>
