import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async add_assets_to_assets_group(assets_group_id, assets_ids) {
		const url = "/api/assets-group/" + assets_group_id + "/add-assets";

		const paginated_assets_ids = this._paginate(assets_ids, 500);
		for (let index in paginated_assets_ids) {
			const assets_ids_page = paginated_assets_ids[index];
			await this.put(url, {
				body_params: {
					assetsIds: assets_ids_page
				}
			});
		}

		return true;
	}

	async remove_assets_to_assets_group(assets_group_id, assets_ids) {
		const url = "/api/assets-group/" + assets_group_id + "/remove-assets";

		const paginated_assets_ids = this._paginate(assets_ids, 500);
		for (let index in paginated_assets_ids) {
			const assets_ids_page = paginated_assets_ids[index];
			await this.put(url, {
				body_params: {
					assetsIds: assets_ids_page
				}
			});
		}

		return true;
	}

	_paginate(arr, size) {
		return arr.reduce((acc, val, i) => {
			let idx = Math.floor(i / size);
			let page = acc[idx] || (acc[idx] = []);
			page.push(val);

			return acc;
		}, []);
	}
}
