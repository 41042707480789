import UserApiKeyUpdaterInterfaceClass from "../infrastructure/user-api-key-updater-interface";
import UserApiKeyUpdaterValidator from "../domain/user-api-key-updater-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.update_validator = UserApiKeyUpdaterValidator;
		this.backend_interface = new UserApiKeyUpdaterInterfaceClass();
	}

	async user_api_key_update(user_id) {
		try {
			const interface_result = await this.backend_interface.user_api_key_update(
				user_id
			);

			const validated_result = this.update_validator.validate(interface_result);
			return validated_result;
		} catch (e) {
			throw new AppError(
				"user_api_key_update",
				"general.errors.general_error",
				e
			);
		}
	}
}
