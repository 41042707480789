<template>
	<div>
		<div v-if="loading" class="flex-center-content">
			<div class="p-sm">
				<Loader size="30" />
			</div>
		</div>
		<div v-else>
			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="success" class="p-sm">
				<Alert type="success" message="general.created_successful" />
			</div>

			<div>
				<div class="p-sm">
					<div id="request-download-form">
						<div class="flex-vertical-center">
							{{
								$t(
									"entities.energy_system_measurements_download_request.day_from"
								)
							}}
						</div>
						<div>
							<Datepicker v-model="request.day_from" :allow_to="new Date()" />
						</div>

						<div class="flex-vertical-center">
							{{
								$t(
									"entities.energy_system_measurements_download_request.day_to"
								)
							}}
						</div>
						<div>
							<Datepicker v-model="request.day_to" :allow_to="new Date()" />
						</div>

						<div class="flex-vertical-center">
							{{
								$t(
									"entities.energy_system_measurements_download_request.resolutions.minutely"
								)
							}}
						</div>
						<div>
							<Checkbox v-model="request.resolutions.minutely" />
						</div>

						<div class="flex-vertical-center">
							{{
								$t(
									"entities.energy_system_measurements_download_request.resolutions.daily"
								)
							}}
						</div>
						<div>
							<Checkbox v-model="request.resolutions.daily" />
						</div>

						<div>
							{{
								$t(
									"entities.energy_system_measurements_download_request.resolutions.monthly"
								)
							}}
						</div>
						<div>
							<Checkbox v-model="request.resolutions.monthly" />
						</div>

						<div class="flex-vertical-center">
							{{
								$t(
									"entities.energy_system_measurements_download_request.force_download"
								)
							}}
						</div>
						<div>
							<Checkbox v-model="request.force_download" />
						</div>
					</div>
				</div>
				<div class="p-sm border-t flex-horizontal-right">
					<button
						class="btn btn-primary"
						v-on:click="create_energy_system_measurements_download_request"
					>
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Datepicker from "@form_components/Datepicker.vue";
import Checkbox from "@form_components/Checkbox.vue";

import moment from "moment";
import EnergySystemMeasurementsDownloadRequestCreatorClass from "@src/admin/application/energy-system-measurements-download-request-creator.js";
const EnergySystemMeasurementsDownloadRequestCreator =
	new EnergySystemMeasurementsDownloadRequestCreatorClass();

export default {
	name: "EnergySystemCustomConfig",
	components: {
		Loader,
		Alert,
		Datepicker,
		Checkbox
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: false,
			error: null,
			success: null,
			request: {
				day_from: moment().startOf("day").toDate(),
				day_to: moment().endOf("day").toDate(),
				force_download: false,
				resolutions: {
					minutely: true,
					daily: true,
					monthly: true
				}
			},
			user_id: this.$route.params.user_id
		};
	},
	methods: {
		async create_energy_system_measurements_download_request() {
			this.loading = true;
			this.success = null;
			this.error = null;

			try {
				const request = Object.assign({}, this.request);

				await EnergySystemMeasurementsDownloadRequestCreator.create_energy_system_measurements_download_request(
					this.user_id,
					this.energy_system.id,
					request
				);

				this.success = true;
				setTimeout(() => {
					this.success = null;
				}, 8000);
			} catch (error) {
				this.error = error;

				setTimeout(() => {
					this.error = null;
				}, 8000);
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		"request.day_from"(new_value, old_value) {
			new_value = moment(new_value).startOf("day").toDate();
			if (moment(new_value).isAfter(this.request.day_to, "day"))
				this.request.day_to = moment(new_value).endOf("day").toDate();
		},
		"request.day_to"(new_value, old_value) {
			new_value = moment(new_value).endOf("day").toDate();
			if (moment(new_value).isBefore(this.request.day_from, "day"))
				this.request.day_from = moment(new_value).startOf("day").toDate();
		}
	}
};
</script>

<style scoped>
#request-download-form {
	display: grid;
	grid-template-columns: auto 300px;
	row-gap: 15px;
	column-gap: 15px;
}
</style>
