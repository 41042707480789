<template lang="html">
	<div class="display-flex">
		<div class="flex-vertical-center">
			<div
				style="width: 10px; height: 10px"
				:style="{ 'background-color': asset_reports_color }"
			></div>
		</div>

		<div class="m-l-xs font-bold" style="font-size: 12px">
			{{ label }}
		</div>
	</div>
</template>

<script>
export default {
	name: "ChartCaterogy",

	props: {
		asset_reports_color: String,
		label: String
	}
};
</script>

<style lang="css" scoped></style>
