<template lang="html">
	<div id="app" class="p-sm full-width full-height">
		<LocationsList v-if="route_is_home" />

		<router-view v-else></router-view>
	</div>
</template>

<script>
import LocationsList from "./LocationsList";

export default {
	name: "SmallDeviceHome",
	components: {
		LocationsList
	},
	computed: {
		route_is_home() {
			const current_route_name = this.$route.name;

			return current_route_name == "app";
		}
	}
};
</script>

<style lang="css" scoped>
#app {
	background-color: #f9f9f9;
}
</style>
