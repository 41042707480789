import BackendInterface from "@src/shared/infrastructure/backend-interface";
import ForecastVariables from "../../domain/forecast/forecast-variables";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_weather_forecast(asset_id) {
		const url = "/api/asset/" + asset_id + "/forecast/weather";
		const result = await this.get(url);

		return this._convert_backend_weather_forecast(result);
	}

	_convert_backend_weather_forecast(backend_weather_forecast) {
		let local_weather_forecast = {
			resolution_in_minutes: backend_weather_forecast.resolutionInMinutes,
			data: []
		};

		const backend_weather_variables_converter = {
			time: "time",
			temperature: "temperature",
			dewPoint: "dew_point",
			humidity: "humidity",
			ghi: "ghi",
			dni: "dni",
			dhi: "dhi",
			cloudCover: "cloud_cover",
			windSpeed_10m: "wind_speed_10m",
			windBearing_10m: "wind_bearing_10m",
			windGust: "wind_gust",
			precipIntensity: "precip_intensity",
			precipProbability: "precip_probability",
			visibility: "visibility",
			pressure: "pressure"
		};

		backend_weather_forecast.data.forEach((item) => {
			let local_item = {
				time: new Date(item.time)
			};

			for (let backend_variable_slug in item) {
				const local_variable_name =
					backend_weather_variables_converter[backend_variable_slug];
				if (!local_variable_name || !ForecastVariables[local_variable_name])
					continue;

				const forecast_variable_data = ForecastVariables[local_variable_name];

				let value =
					item[backend_variable_slug] != null &&
					forecast_variable_data.is_percentage
						? item[backend_variable_slug] * 100
						: item[backend_variable_slug];

				if (backend_variable_slug == "time") value = new Date(value);
				else local_item[local_variable_name] = value;
			}

			local_weather_forecast.data.push(local_item);
		});

		return local_weather_forecast;
	}
}
