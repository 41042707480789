import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async remove_user(role, user_id) {
		let url;
		switch (role) {
			case "maintainer":
				url = "/api/maintainers";
				break;
			case "guest":
				url = "/api/guests";
				break;
			case "manager":
				url = "/api/manager";
				break;
		}
		url += "/" + user_id;

		await this.delete(url);

		return true;
	}
}
