import AssetSavingsSettingsFinderInterfaceClass from "../infrastructure/asset-savings-settings-finder-interface.js";
import AssetSavingsSettingsValidator from "../domain/asset-savings-settings-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetSavingsSettingsValidator;
		this.backend_interface = new AssetSavingsSettingsFinderInterfaceClass();
	}

	async get_asset_savings_settings(asset_id) {
		try {
			const interface_result =
				await this.backend_interface.get_asset_savings_settings(asset_id);

			const validated_result = this.validator.validate(interface_result);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_savings_settings",
				"general.errors.general_error",
				e
			);
		}
	}
}
