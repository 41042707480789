<template>
	<div class="white-bg p-md relative">
		<div v-if="show_popup" class="popup">
			<router-view :refresh="refresh"></router-view>
		</div>

		<div class="full-width full-height flex-auto-full-height-content">
			<div class="header p-b-xl">
				<div class="display-flex-space-between">
					<div>
						<span class="font-bold">
							{{ $tc("user_settings.provider", 2) }}</span
						>
					</div>
					<div>
						<router-link :to="{ name: 'user-energy-data-provider-new' }">
							<div class="btn btn-primary">{{ $t("general.add_new") }} +</div>
						</router-link>
					</div>
				</div>
			</div>

			<div class="content relative">
				<div class="absolute full-width full-height">
					<div v-if="loading" class="m-t-xl">
						<Loader />
					</div>

					<div v-else-if="error" class="p-sm">
						<Alert :message="error" />
					</div>

					<div v-else-if="providers && providers.length == 0" class="m-t">
						<Alert
							type="info"
							message="user_settings.user_energy_measurement_providers.no_providers_added"
						/>
					</div>

					<DataTable
						v-else
						:items="providers"
						:fields="table_fields"
						:items_per_page="14"
					>
						<template #cell(provider)="data">
							<EnergyProviderImg :provider_name="data.value" />
						</template>

						<template #cell(links)="data">
							<router-link
								:to="{
									name: 'user-energy-data-provider-edit',
									params: { auth_id: data.item.id }
								}"
							>
								<div class="primary-color">
									<Icon icon="edit" />
								</div>
							</router-link>
						</template>
					</DataTable>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import DataTable from "@sub_components/DataTable.vue";

import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";

import EnergyProviderAuthFinderClass from "@src/energy-provider-auth/application/energy-provider-auth-finder";
const EnergyProviderAuthFinder = new EnergyProviderAuthFinderClass();

export default {
	name: "UserEnergyDataProviders",
	components: {
		Loader,
		Alert,
		DataTable,
		EnergyProviderImg
	},
	data: function () {
		return {
			loading: false,
			error: null,
			providers: undefined
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.providers =
				await EnergyProviderAuthFinder.get_energy_provider_auths();
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		show_popup() {
			const route_name = this.$route.name;
			const allowed_route_names = [
				"user-energy-data-provider-new",
				"user-energy-data-provider-edit"
			];

			const route_is_allowed = allowed_route_names.indexOf(route_name) != -1;

			return route_is_allowed;
		},
		table_fields() {
			return [
				{
					key: "provider",
					label: this.$tc("user_settings.provider", 1)
				},
				{
					key: "name",
					label: this.$t("entities.energy_provider_auth.name")
				},
				{
					key: "systems_number",
					label: this.$tc("user_settings.plant", 2)
				},
				{
					key: "last_update",
					label: this.$t("general.date"),
					class: "text-center",
					formatter: (value) => {
						return this.$format_date_to_day_hour_minute(value);
					}
				},
				{
					key: "links"
				}
			];
		}
	}
};
</script>

<style>
#new-provider {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1;
}
</style>

<style media="screen" scoped>
.provider-logo {
	max-height: 30px;
}
.popup {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 3;
}
.popup > div {
	top: 25px;
	width: 600px;
	left: calc(50% - 300px);
	max-height: calc(100% - 50px);
}

.user-table thead {
	border-bottom: 1px solid var(--grey2);
}
.user-table th {
	color: var(--grey2);
	font-weight: 500;
	font-size: 16px;
	padding: 5px 10px 15px;
}
.user-table tbody tr {
	line-height: 60px;
}
.user-table tbody td {
	padding-left: 10px;
	padding-right: 10px;
}
</style>
