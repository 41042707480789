<template>
	<div
		class="absolute full-width full-height rounded-s"
		style="background-color: rgba(0, 0, 0, 0.3)"
	>
		<div class="full-width full-height flex-center-content">
			<div style="width: 600px">
				<PageHeader
					:text="$t('general.remove')"
					close_name="assets-group-config"
				/>
				<div class="white-bg p-sm rounded-s_bottom">
					<div v-if="loading" class="p-sm">
						<Loader size="35" />
					</div>
					<div v-else-if="error" class="m-b">
						<Alert :message="error" />
					</div>
					<div v-else>
						<div class="flex-center-content">
							<div style="width: 300px" class="text-center p-t-l p-b-l">
								<span>
									{{
										$t(
											"user_settings.assets_groups_config.remove_confirmation_msg"
										)
									}}
								</span>
							</div>
						</div>
						<div class="flex-horizontal-right gap-m">
							<router-link
								:to="{
									name: 'assets-group-config'
								}"
							>
								<button class="btn btn-secondary">
									{{ $t("general.cancel") }}
								</button>
							</router-link>

							<button class="btn btn-danger" v-on:click="remove_assets_group">
								{{ $t("general.remove") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "../../../sub_components/PageHeader.vue";
import Loader from "../../../sub_components/Loader.vue";
import Alert from "../../../sub_components/Alert.vue";

import AssetsGroupEraserClass from "../../../../../../src/assets-group/application/assets-group-eraser";
const AssetsGroupEraser = new AssetsGroupEraserClass();

export default {
	name: "RemoveAssetsGroup",
	components: {
		PageHeader,
		Loader,
		Alert
	},
	data() {
		return {
			loading: false,
			error: null
		};
	},
	methods: {
		async remove_assets_group() {
			this.loading = true;

			try {
				const assets_group_id = this.$route.params.assets_group_id;
				await AssetsGroupEraser.remove_assets_group(assets_group_id);

				this.$router.push({ name: "assets-group-config" });
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>
