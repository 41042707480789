import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.array().items(
	Joi.object({
		time: Joi.date().required(),
		irradiance: Joi.number().min(0)
	})
);

export default new Validator(schema);
