<template lang="html">
	<div v-if="!loading && completed_monthly_energy">
		<ColumnChart
			width="100%"
			:height="height_in_px + 'px'"
			:series="formated_series"
			:categories="
				completed_monthly_energy.map((item) =>
					$format_date_to_month_string(item.time)
				)
			"
			:bar_colors="[asset_reports_color, '#cfd4d9', 'var(--warning-color)']"
			:multiple_y_axis="true"
			:yaxis="[
				{
					seriesName: $t('asset_energy_panel.energy_self_consumption'),
					labels: {
						formatter: (value) => $format_number(value) + ' kWh'
					}
				},
				{
					seriesName: $t('asset_energy_panel.energy_self_consumption'),
					show: false
				},
				{
					seriesName: 'GHI',
					show: false
				}
			]"
			:x_axis_formatter="(value) => value"
			:show_toolbar="false"
			column_width="20px"
			:show_legend="false"
			:custom_chart_options="{
				markers: {
					size: 7,
					shape: 'square',
					strokeColors: 'var(--warning-color)'
				},
				stroke: {
					width: 3
				}
			}"
		/>

		<div class="flex-center-content" style="margin-top: -15px">
			<div class="display-flex">
				<ChartCategory
					:asset_reports_color="asset_reports_color"
					:label="$t('asset_energy_panel.energy_self_consumption')"
				/>
				<ChartCategory
					class="m-l-xs"
					asset_reports_color="#cfd4d9"
					:label="
						$t(
							'asset_reports.asset_monthly_savings_report.page3.total_consumption'
						)
					"
				/>
				<ChartCategory
					class="m-l-xs"
					asset_reports_color="var(--warning-color)"
					label="GHI"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ColumnChart from "@sub_components/charts/ColumnChart";
import ChartCategory from "./ChartCategory";

import moment from "moment";

import AssetReanalysisWeatherFinderClass from "@src/asset-weather/application/asset-reanalysis-weather-finder.js";
const AssetReanalysisWeatherFinder = new AssetReanalysisWeatherFinderClass();

export default {
	name: "EnergyConstumptionMonthlyChart",
	components: {
		ColumnChart,
		ChartCategory
	},
	props: {
		height_in_px: Number,
		monthly_energy: Array,
		asset_reports_color: String,
		to: Date,
		asset: Object
	},
	data() {
		return {
			loading: true,
			completed_monthly_energy: null,
			formated_series: null,
			asset_monthly_reanalysis_weather: null
		};
	},
	async mounted() {
		this.loading = true;

		let completed_monthly_energy = [];
		const year = moment(this.monthly_energy[0]).format("YYYY");

		const from_month = Number(moment(this.monthly_energy[0].time).format("M"));

		for (let month = from_month; month <= 12; month++) {
			const month_data = this.monthly_energy.find(
				(item) => Number(moment(item.time).format("M")) === month
			);

			completed_monthly_energy.push(
				month_data
					? month_data
					: {
							time: moment()
								.year(year)
								.month(month - 1)
								.toDate(),
							energy_self_consumption: null,
							energy_consumption: null
					  }
			);

			if (Number(moment(this.to).format("M")) == month) break;
		}

		this.completed_monthly_energy = completed_monthly_energy;

		this.asset_monthly_reanalysis_weather =
			await AssetReanalysisWeatherFinder.get_asset_monthly_reanalysis_weaher(
				this.asset.id,
				this.completed_monthly_energy[0].time,
				this.to
			);

		this.loading = false;
	},
	computed: {
		formated_series() {
			let result = [
				{
					name: this.$t("asset_energy_panel.energy_self_consumption"),
					type: "column",
					data: this.completed_monthly_energy.map(
						(item) => item.energy_self_consumption || null
					)
				},
				{
					name: this.$t("asset_energy_panel.energy_consumption"),
					type: "column",
					data: this.completed_monthly_energy.map(
						(item) => item.energy_consumption || null
					)
				}
			];

			if (
				this.asset_monthly_reanalysis_weather &&
				this.asset_monthly_reanalysis_weather.length > 0
			) {
				result.push({
					name: "GHI",
					type: "line",
					data: this.asset_monthly_reanalysis_weather.map(
						(item) => item.ghi || null
					)
				});
			}

			return result;
		}
	}
};
</script>

<style lang="css" scoped></style>
