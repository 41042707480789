import AssetEnergyDataConfigInterfaceClass from "../infrastructure/asset-energy-data-config-finder-interface";
import AssetEnergyDataConfigFinderValidator from "../domain/asset-energy-data-config-finder-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergyDataConfigInterfaceClass();
		this.validator = AssetEnergyDataConfigFinderValidator;
	}

	async get_asset_energy_data_configuration(asset_id) {
		try {
			const asset_provider_data =
				await this.backend_interface.get_asset_energy_data_configuration(
					asset_id
				);

			const validated_asset_provider_data =
				this.validator.validate(asset_provider_data);
			return validated_asset_provider_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_data_configuration",
				"general.errors.general_error",
				e
			);
		}
	}
}
