<template lang="html">
	<div class="full-width full-height">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else-if="devices" class="full-width full-height">
			<div
				id="selected-device"
				class="flex-center-content"
				v-if="selected_device"
			>
				<EnergyDeviceInfo
					:energy_device="selected_device"
					v-on:close="selected_device = null"
				/>
			</div>

			<div class="flex-auto-full-height-content p-sm">
				<div class="header m-b p-b-xxs border-b font-bold">
					<span>{{ $t("user_settings.energy_systems_manager.devices") }}</span>
				</div>

				<div class="content">
					<div class="flex-auto-full-height-content">
						<div class="content relative">
							<div class="absolute full-width full-height overflow-y-scroll">
								<DataTable
									:items="devices"
									:items_per_page="30"
									:fields="table_columns"
								>
									<template #cell(options)="data">
										<div
											class="primary-color cursor-pointer"
											v-on:click="selected_device = data.item"
										>
											<Icon icon="eye" size="25" />
										</div>
									</template>
								</DataTable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import EnergyDeviceInfo from "./EnergyDeviceInfo";
import DataTable from "@sub_components/DataTable.vue";

import EnergyDevicesFinderClass from "@src/energy-device/application/energy-devices-finder.js";
const EnergyDevicesFinder = new EnergyDevicesFinderClass();

export default {
	name: "EnergySystemDevices",
	components: {
		Loader,
		Alert,
		EnergyDeviceInfo,
		DataTable
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: true,
			error: null,
			devices: null,
			selected_device: null
		};
	},
	async mounted() {
		try {
			this.loading = true;

			this.devices = await EnergyDevicesFinder.get_energy_devices_by_system_id(
				this.energy_system.id
			);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	computed: {
		table_columns() {
			return [
				{
					key: "extra_data.model",
					label: this.$t("entities.energy_device.extra_data.model"),
					sortable: true
				},
				{
					key: "extra_data.serial_number",
					label: this.$t("entities.energy_device.extra_data.serial_number"),
					sortable: true
				},
				{
					key: "options",
					class: "text-center",
					label: this.$t("user_settings.energy_systems_manager.data_sheet")
				}
			];
		}
	}
};
</script>

<style lang="css" scoped>
#selected-device {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 3;
}
</style>
