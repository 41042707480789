<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script></script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700&display=swap");
@import "assets/general.css";
@import "assets/style.css";
@import "assets/responsive.css";

:root {
	--main-font-family: "Montserrat";
}

#app {
	font-family: var(--main-font-family), sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
#app,
#app {
	height: 100%;
	margin: 0;
	overflow: hidden;
}
</style>
