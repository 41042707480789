<template lang="html">
	<div id="energy-system-savings-settings">
		<PageHeader
			:text="$t('user_settings.energy_systems_manager.savings.price_rates')"
			:on_close_click="close"
			class="rounded-s_top"
		/>
		<div class="p-sm white-bg rounded-s_bottom">
			<div v-if="loading">
				<Loader size="30" />
			</div>
			<div v-else-if="mounted_error">
				<Alert :message="mounted_error" />
			</div>
			<div v-else-if="energy_from_grid_price_rates">
				<div v-if="save_error" class="m-b">
					<Alert :message="save_error" />
				</div>
				<div v-else-if="success" class="m-b">
					<Alert message="general.update_successful" type="success" />
				</div>
				<div class="font-bold border-b m-b-xs p-b-xxs">
					{{ $t("user_settings.energy_systems_manager.savings.tariff") }}
				</div>
				<div>
					<Multiselect
						v-model="savings.energy_from_grid_price_rate_id"
						:multiple="false"
						:placeholder="
							$t('user_settings.energy_systems_manager.savings.select_tariff')
						"
						label="name"
						track_by="id"
						:options="energy_from_grid_price_rates"
					/>
				</div>
				<div v-if="selected_price_rate_prices" class="m-t">
					<div class="font-bold border-b m-b-xs p-b-xxs">
						{{ $t("user_settings.energy_systems_manager.periods") }}
					</div>
					<div id="price_rate_prices">
						<div v-for="(price_rate, index) in selected_price_rate_prices">
							<div class="font-bold">{{ price_rate.name }}</div>
							<div>
								<NumberInput
									v-model="savings.custom_price_types[index].price"
									:placeholder="price_rate.price"
									unit="€/kWh"
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="savings.energy_from_grid_price_rate_id"
					class="m-t flex-horizontal-right"
				>
					<button class="btn btn-primary" v-on:click="save">
						{{ $t("general.save") }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";
import Multiselect from "@form_components/Multiselect";
import NumberInput from "@form_components/NumberInput";

import EnergyFromGridPriceRatesFinderClass from "@src/price-rate/application/energy-from-grid-price-rates-finder";
import EnergySystemSavingsSettingsUpdaterClass from "@src/energy-system/application/energy-system-savings-settings-updater";

const EnergyFromGridPriceRatesFinder =
	new EnergyFromGridPriceRatesFinderClass();
const EnergySystemSavingsSettingsUpdater =
	new EnergySystemSavingsSettingsUpdaterClass();

export default {
	name: "EnergySystemSavingsSettings",
	components: {
		PageHeader,
		Loader,
		Alert,
		Multiselect,
		NumberInput
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: false,
			mounted_error: null,
			save_error: null,
			success: false,
			energy_from_grid_price_rates: null,
			savings: {
				energy_from_grid_price_rate_id: null,
				custom_price_types: []
			}
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.energy_from_grid_price_rates =
				await EnergyFromGridPriceRatesFinder.get_energy_from_grid_price_rates();

			this.set_energy_system_savings_settings();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		close() {
			this.$emit("closed");
		},
		set_energy_system_savings_settings() {
			if (this.energy_system.savings_settings) {
				this.savings = Object.assign({}, this.energy_system.savings_settings);

				if (
					this.savings.energy_from_grid_price_rate_id &&
					this.savings.custom_price_types
				) {
					const selected_price_rate = this.energy_from_grid_price_rates.find(
						(item) => item.id == this.savings.energy_from_grid_price_rate_id
					);

					if (
						selected_price_rate.price_types.length !=
						this.savings.custom_price_types.length
					) {
						this.savings.custom_price_types =
							selected_price_rate.price_types.map((item) => {
								const savings_price_type = this.savings.custom_price_types.find(
									(price) => price.id == item.id
								);

								let result = {
									id: item.id,
									price: savings_price_type ? savings_price_type.price : null
								};
								return result;
							});
					}
				}
			}
		},
		async save() {
			this.loading = true;
			this.save_error = null;

			try {
				let savings_to_update = Object.assign({}, this.savings);
				savings_to_update.custom_price_types =
					savings_to_update.custom_price_types.filter(
						(item) => item.price != null
					);

				const result =
					await EnergySystemSavingsSettingsUpdater.update_energy_system_settings_savings(
						this.energy_system.id,
						savings_to_update
					);

				this.energy_system.savings_settings = result.savings_settings;

				this.success = true;
				setTimeout(() => {
					this.success = false;
				}, 6000);
			} catch (error) {
				this.save_error = error;
				setTimeout(() => {
					this.save_error = null;
				}, 8000);
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		selected_price_rate_prices() {
			if (!this.savings.energy_from_grid_price_rate_id) return null;

			const price_rate = this.energy_from_grid_price_rates.find(
				(item) => item.id == this.savings.energy_from_grid_price_rate_id
			);

			return price_rate.price_types;
		}
	},
	watch: {
		selected_price_rate_prices(new_price_types, old_price_types) {
			if (
				!old_price_types &&
				this.savings.custom_price_types &&
				this.savings.custom_price_types.length > 0
			)
				return null;

			this.savings.custom_price_types = [];
			new_price_types.forEach((item) => {
				this.savings.custom_price_types.push({
					id: item.id,
					price: null
				});
			});
		}
	}
};
</script>

<style lang="css" scoped>
#energy-system-savings-settings {
	width: 600px;
}
#price_rate_prices {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 15px;
}
</style>
