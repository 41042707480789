import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_savings_total(asset_id) {
		const url = "/api/asset/" + asset_id + "/savings/total";
		const backend_energy_system = await this.get(url);

		return {
			total_savings: backend_energy_system.savingsTotal,
			self_consumption_savings: backend_energy_system.savingsSelfConsumption,
			to_grid_savings: backend_energy_system.savingsToGrid
		};
	}
}
