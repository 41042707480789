import LocalObjectConverter from "../../../shared/infrastructure/backend-converters/local-object-converter";

export default (provider, data) => {
	var result = {
		name: data.name || null
	};

	switch (provider) {
		case "enphase-v4":
			result.auth = {
				planType: data.auth.plan_type,
				clientId: data.auth.client_id,
				clientSecret: data.auth.client_secret,
				apiKey: data.auth.api_key
			};

			switch (data.auth.plan_type) {
				case "developer":
					result.auth.userCode = data.auth.user_code;
					result.auth.redirectUri = data.auth.redirect_uri;
					break;
				case "partner":
					result.auth.username = data.auth.username;
					result.auth.password = data.auth.password;
					break;
				default:
					break;
			}
			break;
		case "datadis":
			result.auth = {
				password: data.auth.password,
				username: data.auth.username,
				authorizedNifs: data.auth.authorized_nifs || []
			};
			break;
		default:
			result.auth = LocalObjectConverter(data.auth);
	}
	return result;
};
