<template>
	<div v-if="position" class="power-balls" v-bind:style="style">
		<PowerBall
			v-if="bubbles_start"
			:power_ball_direction="power_ball_direction"
		/>
		<PowerBall
			v-if="bubbles_start"
			:delay_in_sec="0.5"
			:power_ball_direction="power_ball_direction"
		/>
		<PowerBall
			v-if="bubbles_start"
			:delay_in_sec="1"
			:power_ball_direction="power_ball_direction"
		/>
		<PowerBall
			v-if="bubbles_start"
			:delay_in_sec="1.5"
			:power_ball_direction="power_ball_direction"
		/>
	</div>
</template>

<script>
import PowerBall from "./PowerBall.vue";

export default {
	name: "AssetCurrentPowerBalls",
	components: {
		PowerBall
	},
	props: {
		position: String, //top-left | top-right | bottom-left  | bottom-right
		diagonal_start: String, //top | bottom
		bubbles_start: String // left | right
	},
	data() {
		let style = {};

		switch (this.position) {
			case "top-left":
				style.left = "25%";
				style.top = "calc(20% + 10px)";
				break;
			case "top-right":
				style.right = "25%";
				style.top = "calc(20% + 10px)";
				break;
			case "bottom-left":
				style.left = "25%";
				style.bottom = "calc(20% + 10px)";
				break;
			case "bottom-right":
				style.right = "25%";
				style.bottom = "calc(20% + 10px)";
				break;
		}

		style["--orientation"] = this.diagonal_start == "bottom" ? "left" : "right";

		let power_ball_direction;
		switch (this.bubbles_start) {
			case "left":
				power_ball_direction =
					this.diagonal_start == "bottom"
						? "diagonal-bottom-top"
						: "diagonal-top-bottom";
				break;
			case "right":
				power_ball_direction =
					this.diagonal_start == "bottom"
						? "diagonal-bottom-top-negative"
						: "diagonal-top-bottom-negative";
				break;
		}

		return {
			style: style,
			power_ball_direction: power_ball_direction
		};
	}
};
</script>

<style scoped>
.power-balls {
	position: absolute;
	width: 25%;
	height: calc(30% - 10px);

	background: linear-gradient(
		to top var(--orientation),
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) calc(50% - 0.8px),
		rgb(181, 181, 181) 50%,
		rgba(0, 0, 0, 0) calc(50% + 0.8px),
		rgba(0, 0, 0, 0) 100%
	);
}
</style>
