<template>
	<div class="full-height">
		<button class="btn btn-primary full-height" v-on:click="export_data">
			<Icon size="18" icon="export" />
		</button>
	</div>
</template>

<script>
export default {
	name: "ExportAssetsGroupAssetsSummaryButton",
	props: {
		assets_group_assets_summary: Array,
		has_assets_with_multiple_systems: Boolean
	},
	methods: {
		export_data() {
			let result = [
				[
					this.$t("entities.assets_group_asset_summary.status_provided"),
					this.$t("entities.asset.name"),
					this.$t("entities.asset.custom_id"),
					this.$t(
						"entities.assets_group_asset_summary.latest_measurements.today_energy_production"
					) + " (kWh)",
					this.$t("entities.energy_system.pv_data.peak_power") + " (kWp)",
					this.$t("entities.energy_system.pv_data.nominal_power") + " (kW)",
					this.$t("entities.assets_group_asset_summary.pv_data.pr") + "(%)",
					this.$t("entities.assets_group_asset_summary.provider_names")
				]
			];

			this.assets_group_assets_summary.forEach((item) => {
				result.push([
					item.status_provided
						? this.$t(
								"dashboard.asset_status_descriptions." + item.status_provided
						  )
						: null,
					item.asset_name,
					item.asset_custom_id,
					item.latest_measurements
						? item.latest_measurements.today_energy_production
						: null,
					item.pv_data && this.$is_number(item.pv_data.peak_power)
						? this.$format_number(item.pv_data.peak_power, 2)
						: null,
					item.pv_data && this.$is_number(item.pv_data.nominal_power)
						? this.$format_number(item.pv_data.nominal_power, 2)
						: null,
					item.pv_data && this.$is_number(item.pv_data.pr)
						? this.$format_number(item.pv_data.pr, 0)
						: null,
					item.provider_names && item.provider_names.length > 0
						? item.provider_names
								.map((provider_slug) =>
									this.$get_energy_provider_full_name(provider_slug)
								)
								.join(" ")
						: null
				]);
			});

			this.$download_array_as_xlsx_file(result, "Export.xlsx");
		}
	}
};
</script>
