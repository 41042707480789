<template lang="html">
	<div>
		<div>
			<span>{{ $t("entities.energy_provider_auth.auth.plan_type") }}</span>
			<Multiselect
				class="m-t-xs"
				v-model="plan_type"
				:options="[
					{
						label: 'Developer',
						key: 'developer'
					},
					{
						label: 'Partner',
						key: 'partner'
					}
				]"
				track_by="key"
				label="label"
			/>
		</div>

		<div v-if="plan_type">
			<div class="m-t">
				<span>{{ $t("entities.energy_provider_auth.auth.client_id") }}</span>
				<TextInput class="m-t-xs" v-model="client_id" />
			</div>

			<div class="m-t">
				<span>{{
					$t("entities.energy_provider_auth.auth.client_secret")
				}}</span>
				<TextInput class="m-t-xs" v-model="client_secret" />
			</div>

			<div class="m-t">
				<span>{{ $t("entities.energy_provider_auth.auth.api_key") }}</span>
				<TextInput class="m-t-xs" v-model="api_key" />
			</div>

			<div v-if="plan_type == 'developer'">
				<div class="m-t">
					<span>{{ $t("entities.energy_provider_auth.auth.user_code") }}</span>
					<TextInput class="m-t-xs" v-model="user_code" />
				</div>

				<div class="m-t">
					<span>
						{{ $t("entities.energy_provider_auth.auth.redirect_uri") }}
					</span>
					<TextInput class="m-t-xs" v-model="redirect_uri" />
				</div>
			</div>
			<div v-else-if="plan_type == 'partner'">
				<div class="m-t">
					<span>
						{{ $t("entities.energy_provider_auth.auth.username") }}
					</span>
					<TextInput class="m-t-xs" v-model="username" />
				</div>

				<div class="m-t">
					<span>
						{{ $t("entities.energy_provider_auth.auth.password") }}
					</span>
					<TextInput class="m-t-xs" v-model="password" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";
import Multiselect from "@form_components/Multiselect.vue";

export default {
	name: "EnphaseV4LoginForm",
	props: { editing: Boolean },
	components: {
		TextInput,
		Multiselect
	},
	data() {
		return {
			plan_type: null,
			client_id: null,
			client_secret: null,
			api_key: null,

			//plan_type == developer
			user_code: null,
			redirect_uri: null,

			//plan_type == partner
			username: null,
			password: null
		};
	},
	mounted() {
		this.emit_value();
	},
	methods: {
		emit_value() {
			let value_to_emit = {
				plan_type: this.plan_type,
				client_id: this.client_id,
				client_secret: this.client_secret,
				api_key: this.api_key
			};

			switch (this.plan_type) {
				case "developer":
					value_to_emit.user_code = this.user_code;
					value_to_emit.redirect_uri = this.redirect_uri;
					break;
				case "partner":
					value_to_emit.username = this.username;
					value_to_emit.password = this.password;
					break;
			}

			this.$emit("input", value_to_emit);
		}
	},
	watch: {
		plan_type: function () {
			this.emit_value();
		},
		client_id: function () {
			this.emit_value();
		},
		client_secret: function () {
			this.emit_value();
		},
		api_key: function () {
			this.emit_value();
		},
		user_code: function () {
			this.emit_value();
		},
		redirect_uri: function () {
			this.emit_value();
		},
		username: function () {
			this.emit_value();
		},
		password: function () {
			this.emit_value();
		}
	}
};
</script>
