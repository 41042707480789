import BackendInterface from "@src/shared/infrastructure/backend-interface";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async upload_user_avatar(user_id, avatar_file) {
		const url = "/api/user/" + user_id + "/avatar";
		const result = await this.post(url, {
			files: { avatar: avatar_file }
		});
		return result;
	}
}
