import EmailNotificationsSettingsFinderInterfaceClass from "../infrastructure/email-notifications-settings-finder-interface";
import EmailNotificationsSettingsValidator from "../domain/email-notifications-settings-validator";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface =
			new EmailNotificationsSettingsFinderInterfaceClass();
		this.validator = EmailNotificationsSettingsValidator;
	}

	async get_email_notifications_settings() {
		try {
			const email_notifications_settings =
				await this.backend_interface.get_email_notifications_settings();

			const validated_data = this.validator.validate(
				email_notifications_settings
			);

			return validated_data;
		} catch (error) {
			throw new AppError(
				"error_get_email_notifications_settings",
				"general.errors.general_error",
				error
			);
		}
	}
}
