import Joi from "joi";
import Validator from "@validators/validator";

import CoordinatesValidator from "@src/shared/domain/coordinates-validator";

const asset_schema = Joi.object({
	id: Joi.string().required(),
	name: Joi.string().required(),
	coordinates: CoordinatesValidator.schema.required(),
	custom_id: Joi.string(),
	features: Joi.object({
		has_pv_production: Joi.boolean(),
		has_energy_consumption: Joi.boolean(),
		has_grid_interaction: Joi.boolean(),
		has_eo_production: Joi.boolean()
	})
});
export default new Validator(asset_schema);
