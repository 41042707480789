export const ValidStringsValidation = valid_values => {
	return (value_to_validate, helpers) => {
		//If value_to_validate not in models throw error
		if (valid_values.indexOf(value_to_validate) == -1) {
			return helpers.error("any.invalid", {
				valids: valid_values
			});
		}
		// Return the value unchanged
		return value_to_validate;
	};
};
