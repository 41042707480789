<template>
	<div class="power-ball" v-bind:style="style"></div>
</template>

<script>
export default {
	name: "PowerBall",
	props: {
		delay_in_sec: Number,
		power_ball_direction: String //diagonal-top-bottom | diagonal-top-bottom-negative | diagonal-bottom-top | diagonal-bottom-top-negative
	},
	data() {
		let style = {
			"-webkit-animation": this.power_ball_direction + " 1.5s infinite",
			"-moz-animation": this.power_ball_direction + " 1.5s infinite",
			"-o-animation": this.power_ball_direction + " 1.5s infinite",
			animation: this.power_ball_direction + " 1.5s infinite",
			"animation-timing-function": "linear"
		};

		if (this.delay_in_sec) style["animation-delay"] = this.delay_in_sec + "s";

		switch (this.power_ball_direction) {
			case "diagonal-top-bottom":
				style.top = "-5px";
				style.left = "-5px";
				break;

			case "diagonal-top-bottom-negative":
				style.top = "calc(100% - 5px)";
				style.left = "calc(100% - 5px)";
				break;
			case "diagonal-bottom-top":
				style.top = "calc(100% - 5px)";
				style.right = "calc(100% - 5px)";
				break;
			case "diagonal-bottom-top-negative":
				style.top = "calc(100% - 5px)";
				style.right = "calc(100% - 5px)";
				break;
		}

		return {
			style: style
		};
	}
};
</script>

<style>
.power-ball {
	position: absolute;
	background-color: #4a5060;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	animation-timing-function: linear;
}
@keyframes diagonal-top-bottom {
	from {
		top: -5px;
		left: -5px;
	}
	to {
		top: calc(100% - 5px);
		left: calc(100% - 5px);
	}
}
@keyframes diagonal-top-bottom-negative {
	from {
		top: calc(100% - 5px);
		left: calc(100% - 5px);
	}
	to {
		top: -5px;
		left: -5px;
	}
}

@keyframes diagonal-bottom-top {
	from {
		top: calc(100% - 5px);
		right: calc(100% - 5px);
	}
	to {
		top: -5px;
		right: -5px;
	}
}
@keyframes diagonal-bottom-top-negative {
	from {
		top: -5px;
		right: -5px;
	}
	to {
		top: calc(100% - 5px);
		right: calc(100% - 5px);
	}
}
</style>
