<template lang="html">
	<div
		class="display-flex-space-between p-b-xs"
		style="border-bottom: 1px solid #dadada"
	>
		<div class="text-uppercase font-700" style="font-size: 22px">
			<span v-if="title">{{ title }}</span>
		</div>
		<div>
			<img v-if="user_header_logo" :src="user_header_logo" height="30px" />
			<Base64Img v-else :src="sentinel_header_logo" image_height="30px" />
		</div>
	</div>
</template>

<script>
import Base64Img from "@sub_components/Base64Img";

import UserFinderClass from "@src/user/application/user-finder.js";
const UserFinder = new UserFinderClass();

export default {
	name: "ReportPagesHeader",
	props: {
		title: String
	},
	components: {
		Base64Img
	},
	data() {
		return {
			sentinel_header_logo: "/img/sentinel-solar-logo-v2-grey.png",
			user_header_logo: null,
			logos_height_in_px: 40
		};
	},
	async mounted() {
		const user = await UserFinder.get_user();

		if (user.assets_watermark) this.user_header_logo = user.assets_watermark;
	}
};
</script>

<style lang="css" scoped></style>
