import OwnedUserEraserInterface from "../infrastructure/owned-user-eraser-interface";
import OwnedUsersStoreUtilClass from "./utils/owned-users-store-util";
import OwnedManagerUsersStoreUtilClass from "./utils/owned-manager-users-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new OwnedUserEraserInterface();
	}

	async remove_user(user_role, user_id, manager_id = null) {
		try {
			await this.backend_interface.remove_user(user_role, user_id);

			const store_util = manager_id
				? new OwnedManagerUsersStoreUtilClass(manager_id, user_role)
				: new OwnedUsersStoreUtilClass(user_role);
			await store_util.remove_item(user_id);

			return true;
		} catch (e) {
			throw new AppError(
				"error_remove_user",
				"general.errors.general_error",
				e
			);
		}
	}
}
