import BackendInterface from "@src/shared/infrastructure/backend-interface";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_pv_report(asset_id, from, to) {
		const url = "/api/asset/" + asset_id + "/pv-report";
		let rest_request_params = {
			from: moment(from).format("YYYY-MM"),
			to: moment(to).format("YYYY-MM")
		};

		const backend_pv_report = await this.post(url, {
			query_params: rest_request_params
		});

		const local_pv_report =
			this._convert_backend_asset_pv_report_to_local(backend_pv_report);

		return local_pv_report;
	}

	_convert_backend_asset_pv_report_to_local(backend_pv_report) {
		let local_pv_report = {
			energy_production_summary: null,
			savings_summary: null
		};

		if (backend_pv_report.energyProductionSummary) {
			const backend_report_production_summary =
				backend_pv_report.energyProductionSummary;

			local_pv_report.energy_production_summary = {
				energy_unit: backend_report_production_summary.energyUnit,
				selected_period_energy_production: []
			};

			backend_report_production_summary.selectedPeriodEnergyProduction.forEach(
				(item) => {
					local_pv_report.energy_production_summary.selected_period_energy_production.push(
						{
							date: item.date,
							estimated_energy_production:
								item.estimatedEnergyProduction || null,
							energy_production: item.energyProduction || null,
							energy_production_variation:
								item.energyProductionVariation || null
						}
					);
				}
			);

			if (
				backend_report_production_summary.selectedPeriodEnergyProductionSummary
			) {
				const backend_selected_period_energy_production_summary =
					backend_report_production_summary.selectedPeriodEnergyProductionSummary;

				local_pv_report.energy_production_summary.selected_period_energy_production_summary =
					{
						energy_production:
							backend_selected_period_energy_production_summary.energyProduction ||
							null,
						energy_production_peak_power_ratio:
							backend_selected_period_energy_production_summary.energyProductionPeakPowerRatio ||
							null,
						energy_production_variation:
							backend_selected_period_energy_production_summary.energyProductionVariation ||
							null,
						estimated_energy_production:
							backend_selected_period_energy_production_summary.estimatedEnergyProduction ||
							null
					};
			}

			if (
				backend_report_production_summary.fromStartOfAvailableDataToEndOfSelectedPeriodEnergyProductionSummary
			) {
				const backend_from_start_of_available_data_to_end_of_selected_period_energy_production_summary =
					backend_report_production_summary.fromStartOfAvailableDataToEndOfSelectedPeriodEnergyProductionSummary;

				local_pv_report.energy_production_summary.from_start_of_available_data_to_end_of_selected_period_energy_production_summary =
					{
						energy_production:
							backend_from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energyProduction ||
							null,
						energy_production_eq_1_year_families_consumption:
							backend_from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energyProductionEq1YearFamiliesConsumption ||
							null,
						energy_production_variation:
							backend_from_start_of_available_data_to_end_of_selected_period_energy_production_summary.energyProductionVariation ||
							null,
						estimated_energy_production:
							backend_from_start_of_available_data_to_end_of_selected_period_energy_production_summary.estimatedEnergyProduction ||
							null
					};
			}

			if (
				backend_report_production_summary.fromYearStartToEndOfSelectedPeriodEnergyProductionSummary
			) {
				const backend_from_year_start_to_end_of_selected_period_energy_production_summary =
					backend_report_production_summary.fromYearStartToEndOfSelectedPeriodEnergyProductionSummary;

				local_pv_report.energy_production_summary.from_year_start_to_end_of_selected_period_energy_production_summary =
					{
						energy_consumption:
							backend_from_year_start_to_end_of_selected_period_energy_production_summary.energyConsumption ||
							null,
						energy_production:
							backend_from_year_start_to_end_of_selected_period_energy_production_summary.energyProduction ||
							null,
						energy_self_consumption:
							backend_from_year_start_to_end_of_selected_period_energy_production_summary.energySelfConsumption ||
							null,
						energy_self_consumption_eq_1_year_families_consumption:
							backend_from_year_start_to_end_of_selected_period_energy_production_summary.energySelfConsumptionEq1YearFamiliesConsumption !=
							undefined
								? backend_from_year_start_to_end_of_selected_period_energy_production_summary.energySelfConsumptionEq1YearFamiliesConsumption
								: null
					};
			}
		}

		if (backend_pv_report.savingsSummary) {
			const backend_savings_summary = backend_pv_report.savingsSummary;
			local_pv_report.savings_summary = {};

			if (backend_savings_summary.massUnit)
				local_pv_report.savings_summary.mass_unit =
					backend_savings_summary.massUnit;

			if (backend_savings_summary.selectedPeriodSavings) {
				local_pv_report.savings_summary.selected_period_savings = [];

				backend_savings_summary.selectedPeriodSavings.forEach(
					(backend_item) => {
						local_pv_report.savings_summary.selected_period_savings.push({
							date: new Date(backend_item.date),
							c02: backend_item.c02,
							petrol: backend_item.petrol
						});
					}
				);
			}

			if (backend_savings_summary.selectedPeriodSavingsSummary) {
				local_pv_report.savings_summary.selected_period_savings_summary = {
					c02: backend_savings_summary.selectedPeriodSavingsSummary.c02,
					mass_unit:
						backend_savings_summary.selectedPeriodSavingsSummary.massUnit,
					petrol: backend_savings_summary.selectedPeriodSavingsSummary.petrol
				};
			}

			if (
				backend_savings_summary.fromStartOfAvailableDataToEndOfSelectedPeriodSavingsSummary
			) {
				local_pv_report.savings_summary.from_start_of_available_data_to_end_of_selected_period_savings_summary =
					{
						c02: backend_savings_summary
							.fromStartOfAvailableDataToEndOfSelectedPeriodSavingsSummary.c02,
						mass_unit:
							backend_savings_summary
								.fromStartOfAvailableDataToEndOfSelectedPeriodSavingsSummary
								.massUnit,
						petrol:
							backend_savings_summary
								.fromStartOfAvailableDataToEndOfSelectedPeriodSavingsSummary
								.petrol
					};
			}
		}

		return local_pv_report;
	}
}
