import Joi from "joi";
import Validator from "@validators/validator";

const energy_system_schema = Joi.object({
	id: Joi.string().required(),
	name: Joi.string().required(),
	price_types: Joi.array()
		.required()
		.items(
			Joi.object({
				id: Joi.string().required(),
				name: Joi.string().required(),
				price: Joi.number().required()
			})
		)
});
export default new Validator(energy_system_schema, "entities.energy_system.");
