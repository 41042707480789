<template>
	<div>
		<div class="rounded-s grey3-bg full-width relative" style="height: 35px">
			<div
				class="absolute full-height rounded-s grey1-bg flex-center-content"
				v-bind:style="bar_style"
			>
				<div v-if="show_percentage">{{ $format_number(percentage, 0) }}%</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoaderBar",
	props: {
		percentage: Number,
		show_percentage: Boolean
	},

	computed: {
		bar_style() {
			return {
				width: this.percentage + "%"
			};
		}
	}
};
</script>
