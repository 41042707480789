import AssetEnergySystemCreatorInterfaceClass from "../infrastructure/energy-system-with-provider-creator-interface";

import EnergySystemWithProviderCreatorValidator from "../domain/energy-system-with-provider-creator-validator";
import EnergySystemWithProviderCreatedValidator from "../domain/energy-system-with-provider-created-validator";

import EnergySystemsStoreUtil from "@src/energy-system/application/utils/energy-systems-store-util";
import AssetsStoreUtil from "@src/asset/application/utils/assets-store-util";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergySystemCreatorInterfaceClass();
		this.store_util = EnergySystemsStoreUtil;
		this.assets_store_util = AssetsStoreUtil;
		this.validator = EnergySystemWithProviderCreatorValidator;
		this.created_energy_system_validator =
			EnergySystemWithProviderCreatedValidator;
	}

	async create_energy_system(energy_system) {
		const validated_data = this.validator.validate(energy_system);

		try {
			const interface_data = await this.backend_interface.create_energy_system(
				validated_data
			);

			const validated_interface_data =
				this.created_energy_system_validator.validate(interface_data);

			if (validated_interface_data.error) {
				throw new AppError(
					"error_creating_energy_system",
					"entities.new_energy_system_with_provider.error_options." +
						validated_interface_data.error,
					{
						error_type: validated_interface_data.error
					}
				);
			} else {
				const new_energy_system =
					validated_interface_data.data.new_energy_system;
				await this.store_util.add_or_update_item(new_energy_system);

				const new_asset = validated_interface_data.data.new_asset;
				await this.assets_store_util.add_or_update_item(new_asset);

				return validated_interface_data;
			}
		} catch (e) {
			throw e instanceof AppError
				? e
				: new AppError(
						"error_create_energy_system",
						"general.errors.general_error",
						e
				  );
		}
	}
}
