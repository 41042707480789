<template>
	<div
		class="full-width full-height relative"
		id="asset-energy-panel"
		v-bind:style="{
			'--main-color': custom_color || '#f6f6fa'
		}"
	>
		<div v-if="loading" class="full-height flex-center-content">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="asset" class="flex-auto-full-height-content grey1-color">
			<div class="header">
				<div class="display-flex-space-between border-b m-b">
					<div
						id="title"
						class="flex-center-content"
						v-bind:class="{ white: custom_color }"
					>
						{{ $t("asset_energy_panel.title") }} {{ asset.name }}
					</div>
					<div
						id="logo"
						class="flex-center-content cursor-pointer"
						v-on:click="fullscreen_toogle"
					>
						<img
							alt="Logo"
							:src="custom_logo || '/img/sentinel-solar-logo-v2.png'"
						/>
					</div>
				</div>
			</div>
			<div class="content">
				<div id="content" class="full-height">
					<div>
						<div class="box-shadow white-bg rounded-s">
							<AssetCurrentPower :asset_id="asset.id" />
						</div>
						<div class="box-shadow white-bg rounded-s">
							<AssetEnergySummary :asset_id="asset.id" />
						</div>
						<div>
							<AssetPvData :asset_id="asset.id" />
						</div>
					</div>
					<div>
						<div class="box-shadow white-bg rounded-s">
							<AssetData :asset_id="asset.id" />
						</div>
						<div class="box-shadow white-bg rounded-s">
							<AssetCurrentDayPower :asset_id="asset.id" />
						</div>
						<div class="box-shadow white-bg rounded-s">
							<AssetSavings :asset_id="asset.id" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="custom_logo"
			id="powered-by"
			class="flex-vertical-center grey1-color"
		>
			<div v-bind:class="{ white: custom_color }">powered by</div>
			<div class="m-l-xs">
				<img
					style="height: 25px"
					:src="
						custom_color
							? '/img/sentinel-solar-logo-v2-grey.png'
							: '/img/sentinel-solar-logo-v2.png'
					"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import AssetCurrentPower from "./AssetCurrentPower.vue";
import AssetEnergySummary from "./AssetEnergySummary.vue";
import AssetPvData from "./AssetPvData.vue";
import AssetData from "./AssetData.vue";
import AssetCurrentDayPower from "./AssetCurrentDayPower.vue";
import AssetSavings from "./AssetSavings.vue";

import UserServiceClass from "@services/user-services/user-service.js";
import AssetFinderClass from "@src/asset/application/asset-finder.js";
import MultilingualServiceClass from "@services/multilingual-service";

const UserService = new UserServiceClass();
const AssetFinder = new AssetFinderClass();
const MultilingualService = new MultilingualServiceClass();

export default {
	name: "AssetEnergyPanel",
	components: {
		Loader,
		Alert,
		AssetCurrentPower,
		AssetEnergySummary,
		AssetPvData,
		AssetData,
		AssetCurrentDayPower,
		AssetSavings
	},
	data() {
		return {
			loading: false,
			error: null,
			full_screen: false,
			asset: null,
			custom_logo: null,
			custom_color: null
		};
	},
	async mounted() {
		setTimeout(() => {
			this.reload_page();
		}, 14400000); //4hours

		const api_token = this.$route.query.key;
		const asset_id = this.$route.query.id;

		if (!api_token || !asset_id) {
			this.error = "general.errors.no_results";
			return;
		}

		this.loading = true;
		try {
			UserService.set_api_token(api_token);
			const asset = await AssetFinder.get_asset(asset_id);

			if (!asset) this.error = "general.errors.no_data_available";
			else this.asset = asset;

			const lang = this.$route.query.lang;
			MultilingualService.set_user_app_lang(lang);

			const custom_interface_data =
				await UserService.get_custom_user_interface_data();
			if (custom_interface_data) {
				this.custom_logo = custom_interface_data.logo;
				this.custom_color = custom_interface_data.primary_color;
			}
		} catch (error) {
			this.error = error;

			setTimeout(() => {
				this.reload_page();
			}, 180000);
		} finally {
			this.loading = false;
		}
	},
	methods: {
		fullscreen_toogle() {
			var elem = document.documentElement;

			if (!this.full_screen) {
				if (elem.requestFullscreen) {
					elem.requestFullscreen();
				} else if (elem.webkitRequestFullscreen) {
					/* Safari */
					elem.webkitRequestFullscreen();
				} else if (elem.msRequestFullscreen) {
					/* IE11 */
					elem.msRequestFullscreen();
				}
				this.full_screen = true;
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					/* Safari */
					document.webkitExitFullscreen();
				} else if (document.msExitFullscreen) {
					/* IE11 */
					document.msExitFullscreen();
				}
				this.full_screen = false;
			}
		},
		reload_page() {
			window.location.reload();
		}
	}
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap");
</style>

<style lang="css" scoped>
#asset-energy-panel {
	font-family: "Fira Sans", sans-serif;
	background-color: var(--main-color);
	padding: 35px;
}
#logo img {
	height: 55px;
}
#title {
	font-size: 45px;
	font-weight: bold;
}
#content {
	padding: 3px 4px 6px;
	display: grid;
	grid-template-rows: 1fr 1fr;
	row-gap: 15px;
}
#content > div:first-child {
	display: grid;
	grid-template-columns: 4fr 9fr 3fr;
	column-gap: 15px;
}
#content > div:last-child {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	column-gap: 15px;
}
#powered-by {
	position: absolute;
	right: 35px;
	bottom: 0;
	height: 40px;
}
</style>
