<template>
	<div class="p-xxs full-width full-height">
		<div class="flex-auto-full-height-content rounded-xs overflow-hidden">
			<PageHeader
				class="header"
				:text="
					header
						? $t('pv_main_panels.tab_header', {
								plant_name: header
						  })
						: null
				"
				close_name="app"
				:close_query="{ asset_id: asset ? asset.id : null }"
			/>
			<div v-if="loading" class="content flex-center-content white-bg">
				<Loader />
			</div>
			<div v-else class="content grey3-bg">
				<div class="flex-auto-full-height-content full-width full-height">
					<div class="header p-b">
						<Header v-if="asset" :asset="asset" />
					</div>
					<div class="content relative">
						<General
							v-if="$route.name == 'asset-main-panel'"
							:asset="asset"
							:asset_monthly_energy_data="asset_monthly_energy_data"
						/>
						<router-view v-else :asset="asset" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";

import Header from "./header/LocationEnergyDataMainPanelHeader.vue";
import General from "./general/AssetEnergyMainPanelGeneral.vue";

import AssetMonthyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
import AssetsFinderClass from "@src/asset/application/assets-finder";

const AssetMonthyEnergyFinder = new AssetMonthyEnergyFinderClass();
const AssetsFinder = new AssetsFinderClass();

export default {
	name: "AssetEnergyDataMainPanel",
	components: {
		PageHeader,
		Loader,
		Header,
		General
	},
	data() {
		return {
			loading: true,
			asset: null,
			asset_monthly_energy_data: null,
			error: null
		};
	},
	computed: {
		header() {
			return this.asset
				? this.asset.name +
						(this.asset.custom_id ? " - " + this.asset.custom_id : "")
				: null;
		}
	},
	mounted() {
		this.load_asset_data();
	},
	methods: {
		async load_asset_data() {
			this.loading = true;
			const asset_id = this.$route.params.asset_id;

			try {
				this.asset = await AssetsFinder.get_asset_by_id(asset_id);

				this.asset_monthly_energy_data =
					await AssetMonthyEnergyFinder.get_asset_monthly_energy(asset_id);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="css" scoped>
#om_content {
	height: calc(100% - 35px);
}
</style>
