<template>
	<div>
		<Tooltip position="bottom" theme="light" :width_in_px="300">
			<template #tooltip-content>
				<div class="font-bold text-center m-b">
					{{ $t("dashboard.latest_measure") }}
				</div>
				<div class="location_latest_power_measurements">
					<div>{{ $t("dashboard.time") }}</div>
					<div>
						{{ $format_date_to_day_hour_minute(power_measurement.time) }}
					</div>

					<div>{{ $t("pv_main_panels.generation") }}</div>
					<div>
						<span v-if="$is_number(power_measurement.power_production)"
							>{{ power_measurement.power_production }} kW
						</span>
					</div>

					<div>{{ $t("pv_main_panels.consumption") }}</div>
					<div>
						<span v-if="$is_number(power_measurement.power_consumption)">
							{{ power_measurement.power_consumption }} kW
						</span>
					</div>

					<div>{{ $t("pv_main_panels.from_grid") }}</div>
					<div>
						<span v-if="$is_number(power_measurement.power_from_grid)">
							{{ power_measurement.power_from_grid }} kW
						</span>
					</div>

					<div>{{ $t("pv_main_panels.to_grid") }}</div>
					<div>
						<span v-if="$is_number(power_measurement.power_to_grid)">
							{{ power_measurement.power_to_grid }} kW
						</span>
					</div>

					<div>{{ $t("pv_main_panels.solar_consumption") }}</div>
					<div>
						<span v-if="$is_number(power_measurement.power_self_consumption)">
							{{ power_measurement.power_self_consumption }} kW
						</span>
					</div>
				</div>
			</template>
		</Tooltip>
	</div>
</template>

<script>
export default {
	name: "LatestPowerMasurementsPopup",
	props: { location_id: String, power_measurement: Object }
};
</script>

<style scoped>
.location_latest_power_measurements {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 15px;
	row-gap: 5px;
}
</style>
