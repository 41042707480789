<template>
	<div v-if="links && links.length > 0" class="display-flex gap-xs">
		<div v-if="hide_tooltips" v-for="(item, index) in links">
			<router-link :to="item.to" :class="item.class">
				<button class="btn btn-primary full-height flex-center-content">
					<Icon :icon="item.icon" size="19" />
				</button>
			</router-link>
		</div>
		<Tooltip
			v-else
			v-for="(item, index) in links"
			:key="'popup_item_' + index"
			position="bottom"
			:tooltip_text="$t(item.multilingual_label)"
			:single_line_tooltip_text="true"
		>
			<template #content>
				<router-link :to="item.to" :class="item.class">
					<button class="btn btn-primary full-height flex-center-content">
						<Icon :icon="item.icon" size="19" />
					</button>
				</router-link>
			</template>
		</Tooltip>
	</div>
</template>

<script>
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "AssetShortcuts",
	props: {
		asset: Object,
		asset_id: String,
		hide_tooltips: Boolean,
		custom_elements: Array //["asset-info","asset-main-panel","ticketing","alerts","remove_asset"]
	},
	data() {
		return {
			links: []
		};
	},
	mounted() {
		this.load_links();
		this.remove_current_route_from_links();
	},
	updated() {
		if (!this.custom_elements) this.remove_current_route_from_links();
	},
	computed: {
		selected_asset_id() {
			return this.asset_id || this.asset.id;
		}
	},
	methods: {
		load_links() {
			let links_to_be_added = this.custom_elements || [
				"asset-info",
				"asset-main-panel",
				"ticketing",
				"alerts"
			];

			const links_data = {
				"asset-info": {
					permission_name: "edit_asset_info",
					link_data: {
						icon: "gear",
						multilingual_label: "index.asset_menu.overview",
						to: {
							name: "asset-info",
							params: {
								asset_id: this.selected_asset_id
							}
						}
					}
				},
				"asset-main-panel": {
					permission_name: null,
					link_data: {
						icon: "dashboard",
						multilingual_label: "index.asset_menu.main_panel",
						to: {
							name: "asset-main-panel",
							params: {
								asset_id: this.selected_asset_id
							}
						}
					}
				},
				ticketing: {
					permission_name: "ticketing",
					link_data: {
						icon: "tools",
						multilingual_label: "header.ticketing",
						to: {
							name: "ticketing",
							query: this.selected_asset_id
								? { asset_id: this.selected_asset_id }
								: null
						}
					}
				},
				alerts: {
					permission_name: "have_alerts",
					link_data: {
						icon: "alert",
						multilingual_label: "header.alerts",
						to: {
							name: "alerts",
							query: this.selected_asset_id
								? {
										alerts_from_systems_or_assets: "assets",
										assets: this.selected_asset_id
								  }
								: null
						}
					}
				},
				remove_asset: {
					permission_name: "remove_asset",
					link_data: {
						icon: "trash",
						multilingual_label: "general.remove",
						to: {
							name: "remove-asset",
							params: {
								asset_id: this.selected_asset_id
							}
						}
					}
				}
			};

			let links = [];
			for (let index in links_to_be_added) {
				const item_name = links_to_be_added[index];
				const item_data = links_data[item_name];

				if (
					item_data.permission_name &&
					!UserPermissionsUtil.can_user(item_data.permission_name)
				)
					continue;

				links.push(item_data.link_data);
			}

			this.links = links;
		},
		remove_current_route_from_links() {
			const validator = (route_name) => {
				for (let key in this.links) {
					if (this.links[key].to.name == route_name) {
						this.links.splice(key, 1);
						return true;
					}
				}
			};

			if (validator(this.$route.name)) return;
			else if (this.$route.matched) {
				for (let key in this.$route.matched) {
					if (validator(this.$route.matched[key].name)) break;
				}
			}
		}
	},
	watch: {
		asset() {
			this.load_links();
		},
		asset_id() {
			this.load_links();
		},
		$route() {
			this.load_links();
		}
	}
};
</script>

<style lang="css" scoped></style>
