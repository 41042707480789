import AssetMonthlyPrFinderValidator from "../domain/asset-monthy-pr-finder-validator.js";
import AssetMonthlyPrFinderInterfaceClass from "../infrastructure/asset-monthy-pr-finder-interface.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetMonthlyPrFinderInterfaceClass();
		this.validator = AssetMonthlyPrFinderValidator;
	}

	async get_asset_monthly_pr(asset_id, from, to) {
		try {
			const interface_data = await this.backend_interface.get_asset_monthly_pr(
				asset_id,
				from,
				to
			);

			const validated_data = this.validator.validate(interface_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_asset_monthly_pr",
				"general.errors.general_error"
			);
		}
	}
}
