<template>
	<div
		class="white-bg rounded-s overflow-y-scroll box-shadow1 absolute_sm full-width_xs"
	>
		<PageHeader :text="$t('ticketing.remove_ticket')" close_name="ticketing" />
		<div class="p-sm">
			<div v-if="error" class="alert alert-danger m-t font-s p-xxs col-sm-12">
				{{ $t(error) }}
			</div>
			<div class="m-t">
				<Loader v-if="loading" size="30" />
				<div v-else>
					<router-link :to="{ name: 'ticketing' }">
						<div class="btn btn-primary right">
							{{ $t("general.cancel") }}
						</div>
					</router-link>
					<div v-on:click="remove_ticket" class="btn btn-danger right m-r">
						{{ $t("general.remove") }}
					</div>
				</div>
				<div class="clear"></div>
			</div>
			<div class="clear"></div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@components/sub_components/Loader";

import TicketServiceClass from "@services/user-services/ticket-service";
const TicketService = new TicketServiceClass();

export default {
	name: "RemoveTicket",
	components: {
		PageHeader,
		Loader
	},
	data() {
		return {
			error: null,
			loading: undefined
		};
	},
	methods: {
		async remove_ticket() {
			this.error = null;
			this.loading = true;

			try {
				await TicketService.remove_ticket(this.$route.params.ticket_id);
				this.$emit("refresh_tickets");
				this.$router.push({ name: "ticketing" });
			} catch (e) {
				this.error = "general.errors.general_error";
			} finally {
				this.loading = null;
			}
		}
	}
};
</script>

<style></style>
