<template>
	<div class="full-width full-height relative">
		<div v-if="loading" class="m-t">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else class="absolute full-width full-height overflow-y-scroll">
			<div
				v-if="
					asset_energy_systems_with_provider.length == 0 &&
					asset_energy_systems_with_no_provider.length == 0
				"
			>
				<Alert type="info" message="general.errors.no_results" />
			</div>
			<div v-else>
				<div v-if="asset_energy_systems_with_provider.length > 0">
					<div class="font-bold border-b">
						{{ $t("asset_info.pv_data.systems_with_provider") }}
					</div>

					<div>
						<div
							v-for="energy_system in asset_energy_systems_with_provider"
							class="border-b"
						>
							<div
								class="energy-system-item cursor-pointer"
								v-on:click="set_energy_system(energy_system.id)"
							>
								<div class="flex-center-content">
									<EnergyProviderImg
										:provider_name="energy_system.provider_data.provider_name"
										:height_in_px="20"
									/>
								</div>
								<div class="flex-vertical-center">
									{{ energy_system.name }}
								</div>
								<div class="flex-center-content">
									<Icon
										v-if="visible_energy_system != energy_system.id"
										icon="chevron-down"
										size="20"
									/>
									<Icon v-else icon="chevron-up" size="20" />
								</div>
							</div>

							<div v-if="visible_energy_system == energy_system.id">
								<ConsumptionEnergySystemInfo :energy_system="energy_system" />
							</div>
						</div>
					</div>
				</div>

				<div v-if="asset_energy_systems_with_no_provider.length > 0">
					<div class="font-bold border-b">
						{{ $t("asset_info.pv_data.systems_with_no_provider") }}
					</div>

					<div>
						<div
							v-for="energy_system in asset_energy_systems_with_no_provider"
							class="border-b"
						>
							<div
								class="no-provider-energy-system-item cursor-pointer"
								v-on:click="set_energy_system(energy_system.id)"
							>
								<div class="flex-vertical-center">
									{{ energy_system.name }}
								</div>
								<div class="flex-center-content">
									<Icon
										v-if="visible_energy_system != energy_system.id"
										icon="chevron-down"
										size="20"
									/>
									<Icon v-else icon="chevron-up" size="20" />
								</div>
							</div>
							<div v-if="visible_energy_system == energy_system.id">
								<ConsumptionEnergySystemInfo :energy_system="energy_system" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";

import ConsumptionEnergySystemInfo from "./ConsumptionEnergySystemInfo";

import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder";
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();

export default {
	name: "AssetConsumptionData",
	components: {
		Loader,
		Alert,
		EnergyProviderImg,
		ConsumptionEnergySystemInfo
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_energy_systems_with_provider: [],
			asset_energy_systems_with_no_provider: [],
			visible_energy_system: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.$route.params.asset_id;
			const asset_energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(asset_id);

			asset_energy_systems.forEach((energy_system) => {
				if (
					energy_system.system_type == "generation_and_consumption" ||
					energy_system.system_type == "only_consumption"
				) {
					if (energy_system.has_provider)
						this.asset_energy_systems_with_provider.push(energy_system);
					else this.asset_energy_systems_with_no_provider.push(energy_system);
				}
			});

			if (asset_energy_systems.length == 1)
				this.set_energy_system(asset_energy_systems[0].id);
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		set_energy_system(energy_system_id) {
			if (this.visible_energy_system == energy_system_id)
				this.visible_energy_system = null;
			else this.visible_energy_system = energy_system_id;
		}
	}
};
</script>

<style scoped>
.energy-system-item {
	padding: 10px 0;
	display: grid;
	grid-template-columns: min-content auto 40px;
	column-gap: 10px;
}
.no-provider-energy-system-item {
	padding: 10px 0;
	display: grid;
	grid-template-columns: 1fr 40px;
}
</style>
