<template lang="html">
	<div class="full-width full-height">
		<div
			v-if="location"
			id="location-name"
			class="text-center font-bold border-b"
		>
			{{ location.name }}
		</div>
		<div v-if="loading" class="m-t">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div id="content" v-else-if="monthly_energy_data" class="overflow-y-scroll">
			<div id="current-power">
				<AssetCurrentPower :asset="asset" />
			</div>
			<LocationMonthlyEnergy
				v-if="last_month_energy_data"
				class="m-t-md"
				:energy_aggregated_data="last_month_energy_data"
			/>
			<div class="m-t-md">
				<div class="font-bold">{{ $t("pv_main_panels.total_savings") }}</div>
				<AssetTotalSavings
					:asset="asset"
					:monthly_aggregated_energy="monthly_energy_data"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import AssetCurrentPower from "@sub_components/AssetCurrentPower/AssetCurrentPower.vue";
import LocationMonthlyEnergy from "./LocationMonthlyEnergy";
import AssetTotalSavings from "@components/app_components/asset/energy-data-main-panel/general/AssetTotalSavings";

import AssetsFinderClass from "@src/asset/application/assets-finder";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";

const AssetsFinder = new AssetsFinderClass();
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();

export default {
	name: "AssetEnergyMainPanel",
	components: {
		Loader,
		Alert,
		AssetCurrentPower,
		LocationMonthlyEnergy,
		AssetTotalSavings
	},
	data() {
		return {
			error: null,
			loading: null,
			asset: null,
			monthly_energy_data: null,
			last_month_energy_data: null
		};
	},
	async mounted() {
		this.loading = true;
		this.error = null;

		try {
			const asset_id = this.$route.params.location_id;

			this.monthly_energy_data =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy(asset_id);

			this.last_month_energy_data =
				this.monthly_energy_data[this.monthly_energy_data.length - 1];

			this.asset = await AssetsFinder.get_asset_by_id(asset_id);
		} catch (e) {
			this.error = e;
		} finally {
			this.loading = null;
		}
	}
};
</script>

<style lang="css" scoped>
#location-name {
	font-size: 18px;
}
#content {
	height: calc(100% - 25px);
}
#current-power {
	height: 300px;
}
</style>
