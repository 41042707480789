<template>
	<div v-if="show">
		<Loader v-if="loading" size="20" />
		<div
			v-else-if="!hide_if_no_groups || multiselect_options.length > 1"
			:style="{ width: width_in_px ? width_in_px + 'px' : 'auto' }"
		>
			<Multiselect
				v-model="selected_assets_group_id"
				:options="multiselect_options"
				track_by="assets_group_id"
				:multiple="false"
				:can_clear="can_clear"
			/>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Multiselect from "./Multiselect.vue";

import AssetsGroupsFinderClass from "@src/assets-group/application/assets-groups-finder.js";
const AssetsGroupsFinder = new AssetsGroupsFinderClass();

export default {
	name: "AssetsGroupMultiselect",
	components: {
		Loader,
		Multiselect
	},
	props: {
		can_clear: {
			type: Boolean,
			default: true
		},
		hide_if_no_groups: {
			type: Boolean,
			default: false
		},
		width_in_px: Number
	},
	data() {
		return {
			loading: true,
			assets_groups: null,
			multiselect_options: null,
			selected_assets_group_id: "default"
		};
	},
	async mounted() {
		try {
			this.loading = true;

			this.assets_groups = await AssetsGroupsFinder.get_assets_groups();

			this.format_multiselect_values();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		format_multiselect_values() {
			this.multiselect_options = [
				{
					assets_group_id: "default",
					label: "Default"
				}
			];

			if (this.assets_groups) {
				const multiselect_values = this.assets_groups.map((item) => {
					return {
						assets_group_id: item.id,
						label: item.name
					};
				});

				this.multiselect_options =
					this.multiselect_options.concat(multiselect_values);
			}

			if (
				this.selected_assets_group_id != "default" &&
				!this.multiselect_options.find(
					(item) => item.assets_group_id == this.selected_assets_group_id
				)
			) {
				this.selected_assets_group_id = "default";
			}
		}
	},
	computed: {
		show() {
			return (
				!this.loading &&
				(!this.hide_if_no_groups || this.multiselect_options.length > 1)
			);
		}
	},
	watch: {
		selected_assets_group_id(new_value) {
			this.$emit("input", new_value);
		},
		assets_groups() {
			this.format_multiselect_values();
		}
	}
};
</script>
