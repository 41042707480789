import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendAssetConverter from "./backend-asset-converter";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async create_asset(asset_to_create) {
		const url = "/api/asset";

		const backend_asset_to_create = {
			name: asset_to_create.name,
			coordinates: {
				lat: asset_to_create.coordinates.lat,
				lon: asset_to_create.coordinates.lon
			},
			customId: asset_to_create.custom_id || null
		};

		const backend_created_asset = await this.post(url, {
			body_params: backend_asset_to_create
		});

		const local_created_asset = BackendAssetConverter(backend_created_asset);

		return local_created_asset;
	}
}
