<template>
	<div>
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader size="25" />
		</div>
		<Tooltip
			v-else
			:tooltip_text="
				$t('alerts.' + (new_is_archive_value ? 'archive' : 'unarchive'))
			"
			:single_line_tooltip_text="true"
			position="left"
		>
			<template #content>
				<button class="btn btn-primary" v-on:click="archive_alert">
					<Icon
						:icon="new_is_archive_value ? 'archive' : 'unarchive'"
						size="16"
					/>
				</button>
			</template>
		</Tooltip>
	</div>
</template>

<script>
import Loader from "@loader";

import AlertUpdaterClass from "@src/alert/application/alert-updater.js";
const AlertUpdater = new AlertUpdaterClass();

export default {
	name: "ArchiveAlertButton",
	components: {
		Loader
	},
	props: {
		alert_id: String,
		new_is_archive_value: Boolean
	},
	data() {
		return {
			loading: false
		};
	},
	methods: {
		async archive_alert() {
			this.loading = true;

			try {
				await AlertUpdater.update_alert(this.alert_id, {
					is_archived: this.new_is_archive_value
				});
				this.$emit("alert_archived", this.alert_id);
			} catch (error) {
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>
