import Joi from "joi";
import Validator from "@validators/validator";

const energy_system_custom_monthly_schema = Joi.object({
	data: Joi.array()
		.items(
			Joi.object({
				month: Joi.string().required(),
				energy_production: Joi.number().min(0).allow(null),
				energy_consumption: Joi.number().min(0).allow(null),
				energy_self_consumption: Joi.number().min(0).allow(null),
			})
		)
		.required(),
});
export default new Validator(energy_system_custom_monthly_schema);
