import AssetSunriseSunsetFinderClass from "../infrastructure/asset-sunrise-sunset-finder-interface.js";
import AssetSunriseSunsetValidator from "../domain/asset-sunrise-sunset-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetSunriseSunsetValidator;
		this.backend_interface = new AssetSunriseSunsetFinderClass();
	}

	async get_asset_sunrise_sunset(asset_id) {
		try {
			const interface_result =
				await this.backend_interface.get_asset_sunrise_sunset(asset_id);

			const validated_result = this.validator.validate(interface_result);

			return validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_sunrise_sunset",
				"general.errors.general_error",
				e
			);
		}
	}
}
