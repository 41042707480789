import Joi from "joi";
import Validator from "@validators/validator";

const schema = Joi.object({
	day_from: Joi.date().less(Joi.ref("day_to")).required(),
	day_to: Joi.date().required(),
	force_download: Joi.boolean(),
	resolutions: Joi.object({
		minutely: Joi.boolean().required(),
		daily: Joi.boolean().required(),
		monthly: Joi.boolean().required()
	}).required()
});
export default new Validator(
	schema,
	"entities.energy_system_measurements_download_request."
);
