<template>
	<div>
		<div
			id="alerts-filter"
			class="grey1-color"
			v-bind:style="{
				'grid-template-columns':
					'repeat(' + (alerts_from_systems_selected ? 4 : 3) + ', 250px) auto'
			}"
		>
			<div class="font-700">
				{{ $t("entities.alert.alert_type") }}
			</div>
			<div class="font-700">
				{{
					$t(
						alerts_from_systems_selected
							? "entities.energy_system.name"
							: "general.location"
					)
				}}
			</div>
			<div v-if="alerts_from_systems_selected" class="font-700">
				{{ $t("entities.alert.extra_data.severity") }}
			</div>
			<div class="font-700">
				{{ $t("general.period") }}
			</div>

			<div></div>

			<div>
				<Multiselect
					v-model="filter.alert_types"
					:multiple="true"
					:options="filter_alert_types_options"
					:close_on_select="false"
				/>
			</div>

			<div v-if="alerts_from_systems_selected">
				<EnergySystemMultiselect
					v-model="filter.energy_systems"
					:multiple="true"
				/>
			</div>
			<div v-else>
				<LocationSelect2 v-model="filter.assets" :multiple="true" />
			</div>

			<div v-if="alerts_from_systems_selected">
				<Multiselect
					v-model="filter.severities"
					:multiple="true"
					:close_on_select="false"
					:options="[
						{
							label: $t('entities.alert.extra_data.severity_options.1'),
							value: 1
						},
						{
							label: $t('entities.alert.extra_data.severity_options.2'),
							value: 2
						},
						{
							label: $t('entities.alert.extra_data.severity_options.3'),
							value: 3
						}
					]"
				/>
			</div>
			<div>
				<Datepicker
					v-model="datepicker_value"
					:is_range="true"
					:max_range_days="31"
					:allow_to="new Date()"
					:allow_from="datepicker_allow_from"
				/>
			</div>

			<div class="display-flex gap-s">
				<button class="btn btn-primary-inverse" v-on:click="reset_filter">
					{{ $t("general.clean") }}
				</button>

				<button class="btn btn-primary" v-on:click="emit_filter">
					{{ $t("general.search") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from "@form_components/Multiselect.vue";
import EnergySystemMultiselect from "@form_components/EnergySystemMultiselect.vue";
import Datepicker from "@form_components/Datepicker.vue";
import LocationSelect2 from "@form_components/LocationSelect2.vue";

import moment from "moment";

export default {
	name: "AletsFilter",
	components: {
		Multiselect,
		EnergySystemMultiselect,
		Datepicker,
		LocationSelect2
	},
	props: {
		alerts_from_systems_or_assets: String,
		alerts_filter: Object
	},
	data() {
		return {
			datepicker_value: null,
			datepicker_allow_from: moment().subtract(1, "year").toDate(),
			filter: {},
			default_filter: null
		};
	},
	mounted() {
		this.set_alerts_filter();
	},
	methods: {
		set_alerts_filter() {
			this.datepicker_value = [
				this.alerts_filter.date_from,
				this.alerts_filter.date_to
			];
			this.filter = {
				date_from: this.alerts_filter.date_from,
				date_to: this.alerts_filter.date_to,
				alert_types: this.alerts_filter.alert_types,
				energy_systems: this.alerts_filter.energy_systems,
				assets: this.alerts_filter.assets || null,
				severities: this.alerts_filter.severities
			};
		},
		reset_filter() {
			this.$emit("reset_filter");
		},
		emit_filter() {
			this.$emit("filter", this.filter);
		}
	},
	computed: {
		alerts_from_systems_selected() {
			return this.alerts_from_systems_or_assets == "systems";
		},
		filter_alert_types_options() {
			const alert_types =
				this.alerts_from_systems_or_assets == "systems"
					? ["pr_alert", "provider_alert", "suspicious_data_alert"]
					: [
							"systems_alert",
							"new_asset_imported_with_fields_to_review",
							"new_asset_imported"
					  ];

			return alert_types.map((item) => {
				return {
					label: this.$t("entities.alert.alert_type_options." + item),
					value: item
				};
			});
		}
	},
	watch: {
		datepicker_value(new_value) {
			this.filter.date_from = new_value[0];
			this.filter.date_to = new_value[1];
		},
		alerts_filter() {
			this.set_alerts_filter();
		}
	}
};
</script>

<style scoped>
#alerts-filter {
	display: grid;
	column-gap: 10px;
	row-gap: 5px;
}
</style>
