import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendEnergyMeasurementConverter from "@src/shared/infrastructure/backend-converters/backend-energy-measurement-converter";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_energy_system_monthly_data(
		system_id,
		from,
		to,
		only_provider_data = null
	) {
		const url = "/api/energy-data/systems/" + system_id + "/monthly";

		let params = {
			query_params: {
				from: moment(from).format("YYYY-MM-DD"),
				to: moment(to).format("YYYY-MM-DD")
			}
		};
		if (only_provider_data) params.query_params.onlyProviderData = true;

		const result = await this.get(url, params);

		let formatted_data = [];
		result.forEach((item) => {
			const converted_energy_measurement =
				BackendEnergyMeasurementConverter(item);
			formatted_data.push(converted_energy_measurement);
		});

		return formatted_data;
	}
}
