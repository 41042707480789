<template>
	<div class="full-width full-height" ref="asset_savings_char">
		<div v-if="!asset_savings_has_data">
			<Alert type="info" message="general.errors.no_data_available" />
		</div>
		<StackedColumnChart
			v-else-if="asset_savings_has_data && chart_serie && chart_height"
			width="100%"
			:height="(chart_height ? chart_height - 20 : 300) + 'px'"
			:series="chart_serie"
			:series_data="[
				{
					prop_name: 'self_consumption_savings',
					multilingual_label:
						'pv_main_panels.savings.self_consumption_savings_saved',
					color: 'var(--blue2)'
				},
				{
					prop_name: 'to_grid_savings',
					multilingual_label: 'pv_main_panels.savings.to_grid_savings_saved',
					color: 'var(--blue1)'
				}
			]"
			legend_positon="top"
			:x_axis_formatter="
				(value) => this.$format_date_to_month_abbreviated_string(value)
			"
			:y_axis_formatter="(value) => this.$format_number(value, 0) + ' €'"
		/>
	</div>
</template>

<script>
import Alert from "@alert";
import StackedColumnChart from "@sub_components/charts/StackedColumnChart.vue";

export default {
	name: "ReportPvProductionChart",
	components: {
		Alert,
		StackedColumnChart
	},
	props: {
		asset_savings: Array
	},
	data() {
		return {
			chart_height: null,
			chart_serie: null,
			asset_savings_has_data: false
		};
	},
	mounted() {
		this.asset_savings_has_data = this.asset_savings.find(
			(item) =>
				this.$is_number(item.self_consumption_savings) ||
				this.$is_number(item.to_grid_savings)
		)
			? true
			: false;

		if (!this.asset_savings_has_data) return;

		this.chart_serie = this.asset_savings.map((item) => {
			let element = Object.assign({}, item);
			element.time = element.month;
			delete element.month;

			return element;
		});

		setTimeout(() => {
			this.chart_height = this.$refs.asset_savings_char.clientHeight;
		}, 100);
	}
};
</script>
