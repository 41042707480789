<template>
	<div>
		<AssetForecastChart :asset_id="asset_id" :height_in_px="400" />
	</div>
</template>

<script>
import AssetForecastChart from "@sub_components/location-components/AssetForecastChart";

export default {
	name: "OmWeather",
	props: { location: Object },
	components: {
		AssetForecastChart
	},
	computed: {
		asset_id() {
			return this.$route.params.asset_id;
		}
	}
};
</script>
