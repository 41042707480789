module.exports = {
	env: process.env.NODE_ENV || null,
	api_url: process.env.VUE_APP_API_URL || null,
	app_version: process.env.VUE_APP_VERSION || "no_version",
	map_data: {
		mapbox_token: process.env.VUE_APP_MAP_TOKEN || null,
		custom_map_url: process.env.VUE_APP_CUSTOM_MAP_URL || null,
		google_geocoding_api_token:
			process.env.VUE_APP_GOOGLE_GEOCODING_API_TOKEN || null
	},
	test: {
		user: process.env.VUE_APP_TEST_USER || null,
		password: process.env.VUE_APP_TEST_PASSWORD || null,
		token: process.env.VUE_APP_TEST_TOKEN
	},
	store_entity_names: {
		user_token: "user_backend_token",
		api_token: "api_token",
		language: "user_language",
		user_data: "user_data",
		users: "users_list",
		current_admin_token: "current_admin_token",
		user_energy_measurement_providers: "user_energy_measurement_providers",
		client_app_version: "client_app_version",
		locations_pv_estimated_productions: "locations_pv_estimated_productions",
		locations_monthy_energy_energy: "locations_monthy_energy_energy"
	},
	indexed_db_entities: {
		user_assets: "user_assets",
		assets_status_data: "assets_status_data",
		user_energy_systems: "user_energy_systems",
		testing_entity: "tests",
		assets_default_group_assets_summary: "assets_default_group_assets_summary",
		assets_default_group_systems_summary: "assets_default_group_systems_summary"
	},
	location_image_max_size_in_mb: 1.5,
	html_to_pdf_service_api_key: process.env.VUE_APP_HTML_TO_PDF_API_KEY || null,
	hotjar_api_key: process.env.VUE_APP_HOTJAR_API_KEY || null
};
