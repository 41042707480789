import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_assets_group_systems_summary(assets_group_id) {
		const url = "/api/assets-group/" + assets_group_id + "/system-summary-data";
		const backend_result = await this.get_items_with_offset(url, 4);

		const assets_group_assets_data = backend_result.map((item) =>
			BackendObjectConverter(item, {
				pr: {
					formatter: (value) => value * 100
				}
			})
		);

		return assets_group_assets_data;
	}
}
