<template>
	<div class="m-t display-flex gap-s grey1-color">
		<div
			class="display-grid gap-s"
			:style="{
				'grid-template-columns':
					'repeat(' + (filters_type == 'systems' ? 4 : 3) + ', 280px)'
			}"
		>
			<div v-if="filters_type == 'systems'" class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("entities.energy_system.cups") }}
				</div>
				<div>
					<TextInput v-model="filter.cups" />
				</div>
			</div>

			<div class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("general.status") }}
				</div>
				<div>
					<Multiselect
						v-model="filter.status_provided"
						:multiple="true"
						:options="
							status_options.map((item) => {
								return {
									label: $t(
										'entities.assets_group_summary.systems_status.' + item
									),
									value: item
								};
							})
						"
						:close_on_select="false"
					/>
				</div>
			</div>

			<div class="display-grid gap-xs">
				<div class="font-700">Tipo de dispositivo</div>
				<div>
					<Multiselect
						v-model="filter.systems_info"
						:multiple="true"
						:options="system_type_options"
						:close_on_select="false"
					/>
				</div>
			</div>

			<div class="display-grid gap-xs">
				<div class="font-700">
					{{ $t("entities.energy_system.provider_data.provider_name") }}
				</div>
				<div>
					<EnergyDataProvidersMultiselect
						v-model="filter.provider_names"
						:multiple="true"
					/>
				</div>
			</div>
		</div>

		<div class="flex-vertical-bottom gap-s">
			<button class="btn btn-primary-inverse" v-on:click="reset_filter">
				{{ $t("general.clean") }}
			</button>
			<button class="btn btn-primary" v-on:click="emit_filter">
				{{ $tc("general.filter", 1) }}
			</button>
		</div>
	</div>
</template>

<script>
import TextInput from "@form_components/TextInput.vue";
import Multiselect from "@form_components/Multiselect.vue";
import EnergyDataProvidersMultiselect from "@form_components/selectors/EnergyDataProvidersMultiselect.vue";

export default {
	name: "AssetsGroupFilters",
	props: {
		filters_type: String //assets | systems
	},
	components: {
		TextInput,
		Multiselect,
		EnergyDataProvidersMultiselect
	},
	data() {
		return {
			filter: {
				status_provided: null,
				systems_info: null,
				provider_names: null
			},
			status_options: ["ok", "offline", "faulty", "unavailable", "unsupported"],
			system_type_options: [
				{
					label: "Batería",
					value: "has_battery"
				},
				{
					label: "Generación",
					value: "has_pv_generation"
				},
				{
					label: "Consumo",
					value: "has_consumption"
				},
				{
					label: "Interacción con la red",
					value: "has_grid_interaction"
				}
			]
		};
	},
	methods: {
		reset_filter() {
			this.filter = {
				status_provided: null,
				systems_info: null,
				provider_names: null
			};
		},
		emit_filter() {
			let any_active_filter = false;
			for (let index in this.filter) {
				if (
					this.filter[index] &&
					Array.isArray(this.filter[index]) &&
					this.filter[index].length == 0
				)
					this.filter[index] = null;

				if (this.filter[index]) any_active_filter = true;
			}

			this.$emit(
				"filter",
				any_active_filter ? Object.assign({}, this.filter) : null
			);
		}
	},
	watch: {
		filters_type() {
			this.reset_filter();
		}
	}
};
</script>
