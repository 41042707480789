import FroniusLoginValidator from "./energy-providers-login-validators/fronius-login-validator.js";
import IngeteamLoginValidator from "./energy-providers-login-validators/ingeteam-login-validator.js";
import WibeeeLoginValidator from "./energy-providers-login-validators/wibeee-login-validator.js";
import HuaweiFusionsolarLoginValidator from "./energy-providers-login-validators/huawei-fusionsolar-login-validator.js";
import HuaweiNetecoLoginValidator from "./energy-providers-login-validators/huawei-neteco-login-validator.js";
import EnphaseLoginValidator from "./energy-providers-login-validators/enphase-login-validator.js";
import EnphaseV4LoginValidator from "./energy-providers-login-validators/enphase-v4-login-validator.js";
import DatadisLoginValidator from "./energy-providers-login-validators/datadis-login-validator.js";
import TwinmeterLoginValidator from "./energy-providers-login-validators/twinmeter-login-validator.js";
import VictronLoginValidator from "./energy-providers-login-validators/victron-login-validator.js";
import SungrowLoginValidator from "./energy-providers-login-validators/sungrow-login-validator.js";
import SolaredgeLoginValidator from "./energy-providers-login-validators/solaredge-login-validator.js";
import LacecalLoginValidator from "./energy-providers-login-validators/lacecal-login-validator.js";
import SolaxLoginValidator from "./energy-providers-login-validators/solax-login-validator.js";
import SunvecxLoginValidator from "./energy-providers-login-validators/sunvecx-login-validator.js";
import GrowattLoginValidator from "./energy-providers-login-validators/growatt-login-validator.js";
import SolisLoginValidator from "./energy-providers-login-validators/solis-login-validator.js";
import CanadianSolarLoginValidator from "./energy-providers-login-validators/canadian-solar-login-validator.js";
import KostalLoginValidator from "./energy-providers-login-validators/kostal-login-validator.js";

export default {
	fronius: {
		full_name: "Fronius",
		img_src: "/img/inverters/fronius-logo.svg",
		login_validator: FroniusLoginValidator
	},
	sma: {
		full_name: "SMA",
		img_src: "/img/inverters/sma-logo.svg"
	},
	enphase: {
		full_name: "Enphase",
		img_src: "/img/inverters/enphase-logo.png",
		login_validator: EnphaseLoginValidator
	},
	"enphase-v4": {
		full_name: "Enphase V4",
		img_src: "/img/inverters/enphase-v4-logo.png",
		login_validator: EnphaseV4LoginValidator
	},
	ingeteam: {
		full_name: "Ingeteam",
		img_src: "/img/inverters/ingeteam-logo.gif",
		login_validator: IngeteamLoginValidator
	},
	wibeee: {
		full_name: "Wibeee",
		img_src: "/img/inverters/wibeee-logo.png",
		login_validator: WibeeeLoginValidator
	},
	"huawei-fusionsolar": {
		full_name: "Huawei - Fusionsolar",
		img_src: "/img/inverters/huawei-fusionsolar-logo.svg",
		login_validator: HuaweiFusionsolarLoginValidator
	},
	"huawei-neteco": {
		full_name: "Huawei - Neteco",
		img_src: "/img/inverters/huawei-neteco-logo.png",
		login_validator: HuaweiNetecoLoginValidator
	},
	datadis: {
		full_name: "Datadis",
		img_src: "/img/inverters/logo-datadis.png",
		login_validator: DatadisLoginValidator
	},
	twinmeter: {
		full_name: "Twinmeter",
		img_src: "/img/inverters/twinmeter-logo.png",
		login_validator: TwinmeterLoginValidator
	},
	victron: {
		full_name: "Victron",
		img_src: "/img/inverters/victron-logo.png",
		login_validator: VictronLoginValidator
	},
	sungrow: {
		full_name: "Sungrow",
		img_src: "/img/inverters/sungrow-logo.png",
		login_validator: SungrowLoginValidator
	},
	vmc: {
		full_name: "VMC",
		img_src: "/img/inverters/vmc-logo.jpeg"
	},
	solaredge: {
		full_name: "Solaredge",
		img_src: "/img/inverters/solaredge-logo.png",
		login_validator: SolaredgeLoginValidator
	},
	lacecal: {
		full_name: "Lacecal",
		img_src: "/img/inverters/lacecal-logo.png",
		login_validator: LacecalLoginValidator
	},
	solax: {
		full_name: "Solax",
		img_src: "/img/inverters/solax-logo.png",
		login_validator: SolaxLoginValidator
	},
	sunvecx: {
		full_name: "SunvecX",
		img_src: "/img/inverters/sunvecx-logo.png",
		login_validator: SunvecxLoginValidator
	},
	growatt: {
		full_name: "Growatt",
		img_src: "/img/inverters/growatt-logo.png",
		login_validator: GrowattLoginValidator
	},
	custom: {
		full_name: null,
		img_src: null
	},
	solis: {
		full_name: "Solis",
		img_src: "/img/inverters/solis-logo.png",
		login_validator: SolisLoginValidator
	},
	"canadian-solar": {
		full_name: "Canadian Solar",
		img_src: "/img/inverters/canadian-solar-logo.png",
		login_validator: CanadianSolarLoginValidator
	},
	kostal: {
		full_name: "Kostal",
		img_src: "/img/inverters/kostal-logo.png",
		login_validator: KostalLoginValidator
	}
};
