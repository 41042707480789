import { saveAs } from "file-saver";
import DataExportServiceClass from "@services/data-export-service";
import HtmlToPdfUtilClass from "@src/shared/application/utils/html-to-pdf-util.js";

import header from "./asset-reports-html-content/html-header.js";
import footer from "./asset-reports-html-content/html-footer.js";

//Doc -> https://redstapler.co/sheetjs-tutorial-create-xlsx/
export default {
	install: (app, options) => {
		app.config.globalProperties.$download_file = function (
			file_content,
			file_name
		) {
			saveAs(file_content, file_name);
		};

		app.config.globalProperties.$download_xlsx_file = function (
			xlsx_content,
			file_name
		) {
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
				var view = new Uint8Array(buf); //create uint8array as viewer
				for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
				return buf;
			}

			var excel_blob = new Blob([s2ab(xlsx_content)], {
				type: "application/octet-stream"
			});
			this.$download_file(excel_blob, file_name);
		};

		app.config.globalProperties.$download_array_as_xlsx_file = function (
			array_content,
			file_name
		) {
			const DataExportService = new DataExportServiceClass();

			const xlsx_content = DataExportService.convert_array_to_xlsx_content(
				array_content,
				file_name
			);

			this.$download_xlsx_file(xlsx_content, file_name);
		};

		app.config.globalProperties.$download_array_as_csv_file = function (
			array_content,
			file_name
		) {
			var csv = "";
			array_content.forEach(function (row) {
				csv += '"';
				csv += row.join('","');
				csv += '"';
				csv += "\n";
			});

			const blob = new Blob([csv], { type: "text/csv" });
			this.$download_file(blob, file_name + ".csv");
		};

		app.config.globalProperties.$download_html_to_pdf_file = async function (
			html_content,
			file_name
		) {
			const HtmlToPdfUtil = new HtmlToPdfUtilClass();

			const pdf_url = await HtmlToPdfUtil.get_pdf_url_from_html(
				html_content,
				file_name
			);

			//Download url
			var a = document.createElement("a");
			a.href = pdf_url;
			a.download = file_name;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(pdf_url);
			a.remove();
		};

		app.config.globalProperties.$download_asset_report_as_pdf_file =
			async function (html_content, file_name, custom_css_styles = null) {
				if (typeof html_content == "object") {
					html_content = html_content.innerHTML;
				}

				const html_report = header(custom_css_styles) + html_content + footer;

				await this.$download_html_to_pdf_file(html_report, file_name);
			};
	}
};
