<template>
	<div
		id="custom-radio"
		class="flex-center-content"
		v-bind:class="{
			active: !disabled && value == radio_value,
			'cursor-pointer': !disabled
		}"
		v-on:click="emit_value"
	>
		<div></div>
	</div>
</template>

<script>
export default {
	name: "RadioButton",
	props: {
		value: String,
		radio_value: String,
		disabled: Boolean
	},
	methods: {
		emit_value() {
			this.$emit("input", this.radio_value);
			this.$emit("change", this.radio_value);
		}
	}
};
</script>

<style scoped>
#custom-radio {
	width: 20px;
	height: 20px;
	border: 1px solid var(--grey2);
	border-radius: 50%;
}
#custom-radio:hover {
	background-color: #ccc;
}
#custom-radio.active {
	border: 1px solid var(--custom-primary);
}
#custom-radio.active > div {
	width: 10px;
	height: 10px;
	background-color: var(--custom-primary);
	border-radius: 50%;
}
</style>
