<template>
	<div class="full-width full-height">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else="energy_systems" class="full-width full-height relative">
			<div
				v-if="selected_energy_system"
				class="absolute full-width full-height full-size-popup-bg flex-center-content"
			>
				<AdminEditEnergySystem
					:energy_system="selected_energy_system"
					v-on:close="selected_energy_system = null"
				/>
			</div>
			<div class="flex-auto-full-height-content">
				<div class="header p-sm">
					<div style="width: 250px">
						<TextInput
							v-model="filter"
							:placeholder="$tc('general.filter', 1)"
						/>
					</div>
				</div>
				<div class="content-no-scroll relative">
					<div class="absolute full-width full-height">
						<DataTable
							:items="energy_systems"
							:items_per_page="30"
							:fields="table_columns"
							:filter="filter"
							:filter_fields="['provider_data.system_id_in_provider', 'name']"
						>
							<template #cell(provider_data.provider_name)="data">
								<div class="flex-center-content">
									<EnergyProviderImg
										:provider_name="data.value"
										:heigh_in_px="20"
									/>
								</div>
							</template>

							<template #cell(edit)="data">
								<button
									class="btn btn-primary"
									v-on:click="selected_energy_system = data.item"
								>
									{{ $t("general.edit") }}
								</button>
							</template>
						</DataTable>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import TextInput from "@form_components/TextInput.vue";
import DataTable from "@sub_components/DataTable.vue";
import EnergyProviderImg from "@sub_components/location-components/EnergyProviderImg";

import AdminEditEnergySystem from "./AdminEditEnergySystem.vue";

import EnergySystemsFromUserFinderClass from "@src/energy-system/application/energy-systems-from-user-finder.js";

export default {
	name: "AdminUserEnergySystems",
	components: {
		Loader,
		Alert,
		TextInput,
		DataTable,
		EnergyProviderImg,
		AdminEditEnergySystem
	},
	data() {
		return {
			loading: true,
			error: null,
			energy_systems: null,
			filter: null,
			selected_energy_system: null
		};
	},
	async mounted() {
		this.load_data();
	},
	methods: {
		async load_data() {
			this.loading = true;

			try {
				const user_id = this.$route.params.user_id;
				const EnergySystemsFromUserFinder =
					new EnergySystemsFromUserFinderClass(user_id);

				this.energy_systems =
					await EnergySystemsFromUserFinder.get_energy_systems_from_user();
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	},
	computed: {
		table_columns() {
			return [
				{
					key: "provider_data.provider_name",
					label: this.$tc("user_settings.provider", 1)
				},
				{
					key: "provider_data.system_id_in_provider",
					label: this.$t("user_settings.energy_systems_manager.system_id"),
					sortable: true
				},
				{
					key: "name",
					label: this.$t("user_settings.energy_systems_manager.system_name"),
					sortable: true
				},
				{
					key: "edit"
				}
			];
		}
	}
};
</script>
