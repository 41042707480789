import AssetEnergySystemCreatorInterfaceClass from "../infrastructure/energy-system-creator-interface";
import EnergySystemCreatorValidator from "../domain/energy-system-creator-validator";
import EnergySystemFinderValidator from "../domain/energy-system-finder-validator";

import EnergySystemsStoreUtil from "@src/energy-system/application/utils/energy-systems-store-util";
import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.backend_interface = new AssetEnergySystemCreatorInterfaceClass();
		this.store_util = EnergySystemsStoreUtil;
		this.validator = EnergySystemCreatorValidator;
		this.interface_validator = EnergySystemFinderValidator;
	}

	async create_energy_system(energy_system) {
		const validated_data = this.validator.validate(energy_system);

		try {
			const new_energy_system =
				await this.backend_interface.create_energy_system(validated_data);

			const validated_interface_data =
				this.interface_validator.validate(new_energy_system);

			await EnergySystemsStoreUtil.add_or_update_item(validated_interface_data);

			return validated_interface_data;
		} catch (e) {
			throw new AppError(
				"error_create_energy_system",
				"general.errors.general_error",
				e
			);
		}
	}
}
