<template lang="html">
	<div>
		<Header :title="$t('asset_reports.pv_report.title')" />

		<SelectedPeriodSummary
			:asset_reports="asset_reports"
			:asset_reports_color="asset_reports_color"
		/>

		<EnergyProductionSummary
			:asset_reports="asset_reports"
			:asset_reports_color="asset_reports_color"
		/>

		<div class="m-t-xl">
			<SavingsPollutionTable
				v-if="selected_period_savings"
				:selected_period_savings="selected_period_savings"
				:total_savings="
					from_start_of_available_data_to_end_of_selected_period_savings_summary
				"
				:previously_accumulated="previously_accumulated"
			/>
		</div>

		<Footer :page_number="report_comment ? '2/3' : '2/2'" />
	</div>
</template>

<script>
import Header from "../../reports-shared-components/Header.vue";
import Footer from "../../reports-shared-components/Footer.vue";

import SelectedPeriodSummary from "./sub-components/SelectedPeriodSummary";
import EnergyProductionSummary from "../../reports-shared-components/EnergyProductionSummary.vue";
import SavingsPollutionTable from "./sub-components/SavingsPollutionTable";

export default {
	name: "LocationPvReportPage3",
	components: {
		Header,
		Footer,
		SelectedPeriodSummary,
		EnergyProductionSummary,
		SavingsPollutionTable
	},
	props: {
		asset_reports: Object,
		asset_reports_color: String,
		report_comment: String
	},
	data() {
		var asset_reports = Object.assign({}, this.asset_reports);
		const savings_summary = asset_reports.savings_summary || {};

		function kg_to_tones(value) {
			return value / 1000;
		}

		if (savings_summary.mass_unit == "kg") {
			if (savings_summary.selected_period_savings) {
				savings_summary.selected_period_savings.forEach((item) => {
					item.petrol_in_tones = kg_to_tones(item.petrol);
				});
			}

			if (savings_summary.selected_period_savings_summary) {
				savings_summary.selected_period_savings_summary.petrol_in_tones =
					kg_to_tones(savings_summary.selected_period_savings_summary.petrol);
			}

			if (
				savings_summary.from_start_of_available_data_to_end_of_selected_period_savings_summary
			) {
				savings_summary.from_start_of_available_data_to_end_of_selected_period_savings_summary.petrol_in_tones =
					kg_to_tones(
						savings_summary
							.from_start_of_available_data_to_end_of_selected_period_savings_summary
							.petrol
					);
			}
		}

		const previously_accumulated = {
			petrol_in_tones:
				savings_summary
					.from_start_of_available_data_to_end_of_selected_period_savings_summary
					.petrol_in_tones -
				savings_summary.selected_period_savings_summary.petrol_in_tones,
			c02:
				savings_summary
					.from_start_of_available_data_to_end_of_selected_period_savings_summary
					.c02 - savings_summary.selected_period_savings_summary.c02
		};

		return {
			selected_period_savings: savings_summary.selected_period_savings || null,
			selected_period_savings_summary:
				savings_summary.selected_period_savings_summary || null,
			from_start_of_available_data_to_end_of_selected_period_savings_summary:
				savings_summary.from_start_of_available_data_to_end_of_selected_period_savings_summary ||
				null,
			previously_accumulated: previously_accumulated
		};
	}
};
</script>

<style lang="css" scoped></style>
