import Joi from "joi";
import Validator from "@validators/validator";
import EnergyDataProviderSlugValidator from "@src/energy-provider-auth/domain/energy-data-provider-slug-validator";

const asset_provider_data_schema = Joi.object({
	systems: Joi.array().items(
		Joi.object({
			id: Joi.string().required(),
			name: Joi.string().allow(null),
			provider_name: EnergyDataProviderSlugValidator.schema,
			system_id_in_provider: Joi.string(),
			rate_contributed_generation: Joi.number().min(0).max(100).allow(null),
			rate_contributed_consumption: Joi.number().min(0).max(100).allow(null),
			rate_contributed_grid_interaction: Joi.number()
				.min(0)
				.max(100)
				.allow(null),
			system_type: Joi.string().valid(
				"only_generation",
				"only_consumption",
				"generation_and_consumption",
				"only_grid_interaction"
			)
		})
	)
});

export default new Validator(asset_provider_data_schema);
