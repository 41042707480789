import Joi from "joi";
import Validator from "@validators/validator";

import EnergyDataProviderSlugValidator from "@src/energy-provider-auth/domain/energy-data-provider-slug-validator";

const energy_system_schema = Joi.object({
	provider_name: EnergyDataProviderSlugValidator.schema.required(),
	system_id_in_provider: Joi.string().required(),
	extra_data: Joi.object({
		authorized_nif: Joi.string()
	})
});
export default new Validator(
	energy_system_schema,
	"entities.new_energy_system_with_provider."
);
