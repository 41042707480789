import BackendEntityConverter from "@interfaces/backend-interfaces/converters/backend-entity-converter";

export default (backend_asset_energy_data_config) => {
	let local_asset_provider_data = {};

	if (
		backend_asset_energy_data_config.systems &&
		backend_asset_energy_data_config.systems.length > 0
	) {
		local_asset_provider_data.systems = [];

		backend_asset_energy_data_config.systems.forEach((backend_system) => {
			const asset_backend_provider_name = backend_system.providerName || null;

			let local_system = {
				id: backend_system.id,
				name: backend_system.name || null,
				system_type: backend_system.systemType,
				rate_contributed_generation:
					isNaN(backend_system.rateContributedGeneration) ||
					backend_system.rateContributedGeneration == 0 ||
					backend_system.rateContributedGeneration == null
						? null
						: backend_system.rateContributedGeneration * 100,
				rate_contributed_consumption:
					isNaN(backend_system.rateContributedConsumption) ||
					backend_system.rateContributedConsumption == 0 ||
					backend_system.rateContributedConsumption == null
						? null
						: backend_system.rateContributedConsumption * 100,
				rate_contributed_grid_interaction:
					isNaN(backend_system.rateContributedGridInteraction) ||
					backend_system.rateContributedGridInteraction == 0 ||
					backend_system.rateContributedGridInteraction == null
						? null
						: backend_system.rateContributedGridInteraction * 100
			};

			if (asset_backend_provider_name) {
				local_system.provider_name =
					BackendEntityConverter.convert_backend_value_to_local(
						"energy_measurement_provider_names",
						asset_backend_provider_name
					);
			}

			if (backend_system.systemIdInProvider) {
				local_system.system_id_in_provider = backend_system.systemIdInProvider;
			}

			local_asset_provider_data.systems.push(local_system);
		});
	}

	return local_asset_provider_data;
};
