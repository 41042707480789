import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "@src/shared/infrastructure/backend-converters/backend-object-converter.js";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_sunrise_sunset(asset_id) {
		const url = "/api/asset/" + asset_id + "/sunrise-sunset-times";

		const backend_asset_sunrise_sunset_data = await this.get(url, {
			query_params: {
				useLocalTimeZone: true
			}
		});

		const hour_to_date_converter = (value) => {
			const date = new Date();
			date.setHours(value[0] + value[1]);
			date.setMinutes(value[3] + value[4]);
			return date;
		};

		return BackendObjectConverter(backend_asset_sunrise_sunset_data, {
			sunrise: {
				formatter: hour_to_date_converter
			},
			sunset: {
				formatter: hour_to_date_converter
			}
		});
	}
}
