import EnergySystemCustomHourlyValidator from "../../domain/energy-system-custom-monthly-editor-validator";
import EnergySystemCustomMonthlyEnergyFinderInterfaceClass from "@src/energy-system/infrastructure/energy-data/energy-system-custom-monthly-energy-finder-interface.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = EnergySystemCustomHourlyValidator;
		this.backend_interface =
			new EnergySystemCustomMonthlyEnergyFinderInterfaceClass();
	}

	async get_energy_system_custom_hourly(system_id) {
		try {
			const interface_data =
				await this.backend_interface.get_energy_system_custom_hourly(system_id);

			const validated_data = this.validator.validate(interface_data);

			return validated_data;
		} catch (e) {
			throw new AppError(
				"error_get_energy_system_custom_hourly",
				"general.errors.general_error",
				e
			);
		}
	}
}
