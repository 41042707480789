export default {
	get_variables(resolution = null) {
		return this._get_variables(resolution == "hourly");
	},

	_get_variables(hourly_resolution = false) {
		return {
			irradiance: {
				unit: hourly_resolution ? "W/m&sup2;" : "kWh/m&sup2;",
				is_percentage: false,
				name: "weather_forecast.variables.irradiance",
				color: "#D66A06"
			}
		};
	}
};
