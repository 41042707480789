<template>
	<div id="remove-asset" class="white-bg rounded-s box-shadow1">
		<PageHeader :text="$t('general.remove')" close_name="app" />
		<div class="p-sm">
			<div>
				<div v-if="error">
					<Alert :message="error" />
				</div>
				<div v-else-if="success" class="alert alert-success font-s p-xxs">
					{{ $t("general.update_successful") }}
				</div>
				<div v-else>
					<div class="p-t-xl p-b-m flex-center-content">
						<div class="text-center" style="width: 300px">
							<span>{{ $t("index.asset_remove") }}</span>
						</div>
					</div>
					<div v-if="!loading" class="flex-horizontal-right p-t">
						<div class="m-r">
							<button @click="remove_asset" class="btn btn-primary">
								{{ $t("general.remove") }}
							</button>
						</div>
						<div>
							<button class="left btn btn-danger" v-on:click="close">
								{{ $t("general.cancel") }}
							</button>
						</div>
					</div>
					<div v-else>
						<Loader size="30" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader.vue";
import Loader from "@loader";
import Alert from "@alert";

import AssetsFinderClass from "@src/asset/application/assets-finder";
import AssetEraserClass from "@src/asset/application/asset-eraser";

const AssetsFinder = new AssetsFinderClass();
const AssetEraser = new AssetEraserClass();

export default {
	name: "RemoveAsset",
	components: {
		PageHeader,
		Loader,
		Alert
	},
	data() {
		return {
			loading: false,
			asset: null,
			error: null,
			success: null
		};
	},
	async mounted() {
		this.error = null;
		this.asset_id = this.$route.params.asset_id;

		this.asset = await AssetsFinder.get_asset_by_id(this.asset_id);

		if (!this.asset) this.error = "general.errors.not_available";
	},
	methods: {
		async remove_asset() {
			this.loading = true;
			this.error = null;
			this.success = null;
			try {
				this.$emit("removing_item");
				await AssetEraser.remove_asset(this.asset.id);

				this.success = true;
			} catch (e) {
				this.error = e;
			} finally {
				this.$emit("item_removed");
				this.loading = false;
			}
		},
		close() {
			this.$router.push({ name: "app" });
		}
	}
};
</script>

<style>
#remove-asset {
	width: 600px;
	max-height: calc(100% - 85px);
}
</style>
