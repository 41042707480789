import Joi from "joi";
import Validator from "@validators/validator";

const alert_schema = Joi.object({
	date_from: Joi.date(),
	date_to: Joi.date(),
	is_archived: Joi.boolean(),
	alert_types: Joi.array().items(
		Joi.string().valid("pr_alert", "provider_alert", "suspicious_data_alert")
	),
	energy_systems: Joi.array().items(Joi.string()),
	severities: Joi.array().items(Joi.number().min(0).max(3)),
	order_by: Joi.string().valid("date", "severity"),
	order: Joi.string().valid("asc", "desc"),
	alert_codes: Joi.array().items(Joi.string()),
	provider_names: Joi.array().items(Joi.string())
});

export default new Validator(alert_schema);
